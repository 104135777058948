import { DateTime } from "luxon";

import type {
    DatedTimesOfDay,
    LocalHours,
    TimeOfDayRange,
    WeekdayTimeRanges
} from "src/types";

import { hasValue } from "./hasValue";

type LegacyTimeRange = {
    start: number; // minutes since start of week
    end: number; // minutes since start of week
};

type FromLegacyDateTimeRange = LegacyTimeRange & {
    date: Date | string; // ISO 8601 date string in online-ordering, despite snackpass-types saying otherwise
};

type ToLegacyDateTimeRange = LegacyTimeRange & {
    date: Date;
};

export type FromLegacyHours = {
    zone: string; // IANA timezone
    local: LegacyTimeRange[];
    special?: FromLegacyDateTimeRange[];
};

export type ToLegacyHours = {
    zone: string; // IANA timezone
    local: LegacyTimeRange[];
    special?: ToLegacyDateTimeRange[];
};

const DAY = 1440;

const _convert = (
    startMin: number,
    endMin: number,
    weekday: number // 1-7, Mon-Sun
): TimeOfDayRange | undefined => {
    if (startMin >= endMin) return undefined;

    const endOfDay = weekday * DAY;
    const startOfDay = endOfDay - DAY;

    const start = startMin > startOfDay ? startMin : startOfDay;
    const end = endMin < endOfDay ? endMin : endOfDay;

    return {
        start: {
            hour: Math.floor((start % DAY) / 60),
            minute: start % 60
        },
        end: {
            // Force 24, if end is past midnight
            hour: endMin < endOfDay ? Math.floor((end % DAY) / 60) : 24,
            minute: end % 60
        }
    };
};

// NOTE: Implementing a simple `range` to avoid another dependency.
const range = (start: number, end: number, step = 1): number[] => {
    const result: number[] = [];

    for (let i = start; i <= end; i += step) result.push(i);

    return result;
};

/**
 * Converts the LegacyHours to the new LocalHours type.
 */
export const fromLegacy = ({
    local,
    special,
    zone
}: FromLegacyHours): LocalHours => {
    const weekdays = local.reduce((acc, { start, end }) => {
        // 1-7, Mon-Sun
        const days = range(
            Math.floor(start / DAY) + 1,
            Math.floor(end / DAY) + 1
        );
        for (const day of days) {
            acc[day] = [...(acc[day] ?? []), _convert(start, end, day)].filter(
                Boolean
            );
        }
        // console.dir({ days, acc }, { compact: true, depth: 5 });

        return acc;
    }, {} as WeekdayTimeRanges);

    const dates = Array.isArray(special) // extra defense for Promotion.hours
        ? special.map(({ date, start, end }) => {
              const { year, month, day, weekday } =
                  typeof date === "string"
                      ? DateTime.fromISO(date)
                      : DateTime.fromJSDate(date, {
                            zone
                        });

              return {
                  date: {
                      year,
                      month,
                      day
                  },
                  hours: [_convert(start, end, weekday)].filter(Boolean)
              } as DatedTimesOfDay;
          })
        : undefined;

    return {
        zone,
        weekdays,
        dates
    };
};

/**
 * Converts the new LocalHours type to LegacyHours.
 */
export const toLegacy = (hours: LocalHours): ToLegacyHours => {
    const zone = hours.zone;
    const local = Object.entries(hours.weekdays).flatMap(([day, ranges]) =>
        ranges.map(
            ({ start, end }): LegacyTimeRange => ({
                start:
                    (parseInt(day) - 1) * DAY + start.hour * 60 + start.minute,
                end: (parseInt(day) - 1) * DAY + end.hour * 60 + end.minute
            })
        )
    );

    const special = hours.dates
        ?.map(
            ({
                date: { year, month, day },
                hours
            }): ToLegacyDateTimeRange | undefined => {
                const _date = DateTime.fromObject(
                    {
                        year,
                        month,
                        day
                    },
                    { zone }
                );
                const date = _date.toJSDate();
                const weekday = _date.weekday;
                const days = DAY * (weekday - 1);
                const start =
                    days +
                    (hours[0]
                        ? hours[0].start.hour * 60 + hours[0].start.minute
                        : 0);

                const end =
                    days +
                    (hours[0]
                        ? hours[0].end.hour * 60 + hours[0].end.minute
                        : 0);

                return { date, start, end };
            }
        )
        .filter(hasValue);

    return {
        zone: hours.zone,
        local,
        special
    };
};

export const splitHoursOnDays = (hour: LegacyTimeRange, zone: string) =>
    toLegacy(
        fromLegacy({
            local: [hour],
            zone
        })
    ).local;
