import { CSSProperties } from "react";
import { Text, View } from "src/SharedComponents";
import { Container } from "@material-ui/core";
import LEFT_ARROW_IMAGE from "src/assets/icons/chevron_left.svg";
import X_ICON_IMAGE from "src/assets/icons/xIcon.svg";

type Icon = "cross" | "arrow";

type GiftCardHeaderProps = {
    title: string;
    closeFunc: () => void;
    icon: Icon;
    subtitle?: string;
    bigTitle?: boolean;
};

export const ModalHeader = ({
    title,
    closeFunc,
    icon,
    subtitle,
    bigTitle,
}: GiftCardHeaderProps) => {
    return (
        <Container style={styles.maxWidth100}>
            <View style={styles.container}>
                <img
                    alt="Close"
                    style={styles.imgIcon}
                    src={icon === "arrow" ? LEFT_ARROW_IMAGE : X_ICON_IMAGE}
                    onClick={closeFunc}
                />

                <Text
                    style={bigTitle ? styles.textBigTitle : styles.textTitle}
                    large
                >
                    {title}
                </Text>
            </View>
            {subtitle ? (
                <View style={styles.container}>
                    <Text style={styles.textSubtitle}>{subtitle}</Text>
                </View>
            ) : null}
        </Container>
    );
};

const styles = {
    textTitle: {
        fontStyle: "normal",
        fontSize: "20px",
        fontWeight: 500,
        color: "#282D32",
        textAlign: "center",
        width: "100%",
    } as CSSProperties,

    textBigTitle: {
        fontStyle: "normal",
        fontSize: "38px",
        fontWeight: 700,
        color: "#282D32",
        textAlign: "center",
        width: "100%",
    } as CSSProperties,

    textSubtitle: {
        fontStyle: "normal",
        fontSize: 18,
        fontWeight: 400,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        color: "#606C76",
        padding: "2%",
    } as CSSProperties,

    selector: {
        width: "100%",
        zIndex: 10,
        position: "static",
    } as CSSProperties,

    imgIcon: {
        width: 20,
        height: "auto",
        cursor: "pointer",
    } as CSSProperties,

    container: {
        width: "100%",
        padding: "1%",
        zIndex: 100,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties,

    maxWidth100: {
        maxWidth: "100%",
    } as CSSProperties,
};
