import {
    ActiveCartItemActions,
    CartItem,
    getActiveStore,
} from "@snackpass/accounting";
import _ from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLastOrder } from "./useLastOrder";
import useCartItemHandler from "./useCartItemHandler";
import { ModalsName, showModal } from "@/redux";
import { AddonGroup, IProduct } from "@snackpass/snackpass-types";
import fp from "lodash/fp";
import { toast } from "sonner";

const getAddon = (product: IProduct, selectedAddonGroupId, selectedAddonId) => {
    const addonGroup = product.addonGroups.find(
        (addonGroup) => addonGroup._id === selectedAddonGroupId
    );

    return addonGroup?.addons.find((addon) => addon._id === selectedAddonId);
};

export function getCartItemsMissingRequiredAddons(cartItems: CartItem[]) {
    const _selectedAddonGroupsSet = fp.compose(
        (keys) => new Set(keys),
        fp.keys,
        fp.keyBy("addonGroup._id")
    );
    const _findAddonValidationError = fp.curry(
        (addonGroups: AddonGroup[], selectedAddonGroups: Set<string>) =>
            fp.find(
                (addonGroup: any) =>
                    addonGroup.required &&
                    !selectedAddonGroups.has(addonGroup._id)
            )(addonGroups)
    );

    const _validationError = (items: CartItem[]) =>
        fp.filter((item: CartItem) => {
            const hasAddonGroups =
                fp.getOr(0, "product.addonGroups.length", item) > 0;
            if (hasAddonGroups) {
                const validationError = fp.compose(
                    _findAddonValidationError(item.product.addonGroups),
                    _selectedAddonGroupsSet
                )(item.selectedAddons);
                return !!validationError;
            }
            if (item.product.specialProductType != null) {
                // Gift cards need attention on who we are sending to on quick-add
                return true;
            }
            return false;
        })(items);

    return _validationError(cartItems);
}

export function useQuickAdd() {
    const { lastOrderProductsAndAddons } = useLastOrder();
    const { addCartItem, checkIsCartItemAvailable } = useCartItemHandler(true);
    const activeStore = useSelector(getActiveStore);
    const dispatch = useDispatch();
    const onQuickAdd = useCallback(
        _.debounce(
            () => {
                if (activeStore) {
                    let doNotAddToCart = false;
                    //check if all products are valid, if any item is invalid show error message or open product modal if addon is missing
                    for (
                        let i = 0;
                        i < lastOrderProductsAndAddons.length;
                        i++
                    ) {
                        const o = lastOrderProductsAndAddons[i];
                        const { isValid, text } = checkIsCartItemAvailable(
                            o.product,
                            o.quantity || 1,
                            o.weight?.amount || 1
                        );
                        if (!isValid) {
                            doNotAddToCart = true;
                            toast.error(`Error: ${text}`);
                            break;
                        }
                    }

                    if (doNotAddToCart) {
                        return;
                    }

                    const cartItemsWithMissingAddons =
                        getCartItemsMissingRequiredAddons(
                            lastOrderProductsAndAddons
                        );
                    // if missing required addons, open product modal with rest of the addons selected
                    if (cartItemsWithMissingAddons.length > 0) {
                        const firstItem = cartItemsWithMissingAddons[0];
                        dispatch(
                            ActiveCartItemActions.setActiveCartItem(
                                firstItem.product
                            )
                        );
                        const initializeAddons = () => {
                            firstItem.selectedAddons?.forEach(
                                (selectedAddon) => {
                                    const addOn = getAddon(
                                        firstItem.product,
                                        selectedAddon.addonGroup._id,
                                        selectedAddon.addon._id
                                    );
                                    for (
                                        let i = 0;
                                        i < selectedAddon.quantity;
                                        i++
                                    ) {
                                        addOn &&
                                            dispatch(
                                                ActiveCartItemActions.selectIncrementAddon(
                                                    addOn
                                                )
                                            );
                                    }
                                }
                            );
                        };
                        initializeAddons();
                        dispatch(showModal(ModalsName.productModal));
                        return;
                    }
                    // add all valid products with addons to the cart
                    lastOrderProductsAndAddons.forEach((cartItem) => {
                        //segment track
                        addCartItem(
                            cartItem.product,
                            cartItem.selectedAddons,
                            cartItem.notes,
                            cartItem.quantity,
                            cartItem.weight
                        );
                    });
                    toast.info("Added!");
                }
            },
            1000,
            { leading: true, trailing: false }
        ),
        [lastOrderProductsAndAddons]
    );

    return onQuickAdd;
}
