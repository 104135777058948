import { DateTime } from "luxon";

import { hasDateHours } from "./containsTime";
import { FromLegacyHours, fromLegacy } from "./legacy";

/**
 * Returns whether the store is operating under special hours at the provided date-time in unix time (timeMs).
 * By default, timeMs is now in Store's timezone.
 */
export const isSpecialDate =
    (hours: FromLegacyHours) =>
    (timeMs = DateTime.now().toMillis()): boolean =>
        hasDateHours(fromLegacy(hours))(timeMs);
