import Dinero from "dinero.js";
import { InventoryCost, Money } from "./cost";
import { AllUnits, InventoryUnitType } from "@snackpass/snackpass-types";

// NOTE: I'd like to have this in it's own file, but encounter
//       typescript error ts(4028) when attempting to export `convert`
import configureMeasurements, {
    each,
    mass,
    volume,
    EachSystems,
    EachUnits,
    MassSystems,
    VolumeSystems,
    VolumeUnits,
    MassUnits
} from "convert-units";

export const unitLabel = (unit: InventoryUnitType, qty = 2): string => {
    const [singular, plural] = AllUnits[unit];
    return qty === 1 ? singular : plural;
};

/**
 * Pretty awkward having to type these all out but it's necessary if we
 * are running TypeScript in strict mode:
 *
 * https://github.com/convert-units/convert-units/issues/221
 */
const convert = configureMeasurements<
    "each" | "mass" | "volume",
    EachSystems | MassSystems | VolumeSystems,
    EachUnits | MassUnits | VolumeUnits
>({ each, mass, volume });

export const DEFAULT_QUANTITY_PRECISION = 3;
export let quantityPrecision = DEFAULT_QUANTITY_PRECISION;
let scale = 10 ** DEFAULT_QUANTITY_PRECISION;
export const setQuantityPrecision = (precision?: number): void => {
    quantityPrecision = precision || DEFAULT_QUANTITY_PRECISION;
    scale = 10 ** quantityPrecision;
};

export type Fixed = Dinero.Dinero;
export const Fixed = (amount: number): Fixed =>
    Dinero({
        amount: Math.round(amount * scale),
        precision: quantityPrecision
    });

export type InventoryQuantityObject = {
    amount: number;
    precision: number;
    unit: InventoryUnitType;
};

export type InventoryQuantity = InventoryQuantityObject & {
    inUnits: (this: InventoryQuantity, unit: InventoryUnitType) => number;
    convertTo: (
        this: InventoryQuantity,
        unit: InventoryUnitType
    ) => InventoryQuantity;

    add: (
        this: InventoryQuantity,
        that: InventoryQuantity
    ) => InventoryQuantity;
    subtract: (
        this: InventoryQuantity,
        that: InventoryQuantity
    ) => InventoryQuantity;
    multiply: (this: InventoryQuantity, by: number) => InventoryQuantity;
    divide: (this: InventoryQuantity, by: number) => InventoryQuantity;
    percentage: (this: InventoryQuantity, of: number) => InventoryQuantity;

    price: (this: InventoryQuantity, cost: InventoryCost) => Money;

    toString: (this: InventoryQuantity) => string;
    toObject: (this: InventoryQuantity) => InventoryQuantityObject;
};

export const InventoryQuantity = ({
    amount = 0,
    precision = quantityPrecision,
    unit = "ea"
}: Partial<InventoryQuantityObject>): InventoryQuantity => {
    return {
        amount,
        precision,
        unit,
        convertTo,
        inUnits,
        add,
        subtract,
        multiply,
        divide,
        percentage,
        price,
        toString,
        toObject
    };
};

export function inUnits(
    this: InventoryQuantity,
    unit: InventoryUnitType
): number {
    return Fixed(
        convert(this.amount)
            .from(this.unit as EachUnits | MassUnits | VolumeUnits)
            .to(unit as EachUnits | MassUnits | VolumeUnits)
    ).toUnit();
}

export function convertTo(
    this: InventoryQuantity,
    unit: InventoryUnitType
): InventoryQuantity {
    return InventoryQuantity({
        amount: this.inUnits(unit),
        unit
    });
}

export function add(
    this: InventoryQuantity,
    that: InventoryQuantity
): InventoryQuantity {
    return InventoryQuantity({
        amount: Fixed(this.amount)
            .add(Fixed(that.inUnits(this.unit)))
            .toUnit(),
        unit: this.unit
    });
}

export function subtract(
    this: InventoryQuantity,
    that: InventoryQuantity
): InventoryQuantity {
    return InventoryQuantity({
        amount: Fixed(this.amount)
            .subtract(Fixed(that.inUnits(this.unit)))
            .toUnit(),
        unit: this.unit
    });
}

export function multiply(
    this: InventoryQuantity,
    by: number
): InventoryQuantity {
    return InventoryQuantity({
        amount: Fixed(this.amount).multiply(by).toUnit(),
        unit: this.unit
    });
}

export function divide(this: InventoryQuantity, by: number): InventoryQuantity {
    return InventoryQuantity({
        amount: Fixed(this.amount).divide(by).toUnit(),
        unit: this.unit
    });
}

export function percentage(
    this: InventoryQuantity,
    of: number
): InventoryQuantity {
    return InventoryQuantity({
        amount: Fixed(this.amount).percentage(of).toUnit(),
        unit: this.unit
    });
}

export function price(
    this: InventoryQuantity,
    at: InventoryCost
): Dinero.Dinero {
    return at.amount.multiply(
        0.0000001 + this.inUnits(at.perUnit) / at.perCount
    );
}

export function toString(this: InventoryQuantity): string {
    return `${this.amount} ${this.unit}`;
}

export function toObject(this: InventoryQuantity): InventoryQuantityObject {
    return { amount: this.amount, precision: this.precision, unit: this.unit };
}
