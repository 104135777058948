import { Addon, AddonGroup } from "@snackpass/snackpass-types";
import { createSelector } from "reselect";
import { flattenCartItem } from "../../accountingFormulas";
import { CartItem, ReduxState } from "../../types";
import {
    addonGroupLimitIsReached,
    getAddonIndexById,
    getAddonQuantityByAddon,
    getNumSelectedAddonsInAddonGroup
} from "../../utils";

export const getActiveCartItem = (state: Pick<ReduxState, "activeCartItem">) =>
    state.activeCartItem;

export const getActiveCartItemFlat = createSelector(
    [getActiveCartItem],
    (cartItem) => flattenCartItem(cartItem as CartItem)
);

// the addon selection on the ProductScreen
export const getSelectedAddons = createSelector(
    [getActiveCartItem],
    (activeCartItem) => activeCartItem.selectedAddons
);

// the addon selection on the ProductScreen
export const getActiveCartItemNotes = createSelector(
    [getActiveCartItem],
    (activeCartItem) => activeCartItem.notes
);

// the product object that the user has selected
export const getActiveProduct = createSelector(
    [getActiveCartItem],
    (activeCartItem) => activeCartItem.product
);

// the active promotion for the selected product
export const getActivePromotion = createSelector(
    [getActiveCartItem],
    (activeCartItem) => activeCartItem.promotion
);

// the active reward for the selected product
export const getActiveReward = createSelector(
    [getActiveCartItem],
    (activeCartItem) => activeCartItem.reward
);

// the digital gift card info for the selected product
export const getDigitalGiftCardCartInfo = createSelector(
    [getActiveCartItem],
    (activeCartItem) => activeCartItem.digitalGiftCardInfo
);

// check if all required addons are selected
export const getAddonSelectionIsValidated = createSelector(
    [getActiveProduct, getSelectedAddons],
    (activeProduct, selectedAddons) => {
        let ret = true;
        if (!activeProduct) {
            return ret;
        }
        activeProduct.addonGroups.forEach((addonGroup) => {
            if (!addonGroup.required) {
                return;
            }
            const numAddonsAddedInSubcategory =
                getNumSelectedAddonsInAddonGroup(addonGroup, selectedAddons);
            // no addons selected for required category
            if (!numAddonsAddedInSubcategory) {
                ret = false;
            }
        });
        return ret;
    }
);

// check for required addons that haven't been selected
export const getAddonGroupsNeedSelection = createSelector(
    [getActiveProduct, getSelectedAddons],
    (activeProduct, selectedAddons) => {
        if (!activeProduct?.addonGroups) {
            return [];
        } else {
            return activeProduct.addonGroups.filter(
                (group) =>
                    // No addons selected for required category.
                    group.required &&
                    getNumSelectedAddonsInAddonGroup(group, selectedAddons) ===
                        0
            );
        }
    }
);

export function getAddonIsSelected(state: ReduxState, addon: Addon) {
    const selectedAddons = getSelectedAddons(state);

    if (!selectedAddons) {
        return false;
    }

    const addonIndex = getAddonIndexById(addon._id, selectedAddons);
    return addonIndex !== -1;
}

export function getAddonGroupLimitIsReached(
    state: ReduxState,
    addonGroup: AddonGroup
) {
    const selectedAddons = getSelectedAddons(state);
    return addonGroupLimitIsReached(addonGroup, selectedAddons);
}

export function getAddonQuantity(state: ReduxState, addon: Addon) {
    const selectedAddons = getSelectedAddons(state);
    return getAddonQuantityByAddon(addon, selectedAddons);
}
