import { StoreEnhancer } from "@reduxjs/toolkit";

import createSagaMiddleware from "redux-saga";

/**
 * Enhancer adds runSaga method to the store.
 * This is useful to avoid hot reload creating duplicate sagas.
 * NB: Due to this, sagas will not be hot reloaded.
 */
export const sagaEnhancer: StoreEnhancer<{
    /** Dynamically run sagas.  */
    runSaga: typeof sagaMiddleware.run;
}> =
    (next) =>
    (...args) => ({
        ...next(...args),
        runSaga: (saga, ...sagaArgs) => sagaMiddleware.run(saga, ...sagaArgs),
    });

export const sagaMiddleware = createSagaMiddleware();
