import { useEffect, useState } from "react";
import { Drawer } from "vaul";

import {
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
} from "../ui/drawer-corporate";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveStoreIdOnMap,
    setActiveStoreOnMap,
} from "@/redux/corporateMap";
import { getActiveChainStores } from "@/redux";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import { StoreUtils } from "@/utils";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import { StoreLabelRow } from "./Row";

export function ActiveStoreOnMapDrawer() {
    const [open, setOpen] = useState(false);
    const activeStoreIdOnMap = useSelector(getActiveStoreIdOnMap);
    const activeChainStores = useSelector(getActiveChainStores);
    const store = activeChainStores.find((s) => s._id === activeStoreIdOnMap);
    const dispatch = useDispatch();
    useEffect(() => {
        setOpen(!!activeStoreIdOnMap);
    }, [activeStoreIdOnMap]);
    const storeRoute = store ? StoreUtils.getStoreRoute(store) : "";
    return (
        <Drawer.Root
            modal={false}
            open={open}
            onOpenChange={(open) => {
                setOpen(open);
                if (!open) {
                    dispatch(setActiveStoreOnMap(""));
                }
            }}
        >
            <Drawer.Portal>
                <Drawer.Content
                    className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 mx-[-1px] max-w-4xl"
                    style={{
                        margin: "0 auto",
                    }}
                >
                    <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mt-4 cursor-grab " />
                    <DrawerHeader className="pb-2">
                        <div className="flex flex-row justify-between items-start">
                            <div className="flex flex-col items-start flex-1">
                                <div className="flex flex-row items-start justify-between w-full">
                                    <DrawerTitle className="text-2xl text-left">
                                        {store?.name}
                                    </DrawerTitle>
                                    <div
                                        onClick={() => setOpen(false)}
                                        aria-label="Close drawer"
                                        className="cursor-pointer"
                                    >
                                        <CrossCircledIcon
                                            width={24}
                                            height={24}
                                        />
                                    </div>
                                </div>
                                <DrawerDescription className="text-left">
                                    {store ? (
                                        <StoreLabelRow store={store} />
                                    ) : null}
                                    <br />
                                    {store?.address}
                                </DrawerDescription>
                            </div>
                        </div>
                    </DrawerHeader>
                    <br />
                    <Link
                        onClick={() => {
                            dispatch(setActiveStoreOnMap(""));
                        }}
                        to={storeRoute}
                        className="w-full px-4 py-4 mb-2"
                    >
                        <Button
                            className="w-full"
                            label={"Order"}
                            onPress={() => {}}
                        />
                    </Link>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    );
}
