// TODO: figure this out
export function sortAddons(addonA: any, addonB: any) {
    if (!addonB.required && addonA.required) {
        return -1;
    }
    if (!addonA.required && addonB.required) {
        return 1;
    }

    // then sort by order child
    if (addonA.order && !addonB.order) {
        return -1;
    }
    if (!addonA.order && addonB.order) {
        return 1;
    }

    // if order child doesn't exist, sort alphabetically
    return addonA.name - addonB.name;
}
