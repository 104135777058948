import React from "react";
import { PoweredByBlack } from "src/components/PoweredBy";
import Menu from "./Menu";
import StoreLocations from "./StoreLocations";
import StoreHours from "@/components/StoreHours";
import Promotions from "./Promotions";
import { CateringButton } from "./CateringButton";
import Fulfillment from "./Fulfillment";
import ScheduleTime from "./ScheduleTime";
import BuyDigitalGiftCardButton from "./BuyDigitalGiftCardButton";
import { Divider } from "@/SharedComponents/Divider";
import CoverPhotoMobile from "./CoverPhotoMobile";
import CoverPhotoDesktop from "./CoverPhotoDesktop";
import useURLParams from "@/hooks/useURLParams";
import DesktopStoreHeader from "./DesktopStoreHeader";
import { getActiveStore } from "@snackpass/accounting";
import { useSelector } from "react-redux";

export const StorePageContent = () => {
    const urlParams = useURLParams();
    const fromCustomApp = urlParams.get("fromCustomApp");
    const activeStore = useSelector(getActiveStore);
    if (!activeStore) return null;

    return (
        <div>
            <div className="fixed left-0 right-0 top-0 z-[3] flex w-[100%] justify-center overflow-x-hidden border-b bg-white">
                <DesktopStoreHeader />
            </div>
            <div className="mx-auto max-w-7xl lg:mt-[74px]">
                <CoverPhotoMobile />
                <div className="mx-4 2xl:mx-0 hidden lg:block">
                    <CoverPhotoDesktop />
                </div>
                <div className="mx-4 2xl:mx-0">
                    <div className="flex flex-col items-center justify-between gap-y-2 lg:flex-row">
                        {!fromCustomApp ? (
                            <div className="my-auto w-[100%] lg:w-[60%] flex flex-col justify-start">
                                <h3 className="mb-0 line-clamp-3 text-ellipsis text-2xl font-bold mt-4">
                                    {activeStore.name}
                                </h3>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-7xl">
                {/* Store and order option section */}
                <div className="flex w-full h-full flex-col lg:p-0">
                    <div className="pt-4 md:pt-6 pb-4 md:pb-6 px-4 2xl:px-1 md:grid flex overflow-x-auto flex-row gap-x-3 gap-y-3 md:grid-cols-3 xl:grid-cols-4 [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden">
                        <div className="h-[94px] md:h-[64px] min-w-[144px] md:w-auto">
                            <StoreLocations />
                        </div>
                        <div className="h-[94px] md:h-[64px] min-w-[144px] md:w-auto">
                            <StoreHours />
                        </div>
                        <div className="h-[94px] md:h-[64px] min-w-[144px] md:w-auto">
                            <Fulfillment />
                        </div>
                        <div className="h-[94px] md:h-[64px] min-w-[144px] md:w-auto">
                            <ScheduleTime />
                        </div>
                        <Promotions />
                        <BuyDigitalGiftCardButton />
                        {activeStore?.cateringEnabled ? (
                            <div className="h-[94px] md:h-[64px] min-w-[144px] md:w-auto">
                                <CateringButton />
                            </div>
                        ) : null}
                    </div>
                    {/* desktop note */}
                    {activeStore.notes && (
                        <div className="rounded-xl mx-4 2xl:mx-0 border hidden md:block md:w-auto mb-2 py-2 px-4">
                            <div className="flex flex-row flex-1 items-center p-2 text-base font-normal leading-6 text-secondary">
                                {activeStore.notes}
                            </div>
                        </div>
                    )}
                    {/* mobile notes */}
                    <div className="md:hidden mb-2 md:mb-0">
                        <Divider width={8} />
                    </div>
                    {activeStore.notes && (
                        <>
                            <div className="block h-fit flex-row md:hidden items-center p-4 text-base font-normal leading-6 text-secondary">
                                {activeStore.notes}
                            </div>
                            <div className="md:hidden mt-2">
                                <Divider width={8} />
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <Menu />
                </div>
                <div className="flex justify-center pb-16 pt-[20px] text-center md:pb-4">
                    <PoweredByBlack />
                </div>
            </div>
        </div>
    );
};
