import { useEffect } from "react";
import { useSelector } from "react-redux";
import { match } from "react-router-dom";
import { getActiveStore } from "src/redux";
import LoadingScreen from "../LoadingScreen";
import Seo from "./components/Seo";
import { useLoadChainStores } from "@/hooks/useLoadChainStores";
import { useLoadPickupEstimate } from "@/hooks/useLoadPickupEstimate";
import { useLoadCatering } from "@/hooks/useLoadCatering";
import { useHandleCartStoreDifferences } from "./useHandleCartStoreDifferences";
import { useLoadStore } from "@/hooks/useLoadStore";
import { ErrorScreen } from "../ErrorScreen";
import { useLoadPunchCards } from "@/hooks/useLoadPunchCards";
import { useLoyalty } from "@/hooks/useLoyalty";
import { useLoadPastOrders } from "@/hooks/useLoadPastOrders";
import { useLegacyLoadRewards } from "@/hooks/useLegacyLoadRewards";
import { useAutoOpenDGCProduct } from "@/hooks/useAutoOpenDGCProduct";
import { usePromptScheduleModal } from "@/hooks/usePromptScheduleModal";
import { useCheckInvalidCartItem } from "@/hooks/useCheckInvalidCartItem";
import { usePromotions } from "@/hooks/usePromotions";
import { CorporateMode } from "@/components/CorporateMode";
import { getIsCorporate } from "#activeStore";
import { useFulfillmentOptions } from "./useFulfillmentOptions";
import { useShouldOpenMap } from "./useShouldOpenMap";
import SearchProductModal from "@/modals/SearchProductModal";
import ScheduleModal from "@/modals/ScheduleModal";
import StoreHoursModal from "@/modals/storeHoursModal";
import ProductModal from "@/modals/ProductModal";
import ChooseLocationModal from "@/modals/ChooseLocationModal";
import RewardOptionsModal from "@/modals/RewardOptionsModal";
import ClaimRewardModal from "@/modals/ClaimRewardModal";
import DealModal from "@/modals/DealModal";
import DiscountModal from "@/modals/DiscountModal";
import DealAndDiscountPreviewModal from "@/modals/DealAndDiscountPreviewModal";
import RemoveDealModal from "@/modals/RemoveDealModal";
import FulfillmentModal from "@/modals/FulfillmentModal";
import { StorePageContent } from "./components/StorePageContent";
import ViewOrderButton from "./components/ViewOrderButton";

type Props = {
    match: match<{ storeNameAndId: string }>;
};

// XXX: One component to rule them all, this needs to get split up.
export const StoreScreen = (props: Props) => {
    const { match } = props;
    const { storeNameAndId } = match.params;
    const activeStore = useSelector(getActiveStore);

    // this sets to active store redux, which is needed for all the other hooks
    const { isLoading, error } = useLoadStore(storeNameAndId);
    useLoadChainStores();
    useLoadPickupEstimate();
    useLoadCatering();
    useHandleCartStoreDifferences();
    useLoadPastOrders(storeNameAndId);
    useLoyalty();
    usePromotions();
    useLoadPunchCards();
    useLegacyLoadRewards();
    usePromptScheduleModal();
    useCheckInvalidCartItem();
    useFulfillmentOptions();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [storeNameAndId]);

    useAutoOpenDGCProduct({ checkOnInitialLoad: true });
    useShouldOpenMap();
    const isCorporate = useSelector(getIsCorporate);

    if (isLoading || !activeStore) {
        return <LoadingScreen />;
    }
    if (error || activeStore.isArchived) {
        return <ErrorScreen message="Store not found" />;
    }

    if (!activeStore.onlineOrderingEnabled) {
        return (
            <ErrorScreen message="Online ordering is not enabled for this store" />
        );
    }

    if (isCorporate) {
        return <CorporateMode />;
    }
    return (
        <div>
            <Seo />
            <SearchProductModal />
            <ProductModal />
            <StoreHoursModal />
            <ChooseLocationModal />
            <RewardOptionsModal />
            <ClaimRewardModal />
            <DealModal />
            <DiscountModal />
            <FulfillmentModal />
            <ScheduleModal />
            <RemoveDealModal />
            <DealAndDiscountPreviewModal />
            <StorePageContent />
            <div className="fixed bottom-0 left-0 right-0 z-[2] flex h-[64px] w-full items-center justify-center border-t bg-white lg:hidden">
                <ViewOrderButton />
            </div>
        </div>
    );
};
