import { v4 as uuidv4 } from "uuid";
import { IStore } from "@snackpass/snackpass-types";
import { omit } from "lodash/fp";

import { CartItemWithTaxInfo, DealGroup, ICart } from "../../types";

import {
    ADD_DEAL_GROUP,
    ADD_MULTICART_ITEM,
    CLEAR_MULTICART,
    CLEAR_MULTICART_TABLE_NUMBER,
    MultiCartActions,
    REMOVE_DEAL_GROUP,
    REMOVE_MULTICART_ITEM,
    REMOVE_MULTICART_ITEMS_BY_GROUP_UUID,
    SET_MULTICART_ADDRESS,
    SET_MULTICART_FULFILLMENT,
    SET_MULTICART_ITEMS,
    SET_MULTICART_NOTES,
    SET_MULTICART_NUMBER_OF_BAGS,
    SET_MULTICART_SCHEDULE_DATE,
    SET_MULTICART_TABLE_NUMBER,
    SET_MULTICART_TIP,
    SET_MULTICART_WHEN,
    SET_USE_CREDIT
} from "./actions";
import { calcCartItemPrices } from "../../accountingFormulas/formulas";

export type MultiCartState = ICart[];
export const multiCartInitialState: MultiCartState = [
    {
        items: [],
        dealGroups: [],
        notes: "",
        tip: 0,
        fulfillment: "PICKUP",
        address: "",
        useCredit: true,
        when: "NOW",
        store: null,
        isCatering: false,
        headcount: 1,
        selectedDeliveryQuote: null,
        deliveryQuotes: [],
        canDeliver: false,
        deliveryError: null,
        numberOfBags: 0
    }
];

export function multiCart(
    state = multiCartInitialState,
    action: MultiCartActions
): MultiCartState {
    switch (action.type) {
        case CLEAR_MULTICART:
            return [...multiCartInitialState];

        case ADD_MULTICART_ITEM: {
            // if reward remove promotion
            const rate = action.item.product?.taxInfo
                ? action.item.product.taxInfo.rate
                : action.store.taxRate || 0;
            const { totalPriceAfterDiscount } = calcCartItemPrices(action.item);
            const newCartItem: CartItemWithTaxInfo = {
                ...action.item,
                ...(action.item.reward && { promotion: undefined }),
                taxInfo: { rate },
                taxPolicies: action.item.product.taxPolicies || [],
                amountAfterDiscountInDollars: totalPriceAfterDiscount
            };

            return upsertCartItem(state, action.store, newCartItem);
        }

        case REMOVE_MULTICART_ITEM: {
            let new_state = state.map((cart) => {
                if (cart.store?._id !== action.store._id) {
                    return cart;
                }
                cart.items.splice(action.itemIndex, 1);
                return cart;
            });

            // remove stores with no items from multicart unless it
            // is the last one
            if (new_state.length > 1) {
                new_state = new_state.filter((cart) => cart.items.length > 0);
            }

            // if there is only one cart left and it has no items,
            // reset the multicart to initial state
            if (new_state.length === 1 && new_state[0].items.length === 0) {
                return [...multiCartInitialState];
            }

            return new_state;
        }

        case REMOVE_MULTICART_ITEMS_BY_GROUP_UUID: {
            let new_state = state.map((cart) => {
                const new_items = [...cart.items].filter(
                    (item) => item.groupUuid !== action.groupUuid
                );
                return {
                    ...cart,
                    items: new_items
                };
            });

            // remove stores with no items from multicart unless it
            // is the last one
            if (new_state.length > 1) {
                new_state = new_state.filter((cart) => cart.items.length > 0);
            }

            // if there is only one cart left and it has no items,
            // reset the multicart to initial state
            if (new_state.length === 1 && new_state[0].items.length === 0) {
                return [...multiCartInitialState];
            }
            return new_state;
        }

        case SET_MULTICART_TIP: {
            const { store, tip } = action;
            return state.map((cart) => {
                if (cart.store && cart.store._id === store._id) {
                    return {
                        ...cart,
                        tip
                    };
                }
                return cart;
            });
        }

        case SET_MULTICART_FULFILLMENT: {
            // iff switching fulfillment from delivery to pickup, reset tip to
            // 0. This way, the auto-selected delivery tip does not carry over
            // to pickup. We do not want tip reset if fulfillment does not change
            return state.map((cart) => {
                let tip = cart.tip;
                if (
                    cart.fulfillment === "DELIVERY" &&
                    action.fulfillment === "PICKUP"
                )
                    tip = 0;
                return {
                    ...cart,
                    fulfillment: action.fulfillment,
                    tip
                };
            });
        }

        case SET_MULTICART_NOTES: {
            return state.map((cart) => {
                if (cart.store && cart.store._id === action.store._id) {
                    return {
                        ...cart,
                        notes: action.notes
                    };
                }
                return cart;
            });
        }

        case SET_MULTICART_ADDRESS: {
            return state.map((cart) => ({
                ...cart,
                address: action.address
            }));
        }

        case SET_MULTICART_ITEMS: {
            const { store, items } = action;
            return state.map((cart) => {
                if (cart.store && cart.store._id === store._id) {
                    return {
                        ...cart,
                        items: items
                    };
                }
                return cart;
            });
        }

        case SET_USE_CREDIT: {
            const { useCredit, store } = action;
            return state.map((cart) => {
                if (cart.store && cart.store._id === store._id) {
                    return {
                        ...cart,
                        useCredit: useCredit
                    };
                }
                return cart;
            });
        }

        case ADD_DEAL_GROUP: {
            const { selectedDealItems, promotion, store } = action;
            return state.map((cart) => {
                if (cart.store && cart.store._id === store._id) {
                    const newDealGroupId = uuidv4();
                    const newDealGroup: DealGroup = {
                        items: Object.values(selectedDealItems),
                        promotion,
                        id: newDealGroupId,
                        name: promotion.name
                    };

                    // add dealGroupId to each cart item in cart.dealGroups array
                    const dealGroups = [...cart.dealGroups];
                    newDealGroup.items.forEach((item) => {
                        item.cartItem.dealGroupId = newDealGroupId;
                    });
                    dealGroups.push(newDealGroup);

                    // add dealGroupId to cart.items
                    const items = [...cart.items];
                    const newCartItems = newDealGroup.items.map((item) => ({
                        ...item.cartItem,
                        dealGroupId: newDealGroup.id
                    }));
                    items.push(...newCartItems);

                    return {
                        ...cart,
                        dealGroups,
                        items
                    };
                }
                return cart;
            });
        }

        case REMOVE_DEAL_GROUP: {
            const { dealGroupIndex, store } = action;
            return state.map((cart) => {
                if (cart.store && cart.store._id === store._id) {
                    const updatedDealGroups = [...cart.dealGroups];
                    const dealGroup = updatedDealGroups[dealGroupIndex];
                    const dealGroupId = dealGroup.id;
                    updatedDealGroups.splice(dealGroupIndex, 1);

                    // remove dealGroup items from cart in reverse order
                    const items = [...cart.items];
                    for (let i = items.length - 1; i >= 0; i--) {
                        if (
                            items[i].dealGroupId &&
                            items[i].dealGroupId === dealGroupId
                        )
                            items.splice(i, 1);
                    }

                    return {
                        ...cart,
                        dealGroups: updatedDealGroups,
                        items
                    };
                }
                return cart;
            });
        }

        case SET_MULTICART_WHEN: {
            return state.map((cart) => ({
                ...cart,
                when: action.when
            }));
        }

        case SET_MULTICART_SCHEDULE_DATE: {
            return state.map((cart) => ({
                ...cart,
                scheduledDate: action.scheduledDate
            }));
        }

        case SET_MULTICART_TABLE_NUMBER:
            return state.map((cart) => ({
                ...cart,
                tableNumber: action.tableNumber
            }));

        case CLEAR_MULTICART_TABLE_NUMBER:
            return state.map(omit("tableNumber"));

        case SET_MULTICART_NUMBER_OF_BAGS:
            return state.map((cart) => ({
                ...cart,
                numberOfBags: action.numberOfBags
            }));

        default:
            return state;
    }
}

// returns new multiCart redux state with
// cart item added to the proper cart
const upsertCartItem = (
    multiCart: MultiCartState,
    store: IStore,
    newCartItem: CartItemWithTaxInfo
): MultiCartState => {
    // if the store is in the cart, or there is an empty cart
    // add the new item to its list of items
    let itemAdded = false;
    const new_state = multiCart.map((cart) => {
        if (cart.store && cart.store._id === store._id) {
            const new_items = [...cart.items];
            new_items.push(newCartItem);
            itemAdded = true;
            return {
                ...cart,
                items: new_items
            };
        } else if (!cart.store) {
            itemAdded = true;
            return {
                ...cart,
                store: store,
                items: [newCartItem]
            };
        }
        return cart;
    });

    // ow, store is not in cart so add it
    // and add the item
    if (!itemAdded) {
        new_state.push({
            ...multiCart[0],
            store: store,
            items: [newCartItem]
        });
    }
    return new_state;
};
