import React, { useState, useEffect, useRef, useCallback } from "react";
import { getCategoryName } from "../lib";
import { cn } from "src/utils";
import { Link } from "react-scroll";

import { useMediaQuery } from "react-responsive";
import { CATEGORY_NAME } from "@/utils/Types";
import { useSelector } from "react-redux";
import { selectCategoriesByChannel, selectPopularProducts } from "#menu";
import { Button } from "@/components/ui/button";

type CategoriesProps = {
    hasPastOrder: boolean;
    hasPromos: boolean;
    hidePopular?: boolean;
};

export function Categories({
    hasPastOrder,
    hasPromos,
    hidePopular,
}: CategoriesProps) {
    const hasPopularProducts = useSelector(selectPopularProducts).length > 0;
    const categories = useSelector(selectCategoriesByChannel);
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    const offset = isSmallScreen ? -50 : -125;
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const isBottom = useIsBottom();
    useEffect(() => {
        setActiveCategory(
            hasPromos
                ? CATEGORY_NAME.Promos
                : hasPastOrder
                  ? CATEGORY_NAME.PastOrder
                  : CATEGORY_NAME.Popular,
        );
    }, [hasPastOrder, hasPromos]);

    if (categories.length === 0) {
        return null;
    }

    return (
        <div className="hidden-scroll flex flex-row overflow-x-auto items-center flex-1 px-4 2xl:px-0">
            {hasPromos && (
                <Category
                    category={CATEGORY_NAME.Promos}
                    isActive={activeCategory === CATEGORY_NAME.Promos}
                    onSetActive={setActiveCategory}
                    offset={offset}
                />
            )}
            {hasPastOrder && (
                <Category
                    category={CATEGORY_NAME.PastOrder}
                    isActive={activeCategory === CATEGORY_NAME.PastOrder}
                    onSetActive={setActiveCategory}
                    offset={offset}
                />
            )}
            {hasPopularProducts && !hidePopular && (
                <Category
                    category={CATEGORY_NAME.Popular}
                    isActive={activeCategory === CATEGORY_NAME.Popular}
                    onSetActive={setActiveCategory}
                    offset={offset}
                />
            )}

            {categories.map(({ name }, i, arr) => {
                const isActive =
                    i === arr.length - 1
                        ? isBottom
                        : activeCategory === name && !isBottom;
                return (
                    <Category
                        category={name}
                        isActive={isActive}
                        onSetActive={setActiveCategory}
                        offset={offset}
                        key={i}
                    />
                );
            })}
        </div>
    );
}

/**
 * Provides a flag that indicates whether the user has scrolled to the bottom of the page.
 */
function useIsBottom() {
    const [isBottom, setIsBottom] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = (e: Event) => {
            if (!(e.target instanceof Document)) return;

            const scrollingElement = e.target.scrollingElement;
            if (!scrollingElement) return;

            const _isBottom =
                scrollingElement.scrollHeight -
                    scrollingElement.scrollTop -
                    scrollingElement.clientHeight <
                1;

            setIsBottom(_isBottom);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return isBottom;
}

type CategoryProps = {
    category: string;
    isActive: boolean;
    onSetActive: (category: string) => void;
    offset: number;
};

const Category = ({
    category,
    isActive,
    onSetActive,
    offset,
}: CategoryProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const handleSetActive = useCallback(
        (to) => {
            onSetActive(to);
        },
        [onSetActive],
    );

    useEffect(() => {
        if (isActive) {
            if (ref.current) {
                const parentElement = ref.current.parentElement;
                if (parentElement) {
                    const elementRect = ref.current.getBoundingClientRect();
                    const parentRect = parentElement.getBoundingClientRect();
                    // Calculate the current scroll position relative to the left edge
                    const currentScrollPosition = parentElement.scrollLeft;
                    // Calculate the target scroll position based on the element's position
                    const targetScrollPosition =
                        currentScrollPosition +
                        elementRect.left -
                        parentRect.left -
                        12;
                    // Scroll to the target position smoothly
                    parentElement.scrollTo({
                        left: targetScrollPosition,
                        behavior: "smooth",
                    });
                }
            }
        }
    }, [isActive]);

    return (
        <div ref={ref}>
            <Link
                activeClass="text-primary font-bold"
                to={category}
                spy={true}
                smooth={true}
                duration={500}
                offset={offset}
                spyThrottle={500}
                onSetActive={handleSetActive}
            >
                <Button
                    key={category}
                    variant={isActive ? "default" : "outline"}
                    label={getCategoryName(category)}
                    onPress={() => {}}
                    className={cn(
                        "whitespace-nowrap flex-1 flex-nowrap select-none px-2 my-1 border rounded-lg font-medium flex justify-center items-center mr-2 py-2 text-base cursor-pointer text-center",
                        {
                            "bg-primary !text-white": isActive,
                            "text-primary": !isActive,
                        },
                    )}
                ></Button>
            </Link>
        </div>
    );
};
