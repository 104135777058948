import {
    BatchMapping,
    TimeSlot,
    findBatch,
    getBatchKey,
} from "@snackpass/accounting";

import fp from "lodash/fp";
import moment from "moment-timezone";

export const generateBatchMapping = (
    firebaseBatches: any,
    timeSlots: TimeSlot[],
): BatchMapping => {
    if (!firebaseBatches) {
        return {};
    }

    const batchMapping: BatchMapping = {};

    for (const [scheduledTime, value] of Object.entries<{
        numberOfOrders?: number;
    }>(firebaseBatches)) {
        const batchTime = moment(new Date(scheduledTime));
        const batch = findBatch(batchTime, timeSlots);

        if (batch) {
            const key = getBatchKey(batch);
            const totalNumberOfOrders = fp.getOr(
                0,
                "numberOfOrders",
                batchMapping[key],
            );
            const numberOfOrders = fp.getOr(0, "numberOfOrders", value);

            batchMapping[key] = {
                numberOfOrders: totalNumberOfOrders + numberOfOrders,
            };
        }
    }

    return batchMapping;
};
