import { getActiveStore } from "@/redux";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

export const PausedOrderUntil = () => {
    const activeStore = useSelector(getActiveStore);
    if (activeStore?.closedUntil) {
        const closedUntilTime = DateTime.fromJSDate(
            new Date(activeStore.closedUntil),
        );
        const isToday =
            closedUntilTime.toISODate() === DateTime.local().toISODate();
        const dateFormat = isToday ? "h:mm a" : "h:mm a, MMM d";
        const date = closedUntilTime.toFormat(dateFormat);
        return (
            <div className="mt-2.5 block w-full max-w-full overflow-hidden overflow-x-auto rounded-lg border-2 border-warn p-2.5 text-warn">
                {activeStore.name} has temporarily paused online ordering and
                will be taking new orders again at {date}!
            </div>
        );
    }
    return null;
};
