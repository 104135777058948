import { CartAddon, CartItem } from "../types";

/**
 * Provides a list of addons that are pre-selected for a given cart item.
 * NB: We currently only support selecting one of each pre-selected addon.
 */
export const preSelectedAddons = (
    cartItem: Pick<CartItem, "product">
): CartAddon[] =>
    cartItem.product.addonGroups.flatMap((addonGroup) =>
        addonGroup.addons
            .filter((addon) => addon.preSelected)
            .map((addon) => ({
                addonGroup: {
                    _id: addonGroup._id,
                    name: addonGroup.name
                },
                addon: {
                    _id: addon._id,
                    name: addon.name,
                    price: addon.price
                },
                quantity: 1
            }))
    );
