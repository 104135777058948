import { IStore } from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";

import { getActiveStore } from "src/redux";
import { MapMarker } from "src/SharedComponents";
import * as MapUtils from "src/SharedComponents/Map/MapUtils";

type StoreMarkerProps = {
    store: IStore;
    selectedStoreId: string;
    lat: number;
    lng: number;
    onClick?: () => void;
};

export const StoreMarker = ({ store, selectedStoreId }: StoreMarkerProps) => {
    const activeStore = useSelector(getActiveStore);
    const isActive = selectedStoreId
        ? selectedStoreId === store._id
        : !!activeStore?._id && activeStore._id === store._id;
    const coords = MapUtils.getCoordsFromGeolocation(store.geolocation);

    return (
        <MapMarker
            focused={isActive}
            store={store}
            lat={coords.lat}
            lng={coords.lng}
        />
    );
};
