import { Tooltip } from "antd";
import InfoIcon from "src/assets/icons/info.png";
type Props = {
    hoverText?: string;
};

function InfoTooltip({ hoverText }: Props) {
    return (
        <Tooltip
            trigger="hover"
            style={{ backgroundColor: "black" }}
            overlay={<div>{hoverText}</div>}
        >
            <img
                src={InfoIcon}
                style={{
                    objectFit: "contain",
                    height: 14,
                    width: 14,
                }}
                alt="info"
            />
        </Tooltip>
    );
}

export default InfoTooltip;
