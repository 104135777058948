import React, { useContext } from "react";
import moment from "moment";
import styled from "styled-components";
import WeekDay from "./WeekDay";
import { IStore, WhenTypeEnum } from "@snackpass/snackpass-types";
import { SystemColors } from "@snackpass/design-system";
import Calendar from "react-calendar";
import { ScheduleContext } from "@/modals/ScheduleModal/ScheduleModalContext";

type WeekProps = {
    isCatering?: boolean;
    onSelectWeekDay: (date: moment.Moment) => void;
    selectedWeekDay: moment.Moment | null;
    activeStore: IStore;
    dates: moment.Moment[];
    minCateringDate: Date;
    maxCateringDate: Date;
};

const WeekDays = ({
    isCatering,
    onSelectWeekDay,
    selectedWeekDay,
    activeStore,
    dates,
    minCateringDate,
    maxCateringDate,
}: WeekProps) => {
    const { modalWhen } = useContext(ScheduleContext);

    const hasScheduledOrders = activeStore.hasScheduledOrders;

    const ScheduleView = (
        <div className="hidden-scroll mb-6 mt-2 flex flex-row overflow-x-scroll">
            {hasScheduledOrders &&
                dates.map((date, index) => (
                    <WeekDay
                        isSelected={
                            selectedWeekDay && modalWhen === WhenTypeEnum.Later
                                ? selectedWeekDay.isSame(date, "date")
                                : index === -1
                        }
                        // @ts-ignore
                        onSelectDate={onSelectWeekDay}
                        // @ts-ignore
                        date={date}
                        key={index}
                    />
                ))}
        </div>
    );

    const CalendarComponent = (
        <CalendarContainer>
            <Calendar
                minDate={minCateringDate}
                defaultActiveStartDate={minCateringDate}
                maxDate={maxCateringDate}
                onChange={(day) => {
                    onSelectWeekDay(moment(day));
                }}
                maxDetail={"month"}
                minDetail={"month"}
                view={"month"}
                next2Label={null}
                prev2Label={null}
                value={selectedWeekDay?.toDate()}
                calendarType="US"
            />
        </CalendarContainer>
    );

    return isCatering ? CalendarComponent : ScheduleView;
};

const CalendarContainer = styled.div`
    border-radius: 3px;

    .react-calendar__navigation {
        display: flex;

        .react-calendar__navigation__label {
            font-weight: bold;
            color: ${SystemColors.black} !important;
            cursor: auto;
            margin-top: 0 !important;
            margin-bottom: 5% !important;
            padding: 0 !important;
        }

        .react-calendar__navigation__arrow {
            flex-grow: 0.333;
            margin-top: 0 !important;
            margin-bottom: 5% !important;
            padding: 0 !important;
        }
    }

    .react-calendar__month-view__weekdays {
        text-align: center;
    }

    button {
        margin: 3%;
        margin-top: 5%;
        margin-bottom: 5%;
        background-color: ${SystemColors.white};
        border-radius: 16px;
        color: ${SystemColors.black};
        padding: 3%;
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        border: none;
        height: 65px;
        &:hover {
            background-color: ${SystemColors.gray70};
        }

        &:active {
            background-color: ${SystemColors.black} !important;
            color: ${SystemColors.white} !important;
        }

        &:disabled {
            background-color: ${SystemColors.white};
            color: #a0adba !important;
            cursor: auto;
        }
    }

    .react-calendar__month-view__days {
        display: grid !important;
        grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

        .react-calendar__tile {
            max-width: initial !important;
        }
    }

    .react-calendar__month-view__days__day--weekend {
        color: ${SystemColors.black} !important;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.6;
    }
    .react-calendar__month-view__days__day--weekend {
        color: #dfdfdf;
    }

    .react-calendar__tile--range {
        background-color: ${SystemColors.black} !important;
        color: ${SystemColors.white} !important;
    }
`;

export default WeekDays;
