import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectProducts } from "#menu";
import { getActiveStore } from "src/redux";
import { useAppSelector } from "@/redux/utils";
import { SpecialProductType } from "@snackpass/snackpass-types";

export function useIsDGCEnabled(): {
    isNewDGCExperience: boolean | undefined;
    isDGCEnabled: boolean | undefined;
} {
    const activeStore = useAppSelector(getActiveStore);
    const products = useSelector(selectProducts);
    const dgcProduct = products.find(
        (product) =>
            product.specialProductType === SpecialProductType.DigitalGiftCard,
    );
    const isNewDGCExperience = useMemo(() => dgcProduct != null, [dgcProduct]);
    const isDGCEnabled = useMemo(
        () => isNewDGCExperience || activeStore?.hasGiftCardsEnabled,
        [isNewDGCExperience, activeStore],
    );

    return { isNewDGCExperience, isDGCEnabled };
}
