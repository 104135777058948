import React from "react";
import fp from "lodash/fp";
import { useSelector, useDispatch } from "react-redux";
import { CartSelectors, CartActions } from "@snackpass/accounting";
import { Minus, Plus } from "lucide-react";

const Headcount = () => {
    const dispatch = useDispatch();
    const headcount = useSelector(CartSelectors.getCartHeadcount) || 0;
    const _setCartHeadcount = fp.compose(
        dispatch,
        CartActions.setCartCateringHeadcount
    );

    return (
        <div className="w-[120px] h-10 justify-center bg-white items-center inline-flex">
            <button
                onClick={() => {
                    if (headcount > 0) {
                        _setCartHeadcount(Math.max(headcount - 1, 1));
                    }
                }}
                className={`w-10 h-10 px-3 py-[13px] rounded-tl-lg rounded-bl-lg border-l border-t border-b border-zinc-200 justify-center items-center gap-2 flex 
               
                `}
            >
                <Minus
                    className="w-4 h-4 relative"
                    strokeWidth={2.5}
                    size={20}
                />
            </button>
            <div className="w-13 h-10 py-[13px] border-l border-t border-b border-zinc-200 justify-center items-center gap-2 flex">
                <input
                    className="flex w-11/12 pointer-events-auto text-center text-stone-950 text-base font-semibold leading-normal"
                    value={headcount}
                    type="number"
                    min={1}
                    onChange={(e) => {
                        let headcount = isNaN(parseInt(e.target.value))
                            ? 1
                            : parseInt(e.target.value);
                        _setCartHeadcount(Math.max(headcount, 1));
                    }}
                    placeholder="1"
                />
            </div>
            <button
                onClick={() => _setCartHeadcount(headcount + 1)}
                className="w-10 h-10 px-3 py-[13px] rounded-tr-lg rounded-br-lg border border-zinc-200 justify-center items-center gap-2 flex hover:bg-gray-50"
            >
                <Plus
                    size={20}
                    strokeWidth={2.5}
                    className="w-4 h-4 relative"
                />
            </button>
        </div>
    );
};

export default Headcount;
