import { getActiveStore } from "@/redux";
import { useSelector } from "react-redux";

export const RushHourUntil = () => {
    const activeStore = useSelector(getActiveStore);

    if (!activeStore) {
        return null;
    }
    const rushHourDelayMins = activeStore.rushHourDelay;
    const estimateTimeText = rushHourDelayMins
        ? `Pickup time is up to ${rushHourDelayMins} minutes.`
        : "Expect a longer wait.";

    return (
        <div className="mt-2.5 block w-full max-w-full overflow-hidden overflow-x-auto rounded-lg border-2 border-warn p-2.5 text-warn">
            {activeStore.name} is getting a ton of orders right now. <br />
            {estimateTimeText}
        </div>
    );
};
