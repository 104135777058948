/* Actions */

export const SET_IS_APPLE_PAY_ACTIVE = "SET_IS_APPLE_PAY_ACTIVE";
export type SET_IS_APPLE_PAY_ACTIVE = typeof SET_IS_APPLE_PAY_ACTIVE;

export type SetIsApplePayActive = ReturnType<typeof setIsApplePayActive>;
export const setIsApplePayActive = (isApplePayActive: boolean) =>
    ({
        type: SET_IS_APPLE_PAY_ACTIVE,
        isApplePayActive,
    } as const);

/* Reducer */
export interface PaymentState {
    isApplePayActive: boolean;
}
export const PaymentInitialState: PaymentState = {
    isApplePayActive: false,
};

export default function payment(
    state = PaymentInitialState,
    action: SetIsApplePayActive
): PaymentState {
    switch (action.type) {
        case SET_IS_APPLE_PAY_ACTIVE:
            return { ...state, isApplePayActive: action.isApplePayActive };
        default:
            return state;
    }
}

/* Selectors */
export const getIsApplePayActive = (state: any) =>
    state.payment.isApplePayActive;
