import * as Sentry from "@sentry/react";

const isError = (e: any): e is Error => {
    return (
        e &&
        e.stack &&
        e.message &&
        typeof e.stack === "string" &&
        typeof e.message === "string"
    );
};

const convertToError = (e: any): Error | undefined => {
    if (isError(e)) {
        // Ignore timeout errors (which are really NetworkErrors)
        // https://github.com/axios/axios/pull/3209
        if (e.message.includes("timeout of 0ms exceeded")) {
            return undefined;
        }

        return e;
    }
    return new Error(JSON.stringify(e));
};

export const sendError = (e: any) => {
    const error = convertToError(e);
    if (error) Sentry.captureException(error);
};
