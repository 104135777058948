import React, { CSSProperties } from "react";
import { Styles } from "../../utils";
import { SystemColors } from "@snackpass/design-system";

type InfoRowProps = {
    iconPath?: any;
    style?: CSSProperties;
    iconStyle?: CSSProperties;
    middleComponent?: JSX.Element;
    rightComponent?: JSX.Element;
    onClick?: () => void;
};

const InfoRow = ({
    iconPath,
    style,
    iconStyle,
    middleComponent,
    rightComponent,
    onClick
}: InfoRowProps) => {
    return (
        <div
            style={{
                display: "flex",
                cursor: onClick ? "pointer" : "inherit",
                borderBottom: `1px solid ${SystemColors.gray90}`,
                padding: "10px 0",
                ...Styles.flexRow,
                ...style
            }}
            onClick={onClick}
        >
            {iconPath && (
                <div style={{ margin: "0 15px 0 20px" }}>
                    <img
                        style={{
                            width: 18,
                            height: "auto",
                            ...iconStyle
                        }}
                        alt={iconPath}
                        src={iconPath}
                    />
                </div>
            )}

            <div style={{ flex: 1 }}>{middleComponent}</div>

            <div>{rightComponent}</div>
        </div>
    );
};

export default InfoRow;
