import React, { useCallback, useEffect } from "react";
import {
    ModalsName,
    getIsApplePayActive,
    getUser,
    getUserCards,
    setIsApplePayActive,
    setUser,
    showModal,
} from "src/redux";
import { Button } from "@/components/ui/button";
import { ReactComponent as CardIcon } from "src/assets/icons/credit-card.svg";
import { useSelector, useDispatch } from "react-redux";
import { Divider } from "@/SharedComponents";
import CardInput from "@/components/CardInput";
import { ReactComponent as ApplePayIcon } from "src/assets/icons/Apple_Pay_logo.svg";
import { Api } from "@/utils";
import fp from "lodash/fp";

const Cards = () => {
    const dispatch = useDispatch();
    const handleShowMyCardsModal = () => {
        dispatch(showModal(ModalsName.myCardsModal));
    };
    const activeCard = useSelector(getUser)?.activeCard || null;
    const cards = useSelector(getUserCards);
    const isApplePayActive = useSelector(getIsApplePayActive);
    const deactivateApplePay = () => dispatch(setIsApplePayActive(false));
    const _setUser = fp.compose(dispatch, setUser);

    const handleActivateFirstCard = useCallback(() => {
        Api.me.update({ activeCardIndex: 0 }).then((response) => {
            _setUser(response.data.user);
        });
    }, [_setUser]);

    useEffect(() => {
        // default first card in saved payment method as active card
        if (!isApplePayActive && !activeCard && cards.length) {
            handleActivateFirstCard();
        }
    }, [isApplePayActive, activeCard, cards.length, handleActivateFirstCard]);

    if (!cards.length) {
        return (
            <div className="flex flex-row items-center gap-2 mt-4 mb-2">
                <CardInput onSuccess={deactivateApplePay} />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-row justify-between p-4 md:p-6 items-center">
                <div className="flex flex-col">
                    {activeCard && (
                        <div className="flex flex-row text-secondary text-sm py-2 h-[40px]">
                            <CardIcon className="w-[20px] h-[20px] fill-secondary mr-3" />
                            ···· {activeCard.cardLast4}
                        </div>
                    )}
                    {isApplePayActive && !activeCard && (
                        <div className="flex flex-row items-center justify-start gap-2">
                            <div className="w-fit h-[30px] mt-1 px-1 flex items-center rounded-lg border">
                                <ApplePayIcon className="w-6 h-6" />
                            </div>
                            <div className="text-primary font-medium text-sm">
                                Apple Pay
                            </div>
                        </div>
                    )}
                </div>
                <Button
                    className="w-fit"
                    onPress={handleShowMyCardsModal}
                    label="Edit"
                    variant="outline"
                />
            </div>
            <Divider width={1} className="ml-4 md:ml-6" />
        </>
    );
};

export default Cards;
