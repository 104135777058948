import React, { useCallback, useMemo } from "react";
import { Button } from "@/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import {
    getUser,
    getUserFirstName,
    getUserIsLoggedIn,
    getUserLastName,
    logOut,
    setUserFirstName,
    setUserLastName,
} from "@/redux";
import { Divider } from "@/SharedComponents";
import PhoneVerification from "@/components/PhoneVerification";
import { cn } from "@/utils";
import useURLParams from "@/hooks/useURLParams";
import { Input } from "@/components/ui/input";
import NumberFormat from "react-number-format";
import { noop } from "lodash";
import MarketingConsent from "./MarketingConsent";

const PaymentSection = () => {
    const user = useSelector(getUser);
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);

    const showNotSignedIn = !isUserLoggedIn || !user;

    if (showNotSignedIn) {
        return <NotSignedInContent />;
    }

    return <SignedInContent />;
};

const SignedInContent = () => {
    const user = useSelector(getUser);
    const firstName = useSelector(getUserFirstName);
    const lastName = useSelector(getUserLastName);
    const dispatch = useDispatch();
    const params = useURLParams();
    const fromCustomApp = params.get("fromCustomApp");
    const userHasFirstAndLastName = user?.firstName && user?.lastName;

    const onNameChange = useCallback(
        (val: string, fieldName: string) => {
            if (fieldName === "firstName") {
                dispatch(setUserFirstName(val));
            } else {
                dispatch(setUserLastName(val));
            }
        },
        [dispatch],
    );

    const nameError = useMemo(() => {
        if (!firstName || !lastName) {
            return "Please add your first and last name";
        }
        return "";
    }, [firstName, lastName]);

    return (
        <>
            <div className="text-xl font-semibold leading-6 px-4 md:px-6 mt-6 mb-2 text-primary">
                Account
            </div>
            <div className="p-4 pb-0 md:px-6">
                <div
                    className={cn(
                        "flex justify-between items-center gap-4 md:pt-3 flex-1",
                        {
                            "flex-row": userHasFirstAndLastName,
                            "flex-col": !userHasFirstAndLastName,
                        },
                    )}
                >
                    <div className="flex flex-row w-full">
                        {userHasFirstAndLastName ? (
                            <div className="flex flex-col gap-2">
                                <div>
                                    {user.name ??
                                        `${user.firstName} ${user.lastName}`}
                                </div>
                                <div>
                                    {user?.number && (
                                        <NumberFormat
                                            type="tel"
                                            className="bg-white"
                                            format="+# (###) ###-####"
                                            value={user.number}
                                            placeholder="Phone Number"
                                            disabled
                                        />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col gap-3 w-full">
                                <div className="flex flex-1 w-full flex-row gap-3">
                                    <Input
                                        value={firstName || ""}
                                        type="text"
                                        placeholder="First Name"
                                        maxLength={30}
                                        required
                                        onChange={(e) => {
                                            onNameChange(
                                                e.target.value,
                                                "firstName",
                                            );
                                        }}
                                    />
                                    <Input
                                        value={lastName || ""}
                                        type="text"
                                        placeholder="Last Name"
                                        maxLength={30}
                                        required
                                        onChange={(e) => {
                                            onNameChange(
                                                e.target.value,
                                                "lastName",
                                            );
                                        }}
                                    />
                                </div>
                                {nameError && (
                                    <div className="text-sm text-alert">
                                        {nameError}
                                    </div>
                                )}
                                <NumberFormat
                                    type="tel"
                                    className="bg-tertiary rounded-lg h-12 px-2"
                                    format="+# (###) ###-####"
                                    value={user?.number}
                                    placeholder="Phone Number"
                                    disabled
                                />
                            </div>
                        )}
                    </div>
                    {!fromCustomApp && (
                        <div className="flex flex-col pb-1">
                            <Button
                                className="w-[100px]"
                                onPress={() => {
                                    dispatch(logOut(true));
                                }}
                                label="Sign Out"
                                variant="outline"
                            />
                        </div>
                    )}
                </div>

                <MarketingConsent />

                <Divider width={1} className="pt-4" />
            </div>
        </>
    );
};

const NotSignedInContent = () => {
    return (
        <>
            <div className="hidden md:block ml-6">
                <Divider width={1} />
            </div>
            <div className="px-4 md:px-6 w-full flex flex-col flex-1 pt-6 pb-2">
                <div className="text-2xl font-semibold text-primary">
                    Enter your phone number to checkout
                </div>
                <span className="text-base text-secondary leading-6 py-1">
                    You’ll also be able to access saved payments or rewards
                </span>
                <PhoneVerification handleHide={noop} />
            </div>
        </>
    );
};

export default PaymentSection;
