import { DateTime } from "luxon";

import { containsTime } from "./containsTime";
import { FromLegacyHours, fromLegacy } from "./legacy";

/**
 * Returns whether the Product is available at the provided date-time in unix time (timeMs).
 * By default, timeMs is now in Store's timezone.
 */
export const isProductAvailable =
    (hours: FromLegacyHours) =>
    (timeMs = DateTime.now().toMillis()): boolean =>
        containsTime(fromLegacy(hours))(timeMs);
