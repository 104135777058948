import { selectProducts } from "#menu";
import { CartSelectors, itemIsSoldOut } from "@snackpass/accounting";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useCheckIsCartItemsSoldOut() {
    const products = useSelector(selectProducts);
    const cartItems = useSelector(CartSelectors.getCartItems);

    const hasSoldOutProducts = useMemo(() => {
        return cartItems.some((item) => {
            const product = products.find((p) => p._id === item.product._id);
            return product && itemIsSoldOut(product);
        });
    }, [cartItems, products]);

    return hasSoldOutProducts;
}
