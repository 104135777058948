import React from "react";
import { Helpers } from "../../utils";
import { PurchaseItemGrouping } from "src/utils/Types";
import { formatQuantity } from "@snackpass/accounting";

type AddonsProps = {
    item: PurchaseItemGrouping;
};

const Addons: React.FC<AddonsProps> = ({ item }) => {
    const firstItem = item.items[0];

    if (!firstItem.selectedAddons) {
        return null;
    }

    const addons = Helpers.objToArr(firstItem.selectedAddons);

    return (
        <div className="self-stretch justify-between items-start inline-flex flex-col">
            {addons.map((addon: any, i: number) => {
                let Price;
                let addonHasDiscount = addon.price !== addon.priceAfterDiscount;

                if (addon.price > 0) {
                    Price = (
                        <div className="text-zinc-600 text-sm font-normal leading-[18px] flex pl-2">
                            <div>{`(`}</div>
                            <div
                                className={`${
                                    addonHasDiscount && "line-through"
                                }`}
                            >
                                {`${Helpers.toDollar(
                                    addon.price * (addon.quantity || 1)
                                )}`}
                            </div>
                            {addonHasDiscount ? (
                                <div>
                                    {`  ${Helpers.toDollar(
                                        addon.priceAfterDiscount *
                                            (addon.quantity || 1)
                                    )}`}
                                </div>
                            ) : null}
                            <div>{`)`}</div>
                        </div>
                    );
                }

                return (
                    <div
                        className="self-stretch justify-start items-center flex-wrap inline-flex"
                        key={addon.name}
                    >
                        <div className="text-zinc-600 text-base font-normal leading-normal">
                            {formatQuantity(addon.quantity)} {addon.name}
                        </div>
                        {Price}
                    </div>
                );
            })}

            <div />
        </div>
    );
};

export default Addons;
