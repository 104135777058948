import { Tooltip } from "antd";
import {
    CartActions,
    CartSelectors,
    CheckoutSelectors,
    descriptionForFee,
    labelForFee,
    toDollar,
} from "@snackpass/accounting";
import { SystemColors } from "@snackpass/design-system";
import { FeePolicyWithTotal } from "@snackpass/snackpass-types";
import { useDispatch, useSelector } from "react-redux";
import { GreenIOSSwitch } from "src/components/GreenIOSSwitch";
import { getUserIsLoggedIn } from "src/redux";
import { Text } from "../../SharedComponents";
import { Colors, Helpers } from "../../utils";
import InfoRow from "./InfoRow";
import InfoIcon from "src/assets/icons/info.png";

type FeeRowProps = {
    label: string;
    description?: string;
    amountDollars: number;
    isLast?: boolean;
};

const FeeRow = ({ label, description, amountDollars, isLast }: FeeRowProps) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: isLast ? 0 : 4,
        }}
    >
        <Text small color={SystemColors.white}>
            {label}: {toDollar(amountDollars)}
        </Text>
        {description ? (
            <Text xsmall color={SystemColors.white}>
                {description}
            </Text>
        ) : null}
    </div>
);

type FeesBreakdownProps = {
    fees: FeePolicyWithTotal[];
    numberOfBags: number;
    serviceFee?: number;
    tax: number;
};

const FeesBreakdown = ({
    fees,
    numberOfBags,
    serviceFee,
    tax,
}: FeesBreakdownProps) => (
    <>
        {fees
            .filter((fee) => fee.total)
            .map((fee, i) => (
                <FeeRow
                    label={labelForFee(fee.fee.name)}
                    description={descriptionForFee(fee, numberOfBags)}
                    amountDollars={fee.total}
                    key={i}
                />
            ))}
        {serviceFee ? (
            <FeeRow
                label="Service Fee"
                description="Set by our restaurant to cover operational costs."
                amountDollars={serviceFee}
            />
        ) : null}
        <FeeRow label="Tax" amountDollars={tax} isLast />
    </>
);

const Summary = () => {
    const dispatch = useDispatch();
    const subtotal = useSelector(CheckoutSelectors.getSubtotal);
    const numberOfBags = useSelector(CartSelectors.getNumberOfBags);
    const deliveryFee = useSelector(CheckoutSelectors.getDeliveryFeeApplied);
    const tip = useSelector(CheckoutSelectors.getTip);
    const isLoggedIn = useSelector(getUserIsLoggedIn);
    const useCredit = useSelector(CheckoutSelectors.getUseCredit);
    const hasCreditAvailable = useSelector(
        CheckoutSelectors.getHasCreditAvailable
    );
    const storeCredit = useSelector(CheckoutSelectors.getStoreCredit);
    const giftCardCreditApplied = useSelector(
        CheckoutSelectors.getGiftCardCredit
    );
    const globalCredit = useSelector(CheckoutSelectors.getGlobalCredit);

    const hasSpecialCredit = hasCreditAvailable;

    // Taxes and Fees

    // Tax
    const tax = useSelector(CheckoutSelectors.getTax);
    const effectiveTax = tax;

    // Service Fee (fka Convenience Fee/Card Fee)
    const serviceFee = useSelector(CheckoutSelectors.getStoreConvenienceFee);
    const effectiveServiceFee = serviceFee;

    // Custom Fees
    const fees = useSelector(CheckoutSelectors.getCustomerFeeBreakdown);
    const effectiveFees = fees;

    const customerFeeTotal = useSelector(CheckoutSelectors.getCustomerFeeTotal);
    const effectiveCustomerFeeTotal = customerFeeTotal;

    const taxesAndFees =
        effectiveServiceFee + effectiveTax + effectiveCustomerFeeTotal;

    // End Taxes and Fees

    const handleCheck = (e) => {
        dispatch({
            type: CartActions.SET_USE_CREDIT,
            useCredit: e.target.checked,
        });
    };

    return (
        <div>
            <InfoRow
                middleComponent={<Text small>Subtotal</Text>}
                rightComponent={<Text small>{Helpers.toDollar(subtotal)}</Text>}
                style={{ padding: "4px 0", borderBottom: "none" }}
            />
            {deliveryFee > 0 && (
                <InfoRow
                    middleComponent={<Text small>Delivery Fee</Text>}
                    rightComponent={
                        <Text small>{Helpers.toDollar(deliveryFee)}</Text>
                    }
                    style={{
                        borderBottom: "none",
                        padding: "4px 0",
                    }}
                />
            )}
            <InfoRow
                middleComponent={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                        }}
                    >
                        <Tooltip
                            trigger="hover"
                            style={{ backgroundColor: "black" }}
                            overlay={
                                <FeesBreakdown
                                    fees={effectiveFees}
                                    numberOfBags={numberOfBags}
                                    tax={effectiveTax}
                                    serviceFee={effectiveServiceFee}
                                />
                            }
                        >
                            <div className="flex cursor-pointer flex-row items-center">
                                <Text
                                    small
                                    style={{
                                        paddingRight: 5,
                                    }}
                                >
                                    {serviceFee > 0 ||
                                    effectiveCustomerFeeTotal > 0
                                        ? "Taxes & Fees"
                                        : "Taxes"}
                                </Text>
                                <img
                                    alt="info-icon"
                                    src={InfoIcon}
                                    style={{
                                        objectFit: "contain",
                                        height: 12,
                                        width: 12,
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </div>
                }
                rightComponent={
                    <Text small>{Helpers.toDollar(taxesAndFees)}</Text>
                }
                style={{
                    borderBottom: "none",
                    padding: "4px 0",
                }}
            />
            <InfoRow
                middleComponent={<Text small>Tip</Text>}
                rightComponent={<Text small>{Helpers.toDollar(tip)}</Text>}
                style={{ borderBottom: "none", padding: "4px 0" }}
            />
            {isLoggedIn && hasSpecialCredit ? (
                <>
                    <InfoRow
                        middleComponent={<Text small>Use Credit</Text>}
                        rightComponent={
                            <GreenIOSSwitch
                                checked={useCredit}
                                onChange={handleCheck}
                            />
                        }
                        style={{
                            borderBottom: "none",
                            padding: "4px 0",
                        }}
                    />
                    {giftCardCreditApplied !== 0 ? (
                        <InfoRow
                            middleComponent={
                                <Text
                                    small
                                    style={{
                                        color: Colors.green,
                                    }}
                                >
                                    Gift Card
                                </Text>
                            }
                            rightComponent={
                                <Text
                                    small
                                    style={{
                                        color: Colors.green,
                                    }}
                                >
                                    {toDollar(giftCardCreditApplied)}
                                </Text>
                            }
                            style={{
                                borderBottom: "none",
                                padding: "4px 0",
                            }}
                        />
                    ) : null}
                    {storeCredit !== 0 ? (
                        <InfoRow
                            middleComponent={
                                <Text
                                    small
                                    style={{
                                        color: Colors.green,
                                    }}
                                >
                                    Store Credit
                                </Text>
                            }
                            rightComponent={
                                <Text
                                    small
                                    style={{
                                        color: Colors.green,
                                    }}
                                >
                                    {toDollar(storeCredit)}
                                </Text>
                            }
                            style={{
                                borderBottom: "none",
                                padding: "4px 0",
                            }}
                        />
                    ) : null}
                    {globalCredit !== 0 ? (
                        <InfoRow
                            middleComponent={
                                <Text
                                    small
                                    style={{
                                        color: Colors.green,
                                    }}
                                >
                                    Global Credit
                                </Text>
                            }
                            rightComponent={
                                <Text
                                    small
                                    style={{
                                        color: Colors.green,
                                    }}
                                >
                                    {toDollar(globalCredit)}
                                </Text>
                            }
                            style={{
                                borderBottom: "none",
                                padding: "4px 0",
                            }}
                        />
                    ) : null}
                </>
            ) : null}
        </div>
    );
};

export default Summary;
