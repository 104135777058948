import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IProduct } from "@snackpass/snackpass-types";
import {
    ActiveCartItemActions,
    DealSelectionActions,
} from "@snackpass/accounting";
import {
    ModalsName,
    getActiveDealItemsMap,
    getActivePromotion,
    getActiveStore,
    getDealModalActiveTabIndex,
    setDealModalActiveTabIndex,
    showModal,
} from "@/redux";
import { usePromoItemProductsAndImages } from "@/hooks/usePromoItemProductsAndImages";
import { ProductList } from "./ProductList";
import { Tabs, TabsContent, TabsList } from "@/components/ui/tabs";
import DealItemLabel from "./DealItemLabel";
import { getDealSelection } from "@/redux/deal";
import { usePointsAfterPromotion } from "@/hooks/usePointsAfterPromotion";

const ModalContent = () => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const activePromotion = useSelector(getActivePromotion);
    const { noProductsAvailable } =
        usePromoItemProductsAndImages(activePromotion);
    const activeDealItem = useSelector(getDealSelection).activeDealItem;

    const dealModalActiveTabIndex = useSelector(getDealModalActiveTabIndex);
    const activeDealItemsMap = useSelector(getActiveDealItemsMap);
    const activeTab = activePromotion?.dealItems
        ? activePromotion?.dealItems[dealModalActiveTabIndex]?._id
        : "";
    const handleDealModalActiveTabChange = (activeTabId: string) => {
        const index = activeDealItemsMap[activeTabId];
        dispatch(setDealModalActiveTabIndex(index));
        dispatch(
            DealSelectionActions.setActiveDealItem(
                activePromotion?.dealItems[index],
            ),
        );
    };

    useEffect(() => {
        dispatch(
            DealSelectionActions.setActiveDealItem(
                activePromotion?.dealItems[dealModalActiveTabIndex],
            ),
        );
    }, [activePromotion?.dealItems, dealModalActiveTabIndex, dispatch]);

    const getPointsAfterPromotion = usePointsAfterPromotion();

    const handlePressProduct = (product: IProduct) => {
        dispatch(
            ActiveCartItemActions.setActiveCartItem(
                product,
                activePromotion ?? undefined,
                undefined,
                activeDealItem ?? undefined,
                getPointsAfterPromotion(product),
            ),
        );
        dispatch(showModal(ModalsName.productModal));
    };

    if (!activeStore || !activePromotion || !activeDealItem) {
        return null;
    }

    if (noProductsAvailable) {
        return (
            <div className="flex flex-col px-4 items-center">
                No menu item available right now
            </div>
        );
    }

    return (
        <div>
            <Tabs
                className="w-[100%] overflow-hidden"
                value={activeTab}
                onValueChange={handleDealModalActiveTabChange}
            >
                <TabsList className="flex-1 w-[100%] px-3 bg-white mb-4 overflow-scroll [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden justify-normal ">
                    {activePromotion.dealItems.map((deal) => {
                        return <DealItemLabel dealItem={deal} key={deal._id} />;
                    })}
                </TabsList>
                {activePromotion.dealItems.map((deal, i) => {
                    return (
                        <TabsContent className="mx-4" value={deal._id} key={i}>
                            <ProductList
                                dealItem={deal}
                                promotion={activePromotion}
                                handlePressProduct={handlePressProduct}
                            />
                        </TabsContent>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default ModalContent;
