import Dinero from "dinero.js";

export const D = (cents: number): Dinero.Dinero =>
    Dinero({ amount: Math.round(cents) });

export const DD = (dollars: number): Dinero.Dinero =>
    Dinero({ amount: Math.round(dollars * 100) });

export const convertToDinero = (dollars: number): Dinero.Dinero =>
    Dinero({ amount: Math.round(dollars * 100) });
