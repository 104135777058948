import React from "react";
import moment from "moment-timezone";
import { IStore } from "@snackpass/snackpass-types";
import { RNFirebase } from "react-native-firebase";
import firebase from "firebase";

import * as ScheduleUtils from "./utils";

type FirebaseDbReference = ReturnType<
    ReturnType<typeof firebase.database>["ref"]
>;
//TODO: Change this hook using luxon
export const useTimeSlots = ({
    store,
    date,
    catering
}: {
    store: IStore | null;
    date: moment.Moment | null;
    catering: boolean;
}): ScheduleUtils.TimeSlot[] => {
    const [timeSlots, setTimeSlots] = React.useState<ScheduleUtils.TimeSlot[]>(
        []
    );

    React.useEffect(() => {
        if (!store || !date) {
            return;
        }

        setTimeSlots(
            ScheduleUtils.computeAllTimeSlots(
                date,
                store,
                ScheduleUtils.getRangesForDay(date.clone(), store),
                catering
            )
        );
    }, [date ? date.toString() : null, store ? store._id : null]);

    return timeSlots;
};

export const useBatchListener = ({
    dbRef,
    store,
    date,
    timeSlots,
    hasBatching
}: {
    dbRef: FirebaseDbReference | RNFirebase.database.Reference;
    store: IStore | null;
    date: moment.Moment | null;
    timeSlots: ScheduleUtils.TimeSlot[];
    hasBatching: boolean;
}): {
    batchMapping: ScheduleUtils.BatchMapping;
    listening: moment.Moment | null;
} => {
    const [dateToListenTo, setDateToListenTo] =
        React.useState<moment.Moment | null>(null);
    const [batchMapping, setBatchMapping] =
        React.useState<ScheduleUtils.BatchMapping>({});

    React.useEffect(() => {
        if (!store || !date || !hasBatching || !timeSlots.length) {
            return;
        }

        const batchDate = moment(date).tz(store.hours.zone);
        const batchDateFormatted = batchDate.format("YYYY-MM-DD");

        const ref = dbRef
            .child("batchesByScheduledTime")
            .child(store._id)
            .child(batchDateFormatted);

        setDateToListenTo(batchDate);

        ref.on("value", (snap: any) => {
            setBatchMapping(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                ScheduleUtils.generateBatchMapping(snap.val(), timeSlots)
            );
        });

        return () => {
            setDateToListenTo(null);
            ref.off();
        };
    }, [
        store ? store._id : null,
        date ? date.toString() : null,
        hasBatching,
        JSON.stringify(timeSlots)
    ]);

    return { batchMapping, listening: dateToListenTo };
};
