import { Api } from "src/utils";

import { setUser } from "../user";
import { setSiftForUser } from "src/utils/Sift";
import { sendError } from "src/utils/Errors";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { setUserFirstName, setUserLastName } from "../userName";

export function fetchUser() {
    return async (dispatch: ThunkDispatch<any, any, any>) => {
        try {
            const response = await Api.users.get();
            const user = response.data.user;
            dispatch(setUser(user));
            dispatch(setUserFirstName(user?.firstName));
            dispatch(setUserLastName(user?.lastName));
            setSiftForUser(user._id);
        } catch (err) {
            sendError(err);
        }
    };
}
