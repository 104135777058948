import { Geolocation } from "@snackpass/snackpass-types";
import React from "react";
import GoogleMap from "google-map-react";
import * as MapUtils from "./MapUtils";

const DEFAULT_CENTER = {
    lat: 43.702146,
    lng: -72.28969800000004,
};

const DEFAULT_ZOOM = 13;

type Props = {
    center?: Geolocation;
    defaultCenter?: Geolocation;
    defaultZoom?: number;
    children: any;
};

export const Map = ({
    children,
    center,
    defaultCenter,
    defaultZoom,
}: Props) => {
    const centerCoords = center && MapUtils.getCoordsFromGeolocation(center);
    const defaultCenterCoords =
        defaultCenter && MapUtils.getCoordsFromGeolocation(defaultCenter);
    return (
        <GoogleMap
            bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY ?? "",
            }}
            options={{ clickableIcons: false, gestureHandling: "greedy" }}
            center={centerCoords}
            defaultCenter={defaultCenterCoords || DEFAULT_CENTER}
            defaultZoom={defaultZoom || DEFAULT_ZOOM}
        >
            {children}
        </GoogleMap>
    );
};
