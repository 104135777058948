import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import {
    ModalsName,
    getActivePromotion,
    getIsDealModalOpen,
    hideModal,
    setActiveDealItemsMap,
    setActivePromotion,
    setDealModalActiveTabIndex,
} from "@/redux";
import ModalContent from "./Content";
import { useMediaQuery } from "react-responsive";

import { AddDealToCartButton } from "./AddDealToCartButton";
import { DealSelectionActions } from "@snackpass/accounting";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const DealModal = () => {
    const dispatch = useDispatch();
    const activePromotion = useSelector(getActivePromotion);
    const dealModal = useSelector(getIsDealModalOpen);

    const onHide = () => {
        dispatch(hideModal(ModalsName.dealModal));
        dispatch(DealSelectionActions.clearDealItems());
        dispatch(setActivePromotion(null));
        dispatch(setActiveDealItemsMap(null));
        dispatch(setDealModalActiveTabIndex(0));
    };

    const isDesktop = useMediaQuery({ minWidth: 768 });

    if (isDesktop) {
        return (
            <Dialog open={dealModal} onOpenChange={onHide}>
                <DialogContent
                    onEscapeKeyDown={onHide}
                    className="h-[100%] md:max-h-[700px]"
                >
                    <div className="flex flex-col overflow-y-auto h-full relative w-full hidden-scroll">
                        <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    {activePromotion?.name}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 mb-20">
                            <ModalContent />
                        </div>
                        <DialogFooter className="w-[100%] border-t bg-white md:px-6">
                            <AddDealToCartButton promotion={activePromotion} />
                        </DialogFooter>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
    return (
        <Sheet open={dealModal} onOpenChange={onHide}>
            <SheetContent className="pb-20">
                <SheetHeader>
                    <SheetTitle> {activePromotion?.name}</SheetTitle>
                </SheetHeader>
                <div className="mt-2 overflow-auto">
                    <ModalContent />
                </div>
                <SheetFooter className="gap-2 border-t py-4 px-6">
                    <SheetClose asChild>
                        <AddDealToCartButton promotion={activePromotion} />
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default DealModal;
