import React from "react";
import { optimizedImageURL } from "@/utils/Helpers";

type Props = {
    size: "m" | "l";
    imageURL: string;
};

function GiftCardDesignURL({ size, imageURL }: Props) {
    // ratio is fairly close to 8:5, but precisely is 1.645833
    const height = size === "m" ? 84 : 200;
    const width = size === "m" ? 138 : 329.166;
    return (
        <div
            className="flex flex-col rounded-[12px]"
            style={{
                backgroundImage: `url("${optimizedImageURL(imageURL, {
                    size: { h: height, w: width },
                })}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: height,
                width: width,
            }}
        />
    );
}

export default GiftCardDesignURL;
