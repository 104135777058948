import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartActions, CartSelectors } from "@snackpass/accounting";
import { getActiveStore } from "src/redux";
import { TextInput } from "src/SharedComponents";
import { ReactComponent as TableIcon } from "src/assets/icons/table.svg";

const TableNumber = () => {
    const dispatch = useDispatch();
    const cartTableNumber = useSelector(CartSelectors.getCartTableNumber);
    const activeStore = useSelector(getActiveStore);
    const tableNumber = cartTableNumber;

    const setCartTableNumber = (value) =>
        dispatch(CartActions.setCartTableNumber(value));

    return (
        <div className="mb-3">
            <div className="flex flex-row flex-1">
                <TableIcon className="w-[20px] h-[20px] fill-secondary mr-3" />
                <span className="text-base leading-6 text-primary font-medium">
                    Table Number
                </span>
            </div>
            <div>
                <TextInput
                    type="text"
                    placeholder="Number"
                    spellCheck={false}
                    onChangeText={setCartTableNumber}
                    value={tableNumber || ""} // Provide an initial value or an empty string
                    classNames="bg-none text-base w-full border rounded-lg leading-6 p-3"
                />
                {!tableNumber &&
                    activeStore?.dineInPreferences?.requireTableNumber && (
                        <div className="text-alert mb-3">
                            Table number is required
                        </div>
                    )}
            </div>
        </div>
    );
};

export default TableNumber;
