import React from "react";
import { ModalsName, hideModal } from "@/redux";
import { useDispatch } from "react-redux";
import LoginByPhone from "./LoginByPhone";
import { Page } from "./lib";

type Props = {
    page: Page;
    handleTogglePage: (page: Page) => void;
    setModalTitle: React.Dispatch<React.SetStateAction<string>>;
};

const ModalContent = ({ page, handleTogglePage, setModalTitle }: Props) => {
    const dispatch = useDispatch();
    const handleHideAuthenticationModal = () => {
        dispatch(hideModal(ModalsName.authenticationModal));
    };

    const onHide = () => {
        handleHideAuthenticationModal();
        handleTogglePage("LOGIN");
    };

    if (page === "LOGIN") {
        return (
            <LoginByPhone onSuccess={onHide} setModalTitle={setModalTitle} />
        );
    }
};

export default ModalContent;
