import GiftCardDesignURL from "@/screens/GiftCardViewScreen/components/GiftCardDesignURL";
import { cn } from "@/utils";
import React from "react";

type Props = {
    designURLs: string[];
    value?: string;
    onChange: (value: string) => void;
};

export function DigitalGiftCardDesigns({ designURLs, value, onChange }: Props) {
    const selectedDesign = value;
    return (
        <div className="flex flex-row flex-wrap gap-2.5">
            {designURLs.map((designURL) => (
                <div
                    key={designURL}
                    className={cn(
                        "cursor-pointer rounded-[16px] p-1",
                        selectedDesign === designURL
                            ? "border-2 border-blue-500 p-0.5"
                            : "",
                    )}
                    onClick={() => onChange?.(designURL)}
                >
                    <GiftCardDesignURL size="m" imageURL={designURL} />
                </div>
            ))}
        </div>
    );
}
