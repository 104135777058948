import { CSSProperties } from "react";
import React from "react";
import { Text, View } from "src/SharedComponents";
import { Container } from "@material-ui/core";
import { GiftCardStateType, StepMoment } from "../lib";
import { history } from "src/utils/history";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStore, logOut } from "src/redux";
import X_ICON_IMAGE from "src/assets/icons/xIcon.svg";
import LEFT_ARROW_IMAGE from "src/assets/icons/chevron_left.svg";

type GiftCardHeaderProps = {
    step: StepMoment;
    setStep: React.Dispatch<React.SetStateAction<StepMoment>>;
    giftCardState: GiftCardStateType;
};

const SEND_GIFTCARD = "Send a Digital Gift Card";
const CONFIRM_ORDER = "Confirm order";

export const GiftCardHeader = ({
    step,
    setStep,
    giftCardState,
}: GiftCardHeaderProps) => {
    const dispatch = useDispatch();
    const textValue =
        step === StepMoment.fillingForm ? SEND_GIFTCARD : CONFIRM_ORDER;
    const imgSrc =
        step === StepMoment.fillingForm ? X_ICON_IMAGE : LEFT_ARROW_IMAGE;

    const store = useSelector(getActiveStore);

    if (!store) return null;

    const GoBack = () => {
        switch (step) {
            case StepMoment.fillingForm:
                giftCardState.isKioskPurchase
                    ? dispatch(logOut())
                    : history.replace(`/${store.slug}`);
                break;
            case StepMoment.checkout:
                setStep(StepMoment.fillingForm);
                break;
        }
    };

    return (
        <Container style={{ maxWidth: "100%", padding: "2%" }}>
            <View style={styles.container}>
                <img
                    alt="Close"
                    style={styles.imgIcon}
                    src={imgSrc}
                    onClick={GoBack}
                />

                <Text style={styles.textTitle} large>
                    {textValue}
                </Text>
            </View>
            <View style={styles.container}>
                <Text style={styles.textDefault}>{store.name}</Text>
            </View>
        </Container>
    );
};

const styles = {
    textTitle: {
        fontStyle: "normal",
        fontSize: "20px",
        fontWeight: 700,
        color: "#282D32",
        textAlign: "center",
        width: "100%",
    } as CSSProperties,

    textDefault: {
        fontStyle: "normal",
        fontSize: 18,
        fontWeight: 400,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        color: "#606C76",
        padding: "2%",
    } as CSSProperties,

    selector: {
        width: "100%",
        zIndex: 10,
        position: "static",
    } as CSSProperties,

    imgIcon: {
        width: 20,
        height: "auto",
        cursor: "pointer",
    } as CSSProperties,

    container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties,
};
