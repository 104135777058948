import React, { CSSProperties } from "react";
import { Styles } from "src/utils";

type InfoRowProps = {
    iconPath?: any;
    style?: CSSProperties;
    iconStyle?: CSSProperties;
    iconContainerStyle?: CSSProperties;
    middleComponent?: JSX.Element;
    rightComponent?: JSX.Element;
    bottomComponent?: JSX.Element;
    onClick?: () => void;
};

export const InfoRow = ({
    iconPath,
    style,
    iconStyle,
    iconContainerStyle,
    middleComponent,
    rightComponent,
    bottomComponent,
    onClick,
}: InfoRowProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                cursor: onClick ? "pointer" : "inherit",
                padding: "10px 0",
                ...style,
            }}
            onClick={onClick}
        >
            <div
                style={{
                    display: "flex",
                    ...Styles.flexRow,
                    ...style,
                }}
            >
                {iconPath && (
                    <div
                        style={{
                            margin: "0 15px 0 20px",
                            ...iconContainerStyle,
                        }}
                    >
                        <img
                            style={{
                                width: 18,
                                height: "auto",
                                ...iconStyle,
                            }}
                            alt={iconPath}
                            src={iconPath}
                        />
                    </div>
                )}

                <div style={{ flex: 1 }}>{middleComponent}</div>

                <div style={{ paddingRight: 20 }}>{rightComponent}</div>
            </div>
            {/* 53px is 20 (margin left) + 18 (icon) + 15 (margin right) */}
            {bottomComponent && (
                <div style={{ margin: "4px 0px 0px 53px" }}>
                    {bottomComponent}
                </div>
            )}
        </div>
    );
};
