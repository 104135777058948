import { Action } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "typed-redux-saga";

import { CartActions } from "@snackpass/accounting";

import { ModalsName, hideModal, showModal } from "@/redux";

import { selectUseMulMenu } from "#activeStore";
import { CartConflict, clearConflicts, setConflicts } from "#cart/slice";
import { setMenu } from "#menu";

import { legacyCartValidationSaga } from "./legacyCartValidationSaga";
import { simpleCartValidationSaga } from "./simpleCartValidationSaga";

export function* cartValidationSaga() {
    yield* takeEvery(isCartValidationAction, function* (action) {
        let conflicts: CartConflict[] = [];
        // Only validate the cart on set menu action when using mul menus.
        if (setMenu.match(action)) {
            const usingMulMenu = yield* select(selectUseMulMenu);

            if (usingMulMenu) {
                conflicts = yield* call(
                    simpleCartValidationSaga,
                    action.payload.products,
                );
            }
        } else {
            // Legacy cart validation runs when fulfillment changes.
            conflicts = yield* call(
                legacyCartValidationSaga,
                action.fulfillment,
            );

            //   Clear conflict state if there is no fulfillment conflicts.
            if (!conflicts.length) {
                yield* put(clearConflicts());
                yield* put(hideModal(ModalsName.cartConflictsModal));
            }
        }
        if (conflicts.length) {
            // Set conflicts and flag to surface modal.
            yield* put(setConflicts(conflicts));
            yield* put(showModal(ModalsName.cartConflictsModal));
        }
    });
}

type SetMenu = ReturnType<typeof setMenu>;

const isCartValidationAction = (
    action: Action,
): action is CartActions.SetCartFulfillment | SetMenu =>
    action.type === CartActions.SET_CART_FULFILLMENT || setMenu.match(action);
