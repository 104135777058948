import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { CartSelectors } from "@snackpass/accounting";
import { LegacyMenu } from "@snackpass/menus-sdk";
import { IProduct, IProductCategory } from "@snackpass/snackpass-types";
import { keyBy } from "lodash";

interface MenuState {
    products: IProduct[];
    categories: IProductCategory[];
    cateringCategories: IProductCategory[];
}

const initialState: MenuState = {
    products: [],
    categories: [],
    cateringCategories: [],
};

/**
 * The menu slice contains all the data needed to render the menu.
 */
export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        setMenu: (state, action: PayloadAction<LegacyMenu>) => {
            state.products = action.payload.products;
            state.categories = action.payload.categories;
            state.cateringCategories = action.payload.cateringCategories;
        },
    },
});

const selectMenuState = (state: { menu: MenuState }) => state.menu;

/**
 * Selects all products with addon groups and addons populated.
 */
export const selectProducts = createSelector(
    [selectMenuState],
    (state) => state.products,
);

/**
 * Selects a dictionary of products with addon groups and addons populated by their ID.
 */
export const selectProductDictionary = createSelector(
    [selectProducts],
    (products) => keyBy(products, "_id"),
);

/**
 * Selects all categories.
 */
export const selectCategories = createSelector(
    [selectMenuState],
    (state) => state.categories,
);

/**
 * Selects all catering categories.
 */
export const selectCateringCategories = createSelector(
    [selectMenuState],
    (state) => state.cateringCategories,
);

/**
 * Selects categories for the current channel (i.e. Catering or OO).
 */
export const selectCategoriesByChannel = createSelector(
    [
        CartSelectors.getCartIsCatering,
        selectCategories,
        selectCateringCategories,
    ],
    (isCatering, categories, cateringCategories) =>
        isCatering ? cateringCategories : categories,
);

/**
 * Selects categories with products populated.
 */
export const selectPopulatedCategories = createSelector(
    [selectCategoriesByChannel, selectProductDictionary],
    (categories, productDict) => {
        return categories.map((category) => ({
            ...category,
            products: category.productIds.map((id) => productDict[id]),
        }));
    },
);

/**
 * Selects the top 5 most popular products by purchase count.
 */
export const selectPopularProducts = createSelector(
    [selectProducts, CartSelectors.getCartIsCatering],
    (products, isCatering) =>
        products
            .filter((product) => product.isCatering === isCatering)
            .sort((a, b) => (b.purchaseCount ?? 0) - (a.purchaseCount ?? 0))
            .slice(0, 5),
);

export const { setMenu } = menuSlice.actions;
