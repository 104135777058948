import {
    CSSVariableName,
    extractRGBValues,
    handleSetThemeColors,
} from "@/utils/Theme";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getActiveStore } from "@/redux";

export type Theme = {
    brand: {
        default: string;
    };
};

const themeInitialValue = {
    brand: {
        default: "0 153 255",
    },
};

function generateUITheme(baseColor: string): Theme {
    const brandColor = extractRGBValues(baseColor);
    return {
        brand: {
            default: brandColor,
        },
    };
}

export function useOOBrandTheme() {
    const [theme, setTheme] = useState(themeInitialValue);
    const activeStore = useSelector(getActiveStore);
    const [brandColor, setBrandColor] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!activeStore?.brandColor) {
            return;
        }
        setBrandColor(activeStore?.brandColor);
    }, [activeStore?.brandColor, brandColor]);

    useEffect(() => {
        if (brandColor) {
            setTheme(generateUITheme(brandColor));
        }
    }, [brandColor]);

    useEffect(() => {
        if (theme.brand.default) {
            handleSetThemeColors(
                CSSVariableName.brandColor,
                theme.brand.default,
            );
        }
    }, [theme.brand.default]);
}
