import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartItemWithTaxInfo } from "@snackpass/accounting";
import { Fulfillment } from "@snackpass/snackpass-types";

export type CartConflict = {
    /** The cart item in conflict. */
    item: CartItemWithTaxInfo;

    /** The index of the cart item in the cart. */
    cartIndex: number;

    /** The determined reason of the conflict. */
    reason:
        | {
              type: "fulfillment";
              fulfillment: Fulfillment;
          }
        | {
              type: "unavailable" | "stale";
          };
};

type CartState = {
    /**
     * List of conflicts with cart and current menu.
     */
    conflicts: CartConflict[];
};

const initialState: CartState = {
    conflicts: [],
};

/**
 * An OO specific cart slice for managing application state related to the cart.
 * NB: This is the intended successor to the old `ooCart` reducer.
 */
export const cartSlice = createSlice({
    // `v2` prefix can be removed once the old `ooCart`reducer is removed.
    name: "oo-cart-v2",
    initialState,
    reducers: {
        setConflicts: (state, action: PayloadAction<CartConflict[]>) => {
            state.conflicts = action.payload;
        },
        clearConflicts: (state) => {
            state.conflicts = [];
        },
    },
});

export const { setConflicts, clearConflicts } = cartSlice.actions;
