import fp from "lodash/fp";
import { DateTime } from "luxon";
import moment from "moment-timezone";

import { CartItemFlat, Time } from "@snackpass/accounting";
import {
    CartWhen,
    Fulfillment,
    FulfillmentTypeEnum,
    IHoursSchema,
    IStore,
    SnackpassTimezoneEnum,
    WhenTypeEnum,
} from "@snackpass/snackpass-types";
import {
    isProductAvailable,
    isStoreOpen,
    isStoreOpenForDelivery,
} from "@snackpass/time";

import { isSnackpassTimeEnabled } from "@/statsig/featureGates";
import { Constants, Helpers } from "@/utils";
import { formatLegacySpecialHours, resolveStoreHours } from "@/utils/Time";

export type ValidationErrorType =
    | "INVALID_FULFILLMENT"
    | "MIN_NOT_REACHED"
    | "STORE_CLOSED"
    | "STORE_OFFLINE"
    | "STORE_CLOSED_UNTIL"
    | "INVALID_PRODUCT_HOURS"
    | "CART_INVALID"
    | "TABLE_NUMBER_NOT_ENTERED"
    | "DELIVERY_TIP_NOT_SELECTED"
    | "DELIVERY_ADDRESS_MISSING"
    | "DELIVERY_OUT_OF_RANGE"
    | "DELIVERY_CLOSED"
    | "DELIVERY_ERROR"
    | "SCHEDULED_DATE_MISSING"
    | "SCHEDULED_DATE_IN_PAST"
    | "USER_NAME_IS_EMPTY";

/**
 * Check whether store is opened on scheduled date
 * @param hours
 * @param date The scheduled date in the local system's timezone
 */
const isOpenWithSpecialOnDate = (hours: IHoursSchema, date: Date) => {
    if (isSnackpassTimeEnabled()) {
        return isStoreOpen(formatLegacySpecialHours(hours))(date.getTime());
    }

    // transform date to use UTC timezone for store hour calculations
    const utcDate = DateTime.fromJSDate(date)
        .setZone(hours.zone)
        .setZone("utc", { keepLocalTime: true })
        .toJSDate();

    const resolvedHours = resolveStoreHours(hours, [utcDate]);
    return Time.isOpenOnDate(resolvedHours, date);
};

const isOpenWithSpecial = (store: IStore) => {
    if (isSnackpassTimeEnabled()) {
        return isStoreOpen(formatLegacySpecialHours(store.hours))();
    }

    const resolvedHours = resolveStoreHours(store.hours);
    return Time.isOpen({
        ...store,
        hours: resolvedHours,
    });
};

export const isDeliveryClosed = (
    isDeliveryOrder: boolean,
    isScheduledOrder: boolean,
    isCatering: boolean,
    activeStore: IStore,
    scheduledTime: Date | undefined | null,
) => {
    const isStoreOpenOnScheduledDate = isOpenWithSpecialOnDate(
        activeStore.hours,
        moment(scheduledTime).toDate(),
    );
    const isStoreOpenNow = isOpenWithSpecial(activeStore);
    const deliveryHours: false | IHoursSchema | undefined =
        (activeStore.hasSpecialDeliveryHours &&
            activeStore.specialDeliveryHours) ??
        undefined;

    const isStoreAvailableForDelivery = isSnackpassTimeEnabled()
        ? isStoreOpenForDelivery(
              activeStore.hours,
              deliveryHours,
          )(scheduledTime?.getTime())
        : Time.isOpenForDelivery(activeStore, scheduledTime);

    if (isDeliveryOrder) {
        // first check if current time or scheduled date is outside of store's special delivery hours
        if (!isStoreAvailableForDelivery) {
            return true;
        }
        // - If special delivery hour check passes, check if store is open at scheduled date (caterings are all scheduled orders)
        if (isCatering || isScheduledOrder) {
            // If it's a catering order or a scheduled non-catering order, check if store is open at scheduled date (Note: caterings are all scheduled orders)
            return !isStoreOpenOnScheduledDate;
        }
        // - Otherwise, check if the store is open
        return !isStoreOpenNow;
    }
};

type IsValidCartArgs = {
    fulfillment: Fulfillment;
    activeStore: IStore | null;
    items: CartItemFlat[];
    cartIsValidated: boolean;
    tipIsSelected: boolean;
    minIsReached: boolean;
    deliveryAddress: string;
    when: CartWhen | undefined;
    scheduledDate: Date | null | undefined;
    tableNumber: string | null | undefined;
    tableNumberRequired: boolean;
    canDeliver: boolean;
    deliveryError: string | null;
    pickupMin: number;
    deliveryMin: number;
    cateringMinIsReached: boolean;
    cateringMin: number;
    isCatering: boolean;
    activeStoreName: string;
    hasSoldOutItem: boolean;
    totalPaidByCustomer: number;
    firstName: string;
    lastName: string;
};

// FIXME: do NOT put anything in here that requires an authed user
// this needs to be refactored but basically there can be a race condition with this if the below
// conditions require auth (delivery-quotes issue used to exist here)
export const isValidCart = ({
    fulfillment,
    activeStore,
    items,
    cartIsValidated,
    minIsReached,
    deliveryAddress,
    when,
    scheduledDate,
    tableNumber,
    tableNumberRequired,
    canDeliver,
    deliveryError,
    pickupMin,
    deliveryMin,
    cateringMinIsReached,
    cateringMin,
    isCatering,
    activeStoreName,
    hasSoldOutItem,
    totalPaidByCustomer,
    firstName,
    lastName,
}: IsValidCartArgs): {
    isValid: boolean;
    type: ValidationErrorType | null;
    message: string | null;
    component?: JSX.Element;
} => {
    if (!activeStore) {
        return {
            isValid: false,
            type: "INVALID_FULFILLMENT",
            message: null,
        };
    }
    const isScheduledOrder =
        when === WhenTypeEnum.Later && scheduledDate != null;

    const timezone = activeStore.hours.zone as SnackpassTimezoneEnum;
    const validProductHours = hasValidProductHours(items, scheduledDate);
    const validFulfillment = isValidFulfillment(fulfillment, activeStore);
    const isStoreOpen = isScheduledOrder
        ? isOpenWithSpecialOnDate(
              activeStore.hours,
              moment(scheduledDate!).toDate(),
          )
        : isOpenWithSpecial(activeStore);

    const isClosedUntil = isScheduledOrder
        ? activeStore.closedUntil &&
          moment(scheduledDate!).isBefore(activeStore.closedUntil)
        : Time.storeIsClosedUntil(activeStore);
    const isDeliveryFulfillment = fulfillment === FulfillmentTypeEnum.Delivery;

    if (!firstName || !lastName) {
        return {
            isValid: false,
            type: "USER_NAME_IS_EMPTY",
            message: "Please add your first and last name",
        };
    }
    // This way it still allows $0 / free purchases
    if (
        totalPaidByCustomer &&
        totalPaidByCustomer < Constants.stripeChargeMin
    ) {
        return {
            isValid: false,
            type: "MIN_NOT_REACHED",
            message: "Cannot charge card if total is less than 50¢",
        };
    }

    if (!isStoreOpen) {
        return {
            isValid: false,
            type: "STORE_CLOSED",
            message: "Store Closed",
        };
    }

    if (!validFulfillment) {
        return {
            isValid: false,
            type: "INVALID_FULFILLMENT",
            message: null,
        };
    }

    if (hasSoldOutItem) {
        return {
            isValid: false,
            type: "CART_INVALID",
            message: "Some items in your cart are sold out",
        };
    }

    if (
        !isCatering &&
        !minIsReached &&
        fulfillment === FulfillmentTypeEnum.Pickup
    ) {
        return {
            isValid: false,
            type: "MIN_NOT_REACHED",
            message: `${Helpers.toDollar(
                pickupMin,
            )} pickup min for ${activeStoreName}`,
        };
    }

    if (
        !isCatering &&
        !minIsReached &&
        fulfillment === FulfillmentTypeEnum.Delivery
    ) {
        return {
            isValid: false,
            type: "MIN_NOT_REACHED",
            message: `${Helpers.toDollar(
                deliveryMin,
            )} delivery min for ${activeStoreName}`,
        };
    }

    if (isCatering && !cateringMinIsReached) {
        return {
            isValid: false,
            type: "MIN_NOT_REACHED",
            message: `${Helpers.toDollar(
                cateringMin,
            )} catering min for ${activeStoreName}`,
        };
    }

    if (isClosedUntil) {
        return {
            isValid: false,
            type: "STORE_CLOSED_UNTIL",
            message: "Store Currently Closed",
        };
    }

    if (!validProductHours) {
        return {
            isValid: false,
            type: "INVALID_PRODUCT_HOURS",
            message: "Invalid Products",
        };
    }

    if (!cartIsValidated) {
        return {
            isValid: false,
            type: "CART_INVALID",
            message: "Invalid Promotions",
        };
    }

    if (
        fulfillment === FulfillmentTypeEnum.DineIn &&
        tableNumberRequired &&
        !tableNumber
    ) {
        return {
            isValid: false,
            type: "TABLE_NUMBER_NOT_ENTERED",
            message: "Table Number Required",
        };
    }

    if (isDeliveryFulfillment && !deliveryAddress) {
        return {
            isValid: false,
            type: "DELIVERY_ADDRESS_MISSING",
            message: "Delivery Address Required",
        };
    }
    if (
        isDeliveryClosed(
            isDeliveryFulfillment,
            isScheduledOrder,
            isCatering,
            activeStore,
            scheduledDate,
        )
    ) {
        return {
            isValid: false,
            type: "DELIVERY_CLOSED",
            message: "Delivery Closed",
        };
    }

    if (isDeliveryFulfillment && !canDeliver) {
        return {
            isValid: false,
            type: "DELIVERY_ERROR",
            message:
                deliveryError ||
                "Sorry, you cannot order delivery at this time.",
        };
    }

    if (
        (when === WhenTypeEnum.Later && !scheduledDate) ||
        (!activeStore.pickup &&
            when === WhenTypeEnum.Now &&
            fulfillment === FulfillmentTypeEnum.Pickup)
    ) {
        return {
            isValid: false,
            type: "SCHEDULED_DATE_MISSING",
            message: "Scheduled Time Required",
        };
    }

    if (
        when === WhenTypeEnum.Later &&
        scheduledDate &&
        timezone &&
        // schedule date is in the past
        moment(scheduledDate)
            // @ts-ignore
            .tz(timezone)
            // @ts-ignore
            .isBefore(moment().tz(timezone))
    ) {
        return {
            isValid: false,
            type: "SCHEDULED_DATE_IN_PAST",
            message: "Scheduled Time Invalid",
        };
    }

    return {
        isValid: true,
        type: null,
        message: null,
    };
};

export const hasValidProductHours = (
    items: CartItemFlat[],
    scheduledDate?: Date | null,
) => {
    return items.reduce((acc, item) => {
        const product = item.product;

        if (!product.hours?.local?.length) {
            return acc && true;
        }

        // If cart.scheduleDate is null
        return (
            acc &&
            (isSnackpassTimeEnabled()
                ? isProductAvailable(product.hours)(scheduledDate?.getTime())
                : Time.isOpen(product, scheduledDate))
        );
    }, true);
};

export const isValidFulfillment = (
    fulfillment: Fulfillment,
    activeStore: IStore,
) => {
    switch (fulfillment) {
        case FulfillmentTypeEnum.Delivery:
            return activeStore.delivery;
        case FulfillmentTypeEnum.Pickup:
            return activeStore.pickup || activeStore.hasScheduledOrders;
        case FulfillmentTypeEnum.DineIn:
            return Boolean(fp.get("dineInPreferences.hasDineIn", activeStore));
        default:
            return false;
    }
};
