import React, { Component, CSSProperties } from "react";
import { Text } from "./Text";
import { View } from "./View";
import { Colors } from "src/utils";
import { SystemColors } from "@snackpass/design-system";
import { Check } from "lucide-react";

type Props = {
    isChecked: boolean;
    label?: string;
    checkedColor?: string;
    style?: CSSProperties;
    checkboxContainerStyle?: CSSProperties;
    onClick?: any;
};

export class CheckBox extends Component<Props> {
    render() {
        const {
            label,
            isChecked,
            style,
            onClick,
            checkboxContainerStyle,
            checkedColor = SystemColors.black,
        } = this.props;

        return (
            <div
                style={{
                    ...checkboxContainerStyle,
                    ...style,
                }}
                onClick={onClick}
            >
                <View style={{ position: "relative" }}>
                    <View
                        style={{
                            ...styles.checkBoxContainer,
                            borderColor: isChecked ? checkedColor : Colors.gray,
                        }}
                    >
                        <View
                            style={{
                                ...styles.insideCheck,
                                backgroundColor: isChecked
                                    ? checkedColor
                                    : "transparent",
                                visibility: isChecked ? "visible" : "hidden",
                            }}
                            className="duration-300"
                        >
                            <Check
                                size={13}
                                strokeWidth={5}
                                className={`${!isChecked && "invisible"}`}
                            />
                        </View>
                    </View>

                    {label && <Text style={styles.label}>{label}</Text>}
                </View>
            </div>
        );
    }
}

const styles = {
    checkBoxContainer: {
        borderWidth: 2,
        borderStyle: "solid",
        height: 23,
        width: 23,
        borderRadius: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    } as CSSProperties,
    insideCheck: {
        height: 21,
        width: 21,
        borderRadius: "100%",
        position: "absolute",
        right: -1,
        top: -1,
        justifyContent: "center",
        alignItems: "center",
    } as CSSProperties,
    label: {
        color: Colors.text,
        fontSize: 14,
        marginLeft: 41,
        fontWeight: 500,
        backgroundColor: "rgba(0,0,0,0)",
    } as CSSProperties,
};
