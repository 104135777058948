import { createSelector } from "reselect";
import { propOr, map } from "lodash/fp";
import { IPunchcard } from "@snackpass/snackpass-types";
import { getActiveStore, getPunchcards } from "./globals";
import { getMultiCartStores } from "./multiCartStores";

/* Normal Cart Punchcard Selectors */
export const getActivePunchcard = createSelector(
    [getActiveStore, getPunchcards],
    (activeStore, punchcards) => {
        if (!activeStore) {
            return null;
        }
        const storeId = activeStore._id;
        return punchcards.find((p) => p.store._id === storeId) || null;
    }
);
export const getStoreCreditAvailable = createSelector(
    [getActivePunchcard],
    (activePunchcard) => {
        return activePunchcard ? activePunchcard.credit : null;
    }
);

/* MultiCart Punchcard Selectors */
// same as getActivePunchcard, but returns a list of punchcards
// each for a different store
export const getAllActivePunchcards = createSelector(
    [getMultiCartStores, getPunchcards],
    (multiCartStores, punchcards): (IPunchcard | null)[] =>
        multiCartStores.map(
            (store) =>
                punchcards.find((p) => p.store._id === store?._id) || null
        )
);

// list of store credit available for each store
export const getAllStoreCreditAvailable = createSelector(
    [getAllActivePunchcards],
    map(propOr(null, "credit"))
);
