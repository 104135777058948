import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IProduct } from "@snackpass/snackpass-types";
import {
    ActiveCartItemActions,
    ActiveCartItemSelectors,
} from "@snackpass/accounting";
import {
    useActiveRewardPromo,
    useActiveReward,
} from "@snackpass/loyalty-react";

import { useActiveRewardCategoryProducts } from "@/hooks/useActiveRewardCategoryProducts";
import { CategoryProduct } from "@/utils/Types";
import ProductCard from "@/screens/StoreScreen/components/ProductCard";
import { ModalsName, getActiveStore, hideModal, showModal } from "@/redux";

const ModalContent = () => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);

    const activeRewardPromo = useActiveRewardPromo();
    const activeReward = useActiveReward();

    const selectedAddons =
        useSelector(ActiveCartItemSelectors.getSelectedAddons) || [];
    const { availableCategoryProducts, noProductsAvailable } =
        useActiveRewardCategoryProducts();
    const handlePressProduct = (product: IProduct) => {
        dispatch(
            ActiveCartItemActions.setActiveCartItem(
                product,
                activeRewardPromo ?? undefined,
                activeReward ?? undefined,
            ),
        );
        dispatch(hideModal(ModalsName.claimRewardModal));
        setTimeout(() => {
            dispatch(showModal(ModalsName.productModal));
        }, 250);
    };
    if (!activeStore || (!activeRewardPromo && !activeReward)) {
        return null;
    }

    if (noProductsAvailable) {
        return (
            <div className="flex flex-col px-4 items-center">
                No menu item available right now
            </div>
        );
    }

    return (
        <div className="flex flex-col px-4">
            {availableCategoryProducts.map((cp: CategoryProduct, i: number) => (
                <div key={`${cp.category}-${i}`}>
                    <div className="text-2xl text-primary font-bold py-4">
                        {cp.category}
                    </div>
                    <div className="flex flex-col gap-4">
                        {cp.products.map((p) => {
                            return (
                                <ProductCard
                                    showDescription={false}
                                    key={p._id}
                                    product={p}
                                    handlePressProduct={() =>
                                        handlePressProduct(p)
                                    }
                                    isPromoOrReward={true}
                                    selectedAddons={selectedAddons}
                                ></ProductCard>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ModalContent;
