import React, { CSSProperties } from "react";
import { View } from "src/SharedComponents";

interface Props {
    weight: number;
    unit: string;
    onChangeWeight: (weight: number) => void;
}

const WeightSelector = ({ weight, unit, onChangeWeight }: Props) => (
    <View style={styles.container}>
        <View style={styles.inputWrapper}>
            <input
                style={styles.input}
                value={weight}
                min={0}
                type="number"
                onChange={(e) => onChangeWeight(parseFloat(e.target.value))}
            />
            {unit}
        </View>
    </View>
);

const styles = {
    container: {
        display: "flex",
        flex: "1 1 50%",
        justifyContent: "start",
        flexDirection: "row",
    } as CSSProperties,
    inputWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
    } as CSSProperties,
    input: {
        flex: 1,
        cursor: "pointer",
        borderRadius: 10,
        padding: 5,
        width: 80,
        border: "1px solid #efefef",
        textAlign: "center",
    } as CSSProperties,
};

export default WeightSelector;
