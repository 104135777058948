export const DEFAULT_PICKUP_RANGE = 31; //miles

//Delivery ranges in miles if delivery storeObj does not have 'deliveryRanges' field
export const DEFAULT_DELIVERY_RANGES = [
    {
        start: 0,
        end: 1,
        deliveryFee: 0,
        deliveryMin: 8
    },
    {
        start: 1,
        end: 3,
        deliveryFee: 3.5,
        deliveryMin: 10
    },
    {
        start: 3,
        end: 5,
        deliveryFee: 5,
        deliveryMin: 8
    }
];

// in minutes

export const DEFAULT_BATCH_SIZE = 15;

export const DEFAULT_INTERVAL_TIME = 30;
