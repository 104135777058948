import { DateTime, WeekdayNumbers } from "luxon";
import {
    IDateTimeRangeSchema,
    IHoursSchema,
    IProduct,
    IPromotion,
    IStore,
    ITimeRangeSchema,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";
import { capitalize } from "./Helpers";
import padStart from "lodash/padStart";
import _ from "lodash";

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const DAYS_OF_WEEK = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

export const momentFormat1 = "ddd M/D @ h:mma";

// return whether the hours is open
// accounts for daylight savings
export const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
export const TIME_RANGE_DAYS = [
    60 * 24 * 0,
    60 * 24 * 1,
    60 * 24 * 2,
    60 * 24 * 3,
    60 * 24 * 4,
    60 * 24 * 5,
    60 * 24 * 6
];

const IANA_TO_WINDOWS = {
    [SnackpassTimezoneEnum.chicago]: "CT",
    [SnackpassTimezoneEnum.newYork]: "ET",
    [SnackpassTimezoneEnum.indianapolis]: "ET",
    [SnackpassTimezoneEnum.denver]: "MT",
    [SnackpassTimezoneEnum.phoenix]: "MST",
    [SnackpassTimezoneEnum.la]: "PT",
    [SnackpassTimezoneEnum.honolulu]: "HT",
    [SnackpassTimezoneEnum.juneau]: "AKT"
};

const IANA_TO_WINDOWS_LONG = {
    [SnackpassTimezoneEnum.chicago]: "Central Standard Time",
    [SnackpassTimezoneEnum.newYork]: "Eastern Standard Time",
    [SnackpassTimezoneEnum.indianapolis]: "Eastern Standard Time",
    [SnackpassTimezoneEnum.denver]: "Mountain Standard Time",
    [SnackpassTimezoneEnum.phoenix]: "Mountain Standard Time",
    [SnackpassTimezoneEnum.la]: "Pacific Standard Time",
    [SnackpassTimezoneEnum.honolulu]: "Hawaii-Aleutian Standard Time",
    [SnackpassTimezoneEnum.juneau]: "Alaska Standard Time"
};

export function replaceFullDayOfWeek(str: string): string {
    str = str.replace("monday", "mon");
    str = str.replace("tuesday", "tue");
    str = str.replace("wednesday", "wed");
    str = str.replace("thursday", "thu");
    str = str.replace("friday", "fri");
    str = str.replace("saturday", "sat");
    str = str.replace("sunday", "sun");
    return str;
}

export function getHoursText(hours: IHoursSchema): string {
    let ret = "";
    const times: string[] = [];
    hours.local.forEach((range) => {
        // Converting time from minutes from week start scale (i.e. 0 min => MON
        // 12:00 AM) to days/hours/minutes
        let startDay = Math.floor(range.start / (60 * 24));
        const startHours = Math.floor((range.start % (60 * 24)) / 60);
        const startMin = range.start % 60;
        let endDay = Math.floor(range.end / (60 * 24));
        const endHours = Math.floor((range.end % (60 * 24)) / 60);
        const endMin = range.end % 60;
        // Time.daysOfWeek starts on Sunday not Monday, so we add 1 to account
        // for this
        startDay = (startDay + 1) % 7;
        endDay = (endDay + 1) % 7;
        times.push(
            `${daysOfWeek[startDay]} ${getFormattedTime(
                startHours * 100 + startMin
            )} - ${daysOfWeek[endDay]} ${getFormattedTime(
                endHours * 100 + endMin
            )}`
        );
    });
    ret += times.join("\n");
    return ret;
}

// npr: Because we record our times in minutes-from-start-of-week, we must carefully
//      convert to actual time by parsing the equivalent ISO Week Date, not add minutes
//      to the start of the week, as this breaks when crossing DST boundaries.
//      (https://en.wikipedia.org/wiki/ISO_week_date)
const minutesOfWeekToDateTime = (
    minutes: number,
    anchorWithTZ: DateTime
): DateTime => {
    const { weekNumber, year } = anchorWithTZ;

    // Do a bunch of math to parse out the intended time of the minutes-of-week value
    const isoWeekday = Math.floor(minutes / (24 * 60)) + 1;
    const dayMinutes = minutes % (24 * 60);
    const hoursOfDay = Math.floor(dayMinutes / 60)
        .toString()
        .padStart(2, "0");
    const minutesOfHour = (dayMinutes % 60).toString().padStart(2, "0");

    // Build an ISO Week Date
    const isoWeekFmt = `${year}-W${weekNumber
        .toString()
        .padStart(2, "0")}-${isoWeekday}T${hoursOfDay}:${minutesOfHour}:00.000`;

    // Parse ISO Week Date, setting the correct timezone
    const result = DateTime.fromISO(isoWeekFmt).setZone(anchorWithTZ.zone, {
        keepLocalTime: true
    });

    // Log output only when under test...
    // typeof jest !== "undefined" &&
    //     console.log({
    //         minutes,
    //         anchor: anchorWithTZ.toISO(),
    //         isoWeekFmt,
    //         result: result.toISO()
    //     });

    return result;
};

export function isOpenLocal(hours: IHoursSchema): boolean {
    const now = DateTime.now().setZone(hours.zone);

    for (const timeRange of hours.local) {
        const start = minutesOfWeekToDateTime(timeRange.start, now);
        const end = minutesOfWeekToDateTime(timeRange.end, now);

        if (now >= start && now <= end) return true;
    }

    return false;
}

export function getHoursForProductDescription(product: IProduct): string {
    let ret = "";
    ret += "\nThis product is only available:\n~~~~\n\n";
    if (product.hours) {
        ret += getHoursText(product.hours);
    }
    return ret;
}

/**
 * Determines whether a timestamp fits within the hours of a store, product or promotion.
 * If no timestamp explicitly passed, defaults to checking the current time.
 *
 * @param obj - Store or product whose hours should be checked agianst
 * @param date - Date which should be checked against
 *
 */
export function isOpen(
    obj: Pick<IStore | IProduct | IPromotion, "hours">,
    date?: Date | null
): boolean {
    if (!obj.hours) {
        return false;
    } else if (date) {
        return isOpenOnDate(obj.hours, date);
    } else {
        return isOpenLocal(obj.hours);
    }
}

export function isOpenForDelivery(store: IStore, date?: Date | null): boolean {
    if (!store) {
        return false;
    }

    const hoursToCheck =
        store.hasSpecialDeliveryHours && store.specialDeliveryHours
            ? store.specialDeliveryHours
            : store.hours;

    if (!hoursToCheck) {
        return false;
    }

    return date
        ? isOpenOnDate(hoursToCheck as IHoursSchema, date)
        : isOpenLocal(hoursToCheck as IHoursSchema);
}

export function isOpenOnDate(hours: IHoursSchema, date: Date): boolean {
    const onDate = DateTime.fromJSDate(date).setZone(hours.zone);

    for (const timeRange of hours.local) {
        const start = minutesOfWeekToDateTime(timeRange.start, onDate);
        const end = minutesOfWeekToDateTime(timeRange.end, onDate);

        if (onDate >= start && onDate <= end) return true;
    }

    return false;
}

export function storeIsClosedUntil(store: IStore): boolean {
    return Boolean(store.closedUntil);
}

export const getToday = (): string => {
    const date = new Date();
    return daysOfWeek[date.getDay()];
};

export const getDayBefore = (): string => {
    const yesterday = DateTime.now().minus({ days: 1 }).weekday;
    return daysOfWeek[yesterday];
};

export function todaysHours(storeHours: IHoursSchema): string {
    if (!storeHours) {
        storeHours = {
            local: [],
            zone: SnackpassTimezoneEnum.newYork
        };
    }
    const localHours = storeHours.local;
    if (!localHours[0]) {
        return "Not Open Today";
    }
    if (
        localHours.length === 1 &&
        localHours[0].start === 0 &&
        localHours[0].end === 60 * 24 * 7 - 1
    ) {
        return "24 hours";
    }

    let currentDay = DateTime.now().weekday - 1;
    const currTime = DateTime.now();
    const hourOfDay = currTime.hour;
    // before 4am
    let isLateAM = false;
    if (hourOfDay < 5) {
        isLateAM = true;
    }

    if (isLateAM) {
        if (currentDay > 0) {
            currentDay -= 1;
        } else {
            currentDay = 7;
        }
    }
    const minutes = currTime.diff(currTime.startOf("week")).as("minutes");

    //sort hours ranges
    localHours.sort((a, b) => {
        return a.start > b.start ? 1 : -1;
    });
    if (
        localHours[0].start === 0 &&
        localHours[localHours.length - 1].end === 60 * 24 * 7 - 1
    ) {
        localHours[localHours.length - 1].end += localHours[0].end + 1;
        localHours.splice(0, 1);
    }
    const ranges: ITimeRangeSchema[] = [];
    for (const range of localHours) {
        if (range.start >= minutes && range.end <= minutes) {
            // If we are currently in the range, add it
            ranges.push(range);
        } else if (
            range.start >= TIME_RANGE_DAYS[currentDay] &&
            range.start <= TIME_RANGE_DAYS[currentDay] + 60 * 24
        ) {
            // If the start of the range is today
            ranges.push(range);
        }
    }

    const rangeStrings = ranges.map((r) => {
        const startHours = Math.floor((r.start % (60 * 24)) / 60);
        const startMin = r.start % 60;
        const endHours = Math.floor((r.end % (60 * 24)) / 60);
        const endMin = r.end % 60;

        const start = getFormattedTime(startHours * 100 + startMin);
        const end = getFormattedTime(endHours * 100 + endMin);

        let day = getToday();

        // what does this do?
        if (r.start < TIME_RANGE_DAYS[currentDay]) {
            day = daysOfWeek[(((new Date().getDay() - 1) % 7) + 7) % 7];
        }
        if (isLateAM) {
            day = getDayBefore();
        }
        return `${capitalize(day)} ${start} - ${end}`;
    });
    if (rangeStrings.length === 0) {
        return `Closed on ${DateTime.now().toFormat("cccc")}s`;
    }
    return rangeStrings.join("\n");
}

export function storeIsRushHourUntil(store: IStore): boolean {
    return Boolean(store.rushHourUntil);
}

/**
 * Includes store hours and closed until
 */
export function isFullyOpen(store: IStore): boolean {
    if (!isOpen(store)) {
        return false;
    }

    // If store doesn't have a closed until, and the store is open
    // and the tablet is online, it means the store is fully open
    if (!store.closedUntil) {
        return true;
    } else {
        // Otherwise, need to validate the closed until
        const currTime = DateTime.now();
        const minutes = currTime.diff(currTime.startOf("week")).as("minutes");
        const closedUntil = DateTime.fromJSDate(store.closedUntil);
        const closedUntilMinutes = closedUntil
            .diff(closedUntil.startOf("week"))
            .as("minutes");
        const { start } = getTodaysRange(store.hours);
        return minutes >= closedUntilMinutes && closedUntilMinutes > start;
    }
}

export const isAfterStartTime = (promotion: IPromotion): boolean => {
    if (!promotion.activeTimePeriod) {
        return true;
    } else if (!promotion.activeTimePeriod.startTime) {
        return false;
    }

    return (
        DateTime.now() >=
        DateTime.fromJSDate(new Date(promotion.activeTimePeriod.startTime))
    );
};

export const isAfterEndTime = (promotion: IPromotion): boolean => {
    if (!promotion.activeTimePeriod) {
        return true;
    } else if (!promotion.activeTimePeriod.endTime) {
        return false;
    }

    return (
        DateTime.now() >=
        DateTime.fromJSDate(new Date(promotion.activeTimePeriod.endTime))
    );
};

export function getFormattedTime(fourDigitTime: number): string {
    const formatted = padStart(fourDigitTime.toString(), 4, "0");
    const hours24 = parseInt(formatted.substring(0, 2), 10);
    const hours = (((hours24 + 11) % 12) + 1).toString();
    const amPm = hours24 > 11 ? "pm" : "am";
    const minutes = formatted.substring(2);

    return hours + ":" + minutes + amPm;
}

/**
 * Gets the next date using DAYS_OF_WEEK
 *
 * @param startMinutes The num of minutes from the start of the week.
 */
export const getNextDate = (startMinutes: number): string => {
    // DateTime.weekday returns 1-7, Monday is 1, Sunday is 7, per ISO
    const weekday =
        DateTime.now().startOf("week").plus({ minutes: startMinutes }).weekday %
        7;
    return DAYS_OF_WEEK[weekday];
};

export function getTodaysRange(storeHours: IHoursSchema): ITimeRangeSchema {
    if (!storeHours) {
        storeHours = {
            local: [],
            zone: SnackpassTimezoneEnum.newYork
        };
    }
    const localHours = storeHours.local;
    if (!localHours[0]) {
        return { start: 0, end: 0 };
    }
    if (
        localHours.length === 1 &&
        localHours[0].start === 0 &&
        localHours[0].end === 60 * 24 * 7 - 1
    ) {
        return { start: 0, end: WEEK };
    }

    const currTime = DateTime.now();
    const minutes = currTime.diff(currTime.startOf("week")).as("minutes");
    //stich end of sunday to beginning of monday

    //sort hours ranges
    localHours.sort((a, b) => {
        return b.start - a.start;
    });

    if (
        localHours[0].start === 0 &&
        localHours[localHours.length - 1].end === 60 * 24 * 7 - 1
    ) {
        localHours[localHours.length - 1].end += localHours[0].end + 1;
        localHours.splice(0, 1);
    }

    for (const range of localHours) {
        if (range.start <= minutes && range.end >= minutes) {
            return range;
        }
    }
    return { start: 0, end: 0 };
}

/**
 * This function gets the next range of times the store is open.
 * This function is good to use if a store is closed and you want to get the next time
 * the store opens at to display to the user.
 *
 * @param {IHoursSchema} storeHours The store's hours
 */
export function nextRange(storeHours: IHoursSchema): ITimeRangeSchema {
    if (!storeHours) {
        storeHours = {
            local: [],
            zone: SnackpassTimezoneEnum.newYork
        };
    }
    const localHours = storeHours.local;

    // if no hours set for the store
    if (!localHours[0]) {
        return { start: 0, end: 0 };
    }
    // if the range is all week
    if (
        localHours.length === 1 &&
        localHours[0].start === 0 &&
        localHours[0].end === 60 * 24 * 7 - 1
    ) {
        return localHours[0];
    }

    const currTime = DateTime.now();
    const minutesFromStartOfWeek = currTime
        .diff(currTime.startOf("week"))
        .as("minutes");

    // Sort the hours in ascending order, least start time -> greatest start time
    const sortedHours = localHours.sort((hoursA, hoursB) => {
        return hoursA.start - hoursB.start;
    });

    // Go through all the ranges, and return whichever
    // range has a greater start time than the current time
    for (const range of sortedHours) {
        if (minutesFromStartOfWeek < range.start) {
            return range;
        }
    }
    // If no range above was returned, it means it is the end of the week
    // so return the starting start time (time when the store opens the following week)
    return sortedHours[0];
}

/**
 * Get the next opening hours for a store, as a string.
 * It returns a string of the format "day start - end",
 * ie. "Tue 6:30 am - 8:00 pm"
 * Or, if the next hours overlap multiple days, it will be (StartDay StartTime - EndDay EndTime),
 * ie. "Mon 12:00 am - Tue 6:00 pm"
 *
 * @param {IHoursSchema} storeHours The store's hours
 */
export function nextOpeningHours(
    storeHours: IHoursSchema,
    prefix = "Opens"
): string {
    if (!storeHours) {
        return "";
    }
    const nextTimeRange = nextRange(storeHours);
    if (nextTimeRange.start === 0 && nextTimeRange.end === 0) {
        return "Not open today.";
    }

    const startOfWeek = DateTime.now().startOf("week");
    const start = startOfWeek
        .plus({ minutes: nextTimeRange.start })
        .toFormat("h:mm a")
        // luxon uses intl, which does not do lowercase am/pm
        .replace(" AM", "am")
        .replace(" PM", "pm");
    const end = startOfWeek
        .plus({ minutes: nextTimeRange.end })
        .toFormat("h:mm a")
        .replace(" AM", "am")
        .replace(" PM", "pm");
    const startDay = getNextDate(nextTimeRange.start);
    const endDay = getNextDate(nextTimeRange.end);

    // if the start day is different from the end day,
    // show the range of times
    if (startDay !== endDay) {
        return `${prefix} ${capitalize(startDay)} ${start} - ${capitalize(
            endDay
        )} ${end}`;
    }
    return `${prefix} ${capitalize(startDay)} ${start} - ${end}`;
}

export function getHourRangesText(hours?: IHoursSchema | null): string {
    let ret = "";
    const times: string[] = [];
    hours?.local.forEach((range) => {
        // Converting time from minutes from week start scale (i.e. 0 min => MON
        // 12:00 AM) to days/hours/minutes
        let startDay = Math.floor(range.start / (60 * 24));
        const startHours = Math.floor((range.start % (60 * 24)) / 60);
        const startMin = range.start % 60;
        let endDay = Math.floor(range.end / (60 * 24));
        const endHours = Math.floor((range.end % (60 * 24)) / 60);
        const endMin = range.end % 60;
        // Time.daysOfWeek starts on Sunday not Monday, so we add 1 to account
        // for this
        startDay = (startDay + 1) % 7;
        endDay = (endDay + 1) % 7;
        times.push(
            `${daysOfWeek[startDay]} ${getFormattedTime(
                startHours * 100 + startMin
            )} - ${daysOfWeek[endDay]} ${getFormattedTime(
                endHours * 100 + endMin
            )}`
        );
    });
    ret += times.join("\n");
    return ret;
}

export function assertTimezone(
    possibleTZ: string
): asserts possibleTZ is SnackpassTimezoneEnum {
    const timezones = Object.values(SnackpassTimezoneEnum);
    const isTimezone = !!timezones.find((tz) => tz === possibleTZ);
    if (!isTimezone) {
        throw new Error(
            [
                `${possibleTZ} is not a supported timezone;`,
                `the available timezones are ${timezones.join(",")}`
            ].join(" ")
        );
    }
}

export const getFormattedTimezone = (timezone: SnackpassTimezoneEnum): string =>
    IANA_TO_WINDOWS[timezone];

export const getFormattedTimezoneLong = (
    timezone: SnackpassTimezoneEnum
): string => IANA_TO_WINDOWS_LONG[timezone];

/**
 *  Check if hours.local list has overlap, e.g. if there are 2 monday in the store hours.local list
 * */
export const checkOverlappingHours = (value: ITimeRangeSchema[]) => {
    if (value.length < 2) {
        return true;
    }
    if (value && _.every(value, (i) => !_.isNil(i))) {
        const localSorted = value.sort((a, b) => {
            if (
                !_.isNil(a.start) &&
                !_.isNil(a.end) &&
                !_.isNil(b.start) &&
                !_.isNil(b.end)
            ) {
                if (a.start > b.start) {
                    return 1;
                } else if (a.start === b.start && a.end > b.end) {
                    return 1;
                }
            }

            return -1;
        });
        let counter = 0;
        while (counter < localSorted.length - 1) {
            const first = localSorted[counter].end;
            const second = localSorted[counter + 1].start;
            if (!_.isNil(first) && !_.isNil(second) && first > second) {
                return false;
            }
            counter += 1;
        }
        return true;
    }
    return true;
};

/**
 * Check if end time is after start time
 * */
export const validateHoursStartTimeBeforeEndTime = (
    value: ITimeRangeSchema[]
): boolean => {
    for (const range of value) {
        if (range.start && range.end && range.end <= range.start) {
            return false;
        }
    }
    return true;
};

/**
 *  Will explode if the timezone is not of type SnackpassTimezoneEnum
 *  (this is verified at execution)
 * @deprecated
 * */
export const getOffsetFromTimezone = (
    timezone: SnackpassTimezoneEnum | string,
    onDate?: Date
): number => {
    assertTimezone(timezone);
    const date = onDate ? DateTime.fromJSDate(onDate) : DateTime.now();
    return (
        date.setZone(timezone).offset -
        date.setZone(SnackpassTimezoneEnum.newYork).offset
    );
};

/**
 * The following merges both `local` and `special` store hours into a single week's store hours.
 * When able, this should be removed and moved into a GraphQL computed field.
 */

/**
 * Initializes a datetime using the local timezone and converts to UTC for manipulation
 * @param zone
 */
function localUTCDateTime(zone: string) {
    return DateTime.local({ zone }).setZone("utc", { keepLocalTime: true });
}

/**
 * Converts minute offsets with respect to the date into minute offsets with respect to the week
 * @param {Date} date
 * @param {Number} minute
 */
function dayMinutes2WeekMinutes(date: Date, minute: number) {
    // To ignore the effects of DST shift, the zone is set to UTC such that calculating
    // the minute offsets when DST occurs in the middle of the week
    // does not affect the start-day offsets
    const dt = DateTime.fromJSDate(date, { zone: "utc" });

    const startOfWeek = dt.startOf("week");
    const startOfDay = dt.startOf("day");

    const startDayMinutes = Math.floor(
        // `.as("minutes")` can return a non-integer
        startOfDay.diff(startOfWeek).as("minutes")
    );

    return startDayMinutes + minute;
}

/**
 * Converts minute offsets with respect to the date's week into minute offsets with respect to the date
 * @param {Date} date
 * @param {Number} minute
 */
function weekMinutes2DayMinutes(date: Date, minute: number) {
    const dt = DateTime.fromJSDate(date, { zone: "utc" });

    const startOfDay = dt.startOf("week").set({ minute }).startOf("day");
    const startOfWeek = dt.startOf("week");

    const startOfDayMinutes = startOfDay.diff(startOfWeek).as("minutes");

    return Math.floor(minute - startOfDayMinutes);
}

/**
 * Converts minute offsets with respect to the week into the 1-indexed weekday
 * @param {Number} minutes
 * @param {String} zone
 */
const weekMinutesToWeekday = (minutes: number) =>
    Math.floor((minutes % (60 * 24 * 7)) / (60 * 24)) + 1;

const yearMonthDayFormat = "yyyy-MM-dd";

const dateKeyFrom = (date: Date, zone = "utc") =>
    DateTime.fromJSDate(date, { zone }).toFormat(yearMonthDayFormat);

/**
 * Converts an array of ranges into a list of formatted dates (YYYY-MM-DD)
 * @param {IDateTimeRangeSchema[]} hours
 * @param zone
 */
const getDateList = (hours: IDateTimeRangeSchema[], zone = "utc") =>
    hours.map((range) => dateKeyFrom(range.date, zone));

/**
 * Creates a map of dates to its respective ranges from an array of ranges.
 *
 * ```typescript
 * [{ date: Date("2023-10-12"), start: 1, end: 2 }, { date: Date("2023-10-12"), start: 3, end: 4 }] ->
 * { "2023-10-12": [{ start: 1, end: 2 }, { start: 3, end: 4 }] }
 * ```
 * @param {IDateTimeRangeSchema[]} hours
 * @param zone
 */
const groupByDate = (hours: IDateTimeRangeSchema[], zone = "utc") =>
    _.groupBy(hours, (hour) => dateKeyFrom(hour.date, zone));

type ResolveStoreHoursOptions = {
    /**
     * Set specific dates to resolve hours. If not set, hours are resolved for this week.
     * Dates should be corrected to UTC.
     */
    forDates: Date[];
};

type ResolveStoreHoursReturn = {
    hours: ITimeRangeSchema[];
    dateTimeHours: IDateTimeRangeSchema[];
};

/**
 * Gets all the dates for this ISO week
 * @param zone
 */
const datesThisWeek = (zone: string): Date[] =>
    _.range(0, 7).map((index) =>
        localUTCDateTime(zone)
            .startOf("week")
            .set({ weekday: (index + 1) as WeekdayNumbers })
            .toJSDate()
    );

/**
 * Given specific dates to resolve hours for, return selected special hours overrides and generate regular hours
 * for each specific dates.
 * @param storeHours
 * @param options
 */
export function filterSelectedHoursByDates(
    storeHours: IHoursSchema,
    options: Required<ResolveStoreHoursOptions>
) {
    const { local, special = [] } = storeHours;

    const { forDates } = options;

    const selectedDates = forDates.map((date) => dateKeyFrom(date));
    const dateKeys = new Set(selectedDates);

    // filter special hours based on selected dates
    const selectedSpecialHours = special.filter((hour) =>
        dateKeys.has(dateKeyFrom(hour.date))
    );

    // for each weekday, group the regular hours assigned to it
    const regularHoursByWeekday = _.groupBy(local, (hours) =>
        weekMinutesToWeekday(hours.start)
    );
    const availableRegularWeekdays = new Set(
        local.map((hour) => weekMinutesToWeekday(hour.start))
    );

    const selectedRegularHours: IDateTimeRangeSchema[] = [];

    // generate/duplicate regular hours time ranges for selected dates
    for (const date of dateKeys) {
        const dt = DateTime.fromFormat(date, yearMonthDayFormat, {
            zone: "utc"
        });
        const weekday = dt.weekday;

        if (availableRegularWeekdays.has(weekday)) {
            // assign date to weekday
            const dateTimeRangeForWeekday: IDateTimeRangeSchema[] =
                regularHoursByWeekday[weekday].map((hour) => ({
                    date: dt.toJSDate(),
                    start: weekMinutes2DayMinutes(dt.toJSDate(), hour.start),
                    end: weekMinutes2DayMinutes(dt.toJSDate(), hour.end)
                }));

            selectedRegularHours.push(...dateTimeRangeForWeekday);
        }
    }

    return {
        regularHours: selectedRegularHours,
        specialHours: selectedSpecialHours,
        selectedDates
    };
}

/**
 * Resolves special store hours overrides into regular store hours. `resolveStoreHours` is used to generate
 * the `local` field of the `StoreHoursSchema`, to be consumed by clients. `resolveStoreHours` should ignore
 * any special hours outside the current week, while adding, removing and overriding hours within the current
 * week.
 * @param {IHoursSchema} storeHours
 * @param {ResolveStoreHoursOptions} options if not set, it defaults to the current week
 */
export function resolveStoreHours(
    storeHours: IHoursSchema,
    options?: ResolveStoreHoursOptions
): ResolveStoreHoursReturn {
    const { zone } = storeHours;

    const hoursOptions: ResolveStoreHoursOptions = {
        forDates: datesThisWeek(zone)
    };

    // All dates after this point are all in UTC

    if (options && options.forDates.length > 0) {
        hoursOptions.forDates = options.forDates;
    }

    // Both special and regular hours are filtered by the selected dates
    const { selectedDates, specialHours, regularHours } =
        filterSelectedHoursByDates(storeHours, hoursOptions);

    // range.start === range.end indicates that a store is closed
    // this is handled within RDB and Snackface
    const [openSpecialHours, closedSpecialHours] = _.partition(
        specialHours,
        (range) => range.start !== range.end
    );

    const regularDates = getDateList(regularHours);

    const specialOpenDates = new Set(getDateList(openSpecialHours));
    const specialClosedDates = new Set(getDateList(closedSpecialHours));

    let regularOpenDates = new Set(regularDates);

    if (specialClosedDates.size > 0) {
        regularOpenDates = new Set(
            regularDates.filter((date) => !specialClosedDates.has(date))
        );
    }

    const regularHoursByDate = groupByDate(regularHours);
    const specialHoursByDate = groupByDate(specialHours);

    const dateTimeRangeHours: IDateTimeRangeSchema[] = [];

    for (const date of selectedDates) {
        if (specialOpenDates.has(date)) {
            dateTimeRangeHours.push(...specialHoursByDate[date]);
        } else if (regularOpenDates.has(date)) {
            dateTimeRangeHours.push(...regularHoursByDate[date]);
        }
    }

    const timeRangeHours: ITimeRangeSchema[] = dateTimeRangeHours.map(
        (hour) => ({
            start: dayMinutes2WeekMinutes(hour.date, hour.start),
            end: dayMinutes2WeekMinutes(hour.date, hour.end)
        })
    );

    return {
        hours: timeRangeHours,
        dateTimeHours: dateTimeRangeHours
    };
}
