import { ITimeRangeSchema } from "@snackpass/snackpass-types";
import { z } from "zod";

export const TimeOfDaySchema = z.object({
    /** The hour part (0-24). 24 is allowed for the exclusive-end case. */
    hour: z.number().int().min(0).max(24),

    /** The minute part (0-59) */
    minute: z.number().int().min(0).max(59),

    /** The second part (0-59) */
    second: z.number().int().min(0).max(59).optional()
});

/** TimeOfDay is a value of local hours and minutes. */
export type TimeOfDay = z.infer<typeof TimeOfDaySchema>;

export const TimeOfDayWithZoneSchema = TimeOfDaySchema.extend({
    /** The Store's IANA zone code (e.g. America/New_York) */
    zone: z.string()
});

/** TimeOfDay is a value of local hours and minutes. */
export type TimeOfDayWithZone = z.infer<typeof TimeOfDayWithZoneSchema>;

export const TimeOfDayRangeSchema = z.object({
    /** Start of range (inclusive) */
    start: TimeOfDaySchema,

    /** End of range (exclusive) */
    end: TimeOfDaySchema
});

/** TimeOfDayRange is a value of start and end. */
export type TimeOfDayRange = z.infer<typeof TimeOfDayRangeSchema>;

export const WeekdayTimeRangesSchema = z.record(
    z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7)
    ]),
    TimeOfDayRangeSchema.array()
);

/** WeekdayTimeRanges maps weekday to an array of TimeOfDayRange. */
export type WeekdayTimeRanges = z.infer<typeof WeekdayTimeRangesSchema>;

export const DateSchema = z
    //  2023-03-01 {year: 2023, month: 3, day: 1}
    .object({
        /** The year part */
        year: z.number().int().min(2000).max(2100),

        /** The month part (1-12) */
        month: z.number().int().min(1).max(12),

        /** The day part (1-31) */
        day: z.number().int().min(1).max(31)
    });

/** LocalDate is a value of local year, month, and day.
 *
 * Building a Date from LocalDate:
 * ```typescript
 * const {year, month, day} = localDate;
 * const date = new Date(year, month - 1, day); // monthIndex is 0-indexed
 * const dateTime = luxon.DateTime.fromObject({year, month, day});
 * ```
 */
export type LocalDate = z.infer<typeof DateSchema>;

/**
 * A validator for a date and time of day.
 */
export const DateTimeSchema = DateSchema.and(TimeOfDaySchema);

/**
 * LocalDateTime is a value of local date and time of day.
 *
 * Building a Date from LocalDateTime:
 * ```typescript
 * const { year, month, day, hour, minute, second } = localDateTime;
 * const date = new Date(year, month - 1, day, hour, minute, second);
 * const dateTime = luxon.DateTime.fromObject({ year, month, day, hour, minute, second });
 * ```
 */
export type LocalDateTime = z.infer<typeof DateTimeSchema>;

const dateRangeErrorMessage = ({
    start,
    end
}: {
    start: LocalDate;
    end?: LocalDate;
}) => ({
    message: end
        ? `DateRange: start "${new Date(
              start.year,
              start.month - 1,
              start.day
          )}" must precede end "${new Date(end.year, end.month - 1, end.day)}"`
        : "Unexpected parsing error."
});

const dateTimeRangeErrorMessage = ({
    start,
    end
}: {
    start: LocalDateTime;
    end?: LocalDateTime;
}) => ({
    message: end
        ? `DateTimeRange: start "${new Date(
              start.year,
              start.month - 1,
              start.day,
              start.hour,
              start.minute,
              start.second ?? 0
          )}" must precede end "${new Date(end.year, end.month - 1, end.day, end.hour, end.minute, end.second ?? 0)}"`
        : "Unexpected parsing error."
});

/** A pair of dates, start and end. */
export const LocalDateRangeSchema = z
    .object({ start: DateSchema, end: DateSchema })
    .refine(
        ({ start, end }) =>
            new Date(start.year, start.month - 1, start.day) <
            new Date(end.year, end.month - 1, end.day),
        dateRangeErrorMessage
    );

export type LocalDateRange = z.infer<typeof LocalDateRangeSchema>;

/**
 * A validator for LocalDateRange with an optional end date.
 */
export const LocalDateRangeOpenSchema = z
    .object({ start: DateSchema, end: DateSchema.optional() })
    .refine(
        ({ start, end }) =>
            !end ||
            new Date(start.year, start.month - 1, start.day) <
                new Date(end.year, end.month - 1, end.day),
        dateRangeErrorMessage
    );

export type LocalDateRangeOpen = z.infer<typeof LocalDateRangeOpenSchema>;

/** A pair of date-times, start and end. */
export const LocalDateTimeRangeSchema = z
    .object({ start: DateTimeSchema, end: DateTimeSchema })
    .refine(
        ({ start, end }) =>
            new Date(
                start.year,
                start.month - 1,
                start.day,
                start.hour,
                start.minute,
                start.second ?? 0
            ) <
            new Date(
                end.year,
                end.month - 1,
                end.day,
                end.hour,
                end.minute,
                end.second ?? 0
            ),
        dateTimeRangeErrorMessage
    );

export type LocalDateTimeRange = z.infer<typeof LocalDateTimeRangeSchema>;

/** A pair of date-times, start and end. */
export const LocalDateTimeRangeOpenSchema = z
    .object({ start: DateTimeSchema, end: DateTimeSchema.optional() })
    .refine(
        ({ start, end }) =>
            !end ||
            new Date(
                start.year,
                start.month - 1,
                start.day,
                start.hour,
                start.minute,
                start.second
            ) <
                new Date(
                    end.year,
                    end.month - 1,
                    end.day,
                    end.hour,
                    end.minute,
                    end.second
                ),
        dateTimeRangeErrorMessage
    );

export type LocalDateTimeRangeOpen = z.infer<
    typeof LocalDateTimeRangeOpenSchema
>;

export const DatedTimesOfDaySchema = z.object({
    date: DateSchema,
    hours: TimeOfDayRangeSchema.array()
});

/** DateTimeRanges is for mapping TimeOfDayRanges to specific dates. */
export type DatedTimesOfDay = z.infer<typeof DatedTimesOfDaySchema>;

export const LocalHoursSchema = z.object({
    /** The Store's IANA zone code (e.g. America/New_York) */
    zone: z.string(),

    /** The Store's regular hours, by weekday (1-7, Mon-Sun) */
    weekdays: WeekdayTimeRangesSchema,

    /** The Store's special hours, by date */
    dates: DatedTimesOfDaySchema.array().optional()
});

/** LocalHours represents the hours of operation for a Store.
 *
 *  NOTE: All times are in the Store's local wall-clock time.
 *
 * Example:
 * ```typescript
 * const weekdayHours = [{ start: { hour: 10, minute: 0 }, end: { hour: 18, minute: 0 } }];
 * const storeHours: LocalHours = {
 *     zone: "America/New_York",
 *     weekdays: {
 *         1: weekdayHours,
 *         2: weekdayHours,
 *         3: weekdayHours,
 *         4: weekdayHours,
 *         5: weekdayHours,
 *         6: [
 *             { start: { hour: 12, minute: 0 }, end: { hour: 16, minute: 0 } }
 *         ],
 *     },
 *     dates: [
 *         {
 *             date: { year: 2024, month: 2, day: 14 },
 *             hours: [
 *                 { start: { hour: 8, minute: 30 }, end: { hour: 16, minute: 0 } }
 *             ]
 *         }
 *     ],
 * };
 * ```
 */
export type LocalHours = z.infer<typeof LocalHoursSchema>;

export type RegularHours = {
    weekday: number;
    range: ITimeRangeSchema;
};

export type SpecialHours = {
    date: Date;
    isClosed: boolean;
    range: ITimeRangeSchema;
};
