export default class GeolocationHelpers {
    static requestLocation(
        onSuccessLocation: PositionCallback,
        onErrorLocation: PositionErrorCallback
    ) {
        if (!navigator.geolocation) {
            return;
        }
        navigator.geolocation.getCurrentPosition(
            onSuccessLocation,
            onErrorLocation
        );
    }
}
