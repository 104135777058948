import { delay, put, select, takeEvery } from "typed-redux-saga";

import {
    ModalsName,
    selectIsCartConflictModalOpen,
    selectIsUpsellAlreadyShown,
    showModal,
} from "@/redux";
import { clearConflicts } from "#cart/slice";

export function* surfaceUpsellSaga() {
    yield* takeEvery(clearConflicts, function* () {
        const alreadyShown = yield* select(selectIsUpsellAlreadyShown);

        // Only surface upsell modal if there are no conflicts.
        if (alreadyShown) return;

        const conflictModalVisible = yield* select(
            selectIsCartConflictModalOpen,
        );

        // Force delay to allow other modal to close.
        // XXX: This is highly coupled to the current UI implementation.
        if (conflictModalVisible) {
            yield* delay(300);
        }

        // Surface zee modal!
        yield* put(showModal(ModalsName.upsellModal));
    });
}
