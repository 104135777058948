import { IPurchase_MinimalQueueStatus } from "@snackpass/snackpass-types";
import io from "socket.io-client";

import config from "@/config";
import {
    fetchPurchase,
    upsertQueuePurchaseInfo,
} from "@/redux/purchaseReceipt";

type SocketQuery = { token: string; purchaseId: string };

const OO_RECEIPT_WS_URI = `${config.receiptsApiEndpoint}/oo_receipt`;

/**
 * A SocketManager represents a socket.io connection to the server.
 * Please read this before using websockets. This class is based
 * off of src/lib/Websocket.ts in the @snackpass/pos repo.
 * SocketManager should only be initialized once.
 */
export default class SocketManager {
    _socket: any;
    _purchaseId: string | null;
    _token: string | null;
    connected: boolean;
    shouldBackoffResync: boolean = false;
    afterReconnectTimeoutId: NodeJS.Timeout | undefined;

    constructor() {
        this._purchaseId = null;
        this._token = null;
        this.connected = false;
    }

    setPurchaseId(newPurchaseId: string, token: string) {
        if (this._purchaseId === newPurchaseId) {
            return;
        }
        if (this.connected && this._purchaseId) {
            this._socket.emit("receipt.leave", this._purchaseId);
        }
        if (this.connected) {
            this._socket.emit("receipt.join", newPurchaseId, token);
        }
        this._token = token;
        this._purchaseId = newPurchaseId;
    }

    async connect(dispatch: Function) {
        if (this._socket) return;

        const token = this._token;
        const purchaseId = this._purchaseId;
        if (!purchaseId || !token) {
            throw new Error(
                "[websockets.ts] connect() requires purchaseId and token",
            );
        }

        const query: SocketQuery = {
            token,
            purchaseId,
        };
        this._socket = io(OO_RECEIPT_WS_URI, {
            transports: ["websocket"],
            secure: true,
            query,
        });
        this._socket.on("error", (err: any) => {
            console.error(`[websockets.ts] error: ${err}`);
            this.disconnect();
            return;
        });

        this._socket.on("connect", () => {
            this.connected = true;
            if (this._purchaseId) {
                this._socket.emit("receipt.join", this._purchaseId, token);
            }
        });

        this._socket.on(
            "queue.purchaseCreate",
            async (queuePurchaseStatus: IPurchase_MinimalQueueStatus) => {
                dispatch(upsertQueuePurchaseInfo(queuePurchaseStatus));
            },
        );
        this._socket.on(
            "queue.purchaseUpdateStatus",
            async (queuePurchaseStatus: IPurchase_MinimalQueueStatus) => {
                dispatch(upsertQueuePurchaseInfo(queuePurchaseStatus));
            },
        );

        this._socket.on("reconnect", async () => {
            console.log("[websockets.ts] reconnected:", {
                OO_RECEIPT_WS_URI,
                query,
            });
            this.connected = true;
            if (!this.shouldBackoffResync) {
                await this.resyncPurchase(dispatch);
            }
        });

        this._socket.on(
            "server.shutdown",
            async (data: {
                baseDurationSeconds: number;
                randomOffsetMaximumSeconds: number;
            }) => {
                this.shouldBackoffResync = true;
                const backoffAmountMS = Math.round(
                    (data.baseDurationSeconds +
                        Math.random() * data.randomOffsetMaximumSeconds) *
                        1000,
                );

                if (this.afterReconnectTimeoutId) {
                    clearTimeout(this.afterReconnectTimeoutId);
                }

                this.afterReconnectTimeoutId = setTimeout(async () => {
                    this.resyncPurchase(dispatch);
                    this.shouldBackoffResync = false;
                }, backoffAmountMS);
            },
        );

        this._socket.on("disconnect", async () => {
            this.connected = false;
        });
    }

    async resyncPurchase(dispatch: Function) {
        if (!this._purchaseId || !this._token) {
            return;
        }
        dispatch(
            fetchPurchase({
                purchaseId: this._purchaseId,
                token: this._token,
            }),
        );
    }

    disconnect() {
        if (this._socket) {
            this._socket.close();
            this._socket = null; // reset socket to null just in case we want to reconnect to a new purchaseId
        }
    }
}
