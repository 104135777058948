/**
 *
 *
 * Reusable style lib
 *
 *
 */
import * as Colors from "./Colors";

export const box = {
    boxShadow: "0 0px 1px 0 rgba(0,0,0,0.15)",
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: Colors.lightGray
};

export const input = {
    height: 40,
    padding: 10,
    alignSelf: "stretch",
    width: "100%",
    borderWidth: 0,
    borderBottomWidth: 1,
    backgroundColor: Colors.white,
    borderColor: Colors.lightGray,
    fontSize: 14
};

export const modal = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderWidth: 0,
        boxShadow: "0 10px 30px 0 rgba(0,0,0,0.20)"
    },
    overlay: {
        zIndex: 2000,
        backgroundColor: "rgba(0,0,0,.4)"
    }
};

export const buyButton = {
    backgroundColor: Colors.green4,
    padding: 10,
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.20)",
    borderRadius: 40,
    paddingLeft: 50,
    paddingRight: 50
};

// Adds a gray border to the bottom
export const borderBottom = {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray
};

// full screen container
export const container = {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
};

// adds shadow
export const shadow = {
    boxShadow: "0 0px 10px 0 rgba(0,0,0,0.2)"
};

// adds a lighter shadow
export const shadowLight = {
    boxShadow: "0 -2px 10px 0 rgba(0,0,0,0.23)"
};

export const shadowSuperLight = {
    boxShadow: "0 0px 4px 0 rgba(0,0,0,0.15)"
};

// card like MyFreeStuff Card
export const card = {
    backgroundColor: Colors.white,
    borderRadius: 8,
    alignSelf: "stretch",
    ...shadow
};

export const name = {
    fontSize: 18,
    color: Colors.text
};

export const description = {
    fontSize: 12,
    color: Colors.gray
};
export const textStyles = {
    name: name,
    description: description
};

// makes items flex in row direction, centered
export const flexRow = {
    flexDirection: "row" as "row",
    alignItems: "center" as "center",
    justifyContent: "center" as "center"
};

// makes items flex in column direction, centered
export const flexCol = {
    flexDirection: "column" as "column",
    alignItems: "center" as "column",
    justifyContent: "center" as "center"
};

// height and width of feature image
export const FEATURE_IMAGE_WIDTH = 250;
export const FEATURE_IMAGE_HEIGHT = 180;

// tab style for things like +1 🍑, fav 💛
export const statsTab = {
    borderColor: Colors.lightGray,
    borderRadius: 8,
    borderWidth: 1,
    padding: 6,
    paddingTop: 0,
    paddingBottom: 0,
    height: 26,
    ...flexRow,
    marginRight: 7
};

export const CATEGORY_WIDTH = 350;
