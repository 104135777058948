import { useSelector } from "react-redux";
import { MapComponent } from "./MapboxMap";
import { ListDrawer } from "./ListDrawer";
import { ActiveStoreOnMapDrawer } from "./ActiveStoreOnMapDrawer";
import { Helmet } from "react-helmet";
import { getActiveChain, getActiveChainFetching } from "@/redux";

export function CorporateMode() {
    const activeChain = useSelector(getActiveChain);
    const isFetching = useSelector(getActiveChainFetching);

    if (!activeChain && !isFetching)
        return (
            <div className="flex flex-col w-screen h-screen justify-center items-centerp-4">
                <p className="text-center">
                    You must be a part of a chain to use the corporate map
                    feature.
                </p>
            </div>
        );
    return (
        <div>
            <Helmet>
                <meta name="theme-color" content="#000000" />
            </Helmet>
            <MapComponent />
            <ListDrawer />
            <ActiveStoreOnMapDrawer />
        </div>
    );
}
