import React from "react";
import { CartSelectors } from "@snackpass/accounting";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux";
import Headcount from "./Headcount";
import ScheduleContent from "./Schedule";
import { ReactComponent as GroupIcon } from "src/assets/icons/group.svg";
import { Divider } from "@/SharedComponents";

const DatePicker = ({ title }: { title: string }) => {
    const activeStore = useSelector(getActiveStore);
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const hasScheduledOrders = activeStore?.hasScheduledOrders;
    return (
        <div className="hidden-scroll flex flex-col overflow-y-scroll">
            <div className="animated fadeIn flex-1">
                {isCatering && (
                    <div className="mb-6 mt-4">
                        <div className="my-6 flex flex-row items-center justify-between">
                            <div className="flex flex-row">
                                <GroupIcon className="mr-3 mt-[2px] h-[20px] w-[20px] fill-secondary" />
                                <span className="text-base font-medium leading-6 text-primary">
                                    Group Size
                                </span>
                            </div>

                            <Headcount />
                        </div>
                        <Divider width={1} />
                    </div>
                )}
                <div className="my-2 flex flex-row items-center justify-between">
                    <span className="text-xl font-semibold text-primary">
                        {title}
                    </span>
                </div>
                {hasScheduledOrders || isCatering ? <ScheduleContent /> : null}
            </div>
        </div>
    );
};

export default DatePicker;
