import { useMemo } from "react";
import _ from "lodash";
import { getIsGiftCardProduct } from "@snackpass/accounting";
import { CategoryProduct } from "@/utils/Types";
import { useCategories } from "@/redux/useCategory";
import { useFilterForCompatibleProducts } from "./useFilterForCompatibleProducts";
import {
    IDealItem,
    IPromotion,
    PromoTypes,
    SpecialProductCategory,
} from "@snackpass/snackpass-types";

/*
 * Return a array of product images that are compatible with the promotion
 */
const getImages = (
    promoURL: string | undefined | null,
    categoryProduct: CategoryProduct[],
): string[] => {
    let imgs: string[] = promoURL ? [promoURL] : [];
    for (let c of categoryProduct) {
        for (let p of c.products) {
            if (p?.image) {
                imgs.push(p.image);
            }
        }
    }

    return imgs;
};

/* sums up the number of products in each category to determine the total number of eligible items.
 */
const getNumberOfEligibleItem = (categories: CategoryProduct[]): number => {
    return categories.reduce(
        (total, category) => total + category.products.length,
        0,
    );
};

/**
 * Returns an array of CategoryProducts and Product Images that are compatible with the promotion (type: DEAL, DISCOUNT only)
 */
export const usePromoItemProductsAndImages = (
    promo: IPromotion,
): {
    availableCategoryProducts: CategoryProduct[];
    availableProductImages: string[];
    noProductsAvailable: boolean;
    numberOfEligibleItem: number;
} => {
    const storeCategories = useCategories();
    const filterForCompatibleProducts = useFilterForCompatibleProducts();
    return useMemo(() => {
        if (!promo) {
            return {
                availableCategoryProducts: [],
                noProductsAvailable: true,
                availableProductImages: [],
                numberOfEligibleItem: 0,
            };
        }
        const isStoreWide = promo?.storewide;
        const isGiftCardPromo = promo?.type === PromoTypes.GiftCard;
        let productIds = promo?.productIds ?? [];
        let categories = promo?.categories;
        let availableCategoryProducts: CategoryProduct[] = [];

        if (isGiftCardPromo) {
            availableCategoryProducts = storeCategories.reduce<
                CategoryProduct[]
            >((acc, c) => {
                if (
                    c.specialProductCategory === SpecialProductCategory.GiftCard
                ) {
                    const products = filterForCompatibleProducts(
                        c.productIds,
                    ).filter((product) => getIsGiftCardProduct(product));
                    if (products.length > 0) {
                        acc.push({
                            category: c.name,
                            products,
                        });
                    }
                }
                return acc;
            }, []);
            return {
                availableCategoryProducts,
                availableProductImages: getImages(
                    promo?.imageUrl,
                    availableCategoryProducts,
                ),
                noProductsAvailable: _.isEmpty(availableCategoryProducts),
                numberOfEligibleItem: getNumberOfEligibleItem(
                    availableCategoryProducts,
                ),
            };
        }
        // if promo is storewide, it's a DISCOUNT type
        if (isStoreWide) {
            availableCategoryProducts = storeCategories.reduce<
                CategoryProduct[]
            >((acc, c) => {
                const products = filterForCompatibleProducts(
                    c.productIds,
                ).filter((product) => getIsGiftCardProduct(product) === false);
                if (products.length > 0) {
                    acc.push({
                        category: c.name,
                        products,
                    });
                }
                return acc;
            }, []);
            return {
                availableCategoryProducts,
                availableProductImages: getImages(
                    promo?.imageUrl,
                    availableCategoryProducts,
                ),
                noProductsAvailable: _.isEmpty(availableCategoryProducts),
                numberOfEligibleItem: getNumberOfEligibleItem(
                    availableCategoryProducts,
                ),
            };
        }

        /**
         * if promo is not store wide, that means:
         * a. it could be DISCOUNT type, categories or productId fields are defined and not empty
         * b. it could be DEAL type, categories or productId are empty, but dealItems should have at least length of 2
         *
         * Filters all productIds and then maps them to their respective categories
         */

        // combines the categories and productIds from each deal item into the main lists for later processing.
        if (promo.type === PromoTypes.Deal) {
            promo.dealItems.forEach((dealItem: IDealItem) => {
                if (dealItem?.categories && !_.isEmpty(dealItem?.categories)) {
                    categories = categories.concat(dealItem.categories);
                }
                if (dealItem?.productIds && !_.isEmpty(dealItem?.productIds)) {
                    productIds = productIds.concat(dealItem.productIds);
                }
            });
        }

        /**
         * Filters promo?.productIds if it exists
         * creates an array of CategoryProduct objects where each object
         * contains a category and an array of products belonging to that category.
         */
        const allProductCategories = filterForCompatibleProducts(
            productIds,
        ).reduce<CategoryProduct[]>((acc, p) => {
            if (p.category) {
                const existingCat = acc.find(
                    (cp) => cp.category === p.category,
                );
                if (existingCat) {
                    existingCat.products = existingCat.products.concat(p);
                } else {
                    acc = acc.concat({
                        category: p.category,
                        products: [p],
                    });
                }
            }
            return acc;
        }, []);

        /**
         * Filters promo?.categories
         * creates an array of CategoryProduct objects where each object
         * contains a category and an array of products belonging to that category.
         */
        availableCategoryProducts = allProductCategories.concat(
            categories?.reduce<CategoryProduct[]>((acc, catId) => {
                const category = storeCategories.find((c) => c._id === catId);
                if (category) {
                    const products = filterForCompatibleProducts(
                        category.productIds,
                    );
                    // Check that there is at least 1 eligible product
                    if (products.length > 0) {
                        acc = acc.concat({
                            category: category.name,
                            products,
                        });
                    }
                }
                return acc;
            }, []) || [],
        );
        return {
            availableCategoryProducts,
            availableProductImages: getImages(
                promo?.imageUrl,
                availableCategoryProducts,
            ),
            noProductsAvailable: _.isEmpty(availableCategoryProducts),
            numberOfEligibleItem: getNumberOfEligibleItem(
                availableCategoryProducts,
            ),
        };
    }, [promo, filterForCompatibleProducts, storeCategories]);
};
