import { Geolocation, IStore } from "@snackpass/snackpass-types";

/* Actions */
export const SET_SELECTED_STORE = "SET_SELECTED_STORE";
export const SET_MADE_A_SELECTION = "SET_MADE_A_SELECTION";
export const SET_CENTER = "SET_CENTER";

export type SET_SELECTED_STORE = typeof SET_SELECTED_STORE;
export type SET_MADE_A_SELECTION = typeof SET_MADE_A_SELECTION;
export type SET_CENTER = typeof SET_CENTER;

type SetSelectedStoreAction = {
    type: SET_SELECTED_STORE;
    store: IStore | null;
};

type SetMadeASelectionAction = {
    type: SET_MADE_A_SELECTION;
    madeASelection: boolean;
};

type SetCenterAction = {
    type: SET_CENTER;
    center: Geolocation | undefined;
};

export const setSelectedStore = (
    store: IStore | null,
): SetSelectedStoreAction => ({
    type: SET_SELECTED_STORE,
    store,
});

export const setMadeASelection = (
    madeASelection: boolean,
): SetMadeASelectionAction => ({
    type: SET_MADE_A_SELECTION,
    madeASelection,
});

export const setCenter = (
    center: Geolocation | undefined,
): SetCenterAction => ({
    type: SET_CENTER,
    center,
});

/* Reducer */
export interface LocationsState {
    selectedStore: IStore | null;
    madeASelection: boolean;
    center: Geolocation | undefined;
}

export const LocationsInitialState: LocationsState = {
    selectedStore: null,
    madeASelection: false,
    center: undefined,
};

export default function locationsReducer(
    state = LocationsInitialState,
    action: SetSelectedStoreAction | SetMadeASelectionAction | SetCenterAction,
): LocationsState {
    switch (action.type) {
        case SET_SELECTED_STORE:
            return {
                ...state,
                selectedStore: action.store,
            };
        case SET_MADE_A_SELECTION:
            return {
                ...state,
                madeASelection: action.madeASelection,
            };
        case SET_CENTER:
            return {
                ...state,
                center: action.center,
            };
        default:
            return state;
    }
}

/* Selectors */
export const getSelectedStore = (state: { locations: LocationsState }) =>
    state.locations.selectedStore;
export const getMadeASelection = (state: { locations: LocationsState }) =>
    state.locations.madeASelection;
export const getCenter = (state: { locations: LocationsState }) =>
    state.locations.center;
