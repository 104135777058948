import { setActiveStore } from "#activeStore";
import { setIsUpsellAlreadyShown } from "@/redux";
import {
    getMadeASelection,
    getSelectedStore,
    setCenter,
    setMadeASelection,
    setSelectedStore,
} from "@/redux/locations";
import { StoreUtils } from "@/utils";
import { IStore } from "@snackpass/snackpass-types";
import { useDispatch, useSelector } from "react-redux";

export function useSelectStore() {
    const dispatch = useDispatch();

    const selectedStore = useSelector(getSelectedStore);
    const madeASelection = useSelector(getMadeASelection);

    const handleSelectStore = (store: IStore | null | undefined) => {
        if (store) {
            dispatch(setSelectedStore(store));
            dispatch(setMadeASelection(true));
            dispatch(setCenter(store.geolocation));
        }
    };
    const handleConfirm = () => {
        if (selectedStore) {
            dispatch(setActiveStore(selectedStore));
            dispatch(setIsUpsellAlreadyShown(false));

            // Post message to WebView
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: "confirmedStoreLocation",
                        storeId: selectedStore._id,
                        storeName: selectedStore.name,
                    }),
                );
            }
        }
    };
    const storeRoute = selectedStore
        ? StoreUtils.getStoreRoute(selectedStore)
        : "";

    return {
        handleSelectStore,
        selectedStore,
        madeASelection,
        handleConfirm,
        storeRoute,
    };
}
