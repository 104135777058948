import { StoreUtils } from "@/utils";

/**
 * Provides a slug or ID for a store based on the input string.
 */
export function getStoreParams(storeNameAndId: string):
    | {
          id: string;
      }
    | { slug: string } {
    const objectIDMatch = storeNameAndId.match(/[0-9a-f]{24}/i);
    if (storeNameAndId.startsWith("$")) {
        return { id: storeNameAndId.slice(1) };
    }
    if (storeNameAndId.includes("-")) {
        return { id: StoreUtils.parseStoreIdFromUrl(storeNameAndId) };
    }
    if (objectIDMatch) {
        return { id: objectIDMatch[0] };
    }
    return { slug: storeNameAndId };
}
