import React from "react";
import { Api } from "../../../utils";
import { ReactComponent as CardIcon } from "src/assets/icons/credit-card.svg";
import { useDispatch, useSelector } from "react-redux";
import {
    ModalsName,
    getUserCards,
    hideModal,
    setIsApplePayActive,
} from "@/redux";
import { ReactComponent as CheckIcon } from "src/assets/icons/check-thin.svg";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash.svg";
import { toast } from "sonner";

type Props = {
    activeCardIndex: number | null | undefined;
    card: any;
    setUser: Function;
    cardIndex: number;
};

export const CardRow: React.FC<Props> = ({
    card,
    setUser,
    cardIndex,
    activeCardIndex,
}) => {
    const dispatch = useDispatch();
    const cards = useSelector(getUserCards);
    let isActive = activeCardIndex === cardIndex;
    const deactivateApplePay = () => dispatch(setIsApplePayActive(false));
    const removeCard = () => {
        if (cards.length === 1) {
            dispatch(hideModal(ModalsName.myCardsModal));
        }
        Api.me.cards
            .remove(card._id)
            .then(async (response) => {
                console.log(response.data.user);
                setUser(response.data.user);
            })
            .catch((err) => {
                toast.error("Cannot delete card.");
            });
        if (activeCardIndex === cardIndex && cards.length === 0) {
            dispatch(hideModal(ModalsName.myCardsModal));
        }
        if (activeCardIndex === cardIndex && cards.length > 0) {
            // if active card be deleted, set first card as active card
            handleActivate(0);
        }
    };
    const handleActivate = (cardIndex: number) => {
        deactivateApplePay();
        Api.me.update({ activeCardIndex: cardIndex }).then((response) => {
            setUser(response.data.user);
        });
    };
    return (
        <div className="cursor-pointer items-stretch bg-white hover:bg-tertiary px-6 rounded-lg">
            <div className="flex flex-row items-center justify-between">
                <div
                    className="flex flex-row items-center grow py-4 my-1"
                    onClick={
                        !isActive ? () => handleActivate(cardIndex) : () => {}
                    }
                >
                    <CardIcon className="w-[20px] h-[20px] fill-secondary mr-6 ml-2" />
                    <div className="text-secondary text-sm py-2">
                        ···· {card.cardLast4}
                    </div>
                </div>
                <div className="flex flex-row grow-0 gap-3">
                    <TrashIcon
                        className="w-[20px] h-[20px] fill-secondary p-[2px]"
                        onClick={removeCard}
                    />
                    <div className="w-[20px] h-[20px]">
                        {isActive && (
                            <CheckIcon className="w-[20px] h-[20px] fill-secondary" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
