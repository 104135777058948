/* Actions */
export const SET_IS_TIP_SELECTED = "SET_IS_TIP_SELECTED";
export type SET_IS_TIP_SELECTED = typeof SET_IS_TIP_SELECTED;

export const SET_SELECTED_TIP_INDEX = "SET_SELECTED_TIP_INDEX";
export type SET_SELECTED_TIP_INDEX = typeof SET_SELECTED_TIP_INDEX;

export const SET_CUSTOM_TIP_VALUE = "SET_CUSTOM_TIP_VALUE";
export type SET_CUSTOM_TIP_VALUE = typeof SET_CUSTOM_TIP_VALUE;

export type SetIsTipSelected = ReturnType<typeof setIsTipSelected>;
export const setIsTipSelected = (isTipSelected: boolean) =>
    ({
        type: SET_IS_TIP_SELECTED,
        isTipSelected,
    } as const);

export type SetSelectedTipIndex = ReturnType<typeof setSelectedTipIndex>;
export const setSelectedTipIndex = (selectedTipIndex: number) =>
    ({
        type: SET_SELECTED_TIP_INDEX,
        selectedTipIndex,
    } as const);

export type SetCustomTipValue = ReturnType<typeof setCustomTipValue>;
export const setCustomTipValue = (customTipValue: number) =>
    ({
        type: SET_CUSTOM_TIP_VALUE,
        customTipValue,
    } as const);

/* Reducer */
export interface TipState {
    isTipSelected: boolean;
    selectedTipIndex: number;
    customTipValue: number;
}
export const TipInitialState: TipState = {
    isTipSelected: false,
    selectedTipIndex: 0,
    customTipValue: 0,
};

export default function tip(
    state = TipInitialState,
    action: SetIsTipSelected | SetSelectedTipIndex | SetCustomTipValue
): TipState {
    switch (action.type) {
        case SET_IS_TIP_SELECTED:
            return { ...state, isTipSelected: action.isTipSelected };
        case SET_SELECTED_TIP_INDEX:
            return { ...state, selectedTipIndex: action.selectedTipIndex };
        case SET_CUSTOM_TIP_VALUE:
            return { ...state, customTipValue: action.customTipValue };
        default:
            return state;
    }
}

/* Selectors */
export const getIsTipSelected = (state: any) => state.tip.isTipSelected;
export const getSelectedTipIndex = (state: any) => state.tip.selectedTipIndex;
export const getCustomTipValue = (state: any) => state.tip.customTipValue;
