import React from "react";
import { Helpers } from "@/utils";
import { ReactComponent as Visa } from "src/assets/icons/payment_methods/visa.svg";
import { ReactComponent as Mastercard } from "src/assets/icons/payment_methods/mastercard.svg";
import { ReactComponent as DefaultCard } from "src/assets/icons/payment_methods/default_card.svg";
import { ReactComponent as Amex } from "src/assets/icons/payment_methods/amex.svg";
import { ReactComponent as Discover } from "src/assets/icons/payment_methods/discover.svg";
import { ReactComponent as ApplePay } from "src/assets/icons/payment_methods/apple_pay.svg";
import { ReactComponent as GooglePay } from "src/assets/icons/payment_methods/google_pay.svg";

// Can't find this type in react-stripe, so recreating a minimal version here. This is https://stripe.com/docs/api/charges/object
export type StripeCharge = {
    payment_method_details: {
        card?: {
            wallet?: {
                type: string;
            };
            brand: string;
            last4: string;
        };
        card_present?: {
            brand: string;
            last4: string;
        };
    };
    amount_captured: number;
    amount_refunded: number;
};

type Props = {
    stripeCharge: StripeCharge;
};

function Payment({ stripeCharge }: Props) {
    const paymentMethodDetails = stripeCharge.payment_method_details;
    const amountCaptured = stripeCharge.amount_captured;
    const amountRefunded = stripeCharge.amount_refunded;
    const walletType = paymentMethodDetails?.card?.wallet?.type;
    const renderPaymentMethodLogo = () => {
        if (walletType != null) {
            switch (walletType) {
                case "amex_express_checkout":
                    return <Amex />;
                case "apple_pay":
                    return <ApplePay />;
                case "google_pay":
                    return <GooglePay />;
                case "masterpass":
                    return <Mastercard />;
                default:
                    return <DefaultCard />;
            }
        } else if (paymentMethodDetails.card) {
            switch (paymentMethodDetails.card.brand) {
                case "visa":
                    return <Visa />;
                case "mastercard":
                    return <Mastercard />;
                case "amex":
                    return <Amex />;
                case "discover":
                    return <Discover />;
                default:
                    return <DefaultCard />;
            }
        } else if (paymentMethodDetails.card_present) {
            switch (paymentMethodDetails.card_present.brand) {
                case "visa":
                    return <Visa />;
                case "mastercard":
                    return <Mastercard />;
                case "amex":
                    return <Amex />;
                case "discover":
                    return <Discover />;
                default:
                    return <DefaultCard />;
            }
        }
    };

    const renderPaymentMethodDigitsOrWallet = () => {
        if (walletType != null) {
            switch (walletType) {
                case "amex_express_checkout":
                    return `Amex Express Checkout`;
                case "apple_pay":
                    return `Apple Pay`;
                case "google_pay":
                    return `Google Pay`;
                case "masterpass":
                    return ` Masterpass`;
                default:
                    return `Digital Wallet`;
            }
        } else if (paymentMethodDetails.card) {
            return `•••• ${paymentMethodDetails.card.last4}`;
        } else if (paymentMethodDetails.card_present) {
            return `•••• ${paymentMethodDetails.card_present.last4}`;
        }
    };

    const renderChargeAmount = () => {
        if (amountRefunded > 0) {
            return `Refunded ${Helpers.toDollar(
                Helpers.centsToDollars(amountRefunded)
            )}`;
        } else {
            return Helpers.toDollar(Helpers.centsToDollars(amountCaptured));
        }
    };
    return (
        <div className="w-full h-auto px-6 pt-4 pb-6 bg-white flex-col justify-start items-start gap-4 inline-flex">
            <div className="w-auto text-stone-950 text-xl font-semibold leading-normal">
                Payment
            </div>
            <div className="w-full self-stretch pt-2 md:px-4 pb-4 justify-start items-center gap-4 inline-flex">
                <div className="w-auto h-auto relative bg-white rounded-md border border-zinc-200">
                    {renderPaymentMethodLogo()}
                </div>
                <div className="w-full flex flex-row space-between">
                    <div className="grow shrink basis-0 text-stone-950 text-base font-medium leading-normal">
                        {renderPaymentMethodDigitsOrWallet()}
                    </div>
                    <div className="text-stone-950 text-base font-medium leading-normal">
                        {renderChargeAmount()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;
