import { Helpers, cn } from "@/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartSelectors } from "@snackpass/accounting";
import { showModal, ModalsName, getActiveStore } from "@/redux";
import { Fulfillment, FulfillmentTypeEnum } from "@snackpass/snackpass-types";
import { ReactComponent as BagIcon } from "src/assets/icons/bag-fill.svg";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";

const FulfillmentToggle = ({ cartScreen }: { cartScreen?: boolean }) => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const showFulfillmentModal = () => {
        dispatch(showModal(ModalsName.fulfillmentModal));
    };
    const cartTableNumber = useSelector(CartSelectors.getCartTableNumber);
    const deliveryAddress = useSelector(CartSelectors.getCart).address;
    const activeCartFulfillment = useSelector(CartSelectors.getCartFulfillment);
    const activeFulfillment = activeCartFulfillment;

    if (!activeStore) return null;

    const handleShow = () => {
        showFulfillmentModal();
    };

    const getFulfillmentDescription = (
        activeFulfillment: Fulfillment,
        tableNumber: string,
    ) => {
        if (activeFulfillment === FulfillmentTypeEnum.Delivery) {
            const formatAddress = deliveryAddress.split(",").join(", ");
            return formatAddress;
        }
        if (activeFulfillment === FulfillmentTypeEnum.DineIn) {
            return tableNumber && `Table # ${tableNumber}`;
        }
    };

    const fulfillmentDescription = getFulfillmentDescription(
        activeCartFulfillment,
        cartTableNumber || "",
    );

    return (
        <Button
            className={cn("px-0 py-0 w-[100%] h-[100%] rounded-xl", {
                "bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] transition-shadow duration-500 ease-in-out shadow-default hover:shadow-hover rounded-xl active:shadow-focus border active:scale-95":
                    !cartScreen,
                "hover:bg-white": cartScreen,
            })}
            variant="ghost"
            onPress={handleShow}
            label={
                <div
                    className={cn(
                        "flex flex-col md:flex-row flex-1 md:items-center py-3 md:py-0 md:justify-between rounded-xl ",
                        {
                            "pl-3 pr-2 md:pr-4 h-[94px] md:h-[64px]":
                                !cartScreen,
                            "px-4 h-[78px]": cartScreen,
                        },
                    )}
                >
                    {cartScreen ? null : (
                        <div className="basis-1/8 pb-2 md:pb-0">
                            <BagIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                        </div>
                    )}
                    <div
                        className={cn("basis-6/8 w-full text-start", {
                            "px-0 md:px-3": !cartScreen,
                            "space-y-1": cartScreen,
                        })}
                    >
                        <div className="flex items-start text-base font-semibold text-primary">
                            {`${Helpers.capitalize(
                                activeFulfillment?.replace("_", "-"),
                            )} `}
                        </div>
                        <div className="line-clamp-1 text-start overflow-hidden text-ellipsis text-sm font-normal leading-4 text-secondary tracking-tight">
                            {`${
                                fulfillmentDescription
                                    ? fulfillmentDescription
                                    : ""
                            }`}
                        </div>
                    </div>
                    <div className="basis-1/8 hidden md:block">
                        <ChevronRightIcon className="w-4 h-4 text-[#999DA3]" />
                    </div>
                </div>
            }
        />
    );
};

export default FulfillmentToggle;
