import { CartActions } from "@snackpass/accounting";
import { FulfillmentTypeEnum } from "@snackpass/snackpass-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function useTableNumber() {
    const dispatch = useDispatch();
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        const tableNumber = query.get("table_number") || null;

        if (tableNumber) {
            dispatch(CartActions.setCartTableNumber(tableNumber));
            dispatch(
                CartActions.setCartFulfillment(FulfillmentTypeEnum.DineIn)
            );
        }
    }, []);
}
