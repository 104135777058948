import {
    FeePolicyPayer,
    FeePolicyRecipient,
    IPurchase,
    IPurchaseFee,
    PurchaseFeeEnum,
    ReservedFeeEnum
} from "@snackpass/snackpass-types";
import { verbose } from "../../verbose";

/*
 * Get all customer-facing purchase fees including the
 * legacy convenienceFee and deliveryFee1P
 *
 * @param {IPurchaseFee} purchase
 * @returns {IPurchaseFee[]}
 */
export const getApplicableFees = (purchase: IPurchase): IPurchaseFee[] => {
    const customerFees: IPurchaseFee[] = (purchase.fees || []).filter(
        (f: IPurchaseFee) => f.fee.payer === FeePolicyPayer.Customer
    );

    const convenienceFee: IPurchaseFee = {
        type: PurchaseFeeEnum.Custom,
        fee: {
            name: ReservedFeeEnum.ConvenienceStoreFee,
            payer: FeePolicyPayer.Customer,
            recipient: FeePolicyRecipient.Store,
            flat: purchase.convenienceFee || 0,
            percent: 0,
            rules: {}
        },
        total: purchase.convenienceFee || 0
    };

    const deliveryFee1P: IPurchaseFee = {
        type: PurchaseFeeEnum.Delivery,
        fee: {
            name: ReservedFeeEnum.DeliveryFee1P,
            payer: FeePolicyPayer.Customer,
            recipient: FeePolicyRecipient.Store,
            flat: purchase.deliveryFee || 0,
            percent: 0,
            rules: {}
        },
        total: purchase.deliveryFee || 0
    };

    const fees = [
        ...customerFees,
        ...(purchase.deliveryFee ? [deliveryFee1P] : []),
        ...(purchase.convenienceFee ? [convenienceFee] : [])
    ];

    verbose(`getApplicableFees`, { fees });

    return fees;
};
