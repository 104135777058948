import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import {
    ModalsName,
    getActivePromotion,
    getIsDiscountModalOpen,
    hideModal,
} from "@/redux";
import ModalContent from "./Content";
import { useMediaQuery } from "react-responsive";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const DiscountModal = () => {
    const dispatch = useDispatch();
    const activePromotion = useSelector(getActivePromotion);
    const discountModal = useSelector(getIsDiscountModalOpen);
    const onHide = () => {
        dispatch(hideModal(ModalsName.discountModal));
    };
    const isDesktop = useMediaQuery({ minWidth: 768 });

    if (isDesktop) {
        return (
            <Dialog open={discountModal} onOpenChange={onHide}>
                <DialogContent
                    onEscapeKeyDown={onHide}
                    className="h-[100%] md:max-h-[700px]"
                >
                    <div className="flex flex-col overflow-y-auto h-full relative w-full hidden-scroll">
                        <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    {activePromotion?.name}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 mb-20">
                            <ModalContent />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={discountModal} onOpenChange={onHide}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle> {activePromotion?.name}</SheetTitle>
                </SheetHeader>
                <div className="mt-4 mb-20 overflow-auto">
                    <ModalContent />
                </div>
            </SheetContent>
        </Sheet>
    );
};

export default DiscountModal;
