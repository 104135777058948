import {
    CartActions,
    CartSelectors,
    CheckoutSelectors,
} from "@snackpass/accounting";
import {
    DefaultTipType,
    FulfillmentTypeEnum,
} from "@snackpass/snackpass-types";
import fp from "lodash/fp";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveStore,
    getCustomTipValue,
    getSelectedTipIndex,
    setCustomTipValue,
} from "src/redux";
import { Helpers, cn } from "../../utils";
import { Button } from "@/components/ui/button";
import Cleave from "cleave.js/react";
import {
    CUSTOM_TIP_INDEX,
    NO_TIP_INDEX,
    TipOptionType,
    useTip,
} from "@/hooks/useTip";
import _ from "lodash";
import { onlyDigits } from "@/utils/Helpers";

type CustomTipProps = {
    onChange: (value: number) => void;
    onSelect: (optionType: TipOptionType, optionValue: number) => void;
    isSelected: boolean;
};

const getCustomTipButtonFlex = (showInput, showNoTip) => {
    if (showInput && showNoTip) return 0.6;
    if (showInput && !showNoTip) return 0.5;
    return 1;
};

const CustomTip = ({ isSelected, onChange, onSelect }: CustomTipProps) => {
    const dispatch = useDispatch();
    const customTipValue = useSelector(getCustomTipValue);
    const _setCustomTipValue = fp.compose(dispatch, setCustomTipValue);
    const [showCustomTipInput, setShowCustomTipInput] = useState(false);
    const activeStore = useSelector(getActiveStore);

    // Toggle Custom Tip Input based on selection
    useEffect(() => {
        if (!isSelected) {
            _setCustomTipValue(0);
        }
        setShowCustomTipInput(isSelected);
    }, [_setCustomTipValue, isSelected]);

    const onSelectCustomTip = () => {
        if (!isSelected) {
            onSelect(TipOptionType.CUSTOM_TIP, customTipValue);
            setShowCustomTipInput(true);
        }
    };

    const onChangeCustomTip = (event: any) => {
        const floatValue = parseFloat(onlyDigits(event.target.value));
        if (_.isNaN(floatValue)) {
            // when user clear the input, the value become NaN
            onChange(0);
            _setCustomTipValue(0);
        } else {
            onChange(floatValue);
            _setCustomTipValue(floatValue);
        }
    };

    if (!activeStore) return null;

    const customTipButtonFlex = getCustomTipButtonFlex(
        showCustomTipInput,
        activeStore.showNoTip,
    );

    return (
        <div className="py-1 flex flex-1 flex-row items-center justify-center h-[40px]">
            <div style={{ flex: customTipButtonFlex }}>
                <TipButton
                    tipValue={customTipValue}
                    tipOptionType={TipOptionType.CUSTOM_TIP}
                    isSelected={isSelected}
                    key={-2}
                    onPress={onSelectCustomTip}
                    displayText={"Custom Tip"}
                />
            </div>
            {showCustomTipInput ? (
                <div
                    style={{ flex: 1 - customTipButtonFlex }}
                    className="relative h-[40px] w-[97%] rounded-lg"
                >
                    <Cleave
                        placeholder="0.00"
                        options={{ numeral: true, numeralPositiveOnly: true }}
                        onChange={onChangeCustomTip}
                        value={customTipValue}
                        className="pl-5 text-base font-normal h-[100%] w-[100%] rounded-lg border-[2px] border-primary focus:outline-none text-primary"
                    />
                    <span className="absolute left-2 top-[20%] text-base font-normal text-primary">
                        $
                    </span>
                </div>
            ) : null}
        </div>
    );
};

const Tip = () => {
    const dispatch = useDispatch();
    const selectedTipIndex = useSelector(getSelectedTipIndex);
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const activeStore = useSelector(getActiveStore);
    const _setTip = fp.compose(dispatch, CartActions.setCartTip);
    const isThirdPartyDeliveryOrder =
        fulfillment === FulfillmentTypeEnum.Delivery &&
        activeStore?.delivery &&
        !activeStore?.storeDelivers;
    const description = isThirdPartyDeliveryOrder
        ? "Your tip supports your delivery driver"
        : `100% of your tip supports ${activeStore?.name}.`;
    const { tipOptions, hideTip, onSelectTip } = useTip();

    if (!activeStore) return null;
    const onChangeCustomTip = (tipFlatValue) => {
        onSelectTip(TipOptionType.CUSTOM_TIP, tipFlatValue);
    };

    const isTipSelected = (index: number) => {
        const selected = selectedTipIndex === index;
        return selected;
    };
    if (hideTip) {
        _setTip(0);
        return null;
    } else {
        return (
            <div className="w-[100%]">
                <div className="flex flex-row flex-1 items-center">
                    <span className="text-primary text-base font-semibold leading-6">
                        Add a tip
                    </span>
                </div>
                <div className="text-secondary leading-6 py-2">
                    {description}
                </div>
                <div className="flex flex-col gap-1">
                    <div className="p-1 flex flex-1 flex-row items-center justify-center gap-1 bg-tertiary rounded-lg">
                        {activeStore.showNoTip ? (
                            <TipButton
                                tipValue={0}
                                tipOptionType={TipOptionType.FIXED_TIP}
                                onPress={onSelectTip}
                                isSelected={isTipSelected(NO_TIP_INDEX)}
                                displayText={"No Tip"}
                            />
                        ) : null}
                        {tipOptions.map((tip, i) => (
                            <TipButton
                                tipValue={tip}
                                tipOptionType={TipOptionType.FIXED_TIP}
                                key={i}
                                onPress={onSelectTip}
                                isSelected={isTipSelected(i)}
                                displayText={
                                    activeStore.defaultTip.defaultTipType ===
                                    DefaultTipType.Flat
                                        ? `$${tip.toFixed(2)}`
                                        : `${tip}%`
                                }
                                displayTipAmount={
                                    activeStore.defaultTip.defaultTipType ===
                                    DefaultTipType.Percent
                                }
                            />
                        ))}
                    </div>
                    <CustomTip
                        onSelect={onSelectTip}
                        onChange={onChangeCustomTip}
                        isSelected={isTipSelected(CUSTOM_TIP_INDEX)}
                    />
                </div>
            </div>
        );
    }
};

type TipButtonProps = {
    tipValue: number;
    tipOptionType: TipOptionType;
    isSelected: boolean;
    displayText: string;
    onPress: (tipOptionType: TipOptionType, tipPercentOption: number) => void;
    displayTipAmount?: boolean;
};

const TipButton = ({
    tipValue,
    tipOptionType,
    isSelected,
    displayText,
    onPress,
    displayTipAmount,
}: TipButtonProps) => {
    const tipPercentToDecimal = tipValue / 100;
    const subtotal = useSelector(CheckoutSelectors.getSubtotal);
    const tip = Helpers.round(tipPercentToDecimal * subtotal);

    return (
        <Button
            className={cn("flex-1 h-[40px]", {
                "bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] hover:bg-white":
                    isSelected,
                "bg-transparent hover:bg-white/60": !isSelected,
                "px-0 outline-none border-none shadow-none":
                    displayText === "Custom Tip",
            })}
            variant="ghost"
            onPress={() => (isSelected ? {} : onPress(tipOptionType, tipValue))}
            label={
                <div className="flex flex-col items-center justify-start gap-[2px]">
                    <span
                        className={cn("text-sm", {
                            "text-primary": isSelected,
                            "text-secondary": !isSelected,
                        })}
                    >
                        {displayText}
                    </span>
                    {displayTipAmount ? (
                        <span
                            className={cn("text-xs font-normal text-secondary")}
                        >
                            {tip ? Helpers.toDollar(tip) : null}
                        </span>
                    ) : null}
                </div>
            }
        />
    );
};

export default Tip;
