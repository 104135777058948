import { ActiveCartItemReducer } from "../../types";

import {
    SET_NOTES,
    SET_CART_ITEM,
    CLEAR_ACTIVE_CART_ITEM,
    ActiveCartItemActions,
    SELECT_ADDON,
    SELECT_INCREMENT_ADDON,
    SELECT_DECREMENT_ADDON,
    SELECT_ADDON_QUANTITY
} from "./actions";
import {
    selectAddon,
    selectIncrementAddon,
    selectDecrementAddon,
    selectAddonQuantity
} from "../../accountingFormulas/selectAddon";
import { preSelectedAddons } from "../../utils/preSelectAddons";

// This reducer deals with the selection on the ProductScreen page, where you
// select add ons. This was formerly in state of the Product Screen component

// note: this reducer contains the actions in the same file. May consider
// refactoring other reducers like this

export const activeCartItemInitialState: ActiveCartItemReducer = {
    product: null,
    notes: "",
    selectedAddons: []
};

export function activeCartItem(
    state = activeCartItemInitialState,
    action: ActiveCartItemActions
): ActiveCartItemReducer {
    switch (action.type) {
        case SELECT_ADDON: {
            const [error, updatedSelectedAddons] = selectAddon(
                action.addon,
                state
            );
            if (error) {
                return state;
            }
            return {
                ...state,
                selectedAddons: updatedSelectedAddons
            };
        }
        case SELECT_INCREMENT_ADDON: {
            const [error, updatedSelectedAddons] = selectIncrementAddon(
                action.addon,
                state
            );
            if (error) {
                return state;
            }
            return {
                ...state,
                selectedAddons: updatedSelectedAddons
            };
        }
        case SELECT_DECREMENT_ADDON: {
            const [error, updatedSelectedAddons] = selectDecrementAddon(
                action.addon,
                state
            );
            if (error) {
                return state;
            }
            return {
                ...state,
                selectedAddons: updatedSelectedAddons
            };
        }
        case SELECT_ADDON_QUANTITY: {
            const [error, updatedSelectedAddons] = selectAddonQuantity(
                action.addon,
                action.quantity,
                state
            );
            if (error) {
                return state;
            }
            return {
                ...state,
                selectedAddons: updatedSelectedAddons
            };
        }
        case CLEAR_ACTIVE_CART_ITEM:
            return activeCartItemInitialState;
        case SET_CART_ITEM:
            return {
                product: action.product,
                promotion: action.reward ? null : action.promotion,
                reward: action.reward,
                dealItem: action.dealItem,
                pointsAfterPromotion: action.pointsAfterPromotion,
                selectedAddons: preSelectedAddons({ product: action.product }),
                digitalGiftCardInfo: action.digitalGiftCardInfo
            };
        case SET_NOTES:
            return { ...state, notes: action.notes };
        default:
            return state;
    }
}
