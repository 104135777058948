import { CartAdjustmentType } from "@snackpass/snackpass-types";
import { createSelector } from "reselect";
import {
    flattenCartItem,
    groupFlattenedCartItems
} from "../../accountingFormulas/flattenCartItem";
import { ICart, ReduxState } from "../../types";
import { getIsGiftCardProduct } from "../../utils";
import { fallbackToEmptyArray } from "../../utils/arrays/fallbackToEmptyArray";

export const getCart = (state: Pick<ReduxState, "cart">): ICart => state.cart;

export const getCartItemsUnflattened = createSelector(
    [getCart],
    (cart) => cart.items
);

export const getCartItems = createSelector(
    [getCartItemsUnflattened],
    (cartItems) => cartItems.map((cartItem, i) => flattenCartItem(cartItem, i))
);

export const getDoesCartHaveAnyGiftCardItems = createSelector(
    [getCartItems],
    (cartItems) =>
        cartItems.some((cartItem) => getIsGiftCardProduct(cartItem.product))
);

export const isCartOnlyGiftCardItems = createSelector(
    [getCartItems],
    (cartItems) =>
        cartItems.every((cartItem) => getIsGiftCardProduct(cartItem.product))
);

export const getCartItemsGrouped = createSelector([getCartItems], (cartItems) =>
    groupFlattenedCartItems(cartItems)
);

export const getCartDealGroups = createSelector(
    [getCart],
    (cart) => cart.dealGroups
);

export const getNumCartItems = createSelector(
    [getCart],
    (cart) => cart.items.length
);

export const getCartIsEmpty = createSelector(
    [getCart],
    (cart) => cart.items.length === 0 && cart.dealGroups.length === 0
);
export const getCartTip = createSelector([getCart], (cart) => cart.tip);

export const getCartFulfillment = createSelector(
    [getCart],
    (cart) => cart.fulfillment
);

export const getCartWhen = createSelector([getCart], (cart) => {
    return cart.when;
});

export const getCartIsCatering = createSelector([getCart], (cart) => {
    return cart.isCatering;
});

export const getCartHeadcount = createSelector([getCart], (cart) => {
    return cart.headcount;
});

export const getCartEventType = createSelector([getCart], (cart) => {
    return cart.eventType;
});

export const getCartScheduleDate = createSelector([getCart], (cart) => {
    return cart.scheduledDate;
});

export const getCartStoreId = createSelector([getCart], (cart) => {
    return cart.store ? cart.store._id : null;
});

export const getCartTableNumber = createSelector(
    [getCart],
    (cart) => cart.tableNumber
);

export const getPickupEstimate = createSelector(
    [getCart],
    (cart) => cart.pickupEstimate
);

export const getSelectedCartDeliveryQuote = createSelector(
    [getCart],
    (cart) => cart.selectedDeliveryQuote || null
);

export const getCartDeliveryQuotes = createSelector(
    [getCart],
    (cart) => cart.deliveryQuotes
);

export const getCartCanDeliver = createSelector(
    [getCart],
    (cart) => cart.canDeliver
);

export const getCartDeliveryError = createSelector(
    [getCart],
    (cart) => cart.deliveryError
);

export const getNumberOfBags = createSelector(
    [getCart],
    (cart) => cart.numberOfBags || 0
);

export const getCartAdjustments = createSelector([getCart], (cart) =>
    fallbackToEmptyArray(cart.cartAdjustments)
);

export const getCartFlatDiscounts = createSelector(
    [getCartAdjustments],
    (cartAdjustments) =>
        cartAdjustments?.filter(
            (adjustment) => adjustment.type === CartAdjustmentType.DiscountFlat
        )
);

export const getCartPercentDiscounts = createSelector(
    [getCartAdjustments],
    (cartAdjustments) =>
        cartAdjustments?.filter(
            (adjustment) =>
                adjustment.type === CartAdjustmentType.DiscountPercent
        )
);

export const getCartAddedCharge = createSelector(
    [getCartAdjustments],
    (cartAdjustments) =>
        cartAdjustments?.filter(
            (adjustment) => adjustment.type === CartAdjustmentType.SurchargeFlat
        )
);
