import { Geolocation, IStore } from "@snackpass/snackpass-types";
import { StoreMarker } from "./StoreMarker";
import { CurrentLocationMarker, Map } from "src/SharedComponents";
import { cn } from "@/utils";
import { useSelector } from "react-redux";
import { getMyLocation } from "@/redux";
import { useSortStores } from "@/screens/LocationsScreen.tsx/hooks/useSortStores";
import { getCenter, getSelectedStore } from "@/redux/locations";
import { getActiveStore } from "@snackpass/accounting";

const renderCurrentLocationMarker = (location?: Geolocation) => {
    if (!location) {
        return null;
    }
    return (
        <CurrentLocationMarker
            lat={location.coordinates[1]}
            lng={location.coordinates[0]}
        />
    );
};

// Note: StoreMarker must be called using the TSX syntax vs. function syntax. Otherwise we will
// occasionally encounter the error "React Error: Rendered fewer hooks than expected". See this
// article for more details about this issue:
// https://medium.com/@jonchurch/how-to-fix-react-error-rendered-fewer-hooks-than-expected-e6a378985d3c
// XXX We are hiding a fundamental issue here. This is BAD. FIX ME PLEASE.
const renderStoreMarkers = (stores: IStore[], selectedStore) =>
    stores.map((store, i) => (
        <StoreMarker
            store={store}
            lat={store.geolocation.coordinates[1]}
            lng={store.geolocation.coordinates[0]}
            key={i}
            selectedStoreId={selectedStore?._id}
        />
    ));

type Props = {
    onSelectStore?: (store?: IStore) => void;
    className?: string;
};

export const StoreMap = ({ className }: Props) => {
    const currentLocation = useSelector(getMyLocation);
    const currentLocationMarker = renderCurrentLocationMarker(currentLocation);
    const { sortedStores } = useSortStores();
    const activeStore = useSelector(getActiveStore);
    const selectedStore = useSelector(getSelectedStore);
    const _store = selectedStore || activeStore;
    const storeMarkers = renderStoreMarkers(sortedStores || [], _store);
    const center = useSelector(getCenter);

    return (
        <div
            className={cn(
                "w-full rounded-[10px] overflow-hidden h-[260px]",
                className,
            )}
        >
            <Map center={center || undefined}>
                {storeMarkers}
                {currentLocationMarker}
            </Map>
        </div>
    );
};
