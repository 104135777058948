// This file flattens a cart item,
// ie  a cartItem like { product, promotion, reward } => { totalPriceAfterDiscount... }
import { IPromotion, IReward, PromoTypes } from "@snackpass/snackpass-types";
import _ from "lodash";
import { v4 as uuid } from "uuid";

import {
    CartAddon,
    CartAddonFlat,
    CartItemFlat,
    CartItem,
    CartItemGrouping
} from "../types";

import { applyDiscountToAddon, calcCartItemPrices } from "./formulas";

// create a cart item from parts
// will also create the cart item prices from the parts
export function flattenCartItem(
    cartItem: CartItem,
    cartIndex = -1
): CartItemFlat {
    const {
        _id,
        product,
        reward,
        dealItem,
        dealGroupId,
        selectedAddons,
        notes = "",
        weight,
        digitalGiftCardInfo
    } = cartItem;
    let { promotion } = cartItem;

    const prices = calcCartItemPrices(cartItem);

    // set points
    let { pointsAfterPromotion } = cartItem;
    let { points } = product;

    if (reward) {
        pointsAfterPromotion = 0;
        points = 0;
        promotion = null;
    }

    // pass thru rewards: do not award points if it is a reward promo item,
    if (promotion && promotion.type === PromoTypes.Reward) {
        pointsAfterPromotion = 0;
        points = 0;
    }

    // if deal item, do not award points if it is a discounted item,
    // deal items that are non discounted can have points
    if (dealItem && prices.isDiscounted) {
        pointsAfterPromotion = 0;
        points = 0;
    }

    return {
        _id,
        cartIndex,
        pointsAfterPromotion,
        points,
        product,
        promotion,
        reward,
        dealItem,
        dealGroupId,
        notes,
        selectedAddons: selectedAddons
            ? selectedAddons.map((addon) =>
                  flattenCartAddon(addon, reward ? reward : promotion)
              )
            : selectedAddons,
        groupUuid: cartItem.groupUuid,
        weight,
        digitalGiftCardInfo,
        ...prices
    };
}

const sumBy =
    <T>(fn: (arg0: T) => number) =>
    (data: T[]): number =>
        _.sum(data.map(fn));
const sumByPoints = sumBy<CartItemFlat>((ci) => ci.points || 0);

export function groupFlattenedCartItems(
    cartItemsFlat: CartItemFlat[]
): CartItemGrouping[] {
    // Group cart items by the groupUuid
    const groupedCartItems = _.groupBy(cartItemsFlat, (item) =>
        _.get(item, "groupUuid", uuid())
    );

    // Map the grouped items to be CartItemGrouping[]
    return Object.entries(groupedCartItems).map<CartItemGrouping>(
        ([groupUuid, cartItems]) => {
            return {
                groupUuid,
                items: cartItems,
                quantity: cartItems.length,
                points: sumByPoints(cartItems),
                totalPrice: _.sum(cartItems.map((item) => item.totalPrice)),
                totalPriceAfterDiscount: _.sum(
                    cartItems.map((item) => item.totalPriceAfterDiscount)
                ),
                basePrice: _.sum(cartItems.map((item) => item.basePrice)),
                basePriceAfterDiscount: _.sum(
                    cartItems.map(
                        (item) => item.basePriceAfterDiscount || item.basePrice
                    )
                )
            };
        }
    );
}

// create a cart addon from parts
export function flattenCartAddon(
    cartAddon: CartAddon,
    promotionOrReward?: IPromotion | IReward | null
): CartAddonFlat {
    const { addon, addonGroup, quantity } = cartAddon;
    let priceAfterDiscount = addon.price;
    if (promotionOrReward) {
        priceAfterDiscount = applyDiscountToAddon(
            addon.price,
            promotionOrReward
        );
    }
    const totalPrice = addon.price * (quantity || 1);
    const totalPriceAfterDiscount = priceAfterDiscount * (quantity || 1);
    return {
        ...addon,
        priceAfterDiscount,
        addonGroup,
        quantity,
        totalPrice,
        totalPriceAfterDiscount
    };
}
