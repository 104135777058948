import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPromotion, PromoTypes } from "@snackpass/snackpass-types";

export const SUPPORTED_PROMOTION_TYPES = [
    PromoTypes.Deal,
    PromoTypes.Discount,
    PromoTypes.Reward,
    PromoTypes.GiftCard,
];

export enum SUPPORTED_PROMOTION_TYPES_TYPE {
    Discount = PromoTypes.Discount,
    Deal = PromoTypes.Deal,
    GiftCard = PromoTypes.GiftCard,
}

export const SUPPORTED_PROMOTIONS = Object.values(
    SUPPORTED_PROMOTION_TYPES_TYPE,
).map((type) => type.toUpperCase());

interface PromotionsState {
    promotions: IPromotion[];
    activePromotion: IPromotion | null;
}

const initialState: PromotionsState = {
    promotions: [],
    activePromotion: null,
};

export const promotionsSlice = createSlice({
    name: "promotions",
    initialState,
    reducers: {
        setPromotions: (state, action: PayloadAction<IPromotion[]>) => {
            state.promotions = action.payload;
        },
        setActivePromotion: (
            state,
            action: PayloadAction<IPromotion | null>,
        ) => {
            state.activePromotion = action.payload;
        },
    },
});

export const { setPromotions, setActivePromotion } = promotionsSlice.actions;

export const getPromotions = (state): IPromotion[] =>
    state.promotion.promotions;

export const getGiftCardPromotion = (state): IPromotion[] =>
    state.promotion.promotions.filter((p) => p.type === PromoTypes.GiftCard);

export const getActivePromotion = (state): IPromotion =>
    state.promotion.activePromotion;

export default promotionsSlice.reducer;
