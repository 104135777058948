import { LoyaltyActionType, LoyaltyStateType } from "./types";
import { PunchcardActionTypes } from "./types/punchcards";
import { RewardPromoActionTypes } from "./types/reward-promos";
import { RewardActionTypes } from "./types/rewards";

export const loyaltyInitialState: LoyaltyStateType = {
    // Punchcards
    userStorePunchcardMap: {},
    userStoreTotalPointsMap: {},
    loadingPunchcards: false,
    errorPunchcards: false,
    errorMessagePunchcards: null,

    // Reward promos, passthrough
    storeRewardPromosMap: {},
    storeRewardPromoSummariesMap: {},
    loadingRewardPromos: false,
    errorRewardPromos: false,
    errorMessageRewardPromos: null,
    activeRewardPromo: null,

    // Legacy rewards, non-passthrough
    userStoreRewardsMap: {},
    loadingRewards: false,
    errorRewards: false,
    errorMessageRewards: null,
    activeReward: null
};

export const loyaltyReducer = (
    state: LoyaltyStateType,
    action: LoyaltyActionType
): LoyaltyStateType => {
    switch (action.type) {
        case PunchcardActionTypes.SET_PUNCHCARDS_BY_USER_BY_STORE_ACTION:
            return {
                ...state,
                userStorePunchcardMap: {
                    ...state.userStorePunchcardMap,
                    [action.payload.userId]: {
                        ...state.userStorePunchcardMap[action.payload.userId],
                        ...action.payload.punchcardsStoreMap
                    }
                },
                userStoreTotalPointsMap: {
                    ...state.userStoreTotalPointsMap,
                    [action.payload.userId]: {
                        ...state.userStoreTotalPointsMap[action.payload.userId],
                        ...action.payload.totalPointsStoreMap
                    }
                }
            };
        case RewardPromoActionTypes.SET_REWARD_PROMOS_BY_STORE_ACTION:
            return {
                ...state,
                storeRewardPromosMap: {
                    ...state.storeRewardPromosMap,
                    [action.payload.storeId]: action.payload.rewardPromos
                }
            };
        case RewardPromoActionTypes.SET_REWARD_PROMO_SUMMARIES_BY_STORE_ACTION:
            return {
                ...state,
                storeRewardPromoSummariesMap: {
                    ...state.storeRewardPromoSummariesMap,
                    [action.payload.storeId]:
                        action.payload.rewardPromoSummaries
                }
            };
        case RewardPromoActionTypes.SET_ACTIVE_REWARD_PROMO:
            return {
                ...state,
                activeRewardPromo: action.payload.activeRewardPromo
            };
        case RewardActionTypes.SET_REWARD_BY_STORE_ACTION:
            return {
                ...state,
                userStoreRewardsMap: {
                    ...state.userStoreRewardsMap,
                    [action.payload.userId]: {
                        ...state.userStoreRewardsMap[action.payload.userId],
                        ...action.payload.rewardStoreMap
                    }
                }
            };
        case RewardActionTypes.SET_ACTIVE_REWARD:
            return {
                ...state,
                activeReward: action.payload.activeReward
            };
        default:
            return state;
    }
};
