import React, { useState, useEffect, useCallback } from "react";
import { Colors } from "../../utils";
import { Text } from "../../SharedComponents";
import { Link, match } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import OrderStatusTab from "./OrderStatus";
import ReceiptTab from "./Receipt";
import Tabs from "./Tabs";
import { useDispatch, useSelector } from "react-redux";
import { Segment, SegmentEventNames } from "src/utils/Segment";
import {
    fetchPurchase,
    getPurchaseReceiptData,
    getPurchaseReceiptError,
    isPurchaseSubscribable,
} from "@/redux/purchaseReceipt";
import SocketManager from "./websocket";
import { SystemColors } from "@snackpass/design-system";
import { StripeCharge } from "./Payment";
import API, { isNetworkError } from "@/utils/Api/REST";
import { first } from "lodash";
import { clearUtmTracking, getActiveStore } from "@/redux";
import fp from "lodash/fp";
import { createReceiptToken } from "@/utils/Helpers";
import config from "@/config";
import { useInitSegment } from "@/navigation/hooks/useInitSegment";
import * as Sentry from "@sentry/react";

type PurchaseDetailsProps = {
    match: match<{ purchaseId: string; token: string; tab?: string }>;
};

export const WS = new SocketManager();
const RESYNC_INTERVAL = 1000 * 60 * 2; // 2 minutes

const PurchaseDetails: React.FC<PurchaseDetailsProps> = ({ match }) => {
    useInitSegment();
    const dispatch = useDispatch();
    const _clearUtmTracking = fp.compose(dispatch, clearUtmTracking);
    const activeStore = useSelector(getActiveStore);
    const purchaseId = match.params.purchaseId;
    const reduxPurchase = useSelector(getPurchaseReceiptData);
    // We could possibly have a previous purchase in the redux store, so null it out unless its for the current URL.
    const purchase = purchaseId === reduxPurchase?._id ? reduxPurchase : null;
    const error = useSelector(getPurchaseReceiptError);
    // While on dev we attempt to construct the token automatically rather than using the from URL
    const token = config.IS_LOCAL
        ? createReceiptToken(purchaseId)
        : match.params.token;
    const tab = match.params.tab;
    const [showSuccessMessage, setShowSuccessMessage] =
        useState<boolean>(false);

    const [stripeCharge, setStripeCharge] = useState<StripeCharge | null>(null);
    const loadStripeCharge = useCallback(async () => {
        try {
            let stripeCharge = await API.stripe.retrieveChargeByPurchase(
                purchaseId,
                token,
            );

            setStripeCharge(stripeCharge.data);
        } catch (err) {}
    }, [purchaseId, token]);
    const charges = purchase?.charges;
    useEffect(() => {
        const firstCharge = first(charges);
        const chargeId = firstCharge?.chargeId;
        if (
            chargeId &&
            firstCharge.chargeType === "StripeCharge" &&
            chargeId.startsWith("ch_")
        ) {
            loadStripeCharge();
        }
    }, [loadStripeCharge, charges]);

    useEffect(() => {
        if (purchase) {
            if (isPurchaseSubscribable(purchase)) {
                WS.connect(dispatch);
            } else {
                // if the purchase is completed or canceled, there are no more updates to subscribe to
                // so disconnect.
                WS.disconnect();
            }
        }
    }, [purchaseId, purchase, token, dispatch]);

    const dispatchFetchPurchase = useCallback(
        (purchaseId, token) => {
            dispatch(fetchPurchase({ purchaseId, token }));
        },
        [dispatch],
    );
    useEffect(() => {
        if (error != null) {
            // don't auto refresh if there was an error, even if it was a temporary error
            // the user can refresh manually.
            return;
        }
        // Auto-resync purchase every 2 minutes
        const resyncInterval = setInterval(() => {
            dispatchFetchPurchase(purchaseId, token);
        }, RESYNC_INTERVAL);

        return () => {
            clearInterval(resyncInterval);
        };
    }, [dispatchFetchPurchase, purchaseId, token, error]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const showSuccessMessage = query.get("showSuccessMessage") === "true";
        setShowSuccessMessage(showSuccessMessage);
        window.scrollTo(0, 0);
        WS.setPurchaseId(purchaseId, token);
        if (purchase?._id !== purchaseId) {
            // Initial fetch only triggers if we haven't loaded the purchase already.
            dispatchFetchPurchase(purchaseId, token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseId, token]);

    useEffect(() => {
        // Track when the user opens the smart receipt page with the distinction of direct link vs. from the completing purchase
        Segment.track(SegmentEventNames.OPENED_SMART_RECEIPT, {
            purchaseId,
            direct_link: !showSuccessMessage,
        });

        return () => {
            _clearUtmTracking();
        };
    }, []);

    const isPurchaseLoaded = purchase != null;
    useEffect(() => {
        if (isPurchaseLoaded) {
            Segment.track(
                SegmentEventNames.OPENED_SMART_RECEIPT_WITH_PURCHASE_DETAILS,
                {
                    purchaseId,
                    direct_link: !showSuccessMessage,
                    transactionSource: purchase.transactionSource,
                    storeId: purchase.store?._id,
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPurchaseLoaded]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [tab]);

    const backToMenu = () => {
        if (!purchase || !purchase._id) {
            return null;
        }

        return (
            <Link to={`/${activeStore?.slug}`}>
                <div className="w-auto h-auto flex-col justify-center items-center gap-1 py-4 hidden md:flex">
                    <img
                        className="w-12 h-12 relative rounded-[144px]"
                        alt="store logo"
                        src={activeStore?.logoUrl || ""}
                    />
                    <div className="text-stone-950 text-l font-semibold leading-normal text-center">
                        {activeStore?.name}
                    </div>
                </div>
            </Link>
        );
    };

    if (error && purchase == null) {
        if (isNetworkError(error) === false) {
            // This is an error we'd like to track, so send to sentry
            Sentry.captureException(
                new Error("error fetching purchase details"),
                { extra: { originalError: error, purchaseId, token } },
            );
            return;
        }
        const errorName = isNetworkError(error) ? "Network Error" : error.name;
        const errorMessage = isNetworkError(error)
            ? "Please refresh the page to try again."
            : error.message;
        return (
            <span style={customStyles.error}>
                <div>
                    <Text xlarge color={SystemColors.melon60}>
                        {errorName}
                    </Text>
                </div>
                <div>
                    <Text color={SystemColors.melon60}>{errorMessage}</Text>
                </div>
            </span>
        );
    }

    if (purchase == null || activeStore == null) {
        return <Loading />;
    }

    const resolvedTab = match.params.tab ?? "status";
    return (
        <div className="flex flex-col items-center bg-neutral-100 min-h-screen">
            {backToMenu()}
            <div className="flex flex-col bg-white animated slideInUp faster max-w-screen-sm shadow-md shadow-gray-200 rounded-tl-xl rounded-tr-xl md:mb-6">
                <Tabs
                    resolvedTab={resolvedTab}
                    purchaseId={purchaseId}
                    token={token}
                    isMobile={false}
                    isFooter={false}
                />
                {resolvedTab === "status" ? (
                    <OrderStatusTab
                        purchase={purchase}
                        activeStore={activeStore}
                        authToken={token}
                    />
                ) : (
                    <ReceiptTab
                        purchase={purchase}
                        activeStore={activeStore}
                        stripeCharge={stripeCharge}
                    />
                )}
                <Tabs
                    resolvedTab={resolvedTab}
                    purchaseId={purchaseId}
                    token={token}
                    isMobile={true}
                    isFooter={true}
                />
            </div>
        </div>
    );
};

export const Loading = () => (
    <div style={customStyles.loading}>
        <ThreeDots color="black" height={30} width={60} />
    </div>
);

export const customStyles = {
    modal: {
        flex: 1,
        backgroundColor: Colors.white,
        bottom: 0,
    },
    error: {
        textAlign: "center" as "center",
        margin: "25px",
    },
    loading: {
        padding: "100px 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
};

export default PurchaseDetails;
