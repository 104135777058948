import React, { useState } from "react";
import { Geolocation } from "@snackpass/snackpass-types";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GetUserLocation from "@/components/SetMyLocation/GetUserLocation";
import { AddressInput } from "./AddressInput";
import { useDispatch } from "react-redux";
import { updateMyLocation } from "@/redux";

export const SetMyLocation = () => {
    const [addressInput, setAddressInput] = useState<string>("");
    const dispatch = useDispatch();

    const onSelectAddress = async (address: string) => {
        setAddressInput(address);
        // Resolve the geolocation from the address
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);

        let geolocation: Geolocation | null = null;
        if (latLng && latLng.lat && latLng.lng) {
            geolocation = {
                type: "Point",
                coordinates: [latLng.lng, latLng.lat],
            };
            dispatch(updateMyLocation(geolocation));
        }
    };

    return (
        <div className="flex flex-row items-start mb-[10px]">
            <div style={{ flex: 1 }}>
                <AddressInput
                    addressInput={addressInput}
                    onAddressChange={(value) => {
                        setAddressInput(value);
                    }}
                    onSelectAddress={onSelectAddress}
                />
            </div>
            <GetUserLocation />
        </div>
    );
};
