import { View } from "src/SharedComponents";
import { CSSProperties } from "styled-components";
import _ from "lodash";
import SnackpassLogo from "../../assets/logos/Logo - 512.png";

type Props = {
    storeName: string;
    color: string;
};

export const GiftCardHeader = ({ storeName, color }: Props) => {
    return (
        <View style={styles.outerCardContainer}>
            <View
                style={{
                    ...styles.storeName,
                    color,
                }}
            >
                {_.truncate(storeName, { length: 20 })}
            </View>
            <>
                <img
                    src={SnackpassLogo}
                    alt="Snackpass Logo"
                    style={styles.logo}
                />
            </>
        </View>
    );
};

const styles = {
    outerCardContainer: {
        flexDirection: "row",
        overflow: "hidden",
        justifyContent: "space-around",
        textAlign: "center",
        borderRadius: 10,
    } as CSSProperties,

    storeName: {
        width: "70%",
        height: "50%",
        backgroundColor: "#FFF",
        textAlign: "center",
        fontWeight: 700,
        fontStyle: "normal",
        fontSize: "18px",
        paddingLeft: "5px",
        paddingRight: "5px",
        borderRadius: 8,
        marginTop: "6.5%",
    } as CSSProperties,
    logo: {
        width: "10%",
        marginTop: "5%",
    } as CSSProperties,
};
