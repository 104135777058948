import React from "react";
import { useSelector } from "react-redux";
import { ActiveCartItemSelectors } from "@snackpass/accounting";
import { IProduct } from "@snackpass/snackpass-types";

import { getIsEditCartItem } from "@/redux";
import { DialogFooter } from "@/components/ui/dialog";
import QuantityWeightControl from "./QuantityWeightControl";
import AddToCartButton from "./AddToCartButton";
import { useMediaQuery } from "react-responsive";

import { SheetFooter } from "@/components/ui/sheet";

type Props = {
    activeProduct: IProduct;
    quantity: number;
    weight: number;
    setWeight?: React.Dispatch<React.SetStateAction<number>>;
    setQuantity?: React.Dispatch<React.SetStateAction<number>>;
    handleAddItemToCart: () => void;
};

export function FooterAddToCartButton({
    activeProduct,
    quantity,
    weight,
    setQuantity,
    setWeight,
    handleAddItemToCart,
}: Props) {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const selectedAddons = useSelector(
        ActiveCartItemSelectors.getSelectedAddons,
    );
    const editCartItem = useSelector(getIsEditCartItem);

    if (isDesktop) {
        return (
            <DialogFooter className="w-[100%] border-t bg-white md:px-6">
                <div className="flex w-full flex-row">
                    <div className="hidden md:flex">
                        <QuantityWeightControl
                            quantity={quantity}
                            weight={weight}
                            setQuantity={setQuantity}
                            setWeight={setWeight}
                        />
                    </div>
                    <AddToCartButton
                        activeProduct={activeProduct}
                        quantity={quantity}
                        weight={weight}
                        selectedAddons={selectedAddons}
                        handleAddItemToCart={handleAddItemToCart}
                        editCartItem={editCartItem}
                    />
                </div>
            </DialogFooter>
        );
    }
    return (
        <SheetFooter className="px-6 border-t pt-3 pb-2">
            <div className="flex w-full flex-row">
                <div className="hidden md:flex">
                    <QuantityWeightControl
                        quantity={quantity}
                        weight={weight}
                        setQuantity={setQuantity}
                        setWeight={setWeight}
                    />
                </div>
                <AddToCartButton
                    activeProduct={activeProduct}
                    quantity={quantity}
                    weight={weight}
                    selectedAddons={selectedAddons}
                    handleAddItemToCart={handleAddItemToCart}
                    editCartItem={editCartItem}
                />
            </div>
        </SheetFooter>
    );
}
