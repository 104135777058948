import { useEffect } from "react";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import {
    getActiveChainStores,
    getMyLocation,
    hasLocationSet,
    hideModal,
    ModalsName,
} from "@/redux";
import { useDispatch, useSelector } from "react-redux";
import { Geolocation } from "@snackpass/snackpass-types";
import { Link } from "react-router-dom";
import { StoreUtils } from "@/utils";
import { Button } from "@/components/ui/button";
import { StoreList } from "./StoreList";
import { DistanceWarning } from "./Warning";
import { SetMyLocation } from "@/components/SetMyLocation";
import { StoreMap } from "@/components/StoreMap";
import { useMediaQuery } from "react-responsive";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { setCenter } from "@/redux/locations";
import { useSelectStore } from "@/screens/LocationsScreen.tsx/hooks/useSelectStore";

const ChooseLocationModal = () => {
    const dispatch = useDispatch();
    const storeLocationsModal = useSelector(
        (state: any) => state.modals.modals.storeLocationsModal,
    );

    const onHide = () => {
        dispatch(hideModal(ModalsName.storeLocationsModal));
    };

    const activeChainStores = useSelector(getActiveChainStores);
    const hasLocation = useSelector(hasLocationSet);
    const isDesktop = useMediaQuery({ minWidth: 768 });

    const myLocation = useSelector(getMyLocation);
    const { selectedStore, madeASelection, handleConfirm } = useSelectStore();

    const storeRoute = selectedStore
        ? StoreUtils.getStoreRoute(selectedStore)
        : "";
    const _setCenter = (center: Geolocation) => {
        dispatch(setCenter(center));
    };
    useEffect(() => {
        _setCenter(myLocation);
    }, [myLocation.coordinates[0], myLocation.coordinates[1]]);

    // if no location, set the center to the first store
    useEffect(() => {
        if (!hasLocation && activeChainStores.length > 0) {
            const firstStoreLocation = activeChainStores[0].geolocation;
            _setCenter(firstStoreLocation);
        }
    }, [hasLocation, activeChainStores.length]);

    if (isDesktop) {
        return (
            <Dialog open={storeLocationsModal} onOpenChange={onHide}>
                <DialogContent className="rounded overflow-scroll snackpass__hidden-scroll">
                    {/* sticky map part */}
                    <div className="flex flex-col sticky top-0 right-0 z-[101] ">
                        <div className="flex bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white ml-6 md:m-auto flex flex-col">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    Choose location
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-6 flex-1 bg-white">
                            <SetMyLocation />
                            <StoreMap />
                        </div>
                    </div>
                    {/* scrollable store list */}
                    <div className="w-full px-6 pb-4 min-h-[80vh]">
                        <DistanceWarning />
                        <StoreList />
                    </div>
                    {/* // fixed footer */}
                    {madeASelection ? (
                        <DialogFooter className="bg-white border-t md:px-6 p-4">
                            <Link to={storeRoute} className="w-full px-4">
                                <Button
                                    onPress={handleConfirm}
                                    label="Confirm Location"
                                    className="w-full m-auto"
                                />
                            </Link>
                        </DialogFooter>
                    ) : null}
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={storeLocationsModal} onOpenChange={onHide}>
            <SheetContent>
                <SheetHeader>
                    <div className="flex flex-col w-full">
                        <SheetTitle>Choose location</SheetTitle>
                        <div className="flex flex-col sticky top-0 right-0 z-[101]">
                            <div className="px-6 pt-2 flex-1 bg-white">
                                <SetMyLocation />
                                <StoreMap />
                            </div>
                        </div>
                    </div>
                </SheetHeader>
                <div className="w-full px-6 py-4 mb-4 min-h-[60vh] overflow-scroll">
                    <DistanceWarning />
                    <StoreList />
                </div>
                {madeASelection ? (
                    <SheetFooter className="pt-4 pb-2 border-t">
                        <SheetClose asChild>
                            <Link to={storeRoute} className="w-full px-4">
                                <Button
                                    onPress={() => {
                                        handleConfirm();
                                        onHide();
                                    }}
                                    label="Confirm Location"
                                    className="w-full m-auto"
                                />
                            </Link>
                        </SheetClose>
                    </SheetFooter>
                ) : null}
            </SheetContent>
        </Sheet>
    );
};

export default ChooseLocationModal;
