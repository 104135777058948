import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { BeatLoader } from "react-spinners";
import { Colors } from "src/utils";
import { cn } from "@/utils";

const buttonVariants = cva(
    "select-none inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default:
                    "bg-primary text-primary-foreground md:hover:bg-primary/90",
                destructive:
                    "bg-destructive text-destructive-foreground md:hover:bg-destructive/90",
                outline:
                    "border border-input bg-background hover:bg-tertiary md:hover:text-tertiary-foreground",
                secondary: "bg-accent text-white md:hover:bg-accent/80",
                ghost: "hover:bg-tertiary hover:text-tertiary-foreground",
                link: "text-primary underline-offset-4 hover:underline",
                brand: "bg-brandColor text-white md:hover:bg-brandColor/80",
                hidden: "hidden",
            },
            size: {
                default: "h-10 px-4 py-2",
                sm: "h-9 rounded-md px-3",
                lg: "h-11 rounded-md px-8",
                xl: "h-14 rounded-md px-10",
                tall: "h-[72px] rounded-md px-10 md:px-16",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    onPress: (e?: any) => void;
    label: string | any;
    asChild?: boolean;
    loading?: boolean;
    disabled?: boolean;
    loadingIconSize?: number;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            onPress,
            label,
            className,
            variant,
            size,
            asChild = false,
            loading,
            disabled,
            loadingIconSize,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : "button";
        return (
            <Comp
                disabled={disabled}
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                onClick={(e) => {
                    if (loading || disabled) {
                        return;
                    }
                    onPress(e);
                }}
                {...props}
            >
                {!loading ? (
                    <>{label}</>
                ) : (
                    <BeatLoader
                        color={Colors.white}
                        size={loadingIconSize || 10}
                    />
                )}
            </Comp>
        );
    },
);
Button.displayName = "Button";

export { Button, buttonVariants };
