import { CSSProperties, useEffect, useState } from "react";
import React from "react";
import { Text, View } from "src/SharedComponents";
import { Row, Col } from "react-bootstrap";
import {
    buttonsOrder,
    COL_FULL_SIZE,
    errorStyles,
    GiftCardFormProps,
    GiftCardStateType,
    inputStyles,
    MAX_GIFT_CARD_AMOUNT,
    MIN_GIFT_CARD_AMOUNT,
    outerContainer,
    subTitle,
} from "../lib";
import { Colors } from "src/utils";
import _ from "lodash";
import NumberFormat from "react-number-format";

type GiftCardMoneySelectProps = {
    giftCardState: GiftCardStateType;
    setGiftCardState: React.Dispatch<React.SetStateAction<GiftCardStateType>>;
    setFieldValue: (field: string, value: any) => void;
    setTouched: (formData: object) => void;
};

const BUTTON_OTHER = "buttonOther";
//const labelNext = 'Next';
const COL_BUTTONS_SIZE = 4;
const OTHER = "Other";
const WHITE_COLOR = "white";
const COLOR_BG = "#FFFFFF";
const GFVALUE = "GIFT CARD VALUE";

export const GiftCardMoneySelect = ({
    giftCardState,
    setGiftCardState,
    handleBlur,
    setFieldValue,
    setTouched,
    values,
    errors,
    touched,
}: GiftCardMoneySelectProps & GiftCardFormProps) => {
    const [showInput, setShowInput] = useState<boolean>(
        giftCardState.buttonSelected === BUTTON_OTHER
    );

    const amountState = !giftCardState.amount;

    useEffect(() => {
        onChangeTextValue(values.amount);
    }, [values.amount]);

    const onChangeTextValue = (nextValue: number) => {
        const nextValuerNoLeadingZeros = Number(nextValue);

        if (giftCardState.buttonSelected === BUTTON_OTHER) {
            if (
                _.isNaN(nextValue) ||
                !nextValue ||
                nextValue > MAX_GIFT_CARD_AMOUNT ||
                nextValue < MIN_GIFT_CARD_AMOUNT
            ) {
                setGiftCardState({ ...giftCardState, amount: 0 });
            } else {
                setGiftCardState({
                    ...giftCardState,
                    amount: nextValuerNoLeadingZeros,
                    usingOtherAmountButton: true,
                });
            }
        }
    };

    const handleClick = (buttonLabel, buttonValue) => {
        const otherClicked = buttonLabel === BUTTON_OTHER;
        setShowInput(otherClicked);
        setGiftCardState({ ...giftCardState, giftCardButton: buttonLabel });
        const amount =
            buttonLabel === BUTTON_OTHER &&
            values.amount >= MIN_GIFT_CARD_AMOUNT &&
            values.amount <= MAX_GIFT_CARD_AMOUNT
                ? values.amount
                : buttonValue;
        setGiftCardState({
            ...giftCardState,
            amount,
            buttonSelected: buttonLabel,
            usingOtherAmountButton: false,
        });
        setFieldValue("amount", otherClicked ? "" : amount);
        if (!otherClicked) setTouched({ ...touched, amount: true });
    };
    const showErrorsAmount =
        !showInput && amountState && errors.amount && touched.amount;

    return (
        <View style={outerContainer}>
            <Row className="justify-content-lg-center row-centered">
                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <Text style={subTitle}>{GFVALUE}</Text>
                </Col>
                <Col
                    lg={COL_FULL_SIZE}
                    xs={COL_FULL_SIZE}
                    md={COL_FULL_SIZE}
                    className="column-centered"
                >
                    {showErrorsAmount && (
                        <View style={errorStyles}>{errors.amount}</View>
                    )}
                </Col>
                {buttonsOrder.map((button) => {
                    const colorBackground =
                        giftCardState.buttonSelected === button.label
                            ? Colors.lightBlue
                            : COLOR_BG;

                    const colorText =
                        giftCardState.buttonSelected === button.label
                            ? WHITE_COLOR
                            : Colors.darkerGrey;

                    const buttonValue =
                        button.label === BUTTON_OTHER
                            ? OTHER
                            : `$${button.value}`;

                    return (
                        <Col
                            key={button.label}
                            lg={COL_BUTTONS_SIZE}
                            xs={COL_BUTTONS_SIZE}
                            md={COL_BUTTONS_SIZE}
                            className="column-centered"
                        >
                            <View
                                key={button.label}
                                style={{
                                    ...styles.backgroundButton,
                                    backgroundColor: colorBackground,
                                }}
                                onPress={() =>
                                    handleClick(button.label, button.value)
                                }
                            >
                                <Text
                                    style={{
                                        ...styles.buttonText,
                                        color: colorText,
                                    }}
                                >
                                    {buttonValue}
                                </Text>
                            </View>
                        </Col>
                    );
                })}
            </Row>

            {showInput ? (
                <Row className="justify-content-lg-center row-centered">
                    <Col
                        lg={COL_FULL_SIZE}
                        xs={COL_FULL_SIZE}
                        md={COL_FULL_SIZE}
                        className="column-centered"
                    >
                        {showInput && errors.amount && touched.amount && (
                            <View style={errorStyles}>{errors.amount}</View>
                        )}
                    </Col>

                    <Col
                        lg={COL_FULL_SIZE}
                        xs={COL_FULL_SIZE}
                        md={COL_FULL_SIZE}
                        className="column-centered"
                    >
                        <NumberFormat
                            name="amount"
                            value={values.amount}
                            type="tel"
                            className="gfInput"
                            format="$####"
                            mask=" "
                            style={inputStyles(errors.amount, touched.amount)}
                            onValueChange={(valuesInput) => {
                                const { value } = valuesInput;
                                setFieldValue("amount", value);
                            }}
                            isAllowed={(values) =>
                                values.value.charAt(0) !== "0"
                            } // avoid leading zeros
                            placeholder={`Enter amount between $${MIN_GIFT_CARD_AMOUNT} and $${MAX_GIFT_CARD_AMOUNT}`}
                            onBlur={handleBlur}
                        />
                    </Col>
                </Row>
            ) : null}
        </View>
    );
};

const styles = {
    backgroundButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #DDE5EE",
        padding: "10%",
        borderRadius: 8,
        width: "100%",
        cursor: "pointer",
        marginBottom: "10%",
        maxHeight: 70,
    } as CSSProperties,

    buttonText: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        padding: "5%",
        textAlign: "center",
    } as CSSProperties,

    inputStyles: {
        borderRadius: 10,
        textAlign: "center",
        borderStyle: "solid",
        borderWidth: "4",
        borderColor: "#0077FF",
        color: "#282D32",
    } as CSSProperties,

    inputStyle: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "48px",
        lineHeight: "56px",
        color: "#282D32",
        padding: "2%",
        borderRadius: 8,
        border: "1px solid #DDE5EE",
        width: "100%",
        marginBottom: "2%",
        backgroundColor: "white",
        textAlign: "center",
    } as CSSProperties,
};
