import { validatePhoneNumber } from "src/utils/Helpers";
import { CSSProperties } from "react";
import { Colors } from "src/utils";
import { FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";

export enum PaymentType {
    SnackPass,
    CreditCard,
}

export type GiftCardStateType = {
    toName: string;
    amount: number;
    toPhone: string;
    note: string;
    fromName: string;
    fromEmail: string;
    cardLast4: string;
    paymentType: PaymentType | null;
    buttonSelected: string;
    isKioskPurchase: boolean;
    giftCardButton: string;
    usingOtherAmountButton: boolean;
};

export enum StepMoment {
    fillingForm,
    checkout,
    paymentMethod,
    confirmPurchase,
}

export const outerContainer = {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 10,
    width: "100%",
    padding: "2%",
    height: "100%",
} as CSSProperties;

export const subTitle = {
    width: "100%",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    textAlign: "start",
    color: "#606C76",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingBottom: "2%",
} as CSSProperties;

export const inputStyles = (
    error: string | undefined,
    touched: boolean | undefined,
    disabled?: boolean | undefined
) => {
    const backgroundColor = disabled ? "#F1F5F8" : "white";
    return {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        color: "#606C76",
        padding: "3%",
        borderRadius: 8,
        border: "1px solid #DDE5EE",
        borderColor: error && touched ? Colors.red : "#DDE5EE",
        width: "100%",
        marginBottom: "2%",
        backgroundColor,
        WebkitBoxShadow: `0 0 0 50px ${backgroundColor} inset`,
        WebkitTextFillColor: "#606C76",
    } as CSSProperties;
};

export const inputStylesParams = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    color: "#606C76",
    padding: "3%",
    borderRadius: 8,
    border: "1px solid #DDE5EE",
    width: "100%",
    marginBottom: "2%",
    backgroundColor: "white",
} as CSSProperties;

export const errorStyles = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#FF3929",
    width: "100%",
    backgroundColor: "white",
    textAlign: "start",
    padding: "1% 1% 1% 0",
} as CSSProperties;

export const buttonsOrder = [
    {
        label: "button10",
        value: 10,
    },
    {
        label: "button20",
        value: 20,
    },
    {
        label: "button30",
        value: 30,
    },
    {
        label: "button50",
        value: 50,
    },
    {
        label: "button100",
        value: 100,
    },
    {
        label: "buttonOther",
        value: 0,
    },
];

export const COL_FULL_SIZE = 12;
export const COL_HALF_SIZE = 6;

export const START_STATE: GiftCardStateType = {
    toName: "",
    fromName: "",
    amount: 0,
    fromEmail: "",
    note: "",
    toPhone: "",
    cardLast4: "",
    paymentType: null,
    buttonSelected: "",
    isKioskPurchase: false,
    giftCardButton: "",
    usingOtherAmountButton: false,
};

export const formatNumber = (number: string) => {
    return (
        "+1" +
        number
            .replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(/\D/g, "")
    );
};

export type GiftCardFormProps = {
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any, Element>) => void;
    values: {
        amount: number;
        toName: string;
        toPhone: string;
        note: string;
        fromName: string;
        fromEmail: string;
    };
    errors: FormikErrors<{
        amount: string;
        toName: string;
        toPhone: string;
        note: string;
        fromName: string;
        fromEmail: string;
    }>;
    touched: FormikTouched<{
        amount: string;
        toName: string;
        toPhone: string;
        note: string;
        fromName: string;
        fromEmail: string;
    }>;
    isKioskPurchase?: boolean | undefined;
};

export const MIN_GIFT_CARD_AMOUNT = 5;
export const MAX_GIFT_CARD_AMOUNT = 500;
export const GIFT_CARD_VALUE_ERROR_TEXT = `Value must be between $${MIN_GIFT_CARD_AMOUNT} and $${MAX_GIFT_CARD_AMOUNT}`;

export const GiftCardSchema = Yup.object().shape({
    amount: Yup.number()
        .typeError("you must specify a number")
        .min(MIN_GIFT_CARD_AMOUNT, GIFT_CARD_VALUE_ERROR_TEXT)
        .max(MAX_GIFT_CARD_AMOUNT, GIFT_CARD_VALUE_ERROR_TEXT)
        .positive()
        .integer("Value must be an integer"),
    toName: Yup.string()
        .trim()
        .min(2, "Recipient Name must be at least 2 characters")
        .max(50, "Recipient Name can only have 50 characters max")
        .required("Recipient Name is required"),
    toPhone: Yup.string()
        .required("Recipient Phone Number is required")
        .test(
            "validate-phone",
            "Recipient Phone Number is invalid",
            function (value) {
                return value && validatePhoneNumber(value);
            }
        ),
    note: Yup.string().max(300, "Note can only have 300 characters max"),
    fromName: Yup.string()
        .min(2, "Sender Name can't be that short")
        .max(50, "Sender Name can only have 50 characters max")
        .required("Sender Name is required"),
    fromEmail: Yup.string()
        .email("Invalid Email Address")
        .required("Email Address is required"),
});

export enum GiftCardDesign {
    Amount = "amount",
    Check = "check",
    Gift = "gift",
}

export enum GiftCardStatus {
    INACTIVE = "INACTIVE",
    SENT = "SENT",
    REDEEMED = "REDEEMED",
    OUT_OF_CREDIT = "OUT_OF_CREDIT",
}
