import React from "react";
import { Colors } from "../../utils";
import { Text } from "../../SharedComponents";
import { Helpers } from "../../utils";
import Addons from "./Addons";
import { IPurchase } from "@snackpass/snackpass-types";
import { PurchaseItemGrouping } from "src/utils/Types";

type Props = {
    item: PurchaseItemGrouping;
    purchase: IPurchase;
    index: number;
};

const Item: React.FC<Props> = ({ item }) => {
    const notes = () => {
        let firstItem = item.items[0];

        if (!firstItem.notes) {
            return null;
        }

        return (
            <Text small color={Colors.gray} className="whitespace-pre-line">
                Notes: {firstItem.notes}
            </Text>
        );
    };

    let firstItem = item.items[0];
    const { product, weight } = firstItem;
    const weightInfo = weight ? `(${weight.amount}${weight.unit})` : "";

    const isPriceDiscounted =
        firstItem.totalPriceAfterDiscount !== firstItem.totalPrice;

    return (
        <>
            <div className="self-stretch justify-start items-start gap-6 inline-flex ">
                <div className="w-full text-stone-950 text-base font-semibold leading-normal">
                    {item.quantity}x {product?.name + weightInfo}
                </div>
                <div className="text-stone-950 text-base font-normal leading-normal flex gap-2">
                    {isPriceDiscounted && (
                        <div className={`line-through`}>
                            {Helpers.toDollar(item.totalPrice)}
                        </div>
                    )}
                    <div>{Helpers.toDollar(item.totalPriceAfterDiscount)}</div>
                </div>
            </div>
            <div className="pb-2">
                <Addons item={item} />
                {notes()}
            </div>
        </>
    );
};

export default Item;
