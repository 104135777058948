/* Actions */

export const SET_USER_FIRST_NAME = "SET_USER_FIRST_NAME";
export type SET_USER_FIRST_NAME = typeof SET_USER_FIRST_NAME;

export const SET_USER_LAST_NAME = "SET_USER_LAST_NAME";
export type SET_USER_LAST_NAME = typeof SET_USER_LAST_NAME;

export type SetUserFirstName = ReturnType<typeof setUserFirstName>;
export const setUserFirstName = (firstName: string) =>
    ({
        type: SET_USER_FIRST_NAME,
        firstName,
    }) as const;

export type SetUserLastName = ReturnType<typeof setUserLastName>;
export const setUserLastName = (lastName: string) =>
    ({
        type: SET_USER_LAST_NAME,
        lastName,
    }) as const;

/* Reducer */
export interface UserNameState {
    firstName: string;
    lastName: string;
}
export const UserNameInitialState: UserNameState = {
    firstName: "",
    lastName: "",
};

export default function UserName(
    state = UserNameInitialState,
    action: SetUserFirstName | SetUserLastName,
): UserNameState {
    switch (action.type) {
        case SET_USER_FIRST_NAME:
            return { ...state, firstName: action.firstName ?? "" };
        case SET_USER_LAST_NAME:
            return { ...state, lastName: action.lastName ?? "" };
        default:
            return state;
    }
}

/* Selectors */
export const getUserFirstName = (state: { userName: UserNameState }) =>
    state.userName.firstName;

export const getUserLastName = (state: { userName: UserNameState }) =>
    state.userName.lastName;
