import { IPunchcard } from "@snackpass/snackpass-types";

// @ryndshn: Can't completely remove punchcards from Redux here in favor of @snackpass/loyalty-react.
// @snackpass/accounting assumes punchcard state exists to consider store-credit,
// which currently lives on punchcard

const SET_PUNCHCARDS = "SET_PUNCHCARDS";
type SET_PUNCHCARDS = typeof SET_PUNCHCARDS;

export type SetPunchcards = ReturnType<typeof setPunchcards>;
export const setPunchcards = (punchcards: IPunchcard[]) => {
    return {
        type: SET_PUNCHCARDS,
        punchcards,
    };
};

type PunchcardAction = SetPunchcards;

export interface PunchcardMap {
    [id: string]: IPunchcard;
}

const INITIAL_STATE: IPunchcard[] = [];

export default function punchcards(
    state = INITIAL_STATE,
    action: PunchcardAction,
) {
    switch (action.type) {
        case SET_PUNCHCARDS:
            return action.punchcards;
        default:
            return state;
    }
}
