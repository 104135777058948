import React from "react";
import { Divider } from "src/SharedComponents";
import { useDispatch, useSelector } from "react-redux";
import {
    CartSelectors,
    findPolicyByName,
    toDollar,
} from "@snackpass/accounting";
import { setNumberOfBags } from "@snackpass/accounting/build/src/redux/cart/actions";
import { ReactComponent as BagIcon } from "src/assets/icons/bag.svg";
import { Minus, Plus } from "lucide-react";
import { ReservedFeeEnum } from "@snackpass/snackpass-types";
import { getActiveStore } from "@/redux";

const BagFee = () => {
    const activeStore = useSelector(getActiveStore);
    const storeBagFeePolicy = findPolicyByName(
        ReservedFeeEnum.BagStoreFee,
        activeStore?.feePolicies || [],
    );
    const fee = storeBagFeePolicy?.flat;
    const numberOfBags = useSelector(CartSelectors.getNumberOfBags);
    const dispatch = useDispatch();

    const incrementBag = () => dispatch(setNumberOfBags(numberOfBags + 1));
    const decrementBag = () => dispatch(setNumberOfBags(numberOfBags - 1));

    if (!storeBagFeePolicy || !fee) {
        return null;
    }

    return (
        <>
            <div className="p-4 md:p-6">
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center pb-2">
                            <BagIcon className="w-[20px] h-[20px] fill-secondary mr-3" />
                            <span className="text-primary text-base font-semibold leading-6">
                                Bags
                            </span>
                        </div>
                        <span> {toDollar(fee)} each</span>
                    </div>
                    <div>
                        <div className="w-[120px] h-10 justify-center bg-white items-center inline-flex">
                            <button
                                onClick={() => {
                                    if (numberOfBags > 0) {
                                        decrementBag();
                                    }
                                }}
                                className={`w-10 h-10 px-3 py-[13px] rounded-tl-lg rounded-bl-lg border-l border-t border-b border-zinc-200 justify-center items-center gap-2 flex 
               
                `}
                            >
                                <Minus
                                    className="w-4 h-4 relative"
                                    strokeWidth={2.5}
                                    size={20}
                                />
                            </button>
                            <div className="w-13 h-10 py-[13px] border-l border-t border-b border-zinc-200 justify-center items-center gap-2 flex">
                                <input
                                    className="flex w-11/12 pointer-events-auto text-center text-stone-950 text-base font-semibold leading-normal"
                                    value={numberOfBags}
                                    min={0}
                                    type="number"
                                    disabled
                                />
                            </div>
                            <button
                                onClick={incrementBag}
                                className="w-10 h-10 px-3 py-[13px] rounded-tr-lg rounded-br-lg border border-zinc-200 justify-center items-center gap-2 flex hover:bg-gray-50"
                            >
                                <Plus
                                    size={20}
                                    strokeWidth={2.5}
                                    className="w-4 h-4 relative"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Divider width={1} className="ml-6" />
        </>
    );
};

export default React.memo(BagFee);
