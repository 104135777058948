import { createSelector } from "@reduxjs/toolkit";
import { IChain } from "@snackpass/snackpass-types";
import Api from "src/utils/Api/REST";
import { sendError } from "src/utils/Errors";
import { AppDispatch } from "@/redux/STORE";

export const SET_ACTIVE_CHAIN = "SET_ACTIVE_CHAIN";
export const SET_ACTIVE_CHAIN_FETCHING = "SET_ACTIVE_CHAIN_FETCHING";

export type SET_ACTIVE_CHAIN = typeof SET_ACTIVE_CHAIN;
export type SET_ACTIVE_CHAIN_FETCHING = typeof SET_ACTIVE_CHAIN_FETCHING;

export const setActiveChain = (chain: IChain | null) => {
    return { type: SET_ACTIVE_CHAIN, chain } as const;
};

export const setActiveChainFetching = (isFetching: boolean) => {
    return { type: SET_ACTIVE_CHAIN_FETCHING, isFetching } as const;
};

export type SetActiveChain = ReturnType<typeof setActiveChain>;
export type SetActiveChainFetching = ReturnType<typeof setActiveChainFetching>;

export const getActiveChain = (state): IChain => state.activeChain.chain;
export const getActiveChainFetching = (state): boolean =>
    state.activeChain.isFetching;

export const getActiveChainId = createSelector(
    [getActiveChain],
    (activeChain) => activeChain._id,
);

export const getActiveChainName = createSelector(
    [getActiveChain],
    (activeChain) => activeChain.name,
);

export function fetchChainThunk(chainId: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setActiveChainFetching(true));
        const handleResponse = (response) => {
            const chain = response.data.chain;
            dispatch(setActiveChain(chain));
            dispatch(setActiveChainFetching(false));
            return chain;
        };

        return Api.chains
            .get(chainId)
            .then(handleResponse)
            .catch((e) => {
                dispatch(setActiveChainFetching(false));
                sendError(e);
            });
    };
}

const INITIAL_STATE = {
    chain: null,
    isFetching: false,
};

export default function activeChain(
    state = INITIAL_STATE,
    action: SetActiveChain | SetActiveChainFetching,
) {
    switch (action.type) {
        case SET_ACTIVE_CHAIN:
            return {
                ...state,
                chain: action.chain,
            };
        case SET_ACTIVE_CHAIN_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        default:
            return state;
    }
}
