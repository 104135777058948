import {
    IGiftCard,
    IStore,
    IStore_Public,
    ParticipatingLocations,
} from "@snackpass/snackpass-types";
import React, { useEffect } from "react";
import { GiftCardViewParticipatingLocation } from "./GiftCardViewParticipatingLocation";
import { useFetchGiftCardParticipatingLocations } from "./useFetchGiftCardParticipatingLocations";
import { filterNulls } from "@/utils/arrays/filterNulls";
import { BeatLoader } from "react-spinners";

type Props = {
    giftCard?: IGiftCard;
    store: IStore | null;
    token: string;
};

export function GiftCardViewParticipatingLocations({
    store,
    giftCard,
    token,
}: Props) {
    const { fetchGiftCardParticipatingLocations, data, error } =
        useFetchGiftCardParticipatingLocations();
    useEffect(() => {
        if (giftCard?.id) {
            fetchGiftCardParticipatingLocations(giftCard?.id, token);
        }
    }, [fetchGiftCardParticipatingLocations, giftCard, token]);
    const activeStoreAsAParticipatingLocation: Pick<
        IStore_Public,
        "_id" | "name" | "slug" | "address"
    > | null = store;
    const participatingLocations: ParticipatingLocations = filterNulls(
        // Ensure the current store is always visible, and that it is first.
        [activeStoreAsAParticipatingLocation].concat(
            (data ?? []).filter((location) => location._id !== store?._id)
        )
    );
    const isLoading = data == null && error == null;
    return (
        <div>
            {participatingLocations.map((location, index) => (
                <GiftCardViewParticipatingLocation
                    key={location._id}
                    {...location}
                    isLast={participatingLocations.length - 1 === index}
                />
            ))}
            {isLoading ? (
                <div className="pt-6">
                    <BeatLoader />
                </div>
            ) : null}
            {error ? (
                <span className="p-6 text-start text-sm text-alert">
                    Error: {error.message}
                </span>
            ) : null}
        </div>
    );
}
