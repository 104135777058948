import React, { useMemo } from "react";

import { AddonGroup, Addon, IStore } from "@snackpass/snackpass-types";

import { Divider } from "@/SharedComponents";

import AddonGroupHeader from "./AddonGroupHeader";
import AddonRow from "./AddonRow";
import { sortAddons } from "./utils";

type AddonGroupProps = {
    activeStore: IStore;
    addonGroup: AddonGroup;
    onToggleAddon: (addon: Addon, addonGroup: AddonGroup) => void;
    onChangeAddonQuantity: (addon: Addon, isIncrement: boolean) => void;
    addonSelectionIsValidated: AddonGroup[];
    showRequiredAlerts: boolean;
};

const AddonGroupComponent = ({
    addonGroup,
    onToggleAddon,
    onChangeAddonQuantity,
    activeStore,
    addonSelectionIsValidated,
    showRequiredAlerts,
}: AddonGroupProps) => {
    const addons = useMemo(
        () => addonGroup.addons.toSorted(sortAddons),
        [addonGroup.addons],
    );

    return (
        <>
            <AddonGroupHeader
                addonGroup={addonGroup}
                addonSelectionIsValidated={addonSelectionIsValidated}
                showRequiredAlerts={showRequiredAlerts}
            />
            <div className="pb-6 flex flex-col">
                {addons.map((addon: Addon) => (
                    <React.Fragment key={addon._id}>
                        <AddonRow
                            addon={addon}
                            activeStore={activeStore}
                            addonGroup={addonGroup}
                            onToggleAddon={onToggleAddon}
                            onChangeAddonQuantity={onChangeAddonQuantity}
                        />
                        <Divider className="last:invisible ml-6" />
                    </React.Fragment>
                ))}
            </div>
            <Divider width={8} />
        </>
    );
};

export default AddonGroupComponent;
