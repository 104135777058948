import React from "react";
import { useSelector } from "react-redux";
import { getActiveStore } from "@/redux";
import _ from "lodash";
import { getHourRanges } from "@/utils/Time";
import { cn } from "@/utils";
import { RushHourUntil } from "@/components/RushHourUntil";
import { PausedOrderUntil } from "@/components/PausedOrderUntil";
import { IStore } from "@snackpass/snackpass-types";

const Hours = ({ storeOverride }: { storeOverride?: IStore }) => {
    const a = useSelector(getActiveStore);
    const activeStore = storeOverride ? storeOverride : a;

    if (!activeStore) return null;
    const isRushHourUntil = Boolean(activeStore.rushHourUntil);
    const isClosedUntil = activeStore.closedUntil;

    const hourRanges = getHourRanges(activeStore?.hours);
    if (_.isEmpty(hourRanges)) return null;
    return (
        <div>
            {hourRanges.map((range, index) => {
                return (
                    <div key={`${index} ${range} `}>
                        <div
                            className={cn(
                                "flex flex-1 justify-between text-base font-normal leading-6 text-primary",
                                range.isSpecial ? "" : "pb-2",
                            )}
                        >
                            <span>{range.day}</span>
                            <span>{range.time}</span>
                        </div>
                        {range.isSpecial ? (
                            <div
                                className={cn(
                                    "text-end flex flex-1 justify-end text-xs font-normal",
                                    range.isSpecial ? "pb-2" : "",
                                )}
                            >
                                <span />
                                <span className="text-xs font-medium">
                                    (Special Hours)
                                </span>
                            </div>
                        ) : null}
                    </div>
                );
            })}
            {isRushHourUntil && <RushHourUntil />}
            {isClosedUntil && <PausedOrderUntil />}
        </div>
    );
};

export default Hours;
