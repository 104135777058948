import { useEffect } from "react";
import { useFetchActiveStore } from "#activeStore";

export function useLoadStore(storeNameAndId: string) {
    const { fetchStore, isLoading, error } = useFetchActiveStore();

    useEffect(() => {
        if (storeNameAndId) {
            fetchStore(storeNameAndId);
        }
    }, [fetchStore, storeNameAndId]);

    return {
        isLoading,
        error,
    };
}
