export const detect = (): {
    isBrowser: boolean;
    isReactNative: boolean;
    isNode: boolean;
} => {
    if (typeof document !== "undefined") {
        return {
            isBrowser: true,
            isReactNative: false,
            isNode: false
        };
    }

    if (
        typeof navigator !== "undefined" &&
        navigator.product === "ReactNative"
    ) {
        return {
            isBrowser: false,
            isReactNative: true,
            isNode: false
        };
    }

    return {
        isBrowser: false,
        isReactNative: false,
        isNode: true
    };
};

export default detect;
