import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPurchase } from "@snackpass/snackpass-types";
import { createSelector } from "@reduxjs/toolkit";
export type OrderWithIdempotencyKey = IPurchase & { receiptToken: string };

interface PastOrdersState {
    pastOrders: OrderWithIdempotencyKey[];
}

const initialState: PastOrdersState = {
    pastOrders: [],
};

export const pastOrdersSlice = createSlice({
    name: "pastOrders",
    initialState,
    reducers: {
        setPastOrders: (state, action: PayloadAction<any[]>) => {
            state.pastOrders = action.payload;
        },
    },
});

export const { setPastOrders } = pastOrdersSlice.actions;

export const selectPastOrders = (state): OrderWithIdempotencyKey[] =>
    state.pastOrders.pastOrders;

export const getLastPurchaseTime = createSelector(
    selectPastOrders,
    (pastOrders) => {
        if (pastOrders.length > 0) {
            return pastOrders[pastOrders.length - 1].createdAt;
        }
        return null;
    }
);

export const getTotalLocations = createSelector(
    selectPastOrders,
    (pastOrders) => {
        const uniqueLocations = new Set(
            pastOrders.map((order) => order?.store?._id)
        );
        return uniqueLocations.size;
    }
);

export const getTotalOrders = createSelector(
    selectPastOrders,
    (pastOrders) => pastOrders.length
);

export default pastOrdersSlice.reducer;
