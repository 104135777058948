import { setPendingMarketingConsent } from "@/redux/marketingConsent";
import API from "@/utils/Api/graphql";
import { IndividualMarketingConsentInfo } from "@/utils/Api/graphql/messaging";
import { sendError } from "@/utils/Errors";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

/**
 * Provides an API for fetching marketing consent status.
 */
export function useFetchMarketingConsent() {
    const dispatch = useDispatch();
    const [error, setError] = useState<Error | undefined>(undefined);
    const [data, setData] = useState<
        IndividualMarketingConsentInfo | undefined
    >(undefined);

    const fetchMarketingConsent = useCallback(
        async (storeId: string, purchaseId?: string, authToken?: string) => {
            dispatch(setPendingMarketingConsent(undefined));
            API.messaging
                .getIndividualMarketingConsentInfo(
                    storeId,
                    purchaseId,
                    authToken,
                )
                .then((data) => {
                    setData(data);
                })
                .catch((graphQLError) => {
                    setError(graphQLError);
                    sendError(graphQLError);
                });
        },
        [dispatch],
    );

    return useMemo(
        () => ({
            fetchMarketingConsent,
            data,
            error,
        }),
        [fetchMarketingConsent, data, error],
    );
}
