import React from "react";
import { IPurchase } from "@snackpass/snackpass-types";
import { Button } from "@/components/ui/button";
import useURLParams from "@/hooks/useURLParams";
import { useSelector } from "react-redux";
import { getActiveStore } from "@/redux";

type OrderStatusTabProps = {
    purchase: IPurchase;
};

export function Map({ purchase }: OrderStatusTabProps) {
    const params = useURLParams();
    const fromCustomApp = params.get("fromCustomApp");
    const activeStore = useSelector(getActiveStore);
    const isDelivery = purchase.fulfillment === "DELIVERY";
    if (!activeStore) return null;

    return (
        <div className="flex flex-col w-full max-w-600 p-4">
            {!fromCustomApp ? (
                <Button
                    label="Directions"
                    variant="outline"
                    onPress={() => {
                        const urlParams = isDelivery
                            ? `saddr=${activeStore.address}&daddr=${
                                  purchase.deliveryAddress ?? "My+Location"
                              }`
                            : `saddr=My+Location&daddr=${activeStore.address}`;
                        window.open(
                            `https://www.google.com/maps?${urlParams}`,
                            "_blank"
                        );
                    }}
                />
            ) : null}
        </div>
    );
}
