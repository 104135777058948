import { InfoRow } from "@/SharedComponents";
import {
    IPurchase,
    TransactionSourceTypeEnum,
} from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";
import {
    getKioskAndRegisterPickupDirections,
    getPickupDirections,
} from "@/redux";
import CompassIcon from "src/assets/icons/compass.svg";
import TableIcon from "src/assets/icons/table.svg";

export function PickupOrDineInInfo({ purchase }: { purchase: IPurchase }) {
    const pickupDirections = useSelector(getPickupDirections);
    const kioskAndRegisterDirections = useSelector(
        getKioskAndRegisterPickupDirections
    );
    const transactionSource = purchase.transactionSource;

    return (
        <>
            {/* Display instruction for both dine in and pickup orders */}
            {purchase.fulfillment !== "DELIVERY" &&
                //Check if the store has a pickup directions or kioskAndRegisterDirections only if the transaction source is kiosk or register
                (pickupDirections ||
                    (kioskAndRegisterDirections &&
                        transactionSource ===
                            TransactionSourceTypeEnum.Kiosk)) && (
                    <InfoRow
                        iconPath={CompassIcon}
                        middleComponent={
                            <div className="w-auto text-base font-semibold leading-normal text-stone-950">
                                Instructions
                            </div>
                        }
                        bottomComponent={
                            <div className="w-auto text-base font-normal leading-normal text-zinc-600">
                                {transactionSource ===
                                (TransactionSourceTypeEnum.Online ||
                                    TransactionSourceTypeEnum.App)
                                    ? pickupDirections
                                    : kioskAndRegisterDirections ||
                                      pickupDirections}
                            </div>
                        }
                    />
                )}
            {purchase.fulfillment === "DINE_IN" && purchase.tableNumber && (
                <InfoRow
                    iconPath={TableIcon}
                    middleComponent={
                        <div className="w-auto text-base font-semibold leading-normal text-stone-950">
                            Table Number
                        </div>
                    }
                    bottomComponent={
                        <div className="w-auto text-base font-normal leading-normal text-zinc-600">
                            {purchase.tableNumber}
                        </div>
                    }
                />
            )}
        </>
    );
}
