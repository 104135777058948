import {
    CustomFeeInDollars,
    DeliveryQuote,
    FeePolicy,
    FeePolicyPayer,
    FeePolicyRecipient,
    IOrderItem,
    IPurchase,
    IPurchaseFee,
    IStore,
    ReservedFeeEnum,
    TransactionSource,
    Fulfillment,
    FulfillmentTypeEnum,
    TaxPolicy,
    TaxPolicyEventType,
    CartAdjustment,
    CartAdjustmentType,
    RewardPromotionType
} from "@snackpass/snackpass-types";
import { SalesTax as SalesTaxT } from "@snackpass/snackpass-types";
import Dinero from "dinero.js";
import { apply } from "json-logic-js";
import { compose, isNil, orderBy, reduce, pick } from "lodash/fp";
import { CartItemWithAtLeastTaxInfo, ICart } from "../types";
import { purchaseIs3PDelivery } from "../utils/Helpers";
import { convertToDinero, DD } from "../utils/Money";
import { verbose } from "../verbose";
import { calculateFeeForPolicy, FeeTaxService } from "./fees";
import { flattenCartItem } from "./flattenCartItem";
import { calcSubtotal } from "./formulas";

const ZERO = Dinero({ amount: 0 });

// === TYPES === //

export type CalculatedSalesTax = {
    taxRate: number;
    taxableAmount: Dinero.Dinero;
    taxExemptAmount: Dinero.Dinero;
    taxAmount: Dinero.Dinero;
    // Optional since we don't use policies for custom fees
    appliedTaxPolicies?: TaxPolicy[];
    // _specialGiftTaxAmount is used in salesTax.test.ts to confirm the special gift
    // tax amount is being calculated correctly. Adding this assumes there are no
    // dependencies in other repos.
    _specialGiftTaxAmount?: Dinero.Dinero;
};

export type CartItemWithCalculatedTax = CartItemWithAtLeastTaxInfo &
    CalculatedSalesTax;

export type FeeWithTaxInfo = {
    feePolicy: FeePolicy;
    quantity: number;
    taxInfo: SalesTaxT;
    taxableAmountInDollars: number;
    taxExemptAmountInDollars: number;
    type: "delivery" | "custom";
};

export type FeeWithCalculatedTax = FeeWithTaxInfo & CalculatedSalesTax;

export type CalculateSalesTaxRequest = {
    cartItems: CartItemWithAtLeastTaxInfo[];
    cartAdjustments: CartAdjustment[];
    storeTaxRate: number;
    customerFees: FeeWithTaxInfo[];
    upchargeAmountInDollars: number;
    storeCreditAmountInDollars: number;
    fulfillment: Fulfillment;
};

export type CalculateSalesTaxForStoreRequest = {
    cart: Pick<
        ICart,
        "items" | "cartAdjustments" | "numberOfBags" | "fulfillment"
    > & {
        selectedDeliveryQuote: null | Pick<
            DeliveryQuote,
            "customerPrice" | "provider"
        >;
    };
    store: Pick<
        IStore,
        | "taxRate"
        | "specifyTaxRateByFulfillment"
        | "taxRateDelivery"
        | "taxRateDineIn"
        | "taxRatePickup"
        | "convenienceFeePolicies"
        | "hasConvenienceFee"
        | "convenienceFee"
    > | null;
    transactionSource: TransactionSource;
    customerFeePolicies: FeePolicy[];
    upchargeAmountInDollars: number;
    storeCreditAmountInDollars: number;
};

export type CalculateSalesTaxResponse = {
    cartItems: CartItemWithCalculatedTax[];
    fees: FeeWithCalculatedTax[];
    // Note: returning dinero obj bc this code will be used
    // by the server as well so I want returns to be raw objects
    taxAmount: Dinero.DineroObject;
    taxExemptAmount: Dinero.DineroObject;
    taxableAmount: Dinero.DineroObject;
};

export type ProductTaxProps = {
    item: CartItemWithAtLeastTaxInfo;
    storeTaxRate: number;
    fulfillment: Fulfillment;
};

export const _findTaxPolicy = ({
    item,
    fulfillment
}: {
    item: CartItemWithAtLeastTaxInfo;
    fulfillment: Fulfillment;
}) =>
    [...(item.taxPolicies || [])]
        .sort((a, b) => b.priority - a.priority)
        .find((policy) =>
            // eslint-disable-next-line
            apply(policy.conditions, {
                fulfillment
            })
        );

const _getProductTaxRate = ({
    item,
    storeTaxRate,
    fulfillment
}: ProductTaxProps): number => {
    const taxPolicy = _findTaxPolicy({ item, fulfillment });
    let taxRate =
        item.taxInfo && typeof item.taxInfo.rate === "number"
            ? item.taxInfo.rate
            : storeTaxRate;

    if (taxPolicy) {
        taxPolicy.events.forEach((event) => {
            switch (event.type) {
                case TaxPolicyEventType.setTaxRate:
                    taxRate = event.taxInfo.rate;
                    break;
            }
        });
    }
    return taxRate;
};

/**
 * Calculates the tax on an item. Uses product tax rate
 * otherwise falls back to using the store tax rate
 *
 * @param item
 * @param storeTaxRate
 * @returns
 */
export const calculateTaxForItem = ({
    item,
    storeTaxRate,
    fulfillment
}: ProductTaxProps): CalculatedSalesTax => {
    const cartItemAmount = DD(item.amountAfterDiscountInDollars);
    const productTaxRate = _getProductTaxRate({
        item,
        storeTaxRate,
        fulfillment
    });

    const appliedTaxPolicies = [];
    const appliedTaxPolicy = _findTaxPolicy({ item, fulfillment });
    if (appliedTaxPolicy) appliedTaxPolicies.push(appliedTaxPolicy);

    let _specialGiftTaxAmount;
    if (
        item.rewardPromotion?.type ===
        RewardPromotionType.SPECIAL_GIFT_ACCOUNTING
    ) {
        _specialGiftTaxAmount =
            _backCalculateSpecialGiftTaxFromTotalItemAmount(item);
    } else {
        _specialGiftTaxAmount = ZERO;
    }

    const taxAmount = cartItemAmount
        .percentage(productTaxRate)
        .add(_specialGiftTaxAmount);

    const taxExemptAmount = productTaxRate > 0 ? ZERO : cartItemAmount;
    const taxableAmount = cartItemAmount.subtract(taxExemptAmount);

    return {
        appliedTaxPolicies,
        taxRate: productTaxRate,
        taxAmount,
        taxExemptAmount,
        taxableAmount,
        _specialGiftTaxAmount
    };
};

export const _backCalculateSpecialGiftTaxFromTotalItemAmount = (
    item: CartItemWithAtLeastTaxInfo
): Dinero.Dinero => {
    /*
    e.g., ObjectId('6238c8dec93dfe017aa05886')
    basePrice = 5.28 // paid by giftER
    addons = 1.25 // added by giftEE at pickup
    totalPrice = 6.53

    Here, "discount" is the money collected by Snackpass from gifter
    at time of gift, and the "discount" just means the giftee doesn't
    have to pay that portion at the time of claim. 
    They are free to add addons if desired, which they pay for.

    totalPriceAfterDiscount = 1.25
    postTaxSnackpassContribution = 5.82

    ~0.54 is the extra tax contribution from the special gift found by CX. 
    e.g., $5.28 base price, 10.25% tax rate = $0.54

    Calculated here as:
    5.82 - (6.53 - 1.25) = 0.54

    postTaxSnackpassContribution - (totalPrice - totalPriceAfterDiscount)
    */

    if (!item.postTaxSnackpassContribution || !item.totalPrice) {
        return ZERO;
    }

    const totalPrice = DD(item.totalPrice);
    const amountAfterDiscountInDollars = DD(item.amountAfterDiscountInDollars);
    const postTaxSnackpassContribution = DD(item.postTaxSnackpassContribution);

    const amountAlreadyPaidByGifter = totalPrice.subtract(
        amountAfterDiscountInDollars
    );

    return Dinero.maximum([
        ZERO,
        postTaxSnackpassContribution.subtract(amountAlreadyPaidByGifter)
    ]);
};

/**
 * Calculates sales tax for items
 *
 * @param items
 * @returns
 */
export const _calculateSalesTaxForItems = (
    items: CartItemWithCalculatedTax[]
): Dinero.Dinero => items.reduce((acc, elem) => acc.add(elem.taxAmount), ZERO);

export const calculateSalesTaxForCustomerFee = (
    fee: FeeWithTaxInfo
): FeeWithCalculatedTax => {
    return {
        ...fee,
        taxAmount: convertToDinero(fee.taxableAmountInDollars).percentage(
            fee.taxInfo.rate
        ),
        taxExemptAmount: convertToDinero(fee.taxExemptAmountInDollars),
        taxableAmount: convertToDinero(fee.taxableAmountInDollars),
        taxRate: fee.taxInfo.rate
    };
};

export const _calculateTaxableCartAdjustments = (
    cartItems: CartItemWithCalculatedTax[],
    cartAdjustments: CartAdjustment[],
    fulfillment: Fulfillment,
    storeTaxRate: number
): {
    surchargeTotal: Dinero.Dinero;
    discountTotal: Dinero.Dinero;
    effectiveTaxRate: number;
} => {
    const surchargeTotal = cartAdjustments
        .filter((adj) => adj.type === CartAdjustmentType.SurchargeFlat)
        .reduce((acc, adj) => acc.add(DD(adj.flat || 0)), ZERO);

    const cartTaxAmount = cartItems
        .reduce((acc, item) => {
            const cartItemAmount = DD(item.amountAfterDiscountInDollars);
            const productTaxRate = _getProductTaxRate({
                item,
                storeTaxRate,
                fulfillment
            });

            return acc.add(cartItemAmount.percentage(productTaxRate));
        }, ZERO)
        .add(surchargeTotal.percentage(storeTaxRate));

    const cartTaxableAmount = cartItems
        .reduce((acc, item) => acc.add(item.taxableAmount), ZERO)
        .add(surchargeTotal)
        .toUnit();

    const effectiveTaxRate =
        cartTaxableAmount === 0
            ? 0
            : (cartTaxAmount.toUnit() / cartTaxableAmount) * 100;

    const taxableCartItemSubtotal = cartItems.reduce(
        (acc, item) => acc.add(item.taxableAmount),
        ZERO
    );

    const discountPercent = cartAdjustments
        .filter((adj) => adj.type === CartAdjustmentType.DiscountPercent)
        .reduce((acc, adj) => acc.add(DD(adj.percent || 0)), ZERO);

    const discountFlat = cartAdjustments
        .filter((adj) => adj.type === CartAdjustmentType.DiscountFlat)
        .reduce((acc, adj) => acc.add(DD(adj.flat || 0)), ZERO);

    const discountTotal = taxableCartItemSubtotal
        .add(surchargeTotal)
        .percentage(discountPercent.toUnit())
        .add(discountFlat);

    return {
        surchargeTotal,
        discountTotal,
        effectiveTaxRate
    };
};

export const _buildFeesWithSalesTax = (
    fees: FeeWithTaxInfo[]
): FeeWithCalculatedTax[] => fees.map(calculateSalesTaxForCustomerFee);

export const calculateSalesTaxForCustomerFees = (
    fees: FeeWithTaxInfo[]
): Omit<CalculatedSalesTax, "taxRate"> => {
    const feesWithTaxes = _buildFeesWithSalesTax(fees);
    const taxAmount = Dinero({ amount: 0 });
    const taxableAmount = Dinero({ amount: 0 });
    const taxExemptAmount = Dinero({ amount: 0 });

    return feesWithTaxes.reduce(
        (acc, elem) => ({
            taxAmount: acc.taxAmount.add(elem.taxAmount),
            taxExemptAmount: acc.taxExemptAmount.add(elem.taxExemptAmount),
            taxableAmount: acc.taxableAmount.add(elem.taxableAmount)
        }),
        {
            taxAmount,
            taxExemptAmount,
            taxableAmount
        }
    );
};

// The next four functions concern logic of reducing taxable total by
// applied store credit.
// NOTE: Store credit is applied to cart items, then fees, and for each is
// applied to items in descending order of tax rate.
// For instance, if an order has a $5 10% tax item and a $10 0% tax item
// and a $3 0% tax fee and $1 20% tax fee, credit would be applied to
// item 1, then item 2, then fee 2, then fee 1.
// For partially taxable fees, the order is the same -- the credit reduces
// first the taxable amount, then the tax-exempt amount.
// Also NOTE: this is subject to change based on the work of our finance team
// and tax lawyers they're working with.

// Apply store credit amount to a single cart item.
export type CartItemWithIndex = CartItemWithAtLeastTaxInfo & {
    index: string;
    storeCreditApplied: Dinero.DineroObject;
};
export type CartItemsByIndex = { [index: string]: CartItemWithIndex };
type StoreCreditAndModifiedItems = {
    storeCreditRemaining: Dinero.Dinero;
    modifiedCartItemsByIndex: CartItemsByIndex;
};
export const _applyStoreCreditToCartItem = (
    {
        storeCreditRemaining,
        modifiedCartItemsByIndex
    }: StoreCreditAndModifiedItems,
    itemWithIndex: CartItemWithIndex
): StoreCreditAndModifiedItems => {
    const { index, ...item } = itemWithIndex;
    const itemCost = DD(item.amountAfterDiscountInDollars);

    const creditToApplyAmount = Dinero.minimum([
        itemCost,
        storeCreditRemaining
    ]);

    return {
        storeCreditRemaining:
            storeCreditRemaining.subtract(creditToApplyAmount),
        modifiedCartItemsByIndex: {
            ...modifiedCartItemsByIndex,
            [index]: {
                ...item,
                index,
                storeCreditApplied: creditToApplyAmount.toObject(),
                amountAfterDiscountInDollars: itemCost
                    .subtract(creditToApplyAmount)
                    .toUnit()
            }
        }
    };
};

export const _applyStoreCreditToCartItems = (
    storeCreditAmount: Dinero.Dinero,
    cartItems: CartItemWithAtLeastTaxInfo[],
    storeTaxRate: number,
    fulfillment: Fulfillment
): StoreCreditAndModifiedItems =>
    compose(
        reduce(_applyStoreCreditToCartItem, {
            storeCreditRemaining: storeCreditAmount,
            modifiedCartItemsByIndex: {}
        }),
        orderBy(
            [
                (item: CartItemWithIndex) =>
                    _getProductTaxRate({ item, storeTaxRate, fulfillment })
            ],
            ["desc"]
        ),
        // lodash/fp/map is broken and doesn't give you the index of the item
        // as the second argument despite what the docs say :(
        (items: CartItemWithAtLeastTaxInfo[]) =>
            items.map(
                (item, i) =>
                    ({ ...item, index: i.toString() }) as CartItemWithIndex
            )
    )(cartItems);

// Apply store credit to a single fee.

type FeeWithIndex = FeeWithTaxInfo & {
    index: string;
};
export type FeeWithCreditApplied = FeeWithIndex & {
    storeCreditApplied: Dinero.DineroObject;
};
export type FeesByIndex = {
    [index: string]: FeeWithCreditApplied;
};
type StoreCreditAndModifiedFees = {
    storeCreditRemaining: Dinero.Dinero;
    modifiedFeesByIndex: FeesByIndex;
};

export const _applyStoreCreditToFee = (
    { storeCreditRemaining, modifiedFeesByIndex }: StoreCreditAndModifiedFees,
    feeWithIndex: FeeWithIndex
): StoreCreditAndModifiedFees => {
    const { index, ...fee } = feeWithIndex;
    const feeTaxableAmount = DD(fee.taxableAmountInDollars);
    const feeTaxExemptAmount = DD(fee.taxExemptAmountInDollars);

    // NOTE: Fees' `total` amounts can be negative (in the case of a reversal
    //       due to refund), and so we special case out the instance where
    //       there is no store credit to apply here. Without this, the code
    //       below that takes minumum() does not properly account for the
    //       negative fee case and results in screwy accounting (results in
    //       fees with negative store credit applied, 0 taxable and tax exempt
    //       total, and tax due on them).
    if (storeCreditRemaining.isZero()) {
        return {
            storeCreditRemaining,
            modifiedFeesByIndex: {
                ...modifiedFeesByIndex,
                [index]: {
                    ...fee,
                    index,
                    storeCreditApplied: storeCreditRemaining.toObject(),
                    taxableAmountInDollars: feeTaxableAmount.toUnit(),
                    taxExemptAmountInDollars: feeTaxExemptAmount.toUnit()
                }
            }
        };
    }

    const creditToApplyToTaxableAmount = Dinero.minimum([
        feeTaxableAmount,
        storeCreditRemaining
    ]);

    const creditToApplyToTaxExemptAmount = Dinero.minimum([
        feeTaxExemptAmount,
        storeCreditRemaining.subtract(creditToApplyToTaxableAmount)
    ]);

    const creditApplied = creditToApplyToTaxableAmount.add(
        creditToApplyToTaxExemptAmount
    );

    return {
        storeCreditRemaining: storeCreditRemaining
            .subtract(creditToApplyToTaxableAmount)
            .subtract(creditToApplyToTaxExemptAmount),
        modifiedFeesByIndex: {
            ...modifiedFeesByIndex,
            [index]: {
                ...fee,
                index,
                storeCreditApplied: creditApplied.toObject(),
                taxableAmountInDollars: feeTaxableAmount
                    .subtract(creditToApplyToTaxableAmount)
                    .toUnit(),
                taxExemptAmountInDollars: feeTaxExemptAmount
                    .subtract(creditToApplyToTaxExemptAmount)
                    .toUnit()
            }
        }
    };
};

export const _applyStoreCreditToFees = (
    storeCreditAmount: Dinero.Dinero,
    fees: FeeWithTaxInfo[]
): { storeCreditRemaining: Dinero.Dinero; modifiedFeesByIndex: FeesByIndex } =>
    compose(
        reduce(_applyStoreCreditToFee, {
            storeCreditRemaining: storeCreditAmount,
            modifiedFeesByIndex: {}
        }),
        orderBy([(fee: FeeWithIndex) => fee.taxInfo.rate], ["desc"]),
        (fees: FeeWithTaxInfo[]) =>
            fees.map(
                (fee, i) =>
                    ({
                        ...fee,
                        index: i.toString()
                    }) as FeeWithIndex
            )
    )(fees);

export type GetFeesAndItemsWithCreditAppliedResponse = {
    itemsByIndex: CartItemsByIndex;
    feesByIndex: FeesByIndex;
};

export type GetFeesAndItemsWithCreditAppliedRequest = {
    cartItems: CartItemWithAtLeastTaxInfo[];
    storeTaxRate: number;
    customerFees: FeeWithTaxInfo[];
    storeCreditAmountInDollars: number;
    fulfillment: Fulfillment;
};

export const getFeesAndItemsWithCreditApplied = ({
    cartItems,
    customerFees,
    storeTaxRate,
    storeCreditAmountInDollars,
    fulfillment
}: GetFeesAndItemsWithCreditAppliedRequest): GetFeesAndItemsWithCreditAppliedResponse => {
    const { storeCreditRemaining, modifiedCartItemsByIndex } =
        _applyStoreCreditToCartItems(
            DD(storeCreditAmountInDollars),
            cartItems,
            storeTaxRate,
            fulfillment
        );

    // There could be store credit remaining that was used to pay for things
    // other than cart items and fees, but those other line items are not
    // taxable so the remaining credit can be ignored.
    const { modifiedFeesByIndex } = _applyStoreCreditToFees(
        storeCreditRemaining,
        customerFees
    );

    return {
        itemsByIndex: modifiedCartItemsByIndex,
        feesByIndex: modifiedFeesByIndex
    };
};

/**
 * Calculates and returns the sales tax amount
 *
 * @param cartItems
 * @param taxableCustomFeeAmountInDollars
 * @param storeTaxRate
 * @param upchargeAmountInDollars
 * @returns {Dinero.Dinero}
 */
export function calculate({
    cartItems,
    cartAdjustments,
    customerFees,
    storeTaxRate,
    storeCreditAmountInDollars,
    upchargeAmountInDollars,
    fulfillment
}: CalculateSalesTaxRequest): CalculateSalesTaxResponse {
    const {
        itemsByIndex: modifiedCartItemsByIndex,
        feesByIndex: modifiedFeesByIndex
    } = getFeesAndItemsWithCreditApplied({
        cartItems,
        customerFees,
        storeTaxRate,
        storeCreditAmountInDollars,
        fulfillment
    });

    const cartItemsWithAppliedCredit = cartItems.map(
        (_item, i) => modifiedCartItemsByIndex[i.toString()]
    );
    const feesWithAppliedCredit = customerFees.map(
        (_fee, i) => modifiedFeesByIndex[i.toString()]
    );

    const cartItemsWithTax = cartItemsWithAppliedCredit.map((item) => ({
        ...item,
        ...calculateTaxForItem({ item, storeTaxRate, fulfillment })
    }));
    const feesWithTax = _buildFeesWithSalesTax(feesWithAppliedCredit);

    const salesTaxOnItems = _calculateSalesTaxForItems(cartItemsWithTax);
    const salesTaxFeeInfo = calculateSalesTaxForCustomerFees(
        feesWithAppliedCredit
    );
    const upchargeAmount = convertToDinero(upchargeAmountInDollars || 0);
    const salesTaxOnUpcharge = upchargeAmount.percentage(storeTaxRate);

    const { surchargeTotal, discountTotal, effectiveTaxRate } =
        _calculateTaxableCartAdjustments(
            cartItemsWithTax,
            cartAdjustments,
            fulfillment,
            storeTaxRate
        );

    const salesTaxOnCartSurcharge = surchargeTotal.percentage(storeTaxRate);
    const salesTaxOnCartDiscounts = discountTotal.percentage(effectiveTaxRate);

    const taxAmount = salesTaxOnItems
        .add(salesTaxOnCartSurcharge)
        .subtract(salesTaxOnCartDiscounts)
        .add(salesTaxFeeInfo.taxAmount)
        .add(salesTaxOnUpcharge);

    const taxableAmount = cartItemsWithTax
        .reduce((acc, elem) => acc.add(elem.taxableAmount), ZERO)
        .add(surchargeTotal)
        .subtract(discountTotal)
        .add(salesTaxFeeInfo.taxableAmount)
        .add(upchargeAmount)
        .toObject();

    const taxExemptAmount = cartItemsWithTax
        .reduce((acc, elem) => acc.add(elem.taxExemptAmount), ZERO)
        .add(salesTaxFeeInfo.taxExemptAmount)
        .toObject();

    const taxIsNegative = taxAmount.lessThan(Dinero({ amount: 0 }));
    const result = {
        cartItems: cartItemsWithTax,
        fees: feesWithTax,
        taxAmount: taxIsNegative
            ? Dinero({ amount: 0 }).toObject()
            : taxAmount.toObject(),
        taxExemptAmount,
        taxableAmount
    };

    verbose(`calculate`, { result });

    return result;
}

/**
 * Calculates and returns the sales tax amount for a specific Store
 */
export function calculateForStore({
    cart,
    store,
    transactionSource,
    customerFeePolicies,
    storeCreditAmountInDollars,
    upchargeAmountInDollars = 0
}: CalculateSalesTaxForStoreRequest): CalculateSalesTaxResponse {
    // HACK: Online ordering multicart has really janky logic which causes it
    // to use both single- and multi-cart/checkout selectors at once for either
    // scenario. In addition, its default Redux `store` state is not null,
    // but rather an incomplete object, so the simple `null` check is
    // insufficient.
    if (!store || isNil(store.taxRate)) {
        return {
            cartItems: [],
            fees: [],
            taxAmount: DD(0).toObject(),
            taxExemptAmount: DD(0).toObject(),
            taxableAmount: DD(0).toObject()
        };
    }

    const cartItems = cart.items;
    const cartAdjustments = cart.cartAdjustments || [];
    const storeTaxRate = determineRateForFulfillment(cart.fulfillment, store);

    const subtotal = calcSubtotal(
        cartItems.map(flattenCartItem),
        cartAdjustments
    );

    const calcAmountInDollars = (feePolicy: FeePolicy, quantity: number) =>
        calculateFeeForPolicy(subtotal, feePolicy, quantity);

    const _quantity = (
        feePolicy: FeePolicy,
        cart: Pick<ICart, "numberOfBags">
    ): number => {
        switch (feePolicy.name) {
            case ReservedFeeEnum.BagStoreFee:
                return cart.numberOfBags;
            default:
                return 1;
        }
    };

    // Build basic Customer-paid fees from Store.feePolicies
    const customerFees: FeeWithTaxInfo[] = (customerFeePolicies || []).map(
        (feePolicy): FeeWithTaxInfo => {
            const quantity = _quantity(feePolicy, cart);
            const isTaxable = FeeTaxService.getIsTaxable(feePolicy);
            const rate = isTaxable
                ? !isNil(feePolicy.taxRate)
                    ? feePolicy.taxRate
                    : storeTaxRate
                : 0;

            return {
                feePolicy,
                taxInfo: { rate },
                quantity,
                taxableAmountInDollars: isTaxable
                    ? calcAmountInDollars(feePolicy, quantity)
                    : 0,
                taxExemptAmountInDollars: !isTaxable
                    ? calcAmountInDollars(feePolicy, quantity)
                    : 0,
                type: "custom"
            };
        }
    );

    // Add customer fee for Delivery Fee, if applicable
    if (cart.fulfillment === "DELIVERY" && cart.selectedDeliveryQuote) {
        const quote = cart.selectedDeliveryQuote;
        const feeInDollars = quote.customerPrice;
        const deliveryFee: FeeWithTaxInfo = {
            feePolicy: {
                name:
                    quote.provider === "store"
                        ? ReservedFeeEnum.DeliveryFee1P
                        : ReservedFeeEnum.DeliveryFee3P,
                payer: FeePolicyPayer.Customer,
                recipient:
                    quote.provider === "store"
                        ? FeePolicyRecipient.Store
                        : FeePolicyRecipient.Snackpass,
                flat: feeInDollars,
                percent: 0,
                rules: {}
            },
            quantity: 1,
            taxInfo: { rate: storeTaxRate },
            taxableAmountInDollars: feeInDollars,
            taxExemptAmountInDollars: 0,
            type: "delivery"
        };

        customerFees.push(deliveryFee);
    }

    // Add customer fee for Store Convenience Fee, if applicable
    const convenienceFeePolicy = store.convenienceFeePolicies.find(
        (fp) => fp.transactionSource === transactionSource
    );

    if (subtotal !== 0 && (convenienceFeePolicy || store.hasConvenienceFee)) {
        const feeInDollars = convenienceFeePolicy
            ? convenienceFeePolicy.value
            : store.convenienceFee;
        const fee = {
            name: ReservedFeeEnum.ConvenienceStoreFee,
            payer: FeePolicyPayer.Customer,
            recipient: FeePolicyRecipient.Store,
            flat: feeInDollars,
            percent: 0,
            rules: {}
        };
        const isTaxable = FeeTaxService.getIsTaxable(fee);

        const rate = isTaxable ? storeTaxRate : 0;
        const taxableAmountInDollars = FeeTaxService.getIsTaxable(fee)
            ? feeInDollars
            : 0;
        const taxExemptAmountInDollars = feeInDollars - taxableAmountInDollars;

        const convenienceStoreFee: FeeWithTaxInfo = {
            feePolicy: fee,
            quantity: 1,
            taxInfo: { rate: rate },
            taxableAmountInDollars,
            taxExemptAmountInDollars,
            type: "custom"
        };

        customerFees.push(convenienceStoreFee);
    }

    verbose(`calculateForStore`, {
        cart,
        store,
        storeTaxRate,
        transactionSource,
        customerFeePolicies
    });

    return calculate({
        cartItems,
        cartAdjustments,
        customerFees,
        storeTaxRate,
        upchargeAmountInDollars,
        storeCreditAmountInDollars,
        fulfillment: cart.fulfillment
    });
}

/**
 * Format custom fee into fee with tax information.
 * NOTE: Because customFees are EOL, no need to consider taxable configuration.
 */
const transformCustomFee =
    (feeTaxInfo: SalesTaxT) =>
    (fee: CustomFeeInDollars): FeeWithTaxInfo => ({
        feePolicy: {
            name: fee.name,
            recipient: FeePolicyRecipient.Snackpass,
            payer: FeePolicyPayer.Customer,
            rules: {},
            flat: fee.total,
            percent: 0
        },
        taxInfo: feeTaxInfo,
        taxableAmountInDollars: fee.total,
        taxExemptAmountInDollars: 0,
        type: "custom",
        quantity: 1
    });

/**
 * Format fee into fee with tax information.
 */
const transformFee =
    (feeTaxInfo: SalesTaxT) =>
    ({ fee, total, type }: IPurchaseFee): FeeWithTaxInfo => {
        const taxableAmountInDollars = FeeTaxService.getIsTaxable(fee)
            ? total
            : 0;
        const taxExemptAmountInDollars = total - taxableAmountInDollars;

        return {
            feePolicy: fee,
            taxInfo: feeTaxInfo,
            taxableAmountInDollars,
            taxExemptAmountInDollars,
            type: type === "DELIVERY" ? "delivery" : "custom",
            quantity: 1
        };
    };

const isCustomerFacingFee = ({ fee }: IPurchaseFee) =>
    fee.payer === FeePolicyPayer.Customer;

export function _getCustomerFacingFeesWithTaxInfo(
    purchase: Pick<
        IPurchase,
        "salesTaxRate" | "customFees" | "fees" | "fulfillment" | "deliveryInfo"
    >,
    convenienceFeeAmountDollars: number,
    deliveryFeeAmountDollars: number,
    overrides?: {
        fees?: IPurchaseFee[];
        customFees?: CustomFeeInDollars[];
    }
): FeeWithTaxInfo[] {
    // Transform customer-facing fees, custom fees as well as legacy fees into
    // fee with tax info format.
    const feeTaxInfo: SalesTaxT = { rate: purchase.salesTaxRate || 0 };
    const customFees = overrides?.customFees || purchase.customFees || [];
    const fees = overrides?.fees || purchase.fees || [];
    const customFeesWithTax = customFees.map(transformCustomFee(feeTaxInfo));
    const feesWithTax = fees
        .filter(isCustomerFacingFee)
        .map(transformFee(feeTaxInfo));

    verbose(`_getCustomerFacingFeesWithTaxInfo`, {
        fees,
        feesWithTax
    });

    // Convert legacy convenience, delivery fees into Fee format.
    let convenienceFee: FeeWithTaxInfo | null = null,
        deliveryFee: FeeWithTaxInfo | null = null;
    if (convenienceFeeAmountDollars > 0) {
        const feePolicy = {
            name: ReservedFeeEnum.ConvenienceStoreFee,
            recipient: FeePolicyRecipient.Store,
            payer: FeePolicyPayer.Customer,
            flat: convenienceFeeAmountDollars,
            percent: 0,
            rules: {}
        };
        const convenienceFeeIsTaxable = FeeTaxService.getIsTaxable(feePolicy);
        const taxableAmountInDollars = convenienceFeeIsTaxable
            ? convenienceFeeAmountDollars
            : 0;
        const taxExemptAmountInDollars =
            convenienceFeeAmountDollars - taxableAmountInDollars;

        convenienceFee = {
            feePolicy,
            taxInfo: feeTaxInfo,
            taxableAmountInDollars,
            taxExemptAmountInDollars,
            type: "custom",
            quantity: 1
        };
    }

    if (deliveryFeeAmountDollars > 0) {
        const feePolicy = {
            name: purchaseIs3PDelivery(purchase)
                ? ReservedFeeEnum.DeliveryFee3P
                : ReservedFeeEnum.DeliveryFee1P,
            recipient: purchaseIs3PDelivery(purchase)
                ? FeePolicyRecipient.Snackpass
                : FeePolicyRecipient.Store,
            payer: FeePolicyPayer.Customer,
            flat: deliveryFeeAmountDollars,
            percent: 0,
            rules: {}
        };
        const deliveryFeeIsTaxable = FeeTaxService.getIsTaxable(feePolicy);
        const taxableAmountInDollars = deliveryFeeIsTaxable
            ? deliveryFeeAmountDollars
            : 0;
        const taxExemptAmountInDollars =
            deliveryFeeAmountDollars - taxableAmountInDollars;

        deliveryFee = {
            feePolicy,
            taxInfo: feeTaxInfo,
            taxableAmountInDollars,
            taxExemptAmountInDollars,
            type: "delivery",
            quantity: 1
        };
    }

    return [
        ...customFeesWithTax,
        ...feesWithTax,
        convenienceFee,
        deliveryFee
    ].filter(Boolean) as FeeWithTaxInfo[];
}

/**
 * Calculate amount of sales tax due for a purchase as well as the amount of
 * the purchase that is taxable and tax exempt.
 * All arguments besides the purchase are optional and override the value
 * cached on the purchase if supplied.
 */
export function calculateForPurchase(
    purchase: Pick<
        IPurchase,
        | "convenienceFee"
        | "deliveryFee"
        | "storeCreditUsed"
        | "upChargeAmount"
        | "taxableSnackpassContribution"
        | "fees"
        | "customFees"
        | "items"
        | "salesTaxRate"
        | "fulfillment"
        | "cartAdjustments"
    >,
    overrides?: {
        fees?: IPurchaseFee[];
        items?: IOrderItem[];
        convenienceFeeAmountDollars?: number;
        deliveryFeeAmountDollars?: number;
        upchargeAmountDollars?: number;
        storeCreditUsedAmountDollars?: number;
        taxableSnackpassContributionAmountDollars?: number;
    }
): CalculateSalesTaxResponse {
    const convenienceFee = !isNil(overrides?.convenienceFeeAmountDollars)
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          overrides!.convenienceFeeAmountDollars
        : purchase.convenienceFee || 0;
    const deliveryFee = !isNil(overrides?.deliveryFeeAmountDollars)
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          overrides!.deliveryFeeAmountDollars
        : purchase.deliveryFee || 0;
    const upcharge = !isNil(overrides?.upchargeAmountDollars)
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          overrides!.upchargeAmountDollars
        : purchase.upChargeAmount || 0;
    const storeCredit = !isNil(overrides?.storeCreditUsedAmountDollars)
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          overrides!.storeCreditUsedAmountDollars
        : purchase.storeCreditUsed || 0;
    const taxableSnackpassContribution = !isNil(
        overrides?.taxableSnackpassContributionAmountDollars
    )
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          overrides!.taxableSnackpassContributionAmountDollars
        : purchase.taxableSnackpassContribution || 0;

    // Transform purchase items into cart item format.
    const cartItems = (overrides?.items || purchase.items).map((item) => ({
        ...item,
        taxInfo: item.taxInfo || null,
        taxPolicies: item.taxPolicies ?? [],
        amountAfterDiscountInDollars: item.totalPriceAfterDiscount
    }));
    const cartAdjustments = purchase.cartAdjustments || [];

    const purchaseFees = overrides?.fees || purchase.fees || [];
    const purchaseCustomFees = purchase.customFees || [];

    const customerFees = _getCustomerFacingFeesWithTaxInfo(
        purchase,
        convenienceFee,
        deliveryFee,
        { fees: purchaseFees, customFees: purchaseCustomFees }
    );

    verbose(`calculateForPurchase`, { purchaseFees, customerFees });

    const salesTaxRate = purchase.salesTaxRate || 0;
    const fulfillment = purchase.fulfillment || FulfillmentTypeEnum.Pickup;

    const {
        cartItems: cartItemsWithTax,
        fees: feesWithTax,
        taxAmount,
        taxableAmount,
        taxExemptAmount
    } = calculate({
        cartItems,
        cartAdjustments,
        storeTaxRate: salesTaxRate,
        customerFees,
        upchargeAmountInDollars: upcharge,
        storeCreditAmountInDollars: storeCredit,
        fulfillment
    });

    const taxOnSnackpassContribution = convertToDinero(
        taxableSnackpassContribution
    ).percentage(salesTaxRate);

    const totalTaxAmount = Dinero(taxAmount).add(taxOnSnackpassContribution);
    const taxIsNegative = totalTaxAmount.lessThan(Dinero({ amount: 0 }));

    return {
        cartItems: cartItemsWithTax,
        fees: feesWithTax,
        taxAmount: taxIsNegative
            ? Dinero({ amount: 0 }).toObject()
            : totalTaxAmount.toObject(),
        taxExemptAmount: Dinero(taxExemptAmount).toObject(),
        taxableAmount: Dinero(taxableAmount).toObject()
    };
}

export const buildFulfillmentTaxPolicy = (
    type: FulfillmentTypeEnum,
    rate: number,
    priority?: number
): TaxPolicy => {
    return {
        priority: priority || 0,
        conditions: {
            "==": [{ var: "fulfillment" }, type]
        },
        events: [
            {
                type: TaxPolicyEventType.setTaxRate,
                taxInfo: { rate }
            }
        ]
    };
};

export const determineRateForFulfillment = (
    fulfillment: Fulfillment,
    store: Pick<
        IStore,
        | "taxRate"
        | "specifyTaxRateByFulfillment"
        | "taxRatePickup"
        | "taxRateDineIn"
        | "taxRateDelivery"
    >
): number => {
    verbose(`determineRateForFulfillment`, {
        fulfillment,
        store: pick(
            [
                "taxRate",
                "specifyTaxRateByFulfillment",
                "taxRatePickup",
                "taxRateDineIn",
                "taxRateDelivery"
            ],
            store
        )
    });
    if (store.specifyTaxRateByFulfillment) {
        switch (fulfillment) {
            case "PICKUP":
                return store.taxRatePickup || 0;
            case "DINE_IN":
                return store.taxRateDineIn || 0;
            case "DELIVERY":
                return store.taxRateDelivery || 0;
            default:
                return store.taxRate;
        }
    }
    return store.taxRate;
};

export const SalesTax = {
    determineRateForFulfillment,
    calculate,
    calculateForPurchase,
    calculateForStore,
    calculateTaxForItem,
    calculateSalesTaxForCustomerFees
};
