import { ReduxState } from "../../types";

/**
 * Base selector functions. Other selectors are composed of these base functions.
 */
export const getUser = (state: Pick<ReduxState, "user">) => state.user;
export const getActiveStore = (state: Pick<ReduxState, "activeStore">) =>
    state.activeStore;
export const getMyLocation = (state: Pick<ReduxState, "myLocation">) =>
    state.myLocation;
export const getPunchcards = (state: Pick<ReduxState, "punchcards">) =>
    state.punchcards;
export const getTransactionSource = (
    state: Pick<ReduxState, "transactionSource">
) => state.transactionSource;
export const getPaymentProvider = (
    state: Pick<ReduxState, "paymentProvider">
) => state.paymentProvider;
