import { CSSProperties } from "styled-components";
import { Footer } from "@/components/Footer";
import SnackpassLogo from "src/assets/logos/snackpass-logo.svg";
import SnackpassLogoInverse from "src/assets/logos/snackpass-logo-inverse.svg";

export type PoweredByProps = {
    style?: CSSProperties;
};

export const PoweredByBlack = ({ style }: PoweredByProps) => {
    if (window.ReactNativeWebView) {
        return null;
    }
    return (
        <div className="flex flex-col justify-center pt-[20px] text-center">
            <a href="https://snackpass.co" className="flex flex-row gap-2">
                <span className="text-base font-medium text-primary">
                    Powered by
                </span>
                <img
                    style={{
                        height: 25,
                        ...style,
                    }}
                    alt="Powered by Snackpass"
                    src={SnackpassLogo}
                />
            </a>
            <Footer />
        </div>
    );
};

export const PoweredByInverse = ({ style }: PoweredByProps) => {
    if (window.ReactNativeWebView) {
        return null;
    }
    return (
        <div className="flex flex-col justify-center pt-[20px] text-center">
            <a href="https://snackpass.co" className="flex flex-row gap-2">
                <span className="text-base font-medium text-[#F9FAFB]">
                    Powered by
                </span>
                <img
                    style={{
                        ...style,
                    }}
                    alt="Powered by Snackpass"
                    src={SnackpassLogoInverse}
                />
            </a>
            <Footer />
        </div>
    );
};
