import React, { Component, CSSProperties } from "react";
import { Colors } from "src/utils";
import { BeatLoader } from "react-spinners";

type ButtonProps = {
    labelColor?: string;
    backgroundColor?: string;
    onPress: (e?: any) => void;
    style?: CSSProperties;
    label: string | any;
    loading?: any;
    loadingColor?: string;
    disabled?: boolean;
    className?: string;
    type?: "submit" | "button";
};

export class Button extends Component<ButtonProps> {
    render() {
        const {
            labelColor,
            type,
            className,
            label,
            loading,
            disabled,
            backgroundColor,
            loadingColor = Colors.white
        } = this.props;
        return (
            <button
                type={type}
                className={`${className} button`}
                onClick={e => {
                    if (loading || disabled) {
                        return;
                    }
                    this.props.onPress(e);
                }}
                style={{
                    fontSize: 16,
                    padding: 10,
                    border: "none",
                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.20)",
                    borderRadius: 40,
                    fontWeight: "bold",
                    backgroundColor: disabled
                        ? Colors.gray
                        : backgroundColor || Colors.blue,
                    color: labelColor || Colors.white,
                    ...this.props.style
                }}
            >
                {!loading ? (
                    <span>{label}</span>
                ) : (
                    <BeatLoader color={loadingColor} size={10} />
                )}
            </button>
        );
    }
}
