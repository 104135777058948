import React from "react";
import { cn } from "@/utils";
import { useSelector, useDispatch } from "react-redux";
import { getUserIsLoggedIn, logOut, showModal, ModalsName } from "src/redux";
import { ReactComponent as ProfileIcon } from "src/assets/icons/profile.svg";

const SettingsAlert = () => {
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const dispatch = useDispatch();
    const showAuthenticationModal = () =>
        dispatch(showModal(ModalsName.authenticationModal));

    if (!isUserLoggedIn) {
        return (
            <button
                className={cn(
                    "border-none outline-none font-semibold ml-0 bg-transparent",
                )}
                onClick={() => showAuthenticationModal()}
            >
                <div className="flex flex-row items-center">
                    <ProfileIcon className="w-[22px] h-[22px] fill-secondary mr-1.5" />
                    <span className="text-primary text-base font-medium">
                        Log in
                    </span>
                </div>
            </button>
        );
    }

    return (
        <button
            className={cn(
                "border-none outline-none font-semibold ml-0 bg-transparent",
            )}
            onClick={() => dispatch(logOut(true))}
        >
            <div className="flex flex-row items-center">
                <ProfileIcon className="w-[22px] h-[22px] fill-secondary mr-1.5" />
                <span className="text-primary text-base font-medium">
                    Log Out
                </span>
            </div>
        </button>
    );
};

export default SettingsAlert;
