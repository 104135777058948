import {
    getActiveStore,
    getIsTipSelected,
    getSelectedTipIndex,
    setIsTipSelected,
    setSelectedTipIndex,
} from "@/redux";
import {
    CartActions,
    CartSelectors,
    CheckoutSelectors,
} from "@snackpass/accounting";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import {
    DefaultTipType,
    FulfillmentTypeEnum,
} from "@snackpass/snackpass-types";
import fp from "lodash/fp";
import _ from "lodash";
import { Helpers } from "@/utils";

export const NO_TIP_INDEX = -1;
export const CUSTOM_TIP_INDEX = -2;

export enum TipOptionType {
    CUSTOM_TIP = 0,
    FIXED_TIP = 1,
}

export const useTip = () => {
    const activeStore = useSelector(getActiveStore);
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const subtotal = useSelector(CheckoutSelectors.getSubtotal);
    const tip = useSelector(CheckoutSelectors.getTip);

    const dispatch = useDispatch();
    const tipIsSelected = useSelector(getIsTipSelected);
    const selectedTipIndex = useSelector(getSelectedTipIndex);
    const _setIsTipSelected = fp.compose(dispatch, setIsTipSelected);
    const _setSelectedTipIndex = fp.compose(dispatch, setSelectedTipIndex);
    const _setTip = fp.compose(dispatch, CartActions.setCartTip);

    const isOnlyGiftCardItems = useSelector(
        CartSelectors.isCartOnlyGiftCardItems,
    );

    const [tipOptions, setTipOptions] = useState<number[]>([]);
    const showTipOnPickup = get(activeStore, "showTipOnPickup", true);

    // If don't show tip on pickup, and the fulfillment is pickup
    const hideTip =
        !showTipOnPickup && fulfillment === FulfillmentTypeEnum.Pickup;

    useEffect(() => {
        // set tip options on initial load or when subtotal changes
        if (activeStore?.defaultTip) {
            const { defaultTips } = activeStore?.defaultTip;
            setTipOptions(defaultTips);
        }
    }, [activeStore?.defaultTip, subtotal]);

    useEffect(() => {
        // on initial load(tip not selected yet) set default tip if store has set the default tip value
        if (
            !_.isEmpty(tipOptions) &&
            activeStore?.defaultTip &&
            !tipIsSelected
        ) {
            const { defaultTipType, defaultTipValue } = activeStore?.defaultTip;
            if (isOnlyGiftCardItems) {
                _setTip(0);
            } else {
                if (defaultTipType === DefaultTipType.Percent) {
                    _setTip((defaultTipValue / 100) * subtotal);
                } else {
                    _setTip(defaultTipValue);
                }
            }
            _setIsTipSelected(true);
        }
    }, [
        _setIsTipSelected,
        _setTip,
        activeStore?._id,
        activeStore?.defaultTip,
        selectedTipIndex,
        subtotal,
        tipIsSelected,
        tipOptions,
    ]);

    useEffect(() => {
        // set tip if user already selected a tip and come back to cart screen from store screen
        if (!_.isEmpty(tipOptions) && tipIsSelected) {
            const tipVal = tipOptions[selectedTipIndex];
            if (tipVal) {
                const defaultTipType = activeStore?.defaultTip?.defaultTipType;
                if (defaultTipType === DefaultTipType.Percent) {
                    _setTip((tipVal / 100) * subtotal);
                } else {
                    _setTip(tipVal);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipOptions, subtotal]);

    useEffect(() => {
        // find and set the selected tip index based on the tip value
        if (
            !_.isEmpty(tipOptions) &&
            tipIsSelected &&
            selectedTipIndex > CUSTOM_TIP_INDEX
        ) {
            let tipValue: number;
            if (
                activeStore?.defaultTip.defaultTipType ===
                DefaultTipType.Percent
            ) {
                tipValue = Math.round((tip / subtotal) * 100);
            } else {
                tipValue = tip;
            }
            _setSelectedTipIndex(tipOptions.findIndex((i) => i === tipValue));
        }
    }, [
        tipOptions,
        tipIsSelected,
        activeStore?.defaultTip,
        _setSelectedTipIndex,
        tip,
        subtotal,
        selectedTipIndex,
    ]);

    const updateSelectedTip = (optionType: TipOptionType, tipValue: number) => {
        if (optionType === TipOptionType.CUSTOM_TIP) {
            _setSelectedTipIndex(CUSTOM_TIP_INDEX);
        } else if (optionType === TipOptionType.FIXED_TIP && tipValue === 0) {
            _setSelectedTipIndex(NO_TIP_INDEX);
        } else {
            _setSelectedTipIndex(tipOptions.findIndex((i) => i === tipValue));
        }
    };

    const onSelectTip = (optionType: TipOptionType, tipValue: number) => {
        // UI Updates
        updateSelectedTip(optionType, tipValue);
        const tipPercentToDecimal = tipValue / 100;
        let selectTip = () => {};
        const defaultTipType = activeStore?.defaultTip?.defaultTipType;
        // For custom tip set it always as flat value
        if (
            defaultTipType === DefaultTipType.Percent &&
            optionType !== TipOptionType.CUSTOM_TIP
        ) {
            selectTip = () =>
                _setTip(Helpers.round(tipPercentToDecimal * subtotal));
        } else {
            selectTip = () => _setTip(tipValue);
        }
        selectTip();
        _setIsTipSelected(true);
    };

    return {
        tipOptions,
        hideTip,
        tip,
        onSelectTip,
    };
};
