import { getActiveStore } from "@/redux";
import {
    CartActions,
    CartSelectors,
    DealSelectionActions,
} from "@snackpass/accounting";
import { IStore } from "@snackpass/snackpass-types";
import fp from "lodash/fp";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export function useHandleCartStoreDifferences() {
    const dispatch = useDispatch();
    const cartItems = useSelector(CartSelectors.getCartItems);
    const activeStore = useSelector(getActiveStore);
    const setCartStore = useCallback(
        (store: IStore) => dispatch(CartActions.setCartStore(store)),
        [dispatch],
    );
    const clearCart = useCallback(
        () => dispatch(CartActions.clearCart()),
        [dispatch],
    );
    const clearDealItems = useCallback(
        () => dispatch(DealSelectionActions.clearDealItems()),
        [dispatch],
    );

    useEffect(() => {
        if (!activeStore?._id) {
            return;
        }

        // Check for invalid products
        let hasInvalidProducts = !!fp.find(
            (item) => activeStore._id !== fp.prop("product.store._id")(item),
            cartItems,
        );

        // if there aren't any invalid products,
        // set the cart's store to the current active cart
        if (!hasInvalidProducts) {
            setCartStore(activeStore);
        } else {
            clearCart();
            clearDealItems();
            setCartStore(activeStore);
        }
    }, [activeStore?._id, cartItems, clearCart, clearDealItems, setCartStore]);
}
