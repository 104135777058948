import { CSSProperties } from "react";
import React from "react";
import { lightBlue } from "../../../utils/Colors";
import { history } from "../../../utils/history";
import { Text, View } from "src/SharedComponents";
import { GiftCardStateType } from "../lib";
import { getActiveStore, logOut } from "src/redux";
import { useDispatch, useSelector } from "react-redux";
import xWhiteIcon from "src/assets/icons/xWhiteIcon.svg";
import GiftCardCheck from "src/assets/icons/giftCard-check.svg";

const ORDER_PLACED = "Order placed";

type ConfirmationHeaderProps = {
    fromName: string;
    giftCardState: GiftCardStateType;
};

export const ConfirmationHeader = ({
    fromName,
    giftCardState,
}: ConfirmationHeaderProps) => {
    const dispatch = useDispatch();
    const THANKS = `Thank you, ${fromName}!`;
    const activeStore = useSelector(getActiveStore);

    if (!activeStore) return null;
    const onClose = () => {
        giftCardState.isKioskPurchase
            ? dispatch(logOut())
            : history.replace(`/${activeStore.slug}`);
    };

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <img
                    alt="Close"
                    style={styles.imgIcon}
                    src={xWhiteIcon}
                    onClick={onClose}
                />

                <Text style={styles.textTitle} large>
                    {ORDER_PLACED}
                </Text>
            </View>

            <img alt="Check" style={styles.checkIcon} src={GiftCardCheck} />

            <Text style={styles.thanksText}>{THANKS}</Text>
        </View>
    );
};

const styles = {
    textTitle: {
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "28px",
        fontWeight: 700,
        color: "#FFFFFF",
        textAlign: "center",
        width: "100%",
        marginRight: "25px",
    } as CSSProperties,

    thanksText: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "32px",
        textAlign: "center",
        color: "#FFFFFF",
        padding: "2%",
    } as CSSProperties,

    selector: {
        width: "100%",
        zIndex: 10,
        position: "static",
    } as CSSProperties,

    imgIcon: {
        width: 20,
        height: "auto",
        cursor: "pointer",
        marginLeft: "10px",
    } as CSSProperties,

    checkIcon: {
        marginTop: "5%",
        width: "72px",
        height: "54px",
        alignItems: "center",
    } as CSSProperties,

    header: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        background: lightBlue,
    } as CSSProperties,

    container: {
        width: "100%",
        padding: "1%",
        zIndex: 100,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: lightBlue,
    } as CSSProperties,
};
