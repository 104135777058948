import "core-js";
import smoothscroll from "smoothscroll-polyfill";

import { createRoot } from "react-dom/client";
import "./index.css";
import "src/tailwind/tailwind.generated.css";
import "./qr.css";
import "./react-modal.css";
import "antd/dist/antd.css";
import "./antd-overrides.css"; // Important that this is after antd to properly override
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from "./App";

smoothscroll.polyfill();

const container = document.getElementById("root");
if (container) {
    const root = createRoot(container);
    root.render(<App />);
}
