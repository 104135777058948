import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IProduct } from "@snackpass/snackpass-types";
import {
    ActiveCartItemActions,
    ActiveCartItemSelectors,
} from "@snackpass/accounting";
import { CategoryProduct } from "@/utils/Types";
import ProductCard from "@/screens/StoreScreen/components/ProductCard";
import {
    ModalsName,
    getActivePromotion,
    getActiveStore,
    hideModal,
    showModal,
} from "@/redux";
import { usePromoItemProductsAndImages } from "@/hooks/usePromoItemProductsAndImages";
import { usePointsAfterPromotion } from "@/hooks/usePointsAfterPromotion";

const ModalContent = () => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const activePromotion = useSelector(getActivePromotion);
    const selectedAddons =
        useSelector(ActiveCartItemSelectors.getSelectedAddons) || [];
    const { noProductsAvailable, availableCategoryProducts } =
        usePromoItemProductsAndImages(activePromotion);
    const getPointsAfterPromotion = usePointsAfterPromotion();

    const handlePressProduct = (product: IProduct) => {
        dispatch(
            ActiveCartItemActions.setActiveCartItem(
                product,
                activePromotion ?? undefined,
                undefined,
                undefined,
                getPointsAfterPromotion(product),
            ),
        );
        dispatch(hideModal(ModalsName.claimRewardModal));
        setTimeout(() => {
            dispatch(showModal(ModalsName.productModal));
        }, 250);
    };
    if (!activeStore || !activePromotion) {
        return null;
    }

    if (noProductsAvailable) {
        return (
            <div className="flex flex-col px-4 items-center">
                No menu item available right now
            </div>
        );
    }

    return (
        <div className="flex flex-col px-4">
            {availableCategoryProducts.map((cp: CategoryProduct, i: number) => (
                <div key={`${cp.category}-${i}`}>
                    <div className="text-2xl text-primary font-bold py-4">
                        {cp.category}
                    </div>
                    <div className="flex flex-col gap-4">
                        {cp.products.map((p) => {
                            return (
                                <ProductCard
                                    showDescription={false}
                                    key={p._id}
                                    product={p}
                                    handlePressProduct={() =>
                                        handlePressProduct(p)
                                    }
                                    isPromoOrReward={true}
                                    selectedAddons={selectedAddons}
                                ></ProductCard>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ModalContent;
