import { FeePolicy, ReservedFeeEnum } from "@snackpass/snackpass-types";
import { isNil } from "lodash/fp";
import { verbose } from "../../verbose";

type IsTaxableMapping = {
    [key in ReservedFeeEnum]: {
        isTaxable: boolean;
    };
};

const IS_TAXABLE_MAPPING: IsTaxableMapping = {
    [ReservedFeeEnum.PaymentProcessingCost]: { isTaxable: false },
    [ReservedFeeEnum.DeliveryFee1P]: { isTaxable: true },
    [ReservedFeeEnum.DeliveryTip3P]: { isTaxable: false },
    [ReservedFeeEnum.DeliveryFee3P]: { isTaxable: true },
    [ReservedFeeEnum.DeliveryServiceFee3P]: { isTaxable: true },
    [ReservedFeeEnum.DeliveryCommission3P]: { isTaxable: false },
    [ReservedFeeEnum.DeliveryTipCommission3P]: { isTaxable: false },
    [ReservedFeeEnum.DeliveryCost3P]: { isTaxable: false },
    [ReservedFeeEnum.DeliveryTipCost3P]: { isTaxable: false },
    [ReservedFeeEnum.DeliveryStoreCommissionAdjustment3P]: { isTaxable: false },
    [ReservedFeeEnum.Commission]: { isTaxable: false },
    [ReservedFeeEnum.CommissionFlat]: { isTaxable: false },
    [ReservedFeeEnum.CreditCardCommission]: { isTaxable: false },
    [ReservedFeeEnum.ConvenienceFee]: { isTaxable: true },
    [ReservedFeeEnum.ConvenienceStoreFee]: { isTaxable: false },
    [ReservedFeeEnum.SnackpassConvenienceFee]: { isTaxable: false },
    [ReservedFeeEnum.OnlineOrderingConvenienceFee]: { isTaxable: true },
    [ReservedFeeEnum.OnlineOrderingConvenienceStoreFee]: { isTaxable: true },
    [ReservedFeeEnum.KioskConvenienceFee]: { isTaxable: true },
    [ReservedFeeEnum.KioskConvenienceStoreFee]: { isTaxable: true },
    [ReservedFeeEnum.FaxCommission]: { isTaxable: false },
    [ReservedFeeEnum.SmallOrderDeliveryStoreFee]: { isTaxable: true },
    [ReservedFeeEnum.DeliveryStoreFee1P]: { isTaxable: true },
    [ReservedFeeEnum.DeliveryTipStoreFee1P]: { isTaxable: false },
    [ReservedFeeEnum.BagStoreFee]: { isTaxable: false },
    [ReservedFeeEnum.DisposableCupStoreFee]: { isTaxable: false },
    [ReservedFeeEnum.AlcoholStoreFee]: { isTaxable: false },
    [ReservedFeeEnum.CreditCardCost]: { isTaxable: false },
    [ReservedFeeEnum.FoodHallFee]: { isTaxable: true },
    [ReservedFeeEnum.ProcessingFee]: { isTaxable: false },
    [ReservedFeeEnum.ProcessingFeeFlat]: { isTaxable: false }
};
const _getIsTaxable = (
    policy: Pick<FeePolicy, "isTaxable" | "name">
): boolean => {
    if (!isNil(policy.isTaxable)) {
        return policy.isTaxable;
    }

    const taxInfo = IS_TAXABLE_MAPPING[policy.name as ReservedFeeEnum];
    return taxInfo?.isTaxable ?? true;
};

export const getIsTaxable = (
    policy: Pick<FeePolicy, "isTaxable" | "name">
): boolean => {
    const result = _getIsTaxable(policy);

    verbose(`getIsTaxable`, { policy, result });

    return result;
};

export const FeeTaxService = {
    getIsTaxable
};
