import React from "react";
import { IStore } from "@snackpass/snackpass-types";
import { StoreListRow } from "./StoreListRow";
import { useSortStores } from "@/screens/LocationsScreen.tsx/hooks/useSortStores";
import { useSelectStore } from "@/screens/LocationsScreen.tsx/hooks/useSelectStore";

// Note: StoreListRow must be called using the TSX syntax vs. function syntax. Otherwise we will
// occasionally encounter the error "React Error: Rendered fewer hooks than expected". See this
// article for more details about this issue:
// https://medium.com/@jonchurch/how-to-fix-react-error-rendered-fewer-hooks-than-expected-e6a378985d3c

export const StoreList: React.FC = () => {
    const { sortedStores } = useSortStores();
    const { handleSelectStore, selectedStore } = useSelectStore();
    return (
        <div className="w-full">
            {sortedStores.map((store) => (
                <StoreListRow
                    key={store._id}
                    store={store}
                    onClick={handleSelectStore}
                    selectedStore={selectedStore}
                />
            ))}
        </div>
    );
};
