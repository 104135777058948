import { IPurchase, PurchaseStatus } from "@snackpass/snackpass-types";
import humanizeString from "humanize-string";
import { last } from "lodash";
import moment from "moment";

const purchaseStatusMap = {
    [PurchaseStatus.received]: "Received",
    [PurchaseStatus.started]: "Started",
    [PurchaseStatus.canceled]: "Canceled",
    [PurchaseStatus.completed]: "Ready for Pickup",
};

export function useOrderStatus(purchase: IPurchase) {
    const lastStatus = last(purchase.status)?.type;
    const isDelivery = purchase.fulfillment === "DELIVERY";
    const hasNotStarted = purchase.status.every(
        (status) => status.type !== PurchaseStatus.started,
    );

    /* The pickupTime is calculated server-side when creating purchase, considering the store's waitTimeType (dynamic or manual set on RDB).
     */
    const estimatedPickupTime = moment(purchase.pickupTime || "").format(
        "h:mma",
    );
    return {
        isDelivery,
        /// UI text fields
        orderStatusText:
            isDelivery || purchase.refund
                ? ""
                : purchaseStatusMap[last(purchase.status)?.type || ""],
        deliveryStatusText: humanizeString(purchase.deliveryInfo?.status || ""),
        cancelledText: purchase.refund ? "Cancelled" : "",
        estimatedTimeText:
            isDelivery || purchase.refund || hasNotStarted
                ? ""
                : lastStatus === PurchaseStatus.completed
                  ? "Staff confirmed your order is ready"
                  : `Estimated ready at: ${estimatedPickupTime}`,
        refundText: !purchase.refund
            ? ""
            : `${purchase.partialRefund ? "Partial Refund" : "Refunded"}` +
              (purchase.refundReason ? `: ${purchase.refundReason}` : ""),
        scheduledText: purchase.scheduledDate
            ? `Scheduled for ${moment(purchase.scheduledDate).format(
                  "MMM D, h:mm a",
              )}`
            : "",
        cateringText: purchase.catering?.isCatering
            ? `Catering order for ${purchase?.catering?.headcount} people.`
            : "",
        deliveryTrackingText: purchase.deliveryInfo?.trackingUrl
            ? "Track your order"
            : "",
        deliveryTrackingURL: purchase.deliveryInfo?.trackingUrl || "",
    };
}
