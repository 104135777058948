import { IGiftCard } from "@snackpass/snackpass-types";
import {
    GiftCardActions,
    SET_GIFTCARDS,
    ADD_REDEEM_GIFTCARD,
    SET_INITIAL_STATE
} from "./actions";

export const giftCardsInitialState: IGiftCard[] = [];

export function giftCards(
    state = giftCardsInitialState,
    action: GiftCardActions
): IGiftCard[] {
    switch (action.type) {
        case SET_GIFTCARDS:
            return action.giftCards;

        case ADD_REDEEM_GIFTCARD:
            if (
                state.find(
                    (giftCard) =>
                        // Digital gift cards may not send their code on all clients, so ensure
                        // the duplicate is matching on a non-null value of code
                        giftCard.code != null &&
                        giftCard.code === action.giftCard.code
                )
            ) {
                // If a duplicate exists, there's no action to take.
                return state;
            }
            return [...state, action.giftCard];

        case SET_INITIAL_STATE:
            return giftCardsInitialState;
        default:
            return state;
    }
}
