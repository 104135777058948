import { CartActions } from "@snackpass/accounting";
import { getCartIsCatering } from "@snackpass/accounting/build/src/redux/cart/selectors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useQuery from "src/hooks/useQuery";
import { getActiveStore, ModalsName, showModal } from "src/redux";

export function useLoadCatering() {
    const query = useQuery();
    const history = useHistory();
    const cateringQuery = query.get("catering");
    const [checkedCateringInQueryParams, setCheckedCateringInQueryParams] =
        useState(false);

    const isCatering = useSelector(getCartIsCatering);
    const activeStore = useSelector(getActiveStore);
    const isLoading = !activeStore?._id;
    const dispatch = useDispatch();
    // Switch to catering mode if catering=true query param is set
    useEffect(() => {
        if (isLoading) return;
        const params = new URLSearchParams(window.location.search);

        // if store does not have catering set, rm from query
        if (!activeStore?.cateringEnabled) {
            params.delete("catering");
            history.push({ search: params.toString() });
            return;
        }

        // otherwise, set catering and if necessary, show schedule modal
        const isCateringQuery = cateringQuery === "true";
        if (isCateringQuery) {
            if (!isCatering) dispatch(CartActions.enterCateringMode());
            dispatch(showModal(ModalsName.scheduleModal));
        }
        setCheckedCateringInQueryParams(true);
    }, [isLoading, cateringQuery, activeStore?._id]);

    // Set query param based off of cart redux state
    useEffect(() => {
        if (!checkedCateringInQueryParams) return;
        const params = new URLSearchParams(window.location.search);
        if (isCatering) {
            params.set("catering", "true");
        } else {
            params.delete("catering");
        }
        history.push({ search: params.toString() });
    }, [isCatering, checkedCateringInQueryParams]);

    useEffect(() => {
        if (isCatering && !activeStore?.cateringEnabled) {
            dispatch(CartActions.exitCateringMode());
        }
    }, [isCatering, activeStore?._id]);
}
