import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, AppState } from "./STORE";

/**
 * Typed version of `useDispatch`.
 */
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

/**
 * Typed version of `useSelector`.
 */
export const useAppSelector = useSelector.withTypes<AppState>();
