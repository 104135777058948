import { CSSProperties, useEffect, useState } from "react";
import React from "react";
import { View } from "src/SharedComponents";
import { GiftCardHeader } from "./components/GiftCardHeader";
import { PoweredByBlack } from "src/components/PoweredBy";
import { Row } from "react-bootstrap";
import { GiftCardStateType, START_STATE, StepMoment } from "./lib";
import { history } from "src/utils/history";
import { Api } from "src/utils";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStore } from "src/redux";
import { GiftCardStepper } from "./components/GiftCardStepper";
import { setActiveStore } from "#activeStore";

export const GiftCardScreen = () => {
    const [giftCardState, setGiftCardState] =
        useState<GiftCardStateType>(START_STATE);
    const [step, setStep] = useState<StepMoment>(StepMoment.fillingForm);

    const dispatch = useDispatch();
    const showComponents =
        step === StepMoment.fillingForm || step === StepMoment.checkout;
    const store = useSelector(getActiveStore);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const getStoreSelected = async () => {
            try {
                const storeID = queryParams.get("storeID");
                const result = await Api.stores.get(storeID!);
                const { store } = result.data;
                if (!store.hasGiftCardsEnabled) {
                    history.replace(`/${store?.slug}`);
                }
                dispatch(setActiveStore(store));
            } catch (err) {
                console.log(err);
            }
        };
        if (!store?._id) getStoreSelected();

        const platform = queryParams.get("platform");
        if (platform === "kiosk")
            setGiftCardState({
                ...giftCardState,
                isKioskPurchase: true,
            });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const setStart = () => setGiftCardState(START_STATE);

    const MAX_WIDTH =
        step === StepMoment.fillingForm || step === StepMoment.checkout
            ? 768
            : "100%";

    if (!store) return null;

    return (
        <View>
            <View style={{ ...styles.extContainer, maxWidth: MAX_WIDTH }}>
                <View style={styles.insideContainer}>
                    {showComponents ? (
                        <GiftCardHeader
                            step={step}
                            setStep={setStep}
                            giftCardState={giftCardState}
                        />
                    ) : null}
                    <Row
                        className="justify-content-md-center"
                        style={styles.giftCardStepperRow}
                    >
                        <GiftCardStepper
                            giftCardState={giftCardState}
                            setGiftCardState={setGiftCardState}
                            step={step}
                            setStep={setStep}
                            setStart={setStart}
                            store={store}
                        />
                    </Row>
                </View>
            </View>
            {showComponents ? (
                <View style={styles.footerLogo}>
                    <PoweredByBlack />
                </View>
            ) : null}
        </View>
    );
};

const styles = {
    giftCardStepperRow: { width: "100%" } as CSSProperties,

    extContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
        width: "100%",
        margin: "auto",
    } as CSSProperties,

    insideContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        height: "100%",
    } as CSSProperties,

    footerLogo: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 155,
    } as CSSProperties,
};
