import {
    FeePolicyWithTotal,
    Fulfillment,
    FulfillmentTypeEnum,
    IGiftCard,
    IPurchase,
    ReservedFeeEnum,
    TransactionSourceTypeEnum
} from "@snackpass/snackpass-types";
import Dinero from "dinero.js";
import {
    all,
    compose,
    filter,
    isNil,
    map,
    prop,
    propOr,
    sumBy
} from "lodash/fp";
import { createSelector } from "reselect";
import { Accounting, validatePromotion } from "../../accountingFormulas";
import {
    findCustomerFeePolicies,
    reserved
} from "../../accountingFormulas/fees";
import { FeeTaxService } from "../../accountingFormulas/fees/SalesTax";
import { labelForFee } from "../../accountingFormulas/fees/utils";
import { SalesTax } from "../../accountingFormulas/salesTax";
import { CartItem, DealGroup } from "../../types";
import { getIsGiftCardProduct, round } from "../../utils";
import {
    getCart,
    getCartAdjustments,
    getCartDealGroups,
    getCartFulfillment,
    getCartIsCatering,
    getCartItems,
    getCartScheduleDate,
    getCartTableNumber,
    getDoesCartHaveAnyGiftCardItems,
    getNumberOfBags,
    getPickupEstimate,
    getSelectedCartDeliveryQuote
} from "../cart/selectors";
import {
    getActiveStore,
    getActiveStoreConvenienceFeePolicies,
    getActiveStoreDeliveryFee,
    getActiveStoreDeliveryMin,
    getActiveStoreFeePolicies,
    getActiveStoreId,
    getActiveStorePickupMin,
    getActiveStoreRegion,
    getMyLocation,
    getPaymentProvider,
    getStoreCreditAvailable,
    getTransactionSource,
    getUserAddress,
    getUserCredit
} from "../helperSelectors";
import {
    getActiveGiftCards,
    getGiftCardCreditAvailable
} from "./../giftCards/selectors";

export const getMinimumChargeAmountCents = createSelector(
    [getActiveStore],
    (store) => store?.minimumChargeAmountCents ?? 50
);

export const getTaxRate = createSelector(
    [getActiveStore],
    propOr(0, "taxRate")
);

export const getTip = createSelector([getCart], propOr(0, "tip"));

export const getNotes = createSelector([getCart], prop("notes"));

export const getCartItemSubtotal = createSelector(
    [getCartItems],
    Accounting.calcCartItemSubtotal
);

export const getSubtotal = createSelector(
    [getCartItems, getCartAdjustments],
    Accounting.calcSubtotal
);

export const getSubtotalWithoutDiscounts = createSelector(
    [getCartItems],
    Accounting.calcTotal
);

export const getSubtotalWithoutGiftCards = createSelector(
    [getCartItems, getCartAdjustments],
    (cartItems, cartAdjustments) => {
        const subtotal = Accounting.calcSubtotal(
            cartItems.filter(
                (item) => getIsGiftCardProduct(item.product) === false
            ),
            cartAdjustments.filter((adjustment) => {
                // Remove adjustments made on gift cards as well.
                const matchingCartItem = cartItems.find(
                    (item) => item.groupUuid === adjustment.uuid
                );
                if (matchingCartItem == null) {
                    return true;
                }
                return getIsGiftCardProduct(matchingCartItem.product) === false;
            })
        );
        // The subtotal could be negative if the cart contains physical gift cards that are getting
        // discounted due to flat cart adjustments that apply to the whole cart. In this case we'd
        // rather treat this as though the subtotal was a $0 cart.
        return Math.max(subtotal, 0);
    }
);

export const getPoints = createSelector(
    [getCartItems],
    sumBy(propOr(0, "points"))
);

export const getPointsAfterPromotion = createSelector(
    [getCartItems],
    sumBy(propOr(0, "pointsAfterPromotion"))
);

// this gets *the amount of* user credit available
export const getAvailableCredit = createSelector(
    [getUserCredit, getStoreCreditAvailable, getGiftCardCreditAvailable],
    (userCredit, storeCreditAvailable, giftCardCreditAvailable) =>
        userCredit || storeCreditAvailable || giftCardCreditAvailable || 0
);

// this gets *if* a user has credit available
export const getHasCreditAvailable = createSelector(
    [getAvailableCredit, getDoesCartHaveAnyGiftCardItems],
    (availableCredit, doesCartHaveGiftCardItems) =>
        availableCredit > 0 && doesCartHaveGiftCardItems === false
);

// this gets if credit can be used on a cart
export const getUseCredit = createSelector(
    [getCart, getHasCreditAvailable],
    (cart, hasCredit) => cart.useCredit && hasCredit
);

export const getCustomerFeePolicies = createSelector(
    [
        getSubtotalWithoutGiftCards,
        getCartFulfillment,
        getTransactionSource,
        getPaymentProvider,
        getCartIsCatering,
        getActiveStoreFeePolicies,
        getUseCredit
    ],
    (
        cartSubtotal,
        fulfillment,
        transactionSource,
        paymentProvider,
        isCatering,
        feePolicies,
        useCredit
    ) =>
        findCustomerFeePolicies({
            cartSubtotal,
            fulfillment,
            transactionSource,
            paymentProvider,
            isCatering: Boolean(isCatering),
            feePolicies,
            useCredit
        })
);

export const getCustomerFeeBreakdown = createSelector(
    [getSubtotalWithoutGiftCards, getNumberOfBags, getCustomerFeePolicies],
    (cartSubtotal, numberOfBags, feePolicies) =>
        feePolicies
            .map((policy) =>
                // NOTE: Technically this can return `null`, but here since we
                // know that `policy` exists in `feePolicies`, we can guarantee
                // that it will not be `null`.
                reserved.calculate({
                    feeName: policy.name as ReservedFeeEnum,
                    feePolicies,
                    amount: cartSubtotal,
                    // `quantity` is better than `numberOfBags` on this generic payload...
                    quantity: policy.name === "BAG_STORE_FEE" ? numberOfBags : 1
                })
            )
            .filter(Boolean) as FeePolicyWithTotal[]
);

export const getCustomerFeeTotal = createSelector(
    [getCustomerFeeBreakdown],
    sumBy("total")
);

export const getTaxableCustomerFeeTotal = createSelector(
    [getCustomerFeeBreakdown],
    // FIXME: Because some fees can be partially-tax-exempt, we either need to add an exemptAmount to the fee to
    //        combine with isTaxable, or split such fees into a pair of taxable and non-taxable fees.
    compose(
        sumBy("total"),
        filter(compose(FeeTaxService.getIsTaxable, prop("fee")))
    )
);

/**
 * This selector is meant to be used by clients to display customer-facing
 * fees with labels.
 */
export const getLabeledCustomerFeeBreakdown = createSelector(
    [getCustomerFeeBreakdown, getActiveStore],
    (fees, store) => {
        return fees.map((fee: FeePolicyWithTotal) => {
            return {
                label: labelForFee(fee.fee.name, store),
                amount: fee.total
            };
        });
    }
);

export const getDeliveryFeeApplied = createSelector(
    [
        getCartFulfillment,
        getTransactionSource,
        getSelectedCartDeliveryQuote,
        getActiveStoreDeliveryFee,
        getCartIsCatering
    ],
    (
        fulfillment,
        transactionSource,
        deliveryQuote,
        activeStoreDeliveryFee,
        isCatering
    ) =>
        // Kiosk only submits delivery orders in employee mode, in which case
        // we don't want to apply a Snackpass delivery fee (it's just
        // recording a third party order, so the fee is likely unknown).
        // Also we only apply this for kiosk when cart is catering
        fulfillment === FulfillmentTypeEnum.Delivery &&
        transactionSource !== TransactionSourceTypeEnum.Kiosk &&
        !isCatering
            ? deliveryQuote
                ? deliveryQuote.customerPrice
                : activeStoreDeliveryFee || 0
            : 0
);

// @Deprecated
export const getSubtotalPlusDeliveryFee = createSelector(
    [getSubtotal, getDeliveryFeeApplied],
    (subtotal, deliveryFeeApplied) => round(subtotal + deliveryFeeApplied)
);

export const getMaxCreditAmount = createSelector(
    [
        getSubtotalWithoutGiftCards,
        getDeliveryFeeApplied,
        getTip,
        getCustomerFeeTotal
    ],
    (subtotal, deliveryFeeApplied, tip, customerFeeTotal) =>
        round(subtotal + deliveryFeeApplied + tip + customerFeeTotal) || 0
);

export const getMinIsReached = createSelector(
    [
        getActiveStoreDeliveryMin,
        getActiveStorePickupMin,
        getCartFulfillment,
        getSubtotalWithoutGiftCards,
        getSubtotal
    ],
    (deliveryMin, pickupMin, fulfillment, subtotalWithoutPGC, subtotal) => {
        if (fulfillment === "DELIVERY") {
            const diff = deliveryMin - subtotalWithoutPGC;
            if (diff > 0) {
                return false;
            }
        } else if (fulfillment === "PICKUP") {
            const pickupDiff = pickupMin - subtotal;
            if (pickupDiff > 0) {
                return false;
            }
        }
        return true;
    }
);

export const getDiffDeliveryMin = createSelector(
    [getActiveStoreDeliveryMin, getSubtotalWithoutGiftCards],
    (deliveryMin, subtotal) => deliveryMin - subtotal
);

export const getCheckoutRewards = createSelector(
    [getCartItems],
    (cartItems) => {
        const ret: string[] = [];
        cartItems.forEach((item) => {
            if (item.reward) {
                ret.push(item.reward._id);
            }
        });
        return ret;
    }
);

// get store credit applied
export const getStoreCredit = createSelector(
    [
        getStoreCreditAvailable,
        getGiftCardCreditAvailable,
        getMaxCreditAmount,
        getUseCredit
    ],
    (activeStoreCredit, giftCardCredit, maxCreditAmount, useCredit) =>
        useCredit
            ? Accounting.calcStoreCreditApplied(
                  activeStoreCredit || 0,
                  giftCardCredit || 0,
                  maxCreditAmount
              )
            : 0
);

// Get list of promotion ids that are currently in the cart
export const getCheckoutPromotions = createSelector(
    [getCartItems],
    (cartItems) => {
        const ret: string[] = [];
        cartItems.forEach((item) => {
            if (item.promotion) {
                ret.push(item.promotion._id);
            }
        });
        return ret;
    }
);

// Receipt & Checkout items

export const getSalesTax = createSelector(
    [
        getCart,
        getActiveStore,
        getTransactionSource,
        getCustomerFeePolicies,
        getStoreCredit
    ],
    (
        cart,
        store,
        transactionSource,
        customerFeePolicies,
        storeCreditAmountInDollars
    ) =>
        SalesTax.calculateForStore({
            cart,
            store,
            transactionSource,
            customerFeePolicies,
            upchargeAmountInDollars: 0,
            storeCreditAmountInDollars
        })
);

export const getTaxableAmount = createSelector([getSalesTax], (salesTax) =>
    Dinero(salesTax.taxableAmount).toUnit()
);

export const getTax = createSelector([getSalesTax], (salesTax) =>
    Dinero(salesTax.taxAmount).toUnit()
);

export const cartContainsItemsThatAreNotDeals = createSelector(
    [getCartItems],
    (cartItems) => {
        let numItems = 0;
        cartItems.forEach((item) => {
            if (!item.dealGroupId) {
                numItems += 1;
            }
        });
        return numItems > 0;
    }
);

export const getGlobalCredit = createSelector(
    [
        getStoreCredit,
        getGiftCardCreditAvailable,
        getTax,
        getUserCredit,
        getMaxCreditAmount,
        getUseCredit
    ],
    (
        storeCredit: number,
        giftCardCreditAvailable: number,
        tax: number,
        userCredit: number,
        maxCreditAmount: number,
        useCredit: boolean
    ) =>
        // do not use global credit if store credit used
        useCredit
            ? Accounting.calcGlobalCreditApplied(
                  storeCredit,
                  giftCardCreditAvailable,
                  tax,
                  userCredit,
                  maxCreditAmount
              )
            : 0
);

export const getStoreAndGlobalCreditUsed = createSelector(
    [getStoreCredit, getGlobalCredit],
    Accounting.calcStoreAndGlobalCreditUsed
);

// Get list of promotion ids that are currently in the cart
export const getCheckoutDealIds = createSelector(
    [getCartDealGroups],
    (dealGroups) => {
        const ret: string[] = [];
        dealGroups.forEach((dealGroup) => {
            if (dealGroup.promotion._id) {
                ret.push(dealGroup.promotion._id);
            }
        });
        return ret;
    }
);

/** @deprecated in favor of getStoreConvenienceFee */
const getConvenienceFee = createSelector(
    [
        getSubtotalWithoutGiftCards,
        getUseCredit,
        getGiftCardCreditAvailable,
        getStoreAndGlobalCreditUsed,
        getMaxCreditAmount,
        getTransactionSource,
        getActiveStoreConvenienceFeePolicies
    ],
    Accounting.calcConvenienceFee
);

// Alias to make purpose more clear...
export const getStoreConvenienceFee = getConvenienceFee;

export const getSnackpassConvenienceFee = createSelector(
    [getSubtotalWithoutGiftCards, getCustomerFeePolicies],
    Accounting.calcSnackpassConvenienceFee
);

// it can cover taxes and store convenience fee as well as the default fees computed in getMaxCreditAmount
export const getMaxGiftCardCreditAmount = createSelector(
    [getMaxCreditAmount, getTax, getStoreConvenienceFee],
    (defaultMaxCreditAmount, tax, storeConvenienceFee) =>
        round(defaultMaxCreditAmount + tax + storeConvenienceFee) || 0
);

// get giftCard credit applied
export const getGiftCardCredit = createSelector(
    [getGiftCardCreditAvailable, getMaxGiftCardCreditAmount, getUseCredit],
    (giftCardCredit: number, maxCreditAmount: number, useCredit: boolean) =>
        useCredit
            ? Accounting.calcGiftCardCreditApplied(
                  giftCardCredit || 0,
                  maxCreditAmount
              )
            : 0
);

// Gives the codes and pins for gift cards the user has in their state that they
// have manually added (aka we didn't get these gift cards a phone number lookup)
// manually added gift cards must have encryptedPin, while gift cards added via phone
// should make sure to not fetch this field (since physical gift cards can be converted to digital)
export const getManuallyAddedGiftCardsApplied = createSelector(
    [getUseCredit, getActiveGiftCards],
    (useCredit: boolean, activeGiftCards: readonly IGiftCard[] | null) =>
        useCredit
            ? activeGiftCards
                  ?.filter((card) => card.encryptedPin != null)
                  ?.map((card) => ({
                      code: card.code,
                      encryptedPin: card.encryptedPin
                  }))
            : []
);

export const getTotalCreditUsed = createSelector(
    [getStoreCredit, getGiftCardCredit, getGlobalCredit],
    Accounting.calcTotalCreditUsed
);

export const getAmountPaidByCustomer = createSelector(
    [
        getSubtotal,
        getTax,
        getStoreCredit,
        getGlobalCredit,
        getGiftCardCredit,
        getTip,
        getStoreConvenienceFee,
        getCustomerFeeTotal,
        getDeliveryFeeApplied
    ],
    Accounting.calcGetAmountPaidByCustomer
);

export const getSubtotalPlusTaxesAndFees = createSelector(
    [
        getSubtotal,
        getTax,
        getStoreConvenienceFee,
        getCustomerFeeTotal,
        getDeliveryFeeApplied
    ],
    Accounting.calcSubtotalPlusTaxesAndFees
);

export const getTotal = createSelector(
    [getAmountPaidByCustomer],
    (amountPaidByCustomer) => amountPaidByCustomer
);

export const getTotalWithoutCreditsApplied = createSelector(
    [
        getSubtotal,
        getTax,
        getTip,
        getStoreConvenienceFee,
        getCustomerFeeTotal,
        getDeliveryFeeApplied
    ],
    Accounting.calcTotalWithoutCreditsApplied
);

// Combine individual selectors into a selector group
export const getFeesAndFulfillment = createSelector(
    [
        getDeliveryFeeApplied,
        getStoreConvenienceFee,
        getCustomerFeeBreakdown,
        getCartFulfillment
    ],
    (deliveryFee, convenienceFee, fees, fulfillment) => ({
        deliveryFee,
        convenienceFee,
        fees,
        fulfillment
    })
);

export const getCredits = createSelector(
    [getGlobalCredit, getStoreCredit, getGiftCardCredit],
    (globalCredit, storeCredit, giftCardCredit) => ({
        globalCredit,
        storeCredit,
        giftCardCredit
    })
);

export const getCheckoutBenefits = createSelector(
    [getCheckoutRewards, getCheckoutPromotions],
    (rewards, promotions) => ({
        rewards,
        promotions
    })
);

export const getCartDetails = createSelector(
    [getTip, getNotes, getCartItems, getCartTableNumber, getCartFulfillment],
    (tip, notes, items, tableNumber, fulfillment) => ({
        tip,
        notes,
        items,
        ...(fulfillment === "DINE_IN" && tableNumber && { tableNumber })
    })
);

export const getFormattedPurchase = createSelector(
    [
        getPoints,
        getPointsAfterPromotion,
        getCredits,
        getFeesAndFulfillment,
        getUserAddress,
        getCheckoutBenefits,
        getPickupEstimate,
        getCartDetails,
        getNumberOfBags
    ],
    (
        points,
        pointsAfterPromotion,
        credits,
        feesAndFulfillment,
        address,
        checkoutBenefits,
        pickupEstimate,
        cartDetails,
        numberOfBags
    ) => ({
        globalCreditUsed: credits.globalCredit,
        deliveryAddress: address,
        storeCreditUsed: credits.storeCredit || 0,
        giftCardsCreditUsed: credits.giftCardCredit || 0,
        points,
        pointsAfterPromotion,
        numberOfBags,
        ...cartDetails,
        ...checkoutBenefits,
        ...feesAndFulfillment,
        ...(pickupEstimate && { pickupEstimate })
    })
);

export const getFormattedPurchaseWithStore = createSelector(
    [
        getFormattedPurchase,
        getActiveStoreRegion,
        getActiveStoreId,
        getMyLocation
    ],
    (purchase, region, storeId, geolocation) => {
        return {
            ...purchase,
            region,
            storeId,
            geolocation
        } as unknown as IPurchase;
    }
);

export const getCartItemValidations = createSelector(
    [
        getCartItems,
        getCartFulfillment,
        getSubtotal,
        getCheckoutPromotions,
        getCheckoutDealIds,
        getCartScheduleDate
    ],
    (cartItems, fulfillment, subtotal, promotionIds, dealids, scheduledDate) =>
        cartItems.map((cartItem) =>
            validatePromotion(
                cartItem.promotion,
                subtotal,
                fulfillment,
                promotionIds,
                dealids,
                cartItem.totalPrice,
                scheduledDate
            )
        )
);

// false if any item is not validated
export const getCartIsValidated = createSelector(
    [getCartItemValidations],
    all((validationItem) => Boolean(prop("validated", validationItem)))
);

export const getNonDealCartItems = createSelector([getCartItems], (cartItems) =>
    cartItems.filter((item) => (item.dealGroupId ? false : true))
);

export const getDealGroupValidationMessage = (
    fulfillment: Fulfillment,
    subtotal: number,
    nonDealCartItems: CartItem[],
    dealGroup: DealGroup
): string => {
    const conditions = dealGroup.promotion.conditions || {};

    if (!isNil(conditions.cartMin)) {
        if (subtotal < conditions.cartMin) {
            return `${dealGroup.name} requires a cart min of $${conditions.cartMin}`;
        }
    }
    if (!isNil(conditions.cartMax)) {
        if (subtotal > conditions.cartMax) {
            return `${dealGroup.name} requires a cart max of $${conditions.cartMax}`;
        }
    }
    if (!isNil(conditions.pickupOnly)) {
        if (conditions.pickupOnly && fulfillment !== "PICKUP") {
            return `${dealGroup.name} is pickup only!`;
        }
    }

    return "validated";
};

export const getAmountSavedWithSnackpass = createSelector(
    [getCartItems],
    (cartItems) =>
        cartItems.reduce(
            (prevValue, cartItem) =>
                prevValue +
                (cartItem.totalPrice - cartItem.totalPriceAfterDiscount),
            0
        )
);
