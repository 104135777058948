import _ from "lodash";
import { useCallback, useMemo } from "react";

import {
    CartSelectors,
    CheckoutSelectors,
    toDollar,
    validateAddPromotion,
} from "@snackpass/accounting";
import { IPromotion } from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";
import { getPromotions } from "@/redux";
import { useAppSelector } from "@/redux/utils";

export const usePromotionValidator = () => {
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const promotionsInCart = useSelector(
        CheckoutSelectors.getCheckoutPromotions,
    );
    const scheduledDate = useAppSelector(CartSelectors.getCartScheduleDate);

    const dealIds = useSelector(CheckoutSelectors.getCheckoutDealIds);
    const subtotal = useSelector(CheckoutSelectors.getSubtotal);
    const storePromotions = useSelector(getPromotions);
    const rewardIdsInCart = useSelector(CheckoutSelectors.getCheckoutRewards);
    const populatedCheckoutPromotions = useMemo(
        () =>
            storePromotions.filter((promo) =>
                promotionsInCart.includes(promo._id),
            ),
        [storePromotions, promotionsInCart],
    );

    const getPromotionErrorReason = useCallback((reason: string): string => {
        if (reason.includes("This happy hour is only available")) {
            const cutoffIndex = reason.indexOf("~~~");
            return `Sorry, this promotion is only available: ${reason.slice(
                cutoffIndex + 3,
            )}`;
        }
        return reason;
    }, []);

    const validateCheckoutPromotion = useCallback(
        (promotion: IPromotion) => {
            if (promotion.conditions) {
                const { cartMin, cartMax } = promotion.conditions;
                if (cartMin && subtotal < cartMin) {
                    return {
                        validated: false,
                        reason: `Cart must have a subtotal greater than ${toDollar(
                            cartMin,
                        )}`,
                    };
                } else if (cartMax && subtotal > cartMax) {
                    return {
                        validated: false,
                        reason: `Cart must have a subtotal less than ${toDollar(
                            cartMax,
                        )}`,
                    };
                }
            }
            return {
                validated: true,
                reason: "",
            };
        },
        [subtotal],
    );

    const validateCartPromotions = useCallback(() => {
        const failedValidations = populatedCheckoutPromotions
            .map((promo) => ({
                ...validateCheckoutPromotion(promo),
                promotion: promo,
            }))
            .filter((check) => !check.validated);

        const message = failedValidations
            .map(
                (validation) =>
                    `${validation.promotion.name}: ${getPromotionErrorReason(
                        validation.reason,
                    )}`,
            )
            .join("\n");

        return {
            valid: failedValidations.length === 0,
            message,
        };
    }, [
        getPromotionErrorReason,
        populatedCheckoutPromotions,
        validateCheckoutPromotion,
    ]);

    const _validateAddPromotion = useCallback(
        (promotion: IPromotion) => {
            return validateAddPromotion(
                promotion,
                fulfillment,
                promotionsInCart,
                dealIds,
                subtotal,
                rewardIdsInCart,
                scheduledDate,
            );
        },
        [
            fulfillment,
            promotionsInCart,
            dealIds,
            subtotal,
            rewardIdsInCart,
            scheduledDate,
        ],
    );

    return useMemo(
        () => ({
            getPromotionErrorReason,
            validateAddPromotion: _validateAddPromotion,
            validateCartPromotions,
        }),
        [
            _validateAddPromotion,
            getPromotionErrorReason,
            validateCartPromotions,
        ],
    );
};
