import { GiftCardActions } from "@snackpass/accounting";
import { history } from "src/utils/history";
import { clearUser } from "./user";
import { firebaseAuth } from "src/firebase";
import { ThunkDispatch } from "@reduxjs/toolkit";

export const SET_AUTH_STATUS = "SET_AUTH_STATUS";
export type SET_AUTH_STATUS = "SET_AUTH_STATUS";
export enum AuthStatusType {
    NOT_LOADED = "NOT_LOADED",
    SIGNED_IN = "SIGNED_IN",
    NOT_SIGNED_IN = "NOT_SIGNED_IN",
}

export interface ISetAuthStatusAction {
    type: SET_AUTH_STATUS;
    authStatus: AuthStatusType;
}

export type AuthStatusActions = ISetAuthStatusAction;

export const setAuthStatus = (authStatus: AuthStatusType) => {
    return { type: SET_AUTH_STATUS, authStatus };
};

export function logOut(stayOnCurrentScreen?: boolean) {
    return async (dispatch: ThunkDispatch<any, any, any>) => {
        await firebaseAuth.signOut();
        dispatch(setAuthStatus(AuthStatusType.NOT_SIGNED_IN));
        dispatch(clearUser());
        dispatch({
            type: GiftCardActions.SET_GIFTCARDS,
            giftCards: [],
        });
        localStorage?.removeItem("longSession");
        if (!stayOnCurrentScreen) {
            history.push("/");
        }
    };
}

const initialState = AuthStatusType.NOT_LOADED;

export function authStatus(state = initialState, action: AuthStatusActions) {
    switch (action.type) {
        case SET_AUTH_STATUS:
            return action.authStatus;
        default:
            return state;
    }
}

export const getUserIsLoggedIn = (state: any): boolean =>
    (state.authStatus as AuthStatusType) === AuthStatusType.SIGNED_IN;

export const getUserAuthStatus = (state: any): AuthStatusType =>
    state.authStatus;
