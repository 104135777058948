import { CartAddonFlat, CartItemFlat } from "@snackpass/accounting";
import { getCartItems } from "@snackpass/accounting/build/src/redux/cart/selectors";
import { SystemColors } from "@snackpass/design-system";
import { IProduct } from "@snackpass/snackpass-types";
import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { getProductFulfillmentDescription } from "src/utils/Helpers";
import { getActiveStore } from "../../redux";

type Props = {
    product: IProduct;
};

const AddonRow = ({ addon }: { addon: CartAddonFlat }) => {
    const AddonDescription = (
        <span style={styles.addonText}>
            {addon.addonGroup.name}: {addon.name}
        </span>
    );
    if (!addon.priceAfterDiscount) {
        return (
            <span style={styles.addonContainer} key={addon._id}>
                {AddonDescription}
            </span>
        );
    }
    return (
        <div style={styles.addonContainer} key={addon._id}>
            {AddonDescription}
            <span style={styles.addonText}>
                {addon.priceAfterDiscount > 0 ? "+" : "-"}$
                {addon.priceAfterDiscount.toFixed(2)}
            </span>
        </div>
    );
};

const formatMoney = (amount: number) => {
    if (amount === 0) {
        return "Free";
    }
    return `$${amount.toFixed(2)}`;
};

const Price = ({ item }: { item: CartItemFlat }) => {
    const total = formatMoney(item.totalPrice);
    const totalAfterDiscount = formatMoney(item.totalPriceAfterDiscount);

    if (total !== totalAfterDiscount) {
        return (
            <div style={styles.discountedPriceContainer}>
                <span style={styles.headerPriceStrikethrough}>{total}</span>
                <span style={styles.headerPriceText}>{totalAfterDiscount}</span>
            </div>
        );
    }
    return <span style={styles.headerPriceText}>{total}</span>;
};

export const ProductRow = ({ product }: Props) => {
    const store = useSelector(getActiveStore);
    const cart = useSelector(getCartItems);
    const item = cart.find((item) => item.product._id === product._id);

    if (!store || !item) return null;
    const productDescription = getProductFulfillmentDescription(product, store);
    return (
        <div key={item.cartIndex} style={styles.container}>
            <div style={styles.titleContainer}>
                <span style={styles.titleText}>{item.product.name}</span>
                <Price item={item} />
            </div>

            <span style={styles.descriptionText}>{productDescription}</span>
            {item.selectedAddons?.map((addon, i) => {
                return <AddonRow addon={addon} key={i} />;
            })}
        </div>
    );
};

const styles = {
    addonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    } as CSSProperties,
    titleContainer: {
        display: "flex",
        paddingBottom: 2,
        flexDirection: "row",
        justifyContent: "space-between",
    } as CSSProperties,
    container: {
        borderBottomWidth: 1,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 20,
        paddingTop: 15,
        borderColor: SystemColors.gray80,
    } as CSSProperties,
    titleText: {
        fontWeight: "500",
    } as CSSProperties,
    headerPriceText: {
        fontWeight: "400",
        marginLeft: 10,
    } as CSSProperties,
    headerPriceStrikethrough: {
        fontWeight: "400",
        marginLeft: 10,
        textDecorationLine: "line-through",
        textDecorationStyle: "solid",
    } as CSSProperties,
    descriptionText: {
        color: SystemColors.melon50,
        fontWeight: "500",
    } as CSSProperties,
    addonText: {
        fontWeight: "400",
        color: SystemColors.gray40,
    } as CSSProperties,
    discountedPriceContainer: {
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
};
