import React from "react";

type Props = {
    label: string;
};

function DigitalGiftCardFormHeader({ label }: Props) {
    return (
        <div className="text-stone-950 text-xl font-semibold leading-normal py-6">
            {label}
        </div>
    );
}

export default DigitalGiftCardFormHeader;
