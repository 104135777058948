import { useCallback, useMemo, useState, useEffect } from "react";

import { fetchActiveStore } from "#activeStore";
import { useAppDispatch } from "@/redux/utils";
import { TransactionMonitor } from "@/lib/transaction-monitor";
import { IStore } from "@snackpass/snackpass-types";

/**
 * Fetches a store (and its menu) by ID or slug and sets it as the active store.
 */
export function useFetchActiveStore() {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const monitor = useMemo(() => new TransactionMonitor<IStore, Error>(), []);

    useEffect(() => {
        const unsubscribe = monitor.subscribe((result) => {
            setIsLoading(false); // Stop loading once a result is received
            result.match(
                () => setError(null),
                (error) => setError(error),
            );
        });

        return () => {
            unsubscribe();
        };
    }, [monitor]);

    const _fetchStore = useCallback(
        (storeIDorSlug: string) => {
            setIsLoading(true); // Start loading
            setError(null); // Reset error state
            dispatch(
                fetchActiveStore({
                    storeIDorSlug,
                    monitor,
                }),
            );
        },
        [dispatch, monitor],
    );

    return {
        fetchStore: _fetchStore,
        isLoading,
        error,
    };
}
