import { Geolocation } from "@snackpass/snackpass-types";
import { Coords } from "google-map-react";

import { Helpers } from "src/utils";

export const getCoordsFromGeolocation = (geolocation: Geolocation): Coords => ({
    lat: parseFloat(geolocation.coordinates[1].toString()),
    lng: parseFloat(geolocation.coordinates[0].toString()),
});

export const getFormattedDistance = (
    location1: Geolocation,
    location2: Geolocation
): string | null => {
    if (location1.coordinates[0] === 0 && location1.coordinates[1] === 0) {
        return null;
    }

    return Helpers.getMilesAway(
        Helpers.getDistanceBetweenGeopoints(location1, location2)
    );
};
