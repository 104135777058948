import { selectProductDictionary } from "#menu";
import { CartSelectors, itemIsSoldOut } from "@snackpass/accounting";
import { IProduct } from "@snackpass/snackpass-types";
import { useCallback } from "react";
import {
    checkProductHasValidProductHours,
    productHasCompatibleFulfillmentMethod,
} from "@/utils/Helpers";
import { useAppSelector } from "@/redux/utils";

/**
 * Filters list of products for store that:
 * - exist
 * - are not sold out
 * - have a matching fulfillment method
 * - not a catering product
 * - not a priceByWeight product
 */
export const useFilterForCompatibleProducts = () => {
    const activeProductsMap = useAppSelector(selectProductDictionary);
    const fulfillment = useAppSelector(CartSelectors.getCartFulfillment);
    const scheduledDate = useAppSelector(CartSelectors.getCartScheduleDate);
    const hasCompatibleFulfillmentMethod = useCallback(
        (product: IProduct) =>
            productHasCompatibleFulfillmentMethod(product, fulfillment),
        [fulfillment],
    );

    const hasValidProductHours = useCallback(
        (product: IProduct) =>
            checkProductHasValidProductHours(product, scheduledDate),
        [scheduledDate],
    );
    return useCallback(
        (productIds: string[]): IProduct[] =>
            productIds
                .map((p_id) => activeProductsMap[p_id])
                .filter((product: IProduct) => {
                    // Check if product exists
                    // Check if product is sold out, has matching fulfillment methods,
                    // is not catering product and is not priceByWeight
                    // If product does not exist, return false
                    return (
                        !!product &&
                        !product?.priceByWeight &&
                        !product.isCatering &&
                        !itemIsSoldOut(product) &&
                        hasCompatibleFulfillmentMethod(product) &&
                        hasValidProductHours(product)
                    );
                }),
        [
            activeProductsMap,
            hasCompatibleFulfillmentMethod,
            hasValidProductHours,
        ],
    );
};
