import { useSelector } from "react-redux";
import {
    getActiveChain,
    getActiveChainStores,
    getActiveStore,
    getMyLocation,
    hasLocationSet,
} from "@/redux";
import { getDistance } from "geolib";
import { useMemo } from "react";

export function useSortStores() {
    const activeChainStores = useSelector(getActiveChainStores);
    const activeStore = useSelector(getActiveStore);
    const activeChain = useSelector(getActiveChain);
    const locationSet = useSelector(hasLocationSet);
    const myLocation = useSelector(getMyLocation);

    const referenceLocation = locationSet
        ? myLocation
        : activeStore?.geolocation || activeChainStores[0].geolocation;

    const sortedStores = useMemo(() => {
        const calculateDistance = (store) =>
            getDistance(
                {
                    latitude: referenceLocation.coordinates[1],
                    longitude: referenceLocation.coordinates[0],
                },
                {
                    latitude: store.geolocation.coordinates[1],
                    longitude: store.geolocation.coordinates[0],
                },
            );

        return activeChainStores
            .filter(
                (store) => activeChain && store._id !== activeChain.rootStore,
            )
            .sort((a, b) => calculateDistance(a) - calculateDistance(b));
    }, [activeChainStores, referenceLocation, activeChain]);

    // if there are no active chain stores, return the active store
    // allowing non chain stores to use map
    return sortedStores;
}
