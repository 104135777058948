import { ModalsName, getActiveStore, showModal } from "@/redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export function useShouldOpenMap() {
    const activeStore = useSelector(getActiveStore);
    const dispatch = useDispatch();
    const shouldOpenAddressSelector = () => {
        let query = new URLSearchParams(window.location.search);
        let openAddressSelector = query.get("openAddressSelectorIfChain");
        let map = query.get("map");
        return openAddressSelector === "true" || map === "true";
    };
    useEffect(() => {
        if (activeStore) {
            const openAddressSelector = shouldOpenAddressSelector();
            if (openAddressSelector) {
                dispatch(showModal(ModalsName.storeLocationsModal));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStore?._id]);
}
