import { SystemColors } from "@snackpass/design-system";
import fp from "lodash/fp";
import NumberFormat from "react-number-format";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { Api, Helpers } from "src/utils";
import { Constants } from "src/utils/Constants";
import { SegmentEventNames, Segment } from "src/utils/Segment";
import { Button } from "../ui/button";
import { ReactComponent as Flag } from "src/assets/icons/flag.svg";
import { ReactComponent as Plus } from "src/assets/icons/plus-fill.svg";

const onSubmitPhoneNumber = async (number: string) => {
    await Api.auth.sendVerification(number);

    Segment.track(SegmentEventNames.PHONE_NUMBER_SUBMITTED, {
        number,
    });
};

const PhoneNumber = ({
    phoneNumber,
    showVerification,
    setPhoneNumber,
    setShowVerification,
    hideVerification,
    authModal,
}: {
    phoneNumber: string;
    showVerification: boolean;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    setShowVerification: () => void;
    hideVerification: () => void;
    authModal?: boolean;
}) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const phoneNumInputRef = useRef<HTMLInputElement>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const [showErrorBorder, setShowErrorBorder] = useState<boolean>(false);
    useEffect(() => {
        if (checked) {
            localStorage?.setItem("longSession", "true");
        } else {
            localStorage?.removeItem("longSession");
        }
    }, [checked]);
    const isValidPhoneNumber = Helpers.validatePhoneNumber(phoneNumber);

    useEffect(() => {
        if (!isValidPhoneNumber) {
            hideVerification();
        }
        if (isValidPhoneNumber) {
            setShowErrorBorder(false);
        }
    }, [hideVerification, isValidPhoneNumber]);

    const _onPress = useCallback(async () => {
        if (!isValidPhoneNumber) {
            setShowErrorBorder(true);
        } else {
            setShowErrorBorder(false);
            if (isSubmitting) {
                return;
            }
            try {
                setSubmitting(true);
                await onSubmitPhoneNumber(phoneNumber);
                setShowVerification();
            } catch (err) {
                const wasRateLimited = err.response?.status === 429;
                const errorMessage = wasRateLimited
                    ? `Too many send verification code attempts.`
                    : `Error sending phone number: ${fp.get(
                          err,
                          "response.data.message",
                      )}`;
                setError(errorMessage);
            } finally {
                setSubmitting(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting, phoneNumber]);

    const keyPressed = (e) => {
        if (phoneNumInputRef && e.charCode === Constants.enterKey) {
            phoneNumInputRef.current?.blur();
            _onPress();
        }
    };

    if (authModal) {
        return (
            <div className="flex flex-col w-full">
                <div className="flex flex-row">
                    <div className="w-full flex flex-row items-center">
                        <div className="relative inline-block w-full">
                            <div className="w-[24px] h-auto absolute left-2 top-[50%] translate-y-[-50%] ">
                                <Flag />
                            </div>
                            <NumberFormat
                                type="tel"
                                className="qrInput"
                                format="(###) ###-####"
                                mask=" "
                                style={{
                                    background: "none",
                                    width: "100%",
                                    outline: "none",
                                    borderWidth: showErrorBorder ? 2 : 1,
                                    borderColor: showErrorBorder
                                        ? "#FA3A3A"
                                        : "#DFE0E2",
                                    lineHeight: "24px",
                                    padding: "8px 32px 8px 40px", // Adjust the padding to make space for the flag icon
                                    color: SystemColors.sesame,
                                    fontSize: "16px",
                                    borderRadius: "6px",
                                }}
                                value={phoneNumber}
                                placeholder="Phone Number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                onKeyPress={keyPressed}
                                getInputRef={phoneNumInputRef}
                            />
                        </div>
                    </div>
                </div>
                <div className="h-[24px]">
                    {!isValidPhoneNumber && phoneNumber.length ? (
                        <span className="py-[2px] flex flex-1 items-start text-start text-sm text-red-600">
                            Invalid Phone Number
                        </span>
                    ) : null}
                    {error && (
                        <span className="text-alert text-start text-sm">
                            {error}
                        </span>
                    )}
                </div>
                <Button
                    loading={isSubmitting}
                    label="Continue"
                    onPress={_onPress}
                    variant="brand"
                    className="w-full justify-center items-center px-1 py-1 mb-2"
                    loadingIconSize={5}
                    disabled={!isValidPhoneNumber}
                />

                <div className="mt-2 mb-2 flex flex-row flex-1 gap-2 items-center">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                    />
                    <div>Remember me</div>
                </div>

                <div className="mt-2 flex flex-col flex-1 gap-4 justify-start border-t pt-4">
                    <div className="flex flex-row gap-2 items-center text-secondary">
                        <Plus />
                        Checkout faster with saved payment info
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col w-full mt-2">
            <div className="flex flex-row gap-2">
                <div className="w-full flex flex-row items-center">
                    <NumberFormat
                        type="tel"
                        className="qrInput"
                        format="(###) ###-####"
                        mask=" "
                        style={{
                            background: "none",
                            width: "100%",
                            outline: "none",
                            borderWidth: showErrorBorder ? 2 : 1,
                            borderColor: showErrorBorder
                                ? "#FA3A3A"
                                : "#DFE0E2",
                            lineHeight: "24px",
                            padding: "8px",
                            color: SystemColors.sesame,
                            fontSize: "16px",
                            borderRadius: "8px",
                        }}
                        value={phoneNumber}
                        placeholder="Phone Number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        onKeyPress={keyPressed}
                        getInputRef={phoneNumInputRef}
                    />
                </div>
            </div>
            {showErrorBorder && !isValidPhoneNumber ? (
                <span className="pt-2 flex flex-1 items-start text-start text-sm text-alert">
                    Phone number is required
                </span>
            ) : null}
            {!isValidPhoneNumber && phoneNumber.length ? (
                <span className="pt-2 flex flex-1 items-start text-start text-sm text-alert">
                    Invalid Phone Number
                </span>
            ) : null}
            {error && (
                <span className="text-alert text-start text-sm">{error}</span>
            )}
            {showVerification ? null : (
                <Button
                    loading={isSubmitting}
                    label="Continue"
                    onPress={_onPress}
                    variant="brand"
                    className="w-full justify-center items-center px-1 py-1 mt-4 mb-0"
                    loadingIconSize={5}
                />
            )}
        </div>
    );
};

export default PhoneNumber;
