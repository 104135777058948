import { Result } from "neverthrow";
import { makeSubject, pipe, subscribe, take, toPromise } from "wonka";

/**
 * A monitor that can be subscribed to and emit results.
 */
export class TransactionMonitor<Value = void, Err = Error> {
  private subject = makeSubject<Result<Value, Err>>();

  subscribe = (callback: (value: Result<Value, Err>) => void) => {
    const subscription = pipe(this.subject.source, subscribe(callback));

    return () => subscription.unsubscribe();
  };

  emitResult = (result: Result<Value, Err>) => {
    this.subject.next(result);
  };

  then = (callback?: (result: Result<Value, Err>) => void) =>
    pipe(this.subject.source, take(1), toPromise).then(callback);
}
