import API from "@/utils/Api/graphql";
import { sendError } from "@/utils/Errors";
import { useCallback, useMemo, useState } from "react";

/**
 * Provides an API for opting in to marketing consent status.
 */
export function useOptInToMarketing() {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [data, setData] = useState<boolean | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);

    const optInToMarketing = useCallback(
        async (storeId: string, purchaseId?: string, authToken?: string) => {
            setIsLoading(true);
            API.messaging
                .optInToMarketing(storeId, purchaseId, authToken)
                .then((data) => {
                    setData(data);
                })
                .catch((graphQLError) => {
                    console.log(purchaseId, authToken);
                    setError(graphQLError);
                    sendError(graphQLError);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [],
    );

    return useMemo(
        () => ({
            optInToMarketing,
            data,
            isLoading,
            error,
        }),
        [optInToMarketing, data, isLoading, error],
    );
}
