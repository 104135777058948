import React, { CSSProperties } from "react";
import { View } from "./View";
import { Colors } from "src/utils";

type DividerProps = {
    width?: number;
    style?: CSSProperties;
    className?: string;
};

export function Divider(props: DividerProps) {
    return (
        <View
            style={{
                borderWidth: 0,
                borderBottomWidth: `${props.width ? props.width : 1}px`,
                borderStyle: "solid",
                borderColor: Colors.lightGray,
                alignSelf: "stretch",
                ...props.style,
            }}
            className={props.className}
        />
    );
}
