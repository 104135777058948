// BOGO: add rewardIsInCart utility that includes relevant BOGO check

// BOGO: move to accounting and check for promotions with associated reward
import { CampaignPromotion, IReward } from "@snackpass/snackpass-types";
import { ICart } from "../types";

export function isCampaignPromotion(
    r?: CampaignPromotion | IReward | null
): r is CampaignPromotion {
    if (!r) return false;
    if (!r.campaign || typeof r.campaign === "string") return false;
    if (!r.campaign.promotion) return false;
    return !!r.campaign.promotion._id;
}

/**
 *  Checks if a reward is in the cart; also supports campaign promotions
 *
 *  a campaign promotion is a reward that is actually treated as a promotion; but it's consumable
 * */
export const rewardIsInCart = (
    reward: IReward | CampaignPromotion,
    cart: ICart
): boolean => {
    if (!isCampaignPromotion(reward)) {
        // does an item in the cart have the reward associated with it?
        return cart.items.some((item) => {
            return item.reward && item.reward._id === reward._id;
        });
    }

    // does an item in the cart have a promotion that has the same id as the nested promotion in the reward?
    return cart.items.some((item) => {
        return (
            item.promotion &&
            reward.campaign?.promotion._id === item.promotion._id
        );
    });
};
