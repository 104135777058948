import React, { useContext } from "react";
import { WhenTypeEnum, FulfillmentTypeEnum } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { Helpers } from "src/utils";
import { Button } from "@/components/ui/button";
import { useSelector } from "react-redux";
import { CartSelectors, Time } from "@snackpass/accounting";
import { getActiveStore } from "src/redux";
import { ScheduleContext } from "../ScheduleModalContext";

export type ModalFooterProps = {
    handleConfirmOptions: () => Promise<void>;
};

const ModalFooter: React.FC<ModalFooterProps> = ({ handleConfirmOptions }) => {
    const activeStore = useSelector(getActiveStore);
    const cartTableNumber = useSelector(CartSelectors.getCartTableNumber);
    const tableNumber = cartTableNumber;
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const { modalWhen, modalSelectedTime } = useContext(ScheduleContext);

    if (!activeStore) return null;

    const disableConfirmButton =
        (fulfillment === FulfillmentTypeEnum.Delivery &&
            !Time.isOpenForDelivery(
                activeStore,
                modalSelectedTime?.toDate(),
            )) ||
        (modalWhen === WhenTypeEnum.Later && !modalSelectedTime);

    const renderConfirmMessage = (date: Date | null | undefined) => {
        if (
            fulfillment === FulfillmentTypeEnum.Delivery &&
            !Time.isOpenForDelivery(activeStore, modalSelectedTime?.toDate())
        ) {
            return "Delivery Closed";
        }

        if (modalWhen === WhenTypeEnum.Now) {
            if (fulfillment === FulfillmentTypeEnum.DineIn && tableNumber) {
                return `${Helpers.capitalize(
                    fulfillment.replace("_", "-"),
                )} at table #${tableNumber}`;
            }
        }

        if (modalWhen === WhenTypeEnum.Later && date) {
            let time = "";
            const day = moment(date).isSame(moment(), "day")
                ? "today"
                : moment(date).format("ddd, MMM D");
            time = `${day} at ${moment(date).format("h:mm A")}`;

            if (
                fulfillment === FulfillmentTypeEnum.Pickup ||
                fulfillment === FulfillmentTypeEnum.Delivery
            ) {
                return `Scheduled for: ${time}`;
            }
        }
        return "";
    };

    return (
        <div className="w-full">
            <div className="flex flex-1 items-center justify-center py-2">
                {renderConfirmMessage(modalSelectedTime?.toDate())}
            </div>
            <Button
                onPress={handleConfirmOptions}
                label="Confirm"
                className="w-full"
                disabled={disableConfirmButton}
            />
        </div>
    );
};

export default ModalFooter;
