import { IStore } from "@snackpass/snackpass-types";
import React from "react";

import { StoreLabelRow, Text } from "src/SharedComponents";
import { Colors, cn } from "src/utils";
import LocationIcon from "src/assets/icons/location.png";

type StoreListRowProps = {
    store: IStore;
    onClick?: (store?: IStore) => void;
    selectedStore?: IStore | null;
};

export const StoreListRow = ({
    store,
    onClick,
    selectedStore,
}: StoreListRowProps) => {
    const isActive = selectedStore && selectedStore._id === store._id;

    const storeRowContent = (
        <div
            className={cn(
                "mb-[15px] cursor-pointer rounded-xl border p-[12px]",
                {
                    "border-primary": isActive,
                },
            )}
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                onClick && onClick(store);
            }}
        >
            <Text bold style={{ marginBottom: 8 }} color={Colors.black}>
                {store.name}
            </Text>
            <div style={{ marginBottom: 8 }}>
                <p
                    style={{
                        color: Colors.darkGray,
                        fontSize: 12,
                    }}
                >
                    <StoreLabelRow store={store} />
                </p>
            </div>
            <div className="flex flex-row items-center">
                <img
                    src={store.logoUrl || LocationIcon}
                    alt="Location"
                    className="mr-[4px] h-[14px] w-auto text-primary rounded-full"
                />
                <p className="m-0 text-xs text-primary">{store.address}</p>
            </div>
        </div>
    );

    return storeRowContent;
};
