import { getUser } from "./globals";
import { createSelector } from "reselect";

export const getUserCredit = createSelector([getUser], (user) =>
    user && user.credit ? user.credit : 0
);

export const getUserAddress = createSelector([getUser], (user) =>
    user && user.address ? user.address : ""
);
