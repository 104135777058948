import React, { CSSProperties } from "react";
import { CheckBox } from "src/SharedComponents/CheckBox";
import MultipleAddonsButton from "src/modals/ProductModal/components/MultipleAddonsButton";
import { View, TouchableHighlight, Text } from "../../../../SharedComponents";
import { Colors } from "src/utils";

import AddonPrice from "./AddonPrice";
import { Addon, AddonGroup, IStore, IReward } from "@snackpass/snackpass-types";
import { ReduxState, itemIsSoldOut } from "@snackpass/accounting";
import { ActiveCartItemSelectors } from "@snackpass/accounting";
import {
    getAddonIsSelected,
    getAddonQuantity,
} from "@snackpass/accounting/build/src/redux/activeCartItem/selectors";
import { useSelector } from "react-redux";

type Props = {
    addon: Addon;
    addonGroup: AddonGroup;
    activeStore: IStore;
    onToggleAddon: (addon: Addon, addonGroup: AddonGroup) => void;
    onChangeAddonQuantity: (addon: Addon, isIncrement: boolean) => void;
};

const AddonRow = ({
    addon,
    addonGroup,
    onChangeAddonQuantity,
    onToggleAddon,
}: Props) => {
    const isChecked = useSelector((state) =>
        getAddonIsSelected(state as ReduxState, addon),
    );
    const isLimitReached = useSelector((state) =>
        ActiveCartItemSelectors.getAddonGroupLimitIsReached(
            state as ReduxState,
            addonGroup,
        ),
    );
    const quantity = useSelector((state) =>
        getAddonQuantity(state as ReduxState, addon),
    );

    const handleToggle = () => {
        onToggleAddon(addon, addonGroup);
    };

    const handleQuantityChange = (isIncrement: boolean) => () => {
        onChangeAddonQuantity(addon, isIncrement);
    };

    if (!addon.name) {
        return null;
    }

    const isSoldOut = itemIsSoldOut(addon);
    const fade = isLimitReached && (!isChecked || quantity === 0);
    const opacity = fade || isSoldOut ? 0.4 : 1;

    let addonName = addon.name;
    if (isSoldOut) {
        addonName += " (sold out)";
    }

    return addonGroup.supportsMultiple ? (
        <TouchableHighlight
            style={{ opacity, width: "100%" }}
            onPress={handleQuantityChange(true)}
            disabled={quantity > 0}
            className="px-6"
        >
            <View style={styles.addonRowInnerContainer}>
                <View
                    style={{
                        flex: 3,
                        flexDirection: "row",
                        alignSelf: "center",
                    }}
                >
                    <MultipleAddonsButton
                        isIncrement={false}
                        disabled={quantity === 0}
                        onPress={handleQuantityChange(false)}
                        quantity={quantity}
                        isLimitReached={isLimitReached}
                    />
                    {quantity === 0 ? null : (
                        <div className="text-stone-950 text-base font-semibold leading-normal w-7 text-center">
                            {quantity}
                        </div>
                    )}
                    <MultipleAddonsButton
                        isIncrement={true}
                        disabled={isLimitReached || quantity === 0}
                        onPress={handleQuantityChange(true)}
                        quantity={quantity}
                        isLimitReached={isLimitReached}
                    />
                    <Text style={styles.label}>{addonName}</Text>
                </View>
                <View style={styles.priceLabelContainer}>
                    <AddonPrice
                        addon={addon}
                        addonGroup={addonGroup}
                        quantity={quantity}
                    />
                </View>
            </View>
        </TouchableHighlight>
    ) : (
        <TouchableHighlight
            style={{ opacity, width: "100%" }}
            onPress={handleToggle}
            className="px-6"
        >
            <View style={styles.addonRowInnerContainer}>
                <View style={{ flex: 3, alignSelf: "center" }}>
                    <CheckBox label={addonName} isChecked={isChecked} />
                </View>
                <View style={styles.priceLabelContainer}>
                    <AddonPrice
                        addon={addon}
                        addonGroup={addonGroup}
                        quantity={quantity}
                    />
                </View>
            </View>
        </TouchableHighlight>
    );
};

const styles = {
    label: {
        color: Colors.text,
        fontSize: 14,
        lineHeight: "25px",
        marginLeft: 21,
        fontWeight: 500,
        backgroundColor: "rgba(0,0,0,0)",
    } as CSSProperties,
    addonRowInnerContainer: {
        flexDirection: "row",
        height: "50px",
    } as CSSProperties,
    priceLabelContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    } as CSSProperties,
};

export default AddonRow;
