import { map, prop, propOr } from "lodash/fp";
import { createSelector } from "reselect";
import { getDistanceBetweenGeopoints } from "../../utils";
import { getMultiCart } from "../multiCart/selectors";
import { getDeliveryRange } from "./activeStore";
import { getMyLocation } from "./globals";

export const getMultiCartStores = createSelector(
    [getMultiCart],
    map(prop("store"))
);

// get list of store ids for each store in multiCart
export const getMultiCartStoreIds = createSelector(
    [getMultiCartStores],
    map(propOr("", "_id"))
);

// get list of regions for each store in multiCart
export const getMultiCartStoreRegions = createSelector(
    [getMultiCartStores],
    map(propOr("", "region"))
);

// list of distances between user and each store in multicart
export const getDistancesFromAllStores = createSelector(
    [getMultiCartStores, getMyLocation],
    (stores, myLocation) =>
        stores.map((store) =>
            store
                ? getDistanceBetweenGeopoints(store.geolocation, myLocation)
                : 0
        )
);

// get delivery ranges for each store in multicart
export const getAllDeliveryRanges = createSelector(
    [getMultiCartStores, getDistancesFromAllStores, getMyLocation],
    (stores, distances, location) =>
        stores.map((store, i) =>
            store
                ? getDeliveryRange(store, distances[i], location)
                : { deliveryMin: 0, deliveryFee: 0 }
        )
);

// get delivery fee for each store in multicart
export const getAllStoreDeliveryFees = createSelector(
    [getAllDeliveryRanges],
    map(propOr(0, "deliveryFee"))
);

// get delivery min for each store in multicart
export const getAllStoreDeliveryMin = createSelector(
    [getAllDeliveryRanges],
    map(propOr(0, "deliveryMin"))
);

// get pickup min for each store in multicart
export const getAllStorePickupMin = createSelector(
    [getMultiCartStores],
    map((store) => store?.pickupMin || 0)
);

// get list of convenience fees for each store
export const getAllStoreConvenienceFeePolicies = createSelector(
    [getMultiCartStores],
    map(propOr([], "convenienceFeePolicies"))
);

export const getAllStoreFeePolicies = createSelector(
    [getMultiCartStores],
    map(propOr([], "feePolicies"))
);
