import React, { CSSProperties } from "react";

type ViewProps = {
    row?: boolean;
    className?: string;
    backgroundColor?: string;
    onPress?: (e: any) => void;
    id?: string;
    style?: CSSProperties;
};

export const View: React.FC<React.PropsWithChildren<ViewProps>> = (props) => {
    const { row, className, backgroundColor, onPress, id } = props;
    return (
        <div
            id={id}
            className={className}
            onClick={onPress}
            style={{
                display: "flex",
                backgroundColor: backgroundColor || "",
                flexDirection: row ? "row" : "column",
                ...props.style,
            }}
        >
            {props.children}
        </div>
    );
};
