import React, { Component, CSSProperties } from "react";

type TouchableHighlightProps = {
    labelColor?: string;
    style?: CSSProperties;
    children?: any;
    className?: string;
    onPress: (e: any) => void;
    disabled?: boolean;
};

export class TouchableHighlight extends Component<TouchableHighlightProps> {
    render() {
        const { labelColor, onPress, className, disabled } = this.props;
        return (
            <div
                onClick={e => {
                    if (disabled) {
                        return;
                    }
                    onPress(e);
                }}
                className={`touchable-highlight ${className}`}
                style={{
                    color: labelColor || "white",
                    cursor: disabled ? "auto" : "pointer",
                    ...this.props.style
                }}
            >
                {this.props.children}
            </div>
        );
    }
}
