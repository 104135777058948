import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal, ModalsName, getActiveStore } from "@/redux";
import fp from "lodash/fp";
import useURLParams from "@/hooks/useURLParams";
import { IAddress } from "@snackpass/snackpass-types";
import { cn } from "@/utils";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { ReactComponent as LocationIcon } from "src/assets/icons/location.svg";
import { Button } from "@/components/ui/button";

const getStoreAddress = (
    addressComponents: IAddress | undefined,
    address: string,
): { lineOne: string; lineTwo: string } => {
    let lineOne = "";
    let lineTwo = "";
    // addressComponents take priority, some not live store doesn't have addressComponents, we will use address instead
    if (
        addressComponents?.line1 &&
        addressComponents?.city &&
        addressComponents?.state
    ) {
        lineOne = `${addressComponents.line1}${
            addressComponents.line2 ? "," + addressComponents.line2 : ""
        }`;
        lineTwo = `${addressComponents.city}${
            addressComponents.state ? ", " + addressComponents.state : " "
        } ${addressComponents.zip ? "" + addressComponents.zip : ""}`;
    } else {
        const splitStoreAddress = address.split(",");
        lineOne = splitStoreAddress[0];
        lineTwo = `${splitStoreAddress[1] ? splitStoreAddress[1] : ""}${
            splitStoreAddress[2] ? "," + splitStoreAddress[2] : ""
        }${splitStoreAddress[3] ? " " + splitStoreAddress[3] : ""}`;
    }

    return { lineOne, lineTwo };
};

const StoreLocations = ({ cartScreen }: { cartScreen?: boolean }) => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const showStoreLocationsModal = () => {
        dispatch(showModal(ModalsName.storeLocationsModal));
    };
    const params = useURLParams();
    if (!activeStore) {
        return null;
    }
    const { lineOne, lineTwo } = getStoreAddress(
        activeStore.addressComponents,
        activeStore.address,
    );
    const isChain = !fp.isEmpty(activeStore?.chainId);

    const fromCustomApp = params.get("fromCustomApp");
    const shouldShowStoreLocationModal = isChain && !fromCustomApp;
    return (
        <Button
            className={cn("px-0 py-0 w-[100%] h-[100%] rounded-xl", {
                "bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] shadow-default transition-shadow duration-500 ease-in-out hover:shadow-hover rounded-xl active:shadow-focus border active:scale-95":
                    !cartScreen,
                "cursor-default": !shouldShowStoreLocationModal,
                "hover:bg-white": cartScreen,
            })}
            variant="ghost"
            onPress={
                shouldShowStoreLocationModal
                    ? showStoreLocationsModal
                    : () => {}
            }
            label={
                <div
                    className={cn(
                        "flex flex-col md:flex-row flex-1 md:items-center py-3 md:py-0 md:justify-between rounded-xl ",
                        {
                            "pl-3 pr-2 md:pr-4 h-[94px] md:h-[64px]":
                                !cartScreen,
                            "px-4 h-[78px]": cartScreen,
                        },
                    )}
                >
                    {cartScreen ? null : (
                        <div className="basis-1/8 pb-2 md:pb-0">
                            <LocationIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                        </div>
                    )}
                    <div
                        className={cn("basis-6/8 w-full text-start", {
                            "px-0 md:px-3": !cartScreen,
                            "space-y-1": cartScreen,
                        })}
                    >
                        <div
                            className={cn(
                                "text-base text-primary font-semibold md:line-clamp-1 line-clamp-2 md:text-ellipsis",
                                {
                                    "text-secondary font-normal": !isChain,
                                },
                            )}
                        >
                            {lineOne}
                        </div>
                        <div
                            className={cn(
                                "line-clamp-1 text-start overflow-hidden text-sm font-normal leading-4 text-secondary",
                                {
                                    "hidden md:block": !cartScreen,
                                },
                            )}
                        >
                            {lineTwo}
                        </div>
                    </div>
                    {shouldShowStoreLocationModal && (
                        <div className="basis-1/8 hidden md:block">
                            <ChevronRightIcon className="w-4 h-4 text-[#999DA3] block lg:hidden" />
                            <div className="hidden lg:block border rounded-md border-[#DFE0E2] p-2">
                                Location
                            </div>
                        </div>
                    )}
                </div>
            }
        />
    );
};

export default StoreLocations;
