import React from "react";

const Marketplace = () => {
    return (
        <div className="w-full overflow-scroll h-screen">
            <iframe
                className="flex h-full w-full overflow-scroll"
                src="https://www.snackpass.co"
                title="Snackpass"
            />
        </div>
    );
};

export default Marketplace;
