import { useEffect } from "react";
import { CartSelectors } from "@snackpass/accounting";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux";
import { hideModal, ModalsName } from "@/redux";
import ViewOrderContent from "./ViewOrderContent";
import ViewOrderButton from "@/screens/StoreScreen/components/ViewOrderButton";
import {
    Sheet,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const ViewOrdersModal = () => {
    const dispatch = useDispatch();
    const viewOrderModal = useSelector(actions.getIsViewOrderModalOpen);
    const items = useSelector(CartSelectors.getCartItems);
    const handleHideViewOrderModal = () => {
        dispatch(hideModal(ModalsName.viewOrderModal));
    };

    useEffect(() => {
        if (items.length < 1) {
            handleHideViewOrderModal();
        }
    }, [items.length]);

    return (
        <Sheet open={viewOrderModal} onOpenChange={handleHideViewOrderModal}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Your Order</SheetTitle>
                </SheetHeader>
                <div className="overflow-auto pb-12">
                    <ViewOrderContent />
                </div>
                <SheetFooter className="border-t">
                    <ViewOrderButton isGotoCartButton />
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default ViewOrdersModal;
