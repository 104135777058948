import React, { useEffect } from "react";

export const DownloadApplePayDocument = () => {
    useEffect(() => {
        const downloadFile = () => {
            const link = document.createElement("a");
            link.href =
                "/.well-known/apple-developer-merchantid-domain-association";
            link.setAttribute(
                "download",
                "apple-developer-merchantid-domain-association",
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        downloadFile();
    }, []);

    return null;
};
