import React from "react";

import { hasLocationSet } from "@/redux";
import { useSelector } from "react-redux";
import { Button } from "../ui/button-2";
import { LoaderIcon, NavigationIcon } from "lucide-react";
import { useGetGeolocation } from "@/hooks/useGetLocation";

export const MyLocation: React.FC = () => {
    const locationSet = useSelector(hasLocationSet);
    const { requestLocation, loading } = useGetGeolocation();

    if (!locationSet) {
        return (
            <Button
                variant={"outline"}
                onClick={requestLocation}
                className="my-4 mx-4 py-6"
            >
                {loading ? "" : "Sort Near Me"}
                {loading ? (
                    <LoaderIcon className="animate-spin" />
                ) : (
                    <NavigationIcon className="ml-2" />
                )}
            </Button>
        );
    }

    return null;
};
