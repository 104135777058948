import { version } from "../utils/version";

const config = {
    isProductionEnv: process.env.REACT_APP_ENV === "production",
    isNotProductionEnv: process.env.REACT_APP_ENV !== "production",
    baseUrl:
        process.env.REACT_APP_API_URL ||
        "https://staging-api.snackpass.co/api/v4",
    baseHost:
        process.env.REACT_APP_API_HOST || "https://staging-api.snackpass.co",
    gqlUrl:
        process.env.REACT_APP_GQL_URL ||
        "https://staging-api.snackpass.co/graphql",
    menuGqlUrl:
        process.env.REACT_APP_MENU_GQL_URL ||
        "https://staging-graph.snackpass.co/graphql",
    menuBucketUrl:
        process.env.REACT_APP_MENU_BUCKET_URL ||
        "https://pub-1beb685e4630468ca9a27a04aa32e657.r2.dev",
    /** @deprecated: use `baseHost` instead. */
    receiptsApiEndpoint:
        process.env.REACT_APP_RECEIPT_API_ENDPOINT ||
        "https://staging-api.snackpass.co",
    env: process.env.REACT_APP_ENV || "local",
    IS_DEV: ["development", "local"].includes(process.env.REACT_APP_ENV ?? ""),
    IS_LOCAL: process.env.REACT_APP_ENV === "local",
    sentryDsn: process.env.REACT_APP_SENTRY_DSN,
    sentryRelease: `${process.env.REACT_APP_SENTRY_RELEASE_PREFIX}${process.env.REACT_APP_SENTRY_RELEASE}`,
    PRODUCTION_SEGMENT_API_KEY: "q9v9uFkBR4tcWmwRmFYpYhR3G1GX0A6E",
    DEVELOPMENT_SEGMENT_API_KEY: "F13Ug9uEXKf1qdFyuFBM2AsrW6O13lKz",
    STATSIG_ENVIRONMENT: process.env.REACT_APP_STATSIG_ENVIRONMENT,
    STATSIG_KEY: process.env.REACT_APP_STATSIG_KEY,
    SIFT_BEACON_KEY: process.env.REACT_APP_SIFT_BEACON_KEY || "",
    item_fulfillments: true,
    featureGates: {
        enableOODealAndDiscount: "enable_oo_deal_and_discount",
        enableSnackpassTime: "enable_snackpass_time_on_oo",
    },
    experiments: {
        kioskToSmartReceiptExperiment: "kiosk_to_smart_receipt_experiment",
        kioskToSmartReceiptABCExperiment:
            "kiosk_to_smart_receipt_abc_experiment",
    },
    version,
};

export default config;
