import React, { useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { searchForItem } from "src/redux";
import { useDispatch } from "react-redux";
import { Constants } from "src/utils/Constants";
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg";

export const ItemSearchBar = (props) => {
    const [searchParameter, setSearchParameter] = React.useState(
        props.itemSearchParameter,
    );
    const searchBarRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    function keyPressed(e) {
        if (e.charCode === Constants.enterKey) {
            enterPressed(true);
        }
    }

    function onInputChange(e) {
        setSearchParameter(e.target.value);
    }

    function enterPressed(shouldBlur) {
        if (
            shouldBlur &&
            searchBarRef &&
            window.innerWidth < Constants.LARGE_TABLET_BREAKPOINT
        ) {
            searchBarRef.current?.blur();
        }
        dispatch(searchForItem(searchParameter));
    }

    useEffect(() => {
        enterPressed(false);
        if (isMobile) {
            window.scrollTo(0, 0);
        } else {
            const productsScrollContainer = document.getElementById(
                "productsScrollContainer",
            );
            if (productsScrollContainer) {
                productsScrollContainer.scrollTo(0, 0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParameter]);

    return (
        <div className="border border-border rounded-xl flex w-full md:max-w-[360px] p-1">
            <SearchIcon className="my-auto w-[18px] h-[18px] mx-2" />
            <input
                className="font-normal flex-grow border-none outline-none rounded-xl h-[40px] w-[100%]"
                id="itemSearchBar"
                value={searchParameter}
                onChange={onInputChange}
                onKeyPress={keyPressed}
                type="text"
                placeholder="Search Menu"
                autoCorrect="off"
                autoComplete="off"
                autoFocus={true}
                ref={searchBarRef}
            />
        </div>
    );
};
