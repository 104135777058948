import React from "react";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import {
    hideModal,
    ModalsName,
    getIsRemoveDealModalOpen,
    getDealGroupForDelete,
    setDealGroupForDelete,
} from "@/redux";
import { Button } from "@/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { CartActions } from "@snackpass/accounting";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const RemoveDealModal = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const dispatch = useDispatch();
    const removeDealModal = useSelector(getIsRemoveDealModalOpen);
    const dealGroupForDelete = useSelector(getDealGroupForDelete);
    const handleRemove = () => {
        if (dealGroupForDelete.dealItemId) {
            dispatch(
                CartActions.removeSingleDealItem(
                    dealGroupForDelete.dealGroupIndex,
                    dealGroupForDelete.dealItemId,
                ),
            );
        }

        onHide();
    };
    const onCancel = () => {
        onHide();
    };

    const onHide = () => {
        dispatch(hideModal(ModalsName.removeDealModal));
        dispatch(setDealGroupForDelete(null));
    };
    if (isDesktop) {
        return (
            <Dialog open={removeDealModal} onOpenChange={onHide}>
                <DialogContent onEscapeKeyDown={onHide} className="z-[100]">
                    <div className="flex flex-col overflow-y-auto h-full relative w-full hidden-scroll">
                        <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto flex flex-col">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    Remove Deal?
                                </div>
                            </div>
                        </div>
                        <div className="mx-6 my-10 text-base">
                            {`Removing this item will also remove the deal: ${dealGroupForDelete?.name}`}
                        </div>
                        <DialogFooter className="bg-white border-t p-6 md:px-6">
                            <div className="w-full">
                                <div className="flex flex-1 items-center justify-center"></div>
                                <div className="flex flex-row justify-between gap-4">
                                    <Button
                                        className="w-full"
                                        label={"Cancel"}
                                        onPress={onCancel}
                                        variant="outline"
                                    />
                                    <Button
                                        className="w-full"
                                        label={"Remove"}
                                        onPress={() => {
                                            handleRemove();
                                        }}
                                    />
                                </div>
                            </div>
                        </DialogFooter>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={removeDealModal} onOpenChange={onHide}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle> Remove Deal?</SheetTitle>
                </SheetHeader>
                <SheetDescription className="pt-6 pb-16 px-6">
                    {`Removing this item will also remove the deal: ${dealGroupForDelete?.name}`}
                </SheetDescription>
                <SheetFooter className="py-4 px-6 border-t">
                    <SheetClose asChild>
                        <div className="w-full">
                            <div className="flex flex-row justify-between gap-4">
                                <Button
                                    className="w-full"
                                    label={"Cancel"}
                                    onPress={onCancel}
                                    variant="outline"
                                />
                                <Button
                                    className="w-full"
                                    label={"Remove"}
                                    onPress={() => {
                                        handleRemove();
                                    }}
                                />
                            </div>
                        </div>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default RemoveDealModal;
