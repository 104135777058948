import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

import * as Colors from "./Colors";
import { Constants } from "./Constants";
import * as Helpers from "./Helpers";
import * as Styles from "./Styles";
import * as Segment from "./Segment";

export { default as Api } from "./Api/REST";
export * from "./Store";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}
type StoreNameParts = {
    name: string;
    address: string;
};

export function parseStoreName(storeName: string | undefined): StoreNameParts {
    if (!storeName) {
        return { name: "", address: "" };
    }
    const matched = storeName.match(/(.*?)(?:\((.*?)\))?$/);

    if (!matched) {
        return { name: storeName, address: "" };
    }

    const [, name, address] = matched;

    return {
        name: name.trim(),
        address: address ? address.trim() : "",
    };
}
export { Colors, Constants, Helpers, Styles, Segment };
