import React, { Component } from "react";
import { IProduct } from "@snackpass/snackpass-types";

type ProductDescriptionProps = {
    activeProduct: IProduct;
};

export default class ProductDescription extends Component<ProductDescriptionProps> {
    render = () => {
        const { activeProduct } = this.props;

        if (!activeProduct.description) {
            return null;
        }
        return (
            <div className="pb-3 border-white flex-col justify-start items-start gap-4 inline-flex">
                <div className="text-zinc-600 text-sm font-normal leading-normal">
                    {activeProduct.description}
                </div>
            </div>
        );
    };
}
