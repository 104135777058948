import React from "react";
import { IProduct } from "@snackpass/snackpass-types";
import { CartAddon, itemIsSoldOut } from "@snackpass/accounting";
import {
    useActiveRewardPromo,
    useActiveReward,
} from "@snackpass/loyalty-react";

import { Button } from "@/components/ui/button";
import { Helpers, StoreUtils } from "../../../utils";
import { useSelector } from "react-redux";
import { getActivePromotion } from "@/redux";
import { getDealSelection } from "@/redux/deal";
import { useGetProductPrice } from "@/hooks/useGetProductPrice";
import { getActivePromotion as getActiveCartItemPromotion } from "@snackpass/accounting/build/src/redux/activeCartItem/selectors";

type Props = {
    quantity: number;
    weight: number;
    activeProduct: IProduct;
    selectedAddons: CartAddon[] | null;
    handleAddItemToCart: () => void;
    editCartItem: boolean;
};

const getAddToCartButtonTitle = (
    isSoldOut: boolean,
    isDiscounted: boolean,
    total: number,
    totalAfterDiscount: number,
    editCartItem: boolean,
    isDealItem: boolean,
) => {
    if (isSoldOut) {
        return <span>Sold out</span>;
    }

    const title = isDealItem
        ? "Add to deal"
        : editCartItem
          ? "Update Item"
          : "Add to Order";

    if (isDiscounted) {
        return (
            <div className="flex flex-row gap-1">
                <span>{`${title} ${Helpers.toDollar(
                    totalAfterDiscount,
                )}`}</span>
                <span className="line-through ">{`${Helpers.toDollar(
                    total,
                )}`}</span>
            </div>
        );
    }
    return <div>{`${title} ${Helpers.toDollar(total)}`}</div>;
};

function AddToCartButton({
    handleAddItemToCart,
    activeProduct,
    selectedAddons,
    quantity,
    weight,
    editCartItem,
}: Props) {
    const activeRewardPromo = useActiveRewardPromo();
    const activeReward = useActiveReward();
    const activePromotion = useSelector(getActivePromotion);

    // activeCartItemPromotion: when user trying to update a cart item that might have promotion applied
    const activeCartItemPromotion = useSelector(getActiveCartItemPromotion);
    const activeDealItem = useSelector(getDealSelection).activeDealItem;

    const { basePriceAfterDiscount, isDiscounted } = useGetProductPrice(
        activeProduct,
        activeReward,
        activeRewardPromo || activePromotion || activeCartItemPromotion,
        activeDealItem,
    );
    const isSoldOut = itemIsSoldOut(activeProduct);
    const { total, totalAfterDiscount } = StoreUtils.calculateItemTotal(
        activeRewardPromo || activePromotion || activeCartItemPromotion,
        activeReward,
        activeProduct?.priceByWeight?.perUnit ?? activeProduct.price,
        basePriceAfterDiscount,
        selectedAddons,
        quantity,
        weight,
        isDiscounted,
    );
    const buttonTitle = getAddToCartButtonTitle(
        isSoldOut,
        isDiscounted,
        total,
        totalAfterDiscount,
        editCartItem,
        activeDealItem != null,
    );

    return (
        <div className="bg-white w-full">
            <Button
                onPress={handleAddItemToCart}
                disabled={isSoldOut}
                label={buttonTitle}
                variant="brand"
                className="w-full"
            />
        </div>
    );
}

export default AddToCartButton;
