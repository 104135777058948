import React from "react";
import { CSSProperties } from "styled-components";

type Props = {
    placeholder?: string;
    value: string;
    handleChangeText: (value: string) => void;
    style?: CSSProperties;
    rows?: number;
};

export const TextArea: React.FC<Props> = ({
    placeholder,
    value,
    handleChangeText,
    style,
    rows,
}) => {
    return (
        <textarea
            className="ring-1 rounded-lg ring-border"
            rows={rows}
            placeholder={placeholder}
            style={{
                ...style,
                resize: "vertical",
                color: "#5d5d5d",
            }}
            value={value}
            onChange={(e) => {
                handleChangeText(e.target.value);
            }}
        />
    );
};
