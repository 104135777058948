import React, { useContext } from "react";
import DeliveryAddress from "./DeliveryAddress";
import TableNumber from "./TableNumber";
import { FulfillmentModalContext } from "../FulfillmentModalContext";

const ModalActivePane = () => {
    const { modalFulfillment } = useContext(FulfillmentModalContext);
    switch (modalFulfillment) {
        case "DELIVERY":
            return (
                <div className="mx-6 pb-4">
                    <DeliveryAddress />
                </div>
            );
        case "DINE_IN":
            return (
                <div className="mx-6">
                    <TableNumber />
                </div>
            );
        default:
            return null;
    }
};

export default ModalActivePane;
