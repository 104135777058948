export const Constants = {
    version: "online-ordering@1.1.0",
    defaultTaxRate: 0,
    // The default time before scheduledDate that we send a scheduled order to the POS.
    defaultPickupTimeOffsetMinutes: 15,
    numberOfFeatures: 15,
    defaultPercentCateringTip: 15,
    enterKey: 13,
    TABLET_BREAKPOINT: 768,
    MOBILE_BREAKPOINT: 450,
    LARGE_TABLET_BREAKPOINT: 1050,
    defaultDeliveryTip: 0.15,
    defaultPickupTip: 0.15,
    defaultDineInTip: 0.15,
    stripeChargeMin: 0.5,
};

export const ASHLEY_STORE_ID = "5edea699afad1f00d06794ca";

// These are stores that complained to us about Snackpass
// app upsell, so we do not show any upsell content for
// these stores. Might want to make this a field in store
// model in the future if a lot of stores are against it,
// but ideally they shouldn't be.
export const UPSELL_BLACKLIST = [
    "5f19fb37a5cda800bd894e20", // Ole Ole Burrito Express (Berkeley)
    "5e8ceb375b9c8c00bbce57b2", // Fuel (Center City)
    "5e8ceb03b9257900d6068848", // Fuel (South Philly)
    "5d5dc9b149bd7b008b3ec7ad", // Fuel (University City)
    "622aa2a975e38c01809bf8b2", // Fuel (Northeast Philly)
    "5fb6c789bd385b00da1ae7bc", // First Bite (Ann Arbor)
];
