import React from "react";
import { BeatLoader } from "react-spinners";
import { CSSProperties } from "styled-components";

import { GiftCardContent } from "./GiftCardContent";
import { GiftCardHeader } from "./GiftCardHeader";

import { computeHexColorTint } from "../../utils/Helpers";
import { GiftCardDesign } from "src/screens/GiftCardScreen/lib";

const SMALL = "small";

type Props = {
    loading?: boolean;
    size?: "large" | "small";
    storeName: string;
    color: string;
    type?: GiftCardDesign;
    qty?: number;
};

export const GiftCard = ({
    loading,
    size,
    storeName = "Store Name",
    color = "#FF8880",
    type,
    qty
}: Props) => {
    const tint = computeHexColorTint(color, 0.33);

    if (loading)
        return (
            <div style={styles.loadingBody}>
                <BeatLoader />
            </div>
        );

    return (
        <div
            style={{
                ...styles.body,
                background: `url(.png), radial-gradient(80% 119.76% at -7.03% -9.75%, ${tint} 0%, ${color} 100%)`,
                transform: `scale(${size === SMALL ? 0.75 : 1},${
                    size === SMALL ? 0.75 : 1
                })`
            }}
        >
            <GiftCardHeader storeName={storeName} color={color} />
            <GiftCardContent type={type} qty={qty} />
        </div>
    );
};

const styles = {
    body: {
        width: "342px",
        height: "200px",
        backgroundBlendMode: "overlay, normal",
        boxShadow:
            "0px 12px 24px -12px rgba(0, 0, 0, 0.06), 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), inset 0px -2px 0px rgba(0, 0, 0, 0.16)",
        borderRadius: "24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    } as CSSProperties,
    loadingBody: {
        width: "342px",
        height: "227px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    } as CSSProperties
};
