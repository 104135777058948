import React, { useCallback, useEffect, useState } from "react";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { PaymentRequestTokenEvent } from "@stripe/stripe-js";
import { CheckoutSelectors } from "@snackpass/accounting";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { useOptions, usePaymentRequest } from "@/hooks/usePaymentRequest";

type Props = {
    showButtonOnly?: boolean;
    onPress: (e: PaymentRequestTokenEvent) => void;
    isLoading: boolean;
};

const PaymentRequestButton = ({
    showButtonOnly,
    onPress,
    isLoading,
}: Props) => {
    const total = useSelector(CheckoutSelectors.getAmountPaidByCustomer);
    const onSuccess = useCallback(
        async (e: PaymentRequestTokenEvent) => {
            await onPress(e);
        },
        [onPress],
    );
    const [isPaymentRequestInFlight, setIsPaymentRequestInFlight] =
        useState(false);

    const paymentRequest = usePaymentRequest({
        options: {
            country: "US",
            currency: "usd",
            total: {
                label: "Total",
                amount: Math.ceil(total * 100),
            },
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true,
        },
        onSuccess: onSuccess,
    });
    const options = useOptions(paymentRequest);

    useEffect(() => {
        if (paymentRequest) {
            paymentRequest.on("cancel", async () => {
                setIsPaymentRequestInFlight(false);
            });
        }
        return () => {
            if (paymentRequest) {
                paymentRequest.off("cancel");
            }
        };
    }, [paymentRequest]);

    if (isLoading) {
        return (
            <div className="w-[100%] flex flex-row mb-2 items-center justify-center text-center">
                <BeatLoader size={8} />
            </div>
        );
    }

    if (!paymentRequest) {
        return null;
    }

    if (paymentRequest) {
        return (
            <div className="w-[100%]">
                <div className="relative">
                    {isPaymentRequestInFlight && (
                        <div className="absolute left-1/2 top-1/2 translate-y-[-50%] translate-x-[-50%]">
                            <BeatLoader size={8} />
                        </div>
                    )}
                    <PaymentRequestButtonElement
                        onClick={async (event) => {
                            if (isPaymentRequestInFlight) {
                                return event.preventDefault(); // prevent multiple clicks if already in flight
                            }
                            setIsPaymentRequestInFlight(true);
                            paymentRequest.update({
                                total: {
                                    label: "Total",
                                    amount: Math.ceil(total * 100),
                                },
                            });
                        }}
                        options={options}
                        className={isPaymentRequestInFlight ? "opacity-10" : ""}
                    />
                </div>
                {showButtonOnly ? null : (
                    <div className="flex flex-row flex-1 my-4 justify-stretch items-center">
                        <hr className="flex flex-1" />
                        <span className="px-4 text-secondary text-base justify-center">
                            OR
                        </span>
                        <hr className="flex flex-1" />
                    </div>
                )}
            </div>
        );
    }
    return null;
};

export default PaymentRequestButton;
