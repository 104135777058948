import { configureStore } from "@reduxjs/toolkit";
import {
    Accounting,
    CartItem,
    CartItemWithTaxInfo,
    ICart,
    cartInitialState,
} from "@snackpass/accounting";
import { WhenTypeEnum } from "@snackpass/snackpass-types";
import expireReducer from "redux-persist-expire";
import localforage from "localforage";
import { createMigrate, createTransform, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { sagaEnhancer, sagaMiddleware } from "./middlewares";
import { rootReducer } from "./reducers";

const EXPIRATION_TIME = 24 * 3600; // 24 hours in seconds

const replacer = (key: string, value: unknown) =>
    key === "scheduledDate" && value instanceof Date
        ? value.toISOString()
        : value;

const reviver = (key: string, value: unknown) =>
    key === "scheduledDate" && typeof value === "string"
        ? new Date(value)
        : value;

const dateTransform = createTransform(
    (toDehydrate) => JSON.stringify(toDehydrate, replacer),
    (toRehydrate) => JSON.parse(toRehydrate, reviver),
);
const persistedReducer = persistReducer(
    {
        key: "root",
        version: 0,
        storage: localforage,
        stateReconciler: autoMergeLevel2,
        whitelist: ["cart", "activeCart", "user", "myLocation", "utmTracking"],
        transforms: [
            expireReducer("cart", {
                expireSeconds: EXPIRATION_TIME,
                expiredState: cartInitialState,
                autoExpire: true,
            }),
            dateTransform,
        ],
        migrate: createMigrate(
            {
                0: (state: any) => {
                    return {
                        ...state,
                        cart: transformToCartItemsWithTaxInfo(state.cart),
                    };
                },
            },
            { debug: true },
        ),
    },
    rootReducer as any,
);

/**
 * Application Redux store.
 */
export const STORE = {
    ...configureStore({
        // XXX: `persistedReducer` doesn't play nicely with TypeScript.
        // We wish for the inferred type of our reducer to mirror `rootReducer`.
        reducer: persistedReducer as unknown as typeof rootReducer,

        // Disables immutability and serializability checks for dev performance.
        // Unfortunately, our Redux store is quite large: about 229kb of formatted JSON for just Hogwarts (~7,800 lines).
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            }).prepend(sagaMiddleware),

        enhancers: (getDefaultEnhancers) =>
            getDefaultEnhancers().concat(sagaEnhancer),
    }),
    isInitialized: false,
};

/**
 * Typed version of Redux' `dispatch`.
 */
export type AppDispatch = typeof STORE.dispatch;

/**
 * Shape of application state that we store in Redux.
 */
export type AppState = ReturnType<typeof STORE.getState>;

const transformToCartItemsWithTaxInfo = (cart: ICart): ICart => {
    const mapFunc = (cartItem: CartItem): CartItemWithTaxInfo => {
        const rate = cartItem.product?.taxInfo
            ? cartItem.product.taxInfo.rate
            : cart.store?.taxRate || 0;
        const { totalPriceAfterDiscount } =
            Accounting.calcCartItemPrices(cartItem);
        return {
            ...cartItem,
            taxInfo: { rate },
            amountAfterDiscountInDollars: totalPriceAfterDiscount,
            taxPolicies: cartItem.product.taxPolicies || [],
        };
    };

    return {
        ...cart,
        items: cart.items.map(mapFunc),
        when: cart.isCatering ? WhenTypeEnum.Later : WhenTypeEnum.Now,
    };
};
