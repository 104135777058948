import { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import {
    boundMapToAllStores,
    getBoundToAllStores,
    setActiveStoreOnMap,
} from "@/redux/corporateMap";
import { getActiveStore, updateMyLocation } from "@/redux";
import { useChainStores } from "./useChainStores";
import { isOpenLocal } from "@/utils/Time";
import ClosedIconMap from "src/assets/icons/closed_icon_map.png";
import DefaultIcon from "src/assets/icons/map_default_icon.png";

mapboxgl.accessToken = process.env.REACT_APP_PUBLIC_MAPBOX_ACCESS_TOKEN;
export const MapComponent = () => {
    const stores = useChainStores();
    const dispatch = useDispatch();
    const mapBound = useSelector(getBoundToAllStores);
    const activeStore = useSelector(getActiveStore);
    const mapRef = useRef<mapboxgl.Map | null>(null);

    // used when user clicks on "Open List" button
    useEffect(() => {
        if (mapBound) {
            const bounds = new mapboxgl.LngLatBounds();
            stores.forEach((store) => {
                bounds.extend([
                    store.geolocation.coordinates[0],
                    store.geolocation.coordinates[1],
                ]);
            });
            mapRef.current?.fitBounds(bounds, { padding: 100, maxZoom: 15 });
            dispatch(boundMapToAllStores(false));
        }
    }, [mapBound, stores.length]);

    useEffect(() => {
        const firstStore = stores[0];
        if (!firstStore || !activeStore) return;

        const map = new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [
                firstStore.geolocation.coordinates[0],
                firstStore.geolocation.coordinates[1],
            ],
            zoom: 8,
        });
        mapRef.current = map;

        const geoJson = {
            type: "FeatureCollection",
            features: stores.map((store) => ({
                type: "Feature",
                properties: {
                    description: store.name,
                    storeId: store._id,
                    isOpen: isOpenLocal(store.hours),
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        store.geolocation.coordinates[0],
                        store.geolocation.coordinates[1],
                    ],
                },
            })),
        };

        map.on("load", () => {
            map.loadImage(
                activeStore.mapIcon || DefaultIcon,
                (error, image) => {
                    if (error) throw error;
                    map.addImage("custom-marker", image);
                    map.loadImage(ClosedIconMap, (error, moonImage) => {
                        if (error) throw error;
                        map.addImage("moon-marker", moonImage);

                        map.addSource("stores", {
                            type: "geojson",
                            data: geoJson,
                        });

                        map.addLayer({
                            id: "markers",
                            type: "symbol",
                            source: "stores",
                            layout: {
                                "icon-image": "custom-marker",
                                "icon-size": 0.15,
                                "icon-allow-overlap": true, // Allow icons to overlap
                            },
                        });

                        map.addLayer({
                            id: "closed-markers",
                            type: "symbol",
                            source: "stores",
                            layout: {
                                "icon-image": "moon-marker",
                                "icon-size": 0.15,
                                "icon-allow-overlap": true, // Allow icons to overlap
                            },
                            filter: ["==", ["get", "isOpen"], false], // Filter for closed stores
                        });

                        const bounds = new mapboxgl.LngLatBounds();
                        stores.forEach((store) => {
                            bounds.extend([
                                store.geolocation.coordinates[0],
                                store.geolocation.coordinates[1],
                            ]);
                        });
                        map.fitBounds(bounds, { padding: 100, maxZoom: 15 });

                        map.on("click", "markers", (e) => {
                            const coordinates =
                                e.features[0].geometry.coordinates.slice();
                            map.flyTo({
                                center: coordinates,
                            });
                            // Update the icon size of the active store
                            map.setLayoutProperty("markers", "icon-size", [
                                "match",
                                ["get", "storeId"],
                                e.features[0].properties.storeId,
                                0.25, // Larger size for active store
                                0.15, // Default size
                            ]);

                            dispatch(
                                setActiveStoreOnMap(
                                    e.features[0].properties.storeId,
                                ),
                            );
                        });

                        const geolocateControl = new mapboxgl.GeolocateControl({
                            trackUserLocation: true,
                            showUserLocation: true,
                            showUserHeading: true,
                        });

                        map.on("mouseenter", "markers", () => {
                            map.getCanvas().style.cursor = "pointer";
                        });
                        map.on("mouseleave", "markers", () => {
                            map.getCanvas().style.cursor = "";
                        });

                        map.addControl(geolocateControl, "top-right");
                        geolocateControl.on("geolocate", (e) => {
                            dispatch(
                                updateMyLocation({
                                    type: "Point",
                                    coordinates: [
                                        e.coords.longitude,
                                        e.coords.latitude,
                                    ],
                                }),
                            );
                        });
                    });
                },
            );
        });

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, [stores, activeStore]);

    return (
        <div
            id={"map"}
            className="fixed"
            style={{ width: "100vw", height: "100vh" }}
        />
    );
};
