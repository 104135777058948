import fp from "lodash/fp";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Constants } from "src/utils/Constants";
import { sleepMs } from "src/utils/Sleep";
import { ReactComponent as SnackpassLogo } from "src/assets/logos/black-logo.svg";
import { ReactComponent as LockLogo } from "src/assets/icons/lock.svg";
import { onSubmitPhoneNumber, onSubmitVerificationCode } from "./lib";
import { Button } from "../ui/button";
import { useSelector } from "react-redux";
import { getUser } from "@/redux";
import { Divider } from "@/SharedComponents";
import { X } from "lucide-react";
import { BeatLoader } from "react-spinners";
import { Colors } from "src/utils";
import { IUser } from "@snackpass/snackpass-types";
import { toast } from "sonner";

const Verification = ({
    phoneNumber,
    onSuccess,
    authModal,
}: {
    phoneNumber: string;
    onSuccess: (user: IUser) => void;
    authModal?: boolean;
}) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [code, setCode] = useState("");
    const [isResending, setResending] = useState(false);
    const codeInputRef = useRef<HTMLInputElement>(null);
    const user = useSelector(getUser);

    const _onResend = useCallback(async () => {
        if (isResending) {
            return;
        }
        try {
            setResending(true);
            setError(null);
            await onSubmitPhoneNumber(phoneNumber);
            toast.info("Verification code resent.");
        } catch (err) {
            toast.error("Failed to resend code.");
        } finally {
            // sleep a bit to prevent people from spamming the button
            await sleepMs(2000);
            setResending(false);
        }
    }, [phoneNumber, isResending]);

    const _onConfirmCode = useCallback(async () => {
        if (isSubmitting) {
            return;
        }
        try {
            setSubmitting(true);
            const user = await onSubmitVerificationCode(phoneNumber, code);
            onSuccess(user);
            toast.info("Welcome! You've successfully logged in.");
        } catch (err) {
            const errorMessage =
                fp.get("response.data.message", err) || fp.get("message", err);

            if (errorMessage && errorMessage.includes("IDBDatabase")) {
                setError(
                    `Sorry, we are having a temporary issue. Please refresh this page to continue with your purchase!`,
                );

                setTimeout(() => {
                    window.location.reload();
                }, 5000);

                return;
            }

            setError(`Could not verify: ${errorMessage}`);
            toast.error(`Could not verify: ${errorMessage}`);
        } finally {
            setSubmitting(false);
        }
    }, [phoneNumber, code, isSubmitting]);

    useEffect(() => {
        if (!authModal && code.length === 4) {
            _onConfirmCode();
        }
        if (code.length < 4) {
            setError(null);
        }
    }, [code]);

    const keyPressed = (e) => {
        if (codeInputRef && e.charCode === Constants.enterKey) {
            codeInputRef.current?.blur();
        }
    };

    const isExistingUser = user?.name && user?.firstName;
    const title = `Welcome back ${user?.firstName || user?.name}`;
    const defaultDescription = "Enter the code sent to";
    const description =
        authModal || !isExistingUser
            ? `${phoneNumber}`
            : `${phoneNumber} to checkout with your saved payment info.`;

    if (authModal) {
        return (
            <>
                <div className="w-[100%] flex flex-col items-center justify-center">
                    <div className="leading-6 text-center pb-6">
                        {defaultDescription}
                        <div>{description}</div>
                    </div>
                    <div className="text-primary w-[100%] flex flex-row relative">
                        <input
                            value={code}
                            onChange={(e) => {
                                setCode(e.target.value);
                            }}
                            onKeyPress={keyPressed}
                            placeholder="Enter your code"
                            maxLength={4}
                            className="text-base text-primary border rounded-md w-full h-[40px] pl-2 leading-6"
                            ref={codeInputRef}
                        />

                        {isSubmitting && (
                            <div className="flex items-center absolute top-[25%] px-1 right-1">
                                <BeatLoader color={Colors.black} size={7} />
                            </div>
                        )}
                    </div>
                    <Button
                        loading={isSubmitting}
                        label="Continue"
                        onPress={_onConfirmCode}
                        variant="brand"
                        className="w-full justify-center items-center px-1 py-1 mt-4 mb-6"
                        loadingIconSize={5}
                        disabled={!!error}
                    />
                    {error && (
                        <span className="pt-[2px] pb-6 flex flex-1 items-start text-start text-sm text-alert">
                            {error}
                        </span>
                    )}
                </div>
                <div className="items-center justify-start py-1.5 px-1.5 rounded-lg w-fit cursor-pointer hover:bg-tertiary">
                    <div
                        className="text-secondary font-semibold"
                        onClick={_onResend}
                    >
                        Resend Code
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="w-full shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-lg border mt-6">
            <div className="w-[100%] flex flex-col gap-2 p-3">
                {isExistingUser && (
                    <div>
                        <div className="flex flex-row justify-between">
                            <span className="text-base font-semibold text-primary leading-6 py-1">
                                {title}
                            </span>
                            <X strokeWidth={1} className="h-3 w-3" />
                        </div>
                        <Divider width={1} />
                    </div>
                )}
                <div className="text-base text-primary font-normal leading-6 py-1">
                    {`${defaultDescription} ${description}`}
                </div>
                <div className="text-primary w-[100%] flex flex-row gap-2 relative">
                    <input
                        value={code}
                        onChange={(e) => {
                            setCode(e.target.value);
                        }}
                        onKeyPress={keyPressed}
                        placeholder="Enter your code"
                        maxLength={4}
                        className="text-base text-primary border rounded-lg w-full h-[40px] pl-2 leading-6"
                        ref={codeInputRef}
                    />

                    {isSubmitting && (
                        <div className="flex items-center absolute top-[25%] px-1 right-1">
                            <BeatLoader color={Colors.black} size={7} />
                        </div>
                    )}
                </div>
                {error && (
                    <span className="text-alert text-sm font-normal px-1">
                        {error}
                    </span>
                )}
                <div className="items-center justify-start py-1.5 px-1.5 rounded-lg w-fit cursor-pointer hover:bg-tertiary">
                    <div
                        className="text-secondary font-semibold"
                        onClick={_onResend}
                    >
                        Resend Code
                    </div>
                </div>
            </div>
            <Divider width={1} />
            <div className="bg-[#F5F5F5] flex flex-row justify-between rounded-b-lg py-4 px-4">
                <SnackpassLogo className="fill-secondary h-[20px]" />
                <div className="flex flex-row gap-2 items-center">
                    <LockLogo className="fill-secondary w-4 h-4" />
                    <span className="text-secondary text-sm font-normal select-none">
                        Safe & Secure
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Verification;
