import { useState } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import {
    ModalsName,
    getActiveStore,
    getUserIsLoggedIn,
    showModal,
} from "src/redux";
import { Text } from "src/SharedComponents";
import { StripePaymentForm } from "src/components/Payment/stripe";
import {
    GiftCardStateType,
    subTitle,
    PaymentType,
    StepMoment,
    formatNumber,
} from "../lib";
import { useSelector } from "react-redux";
import { getUser } from "src/redux";
import _ from "lodash";
import GraphQLApi from "src/utils/Api/graphql";
import Cards from "@/screens/CartScreen/PaymentSection/Cards";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";

type GiftCardAddPaymentProps = {
    giftCardState: GiftCardStateType;
    step: StepMoment;
    setStep: React.Dispatch<React.SetStateAction<StepMoment>>;
    setGiftCardState: React.Dispatch<React.SetStateAction<GiftCardStateType>>;
};

const PAY_WITH_SNACKPASS = "Pay with Snackpass";
const PAY_WITH_CARD = "Pay with Card";
const ADD_PAYMENT = "ADD PAYMENT";
const SECURE_PAYMENT = "SECURE PAYMENT INFO";

const CENTS_CEIL = 100;

export const GiftCardAddPayment = ({
    giftCardState,
    setStep,
    setGiftCardState,
}: GiftCardAddPaymentProps) => {
    const user = useSelector(getUser);
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const hasUser = user && isUserLoggedIn;
    const store = useSelector(getActiveStore);
    const dispatch = useDispatch();
    const showAuthenticationModal = () =>
        dispatch(showModal(ModalsName.authenticationModal));

    const [isLoading, setLoading] = useState(false);

    if (!store) return null;

    const CONFIRM_BUTTON_TEXT = `Place Order · $${giftCardState.amount}`;

    const onButtonPressed = (type: PaymentType) => {
        setGiftCardState({ ...giftCardState, paymentType: type });
        if (type === PaymentType.CreditCard && _.isEmpty(user)) {
            setTimeout(
                () =>
                    window.scrollTo({
                        left: 0,
                        top: document.body.scrollHeight,
                        behavior: "smooth",
                    }),
                50,
            );
        }
        if (type === PaymentType.SnackPass && _.isEmpty(user)) {
            showAuthenticationModal();
        }
    };

    const onCreateGiftCardError = (errMessage: string | undefined) => {
        toast(
            errMessage ||
                "Error while purchasing the Gift Card, please check your card info is correct and try again",
        );
    };

    const onCreatePaymentTokenSuccess = async (
        cardTokenId: string,
        cardLast4: string | undefined,
    ) => {
        try {
            setLoading(true);
            setGiftCardState({ ...giftCardState, cardLast4: cardLast4 || "" });
            await GraphQLApi.giftCards.create({
                cardTokenId,
                toPhone: formatNumber(giftCardState.toPhone),
                maxCreditCents: Math.ceil(giftCardState.amount * CENTS_CEIL),
                storeId: store._id,
                note: giftCardState.note,
                fromName: giftCardState.fromName,
                fromEmail: giftCardState.fromEmail,
                toName: giftCardState.toName,
                cardLast4: cardLast4 || "",
                isManuallyEntered: giftCardState.usingOtherAmountButton,
                transactionSource: giftCardState.isKioskPurchase
                    ? "kiosk"
                    : "online",
            });
            setStep(StepMoment.confirmPurchase);
        } catch (err) {
            onCreateGiftCardError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const onPayWithSnackpass = async () => {
        try {
            const fromEmail = user?.email || giftCardState.fromEmail;
            const fromName = user?.name || giftCardState.fromName;
            setLoading(true);
            setGiftCardState({
                ...giftCardState,
                fromEmail,
                fromName,
                cardLast4: user?.activeCard.cardLast4,
            });
            await GraphQLApi.giftCards.create({
                cardTokenId: user?.activeCard.stripeId,
                customerId: user?.paymentCustomerIds.stripe,
                toPhone: formatNumber(giftCardState.toPhone),
                maxCreditCents: Math.ceil(giftCardState.amount * CENTS_CEIL),
                storeId: store._id,
                note: giftCardState.note,
                fromName,
                fromEmail,
                toName: giftCardState.toName,
                cardLast4: user?.activeCard.cardLast4,
                isManuallyEntered: giftCardState.usingOtherAmountButton,
                transactionSource: giftCardState.isKioskPurchase
                    ? "kiosk"
                    : "online",
            });
            setStep(StepMoment.confirmPurchase);
        } catch (err) {
            onCreateGiftCardError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const payWithSnackpass = () => {
        if (hasUser) {
            return (
                <div className="flex flex-col w-full">
                    <div className="flex flex-col justify-start text-start">
                        <div className="text-xl font-semibold text-primary leading-6 px-6 mt-4">
                            Pay with Card
                        </div>
                        <Cards />
                    </div>
                    {!_.isEmpty(user) && !_.isEmpty(user?.activeCard) && (
                        <div className="w-[100%] flex p-2 mt-2">
                            <Button
                                onPress={onPayWithSnackpass}
                                loading={isLoading}
                                label={CONFIRM_BUTTON_TEXT}
                                className="flex-1"
                                variant="brand"
                            />
                        </div>
                    )}
                </div>
            );
        } else {
            setGiftCardState({ ...giftCardState, paymentType: null });
        }
    };

    const payWithCard = () => {
        return (
            <div className="justify-content-lg-center row-centered px-6">
                {giftCardState.paymentType === PaymentType.CreditCard && (
                    <>
                        <div className="flex flex-col">
                            <Text style={subTitle}>{SECURE_PAYMENT}</Text>
                        </div>

                        <StripePaymentForm
                            confirmButtonText={CONFIRM_BUTTON_TEXT}
                            isLoading={isLoading}
                            onCreatePaymentTokenSuccess={
                                onCreatePaymentTokenSuccess
                            }
                            onCreatePaymentTokenError={onCreateGiftCardError}
                        />
                    </>
                )}
            </div>
        );
    };

    const showPaymentOptions = () => {
        return (
            <div className="flex flex-col w-full px-6">
                <div className="flex flex-col">
                    <Text style={subTitle}>{ADD_PAYMENT}</Text>
                </div>
                <div className="flex flex-col my-2">
                    <Button
                        onPress={() => onButtonPressed(PaymentType.SnackPass)}
                        label={PAY_WITH_SNACKPASS}
                        variant="outline"
                    ></Button>
                </div>
                <div className="flex flex-col my-2">
                    <Button
                        onPress={() => onButtonPressed(PaymentType.CreditCard)}
                        label={PAY_WITH_CARD}
                        variant="outline"
                    ></Button>
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col overflow-hidden justify-center items-center content-center text-center rounded-lg w-[100%] p-6 h-[100%]">
            {giftCardState.paymentType === PaymentType.SnackPass
                ? payWithSnackpass()
                : giftCardState.paymentType === PaymentType.CreditCard
                  ? payWithCard()
                  : showPaymentOptions()}
        </div>
    );
};
