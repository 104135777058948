import { AppState } from "@/redux/STORE";
import fp from "lodash/fp";

let getState: () => AppState = () => {
    throw new Error(
        "src/utils/Segment.getState is not initialized. Please call `Segment.registerGetState()`.",
    );
};

export const registerGetState = (fn: () => AppState): void => {
    getState = fn;
};

/**
 * This function tracks a segment event
 *
 * @param eventName The segment event name
 * @param properties Any segment properties. These will be automatically augmented by utm params from redux
 */
const track = (eventName: SegmentEventName, properties?: any) => {
    const state = getState();
    const utmTracking = fp.prop("utmTracking", state);
    const utmSource: string | null = fp.prop("utmTracking.utm_source", state);
    const isQrCode = utmSource ? utmSource.toLowerCase() === "qr" : false;

    if (analytics?.track) {
        analytics.track(eventName, {
            ...properties,
            ...utmTracking,
            is_qr_code: isQrCode,
            transactionSource: properties?.transactionSource ?? "online",
            commit_ref: process.env.REACT_APP_COMMIT_REF,
            app_type: process.env.REACT_APP_APP_TYPE,
        });
    }
};

//=== TYPES ===//
export const PRODUCT_VIEWED = "Product Viewed";
export type PRODUCT_VIEWED = typeof PRODUCT_VIEWED;

export const ADDED_ITEM_TO_CART = "Added Item To Cart";
export type ADDED_ITEM_TO_CART = typeof ADDED_ITEM_TO_CART;

export const UPDATED_CART_ITEM = "Updated Cart Item";
export type UPDATED_CART_ITEM = typeof UPDATED_CART_ITEM;

export const SIGNED_UP = "Signed Up";
export type SIGNED_UP = typeof SIGNED_UP;

export const PHONE_NUMBER_SUBMITTED = "Phone Number Submitted";
export type PHONE_NUMBER_SUBMITTED = typeof PHONE_NUMBER_SUBMITTED;

export const PHONE_NUMBER_VERIFIED = "Phone Number Verified";
export type PHONE_NUMBER_VERIFIED = typeof PHONE_NUMBER_VERIFIED;

export const CHAIN_SCREEN = "Chain Screen";
export type CHAIN_SCREEN = typeof CHAIN_SCREEN;

export const VIEW_CART = "View Cart";
export type VIEW_CART = typeof VIEW_CART;

export const STORE_VIEWED = "Store Viewed";
export type STORE_VIEWED = typeof STORE_VIEWED;

export const MOO_LOADED = "Moo Loaded";
export type MOO_LOADED = typeof MOO_LOADED;

export const OPENED_APPLE_PAY = "Opened Apple Pay";
export type OPENED_APPLE_PAY = typeof OPENED_APPLE_PAY;

export const OPENED_GOOGLE_PAY = "Opened Google Pay";
export type OPENED_GOOGLE_PAY = typeof OPENED_GOOGLE_PAY;

export const PAID_WITH_APPLE_PAY = "Paid with Apple Pay";
export type PAID_WITH_APPLE_PAY = typeof PAID_WITH_APPLE_PAY;

export const UPDATED_USER_FROM_APPLE_PAY = "Updated User From Apple Pay";
export type UPDATED_USER_FROM_APPLE_PAY = typeof UPDATED_USER_FROM_APPLE_PAY;

export const PAID_WITH_GOOGLE_PAY = "Paid with Google Pay";
export type PAID_WITH_GOOGLE_PAY = typeof PAID_WITH_GOOGLE_PAY;

export const REFERRAL_CODE_NOT_SENT = "Denied Sending Referral Text";
export type REFERRAL_CODE_NOT_SENT = typeof REFERRAL_CODE_NOT_SENT;

export const REFERRAL_MODAL_CLOSED = "Referral Modal Closed";
export type REFERRAL_MODAL_CLOSED = typeof REFERRAL_MODAL_CLOSED;

export const OPENED_SMART_RECEIPT = "Opened Smart Receipt";
export type OPENED_SMART_RECEIPT = typeof OPENED_SMART_RECEIPT;

export const OPENED_SMART_RECEIPT_WITH_PURCHASE_DETAILS =
    "Opened Smart Receipt with Purchase Details";
export type OPENED_SMART_RECEIPT_WITH_PURCHASE_DETAILS =
    typeof OPENED_SMART_RECEIPT_WITH_PURCHASE_DETAILS;

export const VIEW_ORDER_STATUS_TAB = "View Order Status Tab";
export type VIEW_ORDER_STATUS_TAB = typeof VIEW_ORDER_STATUS_TAB;

export const VIEW_RECEIPT_TAB = "View Receipt Tab";
export type VIEW_RECEIPT_TAB = typeof VIEW_RECEIPT_TAB;

export const CLICKED_DOWNLOAD_APP = "Clicked Download App";
export type CLICKED_DOWNLOAD_APP = typeof CLICKED_DOWNLOAD_APP;

export const CHECKOUT_ATTEMPT = "Checkout Attempt";
export type CHECKOUT_ATTEMPT = typeof CHECKOUT_ATTEMPT;

export const CHECKOUT_CREATE_PURCHASE = "Checkout Create Purchase";
export type CHECKOUT_CREATE_PURCHASE = typeof CHECKOUT_CREATE_PURCHASE;

export const PURCHASE_COMPLETE = "Purchase Complete";
export type PURCHASE_COMPLETE = typeof PURCHASE_COMPLETE;

export const REWARD_PROMO_VIEWED = "Reward Promo Viewed";
export type REWARD_PROMO_VIEWED = typeof REWARD_PROMO_VIEWED;

export const REWARD_VIEWED = "Reward Viewed";
export type REWARD_VIEWED = typeof REWARD_VIEWED;

export const ADDED_REWARD_PROMO_TO_CART = "Added Reward Promo To Cart";
export type ADDED_REWARD_PROMO_TO_CART = typeof ADDED_REWARD_PROMO_TO_CART;

export const PROMO_PRESSED = "Promotion Pressed";
export type PROMO_PRESSED = typeof PROMO_PRESSED;

export const ADDED_DEAL_TO_CART = "Added Deal To Cart";
export type ADDED_DEAL_TO_CART = typeof ADDED_DEAL_TO_CART;

export const ADDED_DISCOUNT_TO_CART = "Added Discount To Cart";
export type ADDED_DISCOUNT_TO_CART = typeof ADDED_DISCOUNT_TO_CART;

export type SegmentEventName =
    | PRODUCT_VIEWED
    | ADDED_ITEM_TO_CART
    | UPDATED_CART_ITEM
    | SIGNED_UP
    | PHONE_NUMBER_SUBMITTED
    | PHONE_NUMBER_VERIFIED
    | CHAIN_SCREEN
    | VIEW_CART
    | STORE_VIEWED
    | MOO_LOADED
    | OPENED_APPLE_PAY
    | OPENED_GOOGLE_PAY
    | PAID_WITH_APPLE_PAY
    | UPDATED_USER_FROM_APPLE_PAY
    | PAID_WITH_GOOGLE_PAY
    | REFERRAL_CODE_NOT_SENT
    | REFERRAL_MODAL_CLOSED
    | OPENED_SMART_RECEIPT
    | VIEW_ORDER_STATUS_TAB
    | VIEW_RECEIPT_TAB
    | CLICKED_DOWNLOAD_APP
    | CHECKOUT_ATTEMPT
    | CHECKOUT_CREATE_PURCHASE
    | PURCHASE_COMPLETE
    | OPENED_SMART_RECEIPT_WITH_PURCHASE_DETAILS
    | REWARD_VIEWED
    | REWARD_PROMO_VIEWED
    | ADDED_REWARD_PROMO_TO_CART
    | PROMO_PRESSED
    | ADDED_DEAL_TO_CART
    | ADDED_DISCOUNT_TO_CART;

export enum SegmentEventNames {
    PRODUCT_VIEWED = "Product Viewed",
    ADDED_ITEM_TO_CART = "Added Item To Cart",
    UPDATED_CART_ITEM = "Updated Cart Item",
    SIGNED_UP = "Signed Up",
    PHONE_NUMBER_SUBMITTED = "Phone Number Submitted",
    PHONE_NUMBER_VERIFIED = "Phone Number Verified",
    CHAIN_SCREEN = "Chain Screen",
    VIEW_CART = "View Cart",
    STORE_VIEWED = "Store Viewed",
    MOO_LOADED = "Moo Loaded",
    OPENED_APPLE_PAY = "Opened Apple Pay",
    UPDATED_USER_FROM_APPLE_PAY = "Updated User From Apple Pay",
    OPENED_GOOGLE_PAY = "Opened Google Pay",
    PAID_WITH_APPLE_PAY = "Paid with Apple Pay",
    PAID_WITH_GOOGLE_PAY = "Paid with Google Pay",
    REFERRAL_CODE_NOT_SENT = "Denied Sending Referral Text",
    REFERRAL_MODAL_CLOSED = "Referral Modal Closed",
    OPENED_SMART_RECEIPT = "Opened Smart Receipt",
    OPENED_SMART_RECEIPT_WITH_PURCHASE_DETAILS = "Opened Smart Receipt with Purchase Details",
    VIEW_ORDER_STATUS_TAB = "View Order Status Tab",
    VIEW_RECEIPT_TAB = "View Receipt Tab",
    CLICKED_DOWNLOAD_APP = "Clicked Download App",
    CHECKOUT_ATTEMPT = "Checkout Attempt",
    CHECKOUT_CREATE_PURCHASE = "Checkout Create Purchase",
    PURCHASE_COMPLETE = "Purchase Complete",
    REWARD_PROMO_VIEWED = "Reward Promo Viewed",
    REWARD_VIEWED = "Reward Viewed",
    ADDED_REWARD_PROMO_TO_CART = "Added Reward Promo To Cart",
    PROMO_PRESSED = "Promotion Pressed",
    ADDED_DEAL_TO_CART = "Added Deal To Cart",
    ADDED_DISCOUNT_TO_CART = "Added Discount To Cart",
}
export const Segment = {
    track,
};
