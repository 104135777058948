import { useSelectStore } from "@/screens/LocationsScreen.tsx/hooks/useSelectStore";
import { IStore } from "@snackpass/snackpass-types";
import React, { CSSProperties } from "react";
import MarketPlaceIcon from "src/assets/icons/marketplace.svg";

const getStyles = (
    focused?: boolean,
): { [className: string]: CSSProperties } => {
    const backgroundColor = focused ? "black" : "white";
    const foregroundColor = !focused ? "black" : "white";

    return {
        container: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        marker: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 8,
            borderRadius: 8,
            backgroundColor,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
        },
        tail: {
            border: "6px solid transparent",
            borderTopColor: backgroundColor,
            zIndex: 1.1,
        },
        icon: {
            width: 16,
            height: 16,
            fontSize: 13,
            ...(focused && { filter: "invert(100%)" }),
        },
        label: {
            fontSize: 14,
            fontWeight: "bold",
            color: foregroundColor,
            whiteSpace: "nowrap",
        },
    };
};

type Props = {
    lat: number;
    lng: number;
    focused?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    store: IStore;
};

export const MapMarker = ({ focused, onClick, store }: Props) => {
    const styles = getStyles(focused);
    const { handleSelectStore } = useSelectStore();
    if (!store.logoUrl) {
        return (
            <div
                style={styles.container}
                onClick={() => handleSelectStore(store)}
            >
                <div style={styles.marker} onClick={onClick}>
                    <img
                        src={MarketPlaceIcon}
                        style={styles.icon}
                        alt="marketplace"
                    />
                </div>
                <div style={styles.tail}></div>
            </div>
        );
    }
    return (
        <div
            onClick={() => handleSelectStore(store)}
            style={{
                width: "48px",
                height: "48px",
                background: `url(${store.logoUrl || MarketPlaceIcon}) center center / cover no-repeat`,
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: focused
                    ? "0px 0px 0px 0.5px rgba(15, 15, 15, 0.12), 0px 4px 48px 0px rgba(0, 0, 0, 0.12), 0px 3px 16px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                    : "none",
            }}
        />
    );
};
