import config from "@/config";
import { Statsig } from "statsig-react";

// TODO: Remove once we are confident in @snackpass/time
let _isSnackpassTimeEnabled: boolean | null = null;

let _isDiscountAndDealEnabled: boolean | null = null;

/** Check Statsig enableSnackpassTime feature gate. */
export const isSnackpassTimeEnabled = () => {
    const enabled = Statsig.checkGate(config.featureGates.enableSnackpassTime);

    // Log when the value changes
    if (_isSnackpassTimeEnabled !== enabled) {
        _isSnackpassTimeEnabled = enabled;
        console.warn("isSnackpassTimeEnabled:", enabled);
    }

    return enabled;
};

export const isDiscountAndDealEnabled = () => {
    const enabled = Statsig.checkGate(
        config.featureGates.enableOODealAndDiscount,
    );
    if (_isDiscountAndDealEnabled !== enabled) {
        _isDiscountAndDealEnabled = enabled;
    }
    return enabled;
};
