import React, { useState } from "react";
import {
    ModalsName,
    getActiveStore,
    getIsAuthenticationModalOpen,
    hideModal,
} from "@/redux";
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import { ReactComponent as SnackpassLogo } from "src/assets/logos/black-logo.svg";
import { ReactComponent as LockLogo } from "src/assets/icons/lock.svg";
import { cn } from "@/utils";
import { useMediaQuery } from "react-responsive";
import { Page } from "./lib";
import ModalContent from "./ModalContent";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const AuthenticationModal = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const authenticationModal = useSelector(getIsAuthenticationModalOpen);
    const handleHideAuthenticationModal = () => {
        dispatch(hideModal(ModalsName.authenticationModal));
    };
    const [page, setPage] = useState<Page>("LOGIN");
    const [modalTitle, setModalTitle] = useState("Log in or sign up");
    const handleTogglePage = (page: Page) => {
        setPage(page);
    };
    const onHide = () => {
        handleHideAuthenticationModal();
        handleTogglePage("LOGIN");
    };

    if (isDesktop) {
        return (
            <div>
                <Dialog open={authenticationModal} onOpenChange={onHide}>
                    <DialogContent
                        onEscapeKeyDown={onHide}
                        className={cn(
                            "flex flex-col items-start max-w-[408px] rounded-lg",
                            {
                                " h-[380px] max-h-[600px]": !activeStore?.name,
                                " h-[470px] max-h-[700px]": activeStore?.name,
                            },
                        )}
                    >
                        <div className="flex flex-col overflow-y-auto h-full relative w-full hidden-scroll">
                            <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                                <DialogClose className="flex gap-0" />
                                <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto">
                                    <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                        {modalTitle}
                                    </div>
                                </div>
                            </div>
                            <ModalContent
                                page={page}
                                handleTogglePage={handleTogglePage}
                                setModalTitle={setModalTitle}
                            />
                        </div>
                        <DialogFooter className="w-[100%] absolute bottom-0 left-0 border-t px-4 py-4 bg-[#F5F5F5] md:bg-[#F5F5F5] flex flex-col-reverse md:border-t-[1px] md:flex-row sm:justify-center sm:space-x-2 md:px-2">
                            <div className="w-[100%] flex flex-row justify-between">
                                <SnackpassLogo className="fill-secondary h-[20px] pl-4" />
                                <div className="flex flex-row gap-2 items-center pr-4">
                                    <LockLogo className="fill-secondary w-4 h-4" />
                                    <span className="text-secondary text-sm font-normal select-none">
                                        Safe & Secure
                                    </span>
                                </div>
                            </div>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    return (
        <Sheet open={authenticationModal} onOpenChange={onHide}>
            <SheetContent className="overflow-auto pb-16">
                <SheetHeader>
                    <SheetTitle> {modalTitle}</SheetTitle>
                </SheetHeader>
                <ModalContent
                    page={page}
                    handleTogglePage={handleTogglePage}
                    setModalTitle={setModalTitle}
                />
                <SheetFooter className="py-4 border-t">
                    <SheetClose asChild>
                        <div className="w-[100%] flex flex-row justify-between">
                            <SnackpassLogo className="fill-secondary h-[20px] pl-4" />
                            <div className="flex flex-row gap-2 items-center pr-4">
                                <LockLogo className="fill-secondary w-4 h-4" />
                                <span className="text-secondary text-sm font-normal select-none">
                                    Safe & Secure
                                </span>
                            </div>
                        </div>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default AuthenticationModal;
