import React from "react";
import { IProduct } from "@snackpass/snackpass-types";
import { ActiveCartItemActions } from "@snackpass/accounting";
import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../redux";
import {
    showModal,
    hideModal,
    ModalsName,
    getItemSearchParameter,
    searchForItem,
} from "@/redux";
import { ItemSearchBar } from "@/screens/StoreScreen/components/ItemSearchBar";
import ProductSection from "@/screens/StoreScreen/components/ProductSection";
import { useMediaQuery } from "react-responsive";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const SearchProductModal = () => {
    const dispatch = useDispatch();
    const searchProductModal = useSelector(actions.getIsSearchProductModalOpen);

    const isDesktop = useMediaQuery({ minWidth: 768 });
    const onHide = () => {
        dispatch(hideModal(ModalsName.searchProductModal));
        dispatch(searchForItem(""));
    };

    if (isDesktop) {
        return (
            <Dialog open={searchProductModal} onOpenChange={onHide}>
                <DialogContent className="h-[500px]" onEscapeKeyDown={onHide}>
                    <div className="flex flex-col overflow-y-auto h-full relative w-full hidden-scroll">
                        <div className="flex sticky top-0 right-0 z-[101] border-b py-4 bg-white">
                            <DialogClose className="flex gap-0 top-6" />
                            <div className="flex w-[100%] mx-4">
                                <ItemSearchBar />
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="w-[100%]">
                                <SearchProductResult />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={searchProductModal} onOpenChange={onHide}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle className="w-full">
                        <ItemSearchBar />
                    </SheetTitle>
                </SheetHeader>
                <div className="h-[500px] overflow-auto pt-6">
                    <SearchProductResult />
                </div>
            </SheetContent>
        </Sheet>
    );
};

const SearchProductResult = () => {
    const dispatch = useDispatch();
    const filteredProducts = useSelector(actions.getFilteredProducts);
    const itemSearchParameter = useSelector(getItemSearchParameter);
    const setActiveCartItem = (activeProduct: IProduct) =>
        dispatch(ActiveCartItemActions.setActiveCartItem(activeProduct));
    const handleShowProductModal = () => {
        dispatch(showModal(ModalsName.productModal));
    };
    const handleHideSearchProductModal = () => {
        dispatch(hideModal(ModalsName.searchProductModal));
    };

    const onHide = () => {
        handleHideSearchProductModal();
        dispatch(searchForItem(""));
    };
    const handlePressProduct = (product: IProduct) => {
        setActiveCartItem(product);
        handleShowProductModal();
        onHide();
    };
    if (!itemSearchParameter) {
        return null;
    }
    if (itemSearchParameter && filteredProducts.length === 0) {
        return (
            <div className="w-full h-full flex justify-center items-center m-auto">
                <span className="text-primary text-xl font-semibold	m-auto text-center mt-6">
                    Item Not Found
                </span>
            </div>
        );
    }

    return (
        <ProductSection
            key="Search results"
            title="Search results"
            category="searchresults"
            products={filteredProducts}
            handlePressProduct={handlePressProduct}
            hideTitle={true}
        />
    );
};

export default SearchProductModal;
