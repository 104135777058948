import {
    FeePolicyWithTotal,
    IPurchase,
    ReservedFeeEnum
} from "@snackpass/snackpass-types";
import Dinero from "dinero.js";
import {
    all,
    compose,
    filter,
    flatten,
    map,
    max,
    prop,
    propOr,
    sum,
    sumBy
} from "lodash/fp";
import { createSelector } from "reselect";
import { Accounting, validatePromotion } from "../../accountingFormulas";
import {
    findCustomerFeePolicies,
    reserved
} from "../../accountingFormulas/fees";
import { SalesTax } from "../../accountingFormulas/salesTax";
import { CartItemFlat, DealGroup, ICart } from "../../types";
import { round } from "../../utils";
import {
    getCustomerFeePolicies,
    getSubtotalWithoutGiftCards,
    getUseCredit
} from "../checkout/selectors";
import {
    getAllStoreConvenienceFeePolicies,
    getAllStoreCreditAvailable,
    getAllStoreDeliveryFees,
    getAllStoreDeliveryMin,
    getAllStoreFeePolicies,
    getAllStorePickupMin,
    getMultiCartStoreIds,
    getMultiCartStoreRegions,
    getMultiCartStores,
    getMyLocation,
    getTransactionSource,
    getUserAddress,
    getUserCredit
} from "../helperSelectors";
import {
    getAllNumbersOfBags,
    getMultiCart,
    getMultiCartDealGroups,
    getMultiCartFulfillment,
    getMultiCartItems,
    getMultiCartTableNumber,
    getMultiCartUseCredit
} from "../multiCart/selectors";

import { getAllGiftCardCreditAvailable } from "./../giftCards/selectors";

/**
 * IMPORTANT NOTES:
 * The prefix 'All' in the following selectors indicates that
 * the selector returns an array of values, where the array
 * element at each index represents a cart in the multicart.
 */

// get list of tax rates for each store
export const getAllTaxRates = createSelector(
    [getMultiCartStores],
    map(propOr(0, "taxRate"))
);

// get list of tips for each store in multicart
export const getAllTips = createSelector([getMultiCart], map(propOr(0, "tip")));

// total tip amount across all stores in multicart
export const getNetTip = createSelector([getAllTips], sum);

// get list of notes for each store in multicart
export const getMultiCartNotes = createSelector(
    [getMultiCart],
    map(propOr("", "notes"))
);

// get list of subtotals for each store in multicart
export const getAllSubtotals = createSelector(
    [getMultiCartItems],
    map(Accounting.calcCartItemSubtotal)
);

// get net subtotal across all stores in multicart
export const getNetSubtotal = createSelector([getAllSubtotals], sum);

// get list of points for each store in multicart
export const getAllPoints = createSelector(
    [getMultiCartItems],
    map(sumBy(propOr(0, "points")))
);

// get net points across all stores
export const getNetPoints = createSelector([getAllPoints], sum);

// get list of delivery fees for each store
// NOTE - this selector does not work intuitively.
// It will take the delivery fee of the first store in the
// multicart, and then split it evenly across each
// purchase in the cart. This is so that foodhall orders
// do not charge the same delivery fee x times.
// TODO: make this more intuitive
export const getAllDeliveryFeesApplied = createSelector(
    [getMultiCartFulfillment, getAllStoreDeliveryFees, getMultiCart],
    (fulfillment, deliveryFees, multiCart) => {
        const fee = max(deliveryFees) || 0;
        const splitFee = round(fee / multiCart.length);
        return multiCart.map((cart: ICart, i: number) => {
            if (fulfillment !== "DELIVERY") return 0;
            // add remaining amount required to make the sum of
            // the split fees equal to fee
            // ie. $4 delivery fee split across 3 purchases:
            // [1.34, 1.33, 1.33]
            if (i === 0) {
                return round(splitFee + (fee - splitFee * multiCart.length));
            }
            return splitFee;
        });
    }
);

// get net delivery fee across all stores
export const getNetDeliveryFeeApplied = createSelector(
    [getAllDeliveryFeesApplied],
    sum
);

// get list of subtotals + delivery fees for each store in multicart
export const getAllSubtotalsPlusDeliveryFees = createSelector(
    [getAllSubtotals, getAllDeliveryFeesApplied],
    (allSubtotals, allDeliveryFeesApplied) =>
        allSubtotals.map((subtotal: number, i: number) =>
            round(subtotal + allDeliveryFeesApplied[i])
        )
);

// get list of whether minimums are reached for each store in multicart
export const getAllMinIsReached = createSelector(
    [
        getAllStoreDeliveryMin,
        getAllStorePickupMin,
        getMultiCartFulfillment,
        getAllSubtotals
    ],
    (allDeliveryMins, allPickupMins, fulfillment, allSubtotals) =>
        allSubtotals.map((subtotal: number, i: number) => {
            if (fulfillment === "DELIVERY") {
                const diff = allDeliveryMins[i] - subtotal;
                if (diff > 0) return false;
            }
            if (fulfillment === "PICKUP") {
                const pickupDiff = allPickupMins[i] - subtotal;
                if (pickupDiff > 0) return false;
            }
            return true;
        })
);

// gets list of differences between subtotal and delivery min
// for each store in multicart
export const getAllDiffDeliveryMin = createSelector(
    [getAllStoreDeliveryMin, getAllSubtotals],
    (allDeliveryMins, allSubtotals) =>
        allSubtotals.map(
            (subtotal: number, i: number) => allDeliveryMins[i] - subtotal
        )
);

// gets list of checkout rewards for each store in multicart
export const getAllCheckoutRewards = createSelector(
    [getMultiCartItems],
    map((cartItems: CartItemFlat[]) => {
        const ret: string[] = [];
        cartItems.forEach((item) => {
            if (item.reward) {
                ret.push(item.reward._id);
            }
        });
        return ret;
    })
);

// gets list of promotion ids for each store in multicart
export const getAllCheckoutPromotions = createSelector(
    [getMultiCartItems],
    map((cartItems: CartItemFlat[]) => {
        const ret: string[] = [];
        cartItems.forEach((item) => {
            if (item.promotion) {
                ret.push(item.promotion._id);
            }
        });
        return ret;
    })
);

// List of all customer-facing fee policies for each store.
export const getAllCustomerFeePolicies = createSelector(
    [
        getAllSubtotals,
        getMultiCartFulfillment,
        getTransactionSource,
        getAllStoreFeePolicies,
        getUseCredit
    ],
    (allSubtotals, fulfillment, transactionSource, allFeePolicies, useCredit) =>
        allFeePolicies.map((feePolicies, i) =>
            findCustomerFeePolicies({
                cartSubtotal: allSubtotals[i],
                fulfillment,
                transactionSource,
                isCatering: false,
                feePolicies,
                useCredit
            })
        )
);

export const getAllCustomerFeeBreakdowns = createSelector(
    [getAllSubtotals, getAllNumbersOfBags, getAllCustomerFeePolicies],
    (allSubtotals, allNumbersOfBags, allFeePolicies) =>
        allFeePolicies.map(
            (feePolicies, i) =>
                feePolicies
                    .map((policy) =>
                        // NOTE: Technically this can return `null`, but here since we
                        // know that `policy` exists in `feePolicies`, we can guarantee
                        // that it will not be `null`.
                        reserved.calculate({
                            feeName: policy.name as ReservedFeeEnum,
                            feePolicies,
                            amount: allSubtotals[i],
                            // `quantity` is better than `numberOfBags` on this generic payload...
                            quantity:
                                policy?.name === "BAG_STORE_FEE"
                                    ? allNumbersOfBags[i]
                                    : 1
                        })
                    )
                    .filter(Boolean) as FeePolicyWithTotal[]
        )
);

// Reduces mutliCartCustomFeeBreakdowns from 2D to 1D array where fees are
// grouped by name.
// e.g. [
//  [{ fee: {name: "FooFee", flat: 2, percent: 0 }, total: 2 }],
//  [{ fee: { name: "FooFee", flat: 5, percent: 0 } total : 5 }]
// ] => [{ fee: { name: "FooFee", flat: 7, percent: 0 }, total: 7 }]
// We do this to avoid customers from thinking they were charged the same fee
// twice.
type FeesMap = { [key: string]: FeePolicyWithTotal };
export const getReducedMultiCartFeeBreakdowns = createSelector(
    [getAllCustomerFeeBreakdowns],
    (allFeeBreakdowns) =>
        Object.values(
            flatten(allFeeBreakdowns).reduce(
                (feesMap: FeesMap, { fee, total }) => {
                    const acc = feesMap[fee.name] || {
                        fee: { flat: 0, percent: 0 },
                        total: 0
                    };

                    // The policy `flat` and `percent` amounts are updated so
                    // the combined policy reflects the total of all its
                    // components. All other fields in the policy are uniquely
                    // tied to the fee name, so they need not be aggregated
                    // in any way.
                    // NOTE: In particular, this assumes that all fees from
                    // the multicart have the same `rules` and `isTaxable`
                    // parameters. This is based on the assumption that any
                    // given multicart purchase involves restaurants that are
                    // subject to the same fee taxation laws.
                    const updatedFee = {
                        ...fee,
                        flat: fee.flat + acc.fee.flat,
                        percent: fee.percent + acc.fee.percent
                    };

                    const updatedTotal = total + acc.total;

                    return {
                        ...feesMap,
                        [fee.name]: {
                            fee: updatedFee,
                            total: updatedTotal
                        }
                    };
                },
                {} as FeesMap
            )
        ) as FeePolicyWithTotal[]
);

// Total customer fees due to each store
export const getAllCustomerFees = createSelector(
    [getAllCustomerFeeBreakdowns],
    map(sumBy("total"))
);

// Total taxable customer fees due to eaech store
export const getAllTaxableCustomerFees = createSelector(
    [getAllCustomerFeeBreakdowns],
    map(compose(sumBy("total"), filter("fee.isTaxable")))
);

/**
 * Total amount of customer fees across all stores
 * @deprecated Unused
 */
export const getNetCustomerFees = createSelector([getAllCustomerFees], sum);

export const getAllMaxCreditAmounts = createSelector(
    [
        getAllSubtotals,
        getAllDeliveryFeesApplied,
        getAllTips,
        getAllCustomerFees
    ],
    (allSubtotals, allDeliveryFeesApplied, getAllTips, allCustomerFees) =>
        allSubtotals.map(
            (subtotal: number, i: number) =>
                round(
                    subtotal +
                        allDeliveryFeesApplied[i] +
                        getAllTips[i] +
                        allCustomerFees[i]
                ) || 0
        )
);

// get store credit applied for each store in multicart
export const getAllStoreCredit = createSelector(
    [
        getAllStoreCreditAvailable,
        getAllGiftCardCreditAvailable,
        getAllMaxCreditAmounts,
        getMultiCartUseCredit
    ],
    (
        allStoreCredits,
        allGiftCardCredits,
        allMaxCreditAmounts,
        allUseCredits
    ) => {
        return allUseCredits.map((useCredit: boolean, i: number) => {
            if (!useCredit) return 0;
            return Accounting.calcStoreCreditApplied(
                allStoreCredits[i] || 0,
                allGiftCardCredits[i] || 0,
                allMaxCreditAmounts[i]
            );
        });
    }
);

// Receipt & Checkout Items
export const getAllSalesTax = createSelector(
    [
        getMultiCart,
        getMultiCartStores,
        getTransactionSource,
        getCustomerFeePolicies,
        getAllStoreCredit
    ],
    (
        carts,
        stores,
        transactionSource,
        customerFeePolicies,
        storeCreditAmounts
    ) =>
        carts.map((_, i) =>
            SalesTax.calculateForStore({
                cart: carts[i],
                store: stores[i] || null,
                transactionSource,
                customerFeePolicies,
                upchargeAmountInDollars: 0,
                storeCreditAmountInDollars: storeCreditAmounts[i]
            })
        )
);

// list of taxable amount for each store in multicart
export const getAllTaxableAmounts = createSelector(
    [getAllSalesTax],
    (allSalesTax) =>
        allSalesTax.map((salesTax) => Dinero(salesTax.taxableAmount).toUnit())
);

// list of tax for each store in multicart
export const getAllTax = createSelector([getAllSalesTax], (allSalesTax) =>
    allSalesTax.map((salesTax) => Dinero(salesTax.taxAmount).toUnit())
);

// get total tax across all stores in multicart
export const getNetTax = createSelector([getAllTax], sum);

// get global credit used for each store in multicart
export const getAllGlobalCredit = createSelector(
    [
        getAllStoreCredit,
        getAllGiftCardCreditAvailable,
        getAllTax,
        getUserCredit,
        getAllMaxCreditAmounts,
        getMultiCartUseCredit
    ],
    (
        allStoreCredits,
        allGiftCardCreditAvailable,
        allTaxes,
        userCredit,
        allMaxCreditAmounts,
        useCredits
    ) => {
        // keep track of user credit used across stores
        let creditRemaining: number = userCredit;
        return allMaxCreditAmounts.map((maxCreditAmount: number, i: number) => {
            // do not use global credit if store credit used
            if (!useCredits[i]) return 0;
            const creditApplied = Accounting.calcGlobalCreditApplied(
                allStoreCredits[i],
                allGiftCardCreditAvailable[i],
                allTaxes[i],
                creditRemaining,
                maxCreditAmount
            );
            creditRemaining -= creditApplied;
            return creditApplied;
        });
    }
);

// list of total credit used for each store in multicart
export const getAllStoreAndGlobalCreditUsed = createSelector(
    [getAllStoreCredit, getAllGlobalCredit],
    (storeCredits, globalCredits) =>
        storeCredits.map((storeCredit: number, i: number) =>
            Accounting.calcStoreAndGlobalCreditUsed(
                storeCredit,
                globalCredits[i]
            )
        )
);

// get list of promotion ids for each cart in multicart
export const getAllCheckoutDealIds = createSelector(
    [getMultiCartDealGroups],
    (dealGroupLists) =>
        dealGroupLists.map((dealGroups: DealGroup[]) => {
            const ret: string[] = [];
            dealGroups.forEach((dealGroup) => {
                if (dealGroup.promotion._id) {
                    ret.push(dealGroup.promotion._id);
                }
            });
            return ret;
        })
);

// list of convenience fees for each store in multicart
export const getAllConvenienceFees = createSelector(
    [
        getSubtotalWithoutGiftCards,
        getMultiCartUseCredit,
        getAllGiftCardCreditAvailable,
        getAllMaxCreditAmounts,
        getAllStoreAndGlobalCreditUsed,
        getTransactionSource,
        getAllStoreConvenienceFeePolicies
    ],
    (
        cartSubtotal,
        multiCartUseCredit,
        allGiftCardCreditAvailable,
        allMaxCreditAmounts,
        allTotalCreditUsed,
        transactionSource,
        allConvenienceFeePolicies
    ) =>
        allMaxCreditAmounts.map((maxCreditAmount: number, i: number) =>
            Accounting.calcConvenienceFee(
                cartSubtotal,
                multiCartUseCredit[i],
                allGiftCardCreditAvailable[i],
                allTotalCreditUsed[i],
                maxCreditAmount,
                transactionSource,
                allConvenienceFeePolicies[i]
            )
        )
);

export const getAllMaxGiftCardCreditAmounts = createSelector(
    [
        getAllSubtotals,
        getAllDeliveryFeesApplied,
        getAllTips,
        getAllCustomerFees,
        getAllTax,
        getAllConvenienceFees
    ],
    (
        allSubtotals,
        allDeliveryFeesApplied,
        getAllTips,
        allCustomerFees,
        allTaxes,
        allStoreConvenienceFees
    ) =>
        allSubtotals.map(
            (subtotal: number, i: number) =>
                round(
                    subtotal +
                        allDeliveryFeesApplied[i] +
                        getAllTips[i] +
                        allCustomerFees[i] +
                        allTaxes[i] +
                        allStoreConvenienceFees[i]
                ) || 0
        )
);

// get gift card credit used for each store in multicart
export const getAllGifCardCredit = createSelector(
    [
        getAllGiftCardCreditAvailable,
        getAllMaxCreditAmounts,
        getMultiCartUseCredit
    ],
    (allGiftCardCredit, allMaxCreditAmounts, allUseCredit) => {
        return allUseCredit.map((useCredit: boolean, i: number) => {
            if (!useCredit) return 0;
            return Accounting.calcGiftCardCreditApplied(
                allGiftCardCredit[i] || 0,
                allMaxCreditAmounts[i]
            );
        });
    }
);

// list amounts paid by customer for each store in multicart
export const getAllAmountsPaidByCustomer = createSelector(
    [
        getAllSubtotals,
        getAllTax,
        getAllStoreCredit,
        getAllGlobalCredit,
        getAllGifCardCredit,
        getAllTips,
        getAllConvenienceFees,
        getAllCustomerFees,
        getAllDeliveryFeesApplied
    ],
    (
        allSubtotals,
        allTaxes,
        allStoreCredit,
        allGlobalCredit,
        allGiftCardCredits,
        allTips,
        allConvenienceFees,
        allCustomerFees,
        allDeliveryFeesApplied
    ) => {
        return allSubtotals.map((subtotal: number, i: number) =>
            Accounting.calcGetAmountPaidByCustomer(
                subtotal,
                allTaxes[i],
                allStoreCredit[i],
                allGlobalCredit[i],
                allGiftCardCredits[i],
                allTips[i],
                allConvenienceFees[i],
                allCustomerFees[i],
                allDeliveryFeesApplied[i]
            )
        );
    }
);

// gets list of totals paid by customer for each store in multicart
export const getAllTotals = createSelector(
    [getAllAmountsPaidByCustomer],
    map((amount: number) => amount)
);

// gets net total paid by customer for multicart
export const getNetTotal = createSelector([getAllTotals], sum);

// Combine individual selectors into a selector group
// note: we make these selectors return arrays so we
// can use spread operator to apply the values to the
// formatted purchases easily
export const getAllFeesAndFulfillment = createSelector(
    [
        getAllDeliveryFeesApplied,
        getAllConvenienceFees,
        getAllCustomerFeeBreakdowns,
        getMultiCartFulfillment
    ],
    (allDeliveryFees, allConvenienceFees, allCustomFees, fulfillment) =>
        allDeliveryFees.map((deliveryFee: number, i: number) => ({
            deliveryFee: fulfillment === "DELIVERY" ? deliveryFee : 0,
            convenienceFee: allConvenienceFees[i],
            fees: allCustomFees[i],
            fulfillment
        }))
);

export const getAllCredits = createSelector(
    [getAllGlobalCredit, getAllStoreCredit],
    (allGlobalCredit, allStoreCredit) =>
        allGlobalCredit.map((globalCredit: number, i: number) => ({
            globalCreditUsed: globalCredit,
            storeCreditUsed: allStoreCredit[i] || 0
        }))
);

export const getAllCheckoutBenefits = createSelector(
    [getAllCheckoutRewards, getAllCheckoutPromotions],
    (allRewards, allPromotions) =>
        allRewards.map((rewards: string[], i: number) => ({
            rewards,
            promotions: allPromotions[i]
        }))
);

export const getAllMultiCartDetails = createSelector(
    [
        getAllTips,
        getMultiCartNotes,
        getMultiCartItems,
        getMultiCartTableNumber,
        getMultiCartFulfillment
    ],
    (allTips, allNotes, itemLists, tableNumber, fulfillment) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        allTips.map((tip: number, i: number) => ({
            tip,
            notes: allNotes[i],
            items: itemLists[i],
            ...(fulfillment === "DINE_IN" && tableNumber && { tableNumber })
        }))
);

// gets list of formatted purchases for each cart in multicart
export const getAllFormattedPurchases = createSelector(
    [
        getAllFeesAndFulfillment,
        getAllCredits,
        getAllCheckoutBenefits,
        getAllMultiCartDetails,
        getUserAddress
    ],
    (
        allFeesAndFulfillment,
        allCredits,
        allCheckoutBenefits,
        allMultiCartDetails,
        address
    ) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        allFeesAndFulfillment.map((feesAndFulfillment, i) => ({
            ...feesAndFulfillment,
            ...allCredits[i],
            ...allCheckoutBenefits[i],
            ...allMultiCartDetails[i],
            deliveryAddress: address
        }))
);

export const getAllFormattedPurchasesWithStores = createSelector(
    [
        getAllFormattedPurchases,
        getMultiCartStoreRegions,
        getMultiCartStoreIds,
        getMyLocation
    ],
    (allFormattedPurchases, allRegions, allStoreIds, geolocation) =>
        allFormattedPurchases.map(
            (purchase, i) =>
                ({
                    ...purchase,
                    region: allRegions[i],
                    storeId: allStoreIds[i],
                    geolocation
                }) as unknown as IPurchase
        )
);

// gets list of cart item validations for each store in multicart
export const getMultiCartItemValidations = createSelector(
    [
        getMultiCartItems,
        getMultiCartFulfillment,
        getAllSubtotals,
        getAllCheckoutPromotions,
        getAllCheckoutDealIds
    ],
    (
        itemLists,
        fulfillment,
        allSubtotals,
        allCheckoutPromotions,
        allCheckoutDealIds
    ) =>
        itemLists.map((cartItems: CartItemFlat[], i: number) => {
            return cartItems.map((cartItem) =>
                validatePromotion(
                    cartItem.promotion,
                    allSubtotals[i],
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    fulfillment,
                    allCheckoutPromotions[i],
                    allCheckoutDealIds[i],
                    cartItem.totalPrice
                )
            );
        })
);

// false if any item in any cart is not validated
export const getMultiCartIsValidated = createSelector(
    [getMultiCartItemValidations],
    (multiCartItemValidations) =>
        multiCartItemValidations
            .map(
                all((validationItem) =>
                    Boolean(prop("validated", validationItem))
                )
            )
            .every((cartValidation: boolean) => cartValidation)
);

export const getMultiCartMinimumChargeAmountsCents = createSelector(
    [getMultiCartStores],
    (stores) => stores.map((store) => store?.minimumChargeAmountCents ?? 50)
);
