import axios from "axios";

import { firebaseAuth } from "src/firebase";

import config from "../../config";

const instance = axios.create({
    baseURL: config.baseUrl,
});

instance.defaults.headers.common["X-Snackpass-Client"] =
    "@snackpass/online-ordering";
instance.defaults.headers.common["X-Snackpass-Client-Version"] =
    process.env.REACT_APP_COMMIT_REF ?? process.env.COMMIT_REF ?? "";

instance.interceptors.request.use(async (config) => {
    const token = await firebaseAuth.currentUser?.getIdToken();
    if (token) {
        config.headers = {
            ...(config.headers ?? {}),
            Authorization: `Bearer ${token}`,
        };
    }
    return config;
});

export const XForterToken = "X-Forter-Token";

export default instance;
