import { cartSlice } from "#cart/slice";
import { createSelector } from "@reduxjs/toolkit";

const selectCartState = (state: {
    [cartSlice.name]: ReturnType<typeof cartSlice.getInitialState>;
}) => state[cartSlice.name];

export const selectConflicts = createSelector(
    [selectCartState],
    (state) => state.conflicts,
);

export const selectHasConflicts = createSelector(
    [selectConflicts],
    (conflicts) => conflicts.length > 0,
);
