import { Fulfillment } from "@snackpass/snackpass-types";

export const SET_STORE_FULFILLMENT_OPTIONS = "SET_STORE_FULFILLMENT_OPTIONS";

export type SET_STORE_FULFILLMENT_OPTIONS =
    typeof SET_STORE_FULFILLMENT_OPTIONS;

export type SetActiveStoreFulfillmentOptions = ReturnType<
    typeof setStoreFulfillmentOptions
>;

export const setStoreFulfillmentOptions = (
    fulfillmentOptions: Fulfillment[]
) => {
    return {
        type: SET_STORE_FULFILLMENT_OPTIONS,
        fulfillmentOptions,
    } as const;
};

export const getStoreFulfillmentOptions = (state): Fulfillment[] =>
    state.fulfillment.fulfillmentOptions;

export interface FulfillmentOptionsState {
    fulfillmentOptions: Fulfillment[];
}
const INITIAL_STATE: FulfillmentOptionsState = {
    fulfillmentOptions: [],
};

export default function fulfillment(
    state = INITIAL_STATE,
    action: SetActiveStoreFulfillmentOptions
) {
    switch (action.type) {
        case SET_STORE_FULFILLMENT_OPTIONS:
            return {
                ...INITIAL_STATE,
                fulfillmentOptions: action.fulfillmentOptions,
            };
        default:
            return state;
    }
}
