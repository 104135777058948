import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateMyLocation } from "src/redux";
import { CartSelectors, CartActions } from "@snackpass/accounting";
import { BeatLoader } from "react-spinners";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import { FulfillmentTypeEnum, Geolocation } from "@snackpass/snackpass-types";
import { result } from "lodash/fp";
import { Api } from "../../../utils";
import { ReactComponent as PinIcon } from "src/assets/icons/pin_outline.svg";

const addLine2ToAddress = (address: string, lineTwo: string): string => {
    const addressComponents = address.split(",");
    if (addressComponents.length === 4) {
        // Insert line2 between street and city
        addressComponents.splice(1, 0, lineTwo);
    } else if (addressComponents.length === 5) {
        // Address already have line 2
        if (lineTwo) {
            addressComponents[1] = lineTwo;
        } else {
            addressComponents.splice(1, 1);
        }
    }
    return addressComponents.join(",");
};

const getAddressLineOneLineTwo = (
    address: string,
): { lineOne: string; lineTwo: string } => {
    const parsedAddress = address.split(",");
    //address has line two
    if (parsedAddress.length === 5) {
        const lineTwo = parsedAddress.splice(1, 1).join(",");
        const lineOne = parsedAddress.join(",");
        return { lineOne, lineTwo };
    }
    //address has no line two
    return { lineOne: address, lineTwo: "" };
};

const DeliveryAddress = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const cartFulfillment = useSelector(CartSelectors.getCartFulfillment);

    const [error, setError] = useState<string | null>(null);
    const [address, setAddress] = useState<string>("");
    const [addressLineTwo, setAddressLineTwo] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const deliveryAddress = useSelector(CartSelectors.getCart).address;

    // Need this or else Google Places will bill each request separately:
    // https://developers.google.com/maps/documentation/places/web-service/session-tokens
    const sessionToken = useMemo(
        () => new google.maps.places.AutocompleteSessionToken(),
        [],
    );

    useEffect(() => {
        const { lineOne, lineTwo } = getAddressLineOneLineTwo(deliveryAddress);
        setAddress(lineOne);
        setAddressLineTwo(lineTwo);
    }, [deliveryAddress]);

    const updateUserAddress = async (address: string) => {
        if (cartFulfillment === FulfillmentTypeEnum.Delivery && !address) {
            return setError("Please enter your delivery address");
        }

        setLoading(true);
        setError(null);
        try {
            // Resolve the geolocation from the address
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);

            // By default, the `address` suggestions don't have details
            // e.g. zip code, so we fetch it from this object instead.
            const formattedAddress: string = results[0].formatted_address;

            // Even after fetching the details of the suggestion, it may still be unable to provide a zip code
            // so we have to process the details obtained before using them, in case some required field is missing
            const formattedAddressParts = formattedAddress
                .split(",")
                .map(result("trim"))
                .reverse() as string[];

            const [country, stateZip, city, ...street] = formattedAddressParts;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, zip] = stateZip.split(" ");

            if (!zip || !parseInt(zip)) {
                return setError(
                    "Couldn't get the postal code of the selected address. Please, try something more specific",
                );
            }

            if (street.length === 0) {
                return setError("Your address must include a street");
            }

            const sanitizedStreet = street.reverse().join(" ");

            const sanitizedAddress = [
                sanitizedStreet,
                city,
                stateZip,
                country,
            ].join(",");

            setAddress(sanitizedAddress);

            if (!latLng || !latLng.lat || !latLng.lng) {
                return setError("Failed to fetch geo-location.");
            }

            const geolocation = {
                type: "Point",
                coordinates: [latLng.lng, latLng.lat],
            } as Geolocation;

            // If the user is logged in, attempt to update their address
            // and geolocation, but fail silently otherwise
            if (user && user._id) {
                await Api.me.update({
                    address: sanitizedAddress,
                    geolocation,
                });
                if (geolocation && geolocation?.coordinates.length === 2) {
                    dispatch(updateMyLocation(geolocation));
                }
            }

            // Note: we need to set the cart address (so delivery address is sent to server correctly)
            // AND the address and geolocation in redux because that is what is used
            // to determine if customer is out of delivery range
            dispatch(CartActions.setCartAddress(sanitizedAddress));
            geolocation && dispatch(updateMyLocation(geolocation));
        } catch (err) {
            setError("Failed to update address.");
        } finally {
            setLoading(false);
        }
    };

    const updateUserAddressLineTwo = (
        lineTwo: string,
        deliveryAddress: string,
    ) => {
        const fullAddress = addLine2ToAddress(deliveryAddress, lineTwo);
        dispatch(CartActions.setCartAddress(fullAddress));
    };

    const onSelectAddress = async (address: string): Promise<void> => {
        // Update address when an address is selected so user can't modify
        // the address with second address lineand screw up Chowly fields.
        // TODO: make it so second address field is possible.
        await updateUserAddress(address);
    };

    return (
        <div className="mb-2">
            <div className="flex flex-row leading-6 items-center mb-3">
                {" "}
                <PinIcon className="w-[20px] h-[20px] mr-2 fill-secondary" />{" "}
                <span className="text-base leading-6 text-primary font-medium">
                    Address
                </span>
            </div>
            {loading ? (
                <div className="flex flex-1 items-center justify-center mt-8 mb-2">
                    <BeatLoader size={7} />
                </div>
            ) : (
                <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={(address: string) => {
                        onSelectAddress(address);
                    }}
                    searchOptions={{
                        componentRestrictions: { country: "us" },
                        sessionToken,
                    }}
                    debounce={500}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <div>
                            <div>
                                <input
                                    className="w-full outline-none border p-3 rounded-lg"
                                    {...getInputProps({
                                        placeholder: "Delivery Address",
                                    })}
                                />
                                <div className="w-full relative">
                                    <div className="absolute z-[1000] top-0 left-0 right-0 w-full">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                            const addProps = suggestion.active
                                                ? {
                                                      className:
                                                          "suggestion-item--active",
                                                      style: {
                                                          backgroundColor:
                                                              "#fafafa",
                                                          cursor: "pointer",
                                                      },
                                                  }
                                                : {
                                                      className:
                                                          "suggestion-item",
                                                      style: {
                                                          backgroundColor:
                                                              "#ffffff",
                                                          cursor: "pointer",
                                                      },
                                                  };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(
                                                        suggestion,
                                                        addProps,
                                                    )}
                                                    className="border border-b-1 border-neutral-2-light py-2 pl-2"
                                                    key={suggestion.placeId}
                                                >
                                                    <span>
                                                        {suggestion.description}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <input
                                className="w-full mt-3 outline-none border p-3 rounded-lg"
                                placeholder="Apt, Floor, Suite, ect, (Optional)"
                                value={addressLineTwo}
                                onChange={(e) => {
                                    updateUserAddressLineTwo(
                                        e.target.value,
                                        deliveryAddress,
                                    );
                                }}
                            />
                            {error && (
                                <div className="block mt-[10px] px-1 text-start text-red-400">
                                    {error}
                                </div>
                            )}
                        </div>
                    )}
                </PlacesAutocomplete>
            )}
        </div>
    );
};

export default DeliveryAddress;
