/* Actions */
export const SET_ACTIVE_STORE_ON_MAP = "SET_ACTIVE_STORE_ON_MAP";
export const BOUND_MAP_TO_ALL_STORES = "BOUND_MAP_TO_ALL_STORES";

export type SET_ACTIVE_STORE_ON_MAP = typeof SET_ACTIVE_STORE_ON_MAP;
export type BOUND_MAP_TO_ALL_STORES = typeof BOUND_MAP_TO_ALL_STORES;

export type SetActiveStoreOnMap = {
    type: SET_ACTIVE_STORE_ON_MAP;
    activeStoreId: string;
};

export type BoundMapToAllStores = {
    type: BOUND_MAP_TO_ALL_STORES;
    boundToAll: boolean;
};

export const setActiveStoreOnMap = (
    activeStoreId: string,
): SetActiveStoreOnMap => ({
    type: SET_ACTIVE_STORE_ON_MAP,
    activeStoreId,
});

export const boundMapToAllStores = (
    boundToAll: boolean,
): BoundMapToAllStores => ({
    type: BOUND_MAP_TO_ALL_STORES,
    boundToAll,
});

/* Reducer */
export interface CorporateMapState {
    activeStoreId: string | null;
    boundToAllStores: boolean;
    userLocation: {
        latitude: number;
        longitude: number;
    };
}

export const CorporateMapInitialState: CorporateMapState = {
    activeStoreId: null,
    boundToAllStores: false,
    userLocation: {
        latitude: 0,
        longitude: 0,
    },
};

export default function corporateMap(
    state = CorporateMapInitialState,
    action: SetActiveStoreOnMap | BoundMapToAllStores,
): CorporateMapState {
    switch (action.type) {
        case SET_ACTIVE_STORE_ON_MAP:
            return {
                ...state,
                activeStoreId: action.activeStoreId,
            };
        case BOUND_MAP_TO_ALL_STORES:
            return {
                ...state,
                boundToAllStores: action.boundToAll,
            };
        default:
            return state;
    }
}

/* Selectors */
export const getActiveStoreIdOnMap = (state: {
    corporateMap: CorporateMapState;
}) => state.corporateMap.activeStoreId;

export const getBoundToAllStores = (state: {
    corporateMap: CorporateMapState;
}) => state.corporateMap.boundToAllStores;
