import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSetRewardPromos } from "@snackpass/loyalty-react";
import { extractNameAndPoints } from "@snackpass/loyalty";

import * as actions from "../redux";
import API from "@/utils/Api/REST";
import { sendError } from "@/utils/Errors";
import {
    getUser,
    SUPPORTED_PROMOTION_TYPES,
    SUPPORTED_PROMOTIONS,
} from "../redux";
import { isDiscountAndDealEnabled } from "@/statsig/featureGates";

import { useIsDGCEnabled } from "./useIsDGCEnabled";

// fetching user's eligible promotions (Discount + Deal) if user logged in
// if user is not logged in we fetch promotion summary (Discount + Deal + Reward)

export function usePromotions() {
    const activeStore = useSelector(actions.getActiveStore);
    const user = useSelector(getUser);
    const userId = user?._id;
    const dispatch = useDispatch();
    const storeId = activeStore?._id;
    const isUserLoggedIn = useSelector(actions.getUserIsLoggedIn);
    const { setRewardPromoSummaries } = useSetRewardPromos();

    const { isDGCEnabled } = useIsDGCEnabled();

    const fetchStorePromotions = useCallback(async () => {
        try {
            if (!storeId) {
                return;
            }
            if (userId && isUserLoggedIn && isDiscountAndDealEnabled()) {
                const promotionsResponse = await API.stores.getEligiblePromos(
                    activeStore,
                    isDGCEnabled,
                );

                const dealAndDiscount =
                    promotionsResponse.data.promotions.filter(
                        (p) =>
                            SUPPORTED_PROMOTIONS.includes(p.type) &&
                            !p.isEmployeeMode, // There are 3 available types on RDB promo settings, if it's not register(isEmployeeMode) only, meaning it is available for app or OO
                    );
                dispatch(actions.setPromotions(dealAndDiscount));
            } else {
                const params = {
                    type: SUPPORTED_PROMOTION_TYPES.join(","),
                    limit: "10",
                };
                const promotionSummaryResponse =
                    await API.stores.getPromoSummary(storeId, params);
                setRewardPromoSummaries(
                    storeId,
                    extractNameAndPoints(
                        promotionSummaryResponse.data.promotionSummary,
                    ),
                );
                if (isDiscountAndDealEnabled()) {
                    dispatch(
                        actions.setPromotions(
                            promotionSummaryResponse.data.details.previewPromotions.filter(
                                (p) =>
                                    SUPPORTED_PROMOTIONS.includes(p.type) &&
                                    !p.isEmployeeMode, // There are 3 available types on RDB promo settings, if it's not register(isEmployeeMode) only, meaning it is available for app or OO
                            ),
                        ),
                    );
                }
            }
        } catch (err) {
            sendError(err);
        }
    }, [
        storeId,
        userId,
        isUserLoggedIn,
        activeStore,
        dispatch,
        setRewardPromoSummaries,
    ]);

    useEffect(() => {
        fetchStorePromotions();
    }, [fetchStorePromotions]);
}
