import { call, select } from "typed-redux-saga";

import { CartSelectors, CartItemWithTaxInfo } from "@snackpass/accounting";
import { Fulfillment, FulfillmentTypeEnum } from "@snackpass/snackpass-types";

import { CartConflict } from "#cart/slice";

export function* legacyCartValidationSaga(fulfillment: Fulfillment) {
    const cart = yield* select(CartSelectors.getCart);

    return yield* call(findConflicts, cart.items, fulfillment);
}

const findConflicts = (
    cartItems: CartItemWithTaxInfo[],
    fulfillment: Fulfillment,
): CartConflict[] =>
    cartItems
        .map((item, index) => ({
            item,
            cartIndex: index,
            reason: {
                type: "fulfillment" as const,
                fulfillment,
            },
        }))
        .filter(({ item }) => isConflict(item, fulfillment));

const isConflict = (item: CartItemWithTaxInfo, fulfillment: Fulfillment) => {
    const fulfillmentMethods = item.product.fulfillmentMethods;
    const fulfillmentMap = {
        [FulfillmentTypeEnum.Delivery]: fulfillmentMethods.isDelivery,
        [FulfillmentTypeEnum.Pickup]: fulfillmentMethods.isPickup,
        [FulfillmentTypeEnum.DineIn]: fulfillmentMethods.isDineIn,
    };

    return !fulfillmentMap[fulfillment];
};
