import { IStore } from "@snackpass/snackpass-types";
import fp from "lodash/fp";
import { createSelector } from "@reduxjs/toolkit";

import Api from "src/utils/Api/REST";
import { arrToObj } from "src/utils/Helpers";
import { sendError } from "src/utils/Errors";
import { AppDispatch } from "@/redux/STORE";

export const SET_ACTIVE_CHAIN_STORES = "SET_ACTIVE_CHAIN_STORES";
export const SET_ACTIVE_CHAIN_STORES_STATUS = "SET_ACTIVE_CHAIN_STORES_STATUS";

export type SET_ACTIVE_CHAIN_STORES = typeof SET_ACTIVE_CHAIN_STORES;
export type SET_ACTIVE_CHAIN_STORES_STATUS =
    typeof SET_ACTIVE_CHAIN_STORES_STATUS;

export type ActiveChainStoresStatus =
    | "NOT LOADED"
    | "LOADING"
    | "SUCCESS"
    | "ERROR";

// ==== ACTIONS ====//
export const setActiveChainStores = (stores: IStore[]) => {
    return { type: SET_ACTIVE_CHAIN_STORES, stores: stores } as const;
};
export function setActiveChainStoresStatus(
    status: ActiveChainStoresStatus,
    error?: Error,
) {
    return { type: SET_ACTIVE_CHAIN_STORES_STATUS, status, error } as const;
}

export type FetchStoresForChainQuery = {
    chainId: string;
    lat?: number;
    lng?: number;
    inferLocation?: boolean;
};

export function fetchStoresForChainThunk(query: FetchStoresForChainQuery) {
    return (dispatch: AppDispatch) => {
        dispatch(setActiveChainStoresStatus("LOADING"));

        const handleReponse = (res) => {
            const stores = res.data.stores;
            // confusing logic
            fp.compose(
                () => dispatch(setActiveChainStoresStatus("SUCCESS")),
                dispatch,
                setActiveChainStores,
            )(stores);
            return stores;
        };

        return Api.stores
            .nearby(query)
            .then(handleReponse)
            .catch((err) => {
                dispatch(setActiveChainStoresStatus("ERROR", err));
                sendError(err);
            });
    };
}

export type SetActiveChainStoresStatus = ReturnType<
    typeof setActiveChainStoresStatus
>;
export type SetActiveChainStores = ReturnType<typeof setActiveChainStores>;

type ActiveChainStoresAction =
    | SetActiveChainStoresStatus
    | SetActiveChainStores;

// ==== REDUCER ====//
type ActiveChainStoresState = {
    stores: IStore[];
    status: ActiveChainStoresStatus;
    error?: Error;
};

export const INITIAL_STATE: ActiveChainStoresState = {
    status: "NOT LOADED",
    stores: [] as IStore[],
};

export default function activeChainStores(
    state: typeof INITIAL_STATE = INITIAL_STATE,
    action: ActiveChainStoresAction,
) {
    switch (action.type) {
        case SET_ACTIVE_CHAIN_STORES_STATUS:
            return {
                ...state,
                status: action.status,
                error: action.error,
            };
        case SET_ACTIVE_CHAIN_STORES:
            return {
                ...state,
                stores: [...action.stores],
            };
        default:
            return state;
    }
}

// ==== SELECTORS ====//
export const getActiveChainStores = (state): IStore[] =>
    state.activeChainStores.stores;
export const getActiveChainStoresStatus = (state) =>
    state.activeChainStores.status;
export const getActiveChainStoresError = (state) =>
    state.activeChainStores.error;

export const getActiveChainStoresMapping = createSelector(
    [getActiveChainStores],
    (stores) => {
        return arrToObj(stores);
    },
);
