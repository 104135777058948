import { useCallback, useEffect, useState } from "react";
import fp from "lodash/fp";
import {
    CartActions,
    CartSelectors,
    CheckoutSelectors,
    GiftCardActions,
    GiftCardSelectors,
    getActiveStoreDeliveryMin,
    getActiveStoreName,
    getActiveStorePickupMin,
} from "@snackpass/accounting";
import {
    clearUtmTracking,
    getActiveStore,
    getActiveStoreTableNumberRequired,
    getIsTipSelected,
    getUser,
    getUserFirstName,
    getUserIsLoggedIn,
    getUserLastName,
    getUtmTracking,
    setIsUpsellAlreadyShown,
    setUser,
} from "src/redux";
import { history } from "src/utils/history";
import uuid from "uuid/v4";
import { isNetworkError } from "src/utils/Api/REST";
import { sendError } from "src/utils/Errors";
import { useDispatch, useSelector } from "react-redux";
import { isValidCart } from "./utils";
import API from "@/utils/Api/graphql";
import {
    CateringStatusType,
    FulfillmentTypeEnum,
    IPurchase,
    IUser,
    WhenTypeEnum,
} from "@snackpass/snackpass-types";
import { Api } from "@/utils";
import { PaymentRequestTokenEvent } from "@stripe/stripe-js";
import _ from "lodash";
import { SegmentEventNames, Segment } from "src/utils/Segment";
import { useCheckIsCartItemsSoldOut } from "../useCheckIsCartItemsSoldOut";
import useURLParams from "@/hooks/useURLParams";
import { toast } from "sonner";
import { setRevealTotalByDefault } from "@/redux/ooCart";
import { getPendingMarketingConsent } from "@/redux/marketingConsent";

const _getMarketingMutationCallback = (
    pendingMarketingConsent: boolean | undefined,
    activeStoreId: string | undefined,
) => {
    if (pendingMarketingConsent) {
        if (activeStoreId) {
            return async () => {
                try {
                    await API.messaging.optInToMarketing(activeStoreId);
                } catch (e) {
                    sendError(e);
                }
            };
        }
    }
    return undefined;
};

const usePurchase = () => {
    const [isLoading, setLoading] = useState(false);
    const [isLoadingGiftCards, setIsLoadingGiftCards] = useState(false);
    const [fetchingQuote, setFetchingQuote] = useState(false);
    const [idempotencyKey, refreshIdempotencyKey] = useState(uuid());
    const totalPaidByCustomer = useSelector(
        CheckoutSelectors.getAmountPaidByCustomer,
    );
    const params = useURLParams();
    const fromCustomApp = params.get("fromCustomApp");
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const tableNumberRequired = useSelector(getActiveStoreTableNumberRequired);
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const tableNumber = useSelector(CartSelectors.getCartTableNumber);
    const items = useSelector(CartSelectors.getCartItems);
    const formattedPurchase = useSelector(
        CheckoutSelectors.getFormattedPurchaseWithStore,
    );
    const utmTracking = useSelector(getUtmTracking);
    const when = useSelector(CartSelectors.getCartWhen);
    const scheduledDate = useSelector(CartSelectors.getCartScheduleDate);
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const headcount = useSelector(CartSelectors.getCartHeadcount);
    const canDeliver = useSelector(CartSelectors.getCartCanDeliver);
    const deliveryError = useSelector(CartSelectors.getCartDeliveryError);
    const deliveryAddress = useSelector(CartSelectors.getCart).address;
    const deliveryQuote = useSelector(
        CartSelectors.getSelectedCartDeliveryQuote,
    );
    const cartSubtotal = useSelector(CheckoutSelectors.getSubtotal);
    const minIsReached = useSelector(CheckoutSelectors.getMinIsReached);
    const pickupMin = useSelector(getActiveStorePickupMin);
    const deliveryMin = useSelector(getActiveStoreDeliveryMin);
    const cateringMin = activeStore?.catering?.orderMinimum || 0;
    const cateringMinIsReached = isCatering && cartSubtotal >= cateringMin;
    const activeStoreName = useSelector(getActiveStoreName);
    const cartIsValidated = useSelector(CheckoutSelectors.getCartIsValidated);
    const isLoggedIn = useSelector(getUserIsLoggedIn);
    const tipIsSelected = useSelector(getIsTipSelected);
    const firstName = useSelector(getUserFirstName);
    const lastName = useSelector(getUserLastName);
    const user = useSelector(getUser);
    const _setUser = fp.compose(dispatch, setUser);
    const hasSoldOutItem = useCheckIsCartItemsSoldOut();
    const pendingMarketingConsent = useSelector(getPendingMarketingConsent);

    /**
     * Resets purchase specific state for next session.
     */
    const resetState = useCallback(() => {
        dispatch(CartActions.clearCart());
        dispatch(clearUtmTracking());
        dispatch(setIsUpsellAlreadyShown(false));
    }, [dispatch]);

    const _updateUserEmail = async (
        user: IUser,
        paymentRequestEvent: PaymentRequestTokenEvent,
    ) => {
        const shouldUpdateEmail =
            _.isEmpty(user?.email) &&
            !_.isEmpty(paymentRequestEvent?.payerEmail);

        if (!shouldUpdateEmail) {
            return;
        }
        const body = {
            ...(shouldUpdateEmail && { email: paymentRequestEvent.payerEmail }),
        };

        await Api.users
            .update(body)
            .then((res) => {
                _setUser(fp.prop("data.user", res));
                Segment.track(SegmentEventNames.UPDATED_USER_FROM_APPLE_PAY, {
                    user: user._id,
                });
            })
            .catch((err) => {
                console.log(err);
                sendError(
                    "Failed silently when trying to update user with payment request (apple/google pay) information.",
                );
            });
    };
    const _updateUserName = async () => {
        if (
            user?.name ||
            (user?.firstName && user?.lastName) ||
            (!firstName && !lastName)
        ) {
            return;
        }
        const res = await Api.users.update({
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            name: `${firstName} ${lastName}`,
        });
        _setUser(fp.prop("data.user", res));
    };

    const _formatDeliveryQuote = useCallback(() => {
        if (fulfillment !== FulfillmentTypeEnum.Delivery || !deliveryQuote) {
            return null;
        }
        return {
            deliveryInfo: {
                provider: deliveryQuote.provider,
                quoteId: deliveryQuote.quoteId ?? undefined,
            },
        };
    }, [JSON.stringify(deliveryQuote), fulfillment]);

    const _checkIdempotencyKeyOnServer = async () => {
        try {
            const idempotencyData =
                await Api.purchases.getByIdempotencyKey(idempotencyKey);
            if (idempotencyData.data.purchase?.refund) {
                return null; // technically we could send the receipt URL, but we know the purchase has failed.
            }
            return idempotencyData;
        } catch (err) {
            // may not be an error, since we are just trying to verify if the purchase was already made or not;
            return null;
        }
    };

    const completePurchase = async (
        purchase: IPurchase,
        receiptToken: string | null | undefined,
    ) => {
        Segment.track(SegmentEventNames.PURCHASE_COMPLETE, {
            store_id: activeStore?._id,
            store_name: activeStore?.name,
            store_kind: activeStore?.kind,
        });
        if (receiptToken == null) {
            let idempotencyKeyData = await _checkIdempotencyKeyOnServer();
            if (idempotencyKeyData == null) {
                // we are 100% sure if we get here that the purchase was successful, so retry once
                idempotencyKeyData = await _checkIdempotencyKeyOnServer();
            }
            if (idempotencyKeyData == null) {
                const err = new Error(
                    "purchase verification failed, but purchase was successfully created",
                );
                sendError(err);
                throw err;
            }
            receiptToken = idempotencyKeyData.data.receiptToken;
        }

        const smartReceiptURL = `/purchases/${purchase._id}/${receiptToken}/status`;

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: "purchaseComplete",
                    smartReceiptURL,
                }),
            );
        }
        if (!fromCustomApp) {
            history.push(smartReceiptURL);
        }

        resetState();
    };

    const _checkIfValidCart = () => {
        return isValidCart({
            fulfillment,
            activeStore,
            items,
            cartIsValidated,
            tipIsSelected,
            minIsReached,
            deliveryAddress,
            when,
            scheduledDate,
            tableNumber,
            tableNumberRequired,
            canDeliver,
            deliveryError,
            pickupMin,
            deliveryMin,
            cateringMinIsReached,
            cateringMin,
            isCatering,
            activeStoreName,
            hasSoldOutItem,
            totalPaidByCustomer,
            firstName,
            lastName,
        });
    };

    const manuallyAddedGiftCardsApplied:
        | { code: string; encryptedPin: string | null | undefined }[]
        | undefined = useSelector(
        CheckoutSelectors.getManuallyAddedGiftCardsApplied,
    );

    const createPurchase = async (
        user: IUser,
        paymentRequestEvent?: PaymentRequestTokenEvent,
    ) => {
        if (isLoading) {
            return;
        }
        setLoading(true);
        const post: IPurchase & {
            token: string | null;
            idempotencyKey: string | null;
            manuallyAddedGiftCardsApplied?: {
                code: string;
                encryptedPin: string | null | undefined;
            }[];
        } = {
            ...formattedPurchase,
            manuallyAddedGiftCardsApplied:
                manuallyAddedGiftCardsApplied &&
                manuallyAddedGiftCardsApplied.length !== 0
                    ? manuallyAddedGiftCardsApplied
                    : undefined,
            ...utmTracking,
            deliveryAddress,
            isOnlineOrder: true,
            transactionSource: "online",
            token: paymentRequestEvent?.token?.id || null,
            idempotencyKey,
            ..._formatDeliveryQuote(),
            ...(when === WhenTypeEnum.Later && {
                scheduledDate: scheduledDate ?? undefined,
            }),
            catering: isCatering
                ? {
                      isCatering,
                      headcount,
                      status: CateringStatusType.unconfirmed,
                  }
                : // XXX: Defaults are the same defaults defined on the purchase schema.
                  {
                      isCatering: false,
                      headcount: 1,
                      status: CateringStatusType.unconfirmed,
                  },
        };

        try {
            // Update user's name before proceeding
            await _updateUserName();
            if (paymentRequestEvent) {
                await _updateUserEmail(user, paymentRequestEvent);
            }
            const marketingCallback = _getMarketingMutationCallback(
                pendingMarketingConsent,
                activeStore?._id,
            );
            const [response, _marketingRep] = await Promise.all([
                Api.purchases.create(post),
                marketingCallback?.(),
            ]);

            const purchase = response.data.purchase;
            if (paymentRequestEvent) {
                paymentRequestEvent.complete("success");
                Segment.track(SegmentEventNames.PAID_WITH_APPLE_PAY, {
                    purchaseId: purchase._id,
                });
            }
            await completePurchase(purchase, null);
        } catch (err) {
            // paymentRequestEvent.complete unavailable 30 seconds after token issuance
            if (paymentRequestEvent?.complete) {
                paymentRequestEvent.complete("fail");
            }
            const idempotencyKeyData = await _checkIdempotencyKeyOnServer();
            if (idempotencyKeyData != null) {
                // actually the purchase was successful!
                const { purchase, receiptToken } = idempotencyKeyData.data;
                await completePurchase(purchase, receiptToken);
                return;
            }
            sendError(err);
            if (err && err.response && err.response.status === 409) {
                toast.error("Oops", {
                    description:
                        "Looks like you've already attempted to make this same order recently! Please check and make sure you haven't been charged for a previous purchase.\n\nIf you did intend to make another order, please refresh your browser and proceed with the purchase again.",
                });
                return;
            }

            if (
                err &&
                err.response &&
                err.response.data.message ===
                    "Sorry, it looks like the items in your cart are stale!"
            ) {
                // Cart prices did not match server-side prices
                toast.error("Oops", {
                    description:
                        "Sorry, the restaurant has adjusted prices of some items in your cart.\n\nPlease clear your cart and re-add all the items in order to complete your order.",
                });
                return;
            }

            // The client never received a response maybe due to server error or timeout, but the request has left
            if (err && !err.response && !err.request) {
                //This helps prevent duplicate charges, because we will not refresh the Idempotency Key with the return statement
                toast.error("Oops", {
                    description:
                        "An unexpected error occurred. Make sure you didn't get an email receipt and try again!",
                });
                return;
            }

            // Service Unavailable Error: Don't refresh idempotency key because request may have succeeded
            if (err?.response?.status === 503) {
                toast.error("Oops", {
                    description:
                        "The purchase request timed out. Make sure you didn't get an email receipt and try again!",
                });
                return;
            }

            if (isNetworkError(err)) {
                toast.error("Oops", {
                    description:
                        "Looks like the wifi's acting up. Make sure you didn't get an email receipt and try again!",
                });
                return;
            }

            const errorMessage = fp.getOr(
                "Unknown error",
                "response.data.message",
                err,
            );

            alert(errorMessage);
            refreshIdempotencyKey(uuid());
        } finally {
            setLoading(false);
        }
    };

    const { isValid, component, message, type } = _checkIfValidCart();
    useEffect(() => {
        // FIXME: add quote fetching to reducer
        const isFetchingQuote =
            fulfillment === FulfillmentTypeEnum.Delivery &&
            !deliveryQuote &&
            !message;
        setFetchingQuote(isFetchingQuote);
    }, [fulfillment, deliveryQuote, message]);

    const giftCards = useSelector(GiftCardSelectors.getGiftCards);
    const activeStoreId = activeStore?._id;

    useEffect(() => {
        const fetchGiftCards = async () => {
            const phoneNumberAddedGiftCards = giftCards.filter(
                (gc) => gc.encryptedPin == null,
            );
            if (phoneNumberAddedGiftCards.length !== 0) {
                // Since gift cards are wiped on logout, if there are already phone number based
                // gift cards then we should not fetch again.
                return;
            }
            if (isLoggedIn) {
                setIsLoadingGiftCards(true);
                const giftCardsActiveByUserAndStore =
                    await API.giftCards.getActiveByUserAndStore(
                        activeStoreId || "",
                    );
                if (giftCardsActiveByUserAndStore.length !== 0) {
                    // Retain manually added gift cards, distinguished by an encrypted pin existing
                    const manuallyAddedGiftCards = giftCards.filter(
                        (gc) => gc.encryptedPin != null,
                    );
                    dispatch({
                        type: GiftCardActions.SET_GIFTCARDS,
                        giftCards: [
                            ...giftCardsActiveByUserAndStore,
                            ...manuallyAddedGiftCards,
                        ],
                    });
                    // show total revealed so that the user sees they are using gift cards
                    dispatch(setRevealTotalByDefault(true));
                }
            }
            setIsLoadingGiftCards(false);
        };
        fetchGiftCards();
    }, [isLoggedIn, dispatch, activeStoreId, giftCards]);

    return {
        createPurchase,
        fetchingQuote,
        isLoadingGiftCards,
        isValid,
        message,
        component,
        type,
        isLoading,
    };
};

export default usePurchase;
