import { CartAdjustmentType } from "@snackpass/snackpass-types";
import { v4 as uuidv4 } from "uuid";

import { attachTaxInfoTo } from "../../accountingFormulas/attachTaxInfoTo";

import { ICart, DealGroup } from "../../types";

import {
    CartActions,
    CLEAR_CART,
    CLEAR_CART_BUT_NOT_FULFILLMENT,
    ADD_CART_ITEM,
    DUPLICATE_CART_ITEM_BY_INDEX,
    ADD_DEAL_GROUP,
    ADD_DEAL_ITEM,
    REMOVE_DEAL_GROUP,
    REMOVE_SINGLE_DEAL_ITEM,
    CLEAR_CART_ITEMS,
    SET_CART_TIP,
    SET_CART_NOTES,
    SET_CART_FULFILLMENT,
    SET_CART_ITEMS,
    SET_CART_ADDRESS,
    SET_USE_CREDIT,
    REMOVE_CART_ITEM,
    UPDATE_CART_ITEM,
    SET_CART_WHEN,
    SET_CART_CATERING,
    SET_CART_CATERING_EVENT,
    SET_CART_CATERING_HEADCOUNT,
    SET_CART_CATERING_SCHEDULE_DATE,
    SET_CART_STORE,
    REMOVE_CART_ITEMS_BY_GROUP_UUID,
    SET_CART_TABLE_NUMBER,
    CLEAR_CART_TABLE_NUMBER,
    SET_PICKUP_ESTIMATE,
    CLEAR_PICKUP_ESTIMATE,
    SET_CART_DELIVERY_QUOTES,
    CLEAR_CART_DELIVERY_QUOTES,
    SELECT_CART_DELIVERY_QUOTE,
    CLEAR_SELECTED_CART_DELIVERY_QUOTE,
    SET_CART_STORE_CAN_DELIVER,
    SET_CART_DELIVERY_ERROR,
    SET_NUMBER_OF_BAGS,
    ENTER_CATERING_MODE,
    EXIT_CATERING_MODE,
    ADD_CART_ADJUSTMENT,
    REMOVE_CART_ADJUSTMENT_BY_UUID,
    CLEAR_CART_DISCOUNTS,
    CLEAR_CART_ADJUSTMENTS,
    UPDATE_CART_ADJUSTMENT_BY_UUID
} from "./actions";
import { preSelectedAddons } from "../../utils/preSelectAddons";

export type CartReducer = ICart;

export const cartInitialState: CartReducer = {
    items: [],
    dealGroups: [],
    notes: "",
    tip: 0,
    fulfillment: "PICKUP",
    address: "",
    useCredit: true, // For non-app clients, make sure to setUseCredit(false)!
    when: "NOW",
    store: null,
    selectedDeliveryQuote: null,
    deliveryQuotes: [],
    canDeliver: false,
    deliveryError: null,
    numberOfBags: 0,
    isCatering: false,
    headcount: 1
};

export function cart(
    state = cartInitialState,
    action: CartActions
): CartReducer {
    switch (action.type) {
        case CLEAR_CART:
            return { ...cartInitialState };
        case CLEAR_CART_BUT_NOT_FULFILLMENT:
            return { ...cartInitialState, fulfillment: state.fulfillment };
        case ADD_CART_ITEM:
        case DUPLICATE_CART_ITEM_BY_INDEX: {
            const item =
                action.type === ADD_CART_ITEM
                    ? action.item
                    : [...state.items][action.cartItemIndex];
            const itemWithTaxInfo = attachTaxInfoTo(item, action.store);
            // Kiosk should not increase # of bags when item is added
            const bags = state.store?.defaultBagInCart
                ? state.store?.defaultBagInCart
                : 0;

            // if reward, remove promotion
            if (itemWithTaxInfo.reward) {
                delete itemWithTaxInfo.promotion;
            }

            // Auto select "pre-selected" addons when no addons are _already_ selected.
            if (
                !itemWithTaxInfo.selectedAddons ||
                itemWithTaxInfo.selectedAddons.length === 0
            ) {
                itemWithTaxInfo.selectedAddons =
                    preSelectedAddons(itemWithTaxInfo);
            }

            return {
                ...state,
                items: [...state.items, itemWithTaxInfo],
                store: action.store,
                numberOfBags: Math.max(bags, state.numberOfBags)
            };
        }
        case REMOVE_CART_ITEM: {
            const items = [...state.items];
            const itemToRemove = items[action.cartItemIndex];
            if (itemToRemove?.dealItem && itemToRemove.dealGroupId) {
                //item belong to deal group
                const dealGroups = [...state.dealGroups];
                const dealGroupId = itemToRemove.dealGroupId;
                const dealGroupIdx = dealGroups.findIndex(
                    (dg) => dg.id === dealGroupId
                );
                dealGroups.splice(dealGroupIdx, 1);
                const items = [...state.items];
                // remove dealGroup items from cart in reverse order
                for (let i = items.length - 1; i >= 0; i--) {
                    if (
                        items[i].dealGroupId &&
                        items[i].dealGroupId === dealGroupId
                    ) {
                        items.splice(i, 1);
                    }
                }
                return { ...state, dealGroups, items };
            } else {
                items.splice(action.cartItemIndex, 1);
                return { ...state, items: items };
            }
        }
        case REMOVE_CART_ITEMS_BY_GROUP_UUID: {
            return {
                ...state,
                items: state.items.filter(
                    (item) => item.groupUuid !== action.groupUuid
                )
            };
        }
        case ADD_DEAL_GROUP: {
            const { selectedDealItems, promotion } = action;
            const newDealGroupId = uuidv4();
            const newDealGroup: DealGroup = {
                items: Object.keys(selectedDealItems).map(
                    (key) => selectedDealItems[key]
                ),
                promotion,
                id: newDealGroupId,
                name: promotion.name
            };

            const dealGroups = [...state.dealGroups];

            // add dealGroupId to each cart item in cart.dealGroups array
            newDealGroup.items.forEach((item) => {
                item.cartItem.dealGroupId = newDealGroup.id;
            });
            dealGroups.push(newDealGroup);

            // also add dealGroupId to cart.items
            const items = [...state.items];
            const newCartItems = newDealGroup.items.map((item) => ({
                ...item.cartItem,
                dealGroupId: newDealGroup.id
            }));
            items.push(...newCartItems);
            return { ...state, dealGroups, items };
        }
        case ADD_DEAL_ITEM: {
            const { dealGroupIndex, item } = action;
            if (!state.dealGroups[dealGroupIndex]) return state;

            const dealGroups = [...state.dealGroups];

            // Update/replace the deal item on the specified deal group index
            dealGroups[dealGroupIndex] = {
                ...dealGroups[dealGroupIndex],
                items: [
                    ...dealGroups[dealGroupIndex].items.filter(
                        ({ dealItem }) => dealItem._id !== item.dealItem._id
                    ),
                    item
                ]
            };

            // Remove the replaced deal item associated with this deal group
            // and add deal item to cart
            const items = [
                ...state.items.filter(
                    (cartItem) =>
                        cartItem.dealGroupId !==
                            dealGroups[dealGroupIndex].id ||
                        cartItem.dealItem?._id !== item.dealItem._id
                ),
                item.cartItem
            ];

            return { ...state, dealGroups, items };
        }
        case REMOVE_DEAL_GROUP: {
            const updatedDealGroups = [...state.dealGroups];
            const dealGroup = updatedDealGroups[action.dealGroupIndex];
            const dealGroupId = dealGroup.id;
            updatedDealGroups.splice(action.dealGroupIndex, 1);

            const items = [...state.items];
            // remove dealGroup items from cart in reverse order
            for (let i = items.length - 1; i >= 0; i--) {
                if (
                    items[i].dealGroupId &&
                    items[i].dealGroupId === dealGroupId
                ) {
                    items.splice(i, 1);
                }
            }
            return { ...state, dealGroups: updatedDealGroups, items };
        }
        case REMOVE_SINGLE_DEAL_ITEM: {
            // remove the deal group from state.dealGroup
            const updatedDealGroups = [...state.dealGroups];
            const dealGroup = updatedDealGroups[action.dealGroupIndex];
            const dealGroupId = dealGroup.id;
            updatedDealGroups.splice(action.dealGroupIndex, 1);

            // remove the deal item from state.items
            let items = [...state.items];
            const cartItemIndex = items.findIndex(
                (item) =>
                    item.dealGroupId === dealGroupId &&
                    item.dealItem?._id === action.dealItemId
            );
            if (cartItemIndex === -1) {
                return { ...state };
            }
            items.splice(cartItemIndex, 1);

            // mark rest of the deal items that in the same deal group as normal item(remove promotion, dealGroup and reward...)
            items = items.map((item) => {
                if (item.dealGroupId !== dealGroupId) {
                    return item;
                }
                return {
                    ...item,
                    promotion: undefined,
                    dealItem: undefined,
                    dealGroupId: undefined
                };
            });

            return { ...state, dealGroups: updatedDealGroups, items };
        }
        case CLEAR_CART_ITEMS:
            return { ...state, items: [], dealGroups: [], notes: "" };
        case SET_CART_TIP:
            return { ...state, tip: action.tip };
        case SET_CART_NOTES:
            return { ...state, notes: action.notes };
        case SET_CART_ADDRESS:
            return { ...state, address: action.address };
        case SET_CART_FULFILLMENT: {
            let tip = state.tip;

            // iff switching fulfillment from delivery to pickup, reset tip to
            // 0. This way, the auto-selected delivery tip does not carry over
            // to pickup. We do not want tip reset if fulfillment does not change
            //
            if (
                state.fulfillment === "DELIVERY" &&
                action.fulfillment === "PICKUP"
            ) {
                tip = 0;
            }
            return { ...state, fulfillment: action.fulfillment, tip };
        }
        case SET_CART_ITEMS:
            return {
                ...state,
                items: action.items.map((item) =>
                    attachTaxInfoTo(item, action.store)
                )
            };
        case SET_USE_CREDIT:
            return Object.assign({}, state, {
                useCredit: action.useCredit
            });
        case UPDATE_CART_ITEM: {
            const itemWithTaxInfo = attachTaxInfoTo(action.item, action.store);

            const items = state.items.map((item, index) => {
                return index === action.itemIndex ? itemWithTaxInfo : item;
            });

            return { ...state, items, store: action.store };
        }
        case SET_CART_WHEN:
            return {
                ...state,
                when: action.when
            };
        case SET_CART_CATERING:
            return {
                ...state,
                isCatering: action.isCatering
            };
        case SET_CART_CATERING_EVENT:
            return {
                ...state,
                eventType: action.eventType
            };
        case SET_CART_CATERING_HEADCOUNT:
            return {
                ...state,
                headcount: action.headcount
            };
        case SET_CART_CATERING_SCHEDULE_DATE:
            return {
                ...state,
                scheduledDate: action.scheduledDate
            };
        case SET_CART_STORE:
            return {
                ...state,
                store: action.store
            };
        case SET_CART_TABLE_NUMBER:
            return {
                ...state,
                tableNumber: action.tableNumber
            };
        case CLEAR_CART_TABLE_NUMBER: {
            const newState = { ...state };
            delete newState.tableNumber;
            return newState;
        }
        case SET_PICKUP_ESTIMATE:
            return {
                ...state,
                pickupEstimate: action.pickupEstimate
            };
        case CLEAR_PICKUP_ESTIMATE: {
            const newState = { ...state };
            delete newState.pickupEstimate;
            return newState;
        }
        case SET_CART_DELIVERY_QUOTES:
            return { ...state, deliveryQuotes: [...action.deliveryQuotes] };
        case CLEAR_CART_DELIVERY_QUOTES: {
            return {
                ...state,
                selectedDeliveryQuote: null,
                deliveryQuotes: []
            };
        }
        case SELECT_CART_DELIVERY_QUOTE: {
            return {
                ...state,
                selectedDeliveryQuote: action.deliveryQuote
            };
        }
        case CLEAR_SELECTED_CART_DELIVERY_QUOTE: {
            return { ...state, selectedDeliveryQuote: null };
        }
        case SET_CART_STORE_CAN_DELIVER:
            return {
                ...state,
                canDeliver: action.canDeliver
            };
        case SET_CART_DELIVERY_ERROR:
            return {
                ...state,
                deliveryError: action.error
            };
        case SET_NUMBER_OF_BAGS:
            return {
                ...state,
                numberOfBags: action.numberOfBags
            };
        case ENTER_CATERING_MODE:
            return {
                ...state,
                when: "LATER",
                isCatering: true,
                scheduledDate: null,
                headcount: 1
            };
        case EXIT_CATERING_MODE:
            return {
                ...state,
                when: "NOW",
                isCatering: false,
                scheduledDate: null,
                fulfillment: "PICKUP",
                headcount: 1
            };
        case ADD_CART_ADJUSTMENT:
            return {
                ...state,
                cartAdjustments: state.cartAdjustments
                    ? [...state.cartAdjustments, action.cartAdjustment]
                    : [action.cartAdjustment]
            };
        case UPDATE_CART_ADJUSTMENT_BY_UUID: {
            return {
                ...state,
                cartAdjustments: state.cartAdjustments?.map((adjustment) =>
                    adjustment.uuid === action.uuid
                        ? action.updatedAdjustment
                        : adjustment
                )
            };
        }
        case REMOVE_CART_ADJUSTMENT_BY_UUID:
            return {
                ...state,
                cartAdjustments: state.cartAdjustments?.filter(
                    (adjustment) => adjustment.uuid != action.uuid
                )
            };
        case CLEAR_CART_DISCOUNTS:
            return {
                ...state,
                cartAdjustments: state.cartAdjustments?.filter(
                    (adjustment) =>
                        adjustment.type !== CartAdjustmentType.DiscountFlat &&
                        adjustment.type !== CartAdjustmentType.DiscountPercent
                )
            };
        case CLEAR_CART_ADJUSTMENTS:
            return {
                ...state,
                cartAdjustments: []
            };
        default:
            return state;
    }
}
