import { IStore } from "@snackpass/snackpass-types";
import { isNil } from "lodash";

import { CartItem, CartItemWithTaxInfo } from "../types";
import { calcCartItemPrices } from "./formulas";

/**
 * Annotate a cart item with tax information; this is a transformation from
 * CartItem to CartItemWithTaxInfo.
 */

export const attachTaxInfoTo = (
    item: CartItem,
    store: IStore | null
): CartItemWithTaxInfo => {
    // Use special tax rate per item if available; default is store tax rate.
    const rate = item.product.taxInfo?.rate;
    const taxInfo = isNil(rate) ? null : { rate };

    const taxPolicies = item.product.taxPolicies ?? [];

    // Prices are usually calculated when cart items are flattened for
    // checkout, but that can happen to any arbitrary cart -- calculating this
    // here is maybe odd, or might feel redundant, but it's fine.
    const { totalPriceAfterDiscount } = calcCartItemPrices(item);

    return {
        ...item,
        taxInfo,
        taxPolicies,
        amountAfterDiscountInDollars: totalPriceAfterDiscount
    };
};
