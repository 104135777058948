import * as Helpers from "../utils/Helpers";
import { createSelector } from "@reduxjs/toolkit";
import { getCartIsCatering } from "@snackpass/accounting/build/src/redux/cart/selectors";
import {
    selectCategories,
    selectCateringCategories,
    selectProductDictionary,
} from "#menu";

export const SEARCH_FOR_ITEM = "SEARCH_FOR_ITEM";

export type SEARCH_FOR_ITEM = typeof SEARCH_FOR_ITEM;

export function searchForItem(item: string) {
    return { type: SEARCH_FOR_ITEM, item } as const;
}

export type SearchForItem = ReturnType<typeof searchForItem>;

let INITIAL_STATE = { itemSearchParameter: "" };

export default function itemSearch(
    state = INITIAL_STATE,
    action: SearchForItem,
) {
    switch (action.type) {
        case SEARCH_FOR_ITEM:
            return { ...state, itemSearchParameter: action.item };
        default:
            return state;
    }
}

export const getItemSearchParameter = (state: {
    itemSearch: typeof INITIAL_STATE;
}) => state.itemSearch.itemSearchParameter;

export const getFilteredProducts = createSelector(
    [
        selectProductDictionary,
        getItemSearchParameter,
        getCartIsCatering,
        selectCategories,
        selectCateringCategories,
    ],
    (
        productsDict,
        searchParameter,
        isCatering,
        categories,
        cateringCategories,
    ) => {
        const products = (isCatering ? cateringCategories : categories).flatMap(
            (category) => category.productIds.map((id) => productsDict[id]),
        );

        return Helpers.filterProductsForItem(products, searchParameter);
    },
);
