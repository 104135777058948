import { DateTime } from "luxon";

import type { LocalHours, TimeOfDayRange } from "src/types";

export const weekdayHours = ({ weekdays }: LocalHours, dateTime: DateTime) =>
    (weekdays[dateTime.weekday] ?? []) as TimeOfDayRange[];

export const dateHours = ({ dates }: LocalHours, dateTime: DateTime) =>
    dates?.find(({ date }) => dateTime.set(date).hasSame(dateTime, "day"))
        ?.hours;

/**
 * Returns whether the unix time is contained within the LocalHours.
 * By default, timeMs is `now()` in LocalHours's timezone.
 */
export const containsTime =
    (hours: LocalHours) =>
    (timeMs = DateTime.now().toMillis()): boolean => {
        const dateTime = DateTime.fromMillis(timeMs).setZone(hours.zone);
        const dates = dateHours(hours, dateTime);
        const weekdays = weekdayHours(hours, dateTime);
        return (dates ?? weekdays).some(
            ({ start, end }) =>
                dateTime.set({ ...start, second: 0 }) <= dateTime &&
                dateTime < dateTime.set({ ...end, second: 0 })
        );
    };

/**
 * Returns whether the unix time is contained within the LocalHours.dates.
 * By default, timeMs is `now()` in LocalHours's timezone.
 */
export const containsDateHours =
    (hours: LocalHours) =>
    (timeMs = DateTime.now().toMillis()): boolean => {
        const dateTime = DateTime.fromMillis(timeMs).setZone(hours.zone);
        const dates = dateHours(hours, dateTime);
        return (dates?.length ?? 0) > 0;
    };

/**
 * Returns whether the unix time's local date exists in the LocalHours.dates.
 * By default, timeMs is `now()` in LocalHours's timezone.
 */
export const hasDateHours =
    (hours: LocalHours) =>
    (timeMs = DateTime.now().toMillis()): boolean => {
        const dateTime = DateTime.fromMillis(timeMs).setZone(hours.zone);
        const dates = dateHours(hours, dateTime);
        return dates !== undefined;
    };
