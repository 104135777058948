import React, { useMemo, useState, useEffect } from "react";
import fp from "lodash/fp";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest, PaymentRequestTokenEvent } from "@stripe/stripe-js";
import { Segment, SegmentEventNames } from "src/utils/Segment";

export const useOptions = (paymentRequest) => {
    const options = useMemo(
        () => ({
            paymentRequest,
        }),
        [paymentRequest]
    );

    return options;
};

type Props = {
    options: any;
    onSuccess: (e: PaymentRequestTokenEvent) => Promise<void>;
};

export const usePaymentRequest = ({ options, onSuccess }: Props) => {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
        null
    );
    const [isApplePayAvailable, setIsApplePayAvailable] =
        useState<boolean>(false);

    useEffect(() => {
        if (stripe && paymentRequest === null) {
            const pr = stripe.paymentRequest(options);
            setPaymentRequest(pr);
        }
    }, [stripe, options, paymentRequest]);

    useEffect(() => {
        let subscribed = true;
        if (paymentRequest) {
            paymentRequest.canMakePayment().then((res) => {
                if (res && subscribed) {
                    setIsApplePayAvailable(fp.get("applePay", res));
                }
            });
        }

        return () => {
            subscribed = false;
        };
    }, [paymentRequest, onSuccess]);

    useEffect(() => {
        if (paymentRequest) {
            paymentRequest.on("token", async (e: PaymentRequestTokenEvent) => {
                onSuccess(e);
                Segment.track(SegmentEventNames.OPENED_APPLE_PAY);
            });
        }
        return () => {
            if (paymentRequest) {
                paymentRequest.off("token");
            }
        };
    }, [paymentRequest, onSuccess]);

    return isApplePayAvailable ? paymentRequest : null;
};
