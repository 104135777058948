import React, { useEffect, useRef, useContext, useMemo } from "react";
import { CartSelectors, Time } from "@snackpass/accounting";
import { IDateTimeRangeSchema, WhenTypeEnum } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { firebaseDatabase } from "src/firebase";
import { useTimeout } from "src/hooks/useTimeout";
import { getActiveStore } from "src/redux";
import Times from "./Times";
import WeekDays from "./WeekDays";
import { ScheduleContext } from "../../ScheduleModalContext";
import { useScheduling } from "@/hooks/useScheduling";
import { DateTime, Duration } from "luxon";
import { computeWeekDates } from "../../helper";
import { dayMinutes2WeekMinutes, splitCrossRange } from "@/utils/Time";
import _ from "lodash";

const MONTHS = 12;

const ScheduleContent = () => {
    const {
        modalWhen,
        modalSelectedWeekDay,
        setModalSelectedWeekDay,
        modalSelectedTime,
        setModalSelectedTime,
    } = useContext(ScheduleContext);
    const scheduledDate = useSelector(CartSelectors.getCartScheduleDate);
    const scrollTimeout = useTimeout();
    const timeRef = useRef<HTMLDivElement>(null);
    const activeStore = useSelector(getActiveStore);
    const isCatering = useSelector(CartSelectors.getCartIsCatering);

    const hasScheduledOrders = activeStore?.hasScheduledOrders;

    // default for catering minLeadTime must be 2 days or 48 hours
    const cateringOffsetDays = Duration.fromObject({
        hour: activeStore?.catering.minLeadTime ?? 48,
    }).shiftTo("days").days;

    const minCateringDate = DateTime.now()
        .startOf("day")
        .plus({ days: cateringOffsetDays })
        .toJSDate();

    // default for user's limit to order is 12 months from today
    // so we also need to subtract the store's minLeadTime so it
    // doesn't get over that year interval
    const maxCateringDate = DateTime.fromJSDate(minCateringDate)
        .plus({ months: MONTHS })
        .minus({ days: cateringOffsetDays })
        .toJSDate();

    const dates = useMemo(() => {
        if (!isCatering) {
            return computeWeekDates(new Date(), 14);
        } else {
            const minDate = DateTime.fromJSDate(minCateringDate);
            const maxDate = DateTime.fromJSDate(maxCateringDate);

            const days = maxDate.diff(minDate).as("days");

            return computeWeekDates(minCateringDate, days);
        }
    }, [isCatering, minCateringDate, maxCateringDate]);

    const local = useMemo(() => {
        if (activeStore) {
            return activeStore.hours.local.flatMap((hour) =>
                splitCrossRange(hour, activeStore.hours.zone),
            );
        }
        return [];
    }, [activeStore]);

    const specialHours: IDateTimeRangeSchema[] = useMemo(() => {
        if (!activeStore || !activeStore.hours.special) return [];
        return activeStore.hours.special.map((hour) => ({
            ...hour,
            date: new Date(hour.date),
        }));
    }, [activeStore]);

    // useScheduling assumes that store hours are repeated, which is not true
    // when using special hours. here we keep a lookup of resolved hours for the
    // next X computed dates
    const resolvedHoursForWeek = useMemo(() => {
        if (!activeStore) return {};

        const jsDates = dates.map((date) =>
            DateTime.fromJSDate(date.toDate())
                .setZone("utc", { keepLocalTime: true })
                .toJSDate(),
        );

        const localDateTime = Time.resolveStoreHours(
            {
                zone: activeStore.hours.zone,
                special: specialHours,
                local: local,
            },
            { forDates: jsDates },
        );

        return _.chain(localDateTime.dateTimeHours)
            .map((hour) => ({
                week: DateTime.fromJSDate(hour.date, { zone: "utc" })
                    .startOf("week")
                    .toFormat("yyyy-MM-dd"),
                start: dayMinutes2WeekMinutes(hour.date, hour.start),
                end: dayMinutes2WeekMinutes(hour.date, hour.end),
            }))
            .groupBy((h) => h.week)
            .value();
    }, [activeStore, dates, local, specialHours]);

    const store = useMemo(() => {
        if (activeStore !== null && modalSelectedWeekDay) {
            const weekOfSelectedDay = modalSelectedWeekDay
                .clone()
                .startOf("isoWeek")
                .format("YYYY-MM-DD");
            return {
                ...activeStore,
                hours: {
                    zone: activeStore.hours.zone,
                    special: specialHours,
                    local: resolvedHoursForWeek[weekOfSelectedDay],
                },
            };
        }
        return activeStore;
    }, [activeStore, modalSelectedWeekDay, resolvedHoursForWeek, specialHours]);

    const scheduling = useScheduling({
        db: firebaseDatabase,
        store,
        date: modalSelectedWeekDay,
        isCatering: isCatering,
    });

    const setInitialScheduledDate = () => {
        const hasScheduledDate =
            scheduledDate && moment(scheduledDate).isValid();

        if (hasScheduledDate) {
            setModalSelectedWeekDay(moment(scheduledDate as Date));
            setModalSelectedTime(
                moment(scheduledDate as Date).startOf("minute"),
            );
        } else {
            // In case we are using catering, we disable selected day
            // so the user must select it from the calendar
            setModalSelectedWeekDay(
                isCatering ? null : moment().startOf("day"),
            );
            setModalSelectedTime(null);
        }
    };

    const onSelectWeekDay = (selectedDay: moment.Moment) => {
        setModalSelectedWeekDay(selectedDay);
        setModalSelectedTime(null);
        if (isCatering) scrollToTime();
    };

    const scrollToTime = () => {
        //Times need to be shown before scroll
        scrollTimeout(() => {
            timeRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }, 50);
    };

    const onSelectTime = async (selectedTime: moment.Moment) => {
        // handle fix cart in modal confirm button
        setModalSelectedTime(selectedTime);
    };

    useEffect(setInitialScheduledDate, []);

    if (!store) return null;

    return (
        <>
            <WeekDays
                isCatering={isCatering}
                // @ts-ignore
                onSelectWeekDay={onSelectWeekDay}
                // @ts-ignore
                selectedWeekDay={modalSelectedWeekDay}
                activeStore={store}
                dates={dates}
                minCateringDate={minCateringDate}
                maxCateringDate={maxCateringDate}
            />
            <div ref={timeRef}>
                {(hasScheduledOrders || isCatering) &&
                modalSelectedWeekDay &&
                modalWhen === WhenTypeEnum.Later ? (
                    <Times
                        scheduling={scheduling}
                        activeStore={store}
                        selectedWeekDay={modalSelectedWeekDay}
                        onSelectTime={onSelectTime}
                        selectedTime={modalSelectedTime}
                    />
                ) : null}
            </div>
        </>
    );
};

export default ScheduleContent;
