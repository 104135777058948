import { useEffect } from "react";
import { Persistor, persistStore } from "redux-persist";

import { STORE } from "@/redux/STORE";
import { rootSaga } from "@/redux/rootSaga";
import { registerGetState } from "@/utils/Segment";

/**
 * Global configuration / state for the Online Ordering app.
 */
export interface AppGlobals {
    /** Redux store instance. */
    store: typeof STORE;

    /** Redux persistor instance. */
    persistor: Persistor;
}

const persistor = persistStore(STORE);

export function useInitApp(): AppGlobals {
    useEffect(() => {
        if (!STORE.isInitialized) {
            // Run the root saga on mount
            STORE.runSaga(rootSaga);
            // The store is now initialized
            STORE.isInitialized = true;

            // Register the store's getState function for usage with Segment tracking
            registerGetState(STORE.getState);
        }
    }, []);

    return {
        store: STORE,
        persistor,
    };
}
