import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDealSelection } from "@/redux/deal";
import { TabsTrigger } from "@/components/ui/tabs";
import { PlusCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { IDealItem } from "@snackpass/snackpass-types";
import { DealSelectionActions } from "@snackpass/accounting";

type Props = {
    dealItem: IDealItem;
};

const DealItemLabel = ({ dealItem }: Props) => {
    const selectedDealItems = useSelector(getDealSelection).selectedDealItems;
    const dispatch = useDispatch();
    const handlePressDealItemLabel = (item: IDealItem) => {
        // TODO: add segment event
        dispatch(DealSelectionActions.setActiveDealItem(item));
    };
    const handleRemoveSelectedDealItem = () => {
        dispatch(DealSelectionActions.removeDealItem(dealItem._id));
    };

    const isSelected = selectedDealItems[dealItem._id];
    const label = isSelected
        ? selectedDealItems[dealItem._id]?.cartItem?.product.name
        : dealItem.name;

    return (
        <TabsTrigger
            className="mx-1 py-2 my-1 data-[state=active]:bg-black data-[state=active]:text-white data-[state=inactive]:bg-tertiary data-[state=inactive]:text-primary !outline-none !focus:outline-none border rounded-lg font-medium whitespace-nowrap flex-nowrap "
            value={dealItem._id}
            onClick={() => {
                handlePressDealItemLabel(dealItem);
            }}
        >
            {label}
            {isSelected ? (
                <CrossCircledIcon
                    className="w-4 h-4 p-[1px] ml-2"
                    onClick={handleRemoveSelectedDealItem}
                />
            ) : (
                <PlusCircledIcon className="w-4 h-4 p-[1px] ml-2" />
            )}
        </TabsTrigger>
    );
};

export default DealItemLabel;
