import { IUser } from "@snackpass/snackpass-types";

import { createSelector } from "@reduxjs/toolkit";

export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CLEAR_USER = "CLEAR_USER";

export type SET_USER = "SET_USER";
export type UPDATE_USER = "UPDATE_USER";
export type CLEAR_USER = "CLEAR_USER";

export type SetUser = {
    type: SET_USER;
    user: IUser;
};

export const setUser = (user: IUser): SetUser => {
    return { type: SET_USER, user };
};

export type ClearUser = {
    type: CLEAR_USER;
};

export const clearUser = (): ClearUser => {
    return { type: CLEAR_USER };
};

type UserState = IUser | null;

const INITIAL_STATE: UserState = null;

export const getUser = (state: { user: UserState }) => state.user;

export const getUserUid = createSelector(
    [getUser],
    (user) => user?.uid ?? null,
);

export const getUserAddress = createSelector(
    [getUser],
    (user) => user?.address ?? null,
);

export const getUserCards = createSelector(
    [getUser],
    (user) => user?.cards ?? [],
);

export const getUserActiveCardIndex = createSelector(
    [getUser],
    (user) => user?.activeCardIndex ?? null,
);

export const getUserActivatedPromotionIds = createSelector(
    [getUser],
    (user) => user?.activatedPromotionIds ?? [],
);

export default function user(
    state: IUser | null = INITIAL_STATE,
    action: SetUser | ClearUser,
) {
    switch (action.type) {
        case SET_USER:
            return {
                ...action.user,
            };
        case CLEAR_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
}
