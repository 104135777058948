import React, { useMemo } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import AddressSearchOption from "@/components/SetMyLocation/AddressSearchOption";

type AddressInputProps = {
    addressInput: string;
    onAddressChange: (address: string) => void;
    onSelectAddress: (address: string) => void;
};

export const AddressInput: React.FC<AddressInputProps> = ({
    onAddressChange,
    onSelectAddress,
    addressInput,
}) => {
    // Need this or else Google Places will bill each request separately:
    // https://developers.google.com/maps/documentation/places/web-service/session-tokens
    const sessionToken = useMemo(
        () => new google.maps.places.AutocompleteSessionToken(),
        []
    );

    return (
        <PlacesAutocomplete
            value={addressInput}
            onChange={onAddressChange}
            onSelect={(address: string) => {
                onSelectAddress(address);
            }}
            searchOptions={{
                componentRestrictions: { country: "us" },
                sessionToken,
            }}
            debounce={500}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: "Set address",
                            className: "location-search-input",
                            style: {
                                width: "100%",
                                backgroundColor: "#f6f7f7",
                                boxShadow: "none",
                                borderRadius: 5,
                                height: 40,
                                textIndent: 7,
                                border: "1px solid #DDE5EE",
                            },
                        })}
                    />
                    <div
                        className="autocomplete-dropdown-container"
                        style={{ margin: "10px 0" }}
                    >
                        {suggestions.map((suggestion) => (
                            <div style={{ margin: "15px 0" }}>
                                <AddressSearchOption
                                    {...getSuggestionItemProps(suggestion, {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                        width: "100%",
                                        padding: 10,
                                    })}
                                    suggestion={suggestion}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};
