import React from "react";
import { useSelector } from "react-redux";
import {
    useUserStorePunchcard,
    useUserStorePointsBalance,
} from "@snackpass/loyalty-react";
import { ChainFeatures } from "@snackpass/snackpass-types";

import {
    getActiveStore,
    getTotalLocations,
    getTotalOrders,
    getUser,
    getLastPurchaseTime,
} from "src/redux";
import { optimizedImageURL } from "@/utils/Helpers";
import { LoyaltyCard } from "./LoyaltyCard";
import { ReactComponent as ChevronRightIcon } from "src/assets/icons/chevron-right.svg";
import { ReactComponent as LockIcon } from "src/assets/icons/lock_outline.svg";

const Content = () => {
    const activeStore = useSelector(getActiveStore);
    const user = useSelector(getUser);
    const userName = user?.firstName || user?.lastName || user?.name;
    const locationsVisited = useSelector(getTotalLocations);
    const totalOrders = useSelector(getTotalOrders);

    const punchcards = useUserStorePunchcard(user._id, activeStore?._id);
    const pointsBalance = useUserStorePointsBalance(user._id, activeStore?._id);

    const storeHasChainWidePunchCard = activeStore?.chainConfig?.some(
        (config) => config.feature === ChainFeatures.ChainWidePunchcard,
    );

    const lastPurchaseTime = useSelector(getLastPurchaseTime);
    const date = lastPurchaseTime
        ? new Date(lastPurchaseTime).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
          })
        : "";
    const dateString = date ? `Guest since ${date}` : "";

    return (
        <div className="flex flex-col space-y-6">
            <div className="flex flex-row items-center justify-between mx-4">
                <div>
                    <div className="text-[28px] font-bold leading-[34px] text-primary">
                        {userName}
                    </div>
                    {dateString && <div>{dateString}</div>}
                </div>
                {user?.profilePicUrl ? (
                    <div
                        className="flex flex-col items-center justify-center self-start w-full"
                        style={{
                            backgroundImage: `url("${optimizedImageURL(
                                user?.profilePicUrl,
                                { size: { h: 56, w: 56 } },
                            )}")`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: 56,
                            width: 56,
                            borderRadius: 56,
                        }}
                    ></div>
                ) : null}
            </div>
            <div className="px-4">
                <LoyaltyCard
                    activeStore={activeStore}
                    userName={userName}
                    customerSince={date}
                />
            </div>
            <div className="flex flex-row border rounded-xl drop-shadow-sm p-4 justify-between mx-4 shadow-md">
                <div className="flex flex-col space-y-2 justify-center items-center flex-1">
                    <span className="text-3xl font-bold leading-snug text-primary">
                        {pointsBalance}
                    </span>
                    <span>Points</span>
                </div>
                <div className="border-l"></div>
                <div className="flex flex-col space-y-2 justify-center items-center flex-1">
                    <span className="text-3xl font-bold leading-snug text-primary">
                        {totalOrders}
                    </span>
                    <span>Orders</span>
                </div>
                <div className="border-l"></div>
                <div className="flex flex-col space-y-2 justify-center items-center flex-1">
                    <span className="text-3xl font-bold leading-snug text-primary">
                        {locationsVisited}
                    </span>
                    <span>Locations</span>
                </div>
            </div>
            {storeHasChainWidePunchCard || !punchcards?.length ? null : (
                <div className="p-4 py-0">
                    <span className="text-xs text-center">
                        Points can only be redeemed at the specific location
                        where they were earned.
                    </span>
                </div>
            )}
            <div className="flex flex-col m-4">
                <div className="text-2xl font-semibold leading-8 text-primary mt-2 mb-4">
                    Settings
                </div>
                <div
                    className="items-center flex flex-row justify-between cursor-pointer"
                    onClick={() => {
                        const privacyCenterURL = "https://privacy.snackpass.co";

                        if (window.ReactNativeWebView) {
                            window.ReactNativeWebView.postMessage(
                                JSON.stringify({
                                    type: "openPrivacyCenter",
                                    privacyCenterURL,
                                }),
                            );
                        } else {
                            window.open(
                                "https://privacy.snackpass.co",
                                "_blank",
                            );
                        }
                    }}
                >
                    <div className="flex flex-row items-center gap-1">
                        <LockIcon
                            className="w-5 h-5 fill-secondary"
                            aria-hidden
                        />
                        <span className="text-base text-primary">
                            Privacy Center
                        </span>
                    </div>
                    <ChevronRightIcon
                        className="w-3.5 h-3.5 text-[#999DA3]"
                        aria-hidden
                    />
                </div>
            </div>
        </div>
    );
};

export default Content;
