import API from "@/utils/Api/REST";
import { ParticipatingLocations } from "@snackpass/snackpass-types";
import { useCallback, useMemo, useState } from "react";

export interface FetchGiftCardParticipatingLocationsAPI {
    /** API call to start fetching the gift card */
    fetchGiftCardParticipatingLocations: (id: string, token: string) => void;

    /** data of the gift card */
    data?: ParticipatingLocations;

    /** Error from the server call */
    error?: Error;
}

/**
 * Provides an API for getting the chain stores that a gift card can currently be used at
 */
export function useFetchGiftCardParticipatingLocations(): FetchGiftCardParticipatingLocationsAPI {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [data, setData] = useState<ParticipatingLocations | undefined>(
        undefined
    );

    const fetchGiftCardParticipatingLocations = useCallback(
        async (id: string, token: string) => {
            API.giftCardsRest
                .getParticipatingLocations(id, token)
                .then((res) => {
                    setData(res.data.participatingLocations);
                })
                .catch((error) => {
                    setError(error);
                });
        },
        []
    );

    return useMemo(
        () => ({
            fetchGiftCardParticipatingLocations,
            data,
            error,
        }),
        [fetchGiftCardParticipatingLocations, data, error]
    );
}
