import React, { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeolocationHelpers from "src/utils/Geolocation";
import { BeatLoader } from "react-spinners";
import { Colors } from "src/utils";
import { Geolocation } from "@snackpass/snackpass-types";
import { getMyLocation, hasLocationSet, updateMyLocation } from "src/redux";
import { toast } from "sonner";

export type GetUserLocationProps = {
    style?: CSSProperties;
};

const GetUserLocation = ({ style }: GetUserLocationProps) => {
    const location = useSelector(getMyLocation);
    const setLocation = useSelector(hasLocationSet);

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onSuccessLocation = (position) => {
        let geolocation: Geolocation = {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude],
        };

        dispatch(updateMyLocation(geolocation));

        setLoading(false);
    };

    const onErrorLocation = (error) => {
        console.error(error);

        toast("You denied geolocation permissions", {
            description: `If this was a mistake, you can change it in your browser settings.`,
        });

        setLoading(false);
    };

    const requestLocation = () => {
        if (!navigator.geolocation) {
            return toast.warning("Your browser does not support geolocation.");
        }

        setLoading(true);

        GeolocationHelpers.requestLocation(onSuccessLocation, onErrorLocation);
    };

    const getLocationPermission = async () => {
        setLoading(true);

        try {
            const result = await navigator.permissions.query({
                name: "geolocation",
            });

            if (result.state === "granted" && !setLocation) {
                requestLocation();
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    useEffect(() => {
        getLocationPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (!navigator.geolocation) {
        return null;
    }

    return (
        <div onClick={requestLocation}>
            <div
                style={{
                    borderRadius: 7,
                    cursor: "pointer",
                    border: "2px solid #e3e6e8",
                    color: "#006aff",
                    padding: "8px 15px",
                    marginLeft: 15,
                    ...style,
                }}
            >
                {!loading ? (
                    <i className="fas fa-location-arrow" />
                ) : (
                    <BeatLoader color={Colors.black} size={7} />
                )}
            </div>
        </div>
    );
};

export default GetUserLocation;
