import { ApolloProvider } from "@apollo/client";
import { LoyaltyProvider } from "@snackpass/loyalty-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider as ReduxProvider } from "react-redux";

import { StatsigReduxProvider } from "@/statsig/StatsigReduxProvider";
import { apolloClient } from "@/utils/Api/graphql/apollo";
import { useInitApp } from "@/useInitApp";

const reactQueryClient = new QueryClient();

/**
 * Global state providers for the Online Ordering app.
 */
export function Providers({ children }: { children: ReactNode | ReactNode[] }) {
    const { persistor, store } = useInitApp();
    // NB: only put _global_ providers here!
    return (
        <ReduxProvider store={store}>
            <LoyaltyProvider>
                <ApolloProvider client={apolloClient}>
                    <StatsigReduxProvider>
                        <QueryClientProvider client={reactQueryClient}>
                            <PersistGate persistor={persistor}>
                                {children}
                            </PersistGate>
                        </QueryClientProvider>
                    </StatsigReduxProvider>
                </ApolloProvider>
            </LoyaltyProvider>
        </ReduxProvider>
    );
}
