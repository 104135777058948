import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartActions, CartSelectors } from "@snackpass/accounting";
import {
    ModalsName,
    getActiveStore,
    setIsSwitchingToCatering,
    showModal,
} from "src/redux";
import { ReactComponent as CateringFillIcon } from "src/assets/icons/calendar-fill.svg";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { useFetchActiveStore } from "#activeStore/hooks/useFetchActiveStore";
import { toast } from "sonner";
import { FulfillmentTypeEnum, WhenTypeEnum } from "@snackpass/snackpass-types";
import { Button } from "@/components/ui/button";
import { cn } from "@/utils";

export const CateringButton = ({ cartScreen }: { cartScreen?: boolean }) => {
    const dispatch = useDispatch();
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const items = useSelector(CartSelectors.getCartItems);
    const store = useSelector(getActiveStore);
    const headcount = useSelector(CartSelectors.getCartHeadcount);
    const { fetchStore } = useFetchActiveStore();
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);

    const dispatchCatering = async () => {
        dispatch(setIsSwitchingToCatering(true));
        if (isCatering) {
            // switch from catering mode to non-catering mode
            if (store) {
                await fetchStore(store._id);
            }
            dispatch(CartActions.exitCateringMode());
        } else {
            // switch to catering mode
            dispatch(CartActions.enterCateringMode());
            dispatch(CartActions.setCartWhen(WhenTypeEnum.Later)); // switch to later so it shows date picker
            dispatch(showModal(ModalsName.scheduleModal));
        }
    };

    const switchMode = async () => {
        if (items.length > 0) {
            toast(
                isCatering
                    ? "Switching from catering"
                    : "Switching to catering",
                {
                    description: isCatering
                        ? "Switching away from catering will clear your cart, reset the fulfillment and scheduled time."
                        : "Switching to catering will clear your cart, reset the fulfillment and scheduled time.",
                    action: {
                        label: isCatering
                            ? "Switching from catering"
                            : "Switching to catering",
                        onClick: () => {
                            dispatchCatering();
                        },
                    },
                    unstyled: true,
                    classNames: {
                        toast: "border rounded-lg w-full p-4 shadow-lg gap-2 flex flex-col bg-white",
                        title: "!font-semibold !text-base",
                        description: "flex-1 w-full !text-base",
                        actionButton:
                            "mt-1 !p-4 !bg-accent text-white !text-base font-semibold rounded-md shrink-0 !self-end !justify-self-start",
                    },
                },
            );
        } else {
            if (fulfillment === FulfillmentTypeEnum.DineIn) {
                toast.error(
                    `Catering orders are for pickup ${
                        store?.catering.allowDelivery ? "or delivery" : ""
                    } only, not for dine-in, please select pickup or delivery for fulfillment above.`,
                );
            } else if (
                fulfillment === FulfillmentTypeEnum.Delivery &&
                !store?.catering.allowDelivery
            ) {
                toast.error(
                    `${store?.name} doesn't support delivery for catering order, please select pickup for fulfillment above.`,
                );
            } else {
                if (isCatering) {
                    // give user feedback on we are switching to non-catering mode
                    // no need when switching to catering mode since it will open up schedule modal
                    toast.info(
                        "Switching away from catering, please re-select fulfillment and time above.",
                    );
                }
                dispatchCatering();
            }
        }
    };
    return (
        <Button
            className={cn(
                "px-0 py-0 w-[100%] h-[100%] rounded-xl outline-none",
                {
                    "bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] transition-shadow duration-500 ease-in-out shadow-default hover:shadow-hover rounded-xl active:shadow-focus border active:scale-95":
                        !cartScreen,
                    "hover:bg-white": cartScreen,
                },
            )}
            variant="ghost"
            onPress={switchMode}
            label={
                <div
                    className={cn(
                        "flex flex-col md:flex-row flex-1 py-3 md:py-0 md:items-center md:justify-between rounded-xl ",
                        {
                            "pl-3 pr-2 md:pr-4 h-[94px] md:h-[64px]":
                                !cartScreen,
                            "px-4 h-[78px]": cartScreen,
                        },
                    )}
                >
                    {cartScreen ? null : (
                        <div className="basis-1/8 pb-2 md:pb-0">
                            <CateringFillIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                        </div>
                    )}
                    <div
                        className={cn("basis-6/8 w-full text-start", {
                            "px-0 md:px-3": !cartScreen,
                            "space-y-1": cartScreen,
                        })}
                    >
                        <div className="flex items-start text-base font-semibold text-primary">
                            Catering
                        </div>
                        {isCatering ? (
                            <div className="line-clamp-1 text-start overflow-hidden text-ellipsis text-sm font-normal leading-4 text-secondary">
                                Catering Group size: {headcount}
                            </div>
                        ) : null}
                    </div>
                    <div className="basis-1/8 hidden md:block">
                        <ChevronRightIcon className="w-4 h-4 text-[#999DA3]" />
                    </div>
                </div>
            }
        />
    );
};
