import { PayloadAction } from "@reduxjs/toolkit";
import { IStore } from "@snackpass/snackpass-types";
import { err, ok } from "neverthrow";
import { match, P } from "ts-pattern";
import {
    call,
    cancelled,
    put,
    SagaGenerator,
    takeLatest,
} from "typed-redux-saga";

import { TransactionMonitor } from "@/lib/transaction-monitor";
import { sendError } from "@/utils/Errors";

import { fetchStoreByID, fetchStoreBySlug } from "../api";
import { fetchActiveStore } from "../actions";
import { getStoreParams } from "../helpers";
import { setActiveStore } from "../slice";

/**
 * Saga to fetch a store by ID or slug and assign it as the active store.
 * An optional monitor can be provided to subscribe to the result.
 */
function* fetchActiveStoreSaga({
    payload: { storeIDorSlug, monitor },
}: PayloadAction<{
    storeIDorSlug: string;
    monitor?: TransactionMonitor<IStore, Error>;
}>) {
    const params = yield* call(getStoreParams, storeIDorSlug);

    const abortCtrl = new AbortController();

    try {
        const result = yield* call(fetchStore, params, abortCtrl.signal);

        yield* result.match<SagaGenerator<void>>(
            function* (store) {
                if (monitor) {
                    yield* call(monitor.emitResult, ok(store));
                }
                yield* put(setActiveStore(store));
            },
            function* (error) {
                if (monitor) {
                    yield* call(monitor.emitResult, err(error));
                }
                yield* call(sendError, error);
            },
        );
    } finally {
        // If the saga was cancelled, abort the fetch request.
        if (yield* cancelled()) {
            abortCtrl.abort();
        }
    }
}

/**
 * Listens for `fetchActiveStore` actions and calls `fetchActiveStoreSaga`.
 * NB: `takeLatest` only allows one `fetchActiveStoreSaga` task to run at a time, cancelling any already running.
 */
export function* fetchActiveStoreWatcher() {
    yield* takeLatest(fetchActiveStore, fetchActiveStoreSaga);
}

function fetchStore(
    params: ReturnType<typeof getStoreParams>,
    signal: AbortSignal,
) {
    return match(params)
        .with({ slug: P.string }, ({ slug }) => fetchStoreBySlug(slug, signal))
        .with({ id: P.string }, ({ id }) => fetchStoreByID(id, signal))
        .exhaustive();
}
