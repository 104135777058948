import React from "react";
import { IProduct } from "@snackpass/snackpass-types";
import { Minus, Plus } from "lucide-react";

type QuantitySelectorProps = {
    product: IProduct;
    quantity: number;
    onChangeQuantity: (quantity: number) => void;
};

export default function QuantitySelector({
    product,
    quantity,
    onChangeQuantity,
}: QuantitySelectorProps) {
    const minimumQuantity = product.minimumQuantity ?? 0;

    return (
        <div className="w-[120px] h-10 justify-center bg-white items-center inline-flex">
            <button
                onClick={() => {
                    if (quantity > minimumQuantity) {
                        onChangeQuantity(quantity - 1);
                    }
                }}
                className={`w-10 h-10 px-3 py-[13px] rounded-tl-lg rounded-bl-lg border-l border-t border-b border-zinc-200 justify-center items-center gap-2 flex ${
                    quantity > minimumQuantity
                        ? "bg-white hover:bg-gray-50"
                        : "bg-zinc-100"
                }`}
            >
                <Minus
                    className="w-4 h-4 relative"
                    strokeWidth={2.5}
                    size={20}
                />
            </button>
            <div className="w-13 h-10 py-[13px] border-l border-t border-b border-zinc-200 justify-center items-center gap-2 flex">
                <input
                    className="flex w-11/12 pointer-events-auto text-center text-stone-950 text-base font-semibold leading-normal"
                    value={quantity}
                    min={minimumQuantity}
                    type="number"
                    onChange={(e) => {
                        let value = e.target.value;
                        let newQuantity = parseInt(value);

                        if (isNaN(newQuantity)) {
                            return;
                        }

                        // This will allow a user to set a number LOWER than the minimum quantity,
                        // which is necessary because otherwise if the min quantity is 10 and a user wants to delete
                        // the 0 to make it 12, it won't let them. So, the quantity validation is done when the
                        // user clicks the add to cart button instead
                        onChangeQuantity(newQuantity);
                    }}
                />
            </div>
            <button
                onClick={() => {
                    onChangeQuantity(quantity + 1);
                }}
                className="w-10 h-10 px-3 py-[13px] rounded-tr-lg rounded-br-lg border border-zinc-200 justify-center items-center gap-2 flex hover:bg-gray-50"
            >
                <Plus
                    size={20}
                    strokeWidth={2.5}
                    className="w-4 h-4 relative"
                />
            </button>
        </div>
    );
}
