import React from "react";
import moment from "moment-timezone";
import { IStore } from "@snackpass/snackpass-types";
import { getActiveStore } from "src/redux";
import { useSelector } from "react-redux";
import clsx from "clsx";

type WeekDayProps = {
    onSelectDate: (date: moment.Moment) => void;
    isSelected: boolean;
    date: moment.Moment;
};

export default function WeekDay({
    date,
    isSelected,
    onSelectDate,
}: WeekDayProps) {
    const activeStore = useSelector(getActiveStore);

    if (!activeStore) return null;
    const isValid = isValidDate(date, activeStore);

    return (
        <div
            onClick={() => isValid && onSelectDate(date)}
            className="flex-1 min-w-[90px] cursor-pointer mr-[10px] text-start inline-block"
        >
            <div
                className={clsx("relative rounded-xl border py-2 pl-2 m-1", {
                    "border-primary custom-outline": isSelected,
                    "border-transparent": !isValid,
                    "cursor-pointer": isValid,
                    "cursor-not-allowed": !isValid,
                })}
            >
                <div className="mb-1 text-base max-w-[800px] font-semibold uppercase text-primary">
                    {date.isSame(moment(), "day")
                        ? "TODAY"
                        : date.format("ddd")}
                </div>
                <div className="text-sm">{date.format("MMM D")}</div>
            </div>
        </div>
    );
}

const isValidDate = (date: moment.Moment, store: IStore) =>
    store?.closedUntil
        ? date.endOf("day").isAfter(moment(store.closedUntil))
        : true;
