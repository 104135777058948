import { selectPastOrders } from "@/redux";
import { selectProductDictionary } from "#menu";
import { CartAddon, CartItem } from "@snackpass/accounting";
import { Addon, AddonGroup } from "@snackpass/snackpass-types";
import _ from "lodash";
import { useSelector } from "react-redux";

export const useLastOrder = () => {
    const pastOrders = useSelector(selectPastOrders);
    const productsMapping = useSelector(selectProductDictionary);

    const hasPastOrder = !_.isEmpty(pastOrders);
    const lastOrder = pastOrders.length > 0 ? pastOrders[0] : null;

    const getFormattedCartItem = () => {
        // creating map to only display unique products in the past order list
        const uniqueProducts = new Map<string, CartItem>();
        if (!lastOrder) {
            return [];
        }
        lastOrder.items.forEach((item) => {
            if (item.product && productsMapping[item.product._id]) {
                const productId = item.product._id;
                if (productsMapping[productId]) {
                    if (!uniqueProducts.has(productId)) {
                        const product = productsMapping[productId];
                        // build up-to-date addonsMap from product
                        type CartAddonMap = {
                            [id: string]: CartAddon;
                        };
                        const addonsMap: CartAddonMap = {};
                        product.addonGroups.forEach(
                            (addonGroup: AddonGroup) => {
                                addonGroup.addons.forEach((addon: Addon) => {
                                    addonsMap[addon._id] = {
                                        addon: {
                                            ...addon,
                                        },
                                        addonGroup: {
                                            ...addonGroup,
                                        },
                                        quantity: 0,
                                    };
                                });
                            },
                        );
                        // get up to date addons, and create cart addon from them
                        const selectedAddons: CartAddon[] = [];
                        item.selectedAddons.forEach((savedAddon) => {
                            const upToDateAddon = addonsMap[savedAddon._id];
                            // addon exists, and is not already selected
                            if (
                                upToDateAddon &&
                                !selectedAddons
                                    .map((cartAddon) => cartAddon.addon._id)
                                    .includes(savedAddon._id)
                            ) {
                                // adds CartAddon object to selectedAddons
                                selectedAddons.push({
                                    addon: upToDateAddon.addon,
                                    addonGroup: upToDateAddon.addonGroup,
                                    quantity: savedAddon.quantity,
                                });
                            }
                        });

                        uniqueProducts.set(productId, {
                            product: product,
                            selectedAddons: selectedAddons,
                            quantity: 1,
                            notes: item.notes || "",
                            weight: item.weight,
                        });
                    } else {
                        const existingProduct = uniqueProducts.get(productId);
                        if (existingProduct) {
                            uniqueProducts.set(productId, {
                                ...existingProduct,
                                quantity: existingProduct?.quantity
                                    ? existingProduct?.quantity + 1
                                    : 1,
                            });
                        }
                    }
                }
            }
        });
        return Array.from(uniqueProducts.values());
    };

    const lastPurchaseDate = lastOrder
        ? new Date(lastOrder.createdAt).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
          })
        : null;

    return {
        hasPastOrder,
        lastOrder,
        lastOrderItems: lastOrder?.items || [],
        lastOrderProductsAndAddons: hasPastOrder ? getFormattedCartItem() : [],
        lastPurchaseDate,
    };
};
