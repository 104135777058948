import React, { useContext } from "react";
import { WhenTypeEnum, FulfillmentTypeEnum } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { Helpers } from "src/utils";
import { Button } from "@/components/ui/button";
import { useSelector } from "react-redux";
import { CartSelectors } from "@snackpass/accounting";
import { getActiveStore } from "src/redux";
import { FulfillmentModalContext } from "../FulfillmentModalContext";
import { isDeliveryClosed } from "@/hooks/usePurchase/utils";

export type ModalFooterProps = {
    handleConfirmOptions: () => Promise<void>;
};

const ModalFooter: React.FC<ModalFooterProps> = ({ handleConfirmOptions }) => {
    const activeStore = useSelector(getActiveStore);
    const cartTableNumber = useSelector(CartSelectors.getCartTableNumber);
    const deliveryError = useSelector(CartSelectors.getCartDeliveryError);
    const tableNumber = cartTableNumber;
    const deliveryAddress = useSelector(CartSelectors.getCart).address;
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const cartWhen = useSelector(CartSelectors.getCartWhen);
    const selectedTime = useSelector(CartSelectors.getCartScheduleDate);
    const { modalFulfillment } = useContext(FulfillmentModalContext);

    if (!activeStore) return null;
    const isScheduledOrder =
        cartWhen === WhenTypeEnum.Later && selectedTime != null;
    const isDeliveryOrder = modalFulfillment === FulfillmentTypeEnum.Delivery;
    const disableConfirmButton =
        (modalFulfillment === FulfillmentTypeEnum.DineIn &&
            activeStore?.dineInPreferences?.requireTableNumber &&
            !tableNumber) ||
        (modalFulfillment === FulfillmentTypeEnum.Delivery &&
            !deliveryAddress) ||
        (modalFulfillment === FulfillmentTypeEnum.Delivery &&
            isDeliveryClosed(
                isDeliveryOrder,
                isScheduledOrder,
                isCatering,
                activeStore,
                selectedTime,
            )) ||
        (modalFulfillment === FulfillmentTypeEnum.Delivery && !!deliveryError);

    const renderConfirmMessage = (date: Date | null | undefined) => {
        if (
            modalFulfillment === FulfillmentTypeEnum.DineIn &&
            activeStore?.dineInPreferences?.requireTableNumber &&
            !tableNumber
        ) {
            return "Please enter table number";
        }
        if (
            modalFulfillment === FulfillmentTypeEnum.Delivery &&
            isDeliveryClosed(
                isDeliveryOrder,
                isScheduledOrder,
                isCatering,
                activeStore,
                selectedTime,
            )
        ) {
            return "Delivery Closed";
        }
        if (
            modalFulfillment === FulfillmentTypeEnum.Delivery &&
            !deliveryAddress
        ) {
            return "Delivery address is required";
        }

        if (
            modalFulfillment === FulfillmentTypeEnum.Delivery &&
            deliveryError
        ) {
            return deliveryError;
        }
        if (cartWhen === WhenTypeEnum.Later && !date) {
            return "";
        }
        let time = "";
        if (date) {
            const day = moment(date).isSame(moment(), "day")
                ? "Today"
                : moment(date).format("ddd, MMM D");
            time = `${day} at ${moment(date).format("h:mm A")}`;
        }
        switch (modalFulfillment) {
            case "DELIVERY":
                return isCatering ? `Delivery ${time}` : `Delivery ASAP`;
            case "DINE_IN":
                if (tableNumber) {
                    return `${Helpers.capitalize(
                        modalFulfillment.replace("_", "-"),
                    )} at table #${tableNumber}`;
                }
                return "";
        }
    };

    return (
        <div className="w-full">
            <div className="flex flex-1 items-center justify-center py-2">
                {renderConfirmMessage(selectedTime)}
            </div>
            <Button
                onPress={handleConfirmOptions}
                label="Confirm"
                className="w-full"
                disabled={disableConfirmButton}
            />
        </div>
    );
};

export default ModalFooter;
