import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentRequestOptions } from "@stripe/stripe-js";
import { CheckoutSelectors } from "@snackpass/accounting";
import { useSelector } from "react-redux";

// This hook is to check if Apple Pay is available
const useApplePay = () => {
    const total = useSelector(CheckoutSelectors.getAmountPaidByCustomer);
    const stripe = useStripe();
    const [isApplePayAvailable, setIsApplePayAvailable] =
        useState<boolean>(false);

    useEffect(() => {
        let isMounted = true;
        if (!isMounted || !stripe) {
            return;
        }
        const options: PaymentRequestOptions = {
            country: "US",
            currency: "usd",
            total: {
                label: "Total",
                amount: Math.ceil(total * 100),
            },
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true,
        };

        const paymentRequest = stripe.paymentRequest(options);

        paymentRequest.canMakePayment().then((result) => {
            if (result) {
                setIsApplePayAvailable(fp.get("applePay", result));
            }
        });
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    return isApplePayAvailable;
};

export default useApplePay;
