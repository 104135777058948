import { createAction } from "@reduxjs/toolkit";
import { IStore } from "@snackpass/snackpass-types";

import { TransactionMonitor } from "@/lib/transaction-monitor";

// Actions prefix
export const ACTIONS_NAMESPACE = "active-store";

/**
 * Fetches a store from the provided ID and sets it as the active store.
 */
export const fetchActiveStore = createAction<{
    storeIDorSlug: string;
    monitor?: TransactionMonitor<IStore, Error>;
}>(`${ACTIONS_NAMESPACE}/fetch-active-store`);
