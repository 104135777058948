import React from "react";
import { Divider } from "@/SharedComponents";
import { optimizedImageURL } from "@/utils/Helpers";
import { ActiveCartItemActions } from "@snackpass/accounting";
import { IProduct } from "@snackpass/snackpass-types";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { setIsUpsellAlreadyShown, showUpsellProduct } from "src/redux";
import { Helpers } from "src/utils";
import { Button } from "@/components/ui/button";
import { PlusCircledIcon } from "@radix-ui/react-icons";

export const UpsellCard = ({ product }: { product: IProduct }) => {
    const dispatch = useDispatch();
    const handleClickItem = () => {
        dispatch(ActiveCartItemActions.setActiveCartItem(product));
        dispatch(showUpsellProduct());
        dispatch(setIsUpsellAlreadyShown(true));
    };
    return (
        <>
            <Button
                className="flex items-start justify-start h-[96px] w-full py-0 px-4 hover:bg-white"
                onPress={handleClickItem}
                variant="ghost"
                label={
                    <div className="w-full relative">
                        <div className="cursor-pointer md:border overflow-hidden flex flex-row rounded-lg h-[96px]">
                            <div className="flex flex-row flex-1">
                                <div className="p-2 flex flex-col">
                                    <span className="text-left text-base text-primary text-ellipsis line-clamp-1 overflow-hidden">
                                        {product.name}
                                    </span>

                                    {!_.isEmpty(product.description) && (
                                        <label className="cursor-pointer text-base font-normal text-ellipsis text-start line-clamp-1 overflow-hidden">
                                            {product.description}
                                        </label>
                                    )}

                                    <div className="flex-1 items-end flex mb-0">
                                        <div className="flex flex-row items-center">
                                            <div className="text-primary font-normal inline-block text-sm">
                                                {Helpers.toDollar(
                                                    product.price,
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {product.image && (
                                    <div className="flex flex-row flex-end items-center">
                                        <img
                                            src={optimizedImageURL(
                                                product.image,
                                            )}
                                            alt="upsell-product"
                                            className="h-[96px] w-[96px] text-center overflow-hidden bg-cover bg-center bg-no-repeat"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <PlusCircledIcon className="w-5 h-5 ml-2 text-white bg-primary rounded-full absolute right-2 bottom-2 opacity-90" />
                    </div>
                }
            ></Button>{" "}
            <div className="md:hidden block">
                <Divider width={1} />
            </div>
        </>
    );
};
