import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import { useSelector, useDispatch } from "react-redux";
import {
    hideModal,
    ModalsName,
    getActiveStore,
    getIsStoreHoursModalOpen,
} from "@/redux";
import { isOpenLocal } from "@/utils/Time";
import { useMediaQuery } from "react-responsive";
import Hours from "./Hours";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import clsx from "clsx";

const StoreHoursModal = () => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const storeHoursModal = useSelector(getIsStoreHoursModalOpen);
    const handleHideStoreHoursModal = () => {
        dispatch(hideModal(ModalsName.storeHoursModal));
    };
    const isDesktop = useMediaQuery({ minWidth: 768 });
    if (!activeStore) return null;
    const isOpen = isOpenLocal(activeStore.hours);

    if (isDesktop) {
        return (
            <Dialog
                open={storeHoursModal}
                onOpenChange={handleHideStoreHoursModal}
            >
                <DialogContent
                    onEscapeKeyDown={handleHideStoreHoursModal}
                    className="hidden-scroll h-auto max-h-[60%] min-h-[20%] overflow-scroll rounded-lg"
                >
                    <div className="hidden-scroll relative flex h-full w-full flex-col overflow-y-auto">
                        <div className="sticky right-0 top-0 z-[101] flex justify-center border-b bg-white">
                            <DialogClose className="flex gap-0" />
                            <div className="sticky top-0 h-auto w-auto bg-white md:m-auto">
                                <div className="flex h-14 items-center text-base font-semibold leading-normal text-primary">
                                    Hours
                                </div>
                            </div>
                        </div>
                        <div className="mx-4 my-4">
                            <div
                                className={clsx(
                                    "flex items-start pb-4 text-2xl font-semibold",
                                    {
                                        "text-green-500": activeStore.isOpen,
                                        "text-alert": !activeStore.isOpen,
                                    },
                                )}
                            >
                                {activeStore.isOpen ? "Open" : "Closed"}
                            </div>
                            <Hours />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={storeHoursModal} onOpenChange={handleHideStoreHoursModal}>
            <SheetContent className="px-2">
                <SheetHeader>
                    <SheetTitle> Hours</SheetTitle>
                </SheetHeader>
                <div className="mx-4 my-4">
                    {isOpen ? (
                        <div className="flex items-start pb-4 text-2xl font-semibold text-brandColor">
                            Open
                        </div>
                    ) : (
                        <div className="flex items-start pb-4 text-2xl font-semibold text-alert">
                            Closed
                        </div>
                    )}
                    <Hours />
                </div>
            </SheetContent>
        </Sheet>
    );
};

export default StoreHoursModal;
