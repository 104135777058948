import React, { useState } from "react";
import fp from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import { CardRow } from "./components/CardRow";
import CardInput from "../../components/CardInput";
import {
    ModalsName,
    getIsMyCardsModalOpen,
    getUser,
    getUserCards,
    hideModal,
    setIsApplePayActive,
    setUser,
} from "src/redux";
import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import { ReactComponent as CardIcon } from "src/assets/icons/credit-card.svg";
import { ReactComponent as PlusIcon } from "src/assets/icons/plus-thin.svg";
import { Divider } from "@/SharedComponents";
import { ApplePayRow } from "./components/ApplePayRow";
import { useMediaQuery } from "react-responsive";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

export type MyCardsModalProps = {
    showAddCardView?: boolean;
};

enum CardInputType {
    creditOrDebitCard = "Credit or Debit Card",
}

const MyCardsModal = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const user = useSelector(getUser);
    const cards = useSelector(getUserCards);
    const myCardsModal = useSelector(getIsMyCardsModalOpen);
    const handleHideMyCardsModal = () => {
        dispatch(hideModal(ModalsName.myCardsModal));
    };
    const activeCardIndex = user?.activeCardIndex;
    const deactivateApplePay = () => dispatch(setIsApplePayActive(false));
    const [cardInputType, setCardInputType] = useState<CardInputType | null>(
        null,
    );

    const dispatch = useDispatch();
    const _setUser = fp.compose(dispatch, setUser);

    const onHide = () => {
        handleHideMyCardsModal();
        setCardInputType(null);
    };

    const cardsView = () => {
        return (
            <div className="flex">
                <div className="flex-1 h-[100%] flex flex-col items-stretch ">
                    <div>
                        <ApplePayRow />
                        {cards.map((card, index) => {
                            return (
                                <CardRow
                                    key={index}
                                    cardIndex={index}
                                    card={card}
                                    activeCardIndex={activeCardIndex}
                                    setUser={_setUser}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const cardInputView = () => {
        return (
            <div className="animated fadeIn fast self-center bg-white rounded-lg">
                <CardInput
                    cardInputName={user?.name}
                    onSuccess={() => {
                        setCardInputType(null);
                        deactivateApplePay();
                    }}
                />
            </div>
        );
    };

    const addCardView = (title: string, onClick: () => void) => {
        return (
            <div
                className="cursor-pointer flex flex-row justify-between items-center px-6 py-4 hover:bg-tertiary rounded-lg"
                onClick={onClick}
            >
                <div className="flex flex-row gap-2 text-base text-primary">
                    <CardIcon className="w-[24px] h-[24px] fill-secondary" />
                    {title}
                </div>
                <PlusIcon
                    className="cursor-pointer w-[20px] h-[20px] fill-secondary"
                    onClick={onClick}
                />
            </div>
        );
    };
    if (isDesktop) {
        return (
            <Dialog open={myCardsModal} onOpenChange={onHide}>
                <DialogContent
                    onEscapeKeyDown={onHide}
                    className="overflow-y-scroll hidden-scroll"
                >
                    <div className="relative flex h-full w-full flex-col">
                        <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    Saved Payment Methods
                                </div>
                            </div>
                        </div>
                        <div className="my-4">{cardsView()}</div>
                        <Divider width={8} />
                        <div className="mt-2 w-[100%]flex flex-col pb-6">
                            <div className="px-6 py-4 text-xl font-semibold text-primary">
                                Add Payment Method
                            </div>
                            {cardInputType != null ? (
                                cardInputView()
                            ) : (
                                <>
                                    {addCardView("Credit or Debit Card", () =>
                                        setCardInputType(
                                            CardInputType.creditOrDebitCard,
                                        ),
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={myCardsModal} onOpenChange={onHide}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle> Saved Payment Methods</SheetTitle>
                </SheetHeader>
                <div className="overflow-auto">
                    <div className="relative flex h-full w-full flex-col">
                        <div className="my-4">{cardsView()}</div>
                        <Divider width={8} />
                        <div className="mt-2 w-[100%]flex flex-col pb-6">
                            <div className="px-6 py-4 text-xl font-semibold text-primary">
                                Add Payment Method
                            </div>
                            {cardInputType != null ? (
                                cardInputView()
                            ) : (
                                <>
                                    {addCardView("Credit or Debit Card", () =>
                                        setCardInputType(
                                            CardInputType.creditOrDebitCard,
                                        ),
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    );
};

export default MyCardsModal;
