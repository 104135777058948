import {
    FeePolicy,
    FeePolicyPayer,
    FeePolicyRecipient,
    FeePolicyWithTotal,
    ReservedFeeEnum
} from "@snackpass/snackpass-types";
import { convertToDinero } from "../../utils/Money";
import {
    calculateCustomFeeWithPolicy,
    calculateFeeForPolicy,
    findPolicyByName
} from "./calculateFees";

type GetPolicyParams = {
    feePolicies: FeePolicy[];
    amount: number;
};

export type FeeCalculatorParams = {
    feePolicies: FeePolicy[];
    amount?: number;
    quantity?: number;
};

export type FeeCalculator = ({
    feePolicies,
    amount,
    quantity
}: FeeCalculatorParams) => FeePolicyWithTotal | null;

/**
 * A mapping to lookup the rule for a specific fee policy. These rules are static (do not change),
 * so we can use this to get the rule information
 */

export const _reservedRules = new Map<
    ReservedFeeEnum,
    { payer: FeePolicyPayer; recipient: FeePolicyRecipient }
>([
    [
        ReservedFeeEnum.DeliveryFee3P,
        {
            payer: FeePolicyPayer.Customer,
            recipient: FeePolicyRecipient.Snackpass
        }
    ],
    [
        ReservedFeeEnum.DeliveryCommission3P,
        {
            payer: FeePolicyPayer.Store,
            recipient: FeePolicyRecipient.Snackpass
        }
    ],
    [
        ReservedFeeEnum.DeliveryCost3P,
        {
            payer: FeePolicyPayer.Snackpass,
            recipient: FeePolicyRecipient.Integration
        }
    ],
    [
        ReservedFeeEnum.DeliveryFee1P,
        {
            payer: FeePolicyPayer.Customer,
            recipient: FeePolicyRecipient.Store
        }
    ],
    [
        ReservedFeeEnum.BagStoreFee,
        {
            payer: FeePolicyPayer.Customer,
            recipient: FeePolicyRecipient.Store
        }
    ],
    [
        ReservedFeeEnum.SnackpassConvenienceFee,
        {
            payer: FeePolicyPayer.Customer,
            recipient: FeePolicyRecipient.Snackpass
        }
    ]
]);

/**
 * Returns a curried function that takes fee policies and an amount. The function
 * finds the fee policy with the name corresponding to the reserved fee, and then calculates the total
 * for that policy (it returns null if there is no corresponding policy)
 *
 * @param reservedFee The reserved fee, ex. DeliveryIntegrationCustomer
 *
 */
export const _getReservedFee =
    (reservedFeeName: ReservedFeeEnum): FeeCalculator =>
    ({ feePolicies, amount }) => {
        const feePolicy = findPolicyByName(reservedFeeName, feePolicies);

        if (!feePolicy) {
            return null;
        }

        const total = calculateFeeForPolicy(amount || 0, feePolicy);

        return {
            total,
            fee: {
                ...feePolicy,
                // get the rules for the reserved fee bc they are static, and just dump them in here
                ..._reservedRules.get(reservedFeeName)
            }
        };
    };

/**
 * Builds a reserved fee policy tuple w/ the fee and the function that can be called
 * to get the total owed
 *
 * @param reservedFee The reserved fee
 */
const _buildPolicy = (
    reservedFee: ReservedFeeEnum
): [ReservedFeeEnum, FeeCalculator] => [
    reservedFee,
    _getReservedFee(reservedFee)
];

// NOTE: The `flat` component of a bag fee policy is the only relevant field
// here; the `percent` component is ignored.
export const calculateBagFee: FeeCalculator = ({
    feePolicies,
    quantity
}): FeePolicyWithTotal | null => {
    const bagFeePolicy = findPolicyByName(
        ReservedFeeEnum.BagStoreFee,
        feePolicies
    );

    if (!bagFeePolicy) {
        return null;
    }

    const total = convertToDinero(bagFeePolicy.flat)
        .multiply(quantity || 0)
        .toUnit();

    return {
        fee: bagFeePolicy,
        total
    };
};

/**
 * A mapping that can ge used to lookup the "calculator" function for a given reserved fee
 */
const feeCalculators = new Map<ReservedFeeEnum, FeeCalculator>([
    _buildPolicy(ReservedFeeEnum.DeliveryFee3P),
    _buildPolicy(ReservedFeeEnum.DeliveryCommission3P),
    [ReservedFeeEnum.BagStoreFee, calculateBagFee]
]);

/**
 * Calculates the fee for a policy safely (just in case we don't have a calculator for it)
 *
 * NOTE: It is expected that ALL ACTIVE FEES are provided to this method in `feePolicies`.
 *       In some cases (ex. Delivery) you will need to create a synthetic FeePolicy instance.
 *
 * @param feeName The reserved fee name
 * @param feePolicies List of policies
 * @param amount The amount
 */
const calculate = ({
    feeName,
    feePolicies,
    amount,
    quantity
}: {
    feeName: ReservedFeeEnum;
    feePolicies: FeePolicy[];
    amount?: number;
    quantity?: number;
}): FeePolicyWithTotal | null => {
    const calculator = feeCalculators.get(feeName);

    // Legacy compatibility -- any non-reserved fees default to the original, simplified fee calculation formula.
    // TODO(@money-devs): All legacy fees (e.g. FoodhallFee) should be migrated to the ReservedFee framework.
    return calculator
        ? calculator({ feePolicies, amount, quantity })
        : calculateCustomFeeWithPolicy({ feeName, feePolicies, amount });
};

export const Reserved = {
    feeCalculators,
    calculate,
    FeeEnum: ReservedFeeEnum
};
