import { Fulfillment, IPromotion, IStore } from "@snackpass/snackpass-types";
import {
    CartItem,
    SelectedDealItems,
    CartWhen,
    CartItemWithTaxInfo
} from "../../types";

export const ADD_MULTICART_ITEM = "ADD_MULTICART_ITEM";
export type ADD_MULTICART_ITEM = "ADD_MULTICART_ITEM";
export const CLEAR_MULTICART = "CLEAR_MULTICART";
export type CLEAR_MULTICART = "CLEAR_MULTICART";
export const REMOVE_MULTICART_ITEMS_BY_GROUP_UUID =
    "REMOVE_MULTICART_ITEMS_BY_GROUP_UUID";
export type REMOVE_MULTICART_ITEMS_BY_GROUP_UUID =
    "REMOVE_MULTICART_ITEMS_BY_GROUP_UUID";
export const CLEAR_EMPTY_CARTS = "CLEAR_EMPTY_CARTS";
export type CLEAR_EMPTY_CARTS = "CLEAR_EMPTY_CARTS";
export const SET_MULTICART_TIP = "SET_MULTICART_TIP";
export type SET_MULTICART_TIP = "SET_MULTICART_TIP";
export const SET_MULTICART_FULFILLMENT = "SET_MULTICART_FULFILLMENT";
export type SET_MULTICART_FULFILLMENT = "SET_MULTICART_FULFILLMENT";
export const SET_MULTICART_NOTES = "SET_MULTICART_NOTES";
export type SET_MULTICART_NOTES = "SET_MULTICART_NOTES";
export const SET_MULTICART_ADDRESS = "SET_MULTICART_ADDRESS";
export type SET_MULTICART_ADDRESS = "SET_MULTICART_ADDRESS";
export const SET_MULTICART_ITEMS = "SET_MULTICART_ITEMS";
export type SET_MULTICART_ITEMS = "SET_MULTICART_ITEMS";
export const SET_USE_CREDIT = "SET_USE_CREDIT";
export type SET_USE_CREDIT = "SET_USE_CREDIT";
export const ADD_DEAL_GROUP = "ADD_DEAL_GROUP";
export type ADD_DEAL_GROUP = "ADD_DEAL_GROUP";
export const REMOVE_DEAL_GROUP = "REMOVE_DEAL_GROUP";
export type REMOVE_DEAL_GROUP = "REMOVE_DEAL_GROUP";

// For order ahead
export const SET_MULTICART_WHEN = "SET_MULTICART_WHEN";
export type SET_MULTICART_WHEN = "SET_MULTICART_WHEN";
export const SET_MULTICART_SCHEDULE_DATE = "SET_MULTICART_SCHEDULE_DATE";
export type SET_MULTICART_SCHEDULE_DATE = "SET_MULTICART_SCHEDULE_DATE";

// For dine-in
export const SET_MULTICART_TABLE_NUMBER = "SET_MULTICART_TABLE_NUMBER";
export type SET_MULTICART_TABLE_NUMBER = typeof SET_MULTICART_TABLE_NUMBER;
export const CLEAR_MULTICART_TABLE_NUMBER = "CLEAR_MULTICART_TABLE_NUMBER";
export type CLEAR_MULTICART_TABLE_NUMBER = typeof CLEAR_MULTICART_TABLE_NUMBER;

// For fee policies
export const SET_MULTICART_NUMBER_OF_BAGS = "SET_MULTICART_NUMBER_OF_BAGS";
export type SET_MULTICART_NUMBER_OF_BAGS = typeof SET_MULTICART_NUMBER_OF_BAGS;

export type AddMultiCartItem = {
    type: ADD_MULTICART_ITEM;
    item: CartItem;
    store: IStore;
};
export const addMultiCartItem = (
    item: CartItem,
    store: IStore
): AddMultiCartItem => ({
    type: ADD_MULTICART_ITEM,
    item,
    store
});

export type ClearMultiCart = { type: CLEAR_MULTICART };
export const clearMultiCart = (): ClearMultiCart => ({ type: CLEAR_MULTICART });

export type RemoveMultiCartItemsByGroupUuid = {
    type: REMOVE_MULTICART_ITEMS_BY_GROUP_UUID;
    groupUuid: string;
};
export const removeMultiCartItemsByGroupUuid = (
    groupUuid: string
): RemoveMultiCartItemsByGroupUuid => ({
    type: REMOVE_MULTICART_ITEMS_BY_GROUP_UUID,
    groupUuid
});

// takes in an array of tips and assigns the tip to each store
// in order
export type SetMultiCartTip = {
    type: SET_MULTICART_TIP;
    store: IStore;
    tip: number;
};
export const setMultiCartTip = (
    store: IStore,
    tip: number
): SetMultiCartTip => ({
    type: SET_MULTICART_TIP,
    store,
    tip
});

// sets fulfillment type for all carts in multicart
export type SetMultiCartFulfillment = {
    type: SET_MULTICART_FULFILLMENT;
    fulfillment: Fulfillment;
};
export const setMultiCartFulfillment = (
    fulfillment: Fulfillment
): SetMultiCartFulfillment => ({
    type: SET_MULTICART_FULFILLMENT,
    fulfillment
});

export const REMOVE_MULTICART_ITEM = "REMOVE_MULTICART_ITEM";
export type REMOVE_MULTICART_ITEM = typeof REMOVE_MULTICART_ITEM;
export type RemoveMultiCartItem = {
    type: REMOVE_MULTICART_ITEM;
    itemIndex: number;
    store: IStore;
};
export const removeMultiCartItem = (
    itemIndex: number,
    store: IStore
): RemoveMultiCartItem => ({
    type: REMOVE_MULTICART_ITEM,
    itemIndex,
    store
});

// sets notes for a specific cart in the multicart
export type SetMultiCartNotes = {
    type: SET_MULTICART_NOTES;
    store: IStore;
    notes: string;
};
export const setMultiCartNotes = (
    store: IStore,
    notes: string
): SetMultiCartNotes => ({
    type: SET_MULTICART_NOTES,
    store,
    notes
});

// sets address for all carts in multicart
export type SetMultiCartAddress = {
    type: SET_MULTICART_ADDRESS;
    address: string;
};
export const setMultiCartAddress = (address: string): SetMultiCartAddress => ({
    type: SET_MULTICART_ADDRESS,
    address
});

// sets items for each cart in multicart
export type SetMultiCartItems = {
    type: SET_MULTICART_ITEMS;
    store: IStore;
    items: CartItemWithTaxInfo[];
};
export const setMultiCartItems = (
    store: IStore,
    items: CartItemWithTaxInfo[]
): SetMultiCartItems => ({
    type: SET_MULTICART_ITEMS,
    store,
    items
});

// sets useCredit for specific cart in multicart
export type SetUseCredit = {
    type: SET_USE_CREDIT;
    useCredit: boolean;
    store: IStore;
};
export const setUseCredit = (
    useCredit: boolean,
    store: IStore
): SetUseCredit => ({
    type: SET_USE_CREDIT,
    useCredit,
    store
});

// adds deal group to specific cart in multicart
export type AddDealGroup = {
    type: ADD_DEAL_GROUP;
    selectedDealItems: SelectedDealItems;
    promotion: IPromotion;
    store: IStore;
};
export const addDealGroup = (
    selectedDealItems: SelectedDealItems,
    promotion: IPromotion,
    store: IStore
): AddDealGroup => ({
    type: ADD_DEAL_GROUP,
    selectedDealItems,
    promotion,
    store
});

// removes deal group from specific cart in multicart
export type RemoveDealGroup = {
    type: REMOVE_DEAL_GROUP;
    dealGroupIndex: number;
    store: IStore;
};
export const removeDealGroup = (
    dealGroupIndex: number,
    store: IStore
): RemoveDealGroup => ({
    type: REMOVE_DEAL_GROUP,
    dealGroupIndex,
    store
});

// sets when for every cart in multicart
export type SetMultiCartWhen = { type: SET_MULTICART_WHEN; when: CartWhen };
export const setMultiCartWhen = (when: CartWhen): SetMultiCartWhen => ({
    type: SET_MULTICART_WHEN,
    when
});

// sets scheduled date for every cart in multicart
export type SetMultiCartScheduleDate = {
    type: SET_MULTICART_SCHEDULE_DATE;
    scheduledDate: Date | null;
};
export const setMultiCartScheduleDate = (
    scheduledDate: Date | null
): SetMultiCartScheduleDate => ({
    type: SET_MULTICART_SCHEDULE_DATE,
    scheduledDate
});

export type SetMultiCartTableNumber = {
    type: SET_MULTICART_TABLE_NUMBER;
    tableNumber: string;
};
export const setMultiCartTableNumber = (
    tableNumber: string
): SetMultiCartTableNumber => ({
    type: SET_MULTICART_TABLE_NUMBER,
    tableNumber
});

export type ClearMultiCartTableNumber = {
    type: CLEAR_MULTICART_TABLE_NUMBER;
};
export const clearMultiCartTableNumber = (): ClearMultiCartTableNumber => ({
    type: CLEAR_MULTICART_TABLE_NUMBER
});

export type SetMultiCartNumberOfBags = {
    type: SET_MULTICART_NUMBER_OF_BAGS;
    numberOfBags: number;
};
export const setMultiCartNumberOfBags = (
    numberOfBags: number
): SetMultiCartNumberOfBags => ({
    type: SET_MULTICART_NUMBER_OF_BAGS,
    numberOfBags
});

export type MultiCartActions =
    | AddMultiCartItem
    | ClearMultiCart
    | SetMultiCartAddress
    | SetMultiCartTip
    | SetMultiCartFulfillment
    | SetMultiCartNotes
    | SetMultiCartItems
    | AddDealGroup
    | RemoveDealGroup
    | SetUseCredit
    | SetMultiCartWhen
    | SetMultiCartScheduleDate
    | SetMultiCartTableNumber
    | ClearMultiCartTableNumber
    | RemoveMultiCartItemsByGroupUuid
    | SetMultiCartNumberOfBags
    | RemoveMultiCartItem;
