import {
    Addon,
    CartAdjustment,
    CartAdjustmentType,
    FeePolicy,
    ICart as ICartType,
    IConvenienceFeePolicy,
    IDiscount,
    IOrderItem,
    IPartyPromoTier,
    IPartyPromotion,
    IProduct,
    IPromotion,
    IReward,
    TransactionSource
} from "@snackpass/snackpass-types";
import { find, sumBy } from "lodash/fp";
import {
    CartAddon,
    CartAddonFlat,
    CartItem,
    CartItemFlat,
    CartItemWithTaxInfo,
    ICart
} from "../types";
import {
    centsToDollars,
    dollarsToCents,
    percentToDecimal,
    round,
    Time,
    toDollar
} from "../utils";
import { DD } from "../utils/Money";
import { calculateFeeForPolicy } from "./fees";
import { isNumber } from "lodash";

export function calcTotal(cartItems: CartItemFlat[] | IOrderItem[]): number {
    return round(sumBy<CartItemFlat | IOrderItem>("totalPrice", cartItems));
}

export function calcCartItemSubtotal(
    cartItems: CartItemFlat[] | IOrderItem[]
): number {
    return round(
        sumBy<CartItemFlat | IOrderItem>("totalPriceAfterDiscount", cartItems)
    );
}

export function calcCustomSurchargeTotal(
    adjustments: readonly CartAdjustment[]
): number {
    return round(
        sumBy(
            "flat",
            adjustments.filter(
                (adj) => adj.type === CartAdjustmentType.SurchargeFlat
            )
        )
    );
}

export function calcCustomDiscountsTotal(
    cartItems: CartItemFlat[] | IOrderItem[],
    adjustments: readonly CartAdjustment[]
): number {
    const discountPercent =
        sumBy(
            "percent",
            adjustments.filter(
                (adj) => adj.type === CartAdjustmentType.DiscountPercent
            )
        ) / 100;
    const discountFlat = sumBy(
        "flat",
        adjustments.filter(
            (adj) => adj.type === CartAdjustmentType.DiscountFlat
        )
    );

    const itemSubtotal = calcCartItemSubtotal(cartItems);
    const surchargeTotal = calcCustomSurchargeTotal(adjustments);

    return round(
        (itemSubtotal + surchargeTotal) * discountPercent + discountFlat
    );
}

export function calcSubtotal(
    cartItems: CartItemFlat[] | IOrderItem[],
    adjustments: readonly CartAdjustment[]
): number {
    const itemSubtotal = calcCartItemSubtotal(cartItems);
    const surchargeTotal = calcCustomSurchargeTotal(adjustments);
    const discountsTotal = calcCustomDiscountsTotal(cartItems, adjustments);

    return round(itemSubtotal + surchargeTotal - discountsTotal);
}

/**
 * Calculate the cost of all items in a cart pre-discount.
 *
 * @deprecated use {@link calcTotal} instead.
 */
export function calcGrossTotal(
    cartItems: CartItemFlat[] | IOrderItem[]
): number {
    return round(sumBy<CartItemFlat | IOrderItem>("totalPrice", cartItems));
}

export function calcStoreCreditApplied(
    userStoreCredit: number,
    giftCardCredit: number,
    maxCreditAmount: number
): number {
    // Do not use store credit if giftcard credit used.
    if (giftCardCredit) {
        return 0;
    }
    return Math.min(userStoreCredit, maxCreditAmount);
}

export function calcGiftCardCreditApplied(
    giftCardCredit: number,
    maxGiftCardCreditAmount: number
): number {
    return Math.min(giftCardCredit, maxGiftCardCreditAmount);
}

export function calcTaxableAmount(
    storeCreditApplied: number,
    subtotalPlusDeliveryFee: number,
    totalTaxableCustomFees: number,
    tip: number
): number {
    // Deduct this from the subtotal

    // Credit from tip is not part of getSubtotalPlusDeliveryFee, so we need to
    // deduct tip from credit used in this calculation.
    let creditDueToTip = 0;
    if (storeCreditApplied >= tip) {
        creditDueToTip = tip;
    } else {
        creditDueToTip = storeCreditApplied;
    }

    const ret =
        subtotalPlusDeliveryFee +
        totalTaxableCustomFees -
        (storeCreditApplied - creditDueToTip);

    return round(ret);
}

export function calcTax(taxableAmount: number, taxRate: number): number {
    // calculate tax based on cents rather than dollars to match server behavior
    const taxRateAsDecimal = percentToDecimal(taxRate);
    const taxableAmountCents = dollarsToCents(taxableAmount);
    const taxAmountCents = Math.round(taxableAmountCents * taxRateAsDecimal);
    return centsToDollars(taxAmountCents);
}

export function calcGlobalCreditApplied(
    storeCreditApplied: number,
    giftCardCreditAvailable: number,
    tax: number,
    totalUserCredit: number,
    maxCreditAmount: number
): number {
    // Do not use global credit if store credit used or gift card credit available.
    if (giftCardCreditAvailable) {
        return 0;
    } else if (storeCreditApplied) {
        return 0;
    } else if (totalUserCredit <= 0) {
        return 0;
    }

    // Global credit can cover tax as well.
    const total = maxCreditAmount + tax;

    return Math.min(totalUserCredit, total);
}

export function calcStoreAndGlobalCreditUsed(
    storeCreditApplied: number,
    globalCreditApplied: number
): number {
    return round(storeCreditApplied + globalCreditApplied);
}

export function calcTotalCreditUsed(
    storeCreditApplied: number,
    giftCardCreditApplied: number,
    globalCreditApplied: number
): number {
    return round(
        storeCreditApplied + giftCardCreditApplied + globalCreditApplied
    );
}

export function calcConvenienceFee(
    cartSubtotal: number,
    useCredit: boolean,
    giftCardCreditAvailable: number,
    storeAndGlobalCreditUsed: number,
    maxCreditAmount: number,
    transactionSource: TransactionSource,
    convenienceFeePolicies: IConvenienceFeePolicy[]
): number {
    // do not apply convenience fee for purchases made wholly with credit or discount
    if (
        cartSubtotal === 0 ||
        storeAndGlobalCreditUsed >= maxCreditAmount ||
        (useCredit && giftCardCreditAvailable >= maxCreditAmount)
    ) {
        return 0;
    }

    const policy = find(
        (policy) => policy.transactionSource === transactionSource,
        convenienceFeePolicies
    );

    return policy ? policy.value : 0;
}

export function calcSnackpassConvenienceFee(
    cartSubtotal: number,
    applicableFeePolicies: FeePolicy[]
): number {
    return calculateFeeForPolicy(
        cartSubtotal,
        find<FeePolicy>(
            (policy) => policy.name === "SNACKPASS_CONVENIENCE_FEE",
            applicableFeePolicies
        )
    );
}

export const calcSubtotalPlusTaxesAndFees = (
    subtotal: number,
    tax: number,
    storeConvenienceFee: number,
    customFees: number,
    deliveryFeeApplied: number
): number => {
    return round(
        round(subtotal) +
            round(tax) +
            round(storeConvenienceFee) +
            round(customFees) +
            round(deliveryFeeApplied)
    );
};

export const calcTotalWithoutCreditsApplied = (
    subtotal: number,
    tax: number,
    tip: number,
    storeConvenienceFee: number,
    customFees: number,
    deliveryFeeApplied: number
): number => {
    return round(
        round(subtotal) +
            round(tax) +
            round(tip) +
            round(storeConvenienceFee) +
            round(customFees) +
            round(deliveryFeeApplied)
    );
};

export const calcGetAmountPaidByCustomer = (
    subtotal: number,
    tax: number,
    storeCreditApplied: number,
    globalCreditApplied: number,
    giftCardCreditApplied: number,
    tip: number,
    storeConvenienceFee: number,
    customFees: number,
    deliveryFeeApplied: number
): number => {
    return round(
        round(subtotal) +
            round(tax) -
            round(storeCreditApplied) -
            round(giftCardCreditApplied) -
            round(globalCreditApplied) +
            round(tip) +
            round(storeConvenienceFee) +
            round(customFees) +
            round(deliveryFeeApplied)
    );
};

export function applyPercentOff(
    originalPrice: number,
    percentOff: number,
    maximumDiscount?: number
) {
    let discountAmount = (originalPrice * percentOff) / 100;
    if (maximumDiscount) {
        discountAmount = Math.min(discountAmount, maximumDiscount);
    }
    const ret = originalPrice - discountAmount;
    return round(ret);
}

export function applyDiscount(
    originalPrice: number,
    discount: IDiscount
): number {
    if (discount.percentOff !== undefined) {
        return applyPercentOff(
            originalPrice,
            discount.percentOff,
            discount.maximumDiscount
        );
    } else if (discount.newPrice !== undefined) {
        return discount.newPrice;
    } else if (discount.dollarsOff === 0 || discount.dollarsOff) {
        return Math.max(round(originalPrice - discount.dollarsOff), 0);
    }

    return originalPrice;
}

export function calcBasePriceAfterDiscount(
    basePrice: number,
    activePromotion?: { discount?: IDiscount | null } | null
): number {
    return activePromotion?.discount
        ? applyDiscount(basePrice, activePromotion.discount)
        : basePrice;
}

// Discounts apply to addons in certain situations
// - if it's percentOff and promotionOrRewardShouldDiscountAddons is true
// - otherwise (for newPrice and dollarsOff discounts) do not discount addons
export function promotionOrRewardShouldDiscountAddons({
    discount,
    shouldDiscountAddons
}: Pick<IPromotion | IReward, "discount" | "shouldDiscountAddons">): boolean {
    if (discount === undefined) {
        return false;
    }

    // only percent off discounts can discount addons
    if (discount.percentOff === undefined) {
        return false;
    }

    // if newPrice, do not discount add ons
    if (discount.newPrice !== undefined) {
        return false;
    }
    // do not discount add ons if promotionOrRewardShouldDiscountAddons field not true
    if (!shouldDiscountAddons) {
        return false;
    }

    return true;
}

// for addons, only discount the addons if percent off
export function applyDiscountToAddon(
    originalPrice: number,
    {
        discount,
        shouldDiscountAddons
    }: Pick<IPromotion | IReward, "discount" | "shouldDiscountAddons">
): number {
    if (
        discount &&
        promotionOrRewardShouldDiscountAddons({
            discount,
            shouldDiscountAddons
        }) &&
        discount.percentOff !== undefined
    ) {
        return applyPercentOff(originalPrice, discount.percentOff);
    }
    return originalPrice;
}

export function validateCartItemsProductHours(
    cart: ICart
): CartItemWithTaxInfo | null {
    for (const item of cart.items) {
        if (item.product && item.product.hours && !Time.isOpen(item.product)) {
            return item;
        }
    }
    return null;
}

/**
 * Get the prices that need to be calculated for the active cart item
 *
 * TODO: should this be in a selector?
 */
export function calcCartItemPrices(cartItem: CartItem): {
    basePrice: number;
    basePriceAfterDiscount: number;
    totalPrice: number;
    totalPriceAfterDiscount: number;
    addonsPrice: number;
    addonsPriceAfterDiscount: number;
    isDiscounted: boolean;
} {
    const {
        product,
        promotion,
        reward,
        dealItem,
        selectedAddons,
        partyTierOverride,
        weight
    } = cartItem;
    if (!product) {
        return {
            basePrice: 0,
            basePriceAfterDiscount: 0,
            addonsPrice: 0,
            addonsPriceAfterDiscount: 0,
            totalPrice: 0,
            totalPriceAfterDiscount: 0,
            isDiscounted: false
        };
    }

    let addonsPrice = 0;
    if (selectedAddons) {
        addonsPrice = selectedAddons.reduce(
            (prevVal, curr) =>
                prevVal + curr.addon.price * (curr.quantity || 1),
            0
        );
    }

    let discountAddons = false;
    // discount addons if not reward, promotion exists, and promotion should
    // discount addons, or if using a promo tier from party mode
    if (!reward && promotion) {
        discountAddons = Boolean(
            (promotion && promotionOrRewardShouldDiscountAddons(promotion)) ||
                partyTierOverride
        );
    }
    // discount addons if not promotion, reward exists, and promotion should
    // discount addons, or if using a promo tier from party mode
    if (reward && !promotion) {
        discountAddons = Boolean(
            (reward && promotionOrRewardShouldDiscountAddons(reward)) ||
                partyTierOverride
        );
    }

    let discount: IDiscount | null = null;
    if (discountAddons && (partyTierOverride || promotion || reward)) {
        discount =
            partyTierOverride?.discount ||
            promotion?.discount ||
            reward?.discount ||
            null;
    }

    let addonsPriceAfterDiscount = 0;
    if (selectedAddons) {
        addonsPriceAfterDiscount = selectedAddons
            .map((cartAddon) => {
                const discountedAddon = {
                    quantity: cartAddon.quantity,
                    price:
                        discount &&
                        discountAddons &&
                        discount.percentOff !== undefined
                            ? applyPercentOff(
                                  cartAddon.addon.price,
                                  discount.percentOff
                              )
                            : cartAddon.addon.price
                };
                return discountedAddon;
            })
            .reduce(
                (prevVal, curr) => prevVal + curr.price * (curr.quantity || 1),
                0
            );
    }

    const basePrice =
        weight && product.priceByWeight?.perUnit
            ? DD(product.priceByWeight.perUnit).multiply(weight.amount).toUnit()
            : product.price;

    const totalPrice = basePrice + addonsPrice;

    // set base price after discount
    // dealItem overrides, reward, rewardaSDZ˛ç overrides promotion
    let basePriceAfterDiscount = calcBasePriceAfterDiscount(
        basePrice,
        promotion
    );
    if (reward) {
        basePriceAfterDiscount = calcBasePriceAfterDiscount(basePrice, reward);
    }
    if (dealItem) {
        basePriceAfterDiscount = calcBasePriceAfterDiscount(
            basePrice,
            dealItem
        );
    }
    const totalPriceAfterDiscount =
        addonsPriceAfterDiscount + basePriceAfterDiscount;

    return {
        basePrice,
        basePriceAfterDiscount,
        addonsPrice,
        addonsPriceAfterDiscount,
        totalPrice,
        totalPriceAfterDiscount,
        isDiscounted: basePriceAfterDiscount !== basePrice
    };
}

export const calculatePrice = (
    item: { price: number },
    promotion: Pick<IPromotion | IReward, "discount"> | null
): number => {
    if (promotion?.discount?.percentOff !== undefined) {
        return applyPercentOff(
            item.price,
            promotion?.discount?.percentOff,
            promotion?.discount?.maximumDiscount
        );
    }
    return item.price;
};

export const calculateAddonPrice = (
    addon: { price: number } | { totalPrice: number },
    promotionOrReward: IPromotion | IReward | undefined | null
): number => {
    if (
        promotionOrReward?.discount &&
        promotionOrRewardShouldDiscountAddons(promotionOrReward) &&
        promotionOrReward.discount?.percentOff !== undefined
    ) {
        return applyPercentOff(
            "price" in addon ? addon.price : addon.totalPrice,
            promotionOrReward.discount.percentOff
        );
    }

    return "price" in addon ? addon.price : addon.totalPrice;
};

const _addTierDiscount =
    (partyPromo: IPartyPromotion, bestTier: IPartyPromoTier) =>
    (item: CartItemFlat) => ({
        ...item,
        promotion: {
            ...partyPromo,
            discount: bestTier.discount
        },
        partyTierOverride: bestTier
    });

const _transformAddon = (selectedAddon: CartAddonFlat): CartAddon => ({
    addon: {
        _id: selectedAddon._id,
        price: selectedAddon.price,
        name: selectedAddon.name
    },
    addonGroup: selectedAddon.addonGroup,
    quantity: selectedAddon.quantity
});

export const calcPrices = (item: CartItemFlat): CartItemFlat => ({
    ...item,
    ...calcCartItemPrices({
        ...item,
        selectedAddons: item.selectedAddons
            ? item.selectedAddons.map(_transformAddon)
            : []
    })
});

// compares the non-party promo that is attached to the cart item (if applicable)
// with the party mode promo and updates the cart item to contain the best
// promo between those.
export const getBestVersionOfCartItemInPartyMode = (
    item: CartItemFlat,
    partyPromo: IPartyPromotion,
    bestTier: IPartyPromoTier
): CartItemFlat => {
    const itemIfUsingPartyPromo = applyPartyDiscountToItem(
        item,
        partyPromo,
        bestTier
    );
    if (!item.promotion?.type || item.promotion.type === "PARTY") {
        return itemIfUsingPartyPromo;
    }
    const currentItemWithPopulatedDiscount = calcPrices(item);

    // a different, non-party promo is attached to this item,
    // now need to compare to party promo.
    return currentItemWithPopulatedDiscount.totalPriceAfterDiscount >
        itemIfUsingPartyPromo.totalPriceAfterDiscount
        ? itemIfUsingPartyPromo
        : currentItemWithPopulatedDiscount;
};

export const applyPartyDiscountToItem = (
    item: CartItemFlat,
    promotion: IPartyPromotion,
    bestTier: IPartyPromoTier
): CartItemFlat => calcPrices(_addTierDiscount(promotion, bestTier)(item));

export const applyPartyDiscountToItems = (
    items: CartItemFlat[],
    promotion: IPartyPromotion,
    bestTier: IPartyPromoTier
): CartItemFlat[] =>
    items.map((item) => applyPartyDiscountToItem(item, promotion, bestTier));

type CartTotalAmount = {
    formattedOriginalAmount: string;
    formattedFinalAmount: string;
    originalAmountNumber: number;
    finalAmountNumber: number;
    hasDiscount: boolean;
};

// used for ui purposes, doesn't affect actual amounts backend uses for purchase
export const getPartyTotalInfo = (
    percentOff: number,
    savedPartyCarts: { [key: string]: ICartType },
    localCartItems: CartItemFlat[],
    userId: string,
    localTipInDollars: number
): CartTotalAmount => {
    const myPartyCart = savedPartyCarts[userId];
    const myPartyCartExistsAndIsNotCanceled =
        myPartyCart?.items.length && !myPartyCart.isCanceled;

    // totals without discount (but excludes tip + tax)
    const myItems = myPartyCartExistsAndIsNotCanceled
        ? myPartyCart.items
        : localCartItems;
    const originalSubtotal = round(calcTotal(myItems));

    // subtotals with discounts (still excludes tip + tax)
    let finalSubtotal = 0;
    myItems.forEach((item: IOrderItem | CartItemFlat) => {
        const originalSubtotalForItem = item.totalPrice;
        if (item.promotion?.type === "PARTY" && percentOff) {
            finalSubtotal +=
                originalSubtotalForItem * ((100 - percentOff) / 100);
        } else if (item.promotion) {
            finalSubtotal += item.totalPriceAfterDiscount;
        } else {
            finalSubtotal += originalSubtotalForItem;
        }
    });
    finalSubtotal = round(finalSubtotal);

    // tip as dollar amount
    const tipAsDollarAmount = myPartyCartExistsAndIsNotCanceled
        ? myPartyCart.tipAmountCents / 100
        : localTipInDollars;

    // subtotals plus tip
    const originalSubtotalPlusTip = originalSubtotal + tipAsDollarAmount;
    const finalSubtotalPlusTip = finalSubtotal + tipAsDollarAmount;

    // subtotals plus tip, formatted as $0.00
    const formattedOriginalAmount = toDollar(originalSubtotalPlusTip);
    const formattedFinalAmount = toDollar(finalSubtotalPlusTip);

    return {
        formattedOriginalAmount,
        formattedFinalAmount,
        originalAmountNumber: originalSubtotalPlusTip,
        finalAmountNumber: finalSubtotalPlusTip,
        hasDiscount: finalSubtotalPlusTip < originalSubtotalPlusTip
    };
};
