import {
    Fulfillment,
    IPromotion,
    IStore,
    PickupEstimate,
    DeliveryQuote,
    CartAdjustment
} from "@snackpass/snackpass-types";
import {
    SelectedDealItems,
    CartWhen,
    CartItem,
    CartItemWithTaxInfo,
    DealSelectionItem,
    CartAddon
} from "../../types";
import { DD } from "../../utils/Money";

export const ADD_CART_ITEM = "ADD_CART_ITEM";
export type ADD_CART_ITEM = "ADD_CART_ITEM";
export const DUPLICATE_CART_ITEM_BY_INDEX = "DUPLICATE_CART_ITEM_BY_INDEX";
export type DUPLICATE_CART_ITEM_BY_INDEX = "DUPLICATE_CART_ITEM_BY_INDEX";
export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS";
export type CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export type REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const REMOVE_CART_ITEMS_BY_GROUP_UUID =
    "REMOVE_CART_ITEMS_BY_GROUP_UUID";
export type REMOVE_CART_ITEMS_BY_GROUP_UUID =
    typeof REMOVE_CART_ITEMS_BY_GROUP_UUID;
export const CLEAR_CART = "CLEAR_CART";
export type CLEAR_CART = "CLEAR_CART";
export const CLEAR_CART_BUT_NOT_FULFILLMENT = "CLEAR_CART_BUT_NOT_FULFILLMENT";
export type CLEAR_CART_BUT_NOT_FULFILLMENT = "CLEAR_CART_BUT_NOT_FULFILLMENT";
export const SET_CART_ADDRESS = "SET_CART_ADDRESS";
export type SET_CART_ADDRESS = "SET_CART_ADDRESS";
export const SET_CART_TIP = "SET_CART_TIP";
export type SET_CART_TIP = "SET_CART_TIP";
export const SET_CART_FULFILLMENT = "SET_CART_FULFILLMENT";
export type SET_CART_FULFILLMENT = "SET_CART_FULFILLMENT";
export const SET_CART_NOTES = "SET_CART_NOTES";
export type SET_CART_NOTES = "SET_CART_NOTES";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export type SET_CART_ITEMS = "SET_CART_ITEMS";
export const REMOVE_DEAL_GROUP = "REMOVE_DEAL_GROUP";
export type REMOVE_DEAL_GROUP = "REMOVE_DEAL_GROUP";
export const REMOVE_SINGLE_DEAL_ITEM = "REMOVE_SINGLE_DEAL_ITEM";
export type REMOVE_SINGLE_DEAL_ITEM = "REMOVE_SINGLE_DEAL_ITEM";
export const SET_USE_CREDIT = "SET_USE_CREDIT";
export type SET_USE_CREDIT = "SET_USE_CREDIT";
export const ADD_DEAL_GROUP = "ADD_DEAL_GROUP";
export type ADD_DEAL_GROUP = "ADD_DEAL_GROUP";
export const ADD_DEAL_ITEM = "ADD_DEAL_ITEM";
export type ADD_DEAL_ITEM = "ADD_DEAL_ITEM";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export type UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const UPDATE_CART_ITEM_ADDON_BY_GROUP_UUID =
    "UPDATE_CART_ITEM_ADDON_BY_GROUP_UUID";
export type UPDATE_CART_ITEM_ADDON_BY_GROUP_UUID =
    "UPDATE_CART_ITEM_ADDON_BY_GROUP_UUID";

// For storing pickup estimate
export const SET_PICKUP_ESTIMATE = "SET_PICKUP_ESTIMATE";
export type SET_PICKUP_ESTIMATE = "SET_PICKUP_ESTIMATE";
export const CLEAR_PICKUP_ESTIMATE = "CLEAR_PICKUP_ESTIMATE";
export type CLEAR_PICKUP_ESTIMATE = "CLEAR_PICKUP_ESTIMATE";

// For order ahead / catering
export const SET_CART_WHEN = "SET_CART_WHEN";
export type SET_CART_WHEN = "SET_CART_WHEN";
export const SET_CART_CATERING = "SET_CART_CATERING";
export type SET_CART_CATERING = "SET_CART_CATERING";
export const SET_CART_CATERING_HEADCOUNT = "SET_CART_CATERING_HEADCOUNT";
export type SET_CART_CATERING_HEADCOUNT = "SET_CART_CATERING_HEADCOUNT";
export const SET_CART_CATERING_EVENT = "SET_CART_CATERING_EVENT";
export type SET_CART_CATERING_EVENT = "SET_CART_CATERING_EVENT";
export const SET_CART_CATERING_SCHEDULE_DATE =
    "SET_CART_CATERING_SCHEDULE_DATE";
export type SET_CART_CATERING_SCHEDULE_DATE = "SET_CART_CATERING_SCHEDULE_DATE";
export const SET_CART_STORE = "SET_CART_STORE";
export type SET_CART_STORE = typeof SET_CART_STORE;
export const ENTER_CATERING_MODE = "ENTER_CATERING_MODE";
export type ENTER_CATERING_MODE = typeof ENTER_CATERING_MODE;
export const EXIT_CATERING_MODE = "EXIT_CATERING_MODE";
export type EXIT_CATERING_MODE = typeof EXIT_CATERING_MODE;

// For dine-in
export const SET_CART_TABLE_NUMBER = "SET_CART_TABLE_NUMBER";
export type SET_CART_TABLE_NUMBER = typeof SET_CART_TABLE_NUMBER;
export const CLEAR_CART_TABLE_NUMBER = "CLEAR_CART_TABLE_NUMBER";
export type CLEAR_CART_TABLE_NUMBER = typeof CLEAR_CART_TABLE_NUMBER;

// For Delivery integrations
export const SET_CART_DELIVERY_QUOTES = "SET_CART_DELIVERY_QUOTES";
export type SET_CART_DELIVERY_QUOTES = typeof SET_CART_DELIVERY_QUOTES;
export const CLEAR_CART_DELIVERY_QUOTES = "CLEAR_CART_DELIVERY_QUOTES";
export type CLEAR_CART_DELIVERY_QUOTES = typeof CLEAR_CART_DELIVERY_QUOTES;
export const SELECT_CART_DELIVERY_QUOTE = "SELECT_CART_DELIVERY_QUOTE";
export type SELECT_CART_DELIVERY_QUOTE = typeof SELECT_CART_DELIVERY_QUOTE;
export const CLEAR_SELECTED_CART_DELIVERY_QUOTE =
    "CLEAR_SELECTED_CART_DELIVERY_QUOTE";
export type CLEAR_SELECTED_CART_DELIVERY_QUOTE =
    typeof CLEAR_SELECTED_CART_DELIVERY_QUOTE;
export const SET_CART_STORE_CAN_DELIVER = "SET_CART_STORE_CAN_DELIVER";
export type SET_CART_STORE_CAN_DELIVER = typeof SET_CART_STORE_CAN_DELIVER;
export const SET_CART_DELIVERY_ERROR = "SET_CART_DELIVERY_ERROR";
export type SET_CART_DELIVERY_ERROR = typeof SET_CART_DELIVERY_ERROR;

// For Fee Policies
export const SET_NUMBER_OF_BAGS = "SET_NUMBER_OF_BAGS";
export type SET_NUMBER_OF_BAGS = typeof SET_NUMBER_OF_BAGS;

export const ADD_CART_ADJUSTMENT = "ADD_CART_ADJUSTMENT";
export type ADD_CART_ADJUSTMENT = typeof ADD_CART_ADJUSTMENT;

export const UPDATE_CART_ADJUSTMENT_BY_UUID = "UPDATE_CART_ADJUSTMENT_BY_UUID";
export type UPDATE_CART_ADJUSTMENT_BY_UUID =
    typeof UPDATE_CART_ADJUSTMENT_BY_UUID;

export const REMOVE_CART_ADJUSTMENT_BY_UUID = "REMOVE_CART_ADJUSTMENT_BY_UUID";
export type REMOVE_CART_ADJUSTMENT_BY_UUID =
    typeof REMOVE_CART_ADJUSTMENT_BY_UUID;

export const CLEAR_CART_DISCOUNTS = "CLEAR_CART_DISCOUNTS";
export type CLEAR_CART_DISCOUNTS = typeof CLEAR_CART_DISCOUNTS;

export const CLEAR_CART_ADJUSTMENTS = "CLEAR_CART_ADJUSTMENTS";
export type CLEAR_CART_ADJUSTMENTS = typeof CLEAR_CART_ADJUSTMENTS;

export type SetCartDeliveryQuotes = {
    type: SET_CART_DELIVERY_QUOTES;
    deliveryQuotes: DeliveryQuote[];
};
export const setCartDeliveryQuotes = (
    deliveryQuotes: DeliveryQuote[]
): SetCartDeliveryQuotes => ({
    type: SET_CART_DELIVERY_QUOTES,
    deliveryQuotes
});

export type SetCartStoreCanDeliver = {
    type: SET_CART_STORE_CAN_DELIVER;
    canDeliver: boolean;
};
export const setCartStoreCanDeliver = (
    canDeliver: boolean
): SetCartStoreCanDeliver => ({ type: SET_CART_STORE_CAN_DELIVER, canDeliver });

export type SetCartDeliveryError = {
    type: SET_CART_DELIVERY_ERROR;
    error: string | null;
};
export const setCartDeliveryError = (
    error: string | null
): SetCartDeliveryError => ({ type: SET_CART_DELIVERY_ERROR, error });

export type ClearCartDeliveryQuotes = {
    type: CLEAR_CART_DELIVERY_QUOTES;
};
export const clearCartDeliveryQuotes = (): ClearCartDeliveryQuotes => ({
    type: CLEAR_CART_DELIVERY_QUOTES
});

export type SelectCartDeliveryQuote = {
    type: SELECT_CART_DELIVERY_QUOTE;
    deliveryQuote: DeliveryQuote;
};
export const selectCartDeliveryQuote = (
    deliveryQuote: DeliveryQuote
): SelectCartDeliveryQuote => ({
    type: SELECT_CART_DELIVERY_QUOTE,
    deliveryQuote
});

export type ClearSelectedCartDeliveryQuote = {
    type: CLEAR_SELECTED_CART_DELIVERY_QUOTE;
};
export const clearSelectedCartDeliveryQuote =
    (): ClearSelectedCartDeliveryQuote => ({
        type: CLEAR_SELECTED_CART_DELIVERY_QUOTE
    });

export type AddCartItem = {
    type: ADD_CART_ITEM;
    item: CartItem;
    store: IStore;
};
export const addCartItem = (item: CartItem, store: IStore): AddCartItem => ({
    type: ADD_CART_ITEM,
    item,
    store
});

export type DuplicateCartItemByIndex = {
    type: DUPLICATE_CART_ITEM_BY_INDEX;
    cartItemIndex: number;
    store: IStore;
};
export const duplicateCartItemByIndex = (
    cartItemIndex: number,
    store: IStore
): DuplicateCartItemByIndex => ({
    type: DUPLICATE_CART_ITEM_BY_INDEX,
    cartItemIndex,
    store
});

// clearing cart but still in the same store
export type ClearCartItems = {
    type: CLEAR_CART_ITEMS;
};
export const clearCartItems = (): ClearCartItems => ({
    type: CLEAR_CART_ITEMS
});

export type ClearCart = {
    type: CLEAR_CART;
};
export const clearCart = (): ClearCart => ({ type: CLEAR_CART });

export type RemoveCartItem = {
    type: REMOVE_CART_ITEM;
    cartItemIndex: number;
};
export const removeCartItem = (cartItemIndex: number): RemoveCartItem => ({
    type: REMOVE_CART_ITEM,
    cartItemIndex
});

export type RemoveCartItemsByGroupUuid = {
    type: REMOVE_CART_ITEMS_BY_GROUP_UUID;
    groupUuid: string;
};
export const removeCartItemsByGroupUuid = (
    groupUuid: string
): RemoveCartItemsByGroupUuid => ({
    type: REMOVE_CART_ITEMS_BY_GROUP_UUID,
    groupUuid
});

export type ClearCartButNotFulfillment = {
    type: CLEAR_CART_BUT_NOT_FULFILLMENT;
};
export const clearCartButNotFulfillment = (): ClearCartButNotFulfillment => ({
    type: CLEAR_CART_BUT_NOT_FULFILLMENT
});

export type SetCartTip = {
    type: SET_CART_TIP;
    tip: number;
};
export const setCartTip = (tip: number): SetCartTip => ({
    type: SET_CART_TIP,
    tip: DD(tip).toUnit() // Ensure properly truncated to whole cents!
});

export type SetCartFulfillment = {
    type: SET_CART_FULFILLMENT;
    fulfillment: Fulfillment;
};
export const setCartFulfillment = (
    fulfillment: Fulfillment
): SetCartFulfillment => ({ type: SET_CART_FULFILLMENT, fulfillment });

export type SetCartNotes = {
    type: SET_CART_NOTES;
    notes: string;
};
export const setCartNotes = (notes: string): SetCartNotes => ({
    type: SET_CART_NOTES,
    notes
});

export type SetCartAddress = {
    type: SET_CART_ADDRESS;
    address: string;
};
export const setCartAddress = (address: string): SetCartAddress => ({
    type: SET_CART_ADDRESS,
    address
});

export type SetCartItems = {
    type: SET_CART_ITEMS;
    items: CartItemWithTaxInfo[];
    store: IStore;
};
export const setCartItems = (
    items: CartItemWithTaxInfo[],
    store: IStore
): SetCartItems => ({
    type: SET_CART_ITEMS,
    items,
    store
});

export type SetUseCredit = {
    type: SET_USE_CREDIT;
    useCredit: boolean;
};
export const setUseCredit = (useCredit: boolean): SetUseCredit => ({
    type: SET_USE_CREDIT,
    useCredit
});

export type AddDealGroup = {
    type: ADD_DEAL_GROUP;
    selectedDealItems: SelectedDealItems;
    promotion: IPromotion;
};
export const addDealGroup = (
    selectedDealItems: SelectedDealItems,
    promotion: IPromotion
): AddDealGroup => ({
    type: ADD_DEAL_GROUP,
    selectedDealItems,
    promotion
});

export type AddDealItem = {
    type: ADD_DEAL_ITEM;
    dealGroupIndex: number;
    item: DealSelectionItem;
};
export const addDealItem = (
    dealGroupIndex: number,
    item: DealSelectionItem
): AddDealItem => ({
    type: ADD_DEAL_ITEM,
    dealGroupIndex,
    item
});

export type RemoveDealGroup = {
    type: REMOVE_DEAL_GROUP;
    dealGroupIndex: number;
};
export const removeDealGroup = (dealGroupIndex: number): RemoveDealGroup => ({
    type: REMOVE_DEAL_GROUP,
    dealGroupIndex
});

export type RemoveSingleDealItem = {
    type: REMOVE_SINGLE_DEAL_ITEM;
    dealGroupIndex: number;
    dealItemId: string;
};
export const removeSingleDealItem = (
    dealGroupIndex: number,
    dealItemId: string
): RemoveSingleDealItem => ({
    type: REMOVE_SINGLE_DEAL_ITEM,
    dealGroupIndex,
    dealItemId
});

export type UpdateCartItem = {
    type: UPDATE_CART_ITEM;
    item: CartItem;
    itemIndex: number;
    store: IStore;
};
export const updateCartItem = (
    item: CartItem,
    itemIndex: number,
    store: IStore
): UpdateCartItem => ({
    type: UPDATE_CART_ITEM,
    item,
    itemIndex,
    store
});

export type UpdateCartItemAddonByGroupUuid = {
    type: UPDATE_CART_ITEM_ADDON_BY_GROUP_UUID;
    groupUuid: string;
    cartAddon: CartAddon;
};
export const updateCartItemAddonByGroupUUID = (
    groupUuid: string,
    cartAddon: CartAddon
): UpdateCartItemAddonByGroupUuid => ({
    type: UPDATE_CART_ITEM_ADDON_BY_GROUP_UUID,
    groupUuid,
    cartAddon
});

export type SetCartWhen = {
    type: SET_CART_WHEN;
    when: CartWhen;
};
export const setCartWhen = (when: CartWhen): SetCartWhen => ({
    type: SET_CART_WHEN,
    when
});

export type SetCartCateringHeadcount = {
    type: SET_CART_CATERING_HEADCOUNT;
    headcount: number;
};
export const setCartCateringHeadcount = (
    headcount: number
): SetCartCateringHeadcount => ({
    type: SET_CART_CATERING_HEADCOUNT,
    headcount
});

export type SetCartCateringEvent = {
    type: SET_CART_CATERING_EVENT;
    eventType: string;
};
export const setCartCateringEvent = (
    eventType: string
): SetCartCateringEvent => ({
    type: SET_CART_CATERING_EVENT,
    eventType
});

export type SetCartCatering = {
    type: SET_CART_CATERING;
    isCatering: boolean;
};
export const setCartCatering = (isCatering: boolean): SetCartCatering => ({
    type: SET_CART_CATERING,
    isCatering
});

export type EnterCateringMode = {
    type: ENTER_CATERING_MODE;
};
export const enterCateringMode = (): EnterCateringMode => ({
    type: ENTER_CATERING_MODE
});

export type ExitCateringMode = {
    type: EXIT_CATERING_MODE;
};
export const exitCateringMode = (): ExitCateringMode => ({
    type: EXIT_CATERING_MODE
});

export type SetCartCateringScheduleDate = {
    type: SET_CART_CATERING_SCHEDULE_DATE;
    scheduledDate: Date | null;
};
export const setCartCateringScheduleDate = (
    scheduledDate: Date | null
): SetCartCateringScheduleDate => ({
    type: SET_CART_CATERING_SCHEDULE_DATE,
    scheduledDate
});

export type SetCartStore = {
    type: SET_CART_STORE;
    store: IStore | null;
};
export const setCartStore = (store: IStore | null): SetCartStore => ({
    type: SET_CART_STORE,
    store
});

export type SetCartTableNumber = {
    type: SET_CART_TABLE_NUMBER;
    tableNumber: string;
};
export const setCartTableNumber = (
    tableNumber: string
): SetCartTableNumber => ({
    type: SET_CART_TABLE_NUMBER,
    tableNumber
});

export type ClearCartTableNumber = {
    type: CLEAR_CART_TABLE_NUMBER;
};
export const clearCartTableNumber = (): ClearCartTableNumber => ({
    type: CLEAR_CART_TABLE_NUMBER
});

export type SetPickupEstimate = {
    type: SET_PICKUP_ESTIMATE;
    pickupEstimate: PickupEstimate;
};
export const setPickupEstimate = (
    pickupEstimate: PickupEstimate
): SetPickupEstimate => ({
    type: SET_PICKUP_ESTIMATE,
    pickupEstimate
});

export type ClearPickupEstimate = { type: CLEAR_PICKUP_ESTIMATE };
export const clearPickupEstimate = (): ClearPickupEstimate => ({
    type: CLEAR_PICKUP_ESTIMATE
});

export type SetNumberOfBags = {
    type: SET_NUMBER_OF_BAGS;
    numberOfBags: number;
};
export const setNumberOfBags = (numberOfBags: number): SetNumberOfBags => ({
    type: SET_NUMBER_OF_BAGS,
    numberOfBags
});

export type AddCartAdjustment = {
    type: ADD_CART_ADJUSTMENT;
    cartAdjustment: CartAdjustment;
};
export const addCartAdjustment = (
    cartAdjustment: CartAdjustment
): AddCartAdjustment => ({
    type: ADD_CART_ADJUSTMENT,
    cartAdjustment
});

export type UpdateCartAdjustmentByUuid = {
    type: UPDATE_CART_ADJUSTMENT_BY_UUID;
    uuid: string;
    updatedAdjustment: CartAdjustment;
};
export const updateCartAdjustmentByUuid = (
    uuid: string,
    updatedAdjustment: CartAdjustment
) => ({
    type: UPDATE_CART_ADJUSTMENT_BY_UUID,
    uuid,
    updatedAdjustment
});

export type RemoveCartAdjustmentByUuid = {
    type: REMOVE_CART_ADJUSTMENT_BY_UUID;
    uuid: string;
};
export const removeCartAdjustmentByUuid = (
    uuid: string
): RemoveCartAdjustmentByUuid => ({
    type: REMOVE_CART_ADJUSTMENT_BY_UUID,
    uuid
});

export type ClearCartDiscounts = {
    type: CLEAR_CART_DISCOUNTS;
};
export const clearCartDiscounts = (): ClearCartDiscounts => ({
    type: CLEAR_CART_DISCOUNTS
});

export type ClearCartAdjustments = {
    type: CLEAR_CART_ADJUSTMENTS;
};
export const clearCartAdjustments = (): ClearCartAdjustments => ({
    type: CLEAR_CART_ADJUSTMENTS
});

export type CartActions =
    | AddCartItem
    | DuplicateCartItemByIndex
    | ClearCartItems
    | ClearCart
    | ClearCartButNotFulfillment
    | SetCartAddress
    | SetCartTip
    | SetCartFulfillment
    | SetCartNotes
    | SetCartItems
    | AddDealGroup
    | AddDealItem
    | RemoveDealGroup
    | RemoveSingleDealItem
    | SetUseCredit
    | RemoveCartItem
    | UpdateCartItem
    | UpdateCartItemAddonByGroupUuid
    | SetCartWhen
    | SetCartCatering
    | SetCartCateringEvent
    | SetCartCateringHeadcount
    | SetCartCateringScheduleDate
    | SetCartCateringEvent
    | SetCartStore
    | SetCartTableNumber
    | ClearCartTableNumber
    | RemoveCartItemsByGroupUuid
    | SetCartDeliveryQuotes
    | ClearCartDeliveryQuotes
    | SelectCartDeliveryQuote
    | ClearSelectedCartDeliveryQuote
    | SetPickupEstimate
    | ClearPickupEstimate
    | SetCartStoreCanDeliver
    | SetCartDeliveryError
    | SetNumberOfBags
    | EnterCateringMode
    | ExitCateringMode
    | AddCartAdjustment
    | UpdateCartAdjustmentByUuid
    | RemoveCartAdjustmentByUuid
    | ClearCartDiscounts
    | ClearCartAdjustments;
