import constant from "lodash/fp/constant";
import {
    activeCartItem,
    cart,
    giftCards,
    dealSelection,
} from "@snackpass/accounting";

import user from "./user";
import activeChain from "./activeChain";
import activeChainStores from "./activeChainStores";
import punchcards from "./punchcards";
import myLocation from "./myLocation";
import itemSearch from "./itemSearch";
import utmTracking from "./utmTracking";
import { authStatus } from "./authStatus";
import { modals } from "./modals";
import { purchaseReceiptReducer } from "./purchaseReceipt";
import { modalHelper } from "./modalHelper";
import tip from "./tip";
import payment from "./payment";
import ooCart from "./ooCart";
import pastOrders from "./pastOrders";
import fulfillment from "./fulfillment";
import { combineReducers } from "@reduxjs/toolkit";
import { menuSlice } from "#menu";
import promotion from "./promotion";
import userName from "./userName";
import { activeStoreSlice } from "#activeStore";
import { cartSlice } from "#cart";
import marketingConsent from "./marketingConsent";
import locations from "./locations";
import corporateMap from "./corporateMap";

const ALL_REDUCERS = {
    utmTracking,
    user,
    activeChain,
    activeChainStores,
    cart,
    giftCards,
    activeCartItem,
    myLocation,
    punchcards,
    purchaseReceiptReducer,
    transactionSource: constant("online"),
    itemSearch,
    authStatus,
    modals,
    modalHelper,
    tip,
    payment,
    ooCart,
    pastOrders,
    fulfillment,
    promotion,
    userName,
    dealSelection,
    marketingConsent,
    locations,
    corporateMap,
    [activeStoreSlice.name]: activeStoreSlice.reducer,
    [menuSlice.name]: menuSlice.reducer,
    [cartSlice.name]: cartSlice.reducer,
};

export const rootReducer = combineReducers(ALL_REDUCERS);

export type ReduxState = typeof ALL_REDUCERS;
