import { useDispatch, useSelector } from "react-redux";
import { showModal, ModalsName, getActivePromotion } from "@/redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SpecialProductType } from "@snackpass/snackpass-types";
import { ActiveCartItemActions } from "@snackpass/accounting";
import { selectProducts } from "#menu";
import { usePointsAfterPromotion } from "./usePointsAfterPromotion";

// This API allows you to trigger the open of the digital gift card modal from a button click
export interface AutoOpenDGCProductAPI {
    openDGCModal: () => void;
}

type Props = {
    checkOnInitialLoad: boolean;
};

export function useAutoOpenDGCProduct({
    checkOnInitialLoad,
}: Props): AutoOpenDGCProductAPI {
    const dispatch = useDispatch();
    const shouldInitiallyOpenDigitalGiftCard = useMemo(() => {
        return window.location.pathname.includes("/giftcard");
    }, []);

    const [shouldOpen, setShouldOpen] = useState(
        shouldInitiallyOpenDigitalGiftCard && checkOnInitialLoad,
    );
    const activePromotion = useSelector(getActivePromotion);
    const products = useSelector(selectProducts);
    useEffect(() => {
        if (products.length === 0 || shouldOpen === false) {
            return;
        }
        setShouldOpen(false); // We see the products, and we're responding to the open request. Hence set the request to done
        const dgcProduct = products.find(
            (product) =>
                product.specialProductType ===
                SpecialProductType.DigitalGiftCard,
        );
        if (dgcProduct) {
            dispatch(
                ActiveCartItemActions.setActiveCartItem(
                    dgcProduct,
                    activePromotion ?? undefined,
                    undefined,
                    undefined,
                    0,
                ),
            );
            dispatch(showModal(ModalsName.productModal));
        }
    }, [dispatch, shouldOpen, products]);

    const openDGCModal = useCallback(() => {
        setShouldOpen(true);
    }, []);

    return useMemo(
        () => ({
            openDGCModal,
        }),
        [openDGCModal],
    );
}
