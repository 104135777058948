import React from "react";
import {
    CartItem,
    CartSelectors,
    CheckoutSelectors,
    DealGroup as IDealGroup,
    flattenCartItem,
} from "@snackpass/accounting";
import CartItemComponent from "./CartItem";
import { useSelector } from "react-redux";
import { InfoCircledIcon } from "@radix-ui/react-icons";

export type Props = {
    dealGroup: IDealGroup;
    dealGroupIndex: number;
};

const DealGroup: React.FC<Props> = ({ dealGroup, dealGroupIndex }) => {
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const subtotal = useSelector(CheckoutSelectors.getSubtotal);
    const nonDealCartItems = useSelector(CheckoutSelectors.getNonDealCartItems);
    const validateMessage = CheckoutSelectors.getDealGroupValidationMessage(
        fulfillment,
        subtotal,
        nonDealCartItems as CartItem[],
        dealGroup,
    );

    return (
        <>
            {validateMessage !== "validated" && (
                <div className="bg-alert/10 border border-alert/20 rounded-md p-2 text-alert mx-4 flex flex-row items-center">
                    <div className="basis-1/8">
                        <InfoCircledIcon className="mr-3 w-4 h-4" />
                    </div>
                    <div className="basis-7/8"> {validateMessage}</div>
                </div>
            )}
            {dealGroup.items.map((item, index) => {
                const cartItem = flattenCartItem(item.cartItem);
                return (
                    <CartItemComponent
                        key={index}
                        item={cartItem}
                        cartItemIndex={index}
                        dealGroupIndex={dealGroupIndex}
                        isDealItem
                    />
                );
            })}
        </>
    );
};

export default DealGroup;
