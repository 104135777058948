import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IPromotion, IReward } from "@snackpass/snackpass-types";
import { useSetRewardPromos, useSetRewards } from "@snackpass/loyalty-react";

import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import { ModalsName, getUserIsLoggedIn, hideModal, showModal } from "@/redux";
import ModalContent from "./Content";
import { Segment, SegmentEventNames } from "src/utils/Segment";
import { useMediaQuery } from "react-responsive";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const RewardOptionsModal = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const { setActiveRewardPromo } = useSetRewardPromos();
    const { setActiveReward } = useSetRewards();
    const dispatch = useDispatch();

    const [_activeRewardPromo, _setActiveRewardPromo] =
        useState<IPromotion | null>(null); // TODO i'm not sure we need these states
    const [_activeReward, _setActiveReward] = useState<IReward | null>(null);

    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const rewardOptionsModal = useSelector(
        // @ts-ignore
        (state: ReduxState) => state.modals.modals.rewardOptionsModal,
    );
    const onHide = () => {
        dispatch(hideModal(ModalsName.rewardOptionsModal));
        _setActiveRewardPromo(null);
        _setActiveReward(null);
    };
    const handleLogIn = () => {
        onHide();
        setTimeout(() => {
            dispatch(showModal(ModalsName.authenticationModal));
        }, 300);
    };

    const handleConfirm = () => {
        setActiveReward(_activeReward);
        setActiveRewardPromo(_activeRewardPromo);
        if (_activeRewardPromo) {
            Segment.track(SegmentEventNames.REWARD_PROMO_VIEWED, {
                reward_promo: _activeRewardPromo,
                is_online_ordering: true,
            });
        }
        if (_activeReward) {
            Segment.track(SegmentEventNames.REWARD_VIEWED, {
                reward_promo: _activeReward,
                is_online_ordering: true,
            });
        }
        onHide();
        setTimeout(() => {
            // for more smooth transition between two modals
            dispatch(showModal(ModalsName.claimRewardModal));
        }, 250);
    };

    if (isDesktop) {
        return (
            <Dialog open={rewardOptionsModal} onOpenChange={onHide}>
                <DialogContent
                    onEscapeKeyDown={onHide}
                    className="overflow-y-scroll hidden-scroll"
                >
                    <div className="flex flex-col h-full relative w-full">
                        <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    Redeem points for rewards
                                </div>
                            </div>
                        </div>
                        <div className="mx-6 mb-6">
                            <ModalContent
                                setActiveRewardPromo={_setActiveRewardPromo}
                                activeRewardPromo={_activeRewardPromo}
                                activeReward={_activeReward}
                                setActiveReward={_setActiveReward}
                            />
                        </div>
                        <DialogFooter className="bg-white border-t p-6 md:px-6">
                            <div className="w-full">
                                <div className="flex flex-1 items-center justify-center"></div>
                                {isUserLoggedIn ? (
                                    <div className="flex flex-col justify-between gap-4">
                                        <Button
                                            className="w-full"
                                            label={"Apply Reward"}
                                            disabled={
                                                !_activeRewardPromo &&
                                                !_activeReward
                                            }
                                            onPress={() => {
                                                handleConfirm();
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-between gap-4">
                                        <Button
                                            className="w-full"
                                            label="Log in or sign up"
                                            onPress={() => {
                                                handleLogIn();
                                            }}
                                        />
                                        <Button
                                            className="w-full"
                                            label="Done"
                                            onPress={onHide}
                                            variant="outline"
                                        />
                                    </div>
                                )}
                            </div>
                        </DialogFooter>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={rewardOptionsModal} onOpenChange={onHide}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Redeem points for rewards</SheetTitle>
                </SheetHeader>
                <div className="overflow-auto">
                    <div className="overflow-scroll [-ms-overflow-style:'none'] [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden">
                        <div className="w-full pb-16 px-4">
                            <ModalContent
                                setActiveRewardPromo={_setActiveRewardPromo}
                                activeRewardPromo={_activeRewardPromo}
                                activeReward={_activeReward}
                                setActiveReward={_setActiveReward}
                            />
                        </div>
                    </div>
                </div>
                <SheetFooter className="px-4 py-4 border-t">
                    <SheetClose asChild>
                        <div className="w-full">
                            <div className="flex flex-1 items-center justify-center"></div>
                            {isUserLoggedIn ? (
                                <div className="flex flex-col justify-between gap-4">
                                    <Button
                                        className="w-full"
                                        label={"Apply Reward"}
                                        disabled={
                                            !_activeRewardPromo &&
                                            !_activeReward
                                        }
                                        onPress={() => {
                                            handleConfirm();
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="flex flex-col justify-between gap-4">
                                    <Button
                                        className="w-full"
                                        label="Log in"
                                        onPress={() => {
                                            handleLogIn();
                                        }}
                                    />
                                    <Button
                                        className="w-full"
                                        label="Done"
                                        onPress={onHide}
                                        variant="outline"
                                    />
                                </div>
                            )}
                        </div>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default RewardOptionsModal;
