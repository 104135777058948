import { SystemColors } from "@snackpass/design-system";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import fp from "lodash/fp";
import React, { useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import { useDispatch, useSelector } from "react-redux";
import {
    getUser,
    getUserFirstName,
    getUserIsLoggedIn,
    getUserLastName,
    setUser,
} from "src/redux";
import uuid from "uuid";
import { isNetworkError } from "src/utils/Api/REST";
import { Api, cn } from "../../utils";
import { sendError } from "src/utils/Errors";
import { Button } from "../ui/button";

export interface Props {
    onSuccess: (user: any) => void;
    cardInputName?: string | null;
    buttonText?: string;
    isSubmitting?: boolean;
    addCardButtonClassName?: string;
}

const CardInput = ({ onSuccess, addCardButtonClassName }: Props) => {
    const dispatch = useDispatch();
    const _setUser = fp.compose(dispatch, setUser);
    const firstName = useSelector(getUserFirstName);
    const lastName = useSelector(getUserLastName);
    const [error, setError] = useState<string | null>(null);
    const [idempotencyKey, setIdempotencyKey] = useState<string>(uuid());
    const [isLoading, setLoading] = useState(false);
    const elements = useElements();
    const stripe = useStripe();
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const refreshIdempotencyKey = () => setIdempotencyKey(uuid());
    const user = useSelector(getUser);

    const _updateUserName = async () => {
        if (
            user?.name ||
            (user?.firstName && user?.lastName) ||
            (!firstName && !lastName)
        ) {
            return;
        }
        try {
            const res = await Api.users.update({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                name: `${firstName} ${lastName}`,
            });
            _setUser(fp.prop("data.user", res));
        } catch (err) {
            sendError(err);
            return "Failed to update user.";
        }
    };

    const onSubmit = async () => {
        if (!isUserLoggedIn || !user?.number) {
            // let user to log in first so we can update user's information
            setError("Please enter and verify your phone number.");
            return;
        }
        if (!stripe || !elements) {
            return;
        }

        try {
            setLoading(true);
            _updateUserName();
            const cardElement = elements.getElement(CardElement);

            const result = await stripe.createToken(cardElement!);

            if (!result || result?.error) {
                setError(
                    result?.error?.message ||
                        "Sorry, could not add card. Check that you typed in the number correctly and try again!",
                );
                return;
            }

            if (result?.token) {
                const response = await Api.me.cards.create(
                    result.token.id,
                    result.token.card?.last4!,
                    idempotencyKey,
                );
                _setUser(response.data.user);
                onSuccess(response.data.user);
                setError(null);
            }
        } catch (err) {
            sendError(err);

            if (isNetworkError(err)) {
                setError(
                    "Failed to add card. Please check your network connection.",
                );
            } else {
                setError("Failed to add card. Please try again.");
                refreshIdempotencyKey();
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col gap-2 w-[100%] justify-center">
            <div>
                <div className="mx-6 px-2 py-3 border rounded-lg">
                    <CardElement options={cardElementOptions} />
                </div>

                {error && (
                    <div className="px-6 py-1">
                        <div className="text-alert text-sm">{error}</div>
                    </div>
                )}
                <div
                    className={cn(
                        `w-full mt-2 mr-6 px-6 py-3 ${addCardButtonClassName}`,
                    )}
                >
                    <Button
                        onPress={onSubmit}
                        loading={isLoading}
                        label="Add Card"
                        className="w-full"
                        disabled={!stripe}
                    />
                </div>
            </div>
        </div>
    );
};

const cardElementOptions = {
    showIcon: true,
    hidePostalCode: false,
    style: {
        base: {
            fontSize: "16px",
            borderRadius: "8px",
            color: "#424770",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: SystemColors.melon60,
        },
    },
};

export default CardInput;
