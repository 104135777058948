import { useEffect } from "react";
import useURLParams from "../../../hooks/useURLParams";
import { Geolocation } from "@snackpass/snackpass-types";
import { useDispatch } from "react-redux";
import { updateMyLocation } from "@/redux";

export function useSetLocationFromURL() {
    const dispatch = useDispatch();
    const params = useURLParams();
    const latParam = params.get("lat");
    const lngParam = params.get("lng");

    const latitude = latParam ? parseFloat(latParam) : 0;
    const longitude = lngParam ? parseFloat(lngParam) : 0;
    const urlGeolocation: Geolocation = {
        type: "Point",
        coordinates: [longitude, latitude],
    };

    useEffect(() => {
        if (latParam && lngParam) {
            const latitude = parseFloat(latParam);
            const longitude = parseFloat(lngParam);
            let geolocation: Geolocation = {
                type: "Point",
                coordinates: [longitude, latitude],
            };

            dispatch(updateMyLocation(geolocation));
        }
    }, [latParam, lngParam]);

    return { urlGeolocation, latParam, lngParam };
}
