import { SystemColors } from "@snackpass/design-system";
import React, { CSSProperties } from "react";
import { Colors } from "src/utils";
import { Plus, Minus } from "lucide-react";

type ButtonProps = {
    isIncrement: boolean;
    buttonContainerStyle?: CSSProperties;
    buttonWidth?: number;
    onPress: (e?: any) => void;
    disabled?: boolean;
    className?: string;
    quantity: number;
    isLimitReached: boolean;
};

const MultipleAddonsButton: React.FC<ButtonProps> = ({
    isIncrement,
    className,
    buttonContainerStyle,
    disabled,
    buttonWidth,
    quantity,
    isLimitReached,
    onPress,
}) => {
    const renderIncrementButton = (isLimitReached: boolean) => {
        const opacity = isLimitReached ? 0.4 : 1;

        return (
            <div style={{ ...buttonContainerStyle, opacity }}>
                <button
                    className={`${className} button`}
                    onClick={(e) => {
                        if (disabled) {
                            return;
                        }
                        onPress(e);
                    }}
                    style={styles.buttonInnerStyle}
                >
                    {quantity > 0 ? (
                        <div
                            style={{
                                borderColor: SystemColors.black,
                                borderWidth: 2,
                                borderStyle: "solid",
                                height: 23,
                                width: 23,
                                borderRadius: "100%",
                                position: "relative",
                                top: 0,
                                left: 0,
                            }}
                            className="hover:opacity-80 duration-300"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    height: 21,
                                    width: 21,
                                    borderRadius: "100%",
                                    position: "absolute",
                                    right: -1,
                                    top: -1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: SystemColors.black,
                                }}
                                className="duration-300"
                            >
                                <Plus size={13} strokeWidth={5} />
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                borderColor: Colors.gray,
                                borderWidth: 2,
                                borderStyle: "solid",
                                height: 23,
                                width: 23,
                                borderRadius: "100%",
                                position: "relative",
                                top: 0,
                                left: 0,
                            }}
                            className="hover:opacity-80 duration-300"
                        ></div>
                    )}
                </button>
            </div>
        );
    };

    const renderDecrementButton = () => {
        return (
            <div style={buttonContainerStyle}>
                <button
                    className={`${className} button`}
                    onClick={(e) => {
                        if (disabled) {
                            return;
                        }
                        onPress(e);
                    }}
                    style={styles.buttonInnerStyle}
                >
                    <div
                        style={{
                            borderColor: Colors.gray,
                            borderWidth: 2,
                            borderStyle: "solid",
                            height: 23,
                            width: 23,
                            borderRadius: "100%",
                            position: "relative",
                            top: 0,
                            left: 0,
                        }}
                        className="hover:opacity-80 duration-300"
                    >
                        <div
                            style={{
                                display: "flex",
                                height: 21,
                                width: 21,
                                borderRadius: "100%",
                                position: "absolute",
                                right: -1,
                                top: -1,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            className="duration-300"
                        >
                            <Minus size={13} strokeWidth={5} color="black" />
                        </div>
                    </div>
                </button>
            </div>
        );
    };

    return (
        <div>
            {isIncrement
                ? renderIncrementButton(isLimitReached)
                : disabled
                ? null
                : renderDecrementButton()}
        </div>
    );
};

const styles = {
    buttonInnerStyle: {
        border: "none",
        backgroundColor: Colors.transparent,
        padding: 0,
    } as CSSProperties,
};

export default MultipleAddonsButton;
