import { useSelector } from "react-redux";
import { IProduct, IStore } from "@snackpass/snackpass-types";
import * as actions from "../redux";
import { getActiveStoreCategoryMap, getPromotions } from "../redux";
import {
    CartSelectors,
    CheckoutSelectors,
    getHasDoublePointsPromotion,
} from "@snackpass/accounting";

// calculate the points after applying the promotion

export function usePointsAfterPromotion() {
    const activeStore = useSelector(actions.getActiveStore);
    const categoryMap = useSelector(getActiveStoreCategoryMap);
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const dealIds = useSelector(CheckoutSelectors.getCheckoutDealIds);
    const subtotal = useSelector(CheckoutSelectors.getSubtotal);
    const promotions = useSelector(CheckoutSelectors.getCheckoutPromotions);
    const storePromotions = useSelector(getPromotions);

    const getPointsAfterPromotion = (product: IProduct) => {
        const store = {
            ...activeStore,
            promotions: storePromotions || [],
        } as IStore;

        const hasDoublePoints = getHasDoublePointsPromotion(
            product,
            store,
            categoryMap,
            fulfillment,
            promotions,
            dealIds,
            subtotal,
        );
        return hasDoublePoints ? (product?.points || 0) * 2 : product.points;
    };

    return getPointsAfterPromotion;
}
