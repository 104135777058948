import { getUserAuthStatus } from "@/redux";
import { getUser } from "@snackpass/accounting";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import config from "src/config";

import * as Sentry from "@sentry/react";
import { IUser } from "@snackpass/snackpass-types";
import Api from "src/utils/Api/REST";
import { getTraits } from "src/utils/Helpers";
import { sendError } from "src/utils/Errors";

export const useInitSegment = () => {
    useEffect(initSegmentAnalytics, []);
    const authStatus = useSelector(getUserAuthStatus);
    const user = useSelector(getUser);

    useEffect(() => {
        if (authStatus !== "SIGNED_IN") {
            return;
        }

        if (user && user?._id) {
            refreshUserTracking(user);
        }
    }, [authStatus, user]);

    return null;
};

// the majority of this method was copy and pasted from segment
// documentation; it was moved from its normal place in the public
// html because of the added flexibility of using a custom
// and independent development data pipeline
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2-copy-the-segment-snippet
export const initSegmentAnalytics = () => {
    const API_KEY = config.IS_DEV
        ? config.DEVELOPMENT_SEGMENT_API_KEY
        : config.PRODUCTION_SEGMENT_API_KEY;

    var analytics: any = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
        if (analytics.invoked)
            window.console &&
                console.error &&
                console.error("Segment snippet included twice.");
        else {
            analytics.invoked = !0;
            analytics.methods = [
                "trackSubmit",
                "trackClick",
                "trackLink",
                "trackForm",
                "pageview",
                "identify",
                "reset",
                "group",
                "track",
                "ready",
                "alias",
                "debug",
                "page",
                "once",
                "off",
                "on",
            ];
            analytics.factory = function (t) {
                return function () {
                    var e = Array.prototype.slice.call(arguments);
                    e.unshift(t);
                    analytics.push(e);
                    return analytics;
                };
            };
            for (var t = 0; t < analytics.methods.length; t++) {
                var e = analytics.methods[t];
                analytics[e] = analytics.factory(e);
            }
            analytics.load = function (t, e) {
                var n = document.createElement("script");
                n.type = "text/javascript";
                n.async = !0;
                n.src =
                    "https://cdn.segment.com/analytics.js/v1/" +
                    t +
                    "/analytics.min.js";
                var a = document.getElementsByTagName("script")[0];
                a.parentNode?.insertBefore(n, a);
                analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = "4.1.0";
            analytics.load(API_KEY);
            analytics.page();
        }
};

// Tracks that a user has used online ordering
const trackUsedOnline = (user: IUser | null) => () => {
    if (user && !user.usedOnline) {
        Api.users.update({
            usedOnline: true,
        });
    }
};

export const refreshUserTracking = async (user: IUser | null) => {
    const { uid } = user ?? {};
    if (!user || !uid) return;
    const traits = await getTraits(user);

    // allows for user auth info to sync: prevents 403
    setTimeout(trackUsedOnline(user), 500);

    // Sentry to add metadata for user
    Sentry.configureScope((scope) => {
        scope.setUser({
            email: user.email,
            id: uid,
            uid: uid,
            name: user.name,
        });
    });

    traits["phone"] = user.number;
    traits["snackpass_uid"] = user._id;

    // Update Segment tracking
    analytics.alias(uid);
    await Api.me
        .getAnalytics()
        .then((response) => {
            analytics.identify(uid, {
                ...traits,
                Purchases: response.data.numPurchases,
                "Rewards Redeemed": response.data.numRewardsRedeemed,
                "Rewards Unredeemed": response.data.numRewardsUnredeemed,
                "Gifts Received": response.data.numGiftsReceived,
                "Gifts Sent": response.data.numGiftsSent,
                Friends: response.data.numFriends,
                "Users Referred": response.data.usersReferred,
            });
        })
        .catch((err) => {
            sendError(err);
            analytics.identify(uid, traits);
        });
};
