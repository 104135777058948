import { selectProducts } from "#menu";
import { itemIsSoldOut } from "@snackpass/accounting";
import { useMemo } from "react";
import { useSelector } from "react-redux";

// This hook is designed to determine if a product added to the cart is sold out or not.
// It does so by checking if the product's sold out status in the cart matches the current state of the product sold out status,
// accounting for the possibility that the product data in the cart might be outdated or stale.

export function useCheckProductIsSoldOut(productId: string) {
    const products = useSelector(selectProducts);
    const isSoldOut = useMemo(() => {
        const product = products.find((p) => p._id === productId);
        return product ? itemIsSoldOut(product) : false;
    }, [productId, products]);

    return isSoldOut;
}
