import React from "react";
import { useSelector } from "react-redux";
import { getUserIsLoggedIn } from "src/redux";
import { X } from "lucide-react";
import { cn } from "@/utils";
import { ReactComponent as LeftArrow } from "src/assets/icons/arrow-left.svg";
import SettingsAlert from "@/SharedComponents/SettingsAlert";
import { useNavigateToCart } from "@/hooks/useNavigateToCart";

const ProfileHeader = () => {
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const { navigateToStore } = useNavigateToCart();

    return (
        <div className="flex h-[60px] w-[100%] max-w-[1280px] flex-row items-center justify-between overflow-hidden pr-4 md:h-[80px]">
            <div className="flex w-[100%] flex-row items-center gap-3 md:w-[60%]">
                <div />
                <div className="mt-1.5 w-fit cursor-pointer items-center justify-center md:ml-2 md:flex md:flex-row">
                    <div
                        onClick={navigateToStore}
                        className={cn(
                            "inline-flex items-center justify-center gap-2 rounded-[100px] bg-white text-gray-500 shadow-sm hover:scale-105  hover:bg-gray-50 hover:text-gray-900 focus:relative"
                        )}
                        aria-label="Close"
                    >
                        <div className="hidden md:flex">
                            <LeftArrow className="h-5 w-5 fill-primary" />
                        </div>
                        <div className="flex md:hidden">
                            <X strokeWidth={3} className="m-2 h-5 w-5" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden flex-row items-center justify-center space-x-5 md:flex">
                {/* no need to show profile since user already at profile page */}
                {isUserLoggedIn ? null : <SettingsAlert />}
            </div>
        </div>
    );
};

export default ProfileHeader;
