import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveCartItem,
    getDigitalGiftCardCartInfo,
} from "@snackpass/accounting/build/src/redux/activeCartItem/selectors";
import { IProduct, IStore } from "@snackpass/snackpass-types";
import {
    itemIsSoldOut,
    Time,
    CartActions,
    CartItem,
    CartSelectors,
    ActiveCartItemSelectors,
    getActiveStore,
    CartAddon,
} from "@snackpass/accounting";
import { useActiveRewardPromo } from "@snackpass/loyalty-react";
import moment from "moment";
import { getCartIsEmpty } from "@snackpass/accounting/build/src/redux/cart/selectors";

import { SegmentEventNames, Segment } from "src/utils/Segment";
import _ from "lodash";
import { toast } from "sonner";
import { isSnackpassTimeEnabled } from "@/statsig/featureGates";
import { isProductAvailable } from "@snackpass/time";
import { selectConflicts } from "#cart/selectors";
import { useAppSelector } from "@/redux/utils";
import { clearConflicts } from "#cart";

const useCartItemHandler = (isQuickAdd?: boolean) => {
    const dispatch = useDispatch();
    const isCartEmpty = useAppSelector(getCartIsEmpty);
    const activeCartItem = useAppSelector(getActiveCartItem);
    const activeProduct = activeCartItem.product as IProduct;
    const activeStore = useAppSelector(getActiveStore);
    const activeCartItemPromotion = activeCartItem.promotion;
    const activeCartItemReward = activeCartItem.reward;
    const activeCartItemDigitalGiftCardInfo = useSelector(
        getDigitalGiftCardCartInfo,
    );
    const scheduledDate = useAppSelector(CartSelectors.getCartScheduleDate);
    const addonSelectionIsValidated = useSelector(
        ActiveCartItemSelectors.getAddonGroupsNeedSelection,
    );
    const conflicts = useAppSelector(selectConflicts);

    const activeRewardPromo = useActiveRewardPromo();

    const getAddonGroupIds = useCallback(
        () =>
            addonSelectionIsValidated.map((group) =>
                (group._id ?? "").toLowerCase(),
            ),
        [addonSelectionIsValidated],
    );

    const setNumberOfBags = useCallback(
        (numberOfBags: number) => {
            dispatch(CartActions.setNumberOfBags(numberOfBags));
        },
        [dispatch],
    );

    const _addCartItem = useCallback(
        (item: CartItem, store: IStore) => {
            dispatch(CartActions.addCartItem(item, store));
        },
        [dispatch],
    );

    const _updateCartItem = useCallback(
        (item: CartItem, cartItemIndex: number, store: IStore) => {
            dispatch(CartActions.updateCartItem(item, cartItemIndex, store));
        },
        [dispatch],
    );

    const _removeCartItem = useCallback(
        (itemIdex: number) => {
            dispatch(CartActions.removeCartItem(itemIdex));
        },
        [dispatch],
    );

    const checkIsCartItemAvailable = useCallback(
        (
            product: IProduct,
            quantity: number,
            weight: number,
        ): {
            isValid: boolean;
            text: string;
            invalidAddonGroupIds;
        } => {
            if (product.priceByWeight && (!weight || weight <= 0)) {
                return {
                    isValid: false,
                    text: `You cannot order 0${product.priceByWeight.unit} or less of ${product.name}.`,
                    invalidAddonGroupIds: null,
                };
            }
            if (quantity < (product.minimumQuantity ?? 0)) {
                return {
                    isValid: false,
                    text: `You must order at least ${product.minimumQuantity} of ${product.name}.`,
                    invalidAddonGroupIds: null,
                };
            }

            if (itemIsSoldOut(product)) {
                return {
                    isValid: false,
                    text: isQuickAdd
                        ? "One or more products are sold out right now"
                        : "Product is sold out right now",
                    invalidAddonGroupIds: null,
                };
            }

            if (isQuickAdd) {
                const addonSoldOut = product.addonGroups.flatMap((ag) =>
                    ag.addons.filter((addon) => {
                        return itemIsSoldOut(addon);
                    }),
                );

                if (addonSoldOut.length > 0) {
                    return {
                        isValid: false,
                        text: `${addonSoldOut
                            .map((addon) => `${addon.name} for ${product.name}`)
                            .join(", ")} are sold out right now`,
                        invalidAddonGroupIds: null,
                    };
                }
            }
            // Product has hours and it is not available atm
            if (
                product.hours?.local?.length &&
                (isSnackpassTimeEnabled()
                    ? !isProductAvailable(product.hours)(
                          scheduledDate?.getTime(),
                      )
                    : !Time.isOpen(product, scheduledDate))
            ) {
                let title: string = isQuickAdd
                    ? `One or more products are not available `
                    : `This product isn't available `;
                if (scheduledDate) {
                    return {
                        isValid: false,
                        text: `${title} for scheduled ${moment(
                            scheduledDate,
                        ).format("ddd, MMM D (h:mm a)")}`,
                        invalidAddonGroupIds: null,
                    };
                } else {
                    return {
                        isValid: false,
                        text: `${title} right now`,
                        invalidAddonGroupIds: null,
                    };
                }
            }
            if (addonSelectionIsValidated.length) {
                const groupIds = getAddonGroupIds();
                return {
                    isValid: false,
                    text: "",
                    invalidAddonGroupIds: groupIds[0],
                };
            }
            return {
                isValid: true,
                text: "",
                invalidAddonGroupIds: null,
            };
        },
        [addonSelectionIsValidated.length, getAddonGroupIds, scheduledDate],
    );

    const addCartItem = useCallback(
        (
            product: IProduct,
            selectedAddons: CartAddon[] | null,
            notes: string | undefined,
            quantity: number,
            weight: number,
        ) => {
            if (!activeCartItem || !activeStore) return;

            if (isCartEmpty) {
                setNumberOfBags(
                    activeStore.defaultBagInCart
                        ? activeStore.defaultBagInCart
                        : 0,
                );
            }
            for (var i = 0; i < quantity; i++) {
                _addCartItem(
                    {
                        product: product,
                        notes,
                        selectedAddons,
                        promotion: activeCartItemPromotion,
                        reward: activeCartItemReward,
                        digitalGiftCardInfo: activeCartItemDigitalGiftCardInfo,
                        ...(product.priceByWeight
                            ? {
                                  weight: {
                                      amount: weight,
                                      unit: product.priceByWeight.unit,
                                  },
                              }
                            : {}),
                        pointsAfterPromotion:
                            activeCartItem.pointsAfterPromotion,
                    },
                    activeStore,
                );
                Segment.track(SegmentEventNames.ADDED_ITEM_TO_CART, {
                    item_id: product._id,
                    item_name: product.name,
                    item_category: product.category,
                    item_location_id: activeStore?.region,
                    quantity: 1,
                    price: product.price,
                    store_name: activeStore?.name,
                    is_quick_checkout: false,
                    store_kind: activeStore?.kind,
                });

                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            type: "addedItemToCart",
                            item_id: product._id,
                            item_name: product.name,
                            item_category: product.category,
                            price: product.price,
                            store_name: activeStore?.name,
                            store_id: activeStore?._id,
                        }),
                    );
                }

                if (activeRewardPromo || activeCartItemReward) {
                    toast.info("Reward added to your cart 🏆");
                }

                if (activeRewardPromo) {
                    Segment.track(
                        SegmentEventNames.ADDED_REWARD_PROMO_TO_CART,
                        {
                            reward_promo: activeRewardPromo,
                            is_online_ordering: true,
                            item_id: activeProduct._id,
                        },
                    );
                }
            }
        },
        [
            activeStore?._id,
            _addCartItem,
            activeCartItem,
            activeCartItemPromotion,
            activeCartItemReward,
            activeCartItemDigitalGiftCardInfo,
            isCartEmpty,
            setNumberOfBags,
            activeRewardPromo,
        ],
    );

    const updateCartItem = useCallback(
        (
            itemIndex: number,
            selectedAddons: CartAddon[] | null,
            notes: string | undefined,
            weight: number,
        ) => {
            if (!activeCartItem || !activeStore) return;
            if (isCartEmpty) {
                setNumberOfBags(
                    activeStore.defaultBagInCart
                        ? activeStore.defaultBagInCart
                        : 0,
                );
            }

            _updateCartItem(
                {
                    product: activeProduct,
                    notes,
                    selectedAddons,
                    promotion: activeCartItemPromotion,
                    reward: activeCartItemReward,
                    digitalGiftCardInfo: activeCartItemDigitalGiftCardInfo,
                    pointsAfterPromotion: activeCartItem?.pointsAfterPromotion,
                    ...(activeProduct.priceByWeight
                        ? {
                              weight: {
                                  amount: weight,
                                  unit: activeProduct.priceByWeight.unit,
                              },
                          }
                        : {}),
                },
                itemIndex,
                activeStore,
            );
            Segment.track(SegmentEventNames.UPDATED_CART_ITEM, {
                item_id: activeProduct._id,
                item_name: activeProduct.name,
                item_category: activeProduct.category,
                item_location_id: activeStore?.region,
                quantity: 1,
                price: activeProduct.price,
                store_name: activeStore?.name,
                is_quick_checkout: false,
            });

            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: "addedItemToCart", // TODO: replace this event with updatedItem
                        item_id: activeProduct._id,
                        item_name: activeProduct.name,
                        item_category: activeProduct.category,
                        price: activeProduct.price,
                        store_name: activeStore?.name,
                        store_id: activeStore?._id,
                    }),
                );
            }
        },
        [
            _updateCartItem,
            activeCartItem,
            activeCartItemPromotion,
            activeCartItemReward,
            activeCartItemDigitalGiftCardInfo,
            activeProduct,
            isCartEmpty,
            setNumberOfBags,
            activeStore?._id,
        ],
    );

    // XXX: This is function is magically removing all conflicted items.
    // We should have a unique function for that purpose.
    const removeCartItem = useCallback(
        (itemIndex: number) => {
            if (conflicts.length) {
                const sorted = _.sortBy(conflicts, (o) => -o.cartIndex);
                for (const conflict of sorted) {
                    _removeCartItem(conflict.cartIndex);
                }
                dispatch(clearConflicts());
            } else {
                _removeCartItem(itemIndex);
            }
        },
        [conflicts, dispatch, _removeCartItem],
    );

    return useMemo(
        () => ({
            addCartItem,
            updateCartItem,
            checkIsCartItemAvailable,
            removeCartItem,
        }),
        [addCartItem, updateCartItem, removeCartItem, checkIsCartItemAvailable],
    );
};

export default useCartItemHandler;
