import React, { createContext } from "react";
import { WhenTypeEnum, CartWhen } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { noop } from "lodash";

type UseScheduleModalContextType = {
    modalWhen: CartWhen | undefined;
    modalSelectedWeekDay: moment.Moment | null;
    modalSelectedTime: moment.Moment | null;
    setModalWhen: (when: WhenTypeEnum) => void;
    setModalSelectedWeekDay: React.Dispatch<
        React.SetStateAction<moment.Moment | null>
    >;
    setModalSelectedTime: React.Dispatch<
        React.SetStateAction<moment.Moment | null>
    >;
};

export const ScheduleContext = createContext<UseScheduleModalContextType>({
    modalWhen: WhenTypeEnum.Now,
    modalSelectedWeekDay: null,
    modalSelectedTime: null,
    setModalWhen: noop,
    setModalSelectedWeekDay: noop,
    setModalSelectedTime: noop,
});
