import React from "react";
import { getPurchaseFirstName, optimizedImageURL } from "@/utils/Helpers";
import { IPurchase, IStore } from "@snackpass/snackpass-types";
import moment from "moment";

type OrderStatusTabProps = {
    purchase: IPurchase;
    activeStore: IStore;
};

export function Pass({ purchase, activeStore }: OrderStatusTabProps) {
    return (
        <div
            style={{
                minHeight: 210,
                borderRadius: "24px",
                background: activeStore.loyaltyCardBackgroundImage
                    ? `url("${optimizedImageURL(
                          activeStore.loyaltyCardBackgroundImage,
                          {
                              size: { h: 210 },
                          },
                      )}")`
                    : `linear-gradient(157deg, rgba(255, 255, 255, 0.20) 39.93%, rgba(255, 255, 255, 0.00) 40.06%), linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${
                          activeStore.brandColor || activeStore.color
                      }`,
                backgroundBlendMode: "soft-light, overlay, normal",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                boxShadow:
                    "0px 5px 21px 0px rgba(0, 0, 0, 0.08), 0px 8px 43px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(15, 15, 15, 0.12), 0px -1px 1px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.40) inset",
            }}
            className="flex flex-row items-end relative"
        >
            <div className="before:block before:absolute before:top-0 before:left-0 before:h-[100%] before:w-[100%] before:bg-gradient-to-b from-transparent via-transparent to-black before:rounded-3xl opacity-30" />
            <div
                className="absolute top-3 left-3 right-0 bottom-0"
                style={{
                    width: 57,
                    height: 57,
                    borderRadius: 57,
                    flexShrink: 0,
                    background: `url(${activeStore.logoUrl}) no-repeat center/cover`,
                }}
            />
            <div className="flex flex-row p-4 items-end flex-1 absolute bottom-0 left-0 w-[100%]">
                <div className="flex-1">
                    <span className="font-bold text-2xl text-white">
                        Order #{purchase.receiptNumber}
                    </span>
                </div>
                <div className="flex-1 flex-col items-end flex">
                    <span className="text-white font-bold">
                        {getPurchaseFirstName(purchase)}
                    </span>
                    <span className="text-white">
                        {moment(purchase.createdAt).format("MMM DD, YYYY")}
                    </span>
                </div>
            </div>
        </div>
    );
}
