import React from "react";
import moment from "moment-timezone";
import { IStore } from "@snackpass/snackpass-types";
import { Database, ref, onValue, off } from "firebase/database";
// compat packages are API compatible with namespaced code
import "firebase/compat/firestore";

import * as ScheduleUtils from "./utils";
import { BatchMapping, TimeSlot } from "@snackpass/accounting";

export const useBatchListener = ({
    db,
    store,
    date,
    timeSlots,
    hasBatching,
}: {
    db: Database;
    store: IStore | null;
    date: moment.Moment | null;
    timeSlots: TimeSlot[];
    hasBatching: boolean;
}): {
    batchMapping: BatchMapping;
    listening: moment.Moment | null;
} => {
    const [dateToListenTo, setDateToListenTo] =
        React.useState<moment.Moment | null>(null);
    const [batchMapping, setBatchMapping] = React.useState<BatchMapping>({});

    React.useEffect(() => {
        if (!store || !date || !hasBatching || !timeSlots.length) {
            return;
        }

        const batchDate = moment(date).tz(store.hours.zone);
        const batchDateFormatted = batchDate.format("YYYY-MM-DD");
        if (db) {
            const reference = ref(
                db,
                "v2/batchesByScheduledTime/" +
                    store._id +
                    "/" +
                    batchDateFormatted,
            );

            // This call is causing an infinite re-render. Since the state is not
            // used elsewhere, this is removed for now
            // setDateToListenTo(batchDate);
            onValue(reference, (snap: any) => {
                setBatchMapping(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    ScheduleUtils.generateBatchMapping(snap.val(), timeSlots),
                );
            });
            return () => {
                setDateToListenTo(null);
                off(reference);
            };
        }
    }, [date, db, hasBatching, store, timeSlots]);

    return { batchMapping, listening: dateToListenTo };
};
