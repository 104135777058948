import React from "react";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import Summary from "./Summary";
import { useSelector } from "react-redux";
import { CheckoutSelectors } from "@snackpass/accounting";
import { Helpers } from "@/utils";
import { getIsTotalRevealedByDefault } from "@/redux/ooCart";

const TotalCharge = () => {
    const total = useSelector(CheckoutSelectors.getTotal);
    const isTotalReaveledByDefault = useSelector(getIsTotalRevealedByDefault);
    const useCredit = useSelector(CheckoutSelectors.getUseCredit);
    return (
        <div className="px-4 md:px-6">
            <Accordion
                className="w-full rounded-md"
                type="single"
                collapsible
                defaultValue={
                    isTotalReaveledByDefault && useCredit
                        ? "totalAccordian"
                        : undefined
                }
                key={isTotalReaveledByDefault && useCredit}
            >
                <AccordionItem value="totalAccordian">
                    <AccordionTrigger className="cursor-pointer pt-6 pb-6  group-data-[state=open]:pb-0 flex flex-row justify-between">
                        <div className="flex flex-row items-center gap-2">
                            <div className="text-xl font-semibold flex">
                                Total
                            </div>
                            <ChevronRightIcon
                                className="text-[#999DA3] w-5 h-5 mx-1 ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-90"
                                aria-hidden
                            />
                        </div>
                        <span className="text-xl font-semibold text-primary">
                            {" "}
                            {Helpers.toDollar(total)}
                        </span>
                    </AccordionTrigger>
                    <AccordionContent className="mb-6">
                        <Summary />
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default TotalCharge;
