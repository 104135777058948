import React, { useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import {
    hideModal,
    destroyModal,
    ModalsName,
    selectIsCartConflictModalOpen,
} from "@/redux";
import { Button } from "@/components/ui/button";
import useCartItemHandler from "@/hooks/useCartItemHandler";
import { useNavigateToCart } from "@/hooks/useNavigateToCart";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { useAppDispatch, useAppSelector } from "@/redux/utils";

import { selectConflicts, selectHasConflicts } from "#cart";

import { Body } from "./Body";

const useRemove = () => {
    const conflicts = useAppSelector(selectConflicts);
    const { removeCartItem } = useCartItemHandler();

    return useCallback(() => {
        // you need to remove it from back to front to maintain index accuracy
        const sorted = _.sortBy(conflicts, (o) => -o.cartIndex);
        for (const conflict of sorted) {
            removeCartItem(conflict.cartIndex);
        }
    }, [conflicts, removeCartItem]);
};

const CartConflictsModal = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const dispatch = useAppDispatch();
    const hasConflicts = useAppSelector(selectHasConflicts);
    const isOpen = useAppSelector(selectIsCartConflictModalOpen);
    const { navigateToStore, navigateToCart } = useNavigateToCart();
    const destroyCartConflictsModal = useCallback(() => {
        dispatch(destroyModal(ModalsName.cartConflictsModal));
    }, [dispatch]);
    const removeConflicts = useRemove();

    const handleRemove = () => {
        removeConflicts();
        onHide();
    };

    const onCancel = () => {
        onHide();
        navigateToStore();
    };

    const onHide = useCallback(() => {
        if (!hasConflicts) {
            navigateToStore();
        }
        destroyCartConflictsModal();
    }, [hasConflicts, navigateToStore, destroyCartConflictsModal]);

    useEffect(() => {
        if (!hasConflicts) {
            // if conflicts has been cleared we navigate user to cart screen
            dispatch(hideModal(ModalsName.cartConflictsModal));
            navigateToCart();
        }
    }, [hasConflicts]);

    if (isDesktop) {
        return (
            <Dialog open={isOpen} onOpenChange={onHide}>
                <DialogContent onEscapeKeyDown={onHide} className="z-[100]">
                    <div className="flex flex-col overflow-y-auto h-full relative w-full hidden-scroll">
                        <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto flex flex-col">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    Remove Items?
                                </div>
                            </div>{" "}
                        </div>
                        <div className="mx-6 mt-4">
                            <Body />
                        </div>
                        <DialogFooter className="bg-white border-t p-6 md:px-6">
                            <div className="w-full">
                                <div className="flex flex-1 items-center justify-center"></div>
                                <div className="flex flex-row justify-between gap-4">
                                    <Button
                                        className="w-full"
                                        label={"Cancel"}
                                        onPress={onCancel}
                                        variant="outline"
                                    />
                                    <Button
                                        className="w-full"
                                        label={"Delete"}
                                        onPress={() => {
                                            handleRemove();
                                        }}
                                    />
                                </div>
                            </div>
                        </DialogFooter>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
    return (
        <Sheet open={isOpen} onOpenChange={onHide}>
            <SheetContent className="overflow-auto">
                <SheetHeader>
                    <SheetTitle> Remove Items?</SheetTitle>
                </SheetHeader>
                <div className="py-4 overflow-auto pb-16">
                    <Body />
                </div>
                <SheetFooter>
                    <SheetClose asChild>
                        <div className="w-full py-4 px-4 border-t">
                            <div className="flex flex-row justify-between gap-4">
                                <Button
                                    className="w-full"
                                    label={"Cancel"}
                                    onPress={onCancel}
                                    variant="outline"
                                />
                                <Button
                                    className="w-full"
                                    label={"Delete"}
                                    onPress={() => {
                                        handleRemove();
                                    }}
                                />
                            </div>
                        </div>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default CartConflictsModal;
