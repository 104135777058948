import { CreateGiftCardInput } from "./types";
import { IGiftCard } from "@snackpass/snackpass-types";
import { gql } from "graphql-request";
import { GQL } from "src/utils/Api/graphql/client";
import { GiftCard, GiftCardId, GiftCardRedeem } from "./fragments";
import { ForterToken } from "src/forter";
import { XForterToken } from "../axios";
class GiftCards {
    static async getByToken(id: string, token: string): Promise<IGiftCard> {
        const query = gql`
            ${GiftCardRedeem}
            query giftCardByToken($id: ID!, $token: String!) {
                giftCardByToken(id: $id, token: $token) {
                    ...GiftCardRedeem
                    toName
                    toPhone
                    code
                    status
                    remainingCreditCents
                    designURL
                }
            }
        `;

        try {
            const { giftCardByToken } = await GQL.request(query, {
                id,
                token,
            });
            return giftCardByToken;
        } catch (error) {
            throw error.response.errors[0];
        }
    }

    static async getActiveByUserAndStore(
        storeId: string,
    ): Promise<IGiftCard[]> {
        const query = gql`
            ${GiftCard}
            query giftCardsActiveByUserAndStore($storeId: ID!) {
                giftCardsActiveByUserAndStore(storeId: $storeId) {
                    ...GiftCard
                }
            }
        `;
        try {
            const { giftCardsActiveByUserAndStore } = await GQL.request(query, {
                storeId,
            });
            return giftCardsActiveByUserAndStore;
        } catch (error) {
            throw error.response.errors[0];
        }
    }

    static create = async (data: CreateGiftCardInput) => {
        const query = gql`
            ${GiftCardId}
            mutation createGiftCard($data: CreateGiftCardInput!) {
                createGiftCard(data: $data) {
                    ...GiftCardId
                }
            }
        `;
        try {
            const headers =
                ForterToken == null
                    ? undefined
                    : { [XForterToken]: ForterToken };
            const { createGiftCard } = await GQL.request(
                query,
                {
                    data,
                },
                headers,
            );
            return createGiftCard;
        } catch (error) {
            throw error.response.errors[0];
        }
    };

    static redeem = async (code: string) => {
        const query = gql`
            ${GiftCard}
            mutation redeemGiftCard($code: String!) {
                redeemGiftCard(code: $code) {
                    ...GiftCard
                }
            }
        `;

        try {
            const { redeemGiftCard } = await GQL.request(query, {
                code,
            });
            return redeemGiftCard;
        } catch (error) {
            throw error.response.errors[0];
        }
    };

    static verifyGiftCard = async (
        code: string,
        pin: string,
        storeId: string,
    ): Promise<IGiftCard> => {
        const query = gql`
            ${GiftCard}
            mutation verifyGiftCard(
                $code: String!
                $pin: String!
                $storeId: ID!
            ) {
                verifyGiftCard(code: $code, pin: $pin, storeId: $storeId) {
                    ...GiftCard
                    encryptedPin
                    code
                }
            }
        `;

        try {
            const { verifyGiftCard } = await GQL.request(query, {
                code,
                pin,
                storeId,
            });
            return verifyGiftCard;
        } catch (error) {
            throw error.response.errors[0];
        }
    };
}

export default GiftCards;
