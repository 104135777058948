import { IDealItem, IPromotion, IStore } from "@snackpass/snackpass-types";
import { CartItem, SelectedDealItems, DealGroup } from "../../types";

export const SET_ACTIVE_DEAL_ITEM = "SET_ACTIVE_DEAL_ITEM";
export type SET_ACTIVE_DEAL_ITEM = "SET_ACTIVE_DEAL_ITEM";
export const SET_DEAL_ITEM = "SET_DEAL_ITEM";
export type SET_DEAL_ITEM = "SET_DEAL_ITEM";
export const REMOVE_DEAL_ITEM = "REMOVE_DEAL_ITEM";
export type REMOVE_DEAL_ITEM = "REMOVE_DEAL_ITEM";
export const CLEAR_DEAL_SELECTION = "CLEAR_DEAL_SELECTION";
export type CLEAR_DEAL_SELECTION = "CLEAR_DEAL_SELECTION";
export const ADD_DEAL_GROUP = "ADD_DEAL_GROUP";
export type ADD_DEAL_GROUP = "ADD_DEAL_GROUP";
export const REMOVE_DEAL_GROUP = "REMOVE_DEAL_GROUP";
export type REMOVE_DEAL_GROUP = "REMOVE_DEAL_GROUP";
export const REMOVE_SINGLE_DEAL_ITEM = "REMOVE_SINGLE_DEAL_ITEM";
export type REMOVE_SINGLE_DEAL_ITEM = "REMOVE_SINGLE_DEAL_ITEM";

export type SetActiveDealItem = {
    type: SET_ACTIVE_DEAL_ITEM;
    activeDealItem: IDealItem;
};

export const setActiveDealItem = (
    activeDealItem: IDealItem
): SetActiveDealItem => {
    return { type: SET_ACTIVE_DEAL_ITEM, activeDealItem };
};

export type SetDealItem = {
    type: SET_DEAL_ITEM;
    item: CartItem;
    store: IStore;
};

export const setDealItem = (item: CartItem, store: IStore): SetDealItem => ({
    type: SET_DEAL_ITEM,
    item,
    store
});

export type RemoveDealItem = {
    type: REMOVE_DEAL_ITEM;
    dealItemId: string;
};

export const removeDealItem = (dealItemId: string): RemoveDealItem => ({
    type: REMOVE_DEAL_ITEM,
    dealItemId
});

export type ClearDealItems = {
    type: CLEAR_DEAL_SELECTION;
};

export const clearDealItems = (): ClearDealItems => {
    return { type: CLEAR_DEAL_SELECTION };
};

export type AddDealGroupToCart = {
    type: ADD_DEAL_GROUP;
    dealGroup: DealGroup;
};

export const addDealGroupToCart = (
    items: SelectedDealItems,
    promotion: IPromotion,
    dealGroupId: string
): AddDealGroupToCart => {
    return {
        type: ADD_DEAL_GROUP,
        dealGroup: {
            items: Object.keys(items).map((key) => items[key]),
            promotion,
            id: dealGroupId,
            name: promotion.name
        }
    };
};

export type DealSelectionActions =
    | SetActiveDealItem
    | SetDealItem
    | RemoveDealItem
    | ClearDealItems
    | AddDealGroupToCart;
