import {
    clearSelectedCartDeliveryQuote,
    selectCartDeliveryQuote,
    setCartDeliveryError,
    setCartDeliveryQuotes,
    setCartStoreCanDeliver,
} from "@snackpass/accounting/build/src/redux/cart/actions";
import Api from "src/utils/Api/REST";
import { compose, first, propOr } from "lodash/fp";
import { IStore } from "@snackpass/snackpass-types";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { sendError } from "src/utils/Errors";

/**
 *
 * Sets available delivery quotes in redux + selects the cheapest quote for the user.
 */

type UseDeliveryProviderProps = {
    toAddress?: string | null;
    store?: IStore | null;
    isCatering: boolean;
};

type DoorDashDeliveryQuoteError = {
    storeId: string;
    message?: string;
    fieldError?: {
        field: string;
        error: string;
    };
};

export const useDeliveryProvider = ({
    toAddress,
    store,
    isCatering,
}: UseDeliveryProviderProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false); // Track fetching state internally

    const setDeliveryError = compose(
        dispatch,
        setCartDeliveryError,
        _formatError,
    );
    const setCanDeliver = compose(dispatch, setCartStoreCanDeliver);
    const clearSelectedQuote = compose(
        dispatch,
        clearSelectedCartDeliveryQuote,
    );
    const setQuotes = compose(dispatch, setCartDeliveryQuotes);
    const selectQuote = compose(dispatch, selectCartDeliveryQuote);

    const fetchQuotes = async () => {
        if (
            isLoading ||
            !store ||
            !store._id ||
            !store.delivery ||
            !toAddress
        ) {
            return; // Don't proceed if already fetching or missing required data
        }

        setIsLoading(true); // Set fetching state to true

        try {
            const { data } = await Api.stores.getDeliveryRates(
                store?._id,
                toAddress,
                isCatering,
            );

            const { quotes, errors, canDeliver } = data;

            if (errors.length > 0 && errors[0].item?.provider === "doordash") {
                const parsedError: DoorDashDeliveryQuoteError = JSON.parse(
                    errors[0].message,
                );

                setDeliveryError([
                    {
                        message: `Error: ${JSON.stringify(
                            parsedError.message ?? parsedError.fieldError,
                        )}`,
                    },
                ]);
            } else {
                setDeliveryError(errors);
            }

            setCanDeliver(canDeliver);

            if (!canDeliver) {
                clearSelectedQuote();
                return;
            }

            setQuotes(quotes);
            const cheapestDelivery = quotes[0];

            if (cheapestDelivery) {
                selectQuote(cheapestDelivery);
            }
        } catch (err) {
            console.log(err);
            setCanDeliver(false);
            setDeliveryError([err.message]);
            sendError(`Failed to fetch delivery quotes. ${err}`);
        } finally {
            setIsLoading(false); // Reset fetching state
        }
    };

    return {
        fetchQuotes,
    };
};

const _formatError = (errors: any[]): string | null => {
    if (!errors || !errors.length) {
        return null;
    }

    return propOr(
        "Sorry, you cannot order delivery at this time.",
        "message",
        first(errors),
    );
};
