import React, { CSSProperties } from "react";
import { lightBlue } from "../../../utils/Colors";
import { Text, Button, View } from "src/SharedComponents";
import { ConfirmationHeader } from "./GiftCardConfirmationHeader";
import { history } from "../../../utils/history";
import { Divider } from "../../../SharedComponents";
import { GiftCardStateType, StepMoment } from "../lib";
import { Helpers } from "src/utils";
import { getActiveStore, logOut } from "src/redux";
import { useDispatch, useSelector } from "react-redux";

const DONE = "Done";
const BUY_ANOTHER_GIFTCARD = "Buy Another Gift Card";
const ORDER_SUMMARY = "Order Summary";
const AMOUNT = "Amount";
const RECIPIENT_NAME = "Recipient Name";
const RECIPIENT_PHONE = "Recipient Phone";
const NOTE = "Note";
const RECEIPT = "Receipt";
const PAYMENT_METHOD = "Payment Method";

type ConfirmationProps = {
    giftCardState: GiftCardStateType;
    setStart: () => void;
    setStep: React.Dispatch<React.SetStateAction<StepMoment>>;
};

export const Confirmation = ({
    giftCardState,
    setStart,
    setStep,
}: ConfirmationProps) => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);

    const onClose = () => {
        giftCardState.isKioskPurchase
            ? dispatch(logOut())
            : history.replace(`/${activeStore?.slug}`);
    };

    const onBuyAnotherGiftCard = () => {
        setStep(StepMoment.fillingForm);
        setStart();
    };

    return (
        <View style={styles.extContainer}>
            <View style={styles.headerContainer}>
                <ConfirmationHeader
                    fromName={giftCardState.fromName}
                    giftCardState={giftCardState}
                />
            </View>

            <View style={styles.secondaryHeaderContainer}>
                <Text>{ORDER_SUMMARY}</Text>
            </View>

            <View style={styles.infoContainer}>
                <Text style={styles.subtitleText}>{AMOUNT}</Text>

                <Text style={styles.dataText}>
                    {Helpers.toDollar(giftCardState.amount)}
                </Text>

                <Divider />

                <Text style={styles.subtitleText}>{RECIPIENT_NAME}</Text>
                <Text style={styles.dataTextNoSeparator}>
                    {giftCardState.toName}
                </Text>
                <Text style={styles.subtitleText}>{RECIPIENT_PHONE}</Text>
                <Text style={styles.dataText}>{giftCardState.toPhone}</Text>
                <Divider style={styles.separator} />

                <Text style={styles.subtitleText}>{NOTE}</Text>
                <Text style={styles.dataText}>{giftCardState.note}</Text>
                <Divider style={styles.separator} />

                <Text style={styles.subtitleText}>{RECEIPT}</Text>
                <Text style={styles.dataText}>{giftCardState.fromEmail}</Text>
                <Divider style={styles.separator} />

                <Text style={styles.subtitleText}>{PAYMENT_METHOD}</Text>
                <Text style={styles.dataText}>
                    {"**** " + giftCardState.cardLast4}
                </Text>
            </View>

            <Button
                onPress={onClose}
                label={DONE}
                style={styles.nextButton}
            ></Button>

            <Button
                onPress={onBuyAnotherGiftCard}
                label={BUY_ANOTHER_GIFTCARD}
                style={styles.buyAnotherButton}
                labelColor={"#282D32"}
            ></Button>
        </View>
    );
};

const styles = {
    extContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignSelf: "stretch",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
    } as CSSProperties,

    bottomContainer: {} as CSSProperties,

    headerContainer: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: lightBlue,
        padding: "3%",
    } as CSSProperties,

    infoContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "16px 24px",
        background: "#FFFFFF",
        flex: "none",
        alignSelf: "stretch",
        flexGrow: "0",
        margin: "0px 0px",
        marginBottom: "42px",
    } as CSSProperties,

    secondaryHeaderContainer: {
        display: "flex",
        flexDirection: "column",
        flex: "1",
        background: "#F1F5F8",
        padding: "24px 24px 16px",
        margin: "0px 0px",
        alignItems: "flex-start",
        alignSelf: "stretch",
    } as CSSProperties,

    secondaryHeaderText: {
        position: "static",
        width: "116px",
        height: "16px",
        left: "24px",
        top: "24px",

        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        letterSpacing: "1px",
        textTransform: "uppercase",
        color: "#606C76",
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 8px",
    } as CSSProperties,

    subtitleText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#606C76",
    } as CSSProperties,

    dataText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "24px",
        color: "#282D32",
        whiteSpace: "pre-line",
        wordBreak: "break-word",
    } as CSSProperties,

    dataTextNoSeparator: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "24px",
        color: "#282D32",
        marginBottom: "16px",
    } as CSSProperties,

    nextButton: {
        backgroundColor: "#0077FF",
        width: "90%",
        marginBottom: "2%",
        marginTop: "2%",
    } as CSSProperties,

    buyAnotherButton: {
        backgroundColor: "#FFFFFF",
        textColor: "#282D32",
        width: "90%",
        marginBottom: "5%",
        fontWeight: 500,
    } as CSSProperties,

    separator: {
        borderWidth: "1px",
        borderStyle: "inset",
        border: "0.5px solid",
        color: "#DDE5EE",
        alignSelf: "stretch",
        margin: "16px 0px",
        flex: "none",
    } as CSSProperties,
};
