import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "@/redux";
import jwt_decode from "jwt-decode";

export const checkIfTokenExpired = (expiredCallback: () => void) => {
    const isLongSession = localStorage?.getItem("longSession") === "true";
    // if isLongSession is true meaning user checked "Remember me" when log in, then ignore auto log out
    if (isLongSession) {
        return;
    }
    const token = localStorage?.getItem("authToken");
    if (token) {
        const decodedJwt: any = jwt_decode(token);
        const expirationDate = decodedJwt?.exp * 1000;
        if (expirationDate && expirationDate <= Date.now()) {
            expiredCallback();
        }
    }
};

export const useAutoSignOut = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const params = new URLSearchParams(location.search);
    const fromCustomApp = params.get("fromCustomApp");
    const authToken = params.get("authToken");

    useEffect(() => {
        // only auto logout if online ordering (which doesn't have either fromCustomApp or authToken)
        if (fromCustomApp || authToken) return;
        checkIfTokenExpired(() => dispatch(logOut(true)));
    }, [location]);

    return null;
};
