import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CartActions,
    CartSelectors,
    CheckoutSelectors,
    getActiveStore,
} from "@snackpass/accounting";
import { fetchPickupEstimate } from "@/screens/StoreScreen/lib";
import { getUserIsLoggedIn } from "@/redux";
import {
    FulfillmentTypeEnum,
    WaitTimeTypeEnum,
} from "@snackpass/snackpass-types";
import { isOpenLocal } from "@/utils/Time";
export const useLoadPickupEstimate = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getUserIsLoggedIn);
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const activeStore = useSelector(getActiveStore);
    const cartItems = useSelector(CartSelectors.getCartItems);
    const scheduledDate = useSelector(CartSelectors.getCartScheduleDate);
    const cartSubtotal = useSelector(CheckoutSelectors.getSubtotal);
    const cartTotal = useSelector(CheckoutSelectors.getTotal);

    useEffect(() => {
        if (
            activeStore &&
            fulfillment === FulfillmentTypeEnum.Pickup &&
            !scheduledDate &&
            isOpenLocal(activeStore.hours) &&
            activeStore.waitTimeType === WaitTimeTypeEnum.Dynamic
        ) {
            fetchPickupEstimate({
                cartItemsCount: cartItems?.length || 1, // default to one item
                activeStore,
                basePrice: cartSubtotal.toString() || "",
                totalPrice: cartTotal.toString() || "",
            })
                .then((pickupEstimate) => {
                    if (pickupEstimate) {
                        dispatch(CartActions.setPickupEstimate(pickupEstimate));
                    }
                })
                .catch((error) => console.log(error));
        } else {
            dispatch(CartActions.clearPickupEstimate());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStore?._id, cartItems.length, dispatch, isLoggedIn]);
};
