import { Button } from "@/components/ui/button";
import { Api } from "@/utils";
import fp, { isEmpty } from "lodash/fp";
import React, { useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import { useDispatch } from "react-redux";
import { setUser } from "src/redux";
import { isNetworkError } from "src/utils/Api/REST";
import { sendError } from "src/utils/Errors";

// Most pieces of this are copied from src/components/CardInput/index.tsx
function PayByGiftCardNameEnforcer() {
    const dispatch = useDispatch();
    const _setUser = fp.compose(dispatch, setUser);
    const [name, setName] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");
    const [isLoading, setLoading] = useState(false);

    const _updateUserName = async () => {
        if (isEmpty(name)) {
            setNameError("Name cannot be empty");
            return;
        }
        setLoading(true);
        const nameParts = name.trim().split(" ");
        try {
            const res = await Api.users.update({
                firstName: nameParts[0],
                lastName: nameParts[1],
                name,
            });
            _setUser(fp.prop("data.user", res));
        } catch (err) {
            sendError(err);
            if (isNetworkError(err)) {
                setNameError(
                    "Failed to set name. Please check your network connection."
                );
            } else {
                setNameError("Failed to set name. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="flex flex-col py-2">
            <div className="text-xl font-semibold text-primary leading-6 px-6">
                Pay with Gift Card
            </div>
            <div className="flex flex-row py-3">
                <div className="w-[100%] flex flex-col">
                    <input
                        value={name}
                        type="text"
                        className="flex-1 ml-6 mr-2 border rounded-lg p-2 leading-6 text-primary text-base outline-none"
                        placeholder="Input Name to Pay with Gift Card"
                        maxLength={30}
                        required
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                    <div className="h-[12px] text-sm text-alert mx-6">
                        {nameError}
                    </div>
                </div>
                <div className={"flex mr-6 basis-1/3"}>
                    <Button
                        onPress={_updateUserName}
                        loading={isLoading}
                        label="Submit Name"
                    />
                </div>
            </div>
        </div>
    );
}

export default PayByGiftCardNameEnforcer;
