import React from "react";
import InfoTooltip from "@/SharedComponents/InfoTooltip";
import { ReceiptLineItem } from "@snackpass/accounting";
import { toDollar } from "../../utils/Helpers";

type Props = {
    lineItem: ReceiptLineItem;
    overrideValue?: string;
};
export default function LineItemRow({ lineItem, overrideValue }: Props) {
    return (
        <div
            className={`flex flex-row justify-between self-stretch text-base leading-normal ${
                lineItem.label === "Total" ? "text-lg" : ""
            }`}
        >
            <span className="flex flex-row gap-1 items-center">
                <div
                    className={`${lineItem.textProps?.bold ? "font-bold" : ""}`}
                    style={{ color: lineItem.textProps?.color }}
                >
                    {lineItem.label}
                </div>
                {lineItem.description != null && (
                    <InfoTooltip hoverText={lineItem.description} />
                )}
            </span>
            <div
                className={`${lineItem.textProps?.bold ? "font-bold" : ""}`}
                style={{ color: lineItem.textProps?.color }}
            >
                {lineItem.isDiscount ? "-" : ""}
                {overrideValue ? overrideValue : toDollar(lineItem.value)}
            </div>
        </div>
    );
}
