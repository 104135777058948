import React, { useMemo } from "react";

import { IPromotion, IDealItem, IProduct } from "@snackpass/snackpass-types";
import { ActiveCartItemSelectors } from "@snackpass/accounting";

import ProductCard from "@/screens/StoreScreen/components/ProductCard";
import { useFilterForCompatibleProducts } from "@/hooks/useFilterForCompatibleProducts";
import { useAppSelector } from "@/redux/utils";

import { selectActiveStore } from "#activeStore";

type Props = {
    handlePressProduct: (p: IProduct) => void;
    dealItem: IDealItem;
    promotion: IPromotion;
    isSelected?: boolean;
    isActive?: boolean;
};

export const ProductList = (props: Props) => {
    const { dealItem, handlePressProduct } = props;
    const filterForCompatibleProducts = useFilterForCompatibleProducts();
    const selectedAddons = useAppSelector(
        ActiveCartItemSelectors.getSelectedAddons,
    );
    const activeStore = useAppSelector(selectActiveStore);

    const categoryProductIds = useMemo(
        () =>
            dealItem.categories?.reduce<string[]>((acc, categoryId) => {
                const category = activeStore.productCategories.find(
                    (category) => category._id === categoryId,
                );
                if (!category) {
                    return acc;
                }
                return [...acc, ...category.productIds];
            }, []) ?? [],
        [activeStore, dealItem.categories],
    );

    const productIds: IProduct[] = useMemo(
        () =>
            filterForCompatibleProducts([
                ...dealItem.productIds,
                ...categoryProductIds,
            ]),
        [dealItem.productIds, categoryProductIds, filterForCompatibleProducts],
    );

    return (
        <div className="flex flex-col gap-4">
            {productIds.map((product) => (
                <ProductCard
                    showDescription={false}
                    key={product._id}
                    product={product}
                    handlePressProduct={handlePressProduct}
                    isPromoOrReward={dealItem.discount != null}
                    selectedAddons={selectedAddons}
                />
            ))}
        </div>
    );
};
