import React from "react";
import { cn } from "@/utils";
import { InfoCircledIcon } from "@radix-ui/react-icons";

type Props = {
    message: string;
    messageClassNames?: string;
};
export const ErrorScreen = ({ message, messageClassNames }: Props) => (
    <div className="flex w-full h-screen items-center justify-center">
        <div className={cn("flex items-center", messageClassNames)}>
            <InfoCircledIcon className="mr-1 w-4 h-4" />
            {message}
        </div>
    </div>
);
