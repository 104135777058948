import React, { useState, useEffect } from "react";
import {
    CartWhen,
    WhenTypeEnum,
    Fulfillment,
    FulfillmentTypeEnum,
} from "@snackpass/snackpass-types";
import { CartSelectors, CartActions } from "@snackpass/accounting";
import moment from "moment-timezone";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import { useDispatch } from "react-redux";
import * as actions from "../../redux";
import {
    hideModal,
    ModalsName,
    getStoreFulfillmentOptions,
    selectIsFulfillmentModalOpen,
} from "@/redux";
import FulfillmentToggle from "./components/FulfillmentToggle";
import ModalActivePane from "./components/ModalActivePane";
import ModalFooter from "./components/ModalFooter";
import _ from "lodash";
import { useFetchActiveStore } from "#activeStore/hooks/useFetchActiveStore";
import { FulfillmentModalContext } from "./FulfillmentModalContext";
import { useMediaQuery } from "react-responsive";
import { useDeliveryProvider } from "@/hooks/useDeliveryProvider";
import { useAppSelector } from "@/redux/utils";
import {
    Sheet,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const FulfillmentModal = () => {
    const { fetchStore } = useFetchActiveStore();
    const activeStore = useAppSelector(actions.getActiveStore);
    const isCatering = useAppSelector(CartSelectors.getCartIsCatering);
    const fulfillment = useAppSelector(CartSelectors.getCartFulfillment);
    const fulfillmentsModal = useAppSelector(selectIsFulfillmentModalOpen);
    const isQRCode = useAppSelector(actions.getIsQRCode);
    const tableNumber = useAppSelector(CartSelectors.getCartTableNumber);
    const dispatch = useDispatch();
    const cartTableNumber = useAppSelector(CartSelectors.getCartTableNumber);
    const deliveryAddress = useAppSelector(CartSelectors.getCart).address;
    const fulfillmentOptions = useAppSelector(getStoreFulfillmentOptions);
    const [modalFulfillment, setModalFulfillment] =
        useState<Fulfillment>(fulfillment);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const handleHideFulfillmentModal = () => {
        dispatch(hideModal(ModalsName.fulfillmentModal));
    };
    const setCartWhen = (when: CartWhen) => {
        dispatch(CartActions.setCartWhen(when));
    };

    const { fetchQuotes } = useDeliveryProvider({
        toAddress: deliveryAddress,
        store: activeStore,
        isCatering,
    });

    useEffect(() => {
        if (isQRCode && tableNumber) {
            return;
        }
        if (activeStore?.defaultFulfillment) {
            setModalFulfillment(activeStore.defaultFulfillment);
            return;
        }
        if (
            fulfillmentOptions &&
            !_.isEmpty(fulfillmentOptions) &&
            !fulfillmentOptions.includes(fulfillment)
        ) {
            setModalFulfillment(fulfillmentOptions[0]);
        }
        dispatch(CartActions.clearCartTableNumber);
    }, [
        activeStore?.defaultFulfillment,
        dispatch,
        fulfillment,
        fulfillmentOptions,
        isQRCode,
        tableNumber,
    ]);

    useEffect(() => {
        setModalFulfillment(fulfillment);
    }, [fulfillment]);

    useEffect(() => {
        if (
            modalFulfillment === FulfillmentTypeEnum.Delivery &&
            deliveryAddress
        ) {
            fetchQuotes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fulfillment, deliveryAddress, modalFulfillment]);

    useEffect(() => {
        if (!activeStore) {
            return;
        }
    }, [activeStore, isCatering]);

    const handleConfirmFulfillment = async () => {
        handleHideFulfillmentModal();
        dispatch(CartActions.setCartTableNumber(cartTableNumber ?? ""));
        if (
            modalFulfillment === FulfillmentTypeEnum.DineIn ||
            (modalFulfillment === FulfillmentTypeEnum.Delivery && !isCatering)
        ) {
            setCartWhen(WhenTypeEnum.Now);
            dispatch(CartActions.setCartCateringScheduleDate(null));
            onSetCartScheduleDate(null);
        }
        dispatch(CartActions.setCartFulfillment(modalFulfillment));
    };

    const onHide = async () => {
        setModalFulfillment(fulfillment);
        handleHideFulfillmentModal();
    };

    const onSetCartScheduleDate = (date: moment.Moment | null) => {
        dispatch(
            CartActions.setCartCateringScheduleDate(
                date ? date.toDate() : null,
            ),
        );

        if (!activeStore?._id) return;

        // TODO: We refetch the store (and menu) when the user selects a schedule date. WHY?
        if (date) {
            // Fire and forget :(
            fetchStore(`$${activeStore?._id}`);
        }
    };

    if (isDesktop) {
        return (
            <FulfillmentModalContext.Provider
                value={{
                    modalFulfillment,
                    setModalFulfillment,
                }}
            >
                <Dialog open={fulfillmentsModal} onOpenChange={onHide}>
                    <DialogContent onEscapeKeyDown={onHide}>
                        <div className="hidden-scroll relative flex h-full w-full flex-col overflow-y-auto">
                            <div className="sticky right-0 top-0 z-[101] flex border-b bg-white">
                                <DialogClose className="flex gap-0" />
                                <div className="sticky top-0 ml-6 h-auto w-auto bg-white md:m-auto">
                                    <div className="flex h-14 items-center text-base font-semibold leading-normal text-primary">
                                        Fulfillment
                                    </div>
                                </div>
                            </div>
                            <div className="mx-6 mb-6 mt-4">
                                <FulfillmentToggle name="modal-fulfillment" />
                            </div>
                            <ModalActivePane />
                            <DialogFooter className="border-t bg-white pt-0 md:px-6 pb-4">
                                <ModalFooter
                                    handleConfirmOptions={
                                        handleConfirmFulfillment
                                    }
                                />
                            </DialogFooter>
                        </div>
                    </DialogContent>
                </Dialog>
            </FulfillmentModalContext.Provider>
        );
    }

    return (
        <FulfillmentModalContext.Provider
            value={{
                modalFulfillment,
                setModalFulfillment,
            }}
        >
            <Sheet open={fulfillmentsModal} onOpenChange={onHide}>
                <SheetContent onEscapeKeyDown={onHide}>
                    <SheetHeader>
                        <SheetTitle> Fulfillment</SheetTitle>
                    </SheetHeader>
                    <div className="hidden-scroll relative flex h-full w-full flex-col overflow-y-auto">
                        <div className="mx-6 mb-6 mt-4">
                            <FulfillmentToggle name="modal-fulfillment" />
                        </div>
                        <div className="pb-20">
                            <ModalActivePane />
                        </div>
                    </div>
                    <SheetFooter className="pb-4 px-6 border-t">
                        <ModalFooter
                            handleConfirmOptions={handleConfirmFulfillment}
                        />
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        </FulfillmentModalContext.Provider>
    );
};

export default FulfillmentModal;
