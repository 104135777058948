import React from "react";
import { Colors } from "../../../utils";
import { Text, TextArea } from "../../../SharedComponents";
import { View } from "../../../SharedComponents";
import { IStore } from "@snackpass/snackpass-types";

type SpecialInstructionsProps = {
    activeStore: IStore;
    notes: string;
    onChangeText: (value: string) => void;
};

const SpecialInstructions: React.FC<SpecialInstructionsProps> = ({
    activeStore,
    notes,
    onChangeText,
}) => {
    if (activeStore.noNotes) {
        return (
            <View>
                <Text center color={Colors.gray}>
                    This store has disabled notes
                </Text>
            </View>
        );
    }
    return (
        <View
            style={{
                width: "100%",
                margin: "25px auto",
            }}
        >
            <TextArea
                placeholder="Add special instructions (optional)"
                rows={2}
                style={{
                    padding: 10,
                    fontSize: 15,
                    alignSelf: "stretch",
                    width: "100%",
                    backgroundColor: Colors.white,
                    boxShadow: "rgba(239, 239, 239, 1) 0px 0px 0px 0.5px",
                }}
                handleChangeText={onChangeText}
                value={notes}
            />
        </View>
    );
};

export default SpecialInstructions;
