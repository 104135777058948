import { firebaseAuth } from "@/firebase";
import { signInWithCustomToken } from "firebase/auth";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useAutoSignIn = () => {
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const authToken = params.get("authToken");
    useEffect(() => {
        if (authToken) {
            // Use Firebase to sign in with custom token
            signInWithCustomToken(firebaseAuth, authToken)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    console.log("Successfully signed in:", user);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("Error signing in:", errorCode, errorMessage);
                });
        }
    }, [authToken]);
};
