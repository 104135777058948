import {
    EntityState,
    PayloadAction,
    SerializedError,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { IPurchase, PurchaseStatus } from "@snackpass/snackpass-types";
import { IPurchase_MinimalQueueStatus } from "@snackpass/snackpass-types/src/v1";
import { last } from "lodash";
import API from "src/utils/Api/REST";
import { getPurchaseFirstName } from "@/utils/Helpers";
import { fetchActiveStore } from "#activeStore";

const purchasesQueueAdapter = createEntityAdapter<
    IPurchase_MinimalQueueStatus,
    string
>({
    selectId: (p: IPurchase_MinimalQueueStatus) => p._id,
});

export const isPurchaseSubscribable = (
    purchase: IPurchase | IPurchase_MinimalQueueStatus,
) => {
    const lastPurchaseStatus = last(purchase?.status)?.type;
    return (
        lastPurchaseStatus == null ||
        ![PurchaseStatus.completed, PurchaseStatus.canceled].includes(
            lastPurchaseStatus,
        )
    );
};

export const fetchPurchase = createAsyncThunk(
    "purchases/fetchPurchase",
    async (
        { purchaseId, token }: { purchaseId: string; token: string },
        thunkAPI,
    ) => {
        const response = await API.purchases.getPurchaseReceipt(
            purchaseId,
            token,
        );
        const purchase = response.data.purchase;
        thunkAPI.dispatch(
            fetchActiveStore({ storeIDorSlug: purchase.store._id }),
        );
        // fire fetchQueueStatus, but don't (a)wait on it.
        thunkAPI.dispatch(fetchQueueStatus({ purchaseId, token, purchase }));
        return purchase;
    },
);

export const fetchQueueStatus = createAsyncThunk(
    "purchases/fetchQueueStatus",
    async ({
        purchaseId,
        token,
        purchase,
    }: {
        purchaseId: string;
        token: string;
        purchase: IPurchase;
    }) => {
        if (isPurchaseSubscribable(purchase)) {
            const response = await API.purchases.getQueueStatus(
                purchaseId,
                token,
            );
            return response.data?.queue ?? [];
        }
        return [];
    },
);

const _getMinimalQueueStatusFromPurchase = (purchase: IPurchase) => ({
    _id: purchase._id,
    status: purchase.status,
    pickupTime: purchase.pickupTime,
    isDelayed: purchase.isDelayed,
    receiptNumber: purchase.receiptNumber,
    firstName: getPurchaseFirstName(purchase),
});

interface PurchaseState {
    purchase: IPurchase | null;
    error: SerializedError | null;
    queue: EntityState<IPurchase_MinimalQueueStatus, string>;
}

const initialState = {
    purchase: null,
    error: null,
    queue: purchasesQueueAdapter.getInitialState(),
} as PurchaseState;

const purchaseSlice = createSlice({
    name: "purchase",
    initialState,
    reducers: {
        upsertQueuePurchaseInfo(
            state,
            action: PayloadAction<IPurchase_MinimalQueueStatus>,
        ) {
            const { purchase } = state;
            const { payload } = action;
            if (purchase?._id === action.payload._id) {
                state.purchase = { ...purchase, ...action.payload };
            }
            const { pickupTime } = payload;
            if (
                pickupTime == null ||
                new Date(pickupTime) < new Date() ||
                !isPurchaseSubscribable(payload)
            ) {
                // the purchase is finished, hence it should actually be taken out of the queue.
                purchasesQueueAdapter.removeOne(
                    state.queue,
                    action.payload._id,
                );
            } else {
                purchasesQueueAdapter.upsertOne(state.queue, action.payload);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPurchase.pending, (state) => {
                state.error = null;
            })
            .addCase(
                fetchPurchase.fulfilled,
                (state, action: PayloadAction<IPurchase>) => {
                    state.purchase = action.payload;
                    purchasesQueueAdapter.upsertOne(
                        state.queue,
                        _getMinimalQueueStatusFromPurchase(state.purchase),
                    );
                    state.error = null;
                },
            )
            .addCase(fetchPurchase.rejected, (state, action) => {
                state.error = action.error;
            })
            .addCase(
                fetchQueueStatus.fulfilled,
                (
                    state,
                    action: PayloadAction<Array<IPurchase_MinimalQueueStatus>>,
                ) => {
                    const updatedQueue = purchasesQueueAdapter.setAll(
                        state.queue,
                        action.payload,
                    );
                    const { purchase } = state;
                    if (purchase) {
                        // ensure the current purchase is in the list
                        purchasesQueueAdapter.upsertOne(
                            updatedQueue,
                            _getMinimalQueueStatusFromPurchase(purchase),
                        );
                    }
                },
            );
    },
});

export const getPurchaseReceiptData = (state: {
    purchaseReceiptReducer: { purchase: IPurchase | null | undefined };
}): IPurchase | null | undefined => {
    return state.purchaseReceiptReducer?.purchase;
};
export const getPurchaseReceiptError = (
    state,
): SerializedError | null | undefined => {
    return state.purchaseReceiptReducer?.error;
};

const getQueueStatusFromRedux = (state) =>
    state.purchaseReceiptReducer?.queue ??
    purchasesQueueAdapter.getInitialState();
const { selectAll: getQueueStatus } = purchasesQueueAdapter.getSelectors(
    getQueueStatusFromRedux,
);

// Grabs the started time, but if the purchase is not started yet, uses the estimated pickup time as a fallback.
const _getStartedTime = (purchase: IPurchase_MinimalQueueStatus) => {
    return (
        purchase.status.find((s) => s.type === PurchaseStatus.started)
            ?.createdAt ?? purchase.pickupTime
    );
};
export const getPurchaseQueueStatus = createSelector(
    getQueueStatus,
    (purchases: IPurchase_MinimalQueueStatus[]) => {
        return purchases.sort(
            (
                a: IPurchase_MinimalQueueStatus,
                b: IPurchase_MinimalQueueStatus,
            ) => {
                const aStartedTime = _getStartedTime(a);
                const bStartedTime = _getStartedTime(b);
                // nulls sort after anything else, this should only be the current purchase.
                if (aStartedTime == null) {
                    return 1;
                }
                if (bStartedTime == null) {
                    return -1;
                }
                return (
                    new Date(aStartedTime).getTime() -
                    new Date(bStartedTime).getTime()
                );
            },
        );
    },
);
export const { upsertQueuePurchaseInfo } = purchaseSlice.actions;
export const purchaseReceiptReducer = purchaseSlice.reducer;
