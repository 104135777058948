import fp from "lodash/fp";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";

import {
    ActiveCartItemActions,
    CartAddon,
    CartItemFlat,
    CartSelectors,
    Time,
    toDollar,
} from "@snackpass/accounting";
import { isProductAvailable } from "@snackpass/time";

import {
    ModalsName,
    setCartItemIndex,
    setDealGroupForDelete,
    setIsEditCartItem,
    showModal,
} from "@/redux";
import {
    DigitalGiftCardCartInfo,
    IProduct,
    IPromotion,
    IReward,
    PromoTypes,
} from "@snackpass/snackpass-types";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash.svg";

import { Divider } from "@/SharedComponents";
import useCartItemHandler from "@/hooks/useCartItemHandler";
import { useCheckProductIsSoldOut } from "@/hooks/useCheckProductIsSoldOut";
import { isSnackpassTimeEnabled } from "@/statsig/featureGates";

import Addons from "./Addons";
import { cn } from "@/utils";

export type CartItemProps = {
    item: CartItemFlat;
    cartItemIndex: number | undefined;
    showDivider?: boolean;
    isDealItem?: boolean;
    dealGroupIndex?: number;
};

const CartItemComponent: React.FC<CartItemProps> = ({
    item,
    cartItemIndex,
    showDivider,
    isDealItem,
    dealGroupIndex,
}) => {
    const dispatch = useDispatch();
    const cart = useSelector(CartSelectors.getCart);
    const scheduledDate = useSelector(CartSelectors.getCartScheduleDate);
    const setActiveCartItem = (
        activeProduct: IProduct,
        promotion?: IPromotion,
        reward?: IReward,
        digitalGiftCardInfo?: DigitalGiftCardCartInfo,
    ) =>
        dispatch(
            ActiveCartItemActions.setActiveCartItem(
                activeProduct,
                promotion,
                reward,
                undefined,
                undefined,
                digitalGiftCardInfo,
            ),
        );
    const handleShowProductModal = () => {
        dispatch(showModal(ModalsName.productModal));
    };
    const setActiveCartItemNotes = (notes: string) =>
        dispatch(ActiveCartItemActions.setActiveCartItemNotes(notes));
    const itemNote = item.notes;
    const { removeCartItem } = useCartItemHandler();
    const onRemoveProduct = () => {
        if (cartItemIndex !== undefined && !isDealItem) {
            removeCartItem(cartItemIndex);
        }
        if (dealGroupIndex !== undefined && isDealItem) {
            dispatch(showModal(ModalsName.removeDealModal));
            dispatch(
                setDealGroupForDelete({
                    name: item?.promotion?.name ?? "This Deal",
                    dealGroupIndex,
                    dealItemId: item.dealItem?._id,
                }),
            );
        }
    };
    let product = item.product;
    const promoName =
        item?.promotion?.type === PromoTypes.Deal
            ? `Deal: ${item.promotion.name}`
            : item?.promotion?.name;
    const itemTotal = item.totalPriceAfterDiscount;

    const isSoldOut = useCheckProductIsSoldOut(product._id);

    const validateProductHours = (): {
        isError: boolean;
        message: string | null;
    } => {
        if (!scheduledDate) {
            return { isError: false, message: null };
        }

        // Can just take the first product
        // from the items list as all of them will have the same hours
        let product = item.product;
        if (
            product.hours?.local?.length &&
            // Uses scheduled date if defined, otherwise uses current time
            !(isSnackpassTimeEnabled()
                ? isProductAvailable(product.hours)(scheduledDate?.getTime())
                : Time.isOpen(product, scheduledDate))
        ) {
            let errorMessage: string = `Not available at scheduled time.`;
            return { isError: true, message: errorMessage };
        }

        return { isError: false, message: null };
    };

    const alertProductHours = async () => {
        let product = item.product;
        let title: string = `This product isn't available `;
        if (scheduledDate) {
            title += `for scheduled pickup on ${moment(scheduledDate).format(
                "ddd, MMM D (h:mm a)",
            )} 😩`;
        } else {
            title += `right now 😩`;
        }
        toast(title, {
            description: Time.getHoursForProductDescription(product),
            action: {
                label: "REMOVE",
                onClick: () => {
                    onRemoveProduct();
                },
            },
            unstyled: true,
            classNames: {
                toast: "border rounded-lg w-full p-4 shadow-lg gap-2 flex flex-col bg-white",
                title: "!font-semibold !text-base",
                description: "flex-1 w-full !text-base",
                actionButton:
                    "mt-1 !p-4 !bg-accent text-white !text-base font-semibold rounded-md shrink-0 !self-end !justify-self-start",
            },
        });
    };

    const handleEditCartItem = () => {
        if (isDealItem) {
            return;
        }
        const getAddonGroup = (selectedAddon: CartAddon) => {
            return product.addonGroups.find(
                (addonGroup) => addonGroup._id === selectedAddon.addonGroup._id,
            );
        };
        const getAddon = (selectedAddon: CartAddon) => {
            const addonGroup = getAddonGroup(selectedAddon);
            return addonGroup?.addons.find(
                (addon) => addon._id === selectedAddon.addon._id,
            );
        };

        const selectIncrementAddon = fp.compose(
            dispatch,
            ActiveCartItemActions.selectIncrementAddon,
        );
        dispatch(
            setActiveCartItem(
                product,
                item?.promotion as IPromotion,
                item.reward as IReward,
                item.digitalGiftCardInfo,
            ),
        );
        dispatch(setIsEditCartItem(true));
        cartItemIndex !== undefined &&
            dispatch(setCartItemIndex(cartItemIndex));
        handleShowProductModal();
        const cartItemInEdit =
            cartItemIndex !== undefined ? cart.items[cartItemIndex] : null;
        dispatch(setActiveCartItemNotes(cartItemInEdit?.notes || ""));
        const initializeAddons = () => {
            cartItemInEdit?.selectedAddons?.forEach((selectedAddon) => {
                const addOn = getAddon(selectedAddon);
                for (let i = 0; i < selectedAddon.quantity; i++) {
                    addOn && selectIncrementAddon(addOn);
                }
            });
        };
        initializeAddons();
    };

    const renderError = (errorMessage: string) => {
        return (
            <div style={{ margin: "10px 0" }}>
                <span
                    onClick={alertProductHours}
                    style={{
                        textAlign: "left",
                        width: "auto",
                        cursor: "pointer",
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                        padding: "3px 10px 3px 50px",
                        backgroundColor: "#eaeefd",
                        color: "#2a4df3",
                        fontWeight: "bold",
                    }}
                >
                    {errorMessage}
                </span>
            </div>
        );
    };

    let { isError, message: errorMessage } = validateProductHours();

    return (
        <div
            className={cn("relative py-5", {
                "cursor-pointer": !isDealItem,
                "cursor-default": isDealItem,
            })}
        >
            {isError && renderError(errorMessage as string)}
            <div className="mx-4 flex flex-row">
                <div
                    className="mr-2.5 h-fit rounded-md border px-2 text-base font-semibold leading-6 text-primary"
                    onClick={handleEditCartItem}
                >
                    1
                </div>
                <div
                    className="flex flex-1 flex-col"
                    onClick={handleEditCartItem}
                >
                    <span className="text-base font-semibold leading-6 text-primary">
                        {product.name}
                    </span>
                    {isSoldOut && (
                        <div className="my-2 flex h-[24px] w-fit items-center justify-center rounded-lg bg-secondary/80 px-2 py-1 text-white shadow-md">
                            Sold Out
                        </div>
                    )}
                    <Addons item={item} onClick={handleEditCartItem} />
                    {itemNote && (
                        <div className="text-sm text-gray-400 whitespace-pre-line">
                            {itemNote}
                        </div>
                    )}
                    {product.priceByWeight ? (
                        <div className="mt-0">
                            Weight:
                            <span className="pl-1">
                                {item.weight!.amount +
                                    product.priceByWeight.unit}
                            </span>
                        </div>
                    ) : null}
                    {item?.promotion ? (
                        <div className="text-accent max-w-60">{promoName}</div>
                    ) : null}
                    {item?.reward ? (
                        <div className="text-accent">{item.reward.name}</div>
                    ) : null}
                </div>
                {item.isDiscounted ? (
                    <div className="flex w-[100px] flex-row gap-1">
                        <div
                            className="w-[65px] pt-1 text-right text-accent"
                            onClick={handleEditCartItem}
                        >
                            {toDollar(itemTotal)}
                        </div>
                        <div
                            className="pt-1 text-right text-secondary line-through"
                            onClick={handleEditCartItem}
                        >
                            {toDollar(item.totalPrice)}
                        </div>
                    </div>
                ) : (
                    <div
                        className="w-[65px] pt-1 text-right text-secondary"
                        onClick={handleEditCartItem}
                    >
                        {toDollar(item.totalPrice)}
                    </div>
                )}

                <div
                    className="ml-3.5 h-fit cursor-pointer rounded-lg border border-border p-1.5 hover:bg-gray-50 "
                    onClick={onRemoveProduct}
                >
                    <TrashIcon className="h-[18px] w-[18px] fill-primary" />
                </div>
            </div>
            {showDivider ? (
                <div className="pl-4 pt-6">
                    <Divider width={1} />
                </div>
            ) : null}
        </div>
    );
};

export default CartItemComponent;
