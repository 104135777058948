import React, { useState } from "react";
import PhoneNumber from "./PhoneNumber";
import { IUser } from "@snackpass/snackpass-types";
import Verification from "./Verification";

interface PhoneVerificationProps {
    handleHide: () => void;
    enterPhoneNumberSubtitle?: string;
    checkoutVerifyCallback?: () => void;
    isVerifyCodeSent?: boolean;
    // this will skip the steps after verify
    verifyPhoneCallback?: any;
    authModal?: boolean;
    setModalTitle?: React.Dispatch<React.SetStateAction<string>>;
}

const PhoneVerification: React.FC<PhoneVerificationProps> = ({
    verifyPhoneCallback,
    checkoutVerifyCallback,
    handleHide,
    authModal,
    setModalTitle,
}) => {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [showVerification, setShowVerification] = useState<boolean>(false);

    const onPhoneVerificationSuccess = (user: IUser) => {
        if (verifyPhoneCallback) {
            // this timeout is necessary because of the custom auth token not
            // being set in time
            setTimeout(() => {
                verifyPhoneCallback(user);
            }, 1000);
            return;
        }

        if (checkoutVerifyCallback) {
            checkoutVerifyCallback();
        }
        if (handleHide) {
            handleHide();
        }
    };

    if (authModal) {
        return (
            <div>
                {!showVerification ? (
                    <PhoneNumber
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        showVerification={showVerification}
                        setShowVerification={() => {
                            setShowVerification(true);
                            setModalTitle &&
                                setModalTitle("Verify your number");
                        }}
                        hideVerification={() => {
                            setShowVerification(false);
                        }}
                        authModal={authModal}
                    />
                ) : null}
                {showVerification && phoneNumber && (
                    <Verification
                        phoneNumber={phoneNumber}
                        onSuccess={onPhoneVerificationSuccess}
                        authModal={authModal}
                    />
                )}
            </div>
        );
    }

    return (
        <div>
            <PhoneNumber
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                showVerification={showVerification}
                setShowVerification={() => {
                    setShowVerification(true);
                }}
                hideVerification={() => {
                    setShowVerification(false);
                }}
            />
            {showVerification && phoneNumber && (
                <Verification
                    phoneNumber={phoneNumber}
                    onSuccess={onPhoneVerificationSuccess}
                />
            )}
        </div>
    );
};

export default PhoneVerification;
