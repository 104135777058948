import { Helpers } from "@/utils";
import { replaceFullDayOfWeek } from "@snackpass/accounting/build/src/utils/Time";
import { IPromotion, PromoTarget } from "@snackpass/snackpass-types";
import moment from "moment";

export const momentFormat1 = "ddd M/D @ h:mma";
const TWO_DAYS = 2;

type PromoTimePeriod = {
    startTime: Date | string | null;
    endTime: Date | string | null;
};

export const isAfterEndTime = (timePeriod: PromoTimePeriod) => {
    if (!timePeriod) {
        return true;
    }
    if (!timePeriod.endTime) {
        return false;
    }
    const closeTime = moment(timePeriod.endTime);
    const duration = moment.duration(moment().diff(closeTime)).asSeconds();
    if (duration >= 0) {
        return true;
    }
    return false;
};

export const isAfterStartTime = (startTime: Date | string) => {
    const openTime = moment(startTime);
    const duration = moment.duration(moment().diff(openTime)).asSeconds();
    if (duration >= 0) {
        return true;
    }
    return false;
};

export const getActiveTimePeriodCondition = (timePeriod: PromoTimePeriod) => {
    const startTime = timePeriod?.startTime;
    if (!startTime) {
        return null;
    }
    // timePeriod.startTime is non Null now
    if (isAfterStartTime(startTime)) {
        return getActiveTimePeriodEndCondition(timePeriod);
    }
    return getActiveTimePeriodStartCondition(timePeriod);
};

const getActiveTimePeriodEndCondition = (timePeriod: PromoTimePeriod) => {
    if (timePeriod?.endTime) {
        const now = moment();
        const endMoment = moment(timePeriod.endTime);
        const duration = endMoment.diff(now, "days");
        if (duration < TWO_DAYS) {
            return (
                getEndPrefix(timePeriod) +
                moment(timePeriod.endTime).format(momentFormat1)
            );
        }
    }
    return null;
};

const getActiveTimePeriodStartCondition = (activeTimePeriod) => {
    return "starts " + moment(activeTimePeriod.startTime).format(momentFormat1);
};

const getEndPrefix = (promotion) => {
    if (isAfterEndTime(promotion)) {
        return "ended at ";
    } else {
        return "ends ";
    }
};

export function getDiscountText(promotion: IPromotion) {
    const ret = "";

    if (!promotion) {
        return ret;
    }
    if (promotion.discount?.isDoublePoints) {
        return ret;
    }
    if (!promotion.discount) {
        return ret;
    }
    if (promotion.discount.newPrice !== undefined) {
        if (promotion.discount.newPrice === 0) {
            return "Free";
        }
        return Helpers.toDollar(promotion.discount.newPrice);
    }
    if (promotion.discount.percentOff !== undefined) {
        if (promotion.discount.percentOff === 100) {
            return "Free";
        }
        return `${promotion.discount.percentOff}% off`;
    }
    if (promotion?.discount?.dollarsOff) {
        return `${Helpers.toDollar(promotion.discount.dollarsOff)} off`;
    }
}

export function getDiscountConditions(promotion: IPromotion): string[] {
    const ret: string[] = [];
    if (promotion.conditions?.onePerCart) {
        ret.push("one per cart");
    }
    if (promotion.conditions?.pickupOnly) {
        ret.push("pickup only");
    }
    if (promotion.conditions?.deliveryOnly) {
        ret.push("delivery only");
    }
    if (
        promotion.conditions?.cartMax !== undefined &&
        typeof promotion.conditions?.cartMax === "number"
    ) {
        ret.push(`cart max ${Helpers.toDollar(promotion.conditions?.cartMax)}`);
    }
    if (
        promotion.conditions?.cartMin !== undefined &&
        typeof promotion.conditions?.cartMin === "number"
    ) {
        ret.push(`cart min ${Helpers.toDollar(promotion.conditions?.cartMin)}`);
    }
    if (promotion.targets.indexOf(PromoTarget.FirstTime) !== -1) {
        ret.push("first order");
    }
    if (promotion.targets.indexOf(PromoTarget.Students) !== -1) {
        ret.push("students only");
    }
    if (promotion.clientShowsRemainingUses && promotion.maximumUses !== null) {
        const remainingUses =
            (promotion.maximumUses || 0) - (promotion.totalUses || 0);
        ret.push(`${remainingUses} remaining (${promotion.maximumUses} total)`);
    }
    if (promotion.hours && promotion["happyHourTimeDescription"]) {
        ret.push(
            "happy hour" +
                `, ${replaceFullDayOfWeek(
                    // @ts-ignore happyHourTimeDescription exists as a virtual on PromotionSchema
                    promotion?.happyHourTimeDescription?.toLowerCase(),
                )}`,
        );
    }
    if (promotion?.activeTimePeriod) {
        const activeTimePeriodCondition = getActiveTimePeriodCondition(
            promotion.activeTimePeriod,
        );

        activeTimePeriodCondition && ret.push(activeTimePeriodCondition);
    }

    return ret;
}
