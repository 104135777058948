import { indexBy, isDeepEqual, omit, prop } from "remeda";
import { call, select } from "typed-redux-saga";

import { CartSelectors, CartItemWithTaxInfo } from "@snackpass/accounting";
import { IProduct } from "@snackpass/snackpass-types";

import { CartConflict } from "#cart/slice";

export function* simpleCartValidationSaga(products: IProduct[]) {
    const cart = yield* select(CartSelectors.getCart);

    return yield* call(findConflicts, cart.items, products);
}

const findConflicts = (
    cartItems: CartItemWithTaxInfo[],
    products: IProduct[],
): CartConflict[] => {
    // Return early if no cart items.
    if (!cartItems.length) return [];

    // Create a map of products for efficient lookup.
    const productsMap = indexBy(products, prop("_id"));

    return cartItems.reduce<CartConflict[]>((conflicts, item, index) => {
        const conflict = hasConflict(item, productsMap);

        if (conflict[0]) {
            conflicts.push({
                item,
                cartIndex: index,
                reason: {
                    type: conflict[1],
                },
            });
        }

        return conflicts;
    }, []);
};

/**
 * Determines if a cart item has a conflict with the current menu and provides a reason if so.
 */
const hasConflict = (
    item: CartItemWithTaxInfo,
    productsMap: Record<string, IProduct>,
) => {
    const product = productsMap[item.product._id];

    // We have a conflict if the product no longer exists or has changed.
    if (!product) return [true, "unavailable"] as const;

    if (!isDeepEqual(omit(product, ["menu"]), omit(item.product, ["menu"])))
        return [true, "stale"] as const;

    return [false] as const;
};
