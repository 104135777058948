import { fork } from "typed-redux-saga";

import { cartValidationSaga } from "./cart-validation";
import { surfaceUpsellSaga } from "./surfaceUpsellSaga";

export function* cartRootSaga() {
    // Starts a saga that validates the cart.
    yield* fork(cartValidationSaga);

    // Starts a saga that surfaces the upsell modal when there are no fulfillment conflicts.
    yield* fork(surfaceUpsellSaga);
}
