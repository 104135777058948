import { useCallback, useMemo } from "react";

import {
    getBasePriceAfterDiscount,
    getHasPromotionOrReward,
    getUnitPriceAfterDiscount,
} from "@snackpass/accounting";
import {
    IDealItem,
    IProduct,
    IPromotion,
    IReward,
} from "@snackpass/snackpass-types";

export const useGetProductPrice = (
    product: IProduct,
    reward: IReward | null,
    promotion: IPromotion,
    dealItem: IDealItem | null,
) => {
    const basePriceAfterDiscount = useMemo(
        () =>
            product.priceByWeight
                ? getUnitPriceAfterDiscount(
                      product,
                      reward,
                      promotion,
                      dealItem,
                  )
                : getBasePriceAfterDiscount(
                      product,
                      reward,
                      promotion,
                      dealItem,
                  ),
        [dealItem, product, promotion, reward],
    );

    const getIsDiscounted = useCallback(() => {
        const hasPromotionOrReward = getHasPromotionOrReward(promotion, reward);
        if (dealItem) {
            return dealItem.discount != null;
        }
        return !!(promotion || reward) && hasPromotionOrReward;
    }, [dealItem, promotion, reward]);

    return {
        basePriceAfterDiscount,
        isDiscounted: getIsDiscounted(),
    };
};
