import { ActiveStoreState, activeStoreSlice } from "#activeStore/slice";
import { createSelector } from "@reduxjs/toolkit";
import { IStore } from "@snackpass/snackpass-types";

function assertActiveStore(state: ActiveStoreState): asserts state is IStore {
    if (!state) {
        throw new Error("[assertActiveStore]: Active store is not set.");
    }
}

const selectActiveStoreState = (state: {
    [activeStoreSlice.name]: ActiveStoreState;
}) => {
    return state[activeStoreSlice.name];
};

/**
 * Selects the current active store.
 * @throws {Error} If the active store is not set.
 * NB: This selector is only intended to be used in the context of a component that requires an active store.
 */
export const selectActiveStore = createSelector(
    [selectActiveStoreState],
    (activeStore) => {
        assertActiveStore(activeStore);
        return activeStore;
    },
);

/**
 * Selects the current active store, if set.
 */
export const selectMaybeActiveStore = createSelector(
    [selectActiveStoreState],
    (activeStore) => activeStore,
);

/**
 * Selects the ID of the current active store, if set.
 */
export const selectMaybeActiveStoreID = createSelector(
    [selectMaybeActiveStore],
    (activeStore) => activeStore?._id,
);

/**
 * Selects the `useMulMenu` flag of the current active store.
 */
export const selectUseMulMenu = createSelector(
    [selectMaybeActiveStore],
    (activeStore) => activeStore?.useMulMenu ?? false,
);

export const getIsCorporate = createSelector(
    [selectMaybeActiveStore],
    (activeStore) => activeStore?.useCorporateMap ?? false,
);
