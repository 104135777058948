import { setStoreFulfillmentOptions } from "@/redux";
import { StoreUtils } from "@/utils";
import {
    CartActions,
    CartSelectors,
    getActiveStore,
} from "@snackpass/accounting";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

export function useFulfillmentOptions() {
    // this hook is used to set the fulfillment options for the store
    const activeStore = useSelector(getActiveStore);
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const dispatch = useDispatch();
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);

    useEffect(() => {
        if (activeStore) {
            const fulfillmentOptions = StoreUtils.getStoreFulfillmentOptions(
                activeStore,
                isCatering,
            );
            dispatch(setStoreFulfillmentOptions(fulfillmentOptions));
            // change fulfillment when new selected store doesn't support the previously selected fulfillment
            if (
                !_.isEmpty(fulfillmentOptions) &&
                !fulfillmentOptions.includes(fulfillment)
            ) {
                dispatch(CartActions.setCartFulfillment(fulfillmentOptions[0]));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStore?._id, isCatering]);
}
