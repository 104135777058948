import {
    Addon,
    AddonGroup,
    Fulfillment,
    Geolocation,
    IProduct,
    IPurchase,
    ISelectedAddon,
    IStore
} from "@snackpass/snackpass-types";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import accounting from "accounting";
import { getDistance } from "geolib";
import _ from "lodash";
import moment from "moment-timezone";
import { CartAddon } from "../types";
import * as Constants from "./Constants";

export function round(num: number, decimalPlaces = 2): number {
    return Number(
        `${Math.round(parseFloat(`${num}e${decimalPlaces}`))}e-${decimalPlaces}`
    );
}
export function dollarsToCents(dollarAmount: number): number {
    return Math.round(dollarAmount * 100);
}

export function centsToDollars(centsAmount: number): number {
    return centsAmount / 100;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isNumber(value: any): boolean {
    return typeof value === "number";
}

export function metersToMiles(meters: number): number {
    return meters * 0.000621371192;
}

/**
 * Calculates distance between two geolocations in meters.
 */
export function getDistanceBetweenGeopoints(
    point1: Geolocation,
    point2: Geolocation
): number {
    return metersToMiles(
        getDistance(
            {
                latitude: point1.coordinates[1],
                longitude: point1.coordinates[0]
            },
            {
                latitude: point2.coordinates[1],
                longitude: point2.coordinates[0]
            }
        )
    );
}

export function toDollar(value: number): string {
    return `$${(Math.round(value * 100) / 100).toFixed(2)}`;
}

/**
 * Return a formatted quantity for addons e.g. '99x'
 * if quantity is greater than 1
 */
export function formatQuantity(quantity: number): string {
    if (quantity > 0) {
        // extra space is intended
        return `${quantity}x `;
    }
    return "";
}

/**
 *
 * @param {number} value A percent as a number like 2.9 (2.9%). Supports 4 decimal places
 * @return {number} The value as a decimal
 * Example:
 *      percentToDecimal(2.9) => 0.0290
 */
export function percentToDecimal(value: number): number {
    if (!value) {
        return 0.0;
    }

    return (parseFloat((value * 0.01).toString()) * 10000) / 10000;
}

export const itemIsSoldOut = (item: Addon | IProduct): boolean => {
    if (item.soldOut) {
        return true;
    } else if (item.soldOutDates?.from && item.soldOutDates?.until) {
        const now = moment();

        if (
            moment(item.soldOutDates.from) < now &&
            now < moment(item.soldOutDates.until)
        ) {
            return true;
        }
    }

    return false;
};
// eslint-disable-next-line @typescript-eslint/unbound-method
export const capitalize: (str: string) => string = _.capitalize;

/**
 *
 * @param {number} amount A decimal number like 0.615 ($0.615).
 * @return {number} The value as its dollar amount
 * Example:
 *      toFixed(0.615) => 0.62
 */
export function toFixed(amount: number): number {
    return parseFloat(accounting.toFixed(amount, 2));
}

export const calcGetInPickupRange = _.curry(
    (
        calcDistance: typeof getDistanceBetweenGeopoints,
        myLocation: Geolocation,
        store: IStore
    ) => {
        if (!store) {
            return false;
        }

        const distance = calcDistance(myLocation, store.geolocation);

        return distance < (store.pickupRange || Constants.DEFAULT_PICKUP_RANGE);
    }
);

export const calcInDeliveryZone = (
    location: Geolocation,
    store: IStore
): boolean =>
    store.deliveryZone
        ? booleanPointInPolygon(location.coordinates, store.deliveryZone)
        : false;

export const calcGetInDeliveryRange = _.curry(
    (
        calcDistance: typeof getDistanceBetweenGeopoints,
        myLocation: Geolocation,
        store: IStore
    ) => {
        if (store.deliveryZone) {
            return calcInDeliveryZone(myLocation, store);
        }

        const distance = calcDistance(myLocation, store.geolocation);
        const ranges = store.deliveryRanges.length
            ? store.deliveryRanges
            : Constants.DEFAULT_DELIVERY_RANGES;

        const maxRange: number = _.max(ranges.map((range) => range.end)) || 0;

        return distance < maxRange;
    }
);

export const calcGetInRange = _.curry(
    (
        calcDistance: typeof getDistanceBetweenGeopoints,
        calcInPickupRange: typeof calcGetInPickupRange,
        calcInDeliveryRange: typeof calcGetInDeliveryRange,
        myLocation: Geolocation,
        storeObj: IStore,
        fulfillment: Fulfillment
    ) => {
        if (fulfillment === "PICKUP") {
            return calcInPickupRange(calcDistance, myLocation, storeObj);
        }

        if (fulfillment === "DELIVERY") {
            return calcInDeliveryRange(calcDistance, myLocation, storeObj);
        }

        return true;
    }
);

export const isInPickupRange = calcGetInPickupRange(
    getDistanceBetweenGeopoints
) as (myLocation: Geolocation, store: IStore) => boolean;

export const isInDeliveryRange = calcGetInDeliveryRange(
    getDistanceBetweenGeopoints
) as (myLocation: Geolocation, store: IStore) => boolean;

export const isInRangeForFulfillment = calcGetInRange(
    getDistanceBetweenGeopoints,
    calcGetInPickupRange,
    calcGetInDeliveryRange
) as (
    myLocation: Geolocation,
    store: IStore,
    fulfillment: Fulfillment
) => boolean;

/**
 * Gets sum of all selected addon quantities in addon group
 * @param addonGroup
 * @param selectedAddons
 * @returns summed quantity
 */
export function getNumSelectedAddonsInAddonGroup(
    addonGroup: AddonGroup,
    selectedAddons: CartAddon[] | null
): number {
    if (!selectedAddons) {
        return 0;
    }

    return selectedAddons
        .filter(
            // Filter out addons with wrong group id
            (addon) => addon.addonGroup._id === addonGroup._id
        )
        .reduce(
            // Sum up quantities of each addon
            (sum, cartAddon) => sum + cartAddon.quantity,
            0
        );
}

/**
 * Checks if limit has been reached for addon group
 * @param addonGroup
 * @param selectedAddons
 * @returns true if sum of addon quantities === limit
 */
export function addonGroupLimitIsReached(
    addonGroup: AddonGroup,
    selectedAddons: CartAddon[] | null
): boolean {
    return (
        _.has(addonGroup, "limit") &&
        addonGroup.limit !== 0 &&
        getNumSelectedAddonsInAddonGroup(addonGroup, selectedAddons) ===
            addonGroup.limit
    );
}

/**
 * Finds addon in selectedAddons by addon id
 * @param addonId
 * @param selectedAddons
 * @returns addonIndex
 */
export function getAddonIndexById(
    addonId: string,
    selectedAddons: CartAddon[] | null
): number {
    if (!selectedAddons) {
        return -1;
    }

    return selectedAddons.map((item) => item.addon._id).indexOf(addonId);
}

/**
 * Gets quantity of addon in selectedAddons
 * @param addon
 * @param selectedAddons
 * @returns quantity
 */
export function getAddonQuantityByAddon(
    addon: Addon,
    selectedAddons: CartAddon[] | null
): number {
    if (!selectedAddons) {
        return 0;
    }
    const addonIndex = getAddonIndexById(addon._id, selectedAddons);
    if (addonIndex === -1) {
        return 0;
    }
    return selectedAddons[addonIndex].quantity;
}

export const purchaseIs3PDelivery = (
    purchase: Pick<IPurchase, "fulfillment" | "deliveryInfo">
): boolean =>
    purchase.fulfillment === "DELIVERY" &&
    (purchase.deliveryInfo?.provider || "store") !== "store";

/**
 * Given ISelectedAddon, computes total price and total discounted price
 * @param addon
 * @returns array of two numbers
 */
export function getPricesFromISelectedAddon(
    addon: ISelectedAddon
): [number, number] {
    const totalPrice = (addon.price || 0) * (addon.quantity || 1);
    const totalPriceAfterDiscount =
        (addon.priceAfterDiscount || 0) * (addon.quantity || 1);
    return [totalPrice, totalPriceAfterDiscount];
}
