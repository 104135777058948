import fp from "lodash/fp";
import moment from "moment-timezone";
import { IStore } from "@snackpass/snackpass-types";
import { useBatchListener } from "./hooks";
import { Database } from "firebase/database";
import {
    UseSchedulingReturn,
    getBatchForSlot,
    isBatchFull,
    isSlotAvailable,
    useTimeSlots,
} from "@snackpass/accounting";

export type UseSchedulingParams = {
    db: Database;
    store: IStore | null;
    date: moment.Moment | null;
    isCatering?: boolean;
};

/**
 * This is a hook that can easily be plugged in to any React component and provides
 * a set of helper methods for scheduled orders. It returns a few useful things...
 *
 * 1) time slots where schedule ahead is available
 * 2) helper methods to determine if a slot is full / available
 * 3) helper methods to determine how many spots in a time slot are available if the store has batching
 *
 * @param {dbRef: Firebase DB, store: IStore | null, date: Date | null}
 */
export function useScheduling({
    db,
    store,
    date,
    isCatering,
}: UseSchedulingParams): UseSchedulingReturn {
    const hasBatching = fp.getOr(false, "hasBatching", store);
    const batchSize = fp.getOr(15, "scheduleAheadBatchSize", store);
    const catering = isCatering ?? false;
    const timeSlots = useTimeSlots({
        store,
        date,
        catering,
    });
    const { batchMapping } = useBatchListener({
        db,
        timeSlots,
        store,
        date,
        hasBatching,
    });

    const _getBatchForSlot = getBatchForSlot(
        batchSize,
        batchMapping,
        timeSlots,
    );

    const isBatchFullCurried = hasBatching
        ? isBatchFull(_getBatchForSlot)
        : () => false;

    return {
        timeSlots,
        hasBatching,
        isSlotAvailable: isSlotAvailable(store, isBatchFullCurried),
        getBatchForSlot: _getBatchForSlot,
        isBatchFull: isBatchFullCurried,
    };
}
