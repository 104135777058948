import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUtmTracking } from "src/redux/utmTracking";

export function useUTM() {
    const dispatch = useDispatch();
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        // Note: need to default to null just in case utm_source is an ""
        const utm_source = query.get("utm_source") || null;
        const utm_medium = query.get("utm_medium") || null;
        const utm_term = query.get("utm_term") || null;
        const utm_content = query.get("utm_content") || null;
        const utm_campaign = query.get("utm_campaign") || null;

        dispatch(
            setUtmTracking({
                utm_source,
                utm_medium,
                utm_term,
                utm_content,
                utm_campaign,
            })
        );

        const isQR = utm_source && utm_source?.toLowerCase() === "qr";
        window.localStorage?.setItem("isQR", JSON.stringify(isQR));
    }, []);
}
