import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { StatsigProvider } from "statsig-react";
import { getActiveStore, getUser } from "src/redux";
import config from "src/config";

const STATSIG_PROVIDER_OPTIONS = {
    environment: { tier: config.STATSIG_ENVIRONMENT },
};

type StatsigReduxProviderProps = {
    children: React.ReactElement;
};

export const StatsigReduxProvider = ({
    children,
}: StatsigReduxProviderProps) => {
    const user = useSelector(getUser);
    const store = useSelector(getActiveStore);
    // Memoize the values based on user and store changes
    const memoizedValues = useMemo(() => {
        const userId = user?._id || "";
        const email = user?.email || "";
        const storeId = store?._id || "";

        return { userId, email, storeId };
    }, [user?._id, store?._id]);
    return (
        <StatsigProvider
            sdkKey={config.STATSIG_KEY || ""}
            user={{
                userID: memoizedValues.userId,
                email: memoizedValues.email,
                customIDs: {
                    storeID: memoizedValues.storeId,
                },
            }}
            options={STATSIG_PROVIDER_OPTIONS}
        >
            {children}
        </StatsigProvider>
    );
};
