import React from "react";
import { IProduct } from "@snackpass/snackpass-types";
import { optimizedImageURL } from "@/utils/Helpers";

type ProductImageProps = {
    activeProduct: IProduct;
};

const ProductImage: React.FC<ProductImageProps> = React.memo(
    ({ activeProduct }) => {
        if (!activeProduct.image) {
            return null;
        }
        return (
            <div
                className="flex flex-col items-center justify-center self-start w-full"
                style={{
                    backgroundImage: `url("${optimizedImageURL(
                        activeProduct.image,
                    )}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: 220,
                }}
            ></div>
        );
    },
);

export default ProductImage;
