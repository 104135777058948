import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveChainStores, getActiveStore } from "@/redux";
import { useLoadChainStores } from "@/hooks/useLoadChainStores";
import { useLoadStore } from "@/hooks/useLoadStore";
import { match } from "react-router-dom";
import { SetMyLocation } from "@/components/SetMyLocation";
import { StoreMap } from "@/components/StoreMap";
import { DistanceWarning } from "@/modals/ChooseLocationModal/Warning";
import { StoreList } from "@/modals/ChooseLocationModal/StoreList";
import { Geolocation } from "@snackpass/snackpass-types";
import { Button } from "@/components/ui/button";
import LoadingScreen from "../LoadingScreen";
import useURLParams from "@/hooks/useURLParams";
import { useSetLocationFromURL } from "@/screens/LocationsScreen.tsx/hooks/useSetLocationFromURL";
import { setCenter } from "@/redux/locations";
import { useSelectStore } from "./hooks/useSelectStore";
import { StoreUtils } from "@/utils";

type Props = {
    match: match<{ storeNameAndId: string }>;
};

export function LocationsScreen(props: Props) {
    const { match } = props;
    const { storeNameAndId } = match.params;

    const dispatch = useDispatch();
    useLoadStore(storeNameAndId);
    useLoadChainStores();
    const { urlGeolocation, latParam, lngParam } = useSetLocationFromURL();
    const params = useURLParams();

    const fromCustomApp = params.get("fromCustomApp");

    const activeStore = useSelector(getActiveStore);
    const { madeASelection, handleConfirm, selectedStore } = useSelectStore();

    const _setCenter = (center: Geolocation) => {
        dispatch(setCenter(center));
    };
    useEffect(() => {
        if (latParam && lngParam) {
            _setCenter(urlGeolocation);
        }
    }, [latParam, lngParam]);

    const hasLatAndLngParams = latParam && lngParam;
    const activeChainStores = useSelector(getActiveChainStores);

    // if no lat and lng params, set the center to the first store location
    useEffect(() => {
        if (!hasLatAndLngParams && activeChainStores.length > 0) {
            const firstStoreLocation = activeChainStores[0].geolocation;
            _setCenter(firstStoreLocation);
        }
    }, [hasLatAndLngParams, activeChainStores.length]);

    const _onClickLocationArrow = () => {
        setCenter(urlGeolocation);
    };
    const hideMap = !(latParam && lngParam);

    if (!activeStore) {
        return <LoadingScreen />;
    }
    return (
        <div className="max-w-5xl m-auto">
            <div className="flex flex-col sticky top-0 right-0 z-[101] ">
                <div className="flex bg-white">
                    <div className="h-auto bg-white ml-6 md:m-auto flex flex-row justify-between w-full">
                        <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal flex-1 w-full">
                            Choose location
                        </div>
                        {!hideMap && (
                            <div className="flex justify-end p-2">
                                <div
                                    className="p-2 px-3 cursor-pointer border-2 border-gray-300 text-blue-500 rounded-lg"
                                    onClick={_onClickLocationArrow}
                                >
                                    <i className="fas fa-location-arrow" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1 bg-white">
                    {!fromCustomApp && (
                        <div className="px-6">
                            <SetMyLocation />
                        </div>
                    )}
                    <StoreMap className="h-[360px] rounded-none" />
                </div>
            </div>
            {/* scrollable store list */}
            <div className="w-full px-6 py-4">
                {(!fromCustomApp || hasLatAndLngParams) && <DistanceWarning />}
                <StoreList />
            </div>
            {madeASelection ? (
                <div className="bg-white border-t md:px-6 p-4 fixed bottom-0 w-full max-w-5xl">
                    <Button
                        onPress={() => {
                            handleConfirm(); // Add url param to skip going to the new page after confirming
                            if (!fromCustomApp && selectedStore) {
                                window.location.href =
                                    StoreUtils.getStoreRoute(selectedStore);
                            }
                        }}
                        label="Confirm Location"
                        className="w-full m-auto"
                    />
                </div>
            ) : null}
        </div>
    );
}
