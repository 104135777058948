import React from "react";
import { optimizedImageURL } from "@/utils/Helpers";
import { IStore } from "@snackpass/snackpass-types";

type LoyaltyCardProps = {
    activeStore: IStore | null;
    userName: string | null | undefined;
    customerSince: string;
};

export function LoyaltyCard({
    activeStore,
    userName,
    customerSince,
}: LoyaltyCardProps) {
    if (!activeStore) {
        return null;
    }

    const useLogoUrl =
        !activeStore?.loyaltyCardBackgroundImage && activeStore?.logoUrl;
    return (
        <div
            style={{
                minHeight: 210,
                borderRadius: "24px",
                background: activeStore?.loyaltyCardBackgroundImage
                    ? `url("${optimizedImageURL(
                          activeStore?.loyaltyCardBackgroundImage,
                          {
                              size: { h: 210 },
                          },
                      )}")`
                    : `linear-gradient(157deg, rgba(255, 255, 255, 0.20) 39.93%, rgba(255, 255, 255, 0.00) 40.06%), linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${
                          activeStore.brandColor || activeStore.color
                      }`,
                backgroundBlendMode: "soft-light, overlay, normal",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                boxShadow:
                    "0px 5px 21px 0px rgba(0, 0, 0, 0.08), 0px 8px 43px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(15, 15, 15, 0.12), 0px -1px 1px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.40) inset",
            }}
            className="flex flex-row items-end relative"
        >
            <div className="before:block before:absolute before:top-0 before:left-0 before:h-[100%] before:w-[100%] before:bg-gradient-to-b from-transparent via-transparent to-black before:rounded-3xl opacity-30" />
            {useLogoUrl ? (
                <div
                    className="absolute top-3 left-3 right-0 bottom-0"
                    style={{
                        width: 57,
                        height: 57,
                        borderRadius: 57,
                        flexShrink: 0,
                        background: `url(${activeStore.logoUrl}) no-repeat center/cover`,
                    }}
                />
            ) : null}
            <div className="flex flex-col p-4 items-end flex-1 absolute bottom-0 right-0 w-[100%]">
                <div className="flex-1 text-white font-semibold">
                    {userName}
                </div>
                <div className="flex-1 flex-col items-end flex  text-white">
                    {customerSince}
                </div>
            </div>
        </div>
    );
}
