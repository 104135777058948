import React, { useMemo, useState } from "react";
import { noop } from "lodash/fp";
import { useSelector } from "react-redux";
import { ReactComponent as CartIcon } from "src/assets/icons/cart_outline.svg";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import ViewOrderContent from "@/modals/ViewOrderModal/ViewOrderContent";
import ViewOrderButton from "./ViewOrderButton";
import { CartSelectors } from "@snackpass/accounting";
import { cn } from "@/utils";
import { useMediaQuery } from "react-responsive";

export const DesktopCartButton = () => {
    const cartItems = useSelector(CartSelectors.getCartItems);
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    const isMobile = useMemo(() => isSmallScreen, [isSmallScreen]);
    const [isPopoverVisible, setIsPopoverVisible] = useState(true);
    const cartItemsLength = cartItems.length ?? 0;
    const onOpenChange = () =>
        cartItemsLength ? setIsPopoverVisible(!isPopoverVisible) : null;
    if (isMobile) {
        return null;
    }
    return (
        <Popover
            open={isPopoverVisible && cartItemsLength > 0}
            onOpenChange={onOpenChange}
        >
            <PopoverTrigger asChild>
                <Button
                    label={
                        <>
                            <CartIcon
                                className={cn("w-[20px] h-[20px]", {
                                    "fill-white": cartItemsLength,
                                    "fill-primary": !cartItemsLength,
                                })}
                            />
                            <span className="ml-2">Cart</span>
                            <span
                                className={cn("font-black mx-1", {
                                    "text-white": cartItemsLength,
                                    "text-primary": !cartItemsLength,
                                })}
                            >
                                {"\u00B7"}
                            </span>
                            <span>{cartItemsLength}</span>
                        </>
                    }
                    onPress={noop}
                    variant={cartItemsLength ? "brand" : "outline"}
                    className={cn({
                        "border-primary text-primary cursor-default":
                            !cartItemsLength,
                    })}
                />
            </PopoverTrigger>
            <PopoverContent className="m-0 p-0 w-[450px] h-auto min-h-[215px] max-h-[700px] overflow-auto relative pb-[70px]">
                <div className="flex flex-col justify-start items-center w-[100%] h-[100%]">
                    <div className="w-[100%]">
                        <ViewOrderContent hideOrderNotes showDivider />
                    </div>
                    <div className="w-[97%] fixed bottom-0 left-2 bg-white">
                        <ViewOrderButton
                            classNames="p-0 px-4 pb-4"
                            isGotoCartButton
                        />
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
};

export default DesktopCartButton;
