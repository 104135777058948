import { RNFirebase } from "react-native-firebase";
import fp from "lodash/fp";
import moment from "moment-timezone";
import { IStore } from "@snackpass/snackpass-types";
import firebase from "firebase";
import * as ScheduleUtils from "./utils";
import { useTimeSlots, useBatchListener } from "./hooks";
import { Constants } from "../../utils";

type FirebaseDbReference = ReturnType<
    ReturnType<typeof firebase.database>["ref"]
>;

export type UseSchedulingParams = {
    dbRef: FirebaseDbReference | RNFirebase.database.Reference;
    store: IStore | null;
    date: moment.Moment | null;
    isCatering?: boolean;
};

export type UseSchedulingReturn = {
    timeSlots: ScheduleUtils.TimeSlot[];
    hasBatching: boolean;
    isSlotAvailable: ReturnType<typeof ScheduleUtils.isSlotAvailable>;
    getBatchForSlot: ReturnType<typeof ScheduleUtils.getBatchForSlot>;
    isBatchFull: ReturnType<typeof ScheduleUtils.isBatchFull>;
};

/**
 * This is a hook that can easily be plugged in to any React component and provides
 * a set of helper methods for scheduled orders. It returns a few useful things...
 *
 * 1) time slots where schedule ahead is available
 * 2) helper methods to determine if a slot is full / available
 * 3) helper methods to determine how many spots in a time slot are available if the store has batching
 *
 * @param {dbRef: Firebase DB, store: IStore | null, date: Date | null}
 */
export function useScheduling({
    dbRef,
    store,
    date,
    isCatering
}: UseSchedulingParams): UseSchedulingReturn {
    const hasBatching = fp.getOr(false, "hasBatching", store);
    const batchSize = fp.getOr(
        Constants.DEFAULT_BATCH_SIZE,
        "scheduleAheadBatchSize",
        store
    );
    const catering = isCatering ?? false;
    const timeSlots = useTimeSlots({
        store,
        date,
        catering
    });
    const { batchMapping } = useBatchListener({
        dbRef,
        timeSlots,
        store,
        date,
        hasBatching
    });

    const getBatchForSlot = ScheduleUtils.getBatchForSlot(
        batchSize,
        batchMapping,
        timeSlots
    );

    const isBatchFullCurried = hasBatching
        ? ScheduleUtils.isBatchFull(getBatchForSlot)
        : () => false;

    return {
        timeSlots,
        hasBatching,
        isSlotAvailable: ScheduleUtils.isSlotAvailable(
            store,
            isBatchFullCurried
        ),
        getBatchForSlot,
        isBatchFull: isBatchFullCurried
    };
}
