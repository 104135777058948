import React from "react";
import { CartItemFlat, formatQuantity, toDollar } from "@snackpass/accounting";

type AddonsProps = {
    item: CartItemFlat;
    onClick?: () => void;
};

const Addons: React.FC<AddonsProps> = ({ item, onClick }) => {
    if (!item.selectedAddons || item.selectedAddons.length === 0) {
        return null;
    }

    let addons = item.selectedAddons;

    return (
        <div onClick={onClick}>
            <div className="flex flex-col pl-0 self-stretch flex-1">
                {addons.map((addon: any, index) => {
                    return (
                        <div
                            className="flex flex-row mb-1 pl-0 self-stretch flex-1"
                            key={index}
                        >
                            <span className="text-sm text-secondary shrink-3 inline-block">
                                {formatQuantity(addon.quantity)}
                                {addon.name + " "}
                                {addon.totalPriceAfterDiscount === 0
                                    ? ""
                                    : `(+${toDollar(
                                          addon.totalPriceAfterDiscount
                                      )})`}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Addons;
