import React from "react";

import { getActiveChainStores, getGeolocation, hasLocationSet } from "@/redux";
import { Helpers } from "@/utils";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";

const WARNING_THRESHOLD_DISTANCE_IN_MILES = 30;

export const DistanceWarning: React.FC = () => {
    const location = useSelector(getGeolocation);
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
    const activeChainStores = useSelector(getActiveChainStores);
    const locationSet = useSelector(hasLocationSet);

    if (activeChainStores.length === 0) {
        return null;
    }

    const nearestStore = activeChainStores[0];
    const distanceInMiles = Helpers.getDistanceBetweenGeopoints(
        location,
        nearestStore.geolocation,
    );

    if (!locationSet) {
        return null;
    }

    if (distanceInMiles >= WARNING_THRESHOLD_DISTANCE_IN_MILES) {
        return (
            <div
                className={clsx(
                    "flex justify-center w-full mb-[15px] p-4 text-primary border rounded-lg text-center text-gray-500",
                    { "text-sm": isMobile },
                )}
            >
                <span>
                    You seem far away. You are {Math.floor(distanceInMiles)}{" "}
                    miles away from the nearest store.
                </span>
            </div>
        );
    }

    return null;
};
