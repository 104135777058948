import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartSelectors, CartActions } from "@snackpass/accounting";
import { ModalsName, getActiveStore, showModal } from "@/redux";
import { WhenTypeEnum } from "@snackpass/snackpass-types";

export function usePromptScheduleModal() {
    const dispatch = useDispatch();
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const activeStore = useSelector(getActiveStore);
    const scheduledDate = useSelector(CartSelectors.getCartScheduleDate);
    useEffect(() => {
        if (!activeStore) return;
        // Show schedule modal if the store doesn't support pickupAsap in non-catering mode
        // only if scheduled not set in order to prevent duplicate display.
        if (!isCatering && !activeStore?.hasPickupNow && !scheduledDate) {
            dispatch(CartActions.setCartWhen(WhenTypeEnum.Later));
            dispatch(showModal(ModalsName.scheduleModal));
        }
    }, [activeStore?.hasPickupNow, dispatch, isCatering, scheduledDate]);
}
