import React from "react";
import { CartSelectors, itemIsSoldOut } from "@snackpass/accounting";
import { useSelector } from "react-redux";

import cartIconWhite from "src/assets/icons/cartIconWhite.svg";
import { Colors } from "src/utils";
import { isMobile } from "react-device-detect";
import { reduce } from "lodash";

const getCartCountOfThisProduct = (cartItems, product) => {
    var count = reduce(
        cartItems,
        function (count, item) {
            return count + (product._id === item.product._id ? 1 : 0);
        },
        0,
    );
    return count;
};

export const InProductBadges = ({ product }) => {
    const isSoldOut = itemIsSoldOut(product);
    const cartItems = useSelector(CartSelectors.getCartItems);

    const cartCountOfThisProduct = getCartCountOfThisProduct(
        cartItems,
        product,
    );

    if (cartCountOfThisProduct === 0 && !isSoldOut) {
        return null;
    }
    return (
        <div style={styles.badgeStack}>
            {cartCountOfThisProduct > 0 && (
                <div style={{ ...styles.badgeDiv }} className="bg-brandColor">
                    <img
                        src={cartIconWhite}
                        style={styles.cartIconStyle}
                        alt="Cart Icon"
                    />
                    <label style={styles.itemCountLbl}>
                        {cartCountOfThisProduct}
                    </label>
                </div>
            )}
            {isSoldOut && (
                <label
                    style={{
                        ...styles.badgeDiv,
                        backgroundColor: Colors.darkGray,
                    }}
                >
                    Sold Out
                </label>
            )}
        </div>
    );
};

const styles = {
    badgeStack: {
        position: "absolute",
        top: isMobile ? 19 : 12,
        right: 18,
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
    badgeDiv: {
        padding: 9,
        height: 25,
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 5,
        color: Colors.white,
        alignSelf: "flex-end",
        boxShadow: "0 1px 3px 0 rgba(100, 100, 100, 0.3)",
    } as React.CSSProperties,
    itemCountLbl: {
        marginTop: isMobile ? 5 : 2,
    } as React.CSSProperties,
    cartIconStyle: {
        width: 15,
        height: 15,
        marginRight: 5,
    } as React.CSSProperties,
};
