import { getActiveStore } from "src/redux";
import React from "react";
import { ReactComponent as GiftIcon } from "src/assets/icons/giftCard-fill.svg";
import { history } from "src/utils/history";
import { useAutoOpenDGCProduct } from "@/hooks/useAutoOpenDGCProduct";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/redux/utils";

import { useIsDGCEnabled } from "@/hooks/useIsDGCEnabled";

function BuyDigitalGiftCardButton() {
    const activeStore = useAppSelector(getActiveStore);
    const storeSlug = activeStore?.slug ?? activeStore?._id;
    const { isNewDGCExperience, isDGCEnabled } = useIsDGCEnabled();

    const { openDGCModal } = useAutoOpenDGCProduct({
        checkOnInitialLoad: false,
    });
    return (
        <>
            {isDGCEnabled && storeSlug ? (
                <div className="flex h-[96px] md:h-[64px] min-w-[144px] md:w-auto">
                    <Button
                        className="px-0 py-0 w-[100%] h-[100%] bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] transition-shadow duration-500 ease-in-out shadow-default hover:shadow-hover rounded-xl active:shadow-focus border active:scale-95"
                        variant="ghost"
                        onPress={() => {
                            if (isNewDGCExperience) {
                                openDGCModal();
                            } else {
                                if (activeStore?._id) {
                                    history.push(
                                        `/giftcard?storeID=${activeStore._id}`,
                                    );
                                }
                            }
                        }}
                        label={
                            <div className="flex flex-col md:flex-row flex-1 py-3 md:py-0 md:items-center md:justify-between h-[94px] md:h-[64px] rounded-xl pl-3 pr-2 md:pr-4">
                                <div className="basis-1/8 pb-2 md:pb-0">
                                    <GiftIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                                </div>
                                <div className="basis-6/8 w-full px-0 md:px-3">
                                    <div className="flex items-start text-base font-semibold text-primary">
                                        Gift Cards
                                    </div>
                                </div>

                                <div className="basis-1/8 hidden md:block">
                                    <ChevronRightIcon className="h-4 w-4 text-[#999DA3]" />
                                </div>
                            </div>
                        }
                    ></Button>
                </div>
            ) : null}
        </>
    );
}

export default BuyDigitalGiftCardButton;
