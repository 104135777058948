import { Action, createSelector } from "@reduxjs/toolkit";

export enum ModalActionTypes {
    ShowModal = "SHOW_MODAL",
    HideModal = "HIDE_MODAL",
    DestroyModal = "DESTROY_MODAL",

    ShowUpsellProduct = "SHOW_UPSELL_PRODUCT",
}

export enum ModalsName {
    productModal = "productModal",
    scheduleModal = "scheduleModal",
    cartConflictsModal = "cartConflictsModal",
    storeLocationsModal = "storeLocationsModal",
    viewOrderModal = "viewOrderModal",
    searchProductModal = "searchProductModal",
    myCardsModal = "myCardsModal",
    authenticationModal = "authenticationModal",
    storeHoursModal = "storeHoursModal",
    rewardOptionsModal = "rewardOptionsModal",
    claimRewardModal = "claimRewardModal",
    fulfillmentModal = "fulfillmentModal",
    dealModal = "dealModal",
    discountModal = "discountModal",
    dealAndDiscountPreviewModal = "dealAndDiscountPreviewModal",
    removeDealModal = "removeDealModal",
    upsellModal = "upsellModal",
}

export interface ModalAction extends Action {
    type: ModalActionTypes;
    payload: { name: ModalsName };
}

export function showModal(name: ModalsName): ModalAction {
    return {
        type: ModalActionTypes.ShowModal,
        payload: { name },
    };
}

export function hideModal(name: ModalsName): ModalAction {
    return {
        type: ModalActionTypes.HideModal,
        payload: { name },
    };
}

export function destroyModal(name: ModalsName): ModalAction {
    return {
        type: ModalActionTypes.DestroyModal,
        payload: { name },
    };
}

export function showUpsellProduct() {
    return {
        type: ModalActionTypes.ShowUpsellProduct,
    };
}

interface ModalState {
    modals: {
        [modalName in ModalsName]?: boolean;
    };
}

const initialState: ModalState = {
    modals: {},
};

export function modals(state = initialState, action: ModalAction) {
    switch (action.type) {
        case ModalActionTypes.ShowModal:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.payload.name]: true,
                },
            };
        case ModalActionTypes.HideModal:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.payload.name]: false,
                },
            };
        case ModalActionTypes.DestroyModal:
            const { [action.payload.name]: _, ...restModals } = state.modals;
            return {
                ...state,
                modals: restModals,
            };

        case ModalActionTypes.ShowUpsellProduct:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [ModalsName.productModal]: true,
                    [ModalsName.upsellModal]: false,
                },
            };
        default:
            return state;
    }
}

const selectModalsState = (state: { modals: ModalState }) => state.modals;

export const selectIsFulfillmentModalOpen = createSelector(
    [selectModalsState],
    (state) => state.modals.fulfillmentModal ?? false,
);

export const selectIsCartConflictModalOpen = createSelector(
    [selectModalsState],
    (state) => state.modals.cartConflictsModal ?? false,
);

export const getIsProductModalOpen = (state) =>
    state.modals.modals.productModal || false;

export const getIsStoreLocationsModalOpen = (state) =>
    state.modals.modals.storeLocationsModal || false;

export const getIsViewOrderModalOpen = (state) =>
    state.modals.modals.viewOrderModal || false;

export const getIsSearchProductModalOpen = (state) =>
    state.modals.modals.searchProductModal || false;

export const getIsMyCardsModalOpen = (state) =>
    state.modals.modals.myCardsModal || false;

export const getIsAuthenticationModalOpen = (state) =>
    state.modals.modals.authenticationModal || false;

export const getIsStoreHoursModalOpen = (state) =>
    state.modals.modals.storeHoursModal || false;

export const getIsDealModalOpen = (state) =>
    state.modals.modals.dealModal || false;

export const getIsDiscountModalOpen = (state) =>
    state.modals.modals.discountModal || false;

export const getIsRemoveDealModalOpen = (state) =>
    state.modals.modals.removeDealModal || false;

export const getIsUpsellModalOpen = (state) =>
    state.modals.modals.upsellModal || false;

export const getIsScheduleModalOpen = (state) =>
    state.modals.modals.scheduleModal || false;
