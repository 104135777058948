import { Divider } from "@/SharedComponents/Divider";
import { Button } from "@/components/ui/button";
import MarketingConsentTerms from "@/screens/CartScreen/PaymentSection/MarketingConsentTerms";
import { useFetchMarketingConsent } from "@/screens/CartScreen/PaymentSection/useFetchMarketingConsent";
import { IPurchase, IStore } from "@snackpass/snackpass-types";
import { useEffect } from "react";
import { useOptInToMarketing } from "./useOptInToMarketing";
import { BeatLoader } from "react-spinners";

type Props = {
    purchase: IPurchase;
    activeStore: IStore;
    authToken: string;
};

function SmartReceiptMarketingConsent({
    purchase,
    activeStore,
    authToken,
}: Props) {
    const { fetchMarketingConsent, data } = useFetchMarketingConsent();
    useEffect(() => {
        fetchMarketingConsent(activeStore._id, purchase._id, authToken);
    }, [fetchMarketingConsent, activeStore._id, purchase._id, authToken]);

    const {
        optInToMarketing,
        data: mutationData,
        isLoading,
    } = useOptInToMarketing();

    // Hide this screen if the user is already subscribed. We don't
    // allow a user to unsubscribe, as reminding them this exists could prompt them to unsubscribe unnecessarily.
    // Also hide if the marketing consent data hasn't loaded yet or has errored. This could also be the skipped phone user
    if (data?.marketingConsent || data == null) {
        return;
    }

    return (
        <div>
            <Divider width={8} />
            <div className="flex flex-col text-stone-950 px-8 py-4 font-medium text-base gap-2">
                <div className="flex flex-row gap-2 items-center">
                    {isLoading ? (
                        <BeatLoader />
                    ) : (
                        <Button
                            label={mutationData ? "Joined" : "Join"}
                            variant="brand"
                            disabled={mutationData}
                            onPress={() =>
                                optInToMarketing(
                                    activeStore._id,
                                    purchase._id,
                                    authToken,
                                )
                            }
                        />
                    )}
                    <div>
                        Receive marketing texts about promotions, rewards, and
                        more.
                    </div>
                </div>
                <MarketingConsentTerms />
            </div>
        </div>
    );
}

export default SmartReceiptMarketingConsent;
