import React, { useEffect } from "react";
import ScheduleModal from "@/modals/ScheduleModal";
import ProductModal from "@/modals/ProductModal";
import { MapMarker } from "@/SharedComponents";
import {
    CartActions,
    CartSelectors,
    getActiveStore,
} from "@snackpass/accounting";
import { IStore, WhenTypeEnum } from "@snackpass/snackpass-types";
import GoogleMapReact from "google-map-react";
import fp from "lodash/fp";
import { useSelector } from "react-redux";
import { match, Redirect, RouteProps, useHistory } from "react-router-dom";
import {
    getGeolocation,
    hasLocationSet,
    setStoreFulfillmentOptions,
} from "src/redux";
import { fetchChainThunk } from "src/redux/activeChain";
import {
    FetchStoresForChainQuery,
    fetchStoresForChainThunk,
} from "src/redux/activeChainStores";
import Content from "./Content";
import Header from "./Header";
import { useFetchActiveStore } from "#activeStore/hooks/useFetchActiveStore";
import { useLoyalty } from "@/hooks/useLoyalty";
import { useLoadPunchCards } from "@/hooks/useLoadPunchCards";
import ClaimRewardModal from "@/modals/ClaimRewardModal";
import { useLegacyLoadRewards } from "@/hooks/useLegacyLoadRewards";
import { PoweredByBlack } from "@/components/PoweredBy";
import FulfillmentModal from "@/modals/FulfillmentModal";
import { StoreUtils } from "@/utils";
import _ from "lodash";
import RemoveDealModal from "@/modals/RemoveDealModal";
import { usePromotions } from "@/hooks/usePromotions";
import UpsellModal from "@/modals/ProductUpsellModal";
import { useAppDispatch, useAppSelector } from "@/redux/utils";
import ChooseLocationModal from "@/modals/ChooseLocationModal";

type Props = {
    match: match<{ storeNameAndId: string }>;
    location: RouteProps["location"];
};

const CartScreen = ({ match, location }: Props) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const activeStore = useAppSelector(getActiveStore);
    const cartItemsUnflattened = useAppSelector(
        CartSelectors.getCartItemsUnflattened,
    );
    const fulfillment = useAppSelector(CartSelectors.getCartFulfillment);
    const isCatering = useAppSelector(CartSelectors.getCartIsCatering);
    const items = useAppSelector(CartSelectors.getCartItemsGrouped);
    const when = useAppSelector(CartSelectors.getCartWhen);
    const fetchChain = (chainId: string) => dispatch(fetchChainThunk(chainId));
    const fetchStoresForChain = (query: FetchStoresForChainQuery) =>
        dispatch(fetchStoresForChainThunk(query));
    const geolocation = useAppSelector(getGeolocation);
    const hasLocation = useAppSelector(hasLocationSet);
    const { fetchStore } = useFetchActiveStore();

    useLoyalty();
    usePromotions();
    useLoadPunchCards();
    useLegacyLoadRewards();

    const fetchChainForStore = async (store: IStore | null) => {
        if (!store || !store.chainId) {
            return;
        }

        // XXX: `fetchChain` is dispatching a thunk.
        // This action is meant to be async (not something we wait for).
        // We should instead be _reacting_ to the chain field being set in the store.
        const chain = await fetchChain(store.chainId);
        if (!chain) {
            return;
        }

        const coordinates = geolocation.coordinates;

        fetchStoresForChain({
            chainId: store.chainId,
            ...(hasLocation && {
                lat: coordinates[1],
                lng: coordinates[0],
            }),
            inferLocation: true,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        async function fetch() {
            fetchChainForStore(activeStore);
        }

        fetch();
    }, [activeStore?._id]);

    useEffect(() => {
        if (
            when === WhenTypeEnum.Later &&
            activeStore?.hasOwnProperty("hasScheduledOrders") &&
            !activeStore?.hasScheduledOrders
        ) {
            dispatch(CartActions.setCartWhen(WhenTypeEnum.Now));
        }
    }, [when, activeStore?.hasScheduledOrders]);

    useEffect(() => {
        fetchStore(match.params.storeNameAndId);
    }, [fetchStore, match.params.storeNameAndId]);

    useEffect(() => {
        if (!activeStore) {
            return;
        }

        dispatch(CartActions.setCartItems(cartItemsUnflattened, activeStore));
    }, [activeStore?._id]);

    useEffect(() => {
        if (activeStore) {
            const fulfillmentOptions = StoreUtils.getStoreFulfillmentOptions(
                activeStore,
                isCatering,
            );
            dispatch(setStoreFulfillmentOptions(fulfillmentOptions));
            // change fulfillment when new selected store doesn't support the previously selected fulfillment
            if (
                !_.isEmpty(fulfillmentOptions) &&
                !fulfillmentOptions.includes(fulfillment)
            ) {
                dispatch(CartActions.setCartFulfillment(fulfillmentOptions[0]));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStore?._id, fulfillment, isCatering]);

    useEffect(() => {
        dispatch(CartActions.setCartFulfillment(fulfillment));
    }, []);

    const hasInvalidProducts = !!fp.find(
        (item) =>
            activeStore?._id !== fp.prop("product.store._id")(item.items[0]),
        items,
    );

    if (!activeStore?._id) {
        return null;
    }

    // Redirect if there are no items in the cart or if the items in the cart are from a different store.
    // NB: We ignore the redirect in the case that the current location differs from this location.
    // This is necessary do to race conditions when the cart is cleared and the user is navigated to the smart receipt screen.
    if (
        (items.length === 0 || hasInvalidProducts) &&
        history.location.pathname === location.pathname
    ) {
        return <Redirect to={`/${activeStore?.slug}`} />;
    }

    return (
        <div className="hidden-scroll h-[100%] w-[100%]">
            <div className="hidden-scroll relative h-[100%] w-[100%]">
                <div className="fixed left-0 right-0 top-0 z-[3] flex w-[100%] justify-center overflow-x-hidden border-b bg-white">
                    <Header />
                </div>
                <div className="mx-auto max-w-[1280px] lg:mt-[96px]">
                    <Content />
                </div>
            </div>
            <ProductModal />
            <ScheduleModal />
            <FulfillmentModal />
            <ChooseLocationModal />
            <ClaimRewardModal />
            <RemoveDealModal />
            <UpsellModal />
            <div className="flex justify-center pt-[20px] text-center">
                <PoweredByBlack />
            </div>
        </div>
    );
};

export const GoogleMap = () => {
    const activeStore = useSelector(getActiveStore);

    if (!activeStore) {
        return null;
    }

    return (
        <div className="mb-[30px] mt-0 h-[150px] w-[100%] overflow-hidden shadow-md md:mt-[20px] md:h-[300px]">
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY ?? "",
                }}
                defaultCenter={{
                    lat: activeStore.geolocation.coordinates[1],
                    lng: activeStore.geolocation.coordinates[0],
                }}
                options={{
                    fullscreenControl: false,
                    zoomControl: true,
                    scrollwheel: false,
                }}
                defaultZoom={16}
            >
                <MapMarker
                    lat={activeStore.geolocation.coordinates[1]}
                    lng={activeStore.geolocation.coordinates[0]}
                    store={activeStore}
                    key={activeStore.name}
                />
            </GoogleMapReact>
        </div>
    );
};

export default CartScreen;
