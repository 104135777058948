import _ from "lodash";
import {
    IStore,
    IPromotion,
    FulfillmentTypeEnum,
    Fulfillment,
    IReward,
} from "@snackpass/snackpass-types";
import fp from "lodash/fp";
import { CartAddon } from "@snackpass/accounting";
import { calculateAddonPrice } from "@snackpass/accounting/build/src/accountingFormulas/formulas";

/**
 * This utility contains Helpers that deal with rewards,
 * promotions, applying discounts, and getting a user's points and credit at a store
 */
export class StoreUtils {
    static parseStoreIdFromUrl(storeNameAndId: string): string {
        let storeParams = storeNameAndId.split("-");
        let lastIndex = _.findLastIndex(storeParams);
        let storeId = storeParams[lastIndex];
        return storeId;
    }

    static getStoreRoute(store: IStore): string {
        return `/${store.slug}`;
    }

    static getStoreFulfillmentOptions = (
        store: IStore,
        isCateringPurchase?: boolean,
    ) => {
        const hasDelivery = isCateringPurchase
            ? store?.catering?.allowDelivery
            : store?.delivery;
        const hasPickup: boolean = fp.propOr(false, "pickup", store);
        const hasDineIn = isCateringPurchase
            ? false
            : store?.dineInPreferences?.hasDineIn;

        return [
            ...(hasPickup ? [FulfillmentTypeEnum.Pickup] : []),
            ...(hasDineIn ? [FulfillmentTypeEnum.DineIn] : []),
            ...(hasDelivery ? [FulfillmentTypeEnum.Delivery] : []),
        ] as Fulfillment[];
    };

    static calculateItemTotal = (
        activePromotion: IPromotion | null | undefined,
        activeReward: IReward | null | undefined,
        itemPrice: number,
        itemPriceAfterDiscount: number,
        selectedAddons: CartAddon[] | null,
        quantity: number,
        weight: number,
        isDiscounted: boolean,
    ): { total: number; totalAfterDiscount: number } => {
        let total = 0;
        let totalAfterDiscount = 0;
        const addOnTotal = selectedAddons
            ? _.sum(
                  selectedAddons.map(
                      (item) => item.addon.price * item.quantity,
                  ),
              )
            : 0;

        total = weight
            ? (itemPrice + addOnTotal) * weight
            : (itemPrice + addOnTotal) * quantity;

        if (isDiscounted) {
            const addOnTotalAfterDiscount = _.sum(
                selectedAddons?.map(
                    (addon, _n) =>
                        calculateAddonPrice(
                            addon.addon,
                            activePromotion || activeReward,
                        ) * addon.quantity,
                ),
            );
            totalAfterDiscount = weight
                ? (itemPriceAfterDiscount + addOnTotalAfterDiscount) * weight
                : (itemPriceAfterDiscount + addOnTotalAfterDiscount) * quantity;
        }

        return { total, totalAfterDiscount };
    };
}
