import React, { useEffect, useState, useCallback } from "react";
import { supabase } from "@/utils/Supabase";
import LoadingScreen from "../LoadingScreen";
import DynamicLinkRegister from "./DynamicLinkResolver";

export const DynamicLinkPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showSearchContent, setShowSearchContent] = useState(false);

    const findLink = useCallback(async () => {
        // redirect to the store online ordering page if current url(from QR code) is linked with a store
        // otherwise ask user to login and show store search bar to register current link
        setLoading(true);
        const urlParts = window.location.pathname.split("/");
        const linkId = urlParts[2];
        const { data } = await supabase
            .from("links")
            .select("store_id, custom_url")
            .eq("link_id", linkId);

        if (data?.length) {
            const foundLink = data[0];
            if (foundLink.custom_url) {
                window.location.href = foundLink.custom_url;
                return;
            }
            const storeId = foundLink.store_id;
            window.location.href = `https://order.snackpass.co/${storeId}`;
        } else {
            setLoading(false);
            setShowSearchContent(true);
        }
    }, []);

    useEffect(() => {
        findLink();
    }, [findLink]);

    if (loading) {
        return <LoadingScreen />;
    }

    if (showSearchContent) {
        return <DynamicLinkRegister />;
    }
};
