/* Actions */
export const SET_REVEAL_TOTAL_BY_DEFAULT = "SET_REVEAL_TOTAL_BY_DEFAULT";
export type SET_REVEAL_TOTAL_BY_DEFAULT = typeof SET_REVEAL_TOTAL_BY_DEFAULT;

export type SetRevealTotalByDefault = ReturnType<
    typeof setRevealTotalByDefault
>;
export const setRevealTotalByDefault = (revealTotalByDefault: boolean) =>
    ({
        type: SET_REVEAL_TOTAL_BY_DEFAULT,
        revealTotalByDefault,
    }) as const;

/* Reducer */
export interface CartState {
    revealTotalByDefault: boolean;
}
export const CartInitialState: CartState = {
    revealTotalByDefault: false,
};

export default function ooCart(
    state = CartInitialState,
    action: SetRevealTotalByDefault,
): CartState {
    switch (action.type) {
        case SET_REVEAL_TOTAL_BY_DEFAULT:
            return {
                ...state,
                revealTotalByDefault: action.revealTotalByDefault,
            };
        default:
            return state;
    }
}

/* Selectors */
export const getIsTotalRevealedByDefault = (state: any) =>
    state.ooCart.revealTotalByDefault;
