import React, { useMemo } from "react";
import _ from "lodash";
import { getIsGiftCardProduct } from "@snackpass/accounting";
import {
    useActiveRewardPromo,
    useActiveReward,
} from "@snackpass/loyalty-react";

import { CategoryProduct } from "@/utils/Types";
import { useCategories } from "@/redux/useCategory";
import { useFilterForCompatibleProducts } from "./useFilterForCompatibleProducts";

/**
 * Returns an array of CategoryProducts that are compatible with the active reward option(i.e. reward promotion)
 */
export const useActiveRewardCategoryProducts = (): {
    availableCategoryProducts: CategoryProduct[];
    noProductsAvailable: boolean;
} => {
    const activeRewardPromo = useActiveRewardPromo();
    const activeReward = useActiveReward();

    const storeCategories = useCategories();
    const filterForCompatibleProducts = useFilterForCompatibleProducts();
    return useMemo(() => {
        // Short circuit if no active reward or store
        if (!activeRewardPromo && !activeReward) {
            return { availableCategoryProducts: [], noProductsAvailable: true };
        }

        const isStoreWide = activeRewardPromo
            ? activeRewardPromo?.storewide
            : activeReward?.storewide;
        const productIds = activeRewardPromo
            ? activeRewardPromo?.productIds
            : activeReward
              ? activeReward?.productIds
              : [];
        const categories = activeRewardPromo
            ? activeRewardPromo?.categories
            : activeReward?.categories;
        let availableCategoryProducts: CategoryProduct[] = [];
        if (isStoreWide) {
            availableCategoryProducts = storeCategories.reduce<
                CategoryProduct[]
            >((acc, c) => {
                const products = filterForCompatibleProducts(
                    c.productIds,
                ).filter((product) => getIsGiftCardProduct(product) === false);
                if (products.length > 0) {
                    acc.push({
                        category: c.name,
                        products,
                    });
                }
                return acc;
            }, []);
            return {
                availableCategoryProducts,
                noProductsAvailable: _.isEmpty(availableCategoryProducts),
            };
        }
        /**
         * Filters all productIds and then maps them to their respective categories
         */
        const rewardProductCategories = filterForCompatibleProducts(
            productIds,
        ).reduce<CategoryProduct[]>((acc, p) => {
            if (p.category) {
                const existingCat = acc.find(
                    (cp) => cp.category === p.category,
                );
                if (existingCat) {
                    existingCat.products = existingCat.products.concat(p);
                } else {
                    acc = acc.concat({
                        category: p.category,
                        products: [p],
                    });
                }
            }
            return acc;
        }, []);

        /**
         * Maps a rewards's categories to their respective products
         */
        availableCategoryProducts = rewardProductCategories.concat(
            categories?.reduce<CategoryProduct[]>((acc, catId) => {
                const category = storeCategories.find((c) => c._id === catId);
                if (category) {
                    const products = filterForCompatibleProducts(
                        category.productIds,
                    );
                    // Check that there is at least 1 eligible product
                    if (products.length > 0) {
                        acc = acc.concat({
                            category: category.name,
                            products,
                        });
                    }
                }
                return acc;
            }, []) || [],
        );
        return {
            availableCategoryProducts,
            noProductsAvailable: _.isEmpty(availableCategoryProducts),
        };
    }, [
        activeRewardPromo,
        activeReward,
        filterForCompatibleProducts,
        storeCategories,
    ]);
};
