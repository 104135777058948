import { MenuService, legacyMenuClient } from "@snackpass/menus-sdk";

import config from "@/config";
import axios from "@/utils/Api/axios";

export type StoreMenuService = ReturnType<typeof menuService>;

export const menuService = MenuService(
    legacyMenuClient(axios, {
        channel: "@snackpass/online-ordering",
        host: config.baseHost,
    }),
);
