import { Time } from "@snackpass/accounting";
import { IStore } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import React from "react";
import { useSelector } from "react-redux";

import { getGeolocation, hasLocationSet } from "src/redux";
import { Colors } from "src/utils";
import * as MapUtils from "src/SharedComponents/Map/MapUtils";

type Props = {
    store: IStore;
};

export const StoreLabelRow = ({ store }: Props) => {
    const location = useSelector(getGeolocation);

    const hasLocation = useSelector(hasLocationSet);
    const distance = hasLocation
        ? `${MapUtils.getFormattedDistance(location, store.geolocation)} • `
        : "";

    const hasCatering = store.cateringEnabled;
    const hasScheduleAhead = store.hasScheduledOrders;
    const hasDelivery = store.delivery;

    return (
        <span
            style={{
                color: Colors.darkGray,
                fontSize: 12,
            }}
        >
            {distance} <OpenLabel store={store} /> {hasCatering && `• Catering`}{" "}
            {hasDelivery && `• Delivery`}{" "}
            {hasScheduleAhead && `• Schedule Ahead`}{" "}
        </span>
    );
};

const OpenLabel = ({ store }: { store: IStore }) => {
    if (store.closedUntil && Time.storeIsClosedUntil(store)) {
        return (
            <span>
                <span
                    style={{
                        color: Colors.red,
                    }}
                >
                    Closed until {moment(store.closedUntil).format("h:mma")}
                </span>
            </span>
        );
    }

    if (!store.isOpen) {
        return (
            <span>
                <span
                    style={{
                        color: Colors.red,
                    }}
                >
                    Closed
                </span>
            </span>
        );
    }

    return (
        <span
            style={{
                color: Colors.green,
            }}
        >
            Open
        </span>
    );
};
