import { Api, Helpers } from "src/utils";
import { firebaseAuth } from "../../firebase";
import { Segment, SegmentEventNames } from "src/utils/Segment";
import { signInWithCustomToken } from "firebase/auth";

export async function onSubmitPhoneNumber(number: string) {
    await Api.auth.sendVerification(number);

    Segment.track(SegmentEventNames.PHONE_NUMBER_SUBMITTED, {
        number,
    });
}

export async function onSubmitVerificationCode(number: string, code: string) {
    const res = await Api.auth.verify(number, code);
    const { customAuthToken, user, existingUser } = res.data;

    Segment.track(SegmentEventNames.PHONE_NUMBER_VERIFIED, {
        number,
    });
    if (!existingUser) {
        const query = new URLSearchParams(window.location.search);
        const utm_source = query.get("utm_source") || null;
        Segment.track(SegmentEventNames.SIGNED_UP, {
            school: Helpers.getSchool(user?.email),
            is_online_ordering: true,
            utm_source,
        });
    }

    await signInWithCustomToken(firebaseAuth, customAuthToken);
    localStorage?.setItem("authToken", JSON.stringify(customAuthToken));
    return user;
}
