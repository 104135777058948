import React, { Component, CSSProperties } from "react";
import { Colors } from "src/utils";

type TextProps = {
    className?: string;
    xxlarge?: boolean;
    xlarge?: boolean;
    large?: boolean;
    small?: boolean;
    xsmall?: boolean;
    center?: boolean;
    bold?: boolean;
    color?: string;
    underline?: boolean;
    lineThrough?: boolean;
    onPress?: (e: any) => void;
    style?: CSSProperties;
};

export class Text extends Component<React.PropsWithChildren<TextProps>> {
    render() {
        const {
            xxlarge,
            xlarge,
            large,
            small,
            xsmall,
            center,
            bold,
            color,
            underline,
            lineThrough,
        } = this.props;

        let fontSize = 16;
        let textAlign: any = "left";
        let fontWeight: any = "normal";
        let fontStyle: any = "none";

        if (xlarge) {
            fontSize = 30;
        }
        if (xxlarge) {
            fontSize = 36;
        }
        if (large) {
            fontSize = 18;
        }
        if (small) {
            fontSize = 14;
        }
        if (xsmall) {
            fontSize = 12;
        }
        if (center) {
            textAlign = "center";
        }
        if (bold) {
            fontWeight = "bold";
        }
        if (underline) {
            fontStyle = "underline";
        }
        if (lineThrough) {
            fontStyle = "line-through";
        }
        return (
            <span
                onClick={this.props.onPress}
                style={{
                    color: color || Colors.text,
                    fontSize: fontSize,
                    textAlign: textAlign,

                    fontWeight: fontWeight,
                    textDecoration: fontStyle,
                    display: "inline-block",
                    ...this.props.style,
                }}
                className={this.props.className}
            >
                {this.props.children}
            </span>
        );
    }
}
