// src/cloudinary.ts
import { Cloudinary } from "@cloudinary/url-gen";
import { format, dpr as DPR } from "@cloudinary/url-gen/actions/delivery";
import {
  limitFill,
  limitFit,
  limitPad,
  scale
} from "@cloudinary/url-gen/actions/resize";
import { auto as autoFormat } from "@cloudinary/url-gen/qualifiers/format";
import {
  autoBest,
  autoEco,
  autoGood,
  autoLow,
  auto as autoQuality
} from "@cloudinary/url-gen/qualifiers/quality";

// src/config.ts
var config_default = {
  cloudinaryCloudName: "ddhetmqtt",
  overlayImagePublicId: "v1712362600:tap-to-order_ijl7bh.png",
  cloudinaryVideoUrlPrefix: "https://res.cloudinary.com/ddhetmqtt/video/upload",
  cloudinaryImageUrlPrefix: "https://res.cloudinary.com/ddhetmqtt/image/upload"
};

// src/cloudinary.ts
var cloudinary = new Cloudinary({
  cloud: {
    cloudName: config_default.cloudinaryCloudName
  },
  url: {
    secure: true
    // force https, set to false to force http
  }
});
var folderMap = {
  "https://res.cloudinary.com/ddhetmqtt/image/upload/store-images": "store-images",
  "https://d2q8km48t273sa.cloudfront.net": "checkmate-cf",
  "https://image-cdn.chowlyinc.com": "chowly-cdn",
  "https://d1ralsognjng37.cloudfront.net": "chowly-cf",
  "http://media-cdn.grubhub.com/image/upload": "grubhub-cdn",
  // Cloudinary doesn't like the query params in these Firebase URLs
  // "https://firebasestorage.googleapis.com/v0/b/futura-c7f12.appspot.com": "firebase",
  "https://storage.googleapis.com/css-photos/menu-photos": "menus-gcs",
  "https://menu-pictures.s3.amazonaws.com": "menus-s3",
  "https://s3.amazonaws.com/toasttab": "toast-s3"
};
var mapModeToResize = (m) => {
  switch (m) {
    case "scale":
      return scale();
    case "pad":
      return limitPad();
    case "fit":
      return limitFit();
    case "fill":
    default:
      return limitFill();
  }
};
var qualityMap = (q) => {
  switch (q) {
    case "best":
      return autoBest();
    case "high":
      return autoGood();
    case "medium":
      return autoEco();
    case "low":
      return autoLow();
    default:
      return autoQuality();
  }
};
var formatMap = (f) => {
  switch (f) {
    case "original":
      return void 0;
    default:
      return format(autoFormat());
  }
};
var optimizedUrl = (originUrl, options) => {
  if (!originUrl)
    return originUrl;
  let publicId = "";
  const { format: format2, quality, dpr, size } = options ?? {
    quality: "auto",
    format: "auto"
  };
  const isCloudinaryImage = originUrl.startsWith(
    config_default.cloudinaryImageUrlPrefix
  );
  if (isCloudinaryImage) {
    originUrl = originUrl.replace(/\/v\d{10,}\//, "/");
  }
  for (const key in folderMap) {
    if (originUrl.startsWith(key)) {
      const folderName = folderMap[key];
      publicId = originUrl.replace(key, folderName);
      break;
    }
  }
  if (publicId === "") {
    if (isCloudinaryImage) {
      publicId = originUrl.split(config_default.cloudinaryImageUrlPrefix)[1];
      if (publicId.startsWith("/")) {
        publicId = publicId.substring(1);
      }
    } else {
      return originUrl;
    }
  }
  let image2 = cloudinary.image(publicId);
  if (size) {
    const { w, h } = size;
    let resize = mapModeToResize(options?.mode);
    if (w)
      resize = resize.width(w);
    if (h)
      resize = resize.height(h);
    image2 = image2.resize(resize);
    if (dpr && dpr !== "auto") {
      image2 = image2.delivery(DPR(dpr));
    } else {
      const defaultDPR = 2;
      const autoDPR = typeof window !== "undefined" && typeof window.devicePixelRatio === "number" && window.devicePixelRatio || defaultDPR;
      image2 = image2.delivery(DPR(`${autoDPR}`));
    }
  }
  image2 = image2.quality(qualityMap(quality));
  const inFormat = formatMap(format2);
  if (inFormat)
    image2 = image2.delivery(inFormat);
  return image2.toURL();
};

// src/presets.ts
var ProductImage175x150 = {
  size: {
    h: 175,
    w: 150
  },
  quality: "medium"
};
var Presets = {
  ProductImage175x150
};

// src/text-overlay.ts
import { Cloudinary as Cloudinary2, Transformation } from "@cloudinary/url-gen";
import { source } from "@cloudinary/url-gen/actions/overlay";
import { scale as scale2 } from "@cloudinary/url-gen/actions/resize";
import { compass } from "@cloudinary/url-gen/qualifiers/gravity";
import { Position } from "@cloudinary/url-gen/qualifiers/position";
import { image } from "@cloudinary/url-gen/qualifiers/source";
var cloudinary2 = new Cloudinary2({
  cloud: {
    cloudName: config_default.cloudinaryCloudName
  },
  url: {
    secure: true
    // force https, set to false to force http
  }
});
var generateTextOverlayVideoUrl = (originUrl, addTextOverlay) => {
  let publicId = "";
  const isCloudinaryURL = originUrl.startsWith(
    config_default.cloudinaryVideoUrlPrefix
  );
  if (!isCloudinaryURL)
    return originUrl;
  publicId = originUrl.split(config_default.cloudinaryVideoUrlPrefix)[1];
  if (publicId.startsWith("/")) {
    publicId = publicId.substring(1);
  }
  const video = cloudinary2.video(publicId);
  if (addTextOverlay) {
    video.overlay(
      source(
        image(config_default.overlayImagePublicId).transformation(
          new Transformation().resize(scale2(0.65))
        )
      ).position(
        new Position().gravity(compass("south")).offsetY(0.15).offsetX(0)
      )
    );
  }
  return video.toURL();
};
export {
  Presets,
  generateTextOverlayVideoUrl,
  optimizedUrl
};
