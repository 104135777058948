import {
    Addon,
    IProduct,
    IPromotion,
    IReward,
    IDealItem,
    DigitalGiftCardCartInfo
} from "@snackpass/snackpass-types";

// Action Types
export const SELECT_ADDON = "SELECT_ADDON";
type SELECT_ADDON = typeof SELECT_ADDON;
export const SELECT_INCREMENT_ADDON = "SELECT_INCREMENT_ADDON";
type SELECT_INCREMENT_ADDON = typeof SELECT_INCREMENT_ADDON;
export const SELECT_DECREMENT_ADDON = "SELECT_DECREMENT_ADDON";
type SELECT_DECREMENT_ADDON = typeof SELECT_DECREMENT_ADDON;
export const SELECT_ADDON_QUANTITY = "SELECT_ADDON_QUANTITY";
type SELECT_ADDON_QUANTITY = typeof SELECT_ADDON_QUANTITY;
export const CLEAR_ACTIVE_CART_ITEM = "CLEAR_ACTIVE_CART_ITEM";
type CLEAR_ACTIVE_CART_ITEM = typeof CLEAR_ACTIVE_CART_ITEM;
export const SET_CART_ITEM = "SET_CART_ITEM";
type SET_CART_ITEM = typeof SET_CART_ITEM;
export const SET_NOTES = "SET_NOTES";
type SET_NOTES = typeof SET_NOTES;

// Actions
export type SelectAddon = {
    type: SELECT_ADDON;
    addon: Addon;
};
export const selectAddon = (addon: Addon): SelectAddon => ({
    type: SELECT_ADDON,
    addon
});

export type SelectIncrementAddon = {
    type: SELECT_INCREMENT_ADDON;
    addon: Addon;
};
export const selectIncrementAddon = (addon: Addon): SelectIncrementAddon => ({
    type: SELECT_INCREMENT_ADDON,
    addon
});

export type SelectDecrementAddon = {
    type: SELECT_DECREMENT_ADDON;
    addon: Addon;
};
export const selectDecrementAddon = (addon: Addon): SelectDecrementAddon => ({
    type: SELECT_DECREMENT_ADDON,
    addon
});

export type SelectAddonQuantity = {
    type: SELECT_ADDON_QUANTITY;
    addon: Addon;
    quantity: number;
};

export const selectAddonQuantity = (
    addon: Addon,
    quantity: number
): SelectAddonQuantity => ({
    type: SELECT_ADDON_QUANTITY,
    addon,
    quantity
});

export type SetActiveCartItem = {
    type: SET_CART_ITEM;
    product: IProduct;
    promotion?: IPromotion | null;
    reward?: IReward | null;
    dealItem?: IDealItem | null;
    pointsAfterPromotion?: number;
    digitalGiftCardInfo?: DigitalGiftCardCartInfo;
};

export const setActiveCartItem = (
    product: IProduct,
    promotion?: IPromotion,
    reward?: IReward,
    dealItem?: IDealItem,
    pointsAfterPromotion?: number,
    digitalGiftCardInfo?: DigitalGiftCardCartInfo
): SetActiveCartItem => {
    return {
        type: SET_CART_ITEM,
        product,
        promotion,
        reward,
        dealItem,
        pointsAfterPromotion,
        digitalGiftCardInfo
    };
};

export type ClearActiveCartItem = {
    type: CLEAR_ACTIVE_CART_ITEM;
};
3;
export const clearActiveCartItem = (): ClearActiveCartItem => ({
    type: CLEAR_ACTIVE_CART_ITEM
});

export type SetActiveCartItemNotes = {
    type: SET_NOTES;
    notes: string;
};
export const setActiveCartItemNotes = (
    notes: string
): SetActiveCartItemNotes => {
    return { type: SET_NOTES, notes };
};

export type ActiveCartItemActions =
    | SelectAddon
    | SelectIncrementAddon
    | SelectDecrementAddon
    | SelectAddonQuantity
    | SetActiveCartItem
    | ClearActiveCartItem
    | SetActiveCartItemNotes;
