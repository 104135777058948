import React from "react";
import { clsx } from "clsx";
import { cn } from "@/utils";

interface Props {
    name: string;
    options: { value: string; label: string }[];
    onValueChange: (value: string) => void;
    value: string;
    small?: boolean;
    labelClassName?: string;
}

const ToggleSlider = ({
    name,
    options,
    onValueChange,
    value,
    labelClassName,
}: Props) => {
    const selectedIndex = options.findIndex((option) => option.value === value);
    return (
        <div className={cn("rounded-lg relative flex-1")}>
            {selectedIndex !== -1 ? (
                <div
                    className="p-[1px] h-full absolute duration-300 transition-all"
                    style={{
                        width: `${100 / options.length}%`,
                        translate: `${selectedIndex * 100}%`,
                    }}
                >
                    <div
                        className={cn(
                            "rounded-md relative h-full shadow-sm bg-white",
                        )}
                    />
                </div>
            ) : null}
            <div className="flex flex-row gap-[1px] relative py-1 rounded-md">
                {options.map((option) => {
                    const selected = value === option.value;
                    const toggleId = `${name}_${
                        option.value
                    } + ${Date.now().toString()}`;
                    return (
                        <label
                            key={toggleId}
                            htmlFor={toggleId}
                            className={clsx(
                                labelClassName,
                                "rounded-md",
                                "overflow-hidden",
                                "transition-all",
                                "duration-300",
                                "flex",
                                "flex-1",
                                "justify-center",
                                "relative",
                                "cursor-pointer",
                                "py-1",
                                "select-none",
                            )}
                        >
                            <span
                                className={cn("text-sm", {
                                    "text-primary font-medium": selected,
                                    "text-secondary": !selected,
                                })}
                            >
                                {option.label}
                            </span>

                            <input
                                type="radio"
                                name={name}
                                id={toggleId}
                                value={option.value}
                                className={cn("hidden", {
                                    "shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]":
                                        selected,
                                })}
                                onChange={(e) => onValueChange(e.target.value)}
                            />
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default ToggleSlider;
