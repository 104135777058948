import { CSSProperties } from "react";
import React from "react";
import { Divider } from "src/SharedComponents";
import { GiftCardStateType } from "../lib";

type GiftCardDataDisplayProps = {
    giftCardState: GiftCardStateType;
};

const scheduleDelivery = "Delivers Today";

export const GiftCardDataDisplay = ({
    giftCardState,
}: GiftCardDataDisplayProps) => {
    return (
        <div className="flex flex-col mx-12 overflow-hidden justify-center items-start text-center rounded-lg w-[100%]">
            <div className="flex flex-col items-start">
                <div className="flex flex-col">
                    <div className="text-normal font-semibold text-base text-start w-[100%] text-primary">
                        {giftCardState.toName}
                    </div>
                </div>
                <div className="flex flex-col my-2">
                    <div className="text-base text-secondary w-[100%]">
                        {giftCardState.toPhone}
                    </div>
                </div>
                {giftCardState.note ? (
                    <div className="flex flex-col mb-4">
                        <Divider className="mb-4" />
                        <div className="text-base text-secondary w-[100%] text-start whitespace-pre-line break-words">
                            {giftCardState.note}
                        </div>
                    </div>
                ) : null}
            </div>
            <Divider className="mb-4" />
            <div className="flex flex-row">
                <div className="flex flex-col">
                    <div className="text0=-base text-secondary w-[100%] text-start whitespace-pre-line break-words">
                        {scheduleDelivery}
                    </div>
                </div>
            </div>
        </div>
    );
};
