import { Addon, AddonGroup } from "@snackpass/snackpass-types";

import { CartAddon, ActiveCartItemReducer } from "../types";

import {
    itemIsSoldOut,
    addonGroupLimitIsReached,
    getAddonIndexById,
    getNumSelectedAddonsInAddonGroup
} from "../utils";

export type SelectAddonResponse = [string, null | CartAddon[]];

enum SelectType {
    Toggle,
    Increment,
    Decrement,
    Quantity
}

// Used to select/deselect addons in addon groups
// where supportsMultiple property is false
export function selectAddon(
    tryAddon: Addon,
    cartItem: ActiveCartItemReducer
): SelectAddonResponse {
    return selectGeneral({
        tryAddon: tryAddon,
        quantity: 0,
        cartItem: cartItem,
        selectType: SelectType.Toggle
    });
}

// Used to increment addon quantity in addon groups
// where supportsMultiple property is true
export function selectIncrementAddon(
    tryAddon: Addon,
    cartItem: ActiveCartItemReducer
): SelectAddonResponse {
    return selectGeneral({
        tryAddon: tryAddon,
        quantity: 0,
        cartItem: cartItem,
        selectType: SelectType.Increment
    });
}

// Used to decrement addon quantity in addon groups
// where supportsMultiple property is true
export function selectDecrementAddon(
    tryAddon: Addon,
    cartItem: ActiveCartItemReducer
): SelectAddonResponse {
    return selectGeneral({
        tryAddon: tryAddon,
        quantity: 0,
        cartItem: cartItem,
        selectType: SelectType.Decrement
    });
}

export function selectAddonQuantity(
    tryAddon: Addon,
    quantity: number,
    cartItem: ActiveCartItemReducer
): SelectAddonResponse {
    return selectGeneral({
        tryAddon: tryAddon,
        quantity: 0,
        cartItem: cartItem,
        selectType: SelectType.Quantity
    });
}

// General fxn containing logic for all three
function selectGeneral(args: {
    tryAddon: Addon;
    quantity: number;
    cartItem: ActiveCartItemReducer;
    selectType: SelectType;
}): SelectAddonResponse {
    try {
        const { product, selectedAddons } = args.cartItem;

        let addon: Addon | null = null;
        let addonGroup: AddonGroup | null = null;

        // no product selected, don't do anything
        if (!product) {
            return ["no product selected", null];
        }
        // find product's addon group from addon
        for (const ag of product.addonGroups) {
            for (const ao of ag.addons) {
                if (ao._id === args.tryAddon._id) {
                    addon = ao;
                    addonGroup = ag;
                }
            }
        }

        // if addon no longer exists, don't do anything
        // use case: saved addons
        if (!addon || !addonGroup) {
            return ["addon does not exist", null];
        }

        // if addon is sold out
        if (itemIsSoldOut(addon)) {
            return ["addon is sold out", null];
        }

        const ret = selectedAddons
            ? selectedAddons.map((item) => ({ ...item }))
            : [];
        const addonIndex = getAddonIndexById(addon._id, ret);
        const addonIsSelected = addonIndex !== -1;
        const addonQuantity =
            selectedAddons && addonIsSelected
                ? selectedAddons[addonIndex].quantity || 0
                : 0;

        if (args.selectType === SelectType.Toggle) {
            // unselect
            if (addonIsSelected) {
                decrementAddonByIndex(ret, addonIndex);
                return ["", ret];
            }
            // if addonGroup limit is reached
            const limitIsReached = addonGroupLimitIsReached(
                addonGroup,
                selectedAddons
            );

            // remove the other selected addon in the addonGroup if limit is 1
            if (limitIsReached && addonGroup.limit === 1) {
                const otherAddonIndex = findAddonIndexByAddonGroup(
                    ret,
                    addonGroup
                );
                if (otherAddonIndex !== -1) {
                    decrementAddonByIndex(ret, otherAddonIndex);
                }
                setAddonQuantity(
                    ret,
                    addonIndex,
                    addon,
                    addonGroup,
                    addonQuantity + 1
                );
                return ["", ret];
            }
            if (limitIsReached) {
                return ["", selectedAddons];
            }
            setAddonQuantity(
                ret,
                addonIndex,
                addon,
                addonGroup,
                addonQuantity + 1
            );
        } else if (args.selectType === SelectType.Increment) {
            // if addonGroup limit is reached
            const limitIsReached = addonGroupLimitIsReached(
                addonGroup,
                selectedAddons
            );

            if (limitIsReached) {
                return ["", selectedAddons];
            }

            setAddonQuantity(
                ret,
                addonIndex,
                addon,
                addonGroup,
                addonQuantity + 1
            );
        } else if (args.selectType === SelectType.Decrement) {
            decrementAddonByIndex(ret, addonIndex);
        } else if (args.selectType === SelectType.Quantity) {
            // Verify that selection doesn't exceed addonGroup limit
            if (addonGroup.limit) {
                const numSelected = getNumSelectedAddonsInAddonGroup(
                    addonGroup,
                    selectedAddons
                );
                const deltaSelected = args.quantity - addonQuantity;
                // Calc if change in numSelected will exceed
                const exceedsLimit =
                    numSelected + deltaSelected > addonGroup.limit;
                if (exceedsLimit) {
                    return ["selection exceeds limit", null];
                }
            }
            setAddonQuantity(ret, addonIndex, addon, addonGroup, args.quantity);
        }

        return ["", ret];
    } catch (err: any) {
        // eslint-disable-next-line
        return [err.message, null];
    }
}

/**
 * Finds index of an addon from an
 * addon group in selected addons
 * (Used to select/deselect addons in addon groups with limit=1)
 * @param selectedAddons
 * @param addonGroup
 * @returns index if found else -1
 */
function findAddonIndexByAddonGroup(
    selectedAddons: CartAddon[],
    addonGroup: AddonGroup
): number {
    let addonIndex = -1;
    if (selectedAddons) {
        addonIndex = selectedAddons
            .map((item) => item.addonGroup._id)
            .indexOf(addonGroup._id);
    }
    return addonIndex;
}

/**
 * Mutates selectedAddons by decrementing the quantity
 * of the addon at the specified index. If decrementing to 0,
 * removes the addon entirely from the selectedAddons array
 * @param selectedAddons
 * @param addonIndex
 */
function decrementAddonByIndex(
    selectedAddons: CartAddon[],
    addonIndex: number
): void {
    if (addonIndex !== -1) {
        selectedAddons[addonIndex].quantity -= 1;
        if (selectedAddons[addonIndex].quantity === 0) {
            selectedAddons.splice(addonIndex, 1);
        }
    }
}

/**
 * Mutates selectedAddons by setting the quantity
 * of the addon. If the addon is not in selectedAddons,
 * it gets pushed to the array. If quantity ends up below 1,
 * the addon gets removed from the array.
 * @param selectedAddons
 * @param addonIndex
 * @param addon
 * @param addonGroup
 * @param quantity
 */
function setAddonQuantity(
    selectedAddons: CartAddon[],
    addonIndex: number,
    addon: Addon,
    addonGroup: AddonGroup,
    quantity: number
): void {
    if (addonIndex === -1 && quantity > 0) {
        selectedAddons.push({
            addon: addon,
            addonGroup: addonGroup,
            quantity
        });
    } else if (addonIndex !== -1 && quantity > 0) {
        selectedAddons[addonIndex].quantity = quantity;
    } else if (addonIndex !== -1 && quantity <= 0) {
        selectedAddons.splice(addonIndex, 1);
    }
}
