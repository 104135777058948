import React from "react";
import { ReactComponent as BellEmpty } from "src/assets/icons/bell-empty.svg";
import { ReactComponent as BellFilled } from "src/assets/icons/bell-filled.svg";
import { ReactComponent as ReceiptFilled } from "src/assets/icons/receipt-filled.svg";
import { ReactComponent as ReceiptEmpty } from "src/assets/icons/receipt-empty.svg";
import { Link } from "react-router-dom";

function TabLink({ to, text, icon: Icon, isSelected, isMobile }) {
    return (
        <Link
            to={to}
            className={`grow shrink basis-0 h-12 px-3 py-[15px] justify-center items-center gap-2 flex border-b-2 ${
                isSelected
                    ? "border-stone-950 text-stone-800"
                    : "border-zinc-300 hover:border-zinc-300 hover:text-stone-800"
            }`}
        >
            <Icon width={16} height={16} />
            <div
                className={`text-${
                    isSelected ? "stone-950" : "stone-800"
                } text-sm font-medium leading-[18px]`}
            >
                {text}
            </div>
        </Link>
    );
}

type Props = {
    resolvedTab: string;
    purchaseId: string;
    token: string;
    isMobile: boolean;
    isFooter: boolean;
};

function Tabs({ resolvedTab, purchaseId, token, isMobile, isFooter }: Props) {
    const searchParams = new URLSearchParams(window.location.search);
    return (
        <div
            className={`w-full h-[50px] bg-white sticky ${
                isMobile ? "md:hidden inline-flex" : "hidden md:inline-flex"
            } ${
                isFooter ? "bottom-0 border-t border-zinc-200" : "top-0"
            } z-10 md:rounded-tl-xl md:rounded-tr-xl py-px border-zinc-200 justify-center items-center`}
        >
            <TabLink
                to={{
                    pathname: `/purchases/${purchaseId}/${token}/status`,
                    search: searchParams.toString(),
                }}
                text="Order Status"
                icon={resolvedTab === "status" ? BellFilled : BellEmpty}
                isSelected={resolvedTab === "status"}
                isMobile={isMobile}
            />
            <TabLink
                to={{
                    pathname: `/purchases/${purchaseId}/${token}/receipt`,
                    search: searchParams.toString(),
                }}
                text="Receipt"
                icon={resolvedTab === "receipt" ? ReceiptFilled : ReceiptEmpty}
                isSelected={resolvedTab === "receipt"}
                isMobile={isMobile}
            />
        </div>
    );
}

export default Tabs;
