import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { firebaseAuth } from "../firebase";
import { useDispatch } from "react-redux";
import { AuthStatusType, setAuthStatus } from "@/redux";
import { fetchUser } from "@/redux/thunks";
import { setGQLAuthHeader } from "@/utils/Api/graphql/client";

export function useLoadUser() {
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            async (firebaseUser) => {
                if (firebaseUser) {
                    // NB: Order matters here. We need to set the auth header before updating the auth status.
                    await setGQLAuthHeader(firebaseUser);
                    dispatch(setAuthStatus(AuthStatusType.SIGNED_IN));
                    dispatch(fetchUser());
                } else {
                    // No user is signed in.
                    console.log("No user is signed in.");
                }
            },
        );
        return () => unsubscribe();
    }, [dispatch]);
}
