import React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "src/utils/Supabase";
import Api from "src/utils/Api/REST";
import { useSelector } from "react-redux";
import { getMyLocation } from "@snackpass/accounting";
import { LoaderIcon, X } from "lucide-react";

const radius = 4500000; // should we limit the radius?

const NoHits = ({ query }: { query: string }) => (
    <div className="p-5 bg-gray-400 border-b border-gray-300">
        <p className="m-0 text-lg text-primary">
            No results have been found for {query}.
        </p>
    </div>
);

type HitType = {
    _id: string;
    name: string;
    address?: string;
    objectID?: string;
};

type HitProps = {
    hit: HitType;
    onClick: (hit: { _id: string; name: string; address?: string }) => void;
};

const Hit = ({ hit, onClick }: HitProps) => (
    <div
        onClick={() => {
            onClick(hit);
        }}
    >
        <div className="p-5 bg-muted border border-b border-gray-200 cursor-pointer">
            <p className="text-base">{`${hit.name}`}</p>
            <span className="text-muted-foreground">{hit._id}</span>
            <br />
            <span className="text-muted-foreground">{hit.address}</span>
        </div>
    </div>
);

const Hits = ({
    hits,
    query,
    isSearching,
    onClick,
}: {
    hits: HitType[];
    query: string;
    isSearching: boolean;
    onClick: (hit: HitType) => Promise<void>;
}) => {
    if (!isSearching && query && hits.length === 0) {
        return <NoHits query={query} />;
    }
    return (
        <ol className="list-none pl-0">
            {hits.map((hit) => (
                <Hit key={hit.objectID} hit={hit} onClick={onClick} />
            ))}
        </ol>
    );
};

const CloseButton = ({
    query,
    onClick,
}: {
    query: string;
    onClick: () => void;
}) => {
    if (!query) {
        return null;
    }
    return (
        <div className="absolute inset-y-2 right-0 flex items-center pr-8">
            <X onClick={onClick} />
        </div>
    );
};

export type StoreSearch = {
    _id: string;
    name: string;
};

type StoreSearchWithQuery = StoreSearch & { query: string };

export const AtlasStoreFinder = () => {
    const [query, setQuery] = useState("");
    const {
        coordinates: [long, lat],
    } = useSelector(getMyLocation);

    const [hits, setHits] = useState<StoreSearchWithQuery[]>([]);

    const { refetch, isLoading } = useQuery({
        queryKey: ["search", query],
        queryFn: async ({ signal }) =>
            Api.stores.search({ query, lat, long, radius, signal }),

        // Disables the query firing on-mount
        enabled: false,
    });

    useEffect(() => {
        if (query) {
            void refetch().then(({ data }) => {
                const storesFromSearch = data?.data.stores ?? [];
                setHits(
                    storesFromSearch.map((store) => ({
                        ...store,
                        query,
                    })),
                );
            });
        } else {
            setHits([]);
        }
    }, [query, refetch]);

    const onClickHit = async (hit: HitType) => {
        // check if dynamic link clicked
        const urlParts = window.location.pathname.split("/");
        if (!urlParts[2]) {
            alert("dynamic link missing");
            return;
        }
        // handle confirmed
        const confirmed = window.confirm(
            `Are you sure you want to register this QR code to ${hit.name}? You will not be able to reassign this code without contacting support.`,
        );
        if (confirmed) {
            const { error } = await supabase
                .from("links")
                .insert([{ link_id: urlParts[2], store_id: hit._id }]);
            if (error) {
                alert(error.message);
            } else {
                alert("success");
            }
        }
    };

    return (
        <div>
            <div className="p-5 relative w-[100%] md:w-4/5">
                <input
                    name="restaurant"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    placeholder="Search store"
                    spellCheck="false"
                    className="w-[100%] text-base py-2.5 pl-2.5 pr-10 border rounded-xl border-secondary"
                    autoCorrect="off"
                    autoComplete="off"
                />
                <CloseButton query={query} onClick={() => setQuery("")} />
            </div>
            {isLoading ? (
                <LoaderIcon className="ml-10  my-4" />
            ) : (
                <Hits
                    isSearching={isLoading}
                    query={query}
                    hits={hits}
                    onClick={onClickHit}
                />
            )}
        </div>
    );
};
