import { setActiveStore } from "#activeStore";
import { fetchMenuSaga, watchFetchMenuSaga } from "./fetchMenuSaga";
import { menuService } from "../service";
import { call, fork, takeLatest } from "typed-redux-saga";

export function* menuServiceSaga() {
    yield* takeLatest(setActiveStore, function* ({ payload: activeStore }) {
        if (!activeStore) return;

        const { _id: storeID } = activeStore;

        // Build a store specific menu service
        const storeMenuService = yield* call(menuService, storeID);

        // Forks off watcher for fetch menu actions.
        yield* fork(watchFetchMenuSaga, storeMenuService);

        // Fetch the menu automatically on store change.
        yield* call(fetchMenuSaga, storeMenuService);
    });
}
