import { Time } from "@snackpass/accounting";
import { IStore } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

import { Colors } from "src/utils";
import * as MapUtils from "src/SharedComponents/Map/MapUtils";
import { isOpenLocal, storeOpenUntil } from "@/utils/Time";
import { getMyLocation, hasLocationSet } from "@/redux";
import { Button } from "../ui/button-2";
import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import Hours from "@/modals/storeHoursModal/Hours";
import { useState } from "react";
import clsx from "clsx";

type Props = {
    store: IStore;
};

export const StoreLabelRow = ({ store }: Props) => {
    const hasLocation = useSelector(hasLocationSet);
    const location = useSelector(getMyLocation);

    const distance = hasLocation
        ? `${MapUtils.getFormattedDistance(location, store.geolocation)} • `
        : "";

    return (
        <span
            style={{
                color: Colors.darkGray,
            }}
        >
            {distance} <OpenLabel store={store} />
        </span>
    );
};

const OpenLabel = ({ store }: { store: IStore }) => {
    let label;
    let color;

    if (store.closedUntil && Time.storeIsClosedUntil(store)) {
        label = `Closed until ${moment(store.closedUntil).format("h:mma")}`;
        color = Colors.red;
    } else if (!store.isOpen) {
        label = "Closed";
        color = Colors.red;
    } else {
        label = `${isOpenLocal(store.hours) ? "Open" : "Today's Hours"} ${storeOpenUntil({ hours: store.hours })}`;
        color = Colors.green;
    }

    const [open, setOpen] = useState(false);

    const handleHideStoreHoursModal = () => {
        setOpen(false);
    };

    const _onClick = () => {
        setOpen(true);
    };

    return (
        <span>
            <span style={{ color }}>{label}</span>
            <span> •</span>
            <Button
                onClick={_onClick}
                variant={"ghost"}
                className="pl-1 pt-0 pb-0 pr-0 font-normal"
            >
                View Hours
            </Button>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent
                    onEscapeKeyDown={handleHideStoreHoursModal}
                    className="hidden-scroll h-auto max-h-[60%] min-h-[20%] overflow-scroll rounded-lg w-[90vw]"
                >
                    <div className="hidden-scroll relative flex h-full w-full flex-col overflow-y-auto">
                        <div className="sticky right-0 top-0 z-[101] flex justify-center border-b bg-white">
                            <DialogClose className="flex gap-0" />
                            <div className="sticky top-0 h-auto w-auto bg-white md:m-auto">
                                <div className="flex h-14 items-center text-base font-semibold leading-normal text-primary">
                                    Hours
                                </div>
                            </div>
                        </div>
                        <div className="mx-4 my-4">
                            <div
                                className={clsx(
                                    "flex items-start pb-4 text-2xl font-semibold",
                                    {
                                        "text-green-500": store.isOpen,
                                        "text-alert": !store.isOpen,
                                    },
                                )}
                            >
                                {store.isOpen ? "Open" : "Closed"}
                            </div>
                            <Hours storeOverride={store} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </span>
    );
};
