// validatePromotion tells you when a promotion is valid to be used for a cart
// item
import moment from "moment";
import { flow, maxBy, filter } from "lodash/fp";
import {
    IPromotion,
    Fulfillment,
    IPartyPromoTier,
    IPartyPromotion
} from "@snackpass/snackpass-types";
import { PromotionValidation } from "../types";
import { Time } from "../utils";
import { toDollar } from "../utils";
import { replaceFullDayOfWeek } from "../utils/Time";

// Same as validate promotion, but whether another promotion can be added
// rather than current promotion is valid
// returns whether validated, and reason for why validation fails
export const ONE_PER_CART_ERROR = "Only one per cart 🛒";
export const PICKUP_ONLY_ERROR = "Pickup only 🏃‍♀️";
export const DELIVERY_ONLY_ERROR = "Delivery only 🚗";

export function validatePromotion(
    promotion: IPromotion | null | undefined,
    subtotal: number,
    fulfillment: Fulfillment,
    // checkout promotionIds
    promotionIds: string[],
    dealIds: string[],
    itemTotalPrice?: number,
    scheduledDate?: Date | null
): PromotionValidation {
    // Only whether a promotion is currently valid //
    // in the cart. Checks  conditions such as cartMin, cartMax, onePerCart,
    // TODO: Combine with above delivery
    if (!promotion) {
        return {
            validated: true,
            reason: ""
        };
    }
    const conditions = promotion.conditions || {};
    if (promotion.hours && !Time.isOpenLocal(promotion.hours)) {
        return {
            validated: false,
            reason: `This happy hour is not available right now`
        };
    }
    if (promotion.activeTimePeriod) {
        if (!Time.isAfterStartTime(promotion)) {
            return {
                validated: false,
                reason: `This promotion will be live at\n${moment(
                    promotion.activeTimePeriod.startTime || new Date()
                ).format(Time.momentFormat1)} 📅`,
                allowAddToCart: false
            };
        }
        if (Time.isAfterStartTime(promotion)) {
            if (Time.isAfterEndTime(promotion)) {
                return {
                    validated: false,
                    reason: `This promotion ended at \n${moment(
                        promotion.activeTimePeriod.endTime || new Date()
                    ).format(Time.momentFormat1)} 📅`,
                    allowAddToCart: false
                };
            }
        }
    }
    if (conditions.onePerCart) {
        if (promotion.type === "DEAL") {
            if (
                dealIds.filter((x) => {
                    return x === promotion._id;
                }).length > 1
            ) {
                return {
                    validated: false,
                    reason: ONE_PER_CART_ERROR
                };
            }
        } else {
            if (
                promotionIds.filter((x) => {
                    return x === promotion._id;
                }).length > 1
            ) {
                return {
                    validated: false,
                    reason: ONE_PER_CART_ERROR
                };
            }
        }
    }
    if (conditions.pickupOnly) {
        if (fulfillment !== "PICKUP") {
            return {
                validated: false,
                reason: PICKUP_ONLY_ERROR
            };
        }
    }
    if (conditions.deliveryOnly) {
        if (fulfillment !== "DELIVERY") {
            return {
                validated: false,
                reason: DELIVERY_ONLY_ERROR
            };
        }
    }
    if (conditions.cartMax && subtotal > conditions.cartMax) {
        return {
            validated: false,
            reason: `Cart must have subtotal less than ${toDollar(
                conditions.cartMax
            )}`
        };
    }
    if (conditions.cartMin && subtotal < conditions.cartMin) {
        return {
            validated: false,
            reason: `Cart must have subtotal greater than ${toDollar(
                conditions.cartMin
            )}`
        };
    }
    if (
        conditions.minimumPrice &&
        itemTotalPrice &&
        itemTotalPrice < conditions.minimumPrice
    ) {
        return {
            validated: false,
            reason: `Item must have price greater than ${toDollar(
                conditions.minimumPrice
            )}`
        };
    }
    if (promotion.hours && !Time.isOpen(promotion, scheduledDate)) {
        // @ts-expect-error happyHourTimeDescription exists as a virtual on PromotionSchema
        const happyHourDescription = (promotion.happyHourTimeDescription ??
            "") as string;
        return {
            validated: false,
            reason: `${promotion.name} is only available:\n${replaceFullDayOfWeek(
                happyHourDescription.toLowerCase()
            )}`,
            allowAddToCart: false
        };
    }
    return { validated: true, reason: "" };
}

/**
 * Returns if the party meets the conditions of the given tier
 *
 * @param tier The party promotion tier
 * @param numberOfPeople The number of people
 */
export const _meetsPartyPromoCondition = (
    tier: IPartyPromoTier,
    numberOfPeople: number
): boolean => {
    const conditions = tier.conditions;

    switch (conditions.conditionType) {
        case "NUM_PEOPLE":
            return (
                conditions.minPeople <= numberOfPeople &&
                (!conditions.maxPeople || numberOfPeople < conditions.maxPeople)
            );
        default:
            return false;
    }
};

/**
 * Gets the best party promo tier / discount for some number of people
 * Note: this can return undefined / null value if there is no tier that can currently be unlocked
 *
 * @param partyPromotion The party promo
 * @param numberOfPeople The number of people (this is used to find the best tier)
 */
export const bestPartyPromoTier = (
    partyPromotion: IPartyPromotion,
    numberOfPeople: number
): IPartyPromoTier | undefined =>
    flow(
        filter((tier: IPartyPromoTier) =>
            _meetsPartyPromoCondition(tier, numberOfPeople)
        ),
        maxBy<IPartyPromoTier | undefined>("discount.percentOff")
    )(partyPromotion.tiers);

export type CanActivateReturn = {
    canActivate: boolean;
    message: string;
    bestDiscount: IPartyPromoTier | null;
};

/**
 * Returns if a user can activate the party.
 *
 * @param partyPromotion Party promo
 * @param numberOfMembers Number of members in the party`
 * @param numberOfCarts Number of completed party carts. Note: make sure to not include canceled carts here
 */
export const canActivateParty = (
    partyPromotion: IPartyPromotion | null,
    numberOfMembers: number,
    numberOfCarts: number
): CanActivateReturn => {
    if (!partyPromotion) {
        return {
            canActivate: false,
            message:
                "This party is too old to order with. Please end the party.",
            bestDiscount: null
        };
    }
    if (numberOfMembers > numberOfCarts) {
        return {
            canActivate: false,
            message:
                "You cannot order until everyone else in the party has ordered.",
            bestDiscount: null
        };
    }

    const bestDiscount = bestPartyPromoTier(partyPromotion, numberOfMembers);

    if (!bestDiscount) {
        return {
            canActivate: false,
            message:
                "You need more people to join your party in order to unlock the discount.",
            bestDiscount: null
        };
    }

    return {
        canActivate: true,
        bestDiscount,
        message: "Can activate party!"
    };
};
