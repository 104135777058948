import { Divider } from "@/SharedComponents";
import { IStore } from "@snackpass/snackpass-types";
import React from "react";

type Props = { isLast: boolean } & Pick<
    IStore,
    "name" | "_id" | "address" | "slug"
>;

export function GiftCardViewParticipatingLocation({
    name,
    _id,
    address,
    slug,
    isLast,
}: Props) {
    return (
        <div className="flex flex-col gap-1 text-left">
            <a href={`/${slug ?? `$${_id}`}`} target="_blank" rel="noreferrer">
                {name}
            </a>
            <div>{address}</div>
            <Divider className={isLast ? "mt-4" : "my-4"} width={1} />
        </div>
    );
}
