import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeolocationHelpers from "src/utils/Geolocation";
import { Geolocation } from "@snackpass/snackpass-types";
import { getMyLocation, hasLocationSet, updateMyLocation } from "src/redux";
import { toast } from "sonner";

export function useGetGeolocation() {
    const location = useSelector(getMyLocation);
    const setLocation = useSelector(hasLocationSet);

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onSuccessLocation = (position) => {
        let geolocation: Geolocation = {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude],
        };

        dispatch(updateMyLocation(geolocation));

        toast.success(
            "Location updated successfully! Stores are now sorted by distance.",
        );
        setLoading(false);
    };

    const onErrorLocation = (error) => {
        console.error(error);

        toast("You denied geolocation permissions", {
            description: `If this was a mistake, you can change it in your browser settings.`,
        });

        setLoading(false);
    };

    const requestLocation = () => {
        if (!navigator.geolocation) {
            return toast.warning("Your browser does not support geolocation.");
        }

        setLoading(true);

        GeolocationHelpers.requestLocation(onSuccessLocation, onErrorLocation);
    };

    const getLocationPermission = async () => {
        setLoading(true);

        try {
            const result = await navigator.permissions.query({
                name: "geolocation",
            });

            if (result.state === "granted" && !setLocation) {
                requestLocation();
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    useEffect(() => {
        getLocationPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return {
        requestLocation,
        loading,
    };
}
