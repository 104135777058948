import React from "react";
import { Button } from "@/components/ui/button";
import {
    CartSelectors,
    CheckoutSelectors,
    getActiveStoreDeliveryMin,
    getActiveStorePickupMin,
    toDollar,
} from "@snackpass/accounting";
import { useDispatch, useSelector } from "react-redux";
import { ModalsName, getActiveStore, hideModal, showModal } from "@/redux";
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg";
import { FulfillmentTypeEnum } from "@snackpass/snackpass-types";
import { Helpers, cn } from "@/utils";
import { usePromotionValidator } from "@/hooks/usePromotionValidator";
import { toast } from "sonner";
import { Segment, SegmentEventNames } from "@/utils/Segment";
import { useNavigateToCart } from "@/hooks/useNavigateToCart";

const viewOrderDefaultTitle = "View Order";
const checkoutDefaultTitle = "Checkout";

export type ViewOrderButtonProps = {
    isGotoCartButton?: boolean;
    classNames?: string;
};

const ViewOrderButton = ({
    isGotoCartButton, // do fulfillment check and navigate to cart if set to true
    classNames,
}: ViewOrderButtonProps) => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const headcount = useSelector(CartSelectors.getCartHeadcount) || 1;
    const cartItems = useSelector(CartSelectors.getCartItems);
    const fulfillment = useSelector(
        CartSelectors.getCartFulfillment,
    ) as FulfillmentTypeEnum;
    const deliveryMin = useSelector(getActiveStoreDeliveryMin);
    const pickupMin = useSelector(getActiveStorePickupMin);
    const minIsReached = useSelector(CheckoutSelectors.getMinIsReached);
    const cartSubtotal = useSelector(CheckoutSelectors.getSubtotal);
    const handleShowSearchProductModal = () => {
        dispatch(showModal(ModalsName.searchProductModal));
    };
    const { validateCartPromotions } = usePromotionValidator();
    const { navigateToCart } = useNavigateToCart();
    const cateringMin = activeStore?.catering?.orderMinimum || 0;
    const cateringMinIsReached = isCatering && cartSubtotal >= cateringMin;
    const pricePerPerson = toDollar(cartSubtotal / headcount);
    const total = toDollar(cartSubtotal);
    const cateringPerPersonCost = isCatering ? `${pricePerPerson}/person` : "";
    const buttonTitle = `${
        isGotoCartButton ? checkoutDefaultTitle : viewOrderDefaultTitle
    } ${total} ${cateringPerPersonCost}`;
    const disableButton =
        (isCatering && !cateringMinIsReached) ||
        !minIsReached ||
        cartItems.length === 0;

    const getMinimumMessage = (
        fulfillment: FulfillmentTypeEnum,
        isCatering: boolean,
    ) => {
        if (isCatering && !cateringMinIsReached) {
            return `Catering minimum amount: ${Helpers.toDollar(cateringMin)}`;
        } else if (
            fulfillment === FulfillmentTypeEnum.Pickup &&
            !minIsReached
        ) {
            return `Pickup minimum amount: ${Helpers.toDollar(pickupMin)}`;
        } else if (
            fulfillment === FulfillmentTypeEnum.Delivery &&
            !minIsReached
        ) {
            return `Delivery minimum amount: ${Helpers.toDollar(deliveryMin)}`;
        } else {
            return "";
        }
    };

    const buttonLabel = () => {
        return (
            <div className="flex flex-col items-center justify-center">
                {buttonTitle}
                <span className="text-xs font-normal">
                    {getMinimumMessage(fulfillment, isCatering)}
                </span>
            </div>
        );
    };

    const handleClick = () => {
        const promotionChecks = validateCartPromotions();
        if (!promotionChecks.valid && isGotoCartButton) {
            toast.error(`Oops, ${promotionChecks.message}`);
            return;
        }
        Segment.track(SegmentEventNames.VIEW_CART, {
            store_id: activeStore?._id,
            store_name: activeStore?.name,
            store_kind: activeStore?.kind,
        });
        if (!isGotoCartButton && cartItems.length === 0) {
            // Cannot go to checkout if the user has no items in their cart
            return;
        }

        if (!isGotoCartButton) {
            // mobile view order button: show view order modal
            dispatch(showModal(ModalsName.viewOrderModal));
            return;
        }

        if (isGotoCartButton) {
            dispatch(hideModal(ModalsName.viewOrderModal));
            navigateToCart();
        }
    };

    return (
        <div
            className={cn(
                "flex flex-row flex-1 items-center justify-center p-4 gap-2",
                classNames,
            )}
        >
            {isGotoCartButton ? null : (
                <Button
                    onPress={handleShowSearchProductModal}
                    className="p-2 lg:hidden"
                    label={<SearchIcon className="my-auto" />}
                    variant="ghost"
                />
            )}
            <Button
                onPress={handleClick}
                label={buttonLabel()}
                variant="brand"
                className="w-full px-1"
                disabled={disableButton}
                size={disableButton ? "lg" : "default"}
            />
        </div>
    );
};

export default ViewOrderButton;
