import React from "react";
import moment from "moment-timezone";
import {
    UseSchedulingReturn,
    TimeSlot,
    BatchInfo,
} from "@snackpass/accounting";
import { cn } from "@/utils";

type SingleTimeProps = {
    scheduling: UseSchedulingReturn;
    isSelected: boolean;
    timeSlot: TimeSlot;
    onSelectTime: (time: moment.Moment) => void;
};

export default function SingleTime({
    timeSlot,
    isSelected,
    onSelectTime,
    scheduling,
}: SingleTimeProps) {
    const hasBatching = scheduling.hasBatching;
    const isDisabled = !scheduling.isSlotAvailable(timeSlot[0]);
    const batch = scheduling.getBatchForSlot(timeSlot[0]);

    return (
        <div className="w-full border-b">
            <button
                className={cn(
                    "text-primary font-semibold border-none outline-none content-center flex items-start justify-start w-full cursor-pointer text-left font-base py-6 border-b pl-3",
                    {
                        "snackpass__disabled-time-option": isDisabled,
                    },
                )}
                disabled={isDisabled}
                onClick={() => onSelectTime(timeSlot[0])}
            >
                <span
                    className={cn(
                        "flex p-1 items-center rounded-full border mr-3",
                        {
                            "bg-accent": isSelected,
                        },
                    )}
                >
                    <span
                        className={cn("p-1 rounded-full border bg-white", {
                            "border-white": !isSelected,
                        })}
                    />
                </span>

                <span className="my-auto">{timeSlot[0].format("h:mm A")}</span>
                {hasBatching && (
                    <BatchMessage isSelected={isSelected} batch={batch} />
                )}
            </button>
        </div>
    );
}

const BatchMessage = ({
    batch,
    isSelected,
}: {
    batch: BatchInfo | null;
    isSelected: boolean;
}) => {
    if (!batch) {
        return null;
    }

    if (batch.isFull) {
        return (
            <span className="pl-3 uppercase block font-medium text-xs">
                Timeslot Filled
            </span>
        );
    }

    const spotsLeftMessage = `${batch.slotsOpen} spot${
        batch.slotsOpen === 1 ? "" : "s"
    } left`;

    return (
        <span
            className={cn(
                "pl-3 uppercase block font-medium text-xs self-center",
                {
                    "text-accent": isSelected,
                    "text-secondary": !isSelected,
                },
            )}
        >
            {spotsLeftMessage}
        </span>
    );
};
