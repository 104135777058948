/**
 *
 * @param activeStore
 * @param points
 * @param onlyPointsName only return singular or plural custom points name(or default) based on points amount
 * @returns
 */

export const getRewardPointsString = (
    points?: number | string,
    onlyPointsName?: boolean,
) => {
    const pointsAmount = Number(points);
    // const rewardPointsName =
    //     activeStore?.loyaltyCustomName?.toLowerCase() ?? "points";
    const rewardPointsName = "points";
    const singularRewardPointsName = rewardPointsName.endsWith("s")
        ? rewardPointsName.slice(0, -1)
        : rewardPointsName;
    const pluralRewardPointsName = rewardPointsName.endsWith("s")
        ? rewardPointsName
        : rewardPointsName + "s";

    if (isNaN(pointsAmount)) {
        // if points is undefined, return title case custom reward points name, e.g. "Points",
        return (
            pluralRewardPointsName.charAt(0).toUpperCase() +
            rewardPointsName.slice(1)
        );
    }

    if (pointsAmount !== 1) {
        // e.g. if points is 2, return "2 Points"
        return onlyPointsName
            ? pluralRewardPointsName
            : `${pointsAmount} ${pluralRewardPointsName}`;
    }

    // e.g. if points is 1, return "1 Point"
    return onlyPointsName
        ? singularRewardPointsName
        : `${pointsAmount} ${singularRewardPointsName}`;
};
