import chroma from "chroma-js";

export enum CSSVariableName {
    brandColor = "--brandColor",
}

export const handleSetThemeColors = (
    cssVariableName: CSSVariableName,
    value: string
) => {
    document.documentElement.style.setProperty(cssVariableName, value);
};

export const extractRGBValues = (rgbColor: string): string => {
    const color = chroma(rgbColor);
    const r = color.get("rgb.r");
    const g = color.get("rgb.g");
    const b = color.get("rgb.b");
    return `${r} ${g} ${b}`;
};
