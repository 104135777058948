import { IGiftCard } from "@snackpass/snackpass-types";

export const SET_GIFTCARDS = "SET_GIFTCARDS";
export type SET_GIFTCARDS = "SET_GIFTCARDS";

export const ADD_REDEEM_GIFTCARD = "ADD_REDEEM_GIFTCARD";
export type ADD_REDEEM_GIFTCARD = "ADD_REDEEM_GIFTCARD";

export type SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";

export type SetGiftCards = {
    type: SET_GIFTCARDS;
    giftCards: IGiftCard[];
};

export const setGiftCards = (giftCards: IGiftCard[]): SetGiftCards => ({
    type: SET_GIFTCARDS,
    giftCards
});

export type AddRedeemGiftCard = {
    type: ADD_REDEEM_GIFTCARD;
    giftCard: IGiftCard;
};

export const addRedeemGiftcard = (giftCard: IGiftCard): AddRedeemGiftCard => ({
    type: ADD_REDEEM_GIFTCARD,
    giftCard
});

export type SetInitialState = {
    type: SET_INITIAL_STATE;
};

export const setInitialState = (): SetInitialState => ({
    type: SET_INITIAL_STATE
});

export type GiftCardActions =
    | SetGiftCards
    | AddRedeemGiftCard
    | SetInitialState;
