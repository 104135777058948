import React, { useEffect } from "react";
import {
    FulfillmentTypeEnum,
    IPurchase,
    IStore,
} from "@snackpass/snackpass-types";
import { ReactComponent as PaperCut } from "src/assets/images/papercut.svg";
import Payment, { StripeCharge } from "./Payment";
import { Divider } from "../../SharedComponents";
import get from "lodash/get";
import moment from "moment-timezone";
import { Segment, SegmentEventNames } from "src/utils/Segment";
import PurchaseReceipt from "@/components/PurchaseReceipt";

function getFulfillmentType(purchase, activeStore) {
    const { fulfillment } = purchase;

    if (fulfillment === FulfillmentTypeEnum.Pickup) {
        return activeStore.pickup ? "Pickup" : "Scheduled Pickup";
    } else if (fulfillment === FulfillmentTypeEnum.Delivery) {
        return "Delivery";
    } else {
        return "Dine In";
    }
}

function ReceiptTab({
    purchase,
    activeStore,
    stripeCharge,
}: {
    purchase: IPurchase;
    activeStore: IStore;
    stripeCharge: StripeCharge | null;
}) {
    const fulfillmentType = getFulfillmentType(purchase, activeStore);

    useEffect(() => {
        // Track when the user opens the receipt page
        Segment.track(SegmentEventNames.VIEW_RECEIPT_TAB, {
            purchaseId: purchase._id,
            transactionSource: purchase.transactionSource,
            storeId: purchase.store?._id,
        });
    }, []);

    return (
        <div>
            <div className="flex flex-col w-full max-w-600 mx-auto p-4">
                <div className="w-auto px-4 text-stone-950 text-xl font-semibold leading-normal">
                    Receipt
                </div>
                <div className="w-auto h-auto my-6 px-3 md:px-6 bg-white flex-col justify-start items-start gap-6 inline-flex border-b border-zinc-200">
                    <div className="self-stretch min-h-[120px] px-4 py-[27px] rounded-xl border border-zinc-200 flex-col justify-start items-start gap-2 flex">
                        <div className="self-stretch text-center text-stone-950 text-[32px] font-bold leading-10">
                            Order #{purchase.receiptNumber}
                        </div>
                        <div className="self-stretch text-center text-zinc-600 text-sm font-normal leading-[18px]">
                            {fulfillmentType}
                        </div>
                        <div className="self-stretch text-center text-zinc-600 text-sm font-normal leading-[18px]">
                            Order placed:{" "}
                            {moment(purchase.createdAt).format("MMM D, h:mm a")}
                        </div>
                        <div className="self-stretch text-center text-zinc-600 text-sm font-normal leading-[18px]">
                            {purchase.isScheduledOrder && purchase.scheduledDate
                                ? `Order Scheduled for ${moment(
                                      purchase.scheduledDate,
                                  ).format("MMM D, h:mm a")}`
                                : ""}
                        </div>
                    </div>
                    <div className="self-stretch pb-4 justify-start items-start gap-2 inline-flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                            <div className="w-auto h-auto text-stone-950 text-base font-semibold leading-normal">
                                {get(purchase, "store.name")}{" "}
                                {get(purchase, "store.emoji")}
                            </div>
                            <div className="self-stretch text-zinc-600 text-base font-normal leading-normal">
                                {activeStore.address}
                            </div>
                        </div>
                        {activeStore.logoUrl && (
                            <img
                                className="w-16 h-16 rounded-[72px]"
                                alt="Store Logo"
                                src={activeStore.logoUrl}
                            />
                        )}
                    </div>
                </div>
                {purchase.notes && (
                    <div className="w-auto h-auto mb-6 px-3 md:px-6 bg-white flex-col justify-start items-start gap-6 inline-flex border-b border-zinc-200">
                        <div className="self-stretch pb-4 justify-start items-start inline-flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                                <div className="w-auto h-auto text-stone-950 text-base font-semibold leading-normal">
                                    Order Notes
                                </div>
                                <div className="self-stretch text-zinc-600 text-base font-normal leading-normal">
                                    {purchase.notes}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {purchase && <PurchaseReceipt purchase={purchase} />}
            </div>
            {stripeCharge && (
                <>
                    <Divider width={8} />
                    <Payment stripeCharge={stripeCharge} />
                </>
            )}

            {/* {!fromCustomApp ? (
                <>
                    <Divider width={8} />
                    <div className="w-full h-auto px-6 py-4 bg-white border-t border-zinc-200 justify-start items-center gap-3 inline-flex">
                        <div className="px-3 grow shrink basis-0 h-12 h-fit justify-start items-start gap-1 flex">
                            <div className="grow shrink basis-0 text-stone-950 self-center text-base font-normal leading-normal">
                                Earn and redeem rewards on the Snackpass app!
                            </div>
                        </div>
                        <Button
                            label="Download"
                            onPress={() => {
                                Segment.track(
                                    SegmentEventNames.CLICKED_DOWNLOAD_APP,
                                    {
                                        purchaseId: purchase._id,
                                        transactionSource:
                                            purchase.transactionSource,
                                        storeId: purchase.store?._id,
                                    }
                                );
                                window.open(
                                    "https://snackpass.co/app",
                                    "_blank"
                                );
                            }}
                        />
                    </div>
                </>
            ) : null} */}
            <div className="bg-neutral-100 h-[100px] max-w-screen-md overflow-hidden">
                <div className="flex flex-row w-screen">
                    <PaperCut />
                    <PaperCut />
                </div>
                {/* <div className="w-full h-auto pt-4 justify-center items-center inline-flex">
                    <SnackpassLogo />
                </div> */}
            </div>
        </div>
    );
}

export default ReceiptTab;
