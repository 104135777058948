import { useCallback, useEffect, useRef } from "react";
import { Maybe, maybe } from "src/utils/Types";

export const useTimeout = () => {
    const timeout = useRef<Maybe<NodeJS.Timeout>>(maybe());

    useEffect(() => {
        const _timeout = timeout.current;
        return () => {
            if (!_timeout) return;
            clearTimeout(_timeout);
        };
    }, [timeout]);

    return useCallback((func: () => any, delay: number) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(func, delay);
    }, []);
};
