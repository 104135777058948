/**
 *
 *
 * isEditCartItem: boolean;
 * Determine weather product is in viewing mode or editing mode
 *
 * cartItemIndex: number
 * Determine which cart item user clicked to edit
 *
 * isSwitchingToCatering: boolean
 * To determine if user are switching from non-catering to catering or not
 * This will help on deciding if we exit the catering mode if user didn't press confirm button in schedule modal
 *
 */

import { createSelector } from "@reduxjs/toolkit";
import { IDealItem } from "@snackpass/snackpass-types";

/* Actions */
export const SET_IS_EDIT_CART_ITEM = "SET_EDIT_CART_ITEM";
export const SET_CART_ITEM_INDEX = "SET_CART_ITEM_INDEX";
export const SET_CART_ITEM_IN_EDIT = "SET_CART_ITEM_IN_EDIT";
export const SET_IS_SWITCHING_TO_CATERING = "SET_IS_SWITCHING_TO_CATERING";
export const SET_DEAL_MODAL_ACTIVE_TAB_INDEX =
    "SET_DEAL_MODAL_ACTIVE_TAB_INDEX";
export const SET_ACTIVE_DEAL_ITEMS_MAP = "SET_ACTIVE_DEAL_ITEMS_MAP";
export const INCREMENT_DEAL_MODAL_ACTIVE_TAB_INDEX =
    "INCREMENT_DEAL_MODAL_ACTIVE_TAB_INDEX";
export const SET_DEAL_GROUP_FOR_DELETE = "SET_DEAL_GROUP_FOR_DELETE";
export const SET_IS_UPSELL_ALREADY_SHOWN = "SET_IS_UPSELL_ALREADY_SHOWN";

export type SET_IS_EDIT_CART_ITEM = typeof SET_IS_EDIT_CART_ITEM;
export type SET_CART_ITEM_INDEX = typeof SET_CART_ITEM_INDEX;
export type SET_CART_ITEM_IN_EDIT = typeof SET_CART_ITEM_IN_EDIT;
export type SET_IS_SWITCHING_TO_CATERING = typeof SET_IS_SWITCHING_TO_CATERING;
export type SET_DEAL_MODAL_ACTIVE_TAB_INDEX =
    typeof SET_DEAL_MODAL_ACTIVE_TAB_INDEX;
export type SET_ACTIVE_DEAL_ITEMS_MAP = typeof SET_ACTIVE_DEAL_ITEMS_MAP;
export type INCREMENT_DEAL_MODAL_ACTIVE_TAB_INDEX =
    typeof INCREMENT_DEAL_MODAL_ACTIVE_TAB_INDEX;
export type SET_DEAL_GROUP_FOR_DELETE = typeof SET_DEAL_GROUP_FOR_DELETE;
export type SET_IS_UPSELL_ALREADY_SHOWN = typeof SET_IS_UPSELL_ALREADY_SHOWN;

export type SetIsEditCartItem = ReturnType<typeof setIsEditCartItem>;
export const setIsEditCartItem = (isEditCartItem: boolean) =>
    ({
        type: SET_IS_EDIT_CART_ITEM,
        isEditCartItem,
    }) as const;

export type SetCartItemIndex = ReturnType<typeof setCartItemIndex>;
export const setCartItemIndex = (cartItemIndex: number) =>
    ({
        type: SET_CART_ITEM_INDEX,
        cartItemIndex,
    }) as const;

export type SetIsSwitchingToCatering = ReturnType<
    typeof setIsSwitchingToCatering
>;
export const setIsSwitchingToCatering = (isSwitchingToCatering: boolean) =>
    ({
        type: SET_IS_SWITCHING_TO_CATERING,
        isSwitchingToCatering,
    }) as const;

export type SetDealModalActiveTabIndex = ReturnType<
    typeof setDealModalActiveTabIndex
>;
export const setDealModalActiveTabIndex = (dealModalActiveTabIndex: number) =>
    ({
        type: SET_DEAL_MODAL_ACTIVE_TAB_INDEX,
        dealModalActiveTabIndex,
    }) as const;

export type SetActiveDealItemsMap = ReturnType<typeof setActiveDealItemsMap>;
export const setActiveDealItemsMap = (activeDealItemsMap: IDealItem[] | null) =>
    ({
        type: SET_ACTIVE_DEAL_ITEMS_MAP,
        activeDealItemsMap,
    }) as const;

export type IncrementDealModalActiveTabIndex = ReturnType<
    typeof incrementDealModalActiveTabIndex
>;
export const incrementDealModalActiveTabIndex = () =>
    ({
        type: INCREMENT_DEAL_MODAL_ACTIVE_TAB_INDEX,
    }) as const;

export type SetDealGroupForDelete = ReturnType<typeof setDealGroupForDelete>;
export const setDealGroupForDelete = (
    dealGroupForDelete: DealGroupForDelete | null,
) =>
    ({
        type: SET_DEAL_GROUP_FOR_DELETE,
        dealGroupForDelete,
    }) as const;

export type SetIsUpsellAlreadyShown = ReturnType<
    typeof setIsUpsellAlreadyShown
>;
export const setIsUpsellAlreadyShown = (isUpsellAlreadyShown: boolean) =>
    ({
        type: SET_IS_UPSELL_ALREADY_SHOWN,
        isUpsellAlreadyShown,
    }) as const;

/* Reducer */

export type DealItemsMap = {
    [key: string]: number;
};

export type DealGroupForDelete = {
    name: string;
    dealGroupIndex: number;
    dealItemId: string | undefined;
};
export interface ModalHelperState {
    isEditCartItem: boolean;
    cartItemIndex: number;
    isSwitchingToCatering: boolean;
    dealModalActiveTabIndex: number;
    activeDealItemsMap: DealItemsMap | null;
    activeDealItemsMapSize: number;
    dealGroupForDelete: DealGroupForDelete | null;
    isUpsellAlreadyShown: boolean;
}

export const InitialState: ModalHelperState = {
    isEditCartItem: false,
    cartItemIndex: -1,
    isSwitchingToCatering: true,
    dealModalActiveTabIndex: 0,
    activeDealItemsMap: null,
    activeDealItemsMapSize: 0,
    dealGroupForDelete: null,
    isUpsellAlreadyShown: false,
};

export const modalHelper = (
    state = InitialState,
    action:
        | SetIsEditCartItem
        | SetCartItemIndex
        | SetIsSwitchingToCatering
        | SetDealModalActiveTabIndex
        | SetActiveDealItemsMap
        | IncrementDealModalActiveTabIndex
        | SetDealGroupForDelete
        | SetIsUpsellAlreadyShown,
) => {
    switch (action.type) {
        case SET_IS_EDIT_CART_ITEM:
            return { ...state, isEditCartItem: action.isEditCartItem };
        case SET_CART_ITEM_INDEX:
            return { ...state, cartItemIndex: action.cartItemIndex };
        case SET_IS_SWITCHING_TO_CATERING:
            return {
                ...state,
                isSwitchingToCatering: action.isSwitchingToCatering,
            };
        case SET_DEAL_MODAL_ACTIVE_TAB_INDEX:
            return {
                ...state,
                dealModalActiveTabIndex: action.dealModalActiveTabIndex,
            };
        case SET_ACTIVE_DEAL_ITEMS_MAP:
            let size = 0;
            const map = action.activeDealItemsMap
                ? action.activeDealItemsMap?.reduce((map, deal, index) => {
                      map[deal._id] = index;
                      size += 1;
                      return map;
                  }, {})
                : null;
            return {
                ...state,
                activeDealItemsMap: map,
                activeDealItemsMapSize: size,
            };
        case INCREMENT_DEAL_MODAL_ACTIVE_TAB_INDEX:
            const nextIndex = state.dealModalActiveTabIndex + 1;
            const result =
                state.activeDealItemsMapSize &&
                nextIndex < state?.activeDealItemsMapSize
                    ? nextIndex
                    : state.dealModalActiveTabIndex;
            return {
                ...state,
                dealModalActiveTabIndex: result,
            };
        case SET_DEAL_GROUP_FOR_DELETE:
            return {
                ...state,
                dealGroupForDelete: action.dealGroupForDelete,
            };
        case SET_IS_UPSELL_ALREADY_SHOWN:
            return {
                ...state,
                isUpsellAlreadyShown: action.isUpsellAlreadyShown,
            };
        default:
            return state;
    }
};

export default modalHelper;

/* Selectors */
const getModalHelperState = (state: { modalHelper: ModalHelperState }): ModalHelperState => state.modalHelper;

export const getIsEditCartItem = (state): boolean =>
    state.modalHelper.isEditCartItem;
export const getCartItemIndex = (state): number =>
    state.modalHelper.cartItemIndex;
export const getIsSwitchingToCatering = (state): string =>
    state.modalHelper.isSwitchingToCatering;
export const getDealModalActiveTabIndex = (state): number =>
    state.modalHelper.dealModalActiveTabIndex;

export const getActiveDealItemsMap = (state): number =>
    state.modalHelper.activeDealItemsMap;

export const getDealGroupForDelete = (state): DealGroupForDelete =>
    state.modalHelper.dealGroupForDelete;

/**
 * Selects a flag that indicates an upsell modal has already shown to the user.
 */
export const selectIsUpsellAlreadyShown = createSelector([
    getModalHelperState
], (state) => state.isUpsellAlreadyShown);
