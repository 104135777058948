import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthStatusType, getUserAuthStatus, setPastOrders } from "@/redux";
import API from "@/utils/Api/REST";
import { StoreUtils } from "@/utils";

export enum FetchingState {
    Idle = "Idle",
    Loading = "Loading",
    Success = "Success",
    Error = "Error",
}

export function useLoadPastOrders(storeNameAndId: string) {
    const dispatch = useDispatch();
    const [fetchingState, setFetchingState] = useState(FetchingState.Idle);

    const { id, slug } = getParseStoreParams(storeNameAndId);
    const authStatus = useSelector(getUserAuthStatus);
    useEffect(() => {
        if (authStatus !== AuthStatusType.SIGNED_IN) return;
        const params = {
            storeId: id,
            slug,
            includeChain: true,
        };
        API.purchases
            .getRecentPurchases(params)
            .then((response) => {
                dispatch(setPastOrders(response.data.purchases));
                setFetchingState(FetchingState.Success);
            })
            .catch((error) => {
                setFetchingState(FetchingState.Error);
            });
    }, [authStatus]);

    return { fetchingState };
}

function getParseStoreParams(storeNameAndId: string): {
    id: string;
    slug: string;
} {
    let storeId: string = "";
    let slug: string = "";
    const objectIDMatch = storeNameAndId.match(/[0-9a-f]{24}/i);
    if (storeNameAndId.startsWith("$")) {
        storeId = storeNameAndId.slice(1);
        slug = "";
    } else if (storeNameAndId.includes("-")) {
        storeId = StoreUtils.parseStoreIdFromUrl(storeNameAndId);
    } else if (objectIDMatch) {
        storeId = objectIDMatch[0];
    } else {
        slug = storeNameAndId;
    }
    return { id: storeId, slug };
}
