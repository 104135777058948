import React from "react";
import { css } from "@emotion/react";
import { SystemColors } from "@snackpass/design-system";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { ProductRow } from "./ProductRow";
import { selectConflicts } from "#cart/selectors";
import { CartConflict } from "#cart";

type FulfillmentConflict = Omit<CartConflict, "reason"> & {
    reason: Extract<CartConflict["reason"], { type: "fulfillment" }>;
};

enum Styles {
    deleteButton = "delete-button",
    cancelButton = "cancel-button",
    modal = "modal",
    headerText = "header-text",
    container = "container",
    buttonRow = "button-row",
    descriptionText = "description-text",
    modalBodyStyle = "modal-body-style",
    button = "button",
}

const styles = css`
    display: flex;
    flex-direction: column;

    .${Styles.deleteButton} {
        background-color: ${SystemColors.melon50} !important;
        width: 150px;
        height: 50px;
    }

    .${Styles.modal} {
        overflow: hidden;
        top: 25px;
        padding: 15px;
    }

    .${Styles.cancelButton} {
        background-color: ${SystemColors.gray90};
        width: 150px;
        height: 50px;
    }

    .${Styles.headerText} {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #282d32;
        flex-grow: 0;
        margin: 20px;
        height: fit-content;
    }

    .${Styles.buttonRow} {
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }

    .${Styles.descriptionText} {
        color: ${SystemColors.gray50};
        text-align: center;
        font-size: 16px;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .${Styles.modalBodyStyle} {
        max-height: 90vh;
        background-color: ${SystemColors.white};
        padding: 20px 0;
        bottom: ${isMobile ? 0 : undefined};
    }
`;

export const Body = () => {
    const conflicts = useSelector(selectConflicts);

    const isAllFulfillment = isAllFulfillmentConflicts(conflicts);

    const description = isAllFulfillment
        ? `Some items in your order are not available for ${getFulfillment(conflicts.at(0)).toLowerCase()}`
        : "Some items in your order are not available or have become stale.";

    return (
        <div css={styles}>
            <div className={Styles.descriptionText}>{description}</div>
            {conflicts.map((conflict, i) => {
                return <ProductRow product={conflict.item.product} key={i} />;
            })}
        </div>
    );
};

const isAllFulfillmentConflicts = (
    conflicts: CartConflict[],
): conflicts is FulfillmentConflict[] =>
    conflicts.every((conflict) => conflict.reason.type === "fulfillment");

const getFulfillment = (conflict: FulfillmentConflict | undefined) =>
    conflict ? `${conflict.reason.fulfillment}` : "";
