import React, { useContext } from "react";
import DatePicker from "./DatePicker";
import { useSelector } from "react-redux";
import { CartSelectors } from "@snackpass/accounting";
import { FulfillmentTypeEnum, WhenTypeEnum } from "@snackpass/snackpass-types";
import { ScheduleContext } from "../ScheduleModalContext";
import { getPickupEstTimeString } from "@/screens/StoreScreen/lib";
import { getActiveStore } from "@/redux";
import { RushHourUntil } from "@/components/RushHourUntil";

const ModalActivePane = () => {
    const { modalWhen } = useContext(ScheduleContext);
    const activeStore = useSelector(getActiveStore);
    const fulfillment = useSelector(CartSelectors.getCartFulfillment);
    const pickupEstimateData = useSelector(CartSelectors.getPickupEstimate);

    if (!activeStore) return null;
    const timeText = getPickupEstTimeString(pickupEstimateData, activeStore);
    const isRushHourUntil = Boolean(activeStore.rushHourUntil);
    const description = timeText ? `Pickup time: ${timeText}` : "";
    const title = "Scheduled for";

    if (
        modalWhen === WhenTypeEnum.Now &&
        fulfillment === FulfillmentTypeEnum.Pickup
    ) {
        return (
            <>
                {isRushHourUntil && (
                    <div className="mt-6 mx-6">
                        <RushHourUntil />
                    </div>
                )}
                <div className="min-h-[100px] mx-6 flex flex-1 items-center justify-center">
                    {description}
                </div>
            </>
        );
    }

    if (modalWhen === WhenTypeEnum.Later) {
        return (
            <div className="mx-6 my-4">
                <DatePicker title={title} />
            </div>
        );
    }
    return null;
};

export default ModalActivePane;
