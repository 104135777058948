import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import {
    ActiveCartItemActions,
    ActiveCartItemSelectors,
    CartItem,
    CartSelectors,
    DealSelectionActions,
} from "@snackpass/accounting";
import {
    Addon,
    IProduct,
    IStore,
    PromoTypes,
} from "@snackpass/snackpass-types";
import {
    useSetRewardPromos,
    useSetRewards,
    useActiveReward,
    useActiveRewardPromo,
} from "@snackpass/loyalty-react";

import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import { SegmentEventNames, Segment } from "src/utils/Segment";
import { FulfillmentDescription } from "../../components/fulfillment_description";
import { Divider } from "../../SharedComponents";
import AddonGroups from "./components/Addons/AddonGroups";
import ProductDescription from "./components/ProductDescription";
import ProductImage from "./components/ProductImage";
import ProductName from "./components/ProductName";
import SpecialInstructions from "./components/SpecialInstructions";
import DigitalGiftCard from "./components/DigitalGiftCard/DigitalGiftCard";
import { isDigitalGiftCard } from "./components/DigitalGiftCard/isDigitalGiftCard";
import { FooterAddToCartButton } from "./components/FooterAddToCartButton";
import QuantityWeightControl from "./components/QuantityWeightControl";
import {
    getActiveStore,
    destroyModal,
    ModalsName,
    getIsEditCartItem,
    setIsEditCartItem,
    getCartItemIndex,
    hideModal,
    setActivePromotion,
    incrementDealModalActiveTabIndex,
    getActivePromotion,
    getIsProductModalOpen,
} from "@/redux";
import useCartItemHandler from "@/hooks/useCartItemHandler";
import { useMediaQuery } from "react-responsive";
import { getDealSelection } from "@/redux/deal";
import { useAppSelector } from "@/redux/utils";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const ProductModal = () => {
    const dispatch = useDispatch();

    const { setActiveRewardPromo } = useSetRewardPromos();
    const { setActiveReward } = useSetRewards();
    const activeReward = useActiveReward();
    const activeRewardPromo = useActiveRewardPromo();
    const activeDealItem = useSelector(getDealSelection).activeDealItem;
    const activePromotion = useSelector(getActivePromotion);
    const handleDestroyProductModal = () => {
        dispatch(destroyModal(ModalsName.productModal));
    };

    const selectAddon = (addon: Addon) => {
        dispatch(ActiveCartItemActions.selectAddon(addon));
    };

    const selectIncrementAddon = (addon: Addon) => {
        dispatch(ActiveCartItemActions.selectIncrementAddon(addon));
    };

    const selectDecrementAddon = (addon: Addon) => {
        dispatch(ActiveCartItemActions.selectDecrementAddon(addon));
    };

    const setActiveCartItemNotes = (notes: string) => {
        dispatch(ActiveCartItemActions.setActiveCartItemNotes(notes));
    };

    const { addCartItem, updateCartItem, checkIsCartItemAvailable } =
        useCartItemHandler();

    const cart = useSelector(CartSelectors.getCart);
    const activeStore = useSelector(getActiveStore);
    const selectedAddons = useSelector(
        ActiveCartItemSelectors.getSelectedAddons,
    );
    const addonSelectionIsValidated = useSelector(
        ActiveCartItemSelectors.getAddonGroupsNeedSelection,
    );
    const activeCartItem = useSelector(
        ActiveCartItemSelectors.getActiveCartItem,
    );
    const editCartItem = useSelector(getIsEditCartItem);
    const cartItemIndex = useSelector(getCartItemIndex);
    const productModal = useAppSelector(getIsProductModalOpen);
    const activeCartItemNotes = useSelector(
        ActiveCartItemSelectors.getActiveCartItemNotes,
    );

    const [quantity, setQuantity] = useState<number>(1);
    const [weight, setWeight] = useState<number>(0);

    const [showRequiredAlerts, setShowRequiredAlerts] =
        useState<boolean>(false);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    useEffect(() => {
        const product = activeCartItem.product;

        if (product) {
            const minimumQuantity = product.minimumQuantity ?? 0;
            setQuantity((prevQuantity) =>
                prevQuantity >= minimumQuantity
                    ? prevQuantity
                    : minimumQuantity,
            );

            Segment.track(SegmentEventNames.PRODUCT_VIEWED, {
                item_id: product._id,
                item_name: product.name,
                item_category: product.category,
                item_location_id: activeStore?.region,
                price: product.price,
                store_id: activeStore?._id,
                store_name: activeStore?.name,
                store_kind: activeStore?.kind,
            });
        }
    }, [
        activeCartItem.product?._id,
        activeCartItem.product?.minimumQuantity,
        activeStore?._id,
        activeStore?.region,
        activeStore?.name,
    ]);

    const handleToggleAddon = (addon: Addon) => {
        selectAddon(addon);
    };

    const handleChangeAddonQuantity = (addon: Addon, isIncrement: boolean) => {
        const propFunc = isIncrement
            ? selectIncrementAddon
            : selectDecrementAddon;
        propFunc(addon);
    };

    const handleAddItemToCart = () => {
        const activeProduct = activeCartItem.product as IProduct;

        if (!activeStore || !activeProduct) return;
        const { isValid, text, invalidAddonGroupIds } =
            checkIsCartItemAvailable(activeProduct, quantity, weight);

        if (!isValid) {
            if (invalidAddonGroupIds) {
                const element = document.getElementById(
                    `${invalidAddonGroupIds}`,
                );
                if (element) {
                    // Will scroll smoothly to the top of the next section
                    element.scrollIntoView({
                        behavior: "smooth",
                    });
                }
                setShowRequiredAlerts(true);
                toast.info("Please select all required modifiers");
            } else {
                toast(text);
            }
            return;
        }

        if (activePromotion?.type === PromoTypes.Discount) {
            Segment.track(SegmentEventNames.ADDED_DISCOUNT_TO_CART, {
                promoId: activePromotion._id,
            });
        }

        if (activeDealItem) {
            dispatch(
                DealSelectionActions.setDealItem(
                    activeCartItem as CartItem,
                    activeStore as IStore,
                ),
            );
            dispatch(hideModal(ModalsName.productModal));
            dispatch(incrementDealModalActiveTabIndex());
            dispatch(ActiveCartItemActions.clearActiveCartItem());
            return;
        }
        const cartItemInEdit = cart.items[cartItemIndex];
        if (cartItemInEdit && editCartItem && cartItemIndex > -1) {
            updateCartItem(
                cartItemIndex,
                selectedAddons,
                activeCartItemNotes,
                weight,
            );
        } else {
            addCartItem(
                activeProduct,
                selectedAddons,
                activeCartItemNotes,
                quantity,
                weight,
            );
        }

        handleAfterAddCart();
    };

    const handleAfterAddCart = () => {
        handleHide();
        setActiveRewardPromo(null);
        setActiveReward(null);
        dispatch(setActivePromotion(null));
        dispatch(hideModal(ModalsName.discountModal));
        dispatch(ActiveCartItemActions.clearActiveCartItem());
    };

    const handleHide = () => {
        handleDestroyProductModal();
        dispatch(setIsEditCartItem(false));
        setShowRequiredAlerts(false);
        //set to default state.
        setQuantity(1);
        setWeight(0);
        dispatch(ActiveCartItemActions.clearActiveCartItem());
        if (activeProduct && isDigitalGiftCard(activeProduct)) {
            dispatch(setActivePromotion(null));
        }
    };
    const activeProduct = activeCartItem.product;
    if (!activeProduct) {
        return null;
    }

    if (!activeStore) {
        return null;
    }

    const desktopHeader = (
        <div className="sticky top-0 z-[100] hidden h-auto w-auto flex-col items-center justify-between bg-white md:flex">
            <div className=" flex h-14 items-center text-base font-semibold leading-normal text-stone-950">
                {activeProduct.name}
            </div>
            <Divider width={1} />
        </div>
    );

    if (isDesktop) {
        return (
            <Dialog open={productModal} onOpenChange={handleHide}>
                <DialogContent
                    onEscapeKeyDown={handleHide}
                    onOpenAutoFocus={(e) => e.preventDefault()}
                    className="h-[100%]"
                >
                    {/* This following div wrapper is needed to keep scrolling inside the rounded borders. This addressed the bug radix UI has. */}
                    <div className="hidden-scroll flex h-full w-full flex-col  overflow-y-auto">
                        <div className="sticky top-0 z-[101] flex justify-self-end">
                            <DialogClose />
                        </div>

                        {desktopHeader}

                        {isDigitalGiftCard(activeProduct) ? (
                            <>
                                <DigitalGiftCard
                                    activeProduct={activeProduct}
                                    activeStore={activeStore}
                                    handleAddItemToCart={handleAddItemToCart}
                                />
                            </>
                        ) : (
                            <>
                                <div className="flex flex-col">
                                    {activeProduct.image && (
                                        <ProductImage
                                            activeProduct={activeProduct}
                                        />
                                    )}
                                    <div className="flex flex-col px-6 pb-3">
                                        <ProductName
                                            activeProduct={activeProduct}
                                            hidePoints={
                                                !!activeRewardPromo ||
                                                !!activeReward
                                            }
                                        />
                                        <ProductDescription
                                            activeProduct={activeProduct}
                                        />
                                        <div className="md:hidden">
                                            <QuantityWeightControl
                                                quantity={quantity}
                                                weight={weight}
                                                setQuantity={setQuantity}
                                                setWeight={setWeight}
                                            />
                                        </div>
                                        <FulfillmentDescription
                                            product={activeProduct}
                                        />
                                    </div>
                                </div>
                                <Divider width={8} />
                                <AddonGroups
                                    activeStore={activeStore}
                                    activeProduct={activeProduct}
                                    onToggleAddon={handleToggleAddon}
                                    onChangeAddonQuantity={
                                        handleChangeAddonQuantity
                                    }
                                    addonSelectionIsValidated={
                                        addonSelectionIsValidated
                                    }
                                    showRequiredAlerts={showRequiredAlerts}
                                />
                                <div className="px-6 pb-3 pt-6">
                                    <div className="text-lg font-semibold leading-normal text-stone-950">
                                        Preferences
                                    </div>
                                    <SpecialInstructions
                                        activeStore={activeStore}
                                        notes={activeCartItemNotes ?? ""}
                                        onChangeText={(notes) => {
                                            setActiveCartItemNotes(notes);
                                        }}
                                    />
                                </div>
                                <FooterAddToCartButton
                                    activeProduct={activeProduct}
                                    quantity={quantity}
                                    weight={weight}
                                    setQuantity={setQuantity}
                                    setWeight={setWeight}
                                    handleAddItemToCart={handleAddItemToCart}
                                />
                            </>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={productModal} onOpenChange={handleHide}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle> {activeProduct.name}</SheetTitle>
                </SheetHeader>
                <div className="overflow-auto pb-12">
                    {isDigitalGiftCard(activeProduct) ? (
                        <>
                            <DigitalGiftCard
                                activeProduct={activeProduct}
                                activeStore={activeStore}
                                handleAddItemToCart={handleAddItemToCart}
                            />
                        </>
                    ) : (
                        <>
                            <div className="flex flex-col">
                                {activeProduct.image && (
                                    <ProductImage
                                        activeProduct={activeProduct}
                                    />
                                )}
                                <div className="flex flex-col px-6 pb-3">
                                    <ProductName
                                        activeProduct={activeProduct}
                                        hidePoints={
                                            !!activeRewardPromo ||
                                            !!activeReward
                                        }
                                    />
                                    <ProductDescription
                                        activeProduct={activeProduct}
                                    />
                                    <div className="md:hidden">
                                        <QuantityWeightControl
                                            quantity={quantity}
                                            weight={weight}
                                            setQuantity={setQuantity}
                                            setWeight={setWeight}
                                        />
                                    </div>
                                    <FulfillmentDescription
                                        product={activeProduct}
                                    />
                                </div>
                            </div>
                            <Divider width={8} />
                            <AddonGroups
                                activeStore={activeStore}
                                activeProduct={activeProduct}
                                onToggleAddon={handleToggleAddon}
                                onChangeAddonQuantity={
                                    handleChangeAddonQuantity
                                }
                                addonSelectionIsValidated={
                                    addonSelectionIsValidated
                                }
                                showRequiredAlerts={showRequiredAlerts}
                            />
                            <div className="px-6 pb-3 pt-6">
                                <div className="text-lg font-semibold leading-normal text-stone-950">
                                    Preferences
                                </div>
                                <SpecialInstructions
                                    activeStore={activeStore}
                                    notes={activeCartItemNotes ?? ""}
                                    onChangeText={(notes) => {
                                        setActiveCartItemNotes(notes);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
                {!isDigitalGiftCard(activeProduct) ? (
                    <FooterAddToCartButton
                        activeProduct={activeProduct}
                        quantity={quantity}
                        weight={weight}
                        setQuantity={setQuantity}
                        setWeight={setWeight}
                        handleAddItemToCart={handleAddItemToCart}
                    />
                ) : null}
            </SheetContent>
        </Sheet>
    );
};
export default ProductModal;
