import type { Axios as AxiosClient } from "axios";
import { ResultAsync } from "neverthrow";

import {
    AnonymousAddonGroup,
    IProduct,
    IProductCategory
} from "@snackpass/snackpass-types";

// TODO: Maybe move this to snackpass-types?
export type NormalizedLegacyMenu = {
    categories: IProductCategory[];
    cateringCategories: IProductCategory[];
    products: Array<Omit<IProduct, "addonGroups"> & { addonGroups: string[] }>;
    addonGroups: Record<
        string,
        {
            id: string;
            ag: AnonymousAddonGroup;
            pRef: Record<string, { id: string; addons: string[] }>;
        }
    >;
    success: boolean;
};

export type LegacyMenuClient = {
    fetchMenu: (storeID: string) => ResultAsync<NormalizedLegacyMenu, Error>;

    sellOutProduct: (id: string, until?: Date) => ResultAsync<IProduct, Error>;

    restockProduct: (id: string) => ResultAsync<IProduct, Error>;

    sellOutAddons: (
        name: string,
        storeID: string,
        until?: Date
    ) => ResultAsync<IProduct[], Error>;

    restockAddons: (
        name: string,
        storeID: string
    ) => ResultAsync<IProduct[], Error>;
};

export const legacyMenuClient = (
    axios: AxiosClient,
    { channel, host }: { channel: string; host: string }
): LegacyMenuClient => ({
    fetchMenu: (storeID: string): ResultAsync<NormalizedLegacyMenu, Error> =>
        ResultAsync.fromPromise(
            axios.get<NormalizedLegacyMenu>(`${host}/api/v4/mul/menus/legacy`, {
                params: {
                    storeID,
                    channel
                }
            }),
            (e) => new Error(`Failed to fetch legacy menu: ${e}`)
        ).map((res) => res.data),

    sellOutProduct: (id: string, until?: Date) =>
        ResultAsync.fromPromise(
            axios.put<{ product: IProduct }>(`${host}/api/v4/products/${id}`, {
                soldOut: true,
                soldOutDates: until
                    ? {
                          from: new Date(),
                          until
                      }
                    : { from: null, until: null }
            }),
            (e) => new Error(`Failed to sell out product: ${e}`)
        ).map((res) => res.data.product),

    restockProduct: (id: string) =>
        ResultAsync.fromPromise(
            axios.put<{ product: IProduct }>(`${host}/api/v4/products/${id}`, {
                soldOut: false,
                soldOutDates: { from: null, until: null }
            }),
            (e) => new Error(`Failed to restock product: ${e}`)
        ).map((res) => res.data.product),

    sellOutAddons: (name: string, storeID: string, until?: Date) =>
        ResultAsync.fromPromise(
            axios.put(
                `${host}/api/v4/products/addons/${encodeURIComponent(name)}?storeId=${storeID}`,
                {
                    soldOut: true,
                    soldOutDates: until
                        ? {
                              from: new Date(),
                              until
                          }
                        : { from: null, until: null }
                }
            ),
            (e) => new Error(`Failed to sell out addons: ${e}`)
        ).map((res) => res.data.products),

    restockAddons: (name: string, storeID: string) =>
        ResultAsync.fromPromise(
            axios.put<{ products: IProduct[] }>(
                `${host}/api/v4/products/addons/${encodeURIComponent(name)}?storeId=${storeID}`,
                {
                    soldOut: false,
                    soldOutDates: { from: null, until: null }
                }
            ),
            (e) => new Error(`Failed to restock addons: ${e}`)
        ).map((res) => res.data.products)
});
