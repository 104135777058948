import { gql } from "graphql-request";
import { GQL } from "src/utils/Api/graphql/client";

export type IndividualMarketingConsentInfo = {
    id: string;
    marketingConsent: boolean;
};

export class Messaging {
    static async getIndividualMarketingConsentInfo(
        storeId: string,
        purchaseId?: string,
        authToken?: string,
    ): Promise<IndividualMarketingConsentInfo> {
        const query = gql`
            query individualMarketingConsentInfo(
                $storeId: ID!
                $purchaseId: String
                $authToken: String
            ) {
                individualMarketingConsentInfo(
                    storeId: $storeId
                    purchaseId: $purchaseId
                    authToken: $authToken
                ) {
                    id
                    marketingConsent
                }
            }
        `;

        try {
            const { individualMarketingConsentInfo } = await GQL.request(
                query,
                {
                    storeId,
                    purchaseId,
                    authToken,
                },
            );
            return individualMarketingConsentInfo;
        } catch (error) {
            if (
                error.response &&
                error.response.errors &&
                error.response.errors[0]
            ) {
                throw error.response?.errors[0];
            } else {
                throw new Error("An unknown error occurred: " + error);
            }
        }
    }

    static async optInToMarketing(
        storeId: string,
        purchaseId?: string,
        authToken?: string,
    ): Promise<boolean> {
        const query = gql`
            mutation optInToMarketing(
                $storeId: ID!
                $purchaseId: String
                $authToken: String
            ) {
                optInToMarketing(
                    storeId: $storeId
                    purchaseId: $purchaseId
                    authToken: $authToken
                )
            }
        `;

        try {
            const { optInToMarketing } = await GQL.request(query, {
                storeId,
                purchaseId,
                authToken,
            });
            return optInToMarketing;
        } catch (error) {
            throw error.response.errors[0];
        }
    }
}
