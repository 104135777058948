import { Button } from "@/components/ui/button";
import React, { useCallback, useState } from "react";
import Cleave from "cleave.js/react";
import DigitalGiftCardFormHeader from "./DigitalGiftCardFormHeader";
import {
    IProduct,
    MAX_GIFTCARD_AMOUNT_CENTS,
    MIN_GIFTCARD_AMOUNT_CENTS,
} from "@snackpass/snackpass-types";
import _ from "lodash";
import { cn } from "@/utils";
import { onlyDigits } from "@/utils/Helpers";
import { standardInputClassNames } from "@/components/ui/input";
import { useFormContext } from "react-hook-form";

const PRE_CANNED_PRICE_OPTIONS = [10, 20, 50, 100];
type Props = {
    activeProduct: IProduct;
    updatePrice: (price: number) => void;
};

function DigitalGiftCardPrice({ activeProduct, updatePrice }: Props) {
    const { setValue, getFieldState } = useFormContext();
    const price = activeProduct.price;
    const isCustomPrice =
        price != null && !PRE_CANNED_PRICE_OPTIONS.includes(price);
    const [customPriceInternalString, setCustomPriceInternalString] = useState(
        isCustomPrice ? price.toString() : "",
    );
    const onChangeCustomPrice = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomPriceInternalString(event.target.value);
            const floatValue = parseFloat(onlyDigits(event.target.value));
            if (!_.isNaN(floatValue)) {
                updatePrice(floatValue);
            } else {
                updatePrice(0);
            }
        },
        [updatePrice],
    );
    return (
        <>
            <DigitalGiftCardFormHeader label={"Choose an amount"} />
            <div className="flex flex-col gap-3">
                <div className="flex flex-1 flex-wrap justify-between gap-2">
                    {PRE_CANNED_PRICE_OPTIONS.map((priceOption) => (
                        <Button
                            key={priceOption}
                            className="flex-1 text-base w-1/2"
                            onPress={(e) => {
                                setCustomPriceInternalString(""); // reset cleave state
                                setValue("price", priceOption, {
                                    shouldValidate: true,
                                }); // ensure a button click causes the form to be valid for price
                                updatePrice(priceOption);
                                e.preventDefault(); // Stop the form from submitting from this button
                            }}
                            label={
                                <div
                                    className={"min-w-[36px]"}
                                >{`$${priceOption}`}</div>
                            }
                            variant={
                                priceOption === price ? "secondary" : "outline"
                            }
                            size={"tall"}
                        />
                    ))}
                </div>
                <div className="relative h-[40px]">
                    <Cleave
                        placeholder={`Custom ($${
                            MIN_GIFTCARD_AMOUNT_CENTS / 100
                        } - $${MAX_GIFTCARD_AMOUNT_CENTS / 100})`}
                        options={{ numeral: true, numeralPositiveOnly: true }}
                        className={cn(
                            standardInputClassNames,
                            "px-5",
                            isCustomPrice ? "border-accent" : "border-input",
                            getFieldState("price").error
                                ? "border-destructive text-destructive bg-destructive/10"
                                : "",
                        )}
                        onChange={onChangeCustomPrice}
                        value={customPriceInternalString}
                        onBlur={() => {
                            setValue("price", price, {
                                shouldValidate: true,
                                shouldTouch: true,
                            });
                        }}
                        // https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input
                        // Make phone numbers show the keyboard with type + pattern + inputMode
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                    />
                    <span
                        className={cn(
                            "absolute left-2 top-[25%] font-normal text-muted-foreground",
                            getFieldState("price").error
                                ? "text-destructive"
                                : "",
                        )}
                    >
                        $
                    </span>
                </div>
            </div>
        </>
    );
}

export default DigitalGiftCardPrice;
