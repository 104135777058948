import { IStore, PickupEstimate } from "@snackpass/snackpass-types";
import { Helpers } from "../../utils";
import _ from "lodash";
import { isBrowser } from "react-device-detect";
import Api from "src/utils/Api/REST";
import { prop } from "lodash/fp";
import { Time } from "@snackpass/accounting";

export function getCategoryName(category: string) {
    return isBrowser ? Helpers.shorten(category, 6, "...") : category;
}

const toReadable = (prop: string[] | undefined) => {
    try {
        return prop ? parseFloat((prop[0] as string).split(": ")[1]) : -1;
    } catch (error) {
        return -1;
    }
};

export const fetchPickupEstimate = async (params: {
    cartItemsCount: number;
    activeStore: IStore;
    basePrice?: string;
    totalPrice?: string;
}): Promise<PickupEstimate | null> => {
    try {
        const { data } = await Api.stores.getPickupEstimate(
            params.cartItemsCount,
            params.activeStore._id,
            params.basePrice,
            params.totalPrice,
        );
        const details = data.pickupEstimate;
        return details
            ? ({
                  storeId: params.activeStore._id,
                  itemCount: params.cartItemsCount,
                  prediction: toReadable(details[0]),
                  month: toReadable(details[1]),
                  dayOfWeek: toReadable(details[2]),
                  hourOfDay: toReadable(details[3]),
                  concurrentOrders: toReadable(details[4]),
                  meanStorePickupTime: toReadable(details[5]),
                  timeToServeInference: toReadable(details[6]),
              } as PickupEstimate)
            : null;
    } catch (error) {
        throw new Error(error);
    }
};

export const calculateDisplayedPickupTime = (rawTime: number): string => {
    if (rawTime < 0) {
        return "";
    }

    const hours: number = Math.floor(rawTime / 60);
    const remainingMinutes: number = Math.round(rawTime % 60); // Round to nearest minute

    if (hours === 0 && remainingMinutes === 0) {
        return "";
    }

    let result = "";
    if (hours > 0) {
        result += `${hours} h `;
    }
    if (remainingMinutes > 0) {
        if (result !== "") {
            result += " ";
        }
        result += `${remainingMinutes} ${remainingMinutes === 1 ? "min" : "mins"}`;
    }

    return result.trim();
};

export const getPickupEstTimeString = (
    pickupEstimateData: PickupEstimate | undefined,
    activeStore: IStore,
) => {
    const isRushHourUntil = Time.storeIsRushHourUntil(activeStore);
    if (isRushHourUntil) {
        const rushHourDelayMins = prop("rushHourDelay", activeStore);
        const estimateTime = rushHourDelayMins
            ? `${rushHourDelayMins} mins`
            : "Expect a longer wait";
        return estimateTime;
    }

    if (!pickupEstimateData) {
        //return the estimate time in RDB settings
        return activeStore.pickupTimeType === "specific" &&
            activeStore.defaultPickupTime
            ? `${activeStore.defaultPickupTime} mins`
            : activeStore.defaultPickupMaxTime
              ? `${activeStore.defaultPickupMinTime}-${activeStore.defaultPickupMaxTime} mins`
              : "";
    } else if (
        pickupEstimateData &&
        pickupEstimateData.storeId === activeStore._id // Need to make sure pickup est time is for the current store
    ) {
        const pickuptimeEstimateTime =
            _.get(pickupEstimateData, "prediction", -1) !== -1
                ? calculateDisplayedPickupTime(pickupEstimateData!.prediction)
                : "";
        return pickuptimeEstimateTime;
    }
    return "";
};
