import React, { useContext } from "react";
import { CartSelectors } from "@snackpass/accounting";
import { Fulfillment, FulfillmentTypeEnum } from "@snackpass/snackpass-types";
import ToggleSlider from "@/components/ui/toggleSlider";
import { useSelector } from "react-redux";
import { getStoreFulfillmentOptions } from "@/redux";
import { FulfillmentModalContext } from "../FulfillmentModalContext";
import { Helpers } from "@/utils";

type Option = {
    value: string;
    label: string;
};

const formatFulfillmentOptions = (
    fulfillment: Fulfillment[],
    isCatering: boolean,
): Option[] => {
    return fulfillment
        .filter(
            (fulfill) =>
                !(isCatering && fulfill === FulfillmentTypeEnum.DineIn),
        )
        .map((fulfill) => {
            return {
                value: fulfill,
                label: Helpers.capitalize(fulfill.replace("_", "-")),
            };
        });
};

export type FulfillmentToggleProps = {
    name: string;
};

const FulfillmentToggle: React.FC<FulfillmentToggleProps> = ({ name }) => {
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const fulfillmentOptions = useSelector(getStoreFulfillmentOptions);

    const { modalFulfillment, setModalFulfillment } = useContext(
        FulfillmentModalContext,
    );
    const formattedFulfillmentOptions = formatFulfillmentOptions(
        fulfillmentOptions,
        isCatering,
    );
    const handleToggle = (val: string) => {
        const fulfillment = val as Fulfillment;
        switch (fulfillment) {
            case FulfillmentTypeEnum.Pickup:
                setModalFulfillment(FulfillmentTypeEnum.Pickup);
                break;
            case FulfillmentTypeEnum.Delivery:
                setModalFulfillment(FulfillmentTypeEnum.Delivery);
                break;
            case FulfillmentTypeEnum.DineIn:
                setModalFulfillment(FulfillmentTypeEnum.DineIn);
                break;
        }
    };

    if (fulfillmentOptions.length < 2) {
        return (
            <div className="flex flex-row items-center justify-center pt-3">
                {formattedFulfillmentOptions.length > 0
                    ? ` Store only support ${formattedFulfillmentOptions[0]?.label.toLowerCase()}`
                    : ""}
            </div>
        );
    }

    return (
        <div className="flex h-[40px] flex-row space-x-1 rounded-lg bg-gray-200 p-[1px]">
            <ToggleSlider
                small
                name={name}
                options={formattedFulfillmentOptions}
                value={modalFulfillment || ""}
                onValueChange={handleToggle}
            />
        </div>
    );
};

export default FulfillmentToggle;
