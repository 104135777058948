import React from "react";
import { useSelector } from "react-redux";
import { ActiveCartItemSelectors } from "@snackpass/accounting";
import {
    useActiveRewardPromo,
    useActiveReward,
} from "@snackpass/loyalty-react";

import { getIsEditCartItem, getActivePromotion } from "@/redux";
import { isDigitalGiftCard } from "./DigitalGiftCard/isDigitalGiftCard";
import WeightSelector from "./WeightSelector";
import QuantitySelector from "./QuantitySelector";

type QuantityWeightControlProp = {
    weight: number;
    quantity: number;
    setWeight: React.Dispatch<React.SetStateAction<number>> | undefined;
    setQuantity: React.Dispatch<React.SetStateAction<number>> | undefined;
};

const QuantityWeightControl: React.FC<QuantityWeightControlProp> = ({
    weight,
    quantity,
    setQuantity,
    setWeight,
}) => {
    const activeRewardPromo = useActiveRewardPromo();
    const activeReward = useActiveReward();
    const activePromotion = useSelector(getActivePromotion);

    const activeCartItem = useSelector(
        ActiveCartItemSelectors.getActiveCartItem,
    );
    const editCartItem = useSelector(getIsEditCartItem);
    const activeProduct = activeCartItem.product;

    if (
        !activeProduct ||
        activeRewardPromo ||
        activeReward ||
        editCartItem ||
        isDigitalGiftCard(activeProduct) ||
        activePromotion
    ) {
        return null;
    }
    return activeProduct.priceByWeight ? (
        <div className="pr-6">
            <WeightSelector
                weight={weight}
                unit={activeProduct.priceByWeight.unit}
                onChangeWeight={(newWeight) => {
                    setWeight &&
                        setWeight(Math.floor(Math.abs(newWeight) * 100) / 100);
                }}
            />
        </div>
    ) : (
        <div className="pr-6">
            <QuantitySelector
                product={activeProduct}
                quantity={quantity}
                onChangeQuantity={(newQuantity) => {
                    setQuantity && setQuantity(newQuantity);
                }}
            />
        </div>
    );
};

export default QuantityWeightControl;
