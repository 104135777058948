import React, { useMemo } from "react";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";
import {} from "@snackpass/loyalty-react";
import { optimizedImageURL } from "@/utils/Helpers";
import { IPromotion, PromoTypes } from "@snackpass/snackpass-types";
import { getDiscountText, getDiscountConditions } from "./lib";
import { cn } from "@/utils";
import { ReactComponent as TagIcon } from "src/assets/icons/tag.svg";
import { usePromoItemProductsAndImages } from "@/hooks/usePromoItemProductsAndImages";
import {
    ModalsName,
    getUserIsLoggedIn,
    setActiveDealItemsMap,
    setActivePromotion,
    showModal,
} from "@/redux";
import { useDispatch, useSelector } from "react-redux";
import { Segment, SegmentEventNames } from "src/utils/Segment";
import { usePromotionValidator } from "@/hooks/usePromotionValidator";
import { DealSelectionActions } from "@snackpass/accounting";
import { toast } from "sonner";
import { useAutoOpenDGCProduct } from "@/hooks/useAutoOpenDGCProduct";
import { useIsDGCEnabled } from "@/hooks/useIsDGCEnabled";

type Props = {
    promotion: IPromotion;
    previewMode?: boolean;
};

export default function PromoCard({ promotion, previewMode }: Props) {
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
    const { isNewDGCExperience } = useIsDGCEnabled();
    const { openDGCModal } = useAutoOpenDGCProduct({
        checkOnInitialLoad: false,
    });
    const discountText = useMemo(
        () => getDiscountText(promotion),
        [promotion._id],
    );
    const discountConditions = useMemo(
        () => getDiscountConditions(promotion),
        [promotion._id],
    );
    const { getPromotionErrorReason, validateAddPromotion } =
        usePromotionValidator();
    const dispatch = useDispatch();
    const { availableProductImages, numberOfEligibleItem } =
        usePromoItemProductsAndImages(promotion);
    const handlePress = () => {
        if (!isUserLoggedIn) {
            dispatch(showModal(ModalsName.dealAndDiscountPreviewModal));
            return;
        }
        const promotionCheck = validateAddPromotion(promotion);

        // promotionCheck.validated might be false, but we allow add to cart first and validate at the checkout screen
        if (promotionCheck.allowAddToCart) {
            dispatch(setActivePromotion(promotion));
            if (promotion.type === PromoTypes.GiftCard) {
                if (isNewDGCExperience) {
                    openDGCModal();
                }
            } else if (promotion.type === PromoTypes.Deal) {
                dispatch(
                    DealSelectionActions.setActiveDealItem(
                        promotion.dealItems[0],
                    ),
                );
                dispatch(setActiveDealItemsMap(promotion.dealItems));
                dispatch(showModal(ModalsName.dealModal));
            } else if (
                promotion.categories.length ||
                promotion.productIds.length ||
                promotion.storewide
            ) {
                dispatch(showModal(ModalsName.discountModal));
            }
        } else {
            toast.error(`${getPromotionErrorReason(promotionCheck.reason)}`);
            return;
        }
        Segment.track(SegmentEventNames.PROMO_PRESSED, {
            promoId: promotion?._id,
        });
    };

    if (isSmallScreen) {
        return (
            <div
                onClick={previewMode ? undefined : handlePress}
                className={cn(
                    "min-h-[50px] relative flex h-fit pr-4 min-w-[355px] cursor-pointer flex-row justify-between overflow-hidden border-b bg-white",
                    {
                        "opacity-50 !cursor-default": previewMode,
                    },
                )}
            >
                <div className="mr-1.5 flex flex-row whitespace-pre-line basis-2/3">
                    <div className="flex flex-col">
                        <p className="mt-4 mb-1 line-clamp-2 text-ellipsis text-base font-semibold text-primary lg:mx-4 lg:mt-4 lg:font-semibold">
                            {promotion.name}
                        </p>

                        {!_.isEmpty(discountConditions) && (
                            <div className="line-clamp-2 overflow-hidden text-ellipsis break-words text-sm font-normal text-secondary lg:mx-4 pb-2">
                                {discountConditions.join(", ")}
                            </div>
                        )}
                        {!_.isEmpty(discountText) && (
                            <div className="line-clamp-2 overflow-hidden text-ellipsis break-words text-sm font-normal text-secondary lg:mx-4 pb-2">
                                {discountText}
                            </div>
                        )}
                        <div className="mt-auto mb-3 inline-block text-sm font-normal leading-5 rounded-md bg-brandColor w-fit px-2 py-1 text-white">
                            {numberOfEligibleItem} eligible Items
                        </div>
                    </div>
                </div>
                <div className="my-4 flex flex-row items-start justify-center relative w-[96px] h-[96px]">
                    {[0, 1, 2].map((ele, i) => {
                        const img: string = availableProductImages[i] ?? "";
                        if (img) {
                            return (
                                <div
                                    key={i}
                                    className={cn(
                                        "border bg-gray-50 border-gray-200 border-opacity-50 absolute top-0 left-0 flex flex-col items-start rounded-xl text-right lg:rounded-l-none lg:rounded-r-xl",
                                        {
                                            "z-[2]": i === 0,
                                            "rotate-[-6deg] z-[1]": i === 1,
                                            "rotate-[-12deg]": i === 2,
                                        },
                                    )}
                                    style={{
                                        backgroundImage: `url("${optimizedImageURL(
                                            img,
                                        )}")`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: 96,
                                        height: 96,
                                    }}
                                />
                            );
                        } else {
                            return (
                                <div
                                    key={i}
                                    className={cn(
                                        "border bg-gray-50 border-gray-200 border-opacity-50 absolute top-0 left-0 flex flex-col items-start rounded-xl text-right lg:rounded-l-none lg:rounded-r-xl",
                                        {
                                            "z-[2]": i === 0,
                                            "rotate-[-6deg] z-[1]": i === 1,
                                            "rotate-[-12deg]": i === 2,
                                        },
                                    )}
                                >
                                    <div className="bg-gray-50 w-[96px] h-[96px] flex items-center justify-center rounded-xl lg:rounded-l-none lg:rounded-r-xl">
                                        <TagIcon className="w-8 h-8 fill-gray-400" />
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }

    return (
        <div
            className={cn("relative h-[182px] min-h-[50px]  min-w-[355px]", {
                "opacity-50 !cursor-default": previewMode,
            })}
        >
            {[0, 1, 2].map((e, i) => {
                const img: string = availableProductImages[i] ?? "";
                return (
                    <div
                        key={i}
                        onClick={previewMode ? undefined : handlePress}
                        className={cn(
                            "absolute flex h-fit cursor-pointer flex-row justify-between overflow-hidden border-b bg-white lg:mb-0 lg:h-[158px] rounded-2xl lg:border",
                            {
                                "top-3 left-0 right-0 z-[2]": i === 0,
                                "top-1.5 left-1.5 right-1.5 z-[1]": i === 1,
                                "top-0 left-3 right-3": i === 2,
                                "!cursor-default": previewMode,
                            },
                        )}
                    >
                        <div className="mr-1.5 flex flex-row whitespace-pre-line">
                            <div className="flex flex-col">
                                <p className="mb-1 line-clamp-2 text-ellipsis text-base font-semibold text-primary lg:mx-4 lg:mt-4 lg:font-semibold">
                                    {promotion.name}
                                </p>

                                {!_.isEmpty(discountConditions) && (
                                    <div className="line-clamp-2 overflow-hidden text-ellipsis break-words text-sm font-normal text-secondary lg:mx-4">
                                        {discountConditions.join(", ")}
                                    </div>
                                )}
                                {!_.isEmpty(discountText) && (
                                    <div className="line-clamp-2 overflow-hidden text-ellipsis break-words text-sm font-normal text-secondary lg:mx-4">
                                        {discountText}
                                    </div>
                                )}
                                <div className="mt-auto ml-4 mb-3 inline-block text-sm font-normal leading-5 rounded-md bg-brandColor w-fit px-2 py-1 text-white">
                                    {numberOfEligibleItem} eligible Items
                                </div>
                            </div>
                        </div>
                        {img ? (
                            <div className="my-4 flex flex-row items-start justify-center lg:my-0">
                                <div
                                    className={
                                        "bg-gray-50 flex flex-col items-start overflow-hidden rounded-xl text-right lg:rounded-l-none lg:rounded-r-xl"
                                    }
                                    style={{
                                        backgroundImage: `url("${optimizedImageURL(
                                            img,
                                        )}")`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: 158,
                                        height: 158,
                                    }}
                                ></div>
                            </div>
                        ) : (
                            <div className="my-4 flex flex-row items-start justify-center lg:my-0">
                                <div
                                    className={
                                        "flex flex-col items-start overflow-hidden rounded-xl text-right lg:rounded-l-none lg:rounded-r-xl"
                                    }
                                >
                                    <div className="bg-gray-50 w-[158px] h-[158px] flex items-center justify-center">
                                        <TagIcon className="w-20 h-20 fill-gray-400" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
