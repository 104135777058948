import { getActiveChain, getActiveChainStores } from "@/redux";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export function useChainStores() {
    const activeChainStores = useSelector(getActiveChainStores);
    const activeChain = useSelector(getActiveChain);

    const memoizedStores = useMemo(() => {
        return activeChainStores.filter(
            (store) => activeChain && store._id !== activeChain.rootStore,
        );
    }, [activeChainStores, activeChain]);

    return memoizedStores;
}
