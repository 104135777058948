import {
    Geolocation,
    IDeliveryRange,
    IStore,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";
import { compose, defaultTo, prop, propOr } from "lodash/fp";
import { createSelector } from "reselect";
import { calcInDeliveryZone, getDistanceBetweenGeopoints } from "../../utils";
import { getSelectedCartDeliveryQuote } from "../cart/selectors";
import { getActiveStore, getMyLocation } from "./globals";

export const getActiveStoreId = createSelector(
    [getActiveStore],
    propOr("", "_id")
);

export const getActiveStoreName = createSelector(
    [getActiveStore],
    propOr("", "name")
);

export const getActiveStoreEmoji = createSelector(
    [getActiveStore],
    propOr("", "emoji")
);

export const getActiveStoreHours = createSelector(
    [getActiveStore],
    propOr({ zone: SnackpassTimezoneEnum.newYork, local: [] }, "hours")
);

export const getActiveStoreRegion = createSelector(
    [getActiveStore],
    propOr("", "region")
);

export const getActiveStorePickupMin = createSelector(
    [getActiveStore],
    compose(defaultTo(0), prop("pickupMin"))
);

/**
 *
 * *************************************
 * *********** Delivery Info ***********
 * *************************************
 *
 */

/**
 * Get delivery range you are currently in for store
 */

//Delivery ranges in miles if delivery storeObj does not have 'deliveryRanges' field
export const DEFAULT_DELIVERY_RANGES = [
    {
        start: 0,
        end: 1,
        deliveryFee: 0,
        deliveryMin: 8
    },
    {
        start: 1,
        end: 3,
        deliveryFee: 3.5,
        deliveryMin: 10
    },
    {
        start: 3,
        end: 5,
        deliveryFee: 5,
        deliveryMin: 8
    }
];

export function getDeliveryRange(
    store: IStore | null,
    distance: number,
    location: Geolocation
): Partial<IDeliveryRange> {
    // Handles the null store case
    if (!store) {
        return {
            deliveryMin: 0,
            deliveryFee: 0
        };
    }

    let applicableRange: IDeliveryRange = {
        deliveryMin: store.deliveryMin || 0,
        deliveryFee: store.deliveryFee || 0,
        start: 0,
        end: 0
    };

    if (!store.deliveryRanges) {
        return applicableRange;
    }

    store.deliveryRanges.forEach((range, i) => {
        const inRange = range.end > distance && distance >= range.start;
        const inZone = calcInDeliveryZone(location, store);
        const inBestRangeForZone =
            inZone && i === store.deliveryRanges.length - 1;

        // It's possible the deliveryRanges for the store don't fully
        // encompass a custom delivery zone. In that case, apply the
        // rules for the last range.
        if (inRange || inBestRangeForZone) {
            applicableRange = range;
        }
    });

    return applicableRange;
}

export const getDistanceFromActiveStore = createSelector(
    [getActiveStore, getMyLocation],
    (activeStore, myLocation) =>
        activeStore
            ? getDistanceBetweenGeopoints(activeStore.geolocation, myLocation)
            : 0
);

/**
 * Get delivery range of active store
 */

export const getActiveDeliveryRange = createSelector(
    [
        getActiveStore,
        getDistanceFromActiveStore,
        getMyLocation,
        getSelectedCartDeliveryQuote
    ],
    (activeStore, distance, location, quote): Partial<IDeliveryRange> =>
        (quote && quote.deliveryRange) ||
        getDeliveryRange(activeStore, distance, location)
);

/**
 * Get delivery fee of active store
 */
export const getActiveStoreDeliveryFee = createSelector(
    [getActiveDeliveryRange],
    prop("deliveryFee")
);

export const getActiveStoreDeliveryMin = createSelector(
    [getActiveDeliveryRange],
    propOr(0, "deliveryMin")
);

export const getActiveStoreConvenienceFeePolicies = createSelector(
    [getActiveStore],
    propOr([], "convenienceFeePolicies")
);

export const getActiveStoreFeePolicies = createSelector(
    [getActiveStore],
    propOr([], "feePolicies")
);
