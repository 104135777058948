import { fork } from "typed-redux-saga";

import { activeStoreRootSaga } from "#activeStore";
import { cartRootSaga } from "#cart";
import { menuRootSaga } from "#menu";

export function* rootSaga() {
    // Starts the root saga for the `activeStore` module.
    yield* fork(activeStoreRootSaga);

    // Starts the root saga for the `menu` module.
    yield* fork(menuRootSaga);

    // Starts the root saga for the `cart` module.
    yield* fork(cartRootSaga);
}
