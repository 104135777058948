import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ModalsName, getActiveStore, showModal } from "@/redux";
import { useAppDispatch, useAppSelector } from "@/redux/utils";
import { selectHasConflicts } from "#cart/selectors";

/**
 * This prevents users from going to the checkout screen if there
 * are fulfillment conflicts
 * */
export const useNavigateToCart = () => {
    const history = useHistory();
    const location = useLocation(); // Get current location
    const currentQuery = location.search; // This will give you the current query string, e.g., "?sort=asc"
    const dispatch = useAppDispatch();
    const hasConflicts = useAppSelector(selectHasConflicts);
    const activeStore = useAppSelector(getActiveStore);

    const navigateToCart = useCallback(() => {
        if (!activeStore) {
            return;
        }

        if (hasConflicts) {
            dispatch(showModal(ModalsName.cartConflictsModal));
        } else {
            // Append the current query to the new route
            history.push(`/${activeStore?.slug}/cart${currentQuery}`);
        }
    }, [activeStore, hasConflicts, history, currentQuery, dispatch]);

    const navigateToStore = useCallback(() => {
        if (!activeStore) {
            return;
        }
        // Append the current query to the new route
        history.push(`/${activeStore?.slug}${currentQuery}`);
    }, [activeStore, history, currentQuery]);

    return { navigateToCart, navigateToStore };
};
