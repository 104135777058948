import { useCallback, useEffect } from "react";
import moment from "moment";
import { isOpenLocal, isTodaySpecial, storeOpenUntil } from "src/utils/Time";
import { cn } from "@/utils";
import { ModalsName, showModal } from "@/redux";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "../ui/button";
import { ReactComponent as StoreIcon } from "src/assets/icons/store-fill.svg";
import { fetchActiveStore, selectActiveStore } from "#activeStore";
import { useAppDispatch, useAppSelector } from "@/redux/utils";
import { IStore } from "@snackpass/snackpass-types";

const StoreHours = () => {
    const activeStore = useAppSelector(selectActiveStore);
    const dispatch = useAppDispatch();
    const handleShowStoreHoursModal = useCallback(() => {
        dispatch(showModal(ModalsName.storeHoursModal));
    }, [dispatch]);

    const fetchLatestStore = useCallback(() => {
        if (activeStore._id) {
            dispatch(fetchActiveStore({ storeIDorSlug: activeStore._id }));
        }
    }, [activeStore._id, dispatch]);

    useEffect(() => {
        // We currently poll every minute for store updates.
        const interval = setInterval(fetchLatestStore, 60 * 1000);
        return () => {
            clearTimeout(interval);
        };
    }, [fetchLatestStore]);

    return (
        <div className="width-full">
            {activeStore.closedUntil ? (
                <ClosedUntil
                    date={activeStore.closedUntil}
                    onPress={handleShowStoreHoursModal}
                />
            ) : (
                <Message
                    hours={activeStore.hours}
                    onPress={handleShowStoreHoursModal}
                />
            )}
        </div>
    );
};

type ClosedUntilProps = {
    date: Date;
    onPress: () => void;
};

const ClosedUntil = ({ date, onPress }: ClosedUntilProps) => {
    // XXX: `date` is most likely a string, not a Date object so we are being defensive here.
    const momentClosedUntil = moment(date);
    const isToday = momentClosedUntil.isSame(moment(), "day");
    const formattedDate = momentClosedUntil.format(
        isToday ? "h:mm a" : "h:mm a, MMM Do",
    );

    return (
        <Button
            className="px-0 py-0 w-[100%] h-[100%] bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] shadow-default transition-shadow duration-500 ease-in-out hover:shadow-hover rounded-xl active:shadow-focus active:scale-95 border"
            variant="ghost"
            onPress={onPress}
            label={
                <div className="flex flex-col md:flex-row flex-1 py-3 md:py-0 md:items-center md:justify-between h-[94px] md:h-[64px] rounded-xl pl-3 pr-2 md:pr-4">
                    <div className="basis-1/8 pb-2 md:pb-0">
                        <StoreIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                    </div>
                    <div className="basis-6/8 w-full text-start px-0 md:px-3">
                        <div className="text-start leading-5 whitespace-nowrap text-primary text-base font-semibold">
                            Closed
                        </div>
                        <div className="text-start overflow-hidden text-sm font-normal leading-4 text-secondary">
                            Until {formattedDate}
                        </div>
                    </div>
                    <div className="basis-1/8 hidden md:block">
                        <ChevronRightIcon className="w-4 h-4 text-[#999DA3]" />
                    </div>
                </div>
            }
        />
    );
};

type MessageProps = {
    hours: IStore["hours"];
    onPress: () => void;
};

const Message = ({ hours, onPress }: MessageProps) => {
    // If the store isn't open, show what time it opens the next day
    const isOpen = isOpenLocal(hours);
    const isSpecialDay = isTodaySpecial(hours);
    if (!isOpen) {
        return (
            <Button
                className="px-0 py-0 w-full h-full bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] shadow-default transition-shadow duration-500 ease-in-out hover:shadow-hover rounded-xl active:shadow-focus active:scale-95 border"
                variant="ghost"
                onPress={onPress}
                label={
                    <div className="flex flex-col md:flex-row flex-1 py-3 md:py-0 md:items-center md:justify-between h-[94px] md:h-[64px] rounded-xl pl-3 pr-2 md:pr-4">
                        <div className="basis-1/8 pb-2 md:pb-0">
                            <StoreIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                        </div>
                        <div className="basis-6/8 w-full text-start px-0 md:px-3">
                            <div className="flex items-start text-start text-base font-semibold text-primary leading-5">
                                {isSpecialDay ? "Special Hours" : null}
                            </div>
                            <div
                                className={cn(
                                    "text-start overflow-hidden text-clip leading-4 whitespace-nowrap",
                                    {
                                        "text-primary text-base font-semibold":
                                            !isSpecialDay,
                                        "text-secondary text-sm font-normal":
                                            isSpecialDay,
                                    },
                                )}
                            >
                                Closed
                            </div>
                        </div>
                        <div className="basis-1/8 hidden md:block">
                            <ChevronRightIcon className="w-4 h-4 text-[#999DA3]" />
                        </div>
                    </div>
                }
            />
        );
    }

    // Otherwise it is open so say when it is open till
    const openUntil = storeOpenUntil({ hours });

    return (
        <Button
            className="px-0 py-0 w-full h-full bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] transition-shadow duration-500 ease-in-out shadow-default hover:shadow-hover active:scale-95 rounded-xl active:shadow-focus border"
            variant="ghost"
            onPress={onPress}
            label={
                <div className="flex flex-col md:flex-row flex-1 py-3 md:py-0 md:items-center md:justify-between h-[94px] md:h-[64px] rounded-xl pl-3 pr-2 md:pr-4">
                    <div className="basis-1/8 pb-2 md:pb-0">
                        <StoreIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                    </div>
                    <div className="basis-6/8 w-full text-start px-0 md:px-3">
                        <div className="flex items-start text-base font-semibold text-primary">
                            {isOpenLocal(hours) ? "Open" : "Today's Hours"}
                        </div>
                        <div className="text-[14px] leading-4 font-normal text-left tracking-tight">
                            {openUntil}
                        </div>
                    </div>
                    <div className="basis-1/8 hidden md:block">
                        <ChevronRightIcon className="w-4 h-4 text-[#999DA3]" />
                    </div>
                </div>
            }
        />
    );
};

export default StoreHours;
