import { optimizedImageURL } from "@/utils/Helpers";
import { IStore } from "@snackpass/snackpass-types";
import React from "react";
import SettingsAlert from "src/SharedComponents/SettingsAlert";

type Props = {
    store: IStore | null;
};

/*
 * This component is very similar to DesktopStoreHeader, but supports mobile, and clicking the logo
 * goes to the store menu. Also we don't show the menu search and catering here.
 */
export default function GiftCardViewHeader({ store }: Props) {
    const logo = store?.logoUrl || store?.thumbnailUrl || "";
    const source = optimizedImageURL(logo, { size: { h: 64, w: 64 } });
    return (
        <div className="fixed left-0 right-0 top-0 z-[3] flex w-[100%] justify-center overflow-x-hidden border-b bg-white">
            <div className="flex h-[96px] w-[100%] max-w-[1280px] flex-row items-center justify-between py-6 pr-4">
                <div className="ml-4 flex w-[30%] flex-row items-center gap-4">
                    {source ? (
                        <a href={`/${store?.slug}`}>
                            <div
                                className="h-[64px] w-[64px] rounded-full bg-cover bg-center bg-no-repeat lg:h-[64px] lg:w-[64px]"
                                style={{
                                    backgroundImage: `url(${source})`,
                                }}
                            />
                        </a>
                    ) : null}
                </div>
                <div className="mr-4 flex flex-row items-center justify-center space-x-5">
                    <SettingsAlert />
                </div>
            </div>
        </div>
    );
}
