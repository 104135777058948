import React, { useState } from "react";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { SystemColors } from "@snackpass/design-system";
import { Button } from "@/components/ui/button";

export type StripePaymentFormProp = {
    confirmButtonText: string;
    isLoading: boolean;
    onCreatePaymentTokenSuccess: (
        paymentToken: any,
        cardLast4: string | undefined
    ) => void;
    onCreatePaymentTokenError: (e: any) => void;
};

const StripePaymentForm = ({
    confirmButtonText,
    isLoading,
    onCreatePaymentTokenSuccess,
    onCreatePaymentTokenError,
}: StripePaymentFormProp) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setIsLoading] = useState<boolean>(false);

    const onSubmitPayment = async () => {
        if (!stripe || !elements) {
            return;
        }

        try {
            setIsLoading(true);

            const cardElement = elements.getElement(CardElement);

            const result = await stripe.createToken(cardElement!);

            if (!result.error) {
                onCreatePaymentTokenSuccess(
                    result.token.id,
                    result.token.card?.last4
                );
            } else {
                onCreatePaymentTokenError(result.error.message);
            }
        } catch (err) {
            onCreatePaymentTokenError(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col gap-2 w-[100%] justify-center">
            <div className="px-2 py-3 border rounded-lg">
                <CardElement options={cardElementOptions} />
            </div>
            <Button
                onPress={onSubmitPayment}
                loading={loading || isLoading}
                label={confirmButtonText}
                disabled={!stripe}
            />
        </div>
    );
};

const cardElementOptions = {
    showIcon: true,
    hidePostalCode: false,
    style: {
        base: {
            fontSize: "16px",
            borderRadius: "8px",
            color: "#424770",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: SystemColors.melon60,
        },
    },
};

export default StripePaymentForm;
