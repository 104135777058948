import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPromotion, IReward } from "@snackpass/snackpass-types";
import { CartSelectors } from "@snackpass/accounting";
import { getCartItems } from "@snackpass/accounting/build/src/redux/cart/selectors";
import {
    cartItemFlatToPassthroughItem,
    rewardIsInCart,
} from "@snackpass/loyalty";
import {
    useSetRewardPromos,
    useSetRewards,
    useUserStoreRewards,
    useUserPassthroughRewardOptions,
    useActivePointsBalance,
} from "@snackpass/loyalty-react";
import { toast } from "sonner";

import {
    ModalsName,
    getUserIsLoggedIn,
    showModal,
    getActiveStore,
    getUser,
} from "@/redux";
import PromotionCard from "@/components/PromotionCard";
import { Divider } from "../../SharedComponents";
import { cn } from "@/utils";

const Rewards = () => {
    const { setActiveRewardPromo } = useSetRewardPromos();
    const { setActiveReward } = useSetRewards();
    const dispatch = useDispatch();

    const activeStore = useSelector(getActiveStore);
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const user = useSelector(getUser);
    const cart = useSelector(CartSelectors.getCart);
    const cartItems = useSelector(getCartItems);

    const pointsBalance = useActivePointsBalance(
        user?._id,
        activeStore?._id,
        cartItemFlatToPassthroughItem(cartItems),
    );
    const rewards = useUserStoreRewards(user?._id, activeStore?._id);

    const rewardOptions = useUserPassthroughRewardOptions(
        user?._id,
        activeStore?._id,
        cartItemFlatToPassthroughItem(cartItems),
    );

    const handleClick = (
        activeRewardOption: IPromotion | null,
        activeReward: IReward | null,
        isOnePerCart?: boolean,
        userNotEligible?: boolean,
    ) => {
        if (activeRewardOption) {
            if (isOnePerCart) {
                toast(`Reward is one per cart.`);
                return;
            }
            if (userNotEligible) {
                const pointsRequired =
                    activeRewardOption.pointsRequired - (pointsBalance || 0);
                toast(
                    `You need ${pointsRequired} more points to get this reward.`,
                );
                return;
            }
            setActiveRewardPromo(activeRewardOption);
            dispatch(showModal(ModalsName.claimRewardModal));
        }
        if (activeReward) {
            if (isOnePerCart) {
                toast(`Reward already in your cart.`);
                return;
            }
            setActiveReward(activeReward);
            dispatch(showModal(ModalsName.claimRewardModal));
        }
    };

    if (isUserLoggedIn && (rewardOptions.length || rewards.length)) {
        return (
            <div>
                <Divider width={1} className="ml-4 md:ml-6" />
                <div className="flex flex-row justify-between">
                    <div className="px-4 md:px-6 pt-6 pb-3 text-xl text-primary font-semibold">
                        Rewards
                    </div>
                </div>

                <div className="w-full h-[90px] mb-6 px-3 md:px-5 gap-2 flex flex-row overflow-x-auto hidden-scroll border-none">
                    {rewards.map((r) => {
                        const isRewardInCart = rewardIsInCart(r, cart);
                        return (
                            <PromotionCard
                                name={r.name}
                                key={r._id}
                                handleClick={() => {
                                    handleClick(null, r, isRewardInCart);
                                }}
                                classNames={cn(
                                    "min-w-[300px] max-w-[500px] !py-1",
                                    {
                                        "cursor-default": isRewardInCart,
                                    },
                                )}
                                disable={false}
                                isSelected={isRewardInCart}
                                isReward
                            />
                        );
                    })}
                    {rewardOptions.map((ro) => {
                        const isOnePerCart =
                            ro.isInCart &&
                            ro.rewardOption.conditions?.onePerCart;
                        const userNotEligible = !ro.isUserEligible;
                        return (
                            <PromotionCard
                                name={ro.rewardOption.name}
                                key={ro.rewardOption._id}
                                handleClick={() => {
                                    handleClick(
                                        ro.rewardOption,
                                        null,
                                        isOnePerCart,
                                        userNotEligible,
                                    );
                                }}
                                classNames={cn(
                                    "min-w-[300px] max-w-[500px] !py-1",
                                    {
                                        "cursor-default":
                                            isOnePerCart || userNotEligible,
                                    },
                                )}
                                points={ro.rewardOption.pointsRequired}
                                isSelected={ro.isInCart}
                            />
                        );
                    })}
                </div>
                <div className="md:hidden">
                    <Divider width={8} />
                </div>
                <div className="hidden md:block ml-6">
                    <Divider width={1} />
                </div>
            </div>
        );
    }

    return null;
};

export default Rewards;
