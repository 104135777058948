import { useEffect } from "react";
import { Segment, SegmentEventNames } from "src/utils/Segment";
export function useTrackSegment(purchase) {
    useEffect(() => {
        // Track when the user opens the order status page
        Segment.track(SegmentEventNames.VIEW_ORDER_STATUS_TAB, {
            purchaseId: purchase._id,
            transactionSource: purchase.transactionSource,
            storeId: purchase.store?._id,
        });
    }, []);
}
