import { SagaGenerator, call, put, takeLatest } from "typed-redux-saga";

import { sendError } from "@/utils/Errors";

import { fetchMenu } from "../actions";
import { StoreMenuService } from "../service";
import { setMenu } from "../slice";

/**
 * Saga to fetch the menu for a given store. Where the menu is sourced from and how it is
 * projected is abstracted by the `menuService`.
 */
export function* fetchMenuSaga(storeMenuService: StoreMenuService) {
    const result = yield* call(storeMenuService.fetchMenu);

    yield* result.match<SagaGenerator<void>>(
        function* (menu) {
            if (menu) {
                yield* put(setMenu(menu));
            }
        },
        function* (error) {
            // Only send error to Sentry if it's not a network error.
            // TODO: Consider adding a retry mechanism for users to try again.
            // TODO: The `menus-sdk` should provide a way to more easily identify a "Network Error"
            if (!error.message.includes("Network Error")) {
                yield* call(sendError, error);
            }
        },
    );
}

/**
 * Watches for `fetchMenu` actions and calls `fetchMenuSaga`.
 */
export function* watchFetchMenuSaga(storeMenuService: StoreMenuService) {
    yield* takeLatest(fetchMenu, function* () {
        yield* call(fetchMenuSaga, storeMenuService);
    });
}
