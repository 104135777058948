import {
    CartActions,
    GiftCardActions,
    centsToDollars,
} from "@snackpass/accounting";
import { Constants } from "src/utils/Constants";
import { SystemColors } from "@snackpass/design-system";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStore } from "src/redux";
import { setRevealTotalByDefault } from "@/redux/ooCart";
import API from "@/utils/Api/graphql";
import { isEmpty } from "lodash";
import { BeatLoader } from "react-spinners";
import { Colors } from "@/utils";
import { getActiveGiftCards } from "@snackpass/accounting/build/src/redux/giftCards/selectors";
import { onlyDigits } from "@/utils/Helpers";

const CODE_LENGTH = 16;
const PIN_LENGTH = 4;

function GiftCardInput() {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const activeGiftCards = useSelector(getActiveGiftCards);

    const [isLoading, setIsLoading] = useState(false);
    const [giftCardCode, setGiftCardCode] = useState("");
    const [giftCardPin, setGiftCardPin] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const hasError = error != null;

    const activeStoreId = activeStore?._id;
    const _onSubmit = useCallback(async () => {
        if (giftCardCode.length !== CODE_LENGTH) {
            setError(`Gift card length must by ${CODE_LENGTH}`);
            setSuccessMessage(null);
            return;
        }
        if (isEmpty(giftCardPin)) {
            setError("Pin is required");
            setSuccessMessage(null);
            return;
        }
        if (
            activeGiftCards?.find((giftCard) => giftCard.code === giftCardCode)
        ) {
            setError("Gift card already added");
            setSuccessMessage(null);
            return;
        }
        setError(null);
        setIsLoading(true);
        await API.giftCards
            .verifyGiftCard(giftCardCode, giftCardPin, activeStoreId || "")
            .then((giftCard) => {
                setSuccessMessage(
                    `$${centsToDollars(giftCard.remainingCreditCents).toFixed(
                        2,
                    )} credits added`,
                );
                dispatch(GiftCardActions.addRedeemGiftcard(giftCard));
                dispatch(CartActions.setUseCredit(true));
                dispatch(setRevealTotalByDefault(true));
                setGiftCardCode("");
                setGiftCardPin("");
            })
            .catch((err) => {
                setError(err.message);
                setSuccessMessage(null);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [activeGiftCards, activeStoreId, dispatch, giftCardCode, giftCardPin]);

    useEffect(() => {
        let timerId;
        if (successMessage) {
            timerId = setTimeout(() => {
                setSuccessMessage(null);
            }, 5000);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [successMessage]);

    const setGiftCardPinCallback = (pin: string) => {
        setGiftCardPin(pin);
    };
    const keyPressed = (e: { charCode: number }) => {
        if (e.charCode === Constants.enterKey) {
            // Generally this should not happen but show the warning message to the user
            // if they manually try to submit an invalid combination
            _onSubmit();
        }
    };

    useEffect(() => {
        // auto submit functionality when we detect the input is correct
        if (
            giftCardCode.length === CODE_LENGTH &&
            giftCardPin.length === PIN_LENGTH
        ) {
            _onSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [giftCardCode, giftCardPin]);
    return (
        <div className="flex flex-col w-full px-4 md:px-6 py-4 gap-2">
            <div className="flex flex-row gap-1">
                <div className="w-full flex flex-row items-center flex-grow">
                    <NumberFormat
                        format="################"
                        style={{
                            background: "none",
                            width: "100%",
                            outline: "none",
                            borderWidth: hasError ? 2 : 1,
                            borderColor: hasError ? "#FA3A3A" : "#DFE0E2",
                            lineHeight: "24px",
                            padding: "8px",
                            color: SystemColors.sesame,
                            fontSize: "16px",
                            borderRadius: "8px",
                        }}
                        placeholder="Add a physical gift card"
                        value={giftCardCode}
                        onChange={(e) =>
                            setGiftCardCode(onlyDigits(e.target.value))
                        }
                        onKeyPress={keyPressed}
                    />
                </div>
                <div className="w-full flex flex-row items-center basis-28 grow-0">
                    <NumberFormat
                        format="####"
                        style={{
                            background: "none",
                            width: "100%",
                            outline: "none",
                            borderWidth: hasError ? 2 : 1,
                            borderColor: hasError ? "#FA3A3A" : "#DFE0E2",
                            lineHeight: "24px",
                            padding: "8px",
                            color: SystemColors.sesame,
                            fontSize: "16px",
                            borderRadius: "8px",
                        }}
                        placeholder="PIN"
                        value={giftCardPin}
                        onChange={(e) =>
                            setGiftCardPinCallback(onlyDigits(e.target.value))
                        }
                        onKeyPress={keyPressed}
                    />
                </div>
            </div>
            {isLoading && <BeatLoader color={Colors.blue} size={10} />}
            {error && (
                <span className="text-alert text-start text-sm">{error}</span>
            )}
            {successMessage && (
                <span className="text-success text-start text-sm">
                    {successMessage}
                </span>
            )}
        </div>
    );
}

export default GiftCardInput;
