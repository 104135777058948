/**
 *
 * Colors library
 *
 */
import { SystemColors } from "@snackpass/design-system";

export const blue = "#2FC3FF";
export const primary = blue;
export const darkBlue = "#0697D3";
export const secondary = "#045993";

export const pink = "#FFB2B2";
export const orange = "#FC622E";
export const purple = "#B99AFF";
export const green = "#1FBA1D";
export const lightGreen = "#00D20C";
export const gray = "#9B9B9B";
export const lightGray = "#EFEFEF";
export const darkGray = "#606060";
export const warmGray = "#929292";
export const underlay = "#F0F0F0";
export const white = "#FDFDFD";
export const red = "#F05454";
export const offWhite = "#F7F8F8";
export const text = "#4E4E4E";
export const transparent = "rgba(0,0,0,0)";
export const teal = "#6DE6E6";

// secondary colors
export const blue2 = "#5AAFF7";
export const yellow2 = "#FFBE5F";
export const pink2 = "#F75AA2";
export const orange2 = "#FF6969";
export const green2 = "#5FC990";

export const green3 = "#2BC04B";
export const green4 = "#36D680";
export const green5 = "#07CC46";
export const yellow3 = "#FFAD36";

export const darkGray2 = "#1B1C1C";

export const greenblue = "rgba(109,230,230,0.31)";

export const referralBackground = "#EFF8EF";

export const lightBlue = "#0077FF";
export const darkerGrey = '#282D32';
// receipt colors
export const opaqueBlack = "#0005";
export const black = SystemColors.gray20;
