import useURLParams from "@/hooks/useURLParams";
import { getActiveStore } from "@/redux";
import { cn } from "@/utils";
import React, { memo } from "react";
import { useSelector } from "react-redux";

const CoverPhotoMobile: React.FC = memo(() => {
    const store = useSelector(getActiveStore);
    const img = store?.thumbnailUrl;
    const urlParams = useURLParams();
    const fromCustomApp = urlParams.get("fromCustomApp");

    if (!img || store.hideBrandingImagesOnOO) {
        // if store doesn't have image, adding padding for the popover menu not blocking, but not on Custom branded app
        return (
            <div
                className={cn({
                    "mb-12": !fromCustomApp,
                    "mb-2": fromCustomApp,
                })}
            />
        );
    }

    return (
        <div className="">
            <div
                className="!bg-cover !bg-center w-full h-[180px] lg:hidden"
                style={{
                    background: `url("${img}")`,
                }}
            />
        </div>
    );
});

export default CoverPhotoMobile;
