import React, { CSSProperties } from "react";
import * as Colors from "../../utils/Colors";
import LocationIcon from "src/assets/icons/location.png";

export default function AddressSearchOption(
    props: { suggestion: any; style?: CSSProperties } & any
) {
    return (
        <div {...props}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        width: 30,
                        height: 30,
                        paddingTop: 8,
                        margin: "auto",
                        borderRadius: "50%",
                        backgroundColor: "#efefef",
                        textAlign: "center",
                        fontSize: 14,
                    }}
                >
                    <img
                        style={{
                            height: 12,
                            width: "auto",
                            margin: "auto",
                        }}
                        alt="Map"
                        src={LocationIcon}
                    />
                </div>

                <div style={{ flex: 1, marginLeft: 10 }}>
                    <p
                        style={{
                            color: "#000",
                            fontSize: 17,
                            fontWeight: 500,
                            margin: 0,
                        }}
                    >
                        {props.suggestion.formattedSuggestion.mainText}
                    </p>
                    <p
                        style={{
                            color: Colors.darkGray,
                            fontSize: 15,
                            margin: 0,
                        }}
                    >
                        {props.suggestion.formattedSuggestion.secondaryText}
                    </p>
                </div>
            </div>
        </div>
    );
}
