import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSetRewards } from "@snackpass/loyalty-react";

import { getActiveStore, getUserIsLoggedIn, getUser } from "@/redux";
import Api from "src/utils/Api/REST";

// this is only to accommodate rewards from the pre pass through model

export function useLegacyLoadRewards() {
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const activeStore = useSelector(getActiveStore);
    const user = useSelector(getUser);

    const { setUserStoreRewards } = useSetRewards();

    const _fetchRewards = useCallback(async () => {
        try {
            const res = await Api.me.getRewards();
            const { rewards } = res.data;
            if (!activeStore) {
                setUserStoreRewards(user?._id, []);
                return;
            }

            setUserStoreRewards(user?._id, rewards);
        } catch (error) {
            setUserStoreRewards(user?._id, []);
        }
    }, [activeStore, user, setUserStoreRewards]);

    useEffect(() => {
        if (isUserLoggedIn) {
            _fetchRewards();
        }
    }, [_fetchRewards, isUserLoggedIn]);
}
