import _ from "lodash";
import { DateTime, WeekdayNumbers } from "luxon";

import { Time } from "@snackpass/accounting";
import { TIME_RANGE_DAYS } from "@snackpass/accounting/build/src/utils/Time";
import {
    IDateTimeRangeSchema,
    IHoursSchema,
    IStore,
    ITimeRangeSchema,
} from "@snackpass/snackpass-types";
import {
    format,
    fromLegacy,
    toLegacy,
    isSpecialDate,
    isStoreOpen,
    storeClosesAt,
    splitHoursOnDays,
} from "@snackpass/time";

import { isSnackpassTimeEnabled } from "@/statsig/featureGates";

export const FULL_WEEK_RANGE = {
    start: 0,
    end: 24 * 60 * 7 - 1,
};

export const parseSpecialHours = (
    special?: IDateTimeRangeSchema[],
): IDateTimeRangeSchema[] =>
    special?.map((hour) => ({
        ...hour,
        date: new Date(hour.date),
    })) ?? [];

// TODO: Copied and pasted from snackpass client. Function in accounting has a bug.
// #inc-109-store-hours-not-adjusted-for-day-light-saving-2023-03-12
export function isOpenLocal(hours: IHoursSchema): boolean {
    if (isSnackpassTimeEnabled()) {
        return isStoreOpen(formatLegacySpecialHours(hours))();
    }

    // account for user being in different timezones
    const today = DateTime.now()
        .setZone(hours.zone)
        // swap tz to UTC to avoid DST shifts in calculations
        .setZone("utc", { keepLocalTime: true });

    const resolvedHours = resolveStoreHours(hours, [today.toJSDate()]);

    const minutes = Math.floor(today.diff(today.startOf("week")).as("minutes"));

    let result = false;
    for (const timeRange of resolvedHours.local) {
        if (minutes >= timeRange.start && minutes <= timeRange.end)
            result = true;
    }

    console.warn("isOpenLocal", {
        now: DateTime.now().setZone(hours.zone).toString(),
        today,
        minutes,
        resolvedHours,
        result,
    });

    return result;
}

export function isTodaySpecial(hours: IHoursSchema): boolean {
    if (isSnackpassTimeEnabled()) return isSpecialDate(hours)();

    const special = parseSpecialHours(hours.special);

    const today = DateTime.now().setZone("utc").toFormat("yyyy-MM-dd");
    const toDateFormat = (date: Date) =>
        DateTime.fromJSDate(date, { zone: "utc" }).toFormat("yyyy-MM-dd");

    return (
        special.filter((hour) => today === toDateFormat(hour.date)).length > 0
    );
}

export function splitCrossRange(
    hour: ITimeRangeSchema,
    zone: string,
): ITimeRangeSchema[] {
    if (isSnackpassTimeEnabled()) return splitHoursOnDays(hour, zone);

    const dt = DateTime.local({ zone }).startOf("week");

    const startRange = dt.set({ minute: hour.start }).weekday;
    const endRange = dt.set({ minute: hour.end }).weekday;

    // no split needed
    if (startRange === endRange) return [hour];

    const hours: ITimeRangeSchema[] = [];

    let start = hour.start;

    // i is 1-indexed
    for (let i = startRange; i < endRange + 1; i++) {
        if (hour.end >= TIME_RANGE_DAYS[i]) {
            hours.push({
                start,
                end: TIME_RANGE_DAYS[i] - 1,
            });

            start = TIME_RANGE_DAYS[i];
        } else {
            hours.push({ start, end: hour.end });
        }
    }

    return hours;
}

export function resolveStoreHours(
    hours: IHoursSchema,
    dates?: Date[],
): IHoursSchema {
    let _hours: IHoursSchema;
    if (isSnackpassTimeEnabled()) {
        _hours = toLegacy(fromLegacy(formatLegacySpecialHours(hours)));
    } else {
        // if no special hours, prevent manipulating hours
        if (!hours.special || hours.special.length === 0) {
            return hours;
        }
        const special = parseSpecialHours(hours.special);

        // split up combined hours; Snackface combines continuous hours (ie. 24 hours) into 1 time frame
        const splitLocalHours = hours.local.flatMap((hour) =>
            splitCrossRange(hour, hours.zone),
        );

        _hours = {
            zone: hours.zone,
            special,
            local: splitLocalHours,
        };
    }

    const { hours: local } = Time.resolveStoreHours(_hours, {
        forDates: dates ?? [],
    });

    return {
        ..._hours,
        local,
    };
}

/**
 * Formats the current week's hours into the day name and its time range.
 * The output will tag each day/time range that was overridden by special hours.
 *
 * Output in the form of:
 * ```typescript
 * [{ day: "Monday", time: "9:00AM - 10:00PM", isSpecial: true/false }]
 * ```
 * @param hours
 */
export function getHourRanges(hours: IHoursSchema) {
    const { local, zone } = hours;

    const dateTimeFromWeekMinute = (minute: number) =>
        DateTime.now().startOf("week").set({ minute });

    const toTimeFromWeekMinute = (minute: number) =>
        dateTimeFromWeekMinute(minute).toFormat("h:mm a");

    const dayNameFrom = (weekday: WeekdayNumbers) =>
        DateTime.now().startOf("week").set({ weekday }).toFormat("cccc");

    const isDateInThisWeek = (date: Date) =>
        DateTime.now().setZone("utc").startOf("week") <=
            DateTime.fromJSDate(date, { zone: "utc" }) &&
        DateTime.fromJSDate(date, { zone: "utc" }) <=
            DateTime.now().setZone("utc").endOf("week");

    const toTimeFromDayMinute = (day: WeekdayNumbers, minute: number) =>
        DateTime.now()
            .startOf("week")
            .set({ weekday: day, minute })
            .toFormat("h:mm a");

    if (
        local.length === 1 &&
        local[0].start === FULL_WEEK_RANGE.start &&
        local[0].end === FULL_WEEK_RANGE.end
    ) {
        return [
            {
                day: "Everyday",
                time: `${toTimeFromWeekMinute(
                    local[0].start,
                )} - ${toTimeFromWeekMinute(local[0].end)}`,
                isSpecial: false,
            },
        ];
    }

    const resolvedHours = resolveStoreHours({
        zone,
        special: hours.special,
        local,
    });

    const special = parseSpecialHours(hours.special).filter((hour) =>
        isDateInThisWeek(hour.date),
    );

    const specialDays = new Set(special.map((hour) => weekDayFrom(hour.date)));
    const localDays = new Set(
        resolvedHours.local.map(
            (hour) => dateTimeFromWeekMinute(hour.start).weekday,
        ),
    );

    const specialByWeekday = _.groupBy(special, (hour) =>
        weekDayFrom(hour.date),
    );
    const localByWeekday = _.groupBy(
        resolvedHours.local,
        (hour) => dateTimeFromWeekMinute(hour.start).weekday,
    );

    return _.range(1, 8).flatMap((w) => {
        const weekday = w as WeekdayNumbers;
        if (specialDays.has(weekday)) {
            return specialByWeekday[weekday].map((hour) => {
                const isClosed = hour.start === hour.end;

                return {
                    day: dayNameFrom(weekday),
                    time: !isClosed
                        ? `${toTimeFromDayMinute(
                              weekday,
                              hour.start,
                          )} - ${toTimeFromDayMinute(weekday, hour.end)}`
                        : "Closed",
                    isSpecial: true,
                };
            });
        } else if (localDays.has(weekday)) {
            return localByWeekday[weekday].map((hour) => ({
                day: dayNameFrom(weekday),
                time: `${toTimeFromWeekMinute(
                    hour.start,
                )} - ${toTimeFromWeekMinute(hour.end)}`,
                isSpecial: false,
            }));
        } else {
            return [
                {
                    day: dayNameFrom(weekday),
                    time: "Closed",
                    isSpecial: false,
                },
            ];
        }
    });
}

export function dayMinutes2WeekMinutes(date: Date, minutes: number): number {
    // prevent DST shifting when converting scope of minutes
    const dt = DateTime.fromJSDate(date, { zone: "utc" });

    const startOfWeek = dt.startOf("week");
    const startOfDay = dt.startOf("day");

    const startOfDayMinutes = Math.floor(
        startOfDay.diff(startOfWeek).as("minutes"),
    );

    return startOfDayMinutes + minutes;
}

export function weekDayFrom(date: Date): number {
    return DateTime.fromJSDate(date, { zone: "utc" }).weekday;
}

const formatTime = (minutes: number, zone: string) => {
    const format = "h:mma";
    // Use the store's timezone to create the local time...thus be DST-safe.
    const time = DateTime.local({ zone })
        .startOf("day")
        .plus({ minutes })
        .toFormat(format);
    return time;
};

/**
 * Provides a customer friendly string that indicates when the store is open until.
 */
export const storeOpenUntil = (store: Pick<IStore, "hours">): string => {
    if (isSnackpassTimeEnabled()) {
        const closesAt = storeClosesAt(
            fromLegacy(formatLegacySpecialHours(store.hours)),
        )();
        return closesAt ? `Until ${format.hoursMinutes(closesAt)}` : "";
    }

    const today = DateTime.now()
        .setZone(store.hours.zone)
        // swap tz to UTC to avoid DST shifts in calculations
        .setZone("utc", { keepLocalTime: true });
    const resolvedHours = resolveStoreHours(store.hours, [today.toJSDate()]);

    const minutes = Math.floor(today.diff(today.startOf("week")).as("minutes"));

    let result = "";
    for (const timeRange of resolvedHours.local) {
        if (minutes >= timeRange.start && minutes <= timeRange.end) {
            result = `Until ${formatTime(timeRange.end, store.hours.zone)}`;
        }
    }

    console.warn("storeOpenUntil", {
        now: DateTime.now().setZone(store.hours.zone).toLocaleString(),
        today,
        minutes,
        resolvedHours,
        result,
    });

    return result;
};

export const formatLegacySpecialHours = (hours: IHoursSchema) => {
    const special = parseSpecialHours(hours?.special);

    // Remove this hack when fully migrated to @snackpass/time
    return {
        ...hours,
        special: special.map((hour) => ({
            ...hour,
            date: DateTime.fromJSDate(hour.date)
                // legacy hours are saved in UTC from RDB
                .setZone("utc")
                // @snackpass/time expects time in store's TZ
                .setZone(hours.zone, {
                    keepLocalTime: true,
                })
                .toJSDate(),
        })),
    };
};
