import { getActiveStore } from "@/redux";
import { optimizedImageURL } from "@/utils/Helpers";
import React, { memo } from "react";
import { useSelector } from "react-redux";

const CoverPhotoDesktop: React.FC = memo(() => {
    const activeStore = useSelector(getActiveStore);
    const coverImage = activeStore?.thumbnailUrl;

    if (!coverImage) {
        return null;
    }

    return (
        <div className="py-4 pt-6">
            <div className="flex h-[180px] w-full flex-row items-center overflow-hidden rounded-[12px] sm:h-[348px]">
                <div
                    className="h-[180px] flex-1 rounded-[12px] bg-cover bg-center bg-no-repeat sm:h-[348px]"
                    style={{
                        backgroundImage: `url("${optimizedImageURL(coverImage)}")`,
                    }}
                />
            </div>
        </div>
    );
});

export default CoverPhotoDesktop;
