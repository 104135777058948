import React, { CSSProperties } from "react";
import { Api, Colors } from "../../../utils";
import { View, Text } from "src/SharedComponents";
import { useSelector } from "react-redux";
import { getUser } from "src/redux";
import { IUser } from "@snackpass/snackpass-types";
import { toast } from "sonner";
import BlueCheckMark from "src/assets/icons/checkmark-blue.svg";
import TrashCan from "src/assets/icons/trash-can-dark.svg";

type Props = {
    activeCardIndex: number | null | undefined;
    card: any;
    setUser: (user: IUser) => void;
    cardIndex: number;
};

const BORDER_COLOR_BLUE = "#0077FF";
const BORDER_COLOR_GREY = "#DDE5EE";
const START = "flex-start";
const END = "flex-end";
const ACTIVE = "Active";
const DIGITAL_DOTS = "· · · ·";

export const CardRow = ({
    activeCardIndex,
    card,
    setUser,
    cardIndex,
}: Props) => {
    const isActive = activeCardIndex === cardIndex;
    const borderColor = isActive ? BORDER_COLOR_BLUE : BORDER_COLOR_GREY;
    const displayButtons = isActive ? START : END;
    const user = useSelector(getUser);

    const showResetActionSheet = async (event) => {
        event.stopPropagation();
        try {
            const response = await Api.me.cards.remove(card._id);
            setUser(response.data.user);
        } catch (err) {
            toast.error("Cannot delete card.");
        }
    };
    const handleActivate = async () => {
        try {
            const response = await Api.me.update({
                activeCardIndex: cardIndex,
            });
            setUser(response.data.user);
        } catch (err) {
            // handle error
            toast.error("Cannot set active card.");
        }
    };

    return (
        <View
            style={{ ...stylesLocal.cardView, borderColor: borderColor }}
            onPress={handleActivate}
        >
            <View
                style={{
                    ...stylesLocal.buttonsTop,
                    justifyContent: displayButtons,
                }}
            >
                {isActive && (
                    <View style={stylesLocal.buttonsTop}>
                        <img
                            alt="Credit Card"
                            style={stylesLocal.imgIcon}
                            src={BlueCheckMark}
                        />
                        <Text style={stylesLocal.slideTitle}>{ACTIVE}</Text>
                    </View>
                )}

                <View
                    style={stylesLocal.trashCan}
                    onPress={showResetActionSheet}
                >
                    <img
                        alt="Credit Card"
                        style={stylesLocal.trashCanIcon}
                        src={TrashCan}
                    />
                </View>
            </View>
            <View style={stylesLocal.textCards}>
                <Text style={stylesLocal.cardsDigits}>{DIGITAL_DOTS}</Text>
                <Text style={stylesLocal.cardsDigits}>{DIGITAL_DOTS}</Text>
                <Text style={stylesLocal.cardsDigits}>{DIGITAL_DOTS}</Text>
                <Text style={stylesLocal.cardsDigits}>{card.cardLast4}</Text>
            </View>

            <View style={stylesLocal.user}>
                <Text style={stylesLocal.cardsDigits}>{user?.name}</Text>
            </View>
        </View>
    );
};

const stylesLocal = {
    slideTitle: {
        textAlign: "center",
        padding: "2%",
        fontWeight: 500,
        fontSize: "100%",
        color: Colors.lightBlue,
    } as CSSProperties,

    cardView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "90%",
        padding: "5%",
        margin: "5%",
        background: "#FFFFFF",
        border: "1px solid",
        boxSizing: "border-box",
        cursor: "pointer",
        borderRadius: 16,
        maxWidth: 550,
    } as CSSProperties,

    buttonsTop: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        padding: "2%",
    } as CSSProperties,

    textCards: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        padding: "2%",
    } as CSSProperties,

    user: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        padding: "2%",
    } as CSSProperties,

    cardsDigits: {
        fontStyle: "normal",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        fontSize: 20,
        textAlign: "center",
        color: "#282D32",
        padding: "4%",
    } as CSSProperties,

    userData: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 20,
        textAlign: "center",
        color: "#282D32",
        padding: "2%",
    } as CSSProperties,

    trashCan: {
        background: "#F1F5F8",
        border: "1px solid #F1F5F8",
        boxSizing: "border-box",
        borderRadius: 56,
        padding: "2%",
        width: "10%",
    } as CSSProperties,

    trashCanIcon: {
        width: "100%",
    } as CSSProperties,

    imgIcon: {
        width: "5%",
    } as CSSProperties,
};
