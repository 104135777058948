import Dinero from "dinero.js";
import { InventoryUnitType } from "@snackpass/snackpass-types";

export type Money = Dinero.Dinero;
export const Money = (dollars: number): Money =>
    Dinero({
        amount: Math.round(dollars * 100),
        precision: 2
    });

export type InventoryCost = {
    amount: Money; // per count-unit, e.g. $4.20 per 2 dozen
    perCount: number;
    perUnit: InventoryUnitType;
};

export const InventoryCost = ({
    amount: value,
    perUnit = "ea",
    perCount = 1
}: {
    amount: Money;
    perCount?: number;
    perUnit?: InventoryUnitType;
}): InventoryCost => {
    return {
        amount: value,
        perUnit,
        perCount
    };
};
