import { useFetchMarketingConsent } from "./useFetchMarketingConsent";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPendingMarketingConsent } from "@/redux/marketingConsent";
import { Divider } from "@/SharedComponents";
import { BeatLoader } from "react-spinners";
import { getPendingMarketingConsent } from "@/redux/marketingConsent";
import MarketingConsentTerms from "./MarketingConsentTerms";
import { useAppSelector } from "@/redux/utils";
import { selectActiveStore } from "#activeStore";
import { Checkbox } from "@/components/ui/checkbox";

function MarketingConsent() {
    const dispatch = useDispatch();
    const { fetchMarketingConsent, data, error } = useFetchMarketingConsent();
    const activeStore = useAppSelector(selectActiveStore);
    const pendingMarketingConsent = useSelector(getPendingMarketingConsent);

    useEffect(() => {
        if (activeStore?._id) {
            fetchMarketingConsent(activeStore?._id);
        }
    }, [fetchMarketingConsent, activeStore?._id]);

    // Hide this screen if the user is already subscribed. We don't
    // allow a user to unsubscribe, as reminding them this exists could prompt them to unsubscribe unnecessarily.
    if (data?.marketingConsent || error != null) {
        return;
    }

    const isLoading = data == null && error == null;
    const currentValue =
        pendingMarketingConsent ?? data?.marketingConsent ?? false;
    return (
        <div className="flex flex-col pt-4 gap-4">
            <Divider width={1} />
            {isLoading ? (
                <BeatLoader />
            ) : (
                <div className="items-top flex space-x-2">
                    <Checkbox
                        id="marketingConsentCheckbox"
                        checked={currentValue}
                        onClick={() => {
                            dispatch(setPendingMarketingConsent(!currentValue));
                        }}
                    />

                    <div className="grid gap-1.5 leading-none">
                        <label
                            htmlFor="marketingConsentCheckbox"
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            Receive marketing texts about promotions, rewards,
                            and more.
                        </label>
                    </div>
                </div>
            )}
            <MarketingConsentTerms />
        </div>
    );
}

export default MarketingConsent;
