import { AxiosResponse } from "axios";
import {
    IStore,
    CreationSource,
    DeliveryQuote,
    IPurchase,
    ParticipatingLocations,
} from "@snackpass/snackpass-types";

import axios, { XForterToken } from "../axios";
import { ForterToken } from "src/forter";

export type StoreSearch = {
    _id: string;
    name: string;
};

class Purchases {
    static create = async (body: Partial<IPurchase>) => {
        return axios.post(`/purchases`, body, {
            headers: { [XForterToken]: ForterToken },
        });
    };

    static get = async (purchaseId: string) => {
        return axios.get(`/purchases/${purchaseId}`);
    };

    static getPurchaseReceipt = async (purchaseId: string, token: string) => {
        return axios.get(`/purchases/${purchaseId}/purchase-receipt`, {
            params: { token },
        });
    };

    static getQueueStatus = async (purchaseId: string, token: string) => {
        return axios.get(`/purchases/${purchaseId}/queue-status`, {
            params: { token },
        });
    };

    static getByIdempotencyKey = async (idempotencyKey: string) =>
        axios.get<{
            purchase: IPurchase;
            receiptUrl?: string;
            receiptToken?: string;
        }>(`/purchases/idempotency-key/${idempotencyKey}`);

    static getRecentPurchases = async (params: any) => {
        return axios.get(`/purchases/me/recent`, {
            params: params,
            headers: { "Content-Type": "application/json" },
        });
    };
}

class Stripe {
    static retrieveChargeByPurchase = async (
        purchaseId: string,
        token: string,
    ) => {
        return axios.get(`/stripe/retrieve-charge-by-purchase`, {
            params: {
                purchaseId,
                token,
            },
        });
    };
}

class Stores {
    static nearby = async (
        query: any,
    ): Promise<AxiosResponse<{ stores: IStore[] }>> => {
        return axios.get(`/stores/nearby`, {
            params: {
                ...query,
                onlineOrderingEnabled: true,
            },
        });
    };
    /** @deprecated: dispatch `fetchActiveStore` instead. */
    static get = async (storeId: string) => {
        return axios.get(`/stores/online/${storeId}`);
    };
    static getDeliveryRates = async (
        storeId: string,
        toAddress: string,
        isCatering: boolean,
    ): Promise<
        AxiosResponse<{
            quotes: DeliveryQuote[];
            errors: any[];
            canDeliver: boolean;
        }>
    > =>
        axios.post(`/stores/${storeId}/delivery-quotes`, {
            to: toAddress,
            isCatering,
        });
    // Note: We need to plug in lng and lat here because
    // online ordering doesn't always update the user's
    // geolocation. We pass the store coordinates so we
    // can get the eligible promotions for a user at a
    // store, regardless of what the coordinates of the
    // user are.
    static getEligiblePromos = async (store: IStore, includeGiftCard = false) =>
        axios.get(`/promotions/eligible`, {
            params: {
                storeId: store._id,
                lng: store.geolocation.coordinates[0],
                lat: store.geolocation.coordinates[1],
                includeGiftCard,
                ignoreTestRule: true,
            },
        });
    static getPromotions = async (storeId: string): Promise<AxiosResponse> =>
        axios.get(`/stores/${storeId}/promotions`, {
            params: { runningOnly: true, onlyActive: true },
        });
    static getPromoSummary = async (
        storeId: string,
        params: Object = {},
    ): Promise<AxiosResponse> =>
        axios.get(`/stores/${storeId}/promotion-summary`, { params });
    /**
     * Get pickup estimate
     * @memberof stores
     * @param { string } itemCount
     * @param { string } storeId
     * @param { string } basePrice
     * @param { string } totalPrice
     * @returns
     * */
    static getPickupEstimate = async (
        itemCount: number,
        storeId: string,
        basePrice?: string,
        totalPrice?: string,
    ): Promise<
        AxiosResponse<{
            pickupEstimate: string[][];
        }>
    > => {
        return axios.get(`/stores/${storeId}/pickup-estimate`, {
            params: {
                itemCount,
                ...(basePrice && { basePrice }),
                ...(totalPrice && { totalPrice }),
            },
        });
    };

    static search = async ({
        query,
        lat,
        long,
        radius,
        signal,
    }: {
        query: string;
        lat: number;
        long: number;
        radius: number;
        signal?: AbortSignal;
    }): Promise<AxiosResponse<{ stores: StoreSearch[] }>> => {
        return axios.get("/stores/search-by-name", {
            params: {
                query,
                lat,
                long,
                radius,
            },
            signal,
        });
    };
}

class Chains {
    static get = async (chainId: string) => {
        return axios.get(`/chains/${chainId}`);
    };
}

class Auth {
    static async sendVerification(phoneNumber: string) {
        return axios.post(`/phoneAuthentication/sendVerification`, {
            phoneNumber,
        });
    }
    static async verify(phoneNumber: string, code: string) {
        return axios.post(`/phoneAuthentication/verifyPhone`, {
            phoneNumber,
            code,
            createUser: true,
            source: CreationSource.Online,
        });
    }
}

class Users {
    static async get() {
        return axios.get(`/users/me`);
    }
    static async update(body: any) {
        return axios.put(`/users/me`, body);
    }
    /**
     * Create new user
     * @memberof users
     * @param {Object} body
     * @param {string} body.email
     * @param {string} body.uid
     * @param {string} body.firstName
     * @param {string} body.lastName
     * @param {boolean} body.isStudent
     * @param {CreationSource} body.creationSource
     * @param {usedOnline} body.usedOnline
     */
    static async create(body: Object) {
        return axios.post(`/users?register=true`, body);
    }
}

class Cards {
    static remove = async (cardId: string) => {
        return axios.delete(`/users/me/cards/${cardId}`);
    };

    /**
     * Create card
     * @param { string } stripeToken
     * @param { string } checkoutToken
     * @param { string } last4
     * @memberof me.cards
     */
    static create = async (
        stripeToken: string,
        cardLast4: string,
        idempotencyKey: string,
    ) => {
        return axios.post(`/users/me/cards`, {
            cardLast4,
            stripeToken,
            idempotencyKey,
        });
    };
}

class Me {
    static cards = Cards;
    static getPunchcards = async (params: { storeId: string }) => {
        return axios.get(`/punchcards`, {
            params,
        });
    };
    static getRewards = async () => {
        return axios.get(`/rewards`, { params: { populatePromotions: true } });
    };
    static update = async (body: Object) => {
        return axios.put(`/users/me`, body);
    };
    static getAnalytics = async () => {
        return axios.get(`/users/me/analytics`);
    };
}

class GiftCardsRest {
    static getParticipatingLocations = async (id: string, token: string) => {
        return axios.get<{ participatingLocations: ParticipatingLocations }>(
            `/giftCards/participating-locations/${id}/${token}`,
        );
    };
}

export function isNetworkError(err: any) {
    return err.code === "ECONNABORTED" || err.message === "Network Error";
}

export default class API {
    static stores = Stores;
    static chains = Chains;
    static users = Users;
    static purchases = Purchases;
    static me = Me;
    static stripe = Stripe;
    static auth = Auth;
    static giftCardsRest = GiftCardsRest;
}
