import {
    FeePolicy,
    FeePolicyPayer,
    FeePolicyRecipient,
    FeePolicyWithTotal,
    Fulfillment,
    KioskPaymentProvider,
    PaymentProvider,
    SnackpassConvenienceFeeStrategy,
    TransactionSource
} from "@snackpass/snackpass-types";
import Dinero from "dinero.js";
import {
    all,
    compose,
    curry,
    filter,
    find,
    flow,
    includes,
    sumBy
} from "lodash/fp";
import { DD } from "../../utils/Money";
import { verbose } from "../../verbose";
import { isServiceFee } from "./utils";

type FeePolicyParams = {
    // client and server
    cartSubtotal: number;
    fulfillment: Fulfillment;
    transactionSource: TransactionSource;
    paymentProvider?: PaymentProvider | KioskPaymentProvider | null;
    feePolicies: FeePolicy[];
    isCatering: boolean;
    useCredit: boolean;
    // server only
    isQr?: boolean;
};

export const _findApplicableFeePolicies = ({
    cartSubtotal,
    fulfillment,
    transactionSource,
    paymentProvider,
    useCredit,
    feePolicies,
    isCatering,
    isQr
}: FeePolicyParams): FeePolicy[] =>
    filter<FeePolicy>((policy) => {
        // explicity type optional fields
        const [bIsQr] = [isQr].map(Boolean);

        const applicabilityConditions = [
            cartSubtotal < (policy?.rules?.cartMax || Infinity),
            cartSubtotal > (policy?.rules?.cartMin || -Infinity),
            includes(fulfillment, policy?.rules?.fulfillments || [fulfillment]),
            includes(
                transactionSource,
                policy?.rules?.transactionSources || [transactionSource]
            ),
            policy.rules?.isCatering ? isCatering : true,
            policy.rules?.isQr ? bIsQr : true,
            // this is a particular case where purchase is made with cash (inStore)
            // but also using gift cards; we should charge service fee in that case
            // and skip inStore included in the excludedPaymentProviders
            // TODO: refactor gift card credit not to be a paymentMethod / paymentProvider
            !useCredit &&
            paymentProvider &&
            policy?.rules?.excludedPaymentProviders?.length
                ? !includes(
                      paymentProvider,
                      policy.rules.excludedPaymentProviders
                  )
                : true
        ];

        return all(Boolean, applicabilityConditions);
    }, feePolicies);

export const _findFeePoliciesForPayer = (
    payer: "customer" | "store"
): ((params: FeePolicyParams) => FeePolicy[]) =>
    compose(
        filter<FeePolicy>((policy) => policy.payer === payer),
        _findApplicableFeePolicies
    );

// Return all fee policies applicable to purchase which are paid by the customer.
// Customer-paid fees have already been calculated by client.
const _findCustomerFeePolicies = _findFeePoliciesForPayer("customer");
export const findCustomerFeePolicies = (
    params: FeePolicyParams
): FeePolicy[] => {
    const result = _findCustomerFeePolicies(params);

    verbose("findCustomerFeePolicies", { params, result });

    return result;
};

// Return all fee policies applicable to purchase which are paid by the store.
// Store-paid fees are applied on the server.
const _findStoreFeePolicies = _findFeePoliciesForPayer("store");
export const findStoreFeePolicies = (params: FeePolicyParams): FeePolicy[] => {
    const result = _findStoreFeePolicies(params);

    verbose("findStoreFeePolicies", { params, result });

    return result;
};

export const calculateFeeForPolicyStrategy = (
    amount: number,
    policy?: FeePolicy,
    quantity = 1
): number => {
    if (
        !policy ||
        //  when the amount is 0, we don't want to apply any service fees
        //  refer to https://www.notion.so/snackpass/Remove-Service-Fees-when-Rewards-gifts-are-applied-ad926d02b56d4437854f38b9f1590180
        (amount === 0 && policy.name && isServiceFee(policy.name))
    ) {
        return 0;
    }

    const flat = DD(policy.flat).multiply(quantity);
    const percent = DD(amount).percentage(policy.percent);

    switch (policy.strategy) {
        case SnackpassConvenienceFeeStrategy.Flat:
            return flat.toUnit();

        case SnackpassConvenienceFeeStrategy.Percent:
            return percent.toUnit();

        case SnackpassConvenienceFeeStrategy.FlatAndPercent:
            return flat.add(percent).toUnit();

        case SnackpassConvenienceFeeStrategy.LesserOfFlatAndPercent:
            return Dinero.minimum([flat, percent]).toUnit();

        case SnackpassConvenienceFeeStrategy.GreaterOfFlatAndPercent:
            return Dinero.maximum([flat, percent]).toUnit();

        default:
            return policy.flat * quantity + 0.01 * policy.percent * amount;
    }
};

export const calculateFeeForPolicy = (
    amount: number,
    policy?: FeePolicy,
    quantity = 1
): number => {
    const result = calculateFeeForPolicyStrategy(amount, policy, quantity);

    verbose("calculateFeeForPolicy", { amount, policy, quantity, result });

    return result;
};

export const findPolicyByName = (
    feeName: string,
    feePolicies: FeePolicy[]
): FeePolicy | undefined =>
    find<FeePolicy>((policy) => policy.name === feeName, feePolicies);

export const calculateCustomFee = curry(
    (feeName: string, feePolicies: FeePolicy[], amount: number): number =>
        calculateFeeForPolicy(amount, findPolicyByName(feeName, feePolicies))
);

export const calculateCustomFeeWithPolicy = ({
    feeName,
    feePolicies,
    amount
}: {
    feeName: string;
    feePolicies: FeePolicy[];
    amount?: number;
}): FeePolicyWithTotal | null => {
    const fee = findPolicyByName(feeName, feePolicies);
    return fee ? { fee, total: calculateFeeForPolicy(amount || 0, fee) } : null;
};

export const calculateTotalFeeForPayer = (
    payer: FeePolicyPayer,
    fees: FeePolicyWithTotal[]
): number =>
    flow(
        filter(
            (feeWithTotal: FeePolicyWithTotal) =>
                feeWithTotal.fee.payer === payer
        ),
        sumBy("total")
    )(fees);

export const calculateTotalPaidToRecipient = (
    recipient: FeePolicyRecipient,
    fees: FeePolicyWithTotal[]
): number =>
    flow(
        filter(
            (feeWithTotal: FeePolicyWithTotal) =>
                feeWithTotal.fee.recipient === recipient
        ),
        sumBy("total")
    )(fees);
