import {
    FeePolicyWithTotal,
    IStore,
    ReservedFeeEnum
} from "@snackpass/snackpass-types";

import { toDollar } from "../../utils/Helpers";

const RESERVED_FEE_TO_LABEL: { [key in ReservedFeeEnum]: string } = {
    [ReservedFeeEnum.PaymentProcessingCost]: "Payment Processing Cost",
    [ReservedFeeEnum.DeliveryFee1P]: "Delivery Fee",
    [ReservedFeeEnum.DeliveryTip3P]: "Delivery Tip",
    [ReservedFeeEnum.DeliveryFee3P]: "Delivery Fee",
    [ReservedFeeEnum.DeliveryServiceFee3P]: "Delivery Service Fee",
    [ReservedFeeEnum.DeliveryCommission3P]: "Delivery Fee (3P)",
    [ReservedFeeEnum.DeliveryTipCommission3P]: "Delivery Tip (3P)",
    [ReservedFeeEnum.DeliveryCost3P]: "Delivery Cost",
    [ReservedFeeEnum.DeliveryTipCost3P]: "Delivery Tip Cost",
    [ReservedFeeEnum.DeliveryStoreCommissionAdjustment3P]: "Delivery Fee (3P)",
    [ReservedFeeEnum.Commission]: "Commission",
    [ReservedFeeEnum.CommissionFlat]: "Commission Flat",
    [ReservedFeeEnum.CreditCardCommission]: "CC Commission",
    [ReservedFeeEnum.ConvenienceFee]: "Convenience Fee",
    [ReservedFeeEnum.ConvenienceStoreFee]: "Convenience Fee (store)",
    [ReservedFeeEnum.SnackpassConvenienceFee]: "Service Fee",
    [ReservedFeeEnum.OnlineOrderingConvenienceFee]: "Convenience Fee",
    [ReservedFeeEnum.OnlineOrderingConvenienceStoreFee]: "Convenience Fee",
    [ReservedFeeEnum.KioskConvenienceFee]: "Convenience Fee",
    [ReservedFeeEnum.KioskConvenienceStoreFee]: "Convenience Fee",
    [ReservedFeeEnum.FaxCommission]: "Fax Fee",
    [ReservedFeeEnum.SmallOrderDeliveryStoreFee]: "Small Order Fee",
    [ReservedFeeEnum.DeliveryStoreFee1P]: "Delivery Fee",
    [ReservedFeeEnum.DeliveryTipStoreFee1P]: "Delivery Tip",
    [ReservedFeeEnum.BagStoreFee]: "Bag Fee",
    [ReservedFeeEnum.DisposableCupStoreFee]: "Disposable Cup Fee",
    [ReservedFeeEnum.AlcoholStoreFee]: "Alcohol Fee",
    [ReservedFeeEnum.CreditCardCost]: "Credit Card Cost",
    [ReservedFeeEnum.FoodHallFee]: "Food Hall Fee",
    [ReservedFeeEnum.ProcessingFee]: "Processing Fee",
    [ReservedFeeEnum.ProcessingFeeFlat]: "Processing Fee (Flat)"
};

export const labelForFee = (
    feeName: string,
    store?: Pick<IStore, "customFeeLabels"> | null
): string => {
    if (feeName === ReservedFeeEnum.SnackpassConvenienceFee) {
        return (
            store?.customFeeLabels?.snackpassConvenienceLabel || "Operating Fee"
        );
    } else if (feeName === ReservedFeeEnum.ConvenienceStoreFee) {
        return store?.customFeeLabels?.convenienceStoreLabel || "Service Fee";
    }
    return RESERVED_FEE_TO_LABEL[feeName as ReservedFeeEnum] || "Fees";
};

export const descriptionForFee = (
    fee: FeePolicyWithTotal,
    numberOfBags: number,
    store?: Pick<IStore, "customFeeLabels"> | null
): string | undefined => {
    switch (fee.fee.name) {
        case ReservedFeeEnum.SnackpassConvenienceFee:
            return (
                store?.customFeeLabels?.snackpassConvenienceDescription ||
                "Charged by our service provider to cover their operational costs."
            );
        case ReservedFeeEnum.ConvenienceStoreFee:
            return (
                store?.customFeeLabels?.convenienceStoreDescription ||
                "Set by our restaurant to cover operational costs."
            );
        case ReservedFeeEnum.BagStoreFee:
            return `${numberOfBags} bag(s) at ${toDollar(fee.fee.flat)} each`;
        default:
            return undefined;
    }
};

export const isServiceFee = (policyName: string | ReservedFeeEnum): boolean =>
    [
        //  based this off of RESERVED_FEE_TO_LABEL
        ReservedFeeEnum.ConvenienceFee,
        ReservedFeeEnum.ConvenienceStoreFee,
        ReservedFeeEnum.SnackpassConvenienceFee,
        ReservedFeeEnum.OnlineOrderingConvenienceFee,
        ReservedFeeEnum.OnlineOrderingConvenienceStoreFee,
        ReservedFeeEnum.KioskConvenienceFee,
        ReservedFeeEnum.KioskConvenienceStoreFee
    ].includes(policyName as ReservedFeeEnum);
