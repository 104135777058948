import { DateTime } from "luxon";

import { containsTime } from "./containsTime";
import { FromLegacyHours, fromLegacy } from "./legacy";

/**
 * Returns whether the store is open at the provided date-time in unix time (timeMs).
 *
 * By default, timeMs is now in Store's timezone.
 */
export const isStoreOpen =
    (hours: FromLegacyHours) =>
    (timeMs = DateTime.now().toMillis()): boolean =>
        containsTime(fromLegacy(hours))(timeMs);

/**
 * Returns whether the store is open for delivery at the provided date-time in unix time (timeMs).
 *
 * By default, timeMs is now in Store's timezone.
 */
export const isStoreOpenForDelivery =
    (storeHours: FromLegacyHours, deliveryHours?: FromLegacyHours | false) =>
    (timeMs = DateTime.now().toMillis()): boolean =>
        containsTime(fromLegacy(deliveryHours || storeHours))(timeMs);
