import {
    ChargeType,
    IPurchase,
    IZageCharge,
    PaymentProvider
} from "@snackpass/snackpass-types";

export const isZageCharge = (
    charge: IPurchase["charges"][number]
): charge is IZageCharge => charge.chargeType === ChargeType.zage;

export const getZageCreditCents = (purchase: IPurchase): number =>
    purchase.charges.length && isZageCharge(purchase.charges[0])
        ? purchase.charges[0]._amount - purchase.charges[0].amountChargedCents
        : 0;
