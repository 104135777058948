import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import { useSelector, useDispatch } from "react-redux";
import {
    hideModal,
    ModalsName,
    getIsUpsellModalOpen,
    setIsUpsellAlreadyShown,
} from "@/redux";
import { useMediaQuery } from "react-responsive";
import { UpsellCard } from "./UpsellCard";
import { useUpsell } from "@/hooks/useUpsell";
import { Button } from "@/components/ui/button";
import {
    Sheet,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const UpsellModal = () => {
    const { upsellProducts, hasUpsellProduct } = useUpsell();
    const dispatch = useDispatch();
    const upsellModal = useSelector(getIsUpsellModalOpen);
    const handleHideUpSellModal = () => {
        dispatch(hideModal(ModalsName.upsellModal));
        dispatch(setIsUpsellAlreadyShown(true));
    };
    const isDesktop = useMediaQuery({ minWidth: 768 });

    if (!hasUpsellProduct) {
        return null;
    }
    if (isDesktop) {
        return (
            <Dialog open={upsellModal} onOpenChange={handleHideUpSellModal}>
                <DialogContent
                    onEscapeKeyDown={handleHideUpSellModal}
                    className="hidden-scroll h-auto max-h-[60%] min-h-[20%] overflow-scroll rounded-lg"
                >
                    <div className="hidden-scroll relative flex h-full w-full flex-col overflow-y-auto">
                        <div className="sticky right-0 top-0 z-[101] flex justify-center border-b bg-white">
                            <DialogClose className="flex gap-0" />
                            <div className="sticky top-0 h-auto w-auto bg-white md:m-auto">
                                <div className="flex h-14 items-center text-base font-semibold leading-normal text-primary">
                                    Recommended for You
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <div className="gap-4 flex flex-col overflow-auto hidden-scroll py-3 bg-white flex-wrap">
                                {upsellProducts.map((product) => (
                                    <UpsellCard
                                        key={product._id}
                                        product={product}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <DialogFooter className="bg-white border-t md:px-6 p-4">
                        <Button
                            onPress={handleHideUpSellModal}
                            label="No thanks"
                            className="w-full m-auto"
                        />
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={upsellModal} onOpenChange={handleHideUpSellModal}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle> Recommended for You</SheetTitle>
                </SheetHeader>
                <div className="overflow-auto pb-20">
                    <div className="mt-2">
                        <div className="gap-4 flex flex-col overflow-auto hidden-scroll py-3 bg-white flex-wrap">
                            {upsellProducts.map((product) => (
                                <UpsellCard
                                    key={product._id}
                                    product={product}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <SheetFooter className="gap-0 border-t px-4 pt-4">
                    <Button
                        onPress={handleHideUpSellModal}
                        label="No thanks"
                        className="w-full m-auto"
                    />
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default UpsellModal;
