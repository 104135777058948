import React from "react";
import OrderItem from "./components/OrderItem";
import { match } from "react-router-dom";
import { ErrorScreen } from "../ErrorScreen";
import { FetchingState, useLoadPastOrders } from "@/hooks/useLoadPastOrders";
import { useSelector } from "react-redux";
import { selectPastOrders } from "@/redux";

type Props = {
    match: match<{ storeNameAndId: string }>;
};

export function PastOrdersScreen(props: Props) {
    const { match } = props;
    const { storeNameAndId } = match.params;
    const { fetchingState } = useLoadPastOrders(storeNameAndId);
    const orders = useSelector(selectPastOrders);

    if (fetchingState === FetchingState.Error) {
        return <ErrorScreen message="Failed to load past orders." />;
    }

    return (
        <div className="relative">
            <div className="flex px-4 bg-white border-b  sticky top-0 right-0 z-[101]">
                <div className="py-4 flex items-center text-primary text-base font-semibold leading-normal flex-1 w-full">
                    Past Orders
                </div>
            </div>
            {orders.length > 0 ? (
                <div className="space-y-4 px-4 pt-4 pb-12">
                    {orders.map((order) => (
                        <OrderItem key={order._id} order={order} />
                    ))}
                </div>
            ) : (
                fetchingState === FetchingState.Success && (
                    <ErrorScreen message="Your past orders will show up here." />
                )
            )}
        </div>
    );
}
