import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { getActiveStore } from "@/redux";

const PRIVACY_POLICY_URL =
    "https://legal.snackpass.co/snackpass-privacy-policy";
const TERMS_URL = "https://legal.snackpass.co/snackpass-customer-terms-of-use";

function MarketingConsentTerms() {
    const activeStore = useSelector(getActiveStore);
    return (
        <p className="text-xs text-gray-500">
            By signing up, you agree to receive promotional and personalized
            marketing text messages from {activeStore?.name} and Snackpass.
            Message frequency varies. Joining is not a condition of any
            purchase. Reply HELP for help. Reply STOP to opt out. Message and
            data rates may apply. Visit our{" "}
            <Link
                to={{
                    pathname: PRIVACY_POLICY_URL,
                }}
                target={"_blank"}
            >
                Privacy Policy
            </Link>{" "}
            and{" "}
            <Link
                to={{
                    pathname: TERMS_URL,
                }}
                target={"_blank"}
            >
                Terms of Service
            </Link>
            .
        </p>
    );
}

export default MarketingConsentTerms;
