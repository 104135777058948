import SettingsAlert from "src/SharedComponents/SettingsAlert";
import { ItemSearchBar } from "./ItemSearchBar";
import DesktopCartButton from "./DesktopCartButton";

export const DesktopStoreHeader = () => {
    return (
        <div className="max-w-[1280px] hidden h-[74px] w-[100%] flex-row items-center justify-between pr-6 2xl:pr-0 lg:flex">
            <div className="ml-4 2xl:ml-0 flex w-[30%] flex-row items-center gap-4">
                <div className="flex-1">
                    <ItemSearchBar />
                </div>
            </div>
            <div className="flex flex-row items-center justify-center space-x-5">
                <SettingsAlert />
                <DesktopCartButton />
            </div>
        </div>
    );
};

export default DesktopStoreHeader;
