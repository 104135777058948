import { cn } from "@/utils";
import React, { Component, CSSProperties } from "react";

function decimalPlaces(num) {
    var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
        return 0;
    }
    return Math.max(
        0,
        // Number of digits right of decimal point.
        (match[1] ? match[1].length : 0) -
            // Adjust for scientific notation.
            (match[2] ? +match[2] : 0)
    );
}

const filterNumber = value => {
    return !isNaN(value) && decimalPlaces(value) <= 2;
    // const regexp = /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/;
    // return regexp.test(value);
};

const filterInteger = value => {
    const regexp = /^(?:[1-9]\d*|\d)$/;
    return regexp.test(value);
};

type Props = {
    label?: string;
    value?: any;
    placeholder?: string;
    onSubmit?: Function;
    onEscape?: Function;
    onChangeText: (value: string) => void;
    type?: string;
    style?: Object;
    number?: boolean;
    integer?: boolean;
    spellCheck?: boolean;
    required?: boolean;
    classNames?: string;
};

export class TextInput extends Component<Props> {
    render() {
        const {
            label,
            value,
            type,
            placeholder,
            style,
            number,
            integer,
            spellCheck,
            required,
            classNames
        } = this.props;

        return (
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                {label ? <p /> : null}
                <input
                    placeholder={placeholder || ""}
                    onKeyDown={e => {
                        try {
                            if (e.key === "Enter") {
                                if (this.props.onSubmit) this.props.onSubmit();
                            }
                            if (e.key === "Escape") {
                                if (this.props.onEscape) this.props.onEscape();
                            }
                        } catch (err) {}
                    }}
                    onChange={e => {
                        if (!this.props.onChangeText) {
                            return;
                        }
                        var value = e.target.value;

                        // if number, filter
                        if (number) {
                            if (filterNumber(value)) {
                                this.props.onChangeText(value);
                            }
                            return;
                        }
                        if (integer) {
                            if (filterInteger(value)) {
                                this.props.onChangeText(value);
                            }
                            return;
                        }
                        this.props.onChangeText(value);
                    }}
                    type={type || "text"}
                    value={value}
                    style={{ ...style } as CSSProperties}
                    spellCheck={spellCheck || false}
                    required={required}
                    className={cn(classNames)}
                />
            </div>
        );
    }
}
