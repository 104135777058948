import { DateTime } from "luxon";
import type { TimeOfDayWithZone } from "src/types";

/**
 * Formats the DateTime to "h:mma".
 */

export const format = {
    hoursMinutes: ({ hour, minute, zone }: TimeOfDayWithZone) =>
        DateTime.now().setZone(zone).set({ hour, minute }).toFormat("h:mma")
};
