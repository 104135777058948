import React, { CSSProperties, useState } from "react";
import { Colors } from "../../utils";
import fp from "lodash/fp";
import { CardRow } from "./components/CardRow";
import { View, Text } from "../../SharedComponents";
import styles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { getUser, getUserCards, setUser } from "src/redux";
import { SystemColors } from "@snackpass/design-system";
import { ModalHeader } from "src/modals/GIftCardPhoneAuthenticationModal/Header";
import { GiftCreditCardInput } from "src/components/GiftCreditCardInput";
import CreditCard from "src/assets/icons/creditCard.back.svg";
import PlusDark from "src/assets/icons/plusDark.svg";

export type GiftCardCreditCardModalProps = {
    handleHide: () => void;
    showGiftcard: boolean;
};

const SAVED_CARDS = "SAVED CARDS";
const PAYMENT_METHOD = "Payment Method";

export const GiftCardCreditCardModal = ({
    handleHide,
    showGiftcard,
}: GiftCardCreditCardModalProps) => {
    const user = useSelector(getUser);
    const cards = useSelector(getUserCards);
    const activeCardIndex = user?.activeCardIndex;
    const [addCardView, setAddCardView] = useState<boolean>(cards.length === 0);

    const dispatch = useDispatch();
    const _setUser = fp.compose(dispatch, setUser);

    const handleSuccess = () => {
        setAddCardView(false);
        handleHide();
    };

    const CardsView = () => {
        return (
            <View style={styles.card}>
                <ModalHeader
                    title={PAYMENT_METHOD}
                    closeFunc={handleHide}
                    icon="arrow"
                />
                <View style={stylesLocal.subHeader}>
                    <View style={stylesLocal.savedCards}>
                        <img
                            alt="Credit Card"
                            style={stylesLocal.imgIcon}
                            src={CreditCard}
                        />
                        <Text style={stylesLocal.slideTitle}>
                            {SAVED_CARDS}
                        </Text>
                    </View>
                    <View
                        style={stylesLocal.paymentMethod}
                        onPress={() => setAddCardView(true)}
                    >
                        <img
                            alt="Credit Card"
                            style={stylesLocal.imgIcon}
                            src={PlusDark}
                        />
                        <Text style={stylesLocal.paymentMethodText}>
                            {PAYMENT_METHOD}
                        </Text>
                    </View>
                </View>
                {cards.map((card, index) => {
                    return (
                        <CardRow
                            key={index}
                            cardIndex={index}
                            card={card}
                            activeCardIndex={activeCardIndex}
                            setUser={_setUser}
                        />
                    );
                })}
            </View>
        );
    };

    const CardInputView = () => {
        return (
            <div
                className="animated fadeIn fast"
                style={{
                    alignSelf: "center",
                    background: SystemColors.white,
                    borderRadius: 4,
                }}
            >
                <GiftCreditCardInput
                    cardInputName={user?.name}
                    onSuccess={handleSuccess}
                    closeFunc={() =>
                        cards.length > 0 ? setAddCardView(false) : handleHide()
                    }
                    settingGiftcard={showGiftcard}
                />
            </div>
        );
    };

    return (
        <div data-private style={stylesLocal.elementContainer}>
            {addCardView ? CardInputView() : CardsView()}
        </div>
    );
};

const stylesLocal = {
    slideTitle: {
        textAlign: "center",
        padding: "5%",
        fontWeight: 500,
        fontSize: "100%",
        letterSpacing: 1,
        color: "#606C76",
    } as CSSProperties,
    elementContainer: {
        width: "100%",
        height: "100%",
    } as CSSProperties,

    subHeader: {
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        padding: "2%",
        height: "100%",
    } as CSSProperties,

    savedCards: {
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        alignContent: "flex-start",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        padding: "2%",
        height: "100%",
    } as CSSProperties,

    paymentMethod: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        padding: "2%",
        height: "100%",
        background: "#F1F5F8",
        border: "1px solid #F1F5F8",
        boxSizing: "border-box",
        borderRadius: 56,
        cursor: "pointer",
        maxWidth: 300,
    } as CSSProperties,

    paymentMethodText: {
        textAlign: "center",
        fontWeight: 500,
        fontSize: "100%",
        color: Colors.darkerGrey,
        paddingLeft: "5%",
    } as CSSProperties,

    imgIcon: {
        width: "10%",
    } as CSSProperties,

    subTitle: {
        width: "100%",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 12,
        textAlign: "start",
        color: "#606C76",
        padding: "2%",
    } as CSSProperties,
};
