import { IStore, ITimeRangeSchema } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import fp from "lodash/fp";
import { Constants } from "../../utils";

export const getBatchForSlot =
    (batchSize: number, batchMapping: BatchMapping, timeSlots: TimeSlot[]) =>
    (date: moment.Moment): BatchInfo | null => {
        const selectedBatch = findBatch(date, timeSlots);

        if (selectedBatch) {
            const key = getBatchKey(selectedBatch);
            const batchInfo = batchMapping[key];
            const slotsTaken = batchInfo ? batchInfo.numberOfOrders : 0;
            const limitedSlots = batchSize > 0;
            const slotsOpen =
                batchSize > 0 ? Math.max(0, batchSize - slotsTaken) : 0;

            return {
                batch: selectedBatch,
                limitedSlots,
                slotsTaken,
                slotsOpen,
                isFull: limitedSlots ? slotsOpen <= 0 : false
            };
        }

        return null;
    };

export const isBatchFull =
    (getBatchForSlotCurried: ReturnType<typeof getBatchForSlot>) =>
    (date: moment.Moment): boolean => {
        const batch = getBatchForSlotCurried(date);
        return batch ? batch.isFull : false;
    };

export const isSlotAvailable =
    (
        store: IStore | null,
        isBatchFullCurried: ReturnType<typeof isBatchFull>
    ) =>
    (slot: moment.Moment): boolean => {
        const batchIsFull = isBatchFullCurried(slot);
        const slotOpen =
            store && store.closedUntil
                ? slot.isAfter(moment(store.closedUntil))
                : true;
        const isSlotAfterScheduleAheadMinLeadTime =
            store && store.scheduledOrderMinLeadTime
                ? slot.isAfter(
                      moment().add(
                          moment.duration(
                              store.scheduledOrderMinLeadTime,
                              "minutes"
                          )
                      )
                  )
                : true;
        return !batchIsFull && slotOpen && isSlotAfterScheduleAheadMinLeadTime;
    };

/**
 * If a given time range fits within the start and end of a day.
 *
 * Ex: let's say we are talking about Monday, which is 0 - 1440 minutes
 * The range 600-1000 will fit within the day, but so will 1440 - 1560, bc 1400 <= 1400
 * we need to do this in the case where a restaurant is open from 8pm-12am and 12am-2am (instead of just 8pm-2am)
 * where we want the 12am-2am to be included range so that we don't prevent the user from ordering at 12am sharp
 * we have a buffer at the start and end of a range, so we need to include ranges that overlap so we can properly
 * apply buffers to the start and end of a day depending on the "merged" ranges. Each of these ranges that
 * fits within a day are merged using the _mergeRanges() fxn, so including ALL overlaps is necessary
 * to merge and properly apply buffers.
 */
export const _fitsWithinDay =
    (startOfDayMinutes: number, endOfDayMinutes: number) =>
    (range: ITimeRangeSchema): boolean => {
        // if the date sits cleanly between the range. ex. checking Tuesday and the range is 10am-4pm on Tuesday
        const sitsWithinRange =
            startOfDayMinutes <= range.start && range.end <= endOfDayMinutes;
        // if the end is greater than the start of day and less than the end
        // ex. Monday is open 8pm-2am,
        const isBleedingStart =
            startOfDayMinutes <= range.end && range.end <= endOfDayMinutes;
        // ex. Monday open 8pm - 2am, should get the 8pm-12am
        const isBleedingEnd =
            range.start <= endOfDayMinutes && endOfDayMinutes <= range.end;

        return sitsWithinRange || isBleedingStart || isBleedingEnd;
    };

/**
 * Merges ranges with start / end times. this happens if ops makes the hours
 * from 8pm-12am and then 12am-2am instead of just 8pm-2am
 */
export const _mergeRanges = fp.reduce(
    (acc: ITimeRangeSchema[], elem: ITimeRangeSchema) => {
        const lastElem = acc[acc.length - 1];

        // if the last element has an end equal to the start, overwrite the end
        // to the the element's end
        // Ex. 8pm-12am, 12am-2am, would then become 8pm-2am
        if (lastElem && lastElem.end === elem.start) {
            return [
                ...acc.slice(0, -1),
                {
                    ...lastElem,
                    end: elem.end
                }
            ];
        }

        return [...acc, elem];
    },
    [] as ITimeRangeSchema[]
);

export const getRangesForDay = (
    day: moment.Moment | null,
    store: IStore
): ITimeRangeSchema[] => {
    if (!day) {
        return [];
    }

    const sortedHours = fp.orderBy(
        "start",
        "asc",
        fp.getOr([], "hours.local", store)
    ) as ITimeRangeSchema[];

    const startOfDayMinutes = day.diff(
        day.startOf("day").clone().startOf("isoWeek"),
        "minutes"
    );

    // end of day is 12:00am of the next day, not 11:59pm of the current day
    // reason being is if a store is open from 9pm-12am, we want to include
    // this range which would otherwise be left out if it was 11:59pm
    const endOfDayMinutes =
        day.diff(day.endOf("day").clone().startOf("isoWeek"), "minutes") + 1;

    return fp.flow(
        fp.filter(_fitsWithinDay(startOfDayMinutes, endOfDayMinutes)), // Find all ranges with a start time gte the start of the day.
        _mergeRanges // merge them together if they overlap (ex. 8pm-12am 12am-2am becomes 8pm-2am)
    )(sortedHours);
};

export const computeTimeSlots = (
    selectedWeekDay: moment.Moment,
    store: IStore,
    isCatering: boolean,
    selectedRange: ITimeRangeSchema | null
): TimeSlot[] => {
    if (!selectedRange) {
        return [];
    }

    const minLeadTimeHours = moment.duration(
        store.catering?.minLeadTime ?? 48,
        "hours"
    );
    const cateringMinutes = minLeadTimeHours.asMinutes();
    const scheduleAheadFirstTimeSlotBuffer =
        store.scheduleAheadFirstTimeSlotBuffer || 0;
    const cateringEnabled = isCatering && store.cateringEnabled;

    const storeScheduleAheadBuffer = cateringEnabled
        ? cateringMinutes
        : scheduleAheadFirstTimeSlotBuffer;
    const startOfDay = selectedWeekDay.clone().startOf("day");
    const endOfDay = selectedWeekDay.clone().endOf("day");
    const start = cateringEnabled
        ? selectedRange.start + scheduleAheadFirstTimeSlotBuffer
        : selectedRange.start + storeScheduleAheadBuffer;
    const intervalSize =
        cateringEnabled || !store.scheduleAheadInterval
            ? Constants.DEFAULT_INTERVAL_TIME
            : store.scheduleAheadInterval;
    const end = selectedRange.end - intervalSize;

    const times: TimeSlot[] = [];
    const minStartTime = moment().add(storeScheduleAheadBuffer, "minutes");
    let temp = start;

    while (temp <= end) {
        const startTime = selectedWeekDay
            .clone()
            .startOf("isoWeek")
            .add(temp, "minutes")
            .startOf("minute");
        const endTime = selectedWeekDay
            .clone()
            .startOf("isoWeek")
            .add(temp, "minutes")
            .add(intervalSize, "minutes")
            .startOf("minute");
        temp += intervalSize;

        if (
            startTime.isAfter(minStartTime) &&
            startTime.diff(startOfDay, "minutes") >= 0 &&
            startTime.diff(endOfDay, "minutes") <= 0
        ) {
            times.push([startTime, endTime]);
        }
    }

    return times;
};

export const computeAllTimeSlots = (
    date: moment.Moment,
    store: IStore,
    ranges: ITimeRangeSchema[],
    isCatering: boolean
): TimeSlot[] =>
    fp.flow(
        fp.map(fp.curry(computeTimeSlots)(date.clone(), store, isCatering)),
        fp.flatten
    )(ranges);

export const findBatch = (
    time: moment.Moment,
    timeSlots: TimeSlot[]
): TimeSlot | null =>
    timeSlots.find(
        (slot) => time.diff(slot[0]) >= 0 && time.isBefore(slot[1])
    ) || null;

export const getBatchKey = (batch: TimeSlot): string =>
    `${batch[0].format("hh:mm a")} - ${batch[1].format("hh:mm a")}`;

export const generateBatchMapping = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    firebaseBatches: any,
    timeSlots: TimeSlot[]
): BatchMapping => {
    if (!firebaseBatches) {
        return {};
    }

    const batchMapping: BatchMapping = {};

    for (const [scheduledTime, value] of Object.entries<{
        numberOfOrders?: number;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    }>(firebaseBatches)) {
        const batchTime = moment(new Date(scheduledTime));
        const batch = findBatch(batchTime, timeSlots);

        if (batch) {
            const key = getBatchKey(batch);
            const totalNumberOfOrders = fp.getOr(
                0,
                "numberOfOrders",
                batchMapping[key]
            );
            const numberOfOrders = fp.getOr(0, "numberOfOrders", value);

            batchMapping[key] = {
                numberOfOrders: totalNumberOfOrders + numberOfOrders
            };
        }
    }

    return batchMapping;
};

// === TYPES === //
export type TimeSlot = [moment.Moment, moment.Moment];

export type BatchMapping = {
    [interval: string]: {
        numberOfOrders: number;
    };
};

export type BatchInfo = {
    batch: TimeSlot;
    limitedSlots: boolean;
    slotsTaken: number;
    slotsOpen: number;
    isFull: boolean;
};
