import React from "react";
import { BeatLoader } from "react-spinners";

import * as Colors from "src/utils/Colors";
import { View } from "src/SharedComponents/View";

const LoadingScreen = () => (
    <View
        style={{
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <BeatLoader color={Colors.black} size={10} />
    </View>
);

export default LoadingScreen;
