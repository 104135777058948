import React from "react";
import {
    getPurchaseQueueStatus,
    isPurchaseSubscribable,
} from "@/redux/purchaseReceipt";
import { cn } from "@/utils";
import { IPurchase } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Divider } from "@/SharedComponents";

function QueueRow({ number, name, isMyPurchase, isLastInQueue }) {
    return (
        <div
            className={`self-stretch px-5 py-4 bg-white ${
                isLastInQueue ? "" : "border-b border-zinc-200"
            } justify-start items-center gap-4 inline-flex`}
        >
            <div className=" flex-row grow shrink basis-0 self-stretch  justify-start items-start gap-2 inline-flex">
                <div
                    className={cn(
                        `self-stretch justify-start items-center gap-2 inline-flex text-base font-semibold leading-normal`,
                        {
                            "text-stone-950": !isMyPurchase,
                            "text-brandColor": isMyPurchase,
                        },
                    )}
                >
                    #{number}
                </div>
                <div
                    className={cn(`text-base font-normal leading-normal`, {
                        "text-stone-950": !isMyPurchase,
                        "text-brandColor": isMyPurchase,
                    })}
                >
                    {name}
                </div>
            </div>
        </div>
    );
}

function OrderQueue({ purchase }: { purchase: IPurchase }) {
    const queue = useSelector(getPurchaseQueueStatus);
    return (
        <div className="w-auto h-auto bg-white flex-col justify-start items-start inline-flex">
            {queue.map((minimalIPurchase, idx) => (
                <QueueRow
                    key={minimalIPurchase._id}
                    number={minimalIPurchase.receiptNumber}
                    name={minimalIPurchase.firstName}
                    isMyPurchase={purchase._id === minimalIPurchase._id}
                    isLastInQueue={idx === queue.length - 1}
                />
            ))}
        </div>
    );
}

type QueueProps = {
    purchase: IPurchase;
};

export function Queue({ purchase }: QueueProps) {
    const queue = useSelector(getPurchaseQueueStatus);
    const indexFrom0 = queue.findIndex((q) => q._id === purchase._id);

    if (!isPurchaseSubscribable(purchase)) {
        return null;
    }
    return (
        <>
            <div className="flex flex-col w-full max-w-600 mx-auto p-4">
                <div className="w-auto px-4 justify-start items-center gap-6 inline-flex">
                    <div className="grow shrink basis-0 text-stone-950 text-xl font-semibold leading-normal">
                        Queue
                    </div>
                    <div className="text-zinc-600 text-xl font-medium leading-normal">
                        {moment.localeData().ordinal(indexFrom0 + 1)} in line
                    </div>
                </div>
                <OrderQueue purchase={purchase} />
            </div>
            <Divider width={8} />
        </>
    );
}
