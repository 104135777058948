import React from "react";
import ViewOrderContent from "@/modals/ViewOrderModal/ViewOrderContent";
import {
    CartActions,
    CartSelectors,
    CheckoutSelectors,
    getUser,
} from "@snackpass/accounting";
import { debounce } from "lodash";
import fp from "lodash/fp";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { getActiveStore, getUserIsLoggedIn } from "src/redux";
import { Divider } from "../../SharedComponents";
import StoreLocations from "../StoreScreen/components/StoreLocations";
import BagFee from "./BagFee";
import CheckoutButton from "./CheckoutButton";
import PaymentSection from "./PaymentSection";
import Tip from "./Tip";
import TotalCharge from "./TotalCharge";
import GiftCardInput from "./GiftCardInput";
import Rewards from "./Rewards";
import ScheduleTime from "../StoreScreen/components/ScheduleTime";
import Fulfillment from "../StoreScreen/components/Fulfillment";
import { CateringButton } from "../StoreScreen/components/CateringButton";

const Content = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return isMobile ? <MobileContent /> : <DesktopContent />;
};

const MobileContent = () => {
    const activeStore = useSelector(getActiveStore);
    const tippingEnabled = activeStore?.collectTip ?? true;
    const isCatering = useSelector(CartSelectors.getCartIsCatering);

    if (!activeStore) return null;

    return (
        <>
            <div className="my-auto w-[100%] flex-1 rounded-tr-xl bg-white pb-[180px]">
                <div className="mt-[70px]">
                    <StoreLocations cartScreen />
                    <Divider width={1} className="ml-4" />
                    <ScheduleTime cartScreen />
                    <Divider width={1} className="ml-4" />
                    <Fulfillment cartScreen />
                    {isCatering && (
                        <>
                            <Divider width={1} className="ml-4" />
                            <CateringButton cartScreen />
                        </>
                    )}
                    <Divider width={1} className="ml-4" />
                    {tippingEnabled ? (
                        <>
                            <div className="px-4 pt-4">
                                <Tip />
                            </div>
                            <Divider width={1} className="ml-4" />
                        </>
                    ) : null}
                    <BagFee />
                    <Divider width={8} />
                    <GiftCardInput />
                    <Divider width={8} />
                    <TotalCharge />
                    <Divider width={8} />
                    <Rewards />
                    <PaymentSection />
                    <div className="h-fit">
                        <CheckoutButton />
                    </div>
                </div>
            </div>
        </>
    );
};

const DesktopContent = () => {
    const activeStore = useSelector(getActiveStore);
    const tippingEnabled = activeStore?.collectTip ?? true;
    const isCatering = useSelector(CartSelectors.getCartIsCatering);

    if (!activeStore) return null;

    const backLink = `/${activeStore.slug}`;

    return (
        <div className="mt-[128px] flex w-full flex-row gap-6 pb-12">
            <div className="hidden-scroll ml-4 basis-1/2 overflow-scroll">
                <div className="rounded-xl border py-4">
                    <div className="px-4 text-2xl font-semibold text-primary">
                        {activeStore.name}
                    </div>
                    <div className="flex flex-col px-2 py-2">
                        <StoreLocations cartScreen />
                    </div>
                    <Divider width={1} className="ml-6" />
                    <div className="px-2 py-2">
                        <ScheduleTime cartScreen />
                    </div>
                    <Divider width={1} className="ml-6" />
                    <div className="px-2 py-2">
                        <Fulfillment cartScreen />
                    </div>
                    {isCatering && (
                        <>
                            <Divider width={1} className="ml-6" />
                            <div className="px-2 py-3">
                                <CateringButton cartScreen />
                            </div>
                        </>
                    )}
                    <OrderNotes />
                </div>
                <div className="mt-6 rounded-xl border py-4">
                    <div className="px-6 text-2xl font-semibold text-primary">
                        Items
                    </div>
                    <ViewOrderContent hideOrderNotes showDivider />
                    <Link to={backLink}>
                        <div className="mx-6 mt-4 flex items-center justify-center rounded-xl border border-input bg-background p-2 hover:bg-tertiary hover:text-tertiary-foreground">
                            <span
                                className="font-semibold text-primary"
                                aria-label="Add items"
                            >
                                Add Items
                            </span>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="hidden-scroll mr-4 basis-1/2 overflow-scroll">
                <div className="rounded-xl border pb-0 pt-4">
                    <div className="px-6 text-2xl font-semibold text-primary">
                        Summary
                    </div>
                    {tippingEnabled ? (
                        <>
                            <div className="px-6 pb-1 pt-6">
                                <Tip />
                            </div>
                            <Divider width={1} className="ml-6" />
                        </>
                    ) : null}
                    <BagFee />
                    <GiftCardInput />
                    <Divider width={1} className="ml-6" />
                    <TotalCharge />
                    <Rewards />
                    <PaymentSection />
                    <div className="mt-6 h-fit w-[100%] items-center">
                        <CheckoutButton />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const OrderNotes = () => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const cartNotes = useSelector(CheckoutSelectors.getNotes);
    const [notes, setNotes] = useState<string>(cartNotes || "");
    const _setCartNotes = fp.compose(dispatch, CartActions.setCartNotes);
    const noNotes = fp.getOr(false, "noNotes", activeStore);

    const debouncedSetCartNotes = debounce((value: string) => {
        _setCartNotes(value);
    }, 500);

    const handleNotesChange = (e) => {
        const value = e.target.value;
        // Update the local state immediately for UI feedback
        setNotes(value);
    };

    useEffect(() => {
        debouncedSetCartNotes(notes);
        return () => {
            debouncedSetCartNotes.cancel(); // Cancel any pending debounced calls
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notes]);

    // If no notes are enabled for the store,
    // return null / don't show the notes
    if (noNotes) {
        return null;
    }

    return (
        <>
            <Divider width={1} className="ml-6" />
            <div className="p-6">
                <div className="pb-2 text-base font-medium leading-6 text-primary">
                    Order notes
                </div>
                <textarea
                    placeholder="Add special instructions"
                    className="h-[80px] w-[100%] resize-y self-stretch rounded-lg bg-white p-[10px] text-sm font-normal text-secondary shadow-[0_0_0_.5px_rgba(239,239,239,1)]"
                    value={notes || ""}
                    onChange={handleNotesChange}
                />
            </div>
        </>
    );
};

export default Content;
