import { CSSProperties } from "react";
import React from "react";
import { Text, View } from "src/SharedComponents";
import { Row, Col } from "react-bootstrap";
import {
    COL_FULL_SIZE,
    errorStyles,
    GiftCardFormProps,
    inputStyles,
    outerContainer,
    subTitle,
} from "../lib";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getUserIsLoggedIn } from "src/redux";

const SENDER = "SENDER";

export const GiftCardSender = ({
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isKioskPurchase,
}: GiftCardFormProps) => {
    const recipient = _.isEmpty(values.toName)
        ? "the recipient"
        : values.toName;
    const alertText = `You'll receive a receipt in your email and get a notification when ${recipient} redeems your gift card.`;
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const isAutoCompleteOn = isKioskPurchase ? "off" : "on";
    return (
        <View style={outerContainer}>
            <Row className="justify-content-lg-center row-centered">
                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <Text style={subTitle}>{SENDER}</Text>
                </Col>

                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    {errors.fromName && touched.fromName && (
                        <View style={errorStyles}>{errors.fromName}</View>
                    )}
                </Col>

                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <input
                        name="fromName"
                        value={values.fromName}
                        style={inputStyles(
                            errors.fromName,
                            touched.fromName,
                            isUserLoggedIn
                        )}
                        type={"text"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={"Sender Name"}
                        className="gfInput"
                        disabled={isUserLoggedIn}
                        autoComplete={isAutoCompleteOn}
                    />
                </Col>

                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    {errors.fromEmail && touched.fromEmail && (
                        <View style={errorStyles}>{errors.fromEmail}</View>
                    )}
                </Col>
                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <input
                        name="fromEmail"
                        value={values.fromEmail}
                        style={inputStyles(
                            errors.fromEmail,
                            touched.fromEmail,
                            isUserLoggedIn && !!values.fromEmail
                        )}
                        type={"email"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={"Email Address"}
                        className="gfInput"
                        autoComplete={isAutoCompleteOn}
                    />
                </Col>
                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <Text style={styles.alertText}>{alertText}</Text>
                </Col>
            </Row>
        </View>
    );
};

const styles = {
    alertText: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        textAlign: "start",
        color: "#606C76",
        padding: "3%",
        wordWrap: "break-word",
        borderRadius: 8,
        width: "100%",
        maxWidth: 768,
        marginBottom: "2%",
        backgroundColor: "#F1F5F8",
    } as CSSProperties,
};
