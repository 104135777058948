import React, { createContext } from "react";
import { Fulfillment, FulfillmentTypeEnum } from "@snackpass/snackpass-types";
import { noop } from "lodash";

type UseFulfillmentModalContextType = {
    modalFulfillment: Fulfillment;
    setModalFulfillment: React.Dispatch<React.SetStateAction<Fulfillment>>;
};

export const FulfillmentModalContext =
    createContext<UseFulfillmentModalContextType>({
        modalFulfillment: FulfillmentTypeEnum.Pickup,
        setModalFulfillment: noop,
    });
