import React from "react";
import { CheckoutSelectors } from "@snackpass/accounting";
import { useSelector } from "react-redux";
import { getIsApplePayActive } from "src/redux";
import { PaymentRequestButton } from "@/components/Payment";
import { Button } from "@/components/ui/button";
import { PaymentRequestTokenEvent } from "@stripe/stripe-js";

const fetchingMessage = "Fetching Delivery Quote...";

type CheckoutActionProps = {
    isLoading: boolean;
    isValid: boolean;
    isFetchingQuote: boolean;
    errorComponent?: JSX.Element | null;
    errorMessage?: string | null;
    errorType?: string | null;
    onPress: (paymentRequestEvent?: PaymentRequestTokenEvent) => void;
};

// Create purchase(finish payment) is one and only responsibility of this component
// all the validation checks are in parent component

const PayNowButton = ({
    onPress,
    isLoading,
    isFetchingQuote,
    isValid,
}: CheckoutActionProps) => {
    const total = useSelector(CheckoutSelectors.getTotal);
    const isApplePayActive = useSelector(getIsApplePayActive);

    const buttonLabel = isFetchingQuote
        ? fetchingMessage
        : isValid && total === 0
          ? "Free"
          : "Pay Now";

    if (isApplePayActive && isValid) {
        return (
            <div className="w-[100%] m-2 items-center justify-center">
                <PaymentRequestButton
                    showButtonOnly
                    onPress={onPress}
                    isLoading={isLoading || isFetchingQuote}
                />
            </div>
        );
    }

    return (
        <Button
            onPress={onPress}
            loading={isLoading || isFetchingQuote}
            className="w-[100%] mx-0 mb-4"
            disabled={!isValid}
            label={buttonLabel}
            variant="brand"
        />
    );
};

export default PayNowButton;
