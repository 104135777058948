import { DealSelectionReducer } from "../../types";

import { attachTaxInfoTo } from "../../accountingFormulas/attachTaxInfoTo";

import {
    DealSelectionActions,
    SET_ACTIVE_DEAL_ITEM,
    SET_DEAL_ITEM,
    CLEAR_DEAL_SELECTION,
    REMOVE_DEAL_ITEM
} from "./actions";

export const INITIAL_DEAL_SELECTION_STATE: DealSelectionReducer = {
    activeDealItem: null,
    selectedDealItems: {}
};

export function dealSelection(
    state: DealSelectionReducer = INITIAL_DEAL_SELECTION_STATE,
    action: DealSelectionActions
): DealSelectionReducer {
    switch (action.type) {
        // SET_DEAL_ITEM: for the active deal item, set the cart item
        // associated. In other words, for this deal item, choose the
        // associated product, addons and notes (which compose the cart item).
        case SET_DEAL_ITEM: {
            if (!state.activeDealItem) {
                return state;
            }

            const itemWithTaxInfo = attachTaxInfoTo(action.item, action.store);

            return {
                ...state,
                selectedDealItems: {
                    ...state.selectedDealItems,
                    [state.activeDealItem._id]: {
                        dealItem: { ...state.activeDealItem },
                        cartItem: itemWithTaxInfo
                    }
                }
            };
        }
        case REMOVE_DEAL_ITEM: {
            const selectedDealItems = state.selectedDealItems;
            delete selectedDealItems[action.dealItemId];

            return {
                ...state,
                selectedDealItems
            };
        }
        case SET_ACTIVE_DEAL_ITEM:
            return { ...state, activeDealItem: action.activeDealItem };
        case CLEAR_DEAL_SELECTION:
            return INITIAL_DEAL_SELECTION_STATE;
        default:
            return state;
    }
}
