import { IStore, IOrderItem, IProduct } from "@snackpass/snackpass-types";
import _ from "lodash";

export interface IStoreWithSlug extends IStore {}

export type CardForm = {
    number: string;
    expMonth: string;
    expYear: string;
    cvc: string;
};

export type PurchaseItemGrouping = {
    groupUuid: string;
    points: number;
    items: IOrderItem[];
    quantity: number;
    totalPrice: number;
    totalPriceAfterDiscount: number;
    basePrice: number;
    basePriceAfterDiscount: number;
};

const MaybeDefinition = undefined;
export type MaybeDefinition = undefined;
export type Maybe<T> = T | MaybeDefinition;
export const maybe = <T extends any = MaybeDefinition>(
    input?: T,
): T extends void
    ? MaybeDefinition
    : T extends null
      ? MaybeDefinition
      : T extends MaybeDefinition
        ? MaybeDefinition
        : Maybe<T> => {
    // @ts-expect-error 'undefined' is not assignable to type 'T extends ... ? undefined : Maybe<T>'
    return _.isNil(input) ? MaybeDefinition : input;
};

export type Timeout = number;

export enum CATEGORY_NAME {
    Popular = "Popular",
    PastOrder = "Previous Order",
    Promos = "Promos",
}
export type CategoryProduct = {
    category: string; // category name
    products: IProduct[]; // products
};
