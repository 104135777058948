import { useSelector } from "react-redux";
import {
    getActiveChain,
    getActiveChainStores,
    getActiveStore,
    hasLocationSet,
} from "@/redux";
import { getDistance } from "geolib";
import { getMyLocation } from "@snackpass/accounting";
import { useMemo } from "react";

export function useSortStores() {
    const activeChainStores = useSelector(getActiveChainStores);
    const myLocation = useSelector(getMyLocation);
    const activeStore = useSelector(getActiveStore);
    const activeChain = useSelector(getActiveChain);
    const locationSet = useSelector(hasLocationSet);

    const referenceLocation = locationSet
        ? myLocation
        : activeStore?.geolocation || activeChainStores[0].geolocation;

    const sortedStores = useMemo(() => {
        // Sort stores by distance to lat lng
        return (
            activeChainStores
                .sort((a, b) => {
                    const distanceA = getDistance(
                        {
                            latitude: referenceLocation.coordinates[1],
                            longitude: referenceLocation.coordinates[0],
                        },
                        {
                            latitude: a.geolocation.coordinates[1],
                            longitude: a.geolocation.coordinates[0],
                        },
                    );
                    const distanceB = getDistance(
                        {
                            latitude: referenceLocation.coordinates[1],
                            longitude: referenceLocation.coordinates[0],
                        },
                        {
                            latitude: b.geolocation.coordinates[1],
                            longitude: b.geolocation.coordinates[0],
                        },
                    );
                    return distanceA - distanceB;
                })
                // active chain must be fetched because it's the only way we can filter out the root store
                .filter(
                    (store) =>
                        activeChain && store._id !== activeChain.rootStore,
                )
        );
    }, [activeChainStores, referenceLocation, activeChain]);

    return { sortedStores };
}
