import React from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { CartSelectors } from "@snackpass/accounting";
import { getCartItems } from "@snackpass/accounting/build/src/redux/cart/selectors";
import { IPromotion, IReward } from "@snackpass/snackpass-types";
import {
    useUserStorePointsBalance,
    useActivePointsBalance,
    useDeductedPoints,
    useUserStoreRewardsNotInCart,
    useUserPassthroughRewardOptions,
    useStoreRewardPromoSummaries,
} from "@snackpass/loyalty-react";
import { cartItemFlatToPassthroughItem } from "@snackpass/loyalty";

import PromotionCard from "@/components/PromotionCard";
import { getUserIsLoggedIn, getUser, getActiveStore } from "@/redux";
import { ReactComponent as PointsIcon } from "src/assets/icons/points.svg";

type ModalContentProps = {
    activeRewardPromo: IPromotion | null;
    setActiveRewardPromo: React.Dispatch<
        React.SetStateAction<IPromotion | null>
    >;
    activeReward: IReward | null;
    setActiveReward: React.Dispatch<React.SetStateAction<IReward | null>>;
};

const ModalContent = ({
    activeRewardPromo,
    setActiveRewardPromo,
    activeReward,
    setActiveReward,
}: ModalContentProps) => {
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const user = useSelector(getUser);
    const activeStore = useSelector(getActiveStore);

    const cartItems = useSelector(getCartItems);
    const cart = useSelector(CartSelectors.getCart);

    const totalPoints = useUserStorePointsBalance(user?._id, activeStore?._id);
    const pointsBalance = useActivePointsBalance(
        user?._id,
        activeStore?._id,
        cartItemFlatToPassthroughItem(cartItems),
    );

    const pointsUsedInCart = useDeductedPoints(
        cartItemFlatToPassthroughItem(cartItems),
    );

    const filteredRewards = useUserStoreRewardsNotInCart(
        user?._id,
        activeStore?._id,
        cart,
    );

    const passthroughRewardOptions = useUserPassthroughRewardOptions(
        user?._id,
        activeStore?._id,
        cartItemFlatToPassthroughItem(cartItems),
    );

    const promotionSummary = useStoreRewardPromoSummaries(activeStore?._id);

    const handleClick = (
        activeRewardOption: IPromotion | null,
        activeReward: IReward | null,
        isUserEligible: boolean,
    ) => {
        if (activeRewardOption) {
            if (isUserEligible) {
                setActiveReward(null);
                setActiveRewardPromo(activeRewardOption);
            } else {
                const pointsRequired =
                    activeRewardOption.pointsRequired - (pointsBalance || 0);
                toast(
                    `You need ${pointsRequired} more points to get this reward.`,
                );
            }
        }
        if (activeReward) {
            setActiveRewardPromo(null);
            setActiveReward(activeReward);
        }
    };

    if (isUserLoggedIn) {
        return (
            <div>
                <div className="mt-4 flex flex-row items-center justify-center px-2">
                    <div className="pr-1 text-base text-secondary">
                        My points: {totalPoints || 0}
                    </div>
                    <PointsIcon className="h-4 w-4 fill-primary" />
                </div>
                {pointsUsedInCart ? (
                    <div className="mt-2 flex flex-row items-center justify-center px-2">
                        <div className="pr-1">
                            Points used in cart: {pointsUsedInCart}
                        </div>
                        <PointsIcon className="h-4 w-4 fill-primary" />
                    </div>
                ) : null}
                {/* Redeemed rewards (non-passthrough) */}
                {filteredRewards.map((r) => {
                    return (
                        <PromotionCard
                            name={r.name}
                            key={r._id}
                            handleClick={() => {
                                handleClick(null, r, true);
                            }}
                            classNames="my-4"
                            disable={false}
                            isSelected={activeReward?._id === r._id}
                            isReward
                        />
                    );
                })}
                {/* Rewards via passthrough */}
                {passthroughRewardOptions.map((ro) => {
                    return (
                        <PromotionCard
                            name={ro.rewardOption.name}
                            key={ro.rewardOption._id}
                            handleClick={() =>
                                handleClick(
                                    ro.rewardOption,
                                    null,
                                    ro.isUserEligible,
                                )
                            }
                            points={ro.rewardOption.pointsRequired}
                            classNames="my-4"
                            disable={!ro.isUserEligible}
                            isSelected={
                                activeRewardPromo?._id === ro.rewardOption._id
                            }
                        />
                    );
                })}
            </div>
        );
    } else {
        return (
            <div>
                {promotionSummary.map((p, i) => {
                    return (
                        <PromotionCard
                            key={i}
                            name={p.name}
                            points={p.points}
                            classNames="my-4"
                            handleClick={() => {}}
                            disable={true}
                        />
                    );
                })}
            </div>
        );
    }
};

export default ModalContent;
