import config from "@/config";
import { getActiveStore } from "@/redux";
import { createReceiptToken } from "@/utils/Helpers";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { match } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";
import GiftCardView from "./components/GiftCardView";
import GiftCardViewHeader from "./components/GiftCardViewHeader";
import { useFetchGiftCard } from "./useFetchGiftCard";
import { useFetchActiveStore } from "#activeStore/hooks/useFetchActiveStore";

type Props = {
    match: match<{ giftCardId: string; token: string }>;
};

export const GiftCardViewScreen = (props: Props) => {
    const { match } = props;
    const { giftCardId } = match.params;
    // While on local we attempt to construct the token automatically rather than using the from URL
    const token = config.IS_LOCAL
        ? createReceiptToken(giftCardId)
        : match.params.token;

    const activeStore = useSelector(getActiveStore);
    const { fetchGiftCard, data, error } = useFetchGiftCard();
    const { fetchStore } = useFetchActiveStore();

    useEffect(() => {
        fetchGiftCard(giftCardId, token);
    }, [fetchGiftCard, giftCardId, token]);

    useEffect(() => {
        if (data?.storeId) {
            fetchStore(data.storeId);
        }
    }, [data?.storeId, fetchStore]);

    const isLoading = data == null && error == null;

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <GiftCardViewHeader store={activeStore} />
            <div className="mx-auto flex min-h-screen flex-col items-center bg-neutral-100 pt-[96px] sm:pt-[144px]">
                <div className="flex max-w-[550px] flex-col self-center rounded-xl bg-white text-center shadow-md shadow-gray-200 md:mb-6">
                    <GiftCardView
                        error={error}
                        data={data}
                        store={activeStore}
                        token={token}
                    />
                </div>
            </div>
        </>
    );
};
