import {
    ReceiptLineItem,
    buildCommonSummaryLineItems,
    centsToDollars,
} from "@snackpass/accounting";
import { IOrderItem, IPurchase } from "@snackpass/snackpass-types";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import uuid from "uuid";
import { PurchaseItemGrouping } from "../../utils/Types";
import Item from "./Item";
import LineItemRow from "./LineItemRow";
import { getRewardPointsString } from "@/utils/Loyalty";

type Props = {
    purchase: IPurchase;
    maxHeight?: number;
};

const PurchaseReceipt: React.FC<Props> = ({ purchase, maxHeight }) => {
    const rewardPointsName = getRewardPointsString();
    const [items, setItems] = useState<PurchaseItemGrouping[]>([]);
    const { preTotalLineItems, restLineItems } = useMemo(() => {
        const receiptLineItems = buildCommonSummaryLineItems(purchase);
        const totalIndex = receiptLineItems.findIndex((lineItem) =>
            ["Total", "Estimated Total"].includes(lineItem.label),
        );
        return {
            preTotalLineItems:
                totalIndex === -1
                    ? receiptLineItems
                    : receiptLineItems.slice(0, totalIndex),
            restLineItems:
                totalIndex === -1 ? null : receiptLineItems.slice(totalIndex),
        };
    }, [purchase]);

    useEffect(() => {
        if (purchase && purchase.items) {
            const generatePurchaseItems = (
                items: IOrderItem[],
            ): PurchaseItemGrouping[] => {
                let groupedCartItems = _.groupBy(items, (item) =>
                    _.get(item, "groupUuid", uuid()),
                );
                return Object.entries(groupedCartItems).map(
                    ([groupUuid, cartItems]) => {
                        return {
                            groupUuid,
                            items: cartItems,
                            quantity: cartItems.length,
                            points: _.sum(
                                cartItems.map((item) => item.points || 0),
                            ),
                            totalPrice: _.sum(
                                cartItems.map((item) => item.totalPrice),
                            ),
                            totalPriceAfterDiscount: _.sum(
                                cartItems.map(
                                    (item) => item.totalPriceAfterDiscount,
                                ),
                            ),
                            basePrice: _.sum(
                                cartItems.map((item) => item.basePrice),
                            ),
                            basePriceAfterDiscount: _.sum(
                                cartItems.map(
                                    (item) =>
                                        item.basePriceAfterDiscount ||
                                        item.basePrice,
                                ),
                            ),
                        } as PurchaseItemGrouping;
                    },
                );
            };
            setItems(generatePurchaseItems(purchase.items));
        }
    }, [purchase]);

    return (
        <>
            <div className="px-3 pb-4 md:px-6 w-full border-b border-zinc-200 flex-col justify-start items-start gap-2 flex">
                <div className="h-auto w-full flex-col justify-start items-start gap-1 flex">
                    {items.map((item, i: number) => {
                        return (
                            <Item
                                purchase={purchase}
                                item={item}
                                index={i}
                                key={i}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="px-3 py-6 md:px-6 border-b border-zinc-200 h-auto w-auto flex-col justify-start items-start gap-2 flex">
                {preTotalLineItems.map((lineItem: ReceiptLineItem, i) => (
                    <LineItemRow lineItem={lineItem} key={i} />
                ))}
            </div>
            {restLineItems != null && (
                <div className="px-3 py-4 md:px-6 w-full flex-col justify-start items-start gap-2 flex">
                    {restLineItems.map((lineItem: ReceiptLineItem, i) => (
                        <LineItemRow lineItem={lineItem} key={i} />
                    ))}
                    {purchase.giftCardsCreditUsed ? (
                        <LineItemRow
                            lineItem={{
                                label: "New Gift Card Balance",
                                value: centsToDollars(
                                    _.sum(
                                        purchase.giftCardsUsed?.map(
                                            (gc) =>
                                                gc.amountRemainingInCents ?? 0,
                                        ),
                                    ),
                                ),
                                isDiscount: false,
                                description:
                                    purchase.refund || purchase.partialRefund
                                        ? "New Gift Card Balance calculated before considering refund"
                                        : undefined,
                            }}
                        />
                    ) : null}
                    <LineItemRow
                        lineItem={{
                            label: `${rewardPointsName} Earned`,
                            value: purchase.pointsEarned,
                            isDiscount: false,
                        }}
                        overrideValue={`+${purchase.pointsEarned}`}
                    />
                </div>
            )}
        </>
    );
};

export default PurchaseReceipt;
