import GiftCards from "@/utils/Api/graphql/giftcards";
import { IGiftCard } from "@snackpass/snackpass-types";
import { useCallback, useMemo, useState } from "react";

export interface FetchGiftCardAPI {
    /** API call to start fetching the gift card */
    fetchGiftCard: (id: string, token: string) => void;

    /** data of the gift card */
    data?: IGiftCard;

    /** GraphQL error from the GiftCard.getByToken API call */
    error?: Error;
}

/**
 * Provides an API for verifying a physical gift card code + pin combination
 */
export function useFetchGiftCard(): FetchGiftCardAPI {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [data, setData] = useState<IGiftCard | undefined>(undefined);

    const fetchGiftCard = useCallback(async (id: string, token: string) => {
        GiftCards.getByToken(id, token)
            .then((giftCard) => {
                setData(giftCard);
            })
            .catch((graphQLError) => {
                setError(graphQLError);
            });
    }, []);

    return useMemo(
        () => ({
            fetchGiftCard,
            data,
            error,
        }),
        [fetchGiftCard, data, error]
    );
}
