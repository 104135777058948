import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSetRewardPromos, useSetRewards } from "@snackpass/loyalty-react";

import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import { ModalsName, hideModal } from "@/redux";
import ModalContent from "./Content";
import { useMediaQuery } from "react-responsive";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const ClaimRewardModal = () => {
    const dispatch = useDispatch();
    const { setActiveRewardPromo } = useSetRewardPromos();
    const { setActiveReward } = useSetRewards();

    const claimRewardModal = useSelector(
        // @ts-ignore
        (state: ReduxState) => state.modals.modals.claimRewardModal,
    );
    const onHide = () => {
        dispatch(hideModal(ModalsName.claimRewardModal));
        setActiveRewardPromo(null);
        setActiveReward(null);
    };
    const isDesktop = useMediaQuery({ minWidth: 768 });

    if (isDesktop) {
        return (
            <Dialog open={claimRewardModal} onOpenChange={onHide}>
                <DialogContent
                    onEscapeKeyDown={onHide}
                    className="h-[100%] md:max-h-[700px]"
                >
                    <div className="flex flex-col overflow-y-auto h-full relative w-full hidden-scroll">
                        <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    Choose Item
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 mb-20">
                            <ModalContent />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Sheet open={claimRewardModal} onOpenChange={onHide}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Choose Item</SheetTitle>
                </SheetHeader>
                <div className="max-h-[500px] overflow-auto">
                    <div className="mt-4 mb-20">
                        <ModalContent />
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    );
};

export default ClaimRewardModal;
