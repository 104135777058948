/* Actions */

export const SET_PENDING_MARKETING_CONSENT = "SET_PENDING_MARKETING_CONSENT";
export type SET_PENDING_MARKETING_CONSENT =
    typeof SET_PENDING_MARKETING_CONSENT;

type SetPendingMarketingConsent = {
    type: SET_PENDING_MARKETING_CONSENT;
    marketingConsent: boolean | undefined;
};

export const setPendingMarketingConsent = (
    marketingConsent: boolean | undefined,
): SetPendingMarketingConsent => {
    return { type: SET_PENDING_MARKETING_CONSENT, marketingConsent };
};

/* Reducer */
export interface MarketingConsentState {
    pendingMarketingConsent: boolean | undefined;
}
export const PaymentInitialState: MarketingConsentState = {
    pendingMarketingConsent: undefined,
};

export default function marketingConsent(
    state = PaymentInitialState,
    action: SetPendingMarketingConsent,
): MarketingConsentState {
    switch (action.type) {
        case SET_PENDING_MARKETING_CONSENT:
            return {
                ...state,
                pendingMarketingConsent: action.marketingConsent,
            };
        default:
            return state;
    }
}

/* Selectors */
export const getPendingMarketingConsent = (state: {
    marketingConsent: MarketingConsentState;
}) => state.marketingConsent.pendingMarketingConsent;
