import React from "react";
import {
    FulfillmentTypeEnum,
    IPurchase,
    IStore,
} from "@snackpass/snackpass-types";
import humanizeString from "humanize-string";
import { Divider, InfoRow } from "../../../SharedComponents";
import { Map } from "./Map";
import { PickupOrDineInInfo } from "./PickupOrDineInInfo";
import { Queue } from "./Queue";
import { useTrackSegment } from "./useTrackSegment";
import { Pass } from "./Pass";
import { OrderStatus } from "./OrderStatus";
import { Helpers } from "@/utils";
import StoreIcon from "src/assets/icons/store.svg";
import PhoneIcon from "src/assets/icons/phone.svg";
import PersonIcon from "src/assets/icons/person.png";
import Linkify from "react-linkify";
import SmartReceiptMarketingConsent from "./SmartReceiptMarketingConsent";
import { OrderAgainButton } from "./OrderAgain";

type OrderStatusTabProps = {
    purchase: IPurchase;
    activeStore: IStore;
    authToken: string;
};

function OrderStatusTab({
    purchase,
    activeStore,
    authToken,
}: OrderStatusTabProps) {
    useTrackSegment(purchase);
    const phoneNumber = Helpers.formatPhoneNumber(purchase.store?.phoneNumber);
    return (
        <div>
            <div className="max-w-600 mx-auto flex w-full flex-col p-4 text-stone-950">
                <OrderStatus purchase={purchase} />
                <Pass purchase={purchase} activeStore={activeStore} />
            </div>
            {activeStore.smartReceiptCustomerMessage ? (
                <>
                    <Divider width={8} />
                    <div className="text-stone-950 px-8 py-6 font-medium text-base border-s-8 border-brandColor">
                        <Linkify
                            componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key,
                            ) => (
                                <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                    className="break-all text-blue-500" // Otherwise long links overflow on mobile
                                >
                                    {decoratedText}
                                </a>
                            )}
                        >
                            {activeStore.smartReceiptCustomerMessage}
                        </Linkify>
                    </div>
                </>
            ) : null}
            <SmartReceiptMarketingConsent
                purchase={purchase}
                activeStore={activeStore}
                authToken={authToken}
            />
            <Divider
                width={8}
                className="w-screen max-w-screen-sm overflow-hidden"
            />
            <Queue purchase={purchase} />
            <div className="max-w-600 mx-auto flex w-full flex-col p-4">
                <div className="mb-6 inline-flex w-auto items-center justify-start gap-6 px-4">
                    <div className="shrink grow basis-0 text-xl font-semibold leading-normal text-stone-950">
                        {humanizeString(purchase.fulfillment ?? "")}
                    </div>
                </div>
                <InfoRow
                    iconPath={StoreIcon}
                    middleComponent={
                        <div className="w-auto text-base font-semibold leading-normal text-stone-950">
                            {activeStore.name}
                        </div>
                    }
                    bottomComponent={
                        <div className="w-auto text-base font-normal leading-normal text-zinc-600">
                            {activeStore.address}
                        </div>
                    }
                />
                <InfoRow
                    iconPath={PhoneIcon}
                    middleComponent={
                        <div className="w-auto text-base font-semibold leading-normal text-stone-950">
                            Store Phone Number
                        </div>
                    }
                    bottomComponent={
                        <div className="w-auto text-base font-normal leading-normal text-zinc-600">
                            <a
                                href={"tel:" + phoneNumber}
                                className="text-zinc-600"
                            >
                                {phoneNumber}
                            </a>
                        </div>
                    }
                />
                {purchase.fulfillment === FulfillmentTypeEnum.Delivery && (
                    <InfoRow
                        iconPath={PersonIcon}
                        middleComponent={
                            <div className="w-auto text-base font-semibold leading-normal text-stone-950">
                                Delivery Address
                            </div>
                        }
                        bottomComponent={
                            <div className="w-auto text-base font-normal leading-normal text-zinc-600">
                                {purchase.deliveryAddress}
                            </div>
                        }
                    />
                )}

                <PickupOrDineInInfo purchase={purchase} />
                <OrderAgainButton />
                <Map purchase={purchase} />
            </div>
        </div>
    );
}

export default OrderStatusTab;
