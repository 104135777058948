import { Route, Switch, withRouter } from "react-router-dom";

// Modals
import AuthenticationModal from "@/modals/AuthenticationModal";
import MyCardsModal from "@/modals/MyCardsModal";
import ViewOrderModal from "@/modals/ViewOrderModal";
import CartConflictsModal from "../modals/CartConflictsModal";

// Screens
import { useAutoSignIn } from "@/hooks/useAutoSignIn";
import { useAutoSignOut } from "@/hooks/useAutoSignOut";
import { useLoadUser } from "@/hooks/useLoadUser";
import { useOOBrandTheme } from "@/hooks/useTheme";
import { useInitSegment } from "@/navigation/hooks/useInitSegment";
import { useTableNumber } from "@/navigation/hooks/useTableNumber";
import { useUTM } from "@/navigation/hooks/useUTM";
import { GiftCardViewScreen } from "@/screens/GiftCardViewScreen/GiftCardViewScreen";
import { LocationsScreen } from "@/screens/LocationsScreen.tsx";
import Marketplace from "@/screens/MarketplaceScreen";
import { PastOrdersScreen } from "@/screens/PastOrdersScreen";
import { ProfileScreen } from "@/screens/ProfileScreen";
import PurchaseDetails from "@/screens/PurchaseDetails";
import CartScreen from "src/screens/CartScreen";
import { DynamicLinkPage } from "src/screens/DynamicLinkRegister";
import { GiftCardScreen } from "src/screens/GiftCardScreen";
import { StoreScreen } from "src/screens/StoreScreen";
import { DownloadApplePayDocument } from "@/screens/DownloadApplePayDocument";

const Navigation = () => {
    useAutoSignIn();
    useAutoSignOut();
    useInitSegment();
    useUTM();
    useTableNumber();
    useLoadUser();
    useOOBrandTheme();

    return (
        <div
            style={{
                paddingBottom: 0,
                width: "100%",
            }}
        >
            <AuthenticationModal />
            <MyCardsModal />
            <ViewOrderModal />
            <CartConflictsModal />
            <div
                style={{
                    alignSelf: "center",
                    width: "100%",
                }}
            >
                <Switch>
                    <Route exact path="/" component={Marketplace} />
                    <Route
                        exact
                        path="/dyn/:dynamicLinkId"
                        component={DynamicLinkPage}
                    />
                    <Route exact path="/giftcard" component={GiftCardScreen} />
                    <Route
                        exact
                        path="/.well-knownn/apple-developer-merchantid-domain-association"
                        component={DownloadApplePayDocument}
                    />
                    <Route
                        exact
                        path="/:storeNameAndId"
                        component={StoreScreen}
                    />
                    <Route
                        exact
                        path="/:storeNameAndId/giftcard"
                        component={StoreScreen}
                    />
                    <Route
                        exact
                        path="/:storeNameAndId/locations"
                        component={LocationsScreen}
                    />
                    <Route
                        exact
                        path="/:storeNameAndId/profile"
                        component={ProfileScreen}
                    />
                    <Route
                        exact
                        path="/:storeNameAndId/past-orders"
                        component={PastOrdersScreen}
                    />
                    <Route
                        exact
                        path="/giftcard/view/:giftCardId/:token"
                        component={GiftCardViewScreen}
                    />
                    <Route
                        exact
                        path="/:storeNameAndId"
                        component={StoreScreen}
                    />
                    <Route exact path="/s/:region" component={Marketplace} />
                    {/* /foodhalls/ and /chains/ are URLs that we previously supported, but are no longer supported */}
                    <Route
                        exact
                        path="/foodhalls/:chainId?"
                        component={Marketplace}
                    />
                    <Route
                        exact
                        path="/chains/:chainId?"
                        component={Marketplace}
                    />
                    {/* end unsupported URLS */}
                    <Route
                        exact
                        path="/purchases/:purchaseId/:token/:tab?"
                        component={PurchaseDetails}
                    />
                    <Route
                        exact
                        path="/:storeNameAndId/cart"
                        component={CartScreen}
                    />
                    <Route
                        exact
                        path="/:storeNameAndId/:productId"
                        component={StoreScreen}
                    />
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(Navigation);
