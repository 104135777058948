import React from "react";
import { GiftCardDesign } from "src/screens/GiftCardScreen/lib";
import { View } from "src/SharedComponents";
import { CSSProperties } from "styled-components";
import GiftCardCheck from "src/assets/icons/giftCard-check.svg";
import Gift from "src/assets/icons/gift.svg";

type Props = {
    type?: GiftCardDesign;
    qty?: number;
};

export const GiftCardContent = ({ type, qty }: Props) => {
    if (type === GiftCardDesign.Amount || type === GiftCardDesign.Check) {
        return (
            <View style={styles.centerWrapper}>
                {type === GiftCardDesign.Check ? (
                    <img
                        src={GiftCardCheck}
                        alt="Check"
                        style={styles.checkSVG}
                    />
                ) : (
                    <div style={styles.amountText}>{`$${qty ? qty : 0}`}</div>
                )}
            </View>
        );
    }

    return (
        <div style={styles.giftWrapper}>
            <img src={Gift} alt="Gift Drawing" />
        </div>
    );
};

const styles = {
    amountText: {
        display: "flex",
        fontWeight: "bold",
        color: "#FFF",
        height: 122,
        alignItems: "Center",
    } as CSSProperties,
    centerWrapper: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties,
    giftWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: "5%",
        marginTop: "auto",
    } as CSSProperties,
    checkSVG: {
        transform: "scale(3,3)",
    } as CSSProperties,
};
