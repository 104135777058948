import { getGeolocation, hasLocationSet } from "@/redux";
import { getActiveStore } from "@snackpass/accounting";
import { IStore } from "@snackpass/snackpass-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChainThunk as fetchChainAction } from "src/redux/activeChain";
import { fetchStoresForChainThunk as fetchStoresForChainAction } from "src/redux/activeChainStores";

// This logic is weirdly written
// we should refactor to be simple and readable
export function useLoadChainStores() {
    const activeStore = useSelector(getActiveStore);
    const dispatch = useDispatch();
    // we should get rid of thunks. just move it into this hook
    const fetchChain = (chainId: string) => dispatch(fetchChainAction(chainId));
    const fetchStoresForChain = (query: any) =>
        dispatch(fetchStoresForChainAction(query));
    const geolocation = useSelector(getGeolocation);
    const hasLocation = useSelector(hasLocationSet);

    const fetchChainAndStores = async (store: IStore | null) => {
        if (!store || !store.chainId) {
            return;
        }

        // could potenitally make this faster by not fetching the chain and marking
        // root store separately from the list
        fetchChain(store.chainId);

        const coordinates = geolocation.coordinates;

        fetchStoresForChain({
            chainId: store.chainId,
            ...(hasLocation && {
                lat: coordinates[1],
                lng: coordinates[0],
            }),
            inferLocation: true,
        });
    };
    useEffect(() => {
        if (!activeStore?._id) {
            return;
        }
        fetchChainAndStores(activeStore);
    }, [activeStore?._id]);
}
