import React, { CSSProperties } from "react";
import { Addon, AddonGroup } from "@snackpass/snackpass-types";
import { Accounting } from "@snackpass/accounting";
import {
    useActiveReward,
    useActiveRewardPromo,
} from "@snackpass/loyalty-react";

import { Colors, Helpers } from "../../../../utils";
import { Text } from "../../../../SharedComponents";
import { View } from "../../../../SharedComponents";

type Props = {
    addon: Addon;
    addonGroup: AddonGroup;
    quantity: number;
};

interface PriceNoDiscountProps {
    addonPrice: number;
    canBeMultiple: boolean | undefined;
    quantity: number;
}

const PriceNoDiscount = ({
    addonPrice,
    canBeMultiple,
    quantity,
}: PriceNoDiscountProps) => (
    <View>
        <Text
            style={{
                textAlign: "right",
                flex: 1,
                padding: 2,
                flexDirection: "row",
            }}
            small
        >
            +
            {Helpers.toDollar(
                quantity === 0 ? addonPrice : addonPrice * quantity,
            )}
        </Text>
        {canBeMultiple && quantity > 0 ? (
            <Text style={styles.unitPriceLabel} color={Colors.gray} xsmall>
                {Helpers.toDollar(addonPrice)} ea
            </Text>
        ) : null}
    </View>
);

const AddonPrice = ({ addon, quantity, addonGroup }: Props) => {
    const activeReward = useActiveReward();
    const activeRewardPromo = useActiveRewardPromo();

    // dont show price if free
    if (!addon.price || addon.price === 0) {
        return null;
    }

    let isDiscounted = false;
    let discountedPrice = addon.price;

    // check if addon is discounted by promotion
    if (
        activeRewardPromo &&
        !activeReward &&
        Accounting.promotionOrRewardShouldDiscountAddons(activeRewardPromo) &&
        activeRewardPromo.discount?.percentOff
    ) {
        isDiscounted = true;
        discountedPrice = Accounting.applyPercentOff(
            addon.price,
            activeRewardPromo.discount.percentOff,
        );
    }

    // check if addon is discounted by reward
    if (
        !activeRewardPromo &&
        activeReward &&
        Accounting.promotionOrRewardShouldDiscountAddons(activeReward) &&
        activeReward.discount.percentOff
    ) {
        isDiscounted = true;
        discountedPrice = Accounting.applyPercentOff(
            addon.price,
            activeReward.discount.percentOff,
        );
    }

    if (!isDiscounted) {
        isDiscounted = false;
        return (
            <PriceNoDiscount
                addonPrice={addon.price}
                canBeMultiple={addonGroup.supportsMultiple}
                quantity={quantity}
            />
        );
    }

    return addonGroup.supportsMultiple && quantity > 0 ? (
        <View style={styles.discountedPriceContainer}>
            <Text style={styles.discountedPriceTotal} small>
                +
                {Helpers.toDollar(
                    quantity === 0
                        ? discountedPrice
                        : discountedPrice * quantity,
                )}
            </Text>
            <View style={{ flexDirection: "row" }}>
                <Text style={styles.originalUnitPrice} xsmall>
                    {Helpers.toDollar(addon.price)}
                </Text>
                <Text
                    style={{
                        color: Colors.gray,
                        textAlign: "right",
                    }}
                    xsmall
                >
                    &nbsp;&nbsp;
                    {Helpers.toDollar(discountedPrice)}
                </Text>
            </View>
        </View>
    ) : (
        <Text
            style={{
                flexDirection: "row",
                ...styles.discountedPriceContainer,
            }}
            small
        >
            <Text style={styles.originalUnitPrice}>
                +{Helpers.toDollar(addon.price)}
            </Text>
            <Text style={styles.discountedPriceTotal}>
                &nbsp;&nbsp;
                {Helpers.toDollar(discountedPrice)}
            </Text>
        </Text>
    );
};

const styles = {
    unitPriceLabel: {
        textAlign: "right",
        flex: 1,
        flexDirection: "row",
        lineHeight: "12px",
    } as CSSProperties,
    insideCheck: {
        height: 15,
        width: 15,
        borderRadius: "100%",
        position: "absolute",
        right: 2,
        top: 2,
    } as CSSProperties,
    label: {
        color: Colors.text,
        fontSize: 14,
        marginLeft: 32,
        fontWeight: 500,
        backgroundColor: "rgba(0,0,0,0)",
    } as CSSProperties,
    discountedPriceContainer: {
        textAlign: "right",
        flex: 2,
        padding: 2,
        alignSelf: "flex-end",
    } as CSSProperties,
    discountedPriceTotal: {
        color: Colors.green,
        textAlign: "right",
    } as CSSProperties,
    originalUnitPrice: {
        textDecorationLine: "line-through",
        textAlign: "right",
    } as CSSProperties,
};

export default AddonPrice;
