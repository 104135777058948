import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { PromoTypes } from "@snackpass/snackpass-types";
import { useSetRewardPromos } from "@snackpass/loyalty-react";

import * as actions from "../redux";
import API from "@/utils/Api/REST";
import { sendError } from "@/utils/Errors";

// fetching store's promotions(only Reward type)
// if user is not logged in we fetch promotion summary(handles by usePromotions hook)

export function useLoyalty() {
    const activeStore = useSelector(actions.getActiveStore);
    const isUserLoggedIn = useSelector(actions.getUserIsLoggedIn);

    const storeId = activeStore?._id;

    const { setRewardPromos } = useSetRewardPromos();

    const fetchStoreLoyalty = useCallback(async () => {
        try {
            if (!storeId) {
                return;
            }

            if (isUserLoggedIn) {
                const promotionsResponse =
                    await API.stores.getPromotions(storeId);

                setRewardPromos(
                    storeId,
                    // Filter to only reward promos
                    promotionsResponse.data.promotions.filter(
                        (p) => p.type === PromoTypes.Reward,
                    ),
                );
            }
        } catch (err) {
            sendError(err);
        }
    }, [isUserLoggedIn, setRewardPromos, storeId]);

    useEffect(() => {
        fetchStoreLoyalty();
    }, [fetchStoreLoyalty]);
}
