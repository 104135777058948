import { prop } from "lodash/fp";
import { createSelector } from "@reduxjs/toolkit";

export const SET_UTM_TRACKING = "SET_UTM_TRACKING";
export type SET_UTM_TRACKING = typeof SET_UTM_TRACKING;
export const CLEAR_UTM_TRACKING = "CLEAR_UTM_TRACKING";
export type CLEAR_UTM_TRACKING = typeof CLEAR_UTM_TRACKING;

export const setUtmTracking = (utmParams: {
    utm_source?: string | null;
    utm_medium?: string | null;
    utm_term?: string | null;
    utm_content?: string | null;
    utm_campaign?: string | null;
}) => {
    return { type: SET_UTM_TRACKING, utmParams } as const;
};

export type SetUtmTracking = ReturnType<typeof setUtmTracking>;

export type UtmTracking = {
    utm_source?: string | null;
    utm_medium?: string | null;
    utm_term?: string | null;
    utm_content?: string | null;
    utm_campaign?: string | null;
};

export const clearUtmTracking = () => {
    return { type: CLEAR_UTM_TRACKING } as const;
};

export type ClearUtmTracking = ReturnType<typeof clearUtmTracking>;

const INITIAL_STATE = {
    utm_source: null as string | null,
    utm_medium: null as string | null,
    utm_term: null as string | null,
    utm_content: null as string | null,
    utm_campaign: null as string | null
};

export default function utmTracking(
    state = INITIAL_STATE,
    action: SetUtmTracking | ClearUtmTracking
) {
    switch (action.type) {
        case SET_UTM_TRACKING:
            // Note, we always default to what is currently stored in the state,
            // that way the ONLY way to clear the utm params is by calling clearUtmTracking()
            // you cannot clear utm by doing setUtmTracking({ utm_source: null }) for instance
            return {
                utm_source: action.utmParams.utm_source || state.utm_source,
                utm_medium: action.utmParams.utm_medium || state.utm_medium,
                utm_term: action.utmParams.utm_term || state.utm_term,
                utm_content: action.utmParams.utm_content || state.utm_content,
                utm_campaign:
                    action.utmParams.utm_campaign || state.utm_campaign
            };
        case CLEAR_UTM_TRACKING:
            return {};
        default:
            return state;
    }
}

export const getUtmTracking = state => state.utmTracking as UtmTracking;
export const getIsQRCode = state =>
    Boolean(state.utmTracking.utm_source) &&
    state.utmTracking.utm_source.toLowerCase() === "qr";
export const getUtmSource = createSelector(
    [getUtmTracking],
    (utmTracking: UtmTracking) => prop("utm_source", utmTracking)
);
