import React, { CSSProperties } from "react";
import { Text, View } from "src/SharedComponents";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import {
    COL_FULL_SIZE,
    errorStyles,
    GiftCardFormProps,
    inputStyles,
    outerContainer,
    subTitle
} from "../lib";

type GiftCardRecipientProps = {
    setFieldValue: (field: string, value: any) => void;
};

const RECIPIENT = "RECIPIENT";

export const GiftCardRecipient = ({
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isKioskPurchase,
    setFieldValue
}: GiftCardFormProps & GiftCardRecipientProps) => {
    const isAutoCompleteOn = isKioskPurchase ? "off" : "on";

    // Because textarea adds an extra character when line breaks, we need to replace it so that characters don't surpass 300
    const handleTextAreaChange = e => {
        setFieldValue("note", e.target.value.replace(/(\r\n|\r)/g, "\n"));
    };

    return (
        <View style={outerContainer}>
            <Row className="justify-content-md-center row-centered">
                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <Text style={subTitle}>{RECIPIENT}</Text>
                </Col>
                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    {errors.toName && touched.toName && (
                        <View style={errorStyles}>{errors.toName}</View>
                    )}
                </Col>

                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <input
                        name="toName"
                        value={values.toName}
                        style={inputStyles(errors.toName, touched.toName)}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={"Recipient Name"}
                        className="gfInput"
                        autoComplete={isAutoCompleteOn}
                        maxLength={51}
                    />
                </Col>

                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    {errors.toPhone && touched.toPhone && (
                        <View style={errorStyles}>{errors.toPhone}</View>
                    )}
                </Col>

                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <NumberFormat
                        name="toPhone"
                        type="tel"
                        className="gfInput"
                        format="(###) ###-####"
                        mask=" "
                        style={inputStyles(errors.toPhone, touched.toPhone)}
                        value={values.toPhone}
                        placeholder="Recipient Phone Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Col>

                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    {errors.note && touched.note && (
                        <View style={errorStyles}>{errors.note}</View>
                    )}
                </Col>
                {/* Had to use textarea because of resize append in TextArea component */}
                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <textarea
                        name="note"
                        value={values.note}
                        placeholder={"Note"}
                        style={{
                            ...inputStyles(errors.note, touched.note),
                            marginBottom: "0%",
                            resize: "none"
                        }}
                        onChange={handleTextAreaChange}
                        onBlur={handleBlur}
                        maxLength={300}
                        className="gfInput"
                        autoComplete={isAutoCompleteOn}
                    />
                </Col>
                <Col lg={COL_FULL_SIZE} xs={COL_FULL_SIZE} md={COL_FULL_SIZE}>
                    <Text style={styles.characterLimitText}>
                        {values.note.length + "/300"}
                    </Text>
                </Col>
            </Row>
        </View>
    );
};

const styles = {
    characterLimitText: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        textAlign: "start",
        color: "#A0ADBA",
        width: "100%"
    } as CSSProperties
};
