import * as Sentry from "@sentry/react";
import { verboseMode } from "@snackpass/accounting";
import { Elements } from "@stripe/react-stripe-js";
import { Router } from "react-router-dom";
import { Toaster } from "sonner";

import config from "./config";
import Navigation from "./navigation";
import { Providers } from "./Providers";
import CacheBusting from "./utils/CacheBusting/CacheBusting";
import history from "./utils/history";
import { stripePromise } from "./utils/Stripe";

// Register event listener
import "./forter";

// If production configure sentry
if (config.env === "production") {
    console.log("[PRODUCTION ENVIRONMENT]");
    Sentry.init({
        dsn: config.sentryDsn,
        release: `online-ordering@${config.version}`,
    });
} else {
    // Set verbose logging in accounting lib when not in prod
    verboseMode(process.env.NODE_ENV !== "production");
}

const App = () => {
    return (
        <Providers>
            <Elements stripe={stripePromise}>
                <CacheBusting>
                    <Router history={history}>
                        <Toaster position="top-center" />
                        <Navigation />
                    </Router>
                </CacheBusting>
            </Elements>
        </Providers>
    );
};

export default App;
