import React from "react";
import ProductCard from "./ProductCard";
import { Divider } from "@/SharedComponents";
import { Element } from "react-scroll";
import { Button } from "@/components/ui/button";
import { CartAddon, CartItem } from "@snackpass/accounting";
import { IProduct } from "@snackpass/snackpass-types";

type DiscriminatedProps =
    | {
          products: IProduct[];
          isPastOrder?: false;
      }
    | {
          products: CartItem[];
          isPastOrder: true;
      };

type Props = {
    category: string;
    title: string;
    handlePressProduct: (
        product: IProduct,
        selectedAddons: CartAddon[] | null | undefined,
    ) => void;
    key: string;
    hideTitle?: boolean;
    onQuickAdd?: _.DebouncedFuncLeading<() => void>;
} & DiscriminatedProps;

export default function ProductSection({
    category,
    title,
    handlePressProduct,
    hideTitle,
    onQuickAdd,
    ...disProps
}: Props) {
    const { products, isPastOrder } = disProps;
    if (!products || products.length === 0) {
        return null;
    }

    return (
        <Element name={category}>
            <div className="mx-4 2xl:mx-0 overflow-hidden">
                {!hideTitle ? (
                    <div className="cursor-pointer py-6 text-2xl font-semibold leading-6 text-primary">
                        {isPastOrder ? (
                            <div className="flex flex-row items-center gap-4">
                                <div>{title}</div>
                                <Button
                                    onPress={() => {
                                        onQuickAdd && onQuickAdd();
                                    }}
                                    label="Quick Add"
                                    variant="outline"
                                />
                            </div>
                        ) : (
                            <span>{title}</span>
                        )}
                    </div>
                ) : null}
                <div className="grid gap-y-6 md:gap-x-0 lg:grid-cols-2 lg:gap-x-5 xl:grid-cols-3 2xl:gap-x-6">
                    {products.map((p, i) => {
                        const product = disProps.isPastOrder
                            ? disProps.products[i].product
                            : p;
                        const selectedAddons = disProps.isPastOrder
                            ? disProps.products[i].selectedAddons ?? undefined
                            : undefined;
                        return (
                            <ProductCard
                                showDescription={true}
                                key={product._id + category + i}
                                product={product}
                                handlePressProduct={handlePressProduct}
                                selectedAddons={selectedAddons}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="md:hidden">
                <Divider width={8} />
            </div>
        </Element>
    );
}
