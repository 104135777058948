import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
    useStoreRewardPromos,
    useUserStoreRewards,
    useActivePointsBalance,
    useStoreRewardPromoSummaries,
    useUserPassthroughRewardOptions,
} from "@snackpass/loyalty-react";
import { cartItemFlatToPassthroughItem } from "@snackpass/loyalty";
import { IUser } from "@snackpass/snackpass-types";

import { showModal, ModalsName, getUser } from "@/redux";
import { getRewardPointsString } from "@/utils/Loyalty";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { ReactComponent as PointsIcon } from "src/assets/icons/points.svg";
import { useAppDispatch, useAppSelector } from "@/redux/utils";
import { CartSelectors } from "@snackpass/accounting";
import { selectActiveStore } from "#activeStore";

const Promotions = () => {
    const user = useSelector(getUser);

    if (!user) {
        return <NonGuardedPromotions />;
    }

    return <GuardedPromotions user={user} />;
};

type GuardedPromotionsProps = {
    user: IUser;
};

const GuardedPromotions = ({ user }: GuardedPromotionsProps) => {
    const dispatch = useAppDispatch();
    const activeStore = useAppSelector(selectActiveStore);
    const cartItems = useAppSelector(CartSelectors.getCartItems);

    const passthroughItems = useMemo(
        () => cartItemFlatToPassthroughItem(cartItems),
        [cartItems],
    );

    const rewardPromos = useStoreRewardPromos(activeStore._id);
    const rewards = useUserStoreRewards(user._id, activeStore._id);
    const passthroughRewardOptions = useUserPassthroughRewardOptions(
        user._id,
        activeStore._id,
        passthroughItems,
    );
    const pointsBalance =
        useActivePointsBalance(user?._id, activeStore?._id, passthroughItems) ||
        0;

    const rewardsAvailable = passthroughRewardOptions.length;

    const showRewardOptionsModal = () => {
        dispatch(showModal(ModalsName.rewardOptionsModal));
    };

    const numberOfRewards =
        (rewards?.length || 0) + (rewardPromos?.length || 0);

    const rewardText =
        numberOfRewards === 1
            ? `${numberOfRewards} Reward`
            : `${numberOfRewards} Rewards`;

    const pointsText = getRewardPointsString(pointsBalance);

    const handleShow = () => {
        showRewardOptionsModal();
    };

    if (!rewardsAvailable) {
        return null;
    }

    return (
        <div className="h-[94px] md:h-[64px] min-w-[144px] md:w-auto">
            <Button
                className="px-0 py-0 w-[100%] h-[100%] bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] transition-shadow duration-500 ease-in-out shadow-default hover:shadow-hover rounded-xl active:shadow-focus border active:scale-95"
                variant="ghost"
                onPress={handleShow}
                label={
                    <div className="flex flex-col md:flex-row h-[94px] md:h-[64px] py-3 md:py-0 flex-1 md:items-center md:justify-between rounded-xl pl-3 pr-2 md:pr-4">
                        <div className="basis-1/8 pb-2 md:pb-0">
                            <PointsIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                        </div>
                        <div className="basis-6/8 w-full text-start px-0 md:px-3">
                            <div className="flex items-start text-base font-semibold text-primary">
                                {pointsText}
                            </div>
                            <div className="font-normal text-start overflow-hidden text-clip text-sm leading-4 whitespace-nowrap">
                                {rewardText}
                            </div>
                        </div>
                        <div className="basis-1/8 hidden md:block">
                            <ChevronRightIcon className="w-4 h-4 text-[#999DA3]" />
                        </div>
                    </div>
                }
            />
        </div>
    );
};

const NonGuardedPromotions = () => {
    const dispatch = useAppDispatch();
    const activeStore = useAppSelector(selectActiveStore);
    const promotionSummary = useStoreRewardPromoSummaries(activeStore._id);

    const rewardsAvailable = promotionSummary.length;

    const showRewardOptionsModal = () => {
        dispatch(showModal(ModalsName.rewardOptionsModal));
    };

    const numberOfRewards = promotionSummary?.length || 0;

    const rewardText =
        numberOfRewards === 1
            ? `${numberOfRewards} Reward`
            : `${numberOfRewards} Rewards`;

    const pointsText = `Earn ${getRewardPointsString()}`;

    const handleShow = () => {
        showRewardOptionsModal();
    };

    if (!rewardsAvailable) {
        return null;
    }

    return (
        <div className="h-[94px] md:h-[64px] min-w-[144px] md:w-auto">
            <Button
                className="px-0 py-0 w-[100%] h-[100%] bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] transition-shadow duration-500 ease-in-out shadow-default hover:shadow-hover rounded-xl active:shadow-focus border active:scale-95"
                variant="ghost"
                onPress={handleShow}
                label={
                    <div className="flex flex-col md:flex-row h-[94px] md:h-[64px] py-3 md:py-0 flex-1 md:items-center md:justify-between rounded-xl pl-3 pr-2 md:pr-4">
                        <div className="basis-1/8 pb-2 md:pb-0">
                            <PointsIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                        </div>
                        <div className="basis-6/8 w-full text-start px-0 md:px-3">
                            <div className="flex items-start text-base font-semibold text-primary">
                                {pointsText}
                            </div>
                            <div className="font-normal text-start overflow-hidden text-clip text-sm leading-4 whitespace-nowrap">
                                {rewardText}
                            </div>
                        </div>
                        <div className="basis-1/8 hidden md:block">
                            <ChevronRightIcon className="w-4 h-4 text-[#999DA3]" />
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default Promotions;
