import detect from "./detect";

const _package = "@snackpass/accounting";
const _verbose = {
    enabled: false
};

const { isBrowser } = detect();

export const verbose = (caller: string, ...extras: any[]): void => {
    if (_verbose.enabled) {
        if (isBrowser) {
            console.groupCollapsed(
                [`%c${_package}`, `%c${caller}`].join("  "),
                "color: grey",
                "font-weight: bold"
            );
            extras.forEach((extra) => {
                Object.keys(extra).forEach((key) => {
                    console.log(key, extra[key]);
                });
            });
            console.groupEnd();
            return;
        }

        console.log(_package, caller, "\n", JSON.stringify(extras));
    }
};

export const verboseMode = (value?: boolean): void => {
    if (typeof value === "boolean") {
        console.info(
            "Setting 'verbose' logging for @snackpass/accounting:",
            value
        );
        _verbose.enabled = value;
    }
};

export default verboseMode;
