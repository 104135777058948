import { createSelector } from "@reduxjs/toolkit";

import { IStore, IProductCategory } from "@snackpass/snackpass-types";
import { CartSelectors } from "@snackpass/accounting";

/**
         88                                                                                               88  
         88                                                                      ,d                       88  
         88                                                                      88                       88  
 ,adPPYb,88  ,adPPYba, 8b,dPPYba,  8b,dPPYba,  ,adPPYba,  ,adPPYba, ,adPPYYba, MM88MMM ,adPPYba,  ,adPPYb,88  
a8"    `Y88 a8P_____88 88P'    "8a 88P'   "Y8 a8P_____88 a8"     "" ""     `Y8   88   a8P_____88 a8"    `Y88  
8b       88 8PP""""""" 88       d8 88         8PP""""""" 8b         ,adPPPPP88   88   8PP""""""" 8b       88  
"8a,   ,d88 "8b,   ,aa 88b,   ,a8" 88         "8b,   ,aa "8a,   ,aa 88,    ,88   88,  "8b,   ,aa "8a,   ,d88  
 `"8bbdP"Y8  `"Ybbd8"' 88`YbbdP"'  88          `"Ybbd8"'  `"Ybbd8"' `"8bbdP"Y8   "Y888 `"Ybbd8"'  `"8bbdP"Y8  
                       88                                                                                     
                       88                                                                                     

Please use #activeStore for any new code. This file will be removed in the future once ported.
 */

export const getActiveStore = (state: {
    activeStore: IStore | null;
}): IStore | null => state.activeStore;

export const getPickupDirections = createSelector(
    [getActiveStore],
    (activeStore) => activeStore?.pickupDirections,
);

export const getKioskAndRegisterPickupDirections = createSelector(
    [getActiveStore],
    (activeStore) => activeStore?.kioskPreferences?.customWayfindingMessage,
);

export const getActiveStoreHasPickup = createSelector(
    [getActiveStore],
    (activeStore) => activeStore?.pickup ?? false,
);

export const getActiveStoreCategoryMap = createSelector(
    [getActiveStore, CartSelectors.getCartIsCatering],
    (activeStore, isCatering) => {
        const ret: Record<string, string> = {};
        isCatering
            ? activeStore?.catering.productCategories.forEach(
                  (category: IProductCategory) => {
                      ret[category.name] = category._id;
                  },
              )
            : activeStore?.productCategories.forEach(
                  (category: IProductCategory) => {
                      ret[category.name] = category._id;
                  },
              );
        return ret;
    },
);

export const getActiveStoreTableNumberRequired = createSelector(
    [getActiveStore],
    (activeStore) =>
        activeStore?.dineInPreferences?.requireTableNumber ?? false,
);
