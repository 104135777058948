import React, { useEffect } from "react";
import { getUserAuthStatus } from "@/redux";
import { useSelector } from "react-redux";
import { match } from "react-router-dom";
import { ErrorScreen } from "../ErrorScreen";
import { useFetchActiveStore } from "#activeStore/hooks/useFetchActiveStore";
import useURLParams from "@/hooks/useURLParams";
import { cn } from "@/utils";
import ProfileContent from "./ProfileContent";
import { useLoadPunchCards } from "@/hooks/useLoadPunchCards";
import ProfileHeader from "./ProfileHeader";
import { useLoadPastOrders } from "@/hooks/useLoadPastOrders";
import { AuthStatusType } from "@/redux";

type Props = {
    match: match<{ storeNameAndId: string }>;
};

export function ProfileScreen(props: Props) {
    const { match } = props;
    const { storeNameAndId } = match.params;
    const authStatus = useSelector(getUserAuthStatus);
    const { fetchStore } = useFetchActiveStore();
    const params = useURLParams();
    const fromCustomApp = params.get("fromCustomApp");

    useEffect(() => {
        fetchStore(storeNameAndId);
    }, [fetchStore, storeNameAndId]);

    useLoadPastOrders(storeNameAndId);
    useLoadPunchCards();

    if (authStatus === AuthStatusType.NOT_SIGNED_IN) {
        return <ErrorScreen message="Not signed in" />;
    }
    return (
        <div className="hidden-scroll h-[100%] w-[100%]">
            <div className="hidden-scroll relative h-[100%] w-[100%]">
                {!fromCustomApp ? (
                    <div className="fixed left-0 right-0 top-0 z-[3] flex w-[100%] justify-center overflow-x-hidden border-b bg-white">
                        <ProfileHeader />
                    </div>
                ) : null}
                <div
                    className={cn(
                        "mx-auto max-w-[1280px] py-4",
                        !fromCustomApp ? "mt-[60px] md:mt-[80px]" : "mt-6",
                    )}
                >
                    <ProfileContent />
                </div>
            </div>
        </div>
    );
}
