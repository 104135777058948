import React from "react";
import { Button } from "@/components/ui/button";
import useURLParams from "@/hooks/useURLParams";
import { useSelector } from "react-redux";
import { getActiveStore } from "@/redux";
import { history } from "src/utils/history";

export function OrderAgainButton() {
    const params = useURLParams();
    const fromCustomApp = params.get("fromCustomApp");
    const activeStore = useSelector(getActiveStore);
    if (!activeStore) return null;

    return (
        <div className="flex flex-col w-full max-w-600 px-4 pt-4">
            {!fromCustomApp ? (
                <Button
                    label="Order Again"
                    variant="brand"
                    onPress={() => {
                        history.push(`/${activeStore?.slug}`);
                    }}
                />
            ) : null}
        </div>
    );
}
