import React from "react";
import { useSelector } from "react-redux";
import { getPromotions } from "@/redux";

import PromoCard from "@/components/PromoCard";

const ModalContent = () => {
    const promotions = useSelector(getPromotions);

    return (
        <div>
            {promotions.map((p, i) => {
                return (
                    <div key={i}>
                        <PromoCard promotion={p} previewMode={true} />
                    </div>
                );
            })}
        </div>
    );
};

export default ModalContent;
