import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

var config = {
    apiKey: "AIzaSyDFYc6u8l2RZ0WvoocxpNHTc3bsQ3slLQ8",
    authDomain: "futura-c7f12.firebaseapp.com",
    databaseURL: "https://futura-c7f12.firebaseio.com",
    projectId: "futura-c7f12",
    storageBucket: "futura-c7f12.appspot.com",
    messagingSenderId: "616491088441"
};

// Initialize Firebase
export const firebaseApp = initializeApp(config);

// Expose the Firebase Auth instance
export const firebaseAuth = getAuth(firebaseApp);

// Expose the Firebase Database instance
export const firebaseDatabase = getDatabase(firebaseApp);
