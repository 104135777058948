import { IStore } from "@snackpass/snackpass-types";

import { StoreUtils, cn } from "src/utils";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveStoreOnMap } from "@/redux/corporateMap";
import { StoreLabelRow } from "./Row";
import { Button } from "../ui/button-2";
import { useSortStores } from "./useSortStores";

type StoreListRowProps = {
    store: IStore;
};

const StoreListRow = ({ store }: StoreListRowProps) => {
    const storeRoute = StoreUtils.getStoreRoute(store);
    const dispatch = useDispatch();
    const storeRowContent = (
        <>
            <div className={cn("p-4 flex flex-row gap-4 w-full")}>
                <div className="flex-1">
                    <p
                        className="text-lg font-semibold mb-1"
                        style={{
                            color: "var(--Text-neutral-default, #0F0F0F)",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {store.name}
                    </p>
                    <p className="mb-0">
                        <StoreLabelRow store={store} />
                    </p>
                    <p className="mb-2 text-primary">{store.address}</p>
                </div>
                <Link
                    onClick={() => {
                        dispatch(setActiveStoreOnMap(""));
                    }}
                    to={storeRoute}
                    key={store._id}
                >
                    <Button className="mt-2">Order</Button>
                </Link>
            </div>
            <hr />
        </>
    );

    return storeRowContent;
};

export const Rows = () => {
    const sortedStores = useSortStores();
    return (
        <div className="w-full">
            {sortedStores.map((store) => {
                return <StoreListRow key={store._id} store={store} />;
            })}
        </div>
    );
};
