/**
 * Taken from https://reselect.js.org/usage/handling-empty-array-results/
 * This utility function helps by returning an unchanging reference if the array is empty
 * rather than falling back to new array instances which will ruin memoization logic
 */

const EMPTY_ARRAY: readonly [] = Object.freeze([]) as readonly [];

export const fallbackToEmptyArray = <T>(array: T[] | undefined | null) => {
    if (array === undefined || array === null) {
        // readonly T[] let's typescript play nice when attempting to .filter or .map the array later
        // even though we know it's empty and these functions are going to return empty when called.
        return EMPTY_ARRAY as readonly T[];
    }
    return array.length === 0 ? (EMPTY_ARRAY as readonly T[]) : array;
};
