import React, { useCallback } from "react";
import { Api } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getIsApplePayActive, getUserActiveCardIndex, setIsApplePayActive } from "@/redux";
import { ReactComponent as CheckIcon } from "src/assets/icons/check-thin.svg";
import { ReactComponent as ApplePayIcon } from "src/assets/icons/Apple_Pay_logo.svg";
import useApplePay from "@/hooks/useApplePay";
import { setUser } from "src/redux";
import fp from "lodash/fp";

export const ApplePayRow: React.FC = () => {
    const dispatch = useDispatch();
    const activeCardIndex = useSelector(getUserActiveCardIndex);
    const _setUser = fp.compose(dispatch, setUser);
    const isApplePayAvailable = useApplePay();
    const _setUseApplePay = (useApplePay: boolean) =>
        dispatch(setIsApplePayActive(useApplePay));
    const isApplePayActive = useSelector(getIsApplePayActive);

    const handleActivate = useCallback(() => {
        _setUseApplePay(isApplePayAvailable);
        Api.me.update({ activeCardIndex: null }).then((response) => {
            _setUser(response.data.user);
        });
    }, [dispatch, isApplePayAvailable, _setUser]);

    if (!isApplePayAvailable) {
        return null;
    }
    return (
        <div
            className="cursor-pointer items-stretch bg-white hover:bg-tertiary px-6 py-4 my-1 rounded-lg"
            onClick={handleActivate}
        >
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center justify-start gap-2">
                    <div className="border rounded-lg px-2">
                        <ApplePayIcon className="w-8 h-8" />
                    </div>
                    <div className="text-primary font-medium text-sm">
                        Apple Pay
                    </div>
                </div>
                <div className="flex flex-row justify-end">
                    {isApplePayActive && !activeCardIndex && (
                        <CheckIcon className="w-[20px] h-[20px] fill-secondary" />
                    )}
                </div>
            </div>
        </div>
    );
};
