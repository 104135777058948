import { cn } from "@/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartSelectors } from "@snackpass/accounting";
import { showModal, ModalsName, getActiveStore } from "@/redux";
import { WhenTypeEnum, FulfillmentTypeEnum } from "@snackpass/snackpass-types";
import moment from "moment";
import { getPickupEstTimeString } from "../lib";
import { ReactComponent as ClockIcon } from "src/assets/icons/clock-fill.svg";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";

const ScheduleTime = ({ cartScreen }: { cartScreen?: boolean }) => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const scheduledDate = useSelector(CartSelectors.getCartScheduleDate);
    const when = useSelector(CartSelectors.getCartWhen);
    const showScheduleModal = () => {
        dispatch(showModal(ModalsName.scheduleModal));
    };
    const activeCartFulfillment = useSelector(CartSelectors.getCartFulfillment);
    const pickupEstimateData = useSelector(CartSelectors.getPickupEstimate);

    if (!activeStore) return null;

    const timeText = getPickupEstTimeString(pickupEstimateData, activeStore);

    const handleShow = () => {
        showScheduleModal();
    };

    const getDescription = (scheduledDate: Date | null | undefined) => {
        if (scheduledDate) {
            return moment(scheduledDate).format("MMM D, h:mm A");
        }

        if (activeCartFulfillment === FulfillmentTypeEnum.Pickup) {
            return timeText ?? "";
        }
    };

    const description = getDescription(scheduledDate);
    // if catering, user can schedule delivery or pickup, if non-catering, user can only schedule pickup
    const canSchedule = isCatering
        ? activeCartFulfillment !== FulfillmentTypeEnum.DineIn
        : activeCartFulfillment === FulfillmentTypeEnum.Pickup &&
          activeStore.hasScheduledOrders;

    return (
        <Button
            className={cn("px-0 py-0 w-[100%] h-[100%] rounded-xl", {
                "bg-gradient-to-b from-white to-gray-50 border-[#FCFCFC] transition-shadow duration-500 ease-in-out shadow-default hover:shadow-hover rounded-xl active:shadow-focus border active:scale-95":
                    !cartScreen,
                "cursor-default": !canSchedule,
                "hover:bg-white": cartScreen,
            })}
            variant="ghost"
            onPress={canSchedule ? handleShow : () => {}}
            label={
                <div
                    className={cn(
                        "flex flex-col md:flex-row flex-1 md:items-center py-3 md:py-0 md:justify-between rounded-xl ",
                        {
                            "pl-3 pr-2 md:pr-4 h-[94px] md:h-[64px]":
                                !cartScreen,
                            "px-4 h-[78px]": cartScreen,
                        },
                    )}
                >
                    {cartScreen ? null : (
                        <div className="basis-1/8 pb-2 md:pb-0">
                            <ClockIcon className="h-5 w-5 md:h-6 md:w-6 fill-primary" />
                        </div>
                    )}
                    <div
                        className={cn("basis-6/8 w-full text-start", {
                            "px-0 md:px-3": !cartScreen,
                            "space-y-1": cartScreen,
                        })}
                    >
                        <div className="flex items-start text-base font-semibold text-primary">
                            {when === WhenTypeEnum.Now ? (
                                <div className="text-base font-semibold leading-6 text-primary">
                                    ASAP
                                </div>
                            ) : (
                                <div className="text-base font-semibold leading-6 text-primary line-clamp-1 text-start">
                                    Scheduled
                                </div>
                            )}
                        </div>
                        <div className="text-start overflow-hidden">
                            {description ? (
                                <div className="font-normal text-sm leading-4 whitespace-nowrap overflow-hidden tracking-tight">
                                    {description}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {canSchedule ? (
                        <div className="basis-1/8 hidden md:block">
                            <ChevronRightIcon className="w-4 h-4 text-[#999DA3] block lg:hidden" />
                            <div className="hidden lg:block border rounded-md border-[#DFE0E2] p-2">
                                Schedule
                            </div>
                        </div>
                    ) : null}
                </div>
            }
        />
    );
};

export default ScheduleTime;
