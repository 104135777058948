import React from "react";
import { get } from "lodash";
import { ChefHat } from "lucide-react";
import { AddonGroup } from "@snackpass/snackpass-types";
import { useActiveReward } from "@snackpass/loyalty-react";

import FreeAddons from "./FreeAddons";
import { ReactComponent as CheckIcon } from "src/assets/icons/check.svg";

type AddonGroupHeaderProps = {
    addonGroup: AddonGroup;
    addonSelectionIsValidated: AddonGroup[];
    showRequiredAlerts: boolean;
};

const AddonGroupHeader: React.FC<AddonGroupHeaderProps> = ({
    addonGroup,
    addonSelectionIsValidated,
    showRequiredAlerts,
}) => {
    const activeReward = useActiveReward();

    const needAction = addonSelectionIsValidated
        .map((group) => (get(group, "_id") || "").toLowerCase())
        .includes(addonGroup._id.toLowerCase());

    const renderChooseAddons = () => {
        if (showRequiredAlerts && needAction && addonGroup.limit) {
            let text =
                addonGroup.limit === 1
                    ? "Selection is required. Choose 1"
                    : `Selection is required. Choose up to ${addonGroup.limit}`;
            return (
                <div className="text-red-700 flex flex-row gap-1 text-sm font-medium leading-[18px]">
                    <ChefHat size={16} color="#B91C1C" strokeWidth={2.5} />{" "}
                    {text}
                </div>
            );
        } else if (addonGroup.limit) {
            let text =
                addonGroup.limit === 1
                    ? "Choose 1"
                    : `Choose up to ${addonGroup.limit}`;
            return (
                <div className="text-zinc-600 text-sm font-normal leading-[18px]">
                    {text}
                </div>
            );
        }
        return null;
    };

    return (
        <div
            id={addonGroup._id}
            className="px-6 pt-6 pb-3 flex flex-row items-center w-full justify-between scroll-mt-8 md:scroll-mt-14"
        >
            <div className="h-auto flex-col justify-start items-start inline-flex">
                <div className="text-stone-950 text-lg font-semibold leading-normal">
                    {addonGroup.name}
                </div>
                {renderChooseAddons()}
            </div>
            {addonGroup.required && (
                <div
                    className={`w-[82px] h-6 px-1.5 py-1 ${
                        needAction ? "bg-orange-50" : "bg-sky-50"
                    } rounded justify-center items-center gap-1.5 inline-flex self-start`}
                >
                    <div className="w-3 h-3 relative">
                        <CheckIcon
                            className="w-[12px] h-[12px] left-[1.50px] top-[0px] absolute"
                            fill={needAction ? "#C2410C" : "#005899"}
                        />
                    </div>
                    <div
                        className={`text-[12px]  font-semibold leading-none ${
                            needAction ? "text-orange-700" : "text-sky-700"
                        }`}
                    >
                        Required
                    </div>
                </div>
            )}
            <FreeAddons reward={activeReward} addonGroup={addonGroup} />
        </div>
    );
};

export default AddonGroupHeader;
