import React, { useMemo } from "react";
import { Helpers } from "../../../../utils";
import { AddonGroup, IStore } from "@snackpass/snackpass-types";
import AddonGroupComponent from "./AddonGroup";
import { IProduct, Addon } from "@snackpass/snackpass-types";

type AddonGroupsProps = {
    activeStore: IStore;
    activeProduct: IProduct;
    onToggleAddon: (addon: Addon, addonGroup: AddonGroup) => void;
    onChangeAddonQuantity: (addon: Addon, isIncrement: boolean) => void;
    addonSelectionIsValidated: AddonGroup[];
    showRequiredAlerts: boolean;
};

const AddonGroups = ({
    activeProduct,
    onToggleAddon,
    onChangeAddonQuantity,
    activeStore,
    addonSelectionIsValidated,
    showRequiredAlerts,
}: AddonGroupsProps) => {
    const sortedAddonGroups = useMemo(
        () => activeProduct.addonGroups.toSorted(Helpers.sortAddonGroup),
        [activeProduct.addonGroups],
    );

    return (
        <div className="flex flex-col">
            {sortedAddonGroups.map((addonGroup, i) => (
                <AddonGroupComponent
                    activeStore={activeStore}
                    key={i}
                    addonGroup={addonGroup}
                    onToggleAddon={onToggleAddon}
                    onChangeAddonQuantity={onChangeAddonQuantity}
                    addonSelectionIsValidated={addonSelectionIsValidated}
                    showRequiredAlerts={showRequiredAlerts}
                />
            ))}
        </div>
    );
};

export default AddonGroups;
