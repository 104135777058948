import { createHmac } from "crypto";
import * as geolib from "geolib";
import { formatNumber } from "libphonenumber-js";
import _ from "lodash";
import fp from "lodash/fp";

import { optimizedUrl, OptimizedURLOptions } from "@snackpass/images";
import {
    Fulfillment,
    FulfillmentTypeEnum,
    Geolocation,
    IProduct,
    IPurchase,
    IStore,
    IUser,
} from "@snackpass/snackpass-types";
import { isSnackpassTimeEnabled } from "@/statsig/featureGates";
import { isProductAvailable } from "@snackpass/time";
import { Time } from "@snackpass/accounting";

export function kFormatter(num: number) {
    return num > 999 ? (num / 1000).toFixed(1) + "k" : num;
}

export function listToDictionary(list: any[], field: string = "_id") {
    if (!list) return {};
    let dictionary = {};
    for (let elem of list) {
        let key = elem[field];
        if (key) dictionary[key] = elem;
    }
    return dictionary;
}

/**
 * Converts a dictionary to a list
 * @param {Object} dict A dictionary
 */
export function objectToArray(dict: Object) {
    if (!dict) {
        return [];
    }
    return Object.values(dict);
}

/**
 *
 * @param {number} value A percent as a number like 2.9 (2.9%). Supports 4 decimal places
 * @return {number} The value as a decimal
 * Example:
 *      percentToDecimal(2.9) => 0.0290
 */
export function percentToDecimal(value: number): number {
    if (!value) return 0.0;
    return (value * 0.01 * 10000) / 10000;
}

// TODO: clean up this page, remove unused export functions

export function objToArr(obj: Object): Object[] {
    let ret: any[] = [];
    Object.keys(obj || {}).forEach((key, i) => {
        let push = obj[key];
        if (typeof push !== "object") {
            push = {};
        }
        ret.push(Object.assign({}, push || {}, { id: key }));
    });
    return ret;
}

export function arrToObj(arr: any[]): { [id: string]: any } {
    if (!arr) {
        return {};
    }
    var ret: { [id: string]: any } = {};
    arr.forEach((item) => {
        ret[item._id] = { ...item };
    });
    return ret;
}

export function round(num: number) {
    let decimals = 2;
    return Number(
        Math.round(parseFloat(num + "e" + decimals)) + "e-" + decimals,
    );
}

export function toDollar(value: number) {
    if (typeof value != "number") {
        value = parseFloat(value);
    }
    return `$${(value || 0).toFixed(2)}`;
}

const nonNumeric = /[^0-9.]/gi;
export const onlyDigits = (s: string) => s.replace(nonNumeric, "");

export function validateEmailSpecialCharacters(str: string) {
    if (!str) {
        return false;
    }
    if (str.indexOf("+") !== -1) {
        return false;
    }
    return true;
}

export function getSchool(email: string) {
    if (!email) {
        return "na";
    }
    return email.split("@")[1] || "na";
}

/**
 * gets the relevant traits from input user
 * @param {IUser} user the user to get traits from
 */
export function getTraits(user: IUser) {
    return {
        email: user.email,
        School: getSecondPart(user.email),
        Name: user.name,
        Number: user.number,
        Phone: user.number,
        "First Name": user.firstName,
        "Last Name": user.lastName,
        Username: user.username,
        Birthday: user.birthday,
        Code: user.code,
        "Is Online Ordering User": true,
    };
}

/**
 * gets the part after @ in an email
 * @param {string} str the email to parse
 */
export function getSecondPart(str?: string) {
    if (!str) {
        return "";
    }
    return str.split("@")[1] || "na";
}

// validates if email is valid
export function validateEmail(email: string) {
    if (!email) {
        return false;
    }
    if (!validateEmailSpecialCharacters(email)) {
        return false;
    }

    // fix android email validation error HACK
    // eslint-disable-next-line no-useless-escape
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

// validate if phone number is valid
export function validatePhoneNumber(p: string) {
    const phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    let digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
}

export function e164ToUS(number: string) {
    if (!number) {
        return "";
    }
    return formatNumber(number, "NATIONAL");
}

// formats phone number to (440) 555-6666
export function phoneFormat(input: any) {
    if (!input) {
        input = "";
    }
    if (typeof input != "string") {
        input = input.toString();
    }
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, "");

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    let size = input.length;
    if (size === 0) {
        // input = input;
    } else if (size < 4) {
        input = "(" + input;
    } else if (size < 7) {
        input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
        input =
            "(" +
            input.substring(0, 3) +
            ") " +
            input.substring(3, 6) +
            "-" +
            input.substring(6, 10);
    }
    return input;
}

// alpha numeric only
export function validateUsername(str: string) {
    return /^\w+$/.test(str);
}

export function validatePassword(password: string) {
    if (!password || password.length < 8) {
        return false;
    }
    return true;
}

const blacklist = ["test_store", "blue_state"];

// check if store is valid
export function isBlacklist(storeId: string) {
    if (blacklist.indexOf(storeId) !== -1) {
        return true;
    }
    return false;
}

export function isStudentEmail(email: string) {
    if (email.endsWith(".edu")) {
        return true;
    }
    return false;
}

export function getInitials(name: string) {
    let names = (name || "").split(" ");
    let ret: any = [];
    for (var i = 0; i < names.length; i++) {
        // if (ret.length < 2) {
        ret.push(names[i][0] || "");
        // }
    }
    return ret.join("");
}

// get number of children of an object
export function getObjLength(obj: Object) {
    if (typeof obj != "object") {
        return 0;
    }
    return Object.keys(obj || {}).length;
}

// sort by order child
export function sortOrder(a: any, b: any) {
    return (a.order || 0) - (b.order || 0);
}

export function sortAddonGroup(a: any, b: any) {
    return (b.required || 0) - (a.required || 0);
}

export function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
        return {
            r: 0,
            g: 0,
            b: 0,
        };
    }
    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    };
}

export const rgbToHex = (r: number, g: number, b: number): string => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

/**
 *
 * @param hex Hex color to tint
 * @param tintFactor The bigger the factor, the lighter the tint (between 0 and 1)
 * @returns Hex code of the computed tint
 */
export const computeHexColorTint = (
    hex: string,
    tintFactor: number,
): string => {
    const rgbColor = hexToRgb(hex);

    const rgbTint = rgbColor;
    rgbTint.r = Math.floor(
        _.clamp(rgbColor.r + (255 - rgbColor.r) * tintFactor, 255),
    );
    rgbTint.g = Math.floor(
        _.clamp(rgbColor.g + (255 - rgbColor.g) * tintFactor, 255),
    );
    rgbTint.b = Math.floor(
        _.clamp(rgbColor.b + (255 - rgbColor.b) * tintFactor, 255),
    );

    return rgbToHex(rgbTint.r, rgbTint.g, rgbTint.b);
};

export function addOpacityToHex(hex: string, opacity: number) {
    if (!hex) {
        return "";
    }
    let rgb = hexToRgb(hex);
    return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
}

export function validateNumberTwoDecimals(value: string) {
    const regex = /^\d+(\.\d{0,2})?$/g;
    return regex.test(value);
}

export const optimizedImageURL = (
    url?: string | null,
    options?: OptimizedURLOptions,
) => optimizedUrl(url || undefined, options) ?? "";

export function shorten(
    text: string | null | undefined,
    numberOfWords: number,
    endingString: string = ".",
) {
    if (!text) {
        return "";
    }
    let words = text.split(" ");
    return (
        words.slice(0, numberOfWords).join(" ") +
        (numberOfWords < words.length ? endingString : "")
    );
}

export function capitalize(str: string) {
    if (!str) {
        return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function metersToMiles(meters: number) {
    if (!meters) return 0;
    return (meters || 0) * 0.000621371192;
}

/**
 * Get distance between two geopoints in meters
 */
export function getDistanceBetweenGeopoints(
    point1: Geolocation,
    point2: Geolocation,
) {
    if (!point1 || !point2) {
        return 0;
    }

    return metersToMiles(
        geolib.getDistance(
            {
                latitude: point1.coordinates[1],
                longitude: point1.coordinates[0],
            },
            {
                latitude: point2.coordinates[1],
                longitude: point2.coordinates[0],
            },
        ),
    );
}

export const getMilesAway = (distanceInMiles: number) => {
    let feet = Math.floor(distanceInMiles * 5280);

    const MAX_FEET = 500;
    if (feet < MAX_FEET) {
        return `${feet} ft`;
    }

    return `${distanceInMiles.toFixed(1)} mi`;
};

export const filterProductsForItem = (
    products: IProduct[],
    searchParameter: string,
) => {
    if (fp.isEmpty(products) || fp.isEmpty(searchParameter)) {
        return products;
    }
    return products
        .filter((product) => {
            return (
                product.name
                    .toLowerCase()
                    .includes(searchParameter.toLowerCase()) ||
                product.description
                    ?.toLocaleLowerCase()
                    .includes(searchParameter.toLowerCase())
            );
        })
        .sort((a, b) => a.name.localeCompare(b.name));
};

export function isDefined<T>(
    maybeDefined: null | undefined | false | T,
): maybeDefined is T {
    return !!maybeDefined;
}

export function centsToDollars(centsAmount: number): number {
    return centsAmount / 100;
}

export function validateZIPCode(p: string): boolean {
    const regex = /^[0-9]{5}/;
    return regex.test(p);
}

export function createReceiptToken(purchaseId: string): string {
    return createHmac("sha256", "foo").update(purchaseId).digest("hex");
}

/**
 * Accepts a product and its store and returns the appropriate description
 * (or lack thereof) for its available fulfillment type limitations
 *
 * There are a few assumptions this function relies on
 *  - the backend will **always** return a product where the combined product.fulfillmentMethods
 *      and store's available methods result in at least one valid method
 *  - there are no more than 3 available fulfillment methods
 *
 * If any of these change in the future, this function should be revisited
 * */
export const getProductFulfillmentDescription = (
    product: IProduct,
    store: IStore,
): string => {
    const FulfillmentMap = {
        [FulfillmentTypeEnum.Pickup]: "pickup",
        [FulfillmentTypeEnum.Delivery]: "delivery",
        [FulfillmentTypeEnum.DineIn]: "dine in",
    };

    const productHas = {
        pickup: product.fulfillmentMethods.isPickup,
        delivery: product.fulfillmentMethods.isDelivery,
        dineIn: product.fulfillmentMethods.isDineIn,
    };
    const storeHas = {
        pickup: store.pickup,
        delivery: store.delivery,
        dineIn: store.dineInPreferences.hasDineIn,
    };

    const fulfillmentMethods = [
        productHas.pickup && storeHas.pickup && FulfillmentTypeEnum.Pickup,
        productHas.delivery &&
            storeHas.delivery &&
            FulfillmentTypeEnum.Delivery,
        productHas.dineIn && storeHas.dineIn && FulfillmentTypeEnum.DineIn,
    ].filter(isDefined);

    // fulfillment types the store does not have are considered to not be options
    const availableMethods = [
        storeHas.pickup && FulfillmentTypeEnum.Pickup,
        storeHas.delivery && FulfillmentTypeEnum.Delivery,
        storeHas.dineIn && FulfillmentTypeEnum.DineIn,
    ].filter(isDefined);
    const numAvailable = availableMethods.length;
    const numHas = fulfillmentMethods.length;

    // if all available then default to nothing
    if (numHas === numAvailable) {
        return "";
    }

    // if it only has one then specify "only"
    if (numHas === 1) {
        return `Only available for ${FulfillmentMap[fulfillmentMethods[0]]}`;
    }

    // if only one, only mention that which is not available
    if (numAvailable - numHas === 1) {
        const missing = availableMethods.find(
            (val) => !fulfillmentMethods.includes(val),
        );
        if (!missing) return "";
        return `Not available for ${FulfillmentMap[missing]}`;
    }

    // all combinations are addressed in the above filtering
    console.error(
        "number of fulfillment methods is assumed to be 3, this invariant was violated",
    );
    return "";
};

export function getFirstName(name: string): string {
    const nameParts = name.trim().split(" ");
    return nameParts[0];
}

export function getPurchaseFirstName(purchase: IPurchase): string {
    return getFirstName(purchase.user?.name ?? purchase.customerName ?? "");
}
/**
 * Formats phone number.
 * ex. +18589997892 => (858) 999-7892
 * @param number the phone number
 */
export const formatPhoneNumber = (number?: string | null): string => {
    if (!number) {
        return "";
    }
    return formatNumber(number, "US", "National");
};

export const productHasCompatibleFulfillmentMethod = (
    product: IProduct,
    fulfillment: Fulfillment,
) => {
    switch (fulfillment) {
        case "PICKUP":
            return product.fulfillmentMethods.isPickup;
        case "DELIVERY":
            return product.fulfillmentMethods.isDelivery;
        case "DINE_IN":
            return product.fulfillmentMethods.isDineIn;
        default:
            return false;
    }
};

export const checkProductHasValidProductHours = (
    product: IProduct,
    scheduledDate,
) => {
    if (!product.hours?.local?.length) {
        return true;
    }
    if (isSnackpassTimeEnabled()) {
        return isProductAvailable(product.hours)(scheduledDate?.getTime());
    }
    return Time.isOpen(product, scheduledDate);
};
