// src/services/punchcards.ts
var projectPunchcards = (punchcards) => punchcards.map((punchcard) => ({
  id: punchcard._id,
  userId: punchcard.userId,
  storeId: punchcard.store._id,
  pointsBalance: Number(punchcard.pointsBalance)
}));

// src/services/passthrough-rewards/cartItemFlatToPassthroughItem.ts
var cartItemFlatToPassthroughItem = (cartItems) => cartItems.map((cartItem) => {
  var _a, _b;
  return {
    itemId: cartItem._id,
    promotionId: (_a = cartItem.promotion) == null ? void 0 : _a._id,
    pointsRequired: ((_b = cartItem.promotion) == null ? void 0 : _b.pointsRequired) || 0
  };
});

// src/services/passthrough-rewards/getUpdatedPointsBalance.ts
var getUpdatedPointsBalance = (points, passthroughCartItems) => points - passthroughCartItems.reduce(
  (total, item) => total + item.pointsRequired,
  0
);

// src/services/passthrough-rewards/getPointsToBeDeducted.ts
var getPointsToBeDeducted = (passthroughCartItems) => passthroughCartItems.reduce((total, item) => {
  return total + item.pointsRequired;
}, 0);

// src/services/passthrough-rewards/getPassthroughRewardOptions.ts
var getPassthroughRewardOptions = (activeUserPoints, rewardPromos, rewardsInCart) => rewardPromos.map((rewardOption) => ({
  rewardOption,
  isUserEligible: activeUserPoints >= rewardOption.pointsRequired,
  isInCart: rewardsInCart.has(rewardOption._id)
}));

// src/services/passthrough-rewards/getPassthroughRewardsInCart.ts
var getPassthroughRewardsInCart = (passthroughRewardItem) => new Set(
  passthroughRewardItem.reduce((acc, item) => {
    if (item.promotionId) {
      acc.push(item.promotionId);
    }
    return acc;
  }, [])
);

// src/services/passthrough-rewards/productIdsFromCategories.ts
var productIdsFromCategories = (categories, categoriesMap) => {
  var _a;
  return (_a = categories == null ? void 0 : categories.flatMap((c) => categoriesMap[c]).filter(Boolean).flatMap((c) => c.productIds)) != null ? _a : [];
};

// src/services/passthrough-rewards/groupCartItemsByProductId.ts
var groupCartItemsByProductId = (cartItemGrouping) => cartItemGrouping.reduce(
  (cartItemsByProductId, group) => {
    group.items.forEach((item) => {
      if (cartItemsByProductId[item.product._id]) {
        cartItemsByProductId[item.product._id].push({
          item,
          groupUuid: group.groupUuid
        });
      } else {
        cartItemsByProductId[item.product._id] = [
          {
            item,
            groupUuid: group.groupUuid
          }
        ];
      }
    });
    return cartItemsByProductId;
  },
  {}
);

// src/services/passthrough-rewards/selectBestCartItemForReward.ts
import {
  Accounting,
  getBasePriceAfterDiscount,
  getUnitPriceAfterDiscount
} from "@snackpass/accounting";
function selectBestCartItemForReward({
  cartItemGroups,
  reward,
  promotion
}) {
  return cartItemGroups.reduce(
    (acc, group) => {
      var _a, _b, _c, _d;
      const itemWeight = (_b = (_a = group.item.weight) == null ? void 0 : _a.amount) != null ? _b : 0;
      const discountedBasePrice = itemWeight !== 0 ? getUnitPriceAfterDiscount(
        group.item.product,
        reward,
        promotion,
        null
      ) : getBasePriceAfterDiscount(
        group.item.product,
        reward,
        promotion,
        null
      );
      const discountedAddons = (_d = (_c = group.item.selectedAddons) == null ? void 0 : _c.reduce(
        (totalAddon, addon) => totalAddon + Accounting.calculateAddonPrice(
          addon,
          promotion != null ? promotion : reward
        ) * addon.quantity,
        0
      )) != null ? _d : 0;
      const totalPriceAfterDiscount = itemWeight !== 0 ? (discountedBasePrice + discountedAddons) * itemWeight : discountedBasePrice + discountedAddons;
      const amountSaved = group.item.totalPrice - totalPriceAfterDiscount;
      if (acc.item === null || amountSaved > acc.amountSaved || amountSaved === acc.amountSaved && group.item.totalPrice > acc.item.totalPrice) {
        acc.item = group.item;
        acc.groupUuid = group.groupUuid;
        acc.amountSaved = group.item.totalPrice - totalPriceAfterDiscount;
      }
      return acc;
    },
    { item: null, groupUuid: "", amountSaved: -1 }
  );
}

// src/services/passthrough-rewards/getEligibleRewardsForCartItems.ts
var getEligibleRewardsForCartItems = ({
  cartProductIds,
  categoriesMap,
  promotions,
  rewards,
  punchcard
}) => {
  const pointsBalance = (punchcard == null ? void 0 : punchcard.chainWidePointsBalance) || (punchcard == null ? void 0 : punchcard.pointsBalance) || 0;
  const cartProductIdsSet = new Set(cartProductIds);
  const availableRewards = rewards.filter((r) => {
    if (r.storewide)
      return true;
    const productIds = productIdsFromCategories(
      r.categories,
      categoriesMap
    );
    return productIds.some((p) => cartProductIdsSet.has(p)) || r.productIds.some((p) => cartProductIdsSet.has(p));
  });
  const availablePromotions = promotions.filter((r) => {
    const enoughPoints = r.pointsRequired <= pointsBalance;
    if (!enoughPoints)
      return false;
    if (r.storewide)
      return true;
    const productIds = productIdsFromCategories(
      r.categories,
      categoriesMap
    );
    return productIds.some((p) => cartProductIdsSet.has(p)) || r.productIds.some((p) => cartProductIdsSet.has(p));
  });
  return {
    promotions: availablePromotions,
    rewards: availableRewards
  };
};

// src/services/passthrough-rewards/getEligibleCartItemsForReward.ts
var getEligibleCartItemsForReward = (reward, categoriesMap, cartItemGroupByProductId, allProducts) => {
  let rewardProducts;
  if (reward.storewide) {
    rewardProducts = allProducts;
  } else {
    rewardProducts = [
      ...reward.productIds,
      ...productIdsFromCategories(reward.categories, categoriesMap)
    ];
  }
  return rewardProducts.reduce((cartItems, productId) => {
    if (cartItemGroupByProductId[productId]) {
      cartItems.push(...cartItemGroupByProductId[productId]);
    }
    return cartItems;
  }, []);
};

// src/services/rewards.ts
var extractNameAndPoints = (summaries) => {
  const regex = /(.*) every (\d+) points/;
  const result = [];
  for (const summary of summaries) {
    const match = summary.match(regex);
    if (match) {
      const name = match[1].trim();
      const points = parseInt(match[2]);
      result.push({ name, points });
    }
  }
  return result;
};
var rewardIsInCart = (reward, cart) => cart.items.some((item) => item.reward && item.reward._id === reward._id);
var filterCartRewards = (rewards, cart) => rewards.filter((reward) => !rewardIsInCart(reward, cart));
export {
  cartItemFlatToPassthroughItem,
  extractNameAndPoints,
  filterCartRewards,
  getEligibleCartItemsForReward,
  getEligibleRewardsForCartItems,
  getPassthroughRewardOptions,
  getPassthroughRewardsInCart,
  getPointsToBeDeducted,
  getUpdatedPointsBalance,
  groupCartItemsByProductId,
  productIdsFromCategories,
  projectPunchcards,
  rewardIsInCart,
  selectBestCartItemForReward
};
