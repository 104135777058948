import React from "react";
import { IProduct } from "@snackpass/snackpass-types";
import { ReactComponent as PointsIcon } from "src/assets/icons/points.svg";

type ProductNameProps = {
    activeProduct: IProduct;
    hidePoints?: boolean;
};

const ProductName: React.FC<ProductNameProps> = ({
    activeProduct,
    hidePoints,
}) => {
    return (
        <>
            <div
                className={`items-center justify-start gap-6 pb-2 text-xl font-semibold leading-normal text-stone-950 inline-flex${
                    activeProduct.image ? " pt-6 " : " pt-10 "
                }`}
            >
                {activeProduct.name}
            </div>

            {activeProduct.points && !hidePoints ? (
                <div className="mb-3 flex w-fit flex-row items-center rounded-md border px-2 py-1 font-normal gap-[2px]">
                    +{activeProduct.points}
                    {/* {activeStore.loyaltyEmoji ? (
                        <div className="text-sm pl-1">
                            {activeStore.loyaltyEmoji}
                        </div>
                    ) : ( */}
                    <PointsIcon className="h-3 w-3 fill-primary" />
                    {/* )} */}
                </div>
            ) : null}
        </>
    );
};

export default ProductName;
