import { SystemColors as SC } from "@snackpass/design-system";
import React from "react";

export const Footer = () => (
    <div
        style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            paddingTop: 20,
            paddingBottom: 20,
        }}
    >
        <a
            href="https://privacy.snackpass.co"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: SC.gray50 }}
        >
            Privacy Center
        </a>
        <a
            href="https://legal.snackpass.co/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: SC.gray50 }}
        >
            Terms and Policies
        </a>
    </div>
);
