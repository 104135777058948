import { useState } from "react";
import { Drawer } from "vaul";
import { clsx } from "clsx";

import {
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
} from "../ui/drawer-corporate";
import { getActiveStore } from "@snackpass/accounting";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../ui/button";
import { Rows } from "./Rows";
import { MyLocation } from "./MyLocation";
import { boundMapToAllStores } from "@/redux/corporateMap";

const STARTING_SNAP_POINT = "330px";
const MAX_SNAP_POINT = 1;
export function ListDrawer() {
    const [snap, setSnap] = useState<number | string | null>(
        MAX_SNAP_POINT,
    );

    const activeStore = useSelector(getActiveStore);
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    return (
        <Drawer.Root
            snapPoints={["200px", STARTING_SNAP_POINT, MAX_SNAP_POINT]}
            activeSnapPoint={snap}
            setActiveSnapPoint={setSnap}
            modal={false}
            open={open}
            onOpenChange={(open) => {
                setOpen(open);
            }}
        >
            <Drawer.Trigger asChild>
                <div className="fixed bottom-10 left-0 right-0 z-0 w-full flex justify-center items-center">
                    <Button
                        label="Open List"
                        className="px-8"
                        onPress={() => {
                            setSnap(STARTING_SNAP_POINT);
                            setOpen(true);
                            dispatch(boundMapToAllStores(true));
                        }}
                        style={{
                            transition:
                                "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
                            opacity: open ? 0 : 1,
                            transform: open
                                ? "translateY(-20px)"
                                : "translateY(0)",
                        }}
                    ></Button>
                </div>
            </Drawer.Trigger>
            <Drawer.Portal>
                <Drawer.Content
                    className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full max-h-[85%] mx-[-1px] max-w-4xl"
                    style={{
                        margin: "0 auto",
                    }}
                >
                    <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mt-4 cursor-grab " />
                    <DrawerHeader className="pb-2">
                        <div className="flex flex-row justify-between items-start 0">
                            <div className="flex flex-col items-start ">
                                <DrawerTitle className="text-3xl text-left">
                                    {activeStore?.name} <br />
                                </DrawerTitle>
                                <DrawerDescription className="text-left">
                                    {activeStore?.aboutUs || ""}
                                </DrawerDescription>
                            </div>
                            <div
                                className="bg-center w-[72px] h-[72px] rounded-full bg-cover bg-no-repeat"
                                style={{
                                    backgroundImage: `url("${activeStore?.logoUrl}")`,
                                }}
                            />
                        </div>
                    </DrawerHeader>
                    <hr />
                    <div
                        className={clsx("flex flex-col w-full overflow-y-auto")}
                    >
                        <MyLocation />
                        <Rows />
                    </div>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    );
}
