import React, { Component } from "react";
import { Colors } from "../../../../utils";
import { Text } from "../../../../SharedComponents";
import { AddonGroup } from "@snackpass/snackpass-types";

type FreeAddonsProps = {
    reward?: any; // TODO: fix...
    addonGroup: AddonGroup;
};

// Some rewards have free addons
export default class FreeAddons extends Component<FreeAddonsProps> {
    render() {
        const { reward, addonGroup } = this.props;
        if (reward && reward.freeAddons && reward.freeAddons[addonGroup.name]) {
            return (
                <Text
                    small
                    color={Colors.green3}
                    bold
                    style={{ flex: 1, textAlign: "right" }}
                >
                    {reward.freeAddons[addonGroup.name].max} free
                </Text>
            );
        }
        return null;
    }
}
