import React from "react";
import { IStore } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import SingleTime from "./SingleTime";
import { UseSchedulingReturn } from "@snackpass/accounting";

type Props = {
    scheduling: UseSchedulingReturn;
    activeStore: IStore;
    onSelectTime: (time: moment.Moment) => void;
    selectedTime: moment.Moment | null;
    selectedWeekDay: moment.Moment | null;
};

const Times = ({
    selectedWeekDay,
    onSelectTime,
    selectedTime,
    scheduling,
}: Props) => {
    const { timeSlots } = scheduling;
    if (!selectedWeekDay) {
        return null;
    }

    return (
        <div className="snackpass__schedule-time-selector h-full">
            {timeSlots.length === 0 ? (
                <div style={{ width: "100%", paddingTop: 30 }}>
                    <h3
                        style={{
                            textAlign: "center",
                            marginBottom: 40,
                        }}
                    >
                        Store not open on this day
                    </h3>
                </div>
            ) : (
                <>
                    {timeSlots.map((timeSlot, index) => (
                        <SingleTime
                            scheduling={scheduling}
                            onSelectTime={onSelectTime}
                            isSelected={
                                selectedTime
                                    ? selectedTime.isSame(timeSlot[0], "minute")
                                    : false
                            }
                            timeSlot={timeSlot}
                            key={`timeSlot.${selectedWeekDay.format(
                                "cccc"
                            )}.${index}`}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default Times;
