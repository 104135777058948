import { IProduct } from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";
import config from "src/config";
import { getProductFulfillmentDescription } from "src/utils/Helpers";
import { getActiveStore } from "../redux";

type Props = {
    product: IProduct;
};
export const FulfillmentDescription = (props: Props) => {
    const { product } = props;
    const itemFulfillmentsEnabled = config.item_fulfillments;
    const store = useSelector(getActiveStore);

    if (!itemFulfillmentsEnabled || !store || !product.fulfillmentMethods) {
        return null;
    }

    const description = getProductFulfillmentDescription(product, store);

    if (!description) {
        return null;
    }

    return <div className="flex-col pt-3 ">{description}</div>;
};
