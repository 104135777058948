"use client";
import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { cn } from "@/utils";

const Accordion = ({
    className,
    ...props
}: AccordionPrimitive.AccordionSingleProps) => (
  <AccordionPrimitive.Root className={cn(className)} {...props}/>
)

const AccordionItem = React.forwardRef<
React.ElementRef<typeof AccordionPrimitive.Item>,
React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Item
      className={cn(
        'overflow-hidden focus-within:relative focus-within:z-10',
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </AccordionPrimitive.Item>
  ));
  AccordionItem.displayName = AccordionPrimitive.Item.displayName;

  
  const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Header className="flex my-auto">
      <AccordionPrimitive.Trigger
        className={cn(
          'text-base group flex flex-1 cursor-default items-center justify-between outline-none',
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  ));
  AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
  
  const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Content
      className={cn(
        'data-[state=open]:animate-accordion-down data-[state=closed]:animate-accordion-up overflow-hidden text-base',
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      <div>{children}</div>
    </AccordionPrimitive.Content>
  ));
  AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionTrigger, AccordionContent, AccordionItem };
