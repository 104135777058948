import { User, onIdTokenChanged } from "firebase/auth";
import { GraphQLClient } from "graphql-request";
import config from "src/config";
import { firebaseAuth } from "src/firebase";

const GQL = new GraphQLClient(config.gqlUrl);

onIdTokenChanged(firebaseAuth, async user => {
    const token = await user?.getIdToken();
    GQL.setHeaders({
        authorization: token ? `Bearer ${token}` : ""
    });
});

export const setGQLAuthHeader = async(user: User | null) => {
    const token = await user?.getIdToken();
    GQL.setHeaders({
        authorization: token ? `Bearer ${token}` : ""
    });
}

export { GQL };
