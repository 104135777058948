import React from "react";

type Props = {
    lat: number;
    lng: number;
};

const styles = {
    container: {
        width: 20,
        height: 20,
        borderRadius: 10,
        border: "3px solid white",
        backgroundColor: "#006AFF"
    }
};

export const CurrentLocationMarker = ({ lat, lng }: Props) => (
    <div style={styles.container}></div>
);
