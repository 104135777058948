//@ts-nocheck
// commenting to avoid TS complaining about window not having _sift
import config from "src/config";
import uuid from "uuid";

const setSiftForUser = (userId: string) => {
    const currentUser = sessionStorage.getItem("userId");
    if (currentUser === userId && window._sift) return;
    createSiftScript(userId);
    sessionStorage.setItem("userId", userId);
}

const createSiftScript = (userId: string) => {
    //attach the object array to the window
    var _sift = window._sift = window._sift || [];
    _sift.push(['_setAccount', config.SIFT_BEACON_KEY || ""]);
    _sift.push(['_setUserId', userId]);
    _sift.push(['_setSessionId', uuid.v4()]);
    _sift.push(['_trackPageview']);
 
    //attach the external script
    const siftElement = document.createElement('script');
    siftElement.setAttribute('src', "https://cdn.sift.com/s.js");
    document.body.appendChild(siftElement);
}

const pushEventsToSift = () => {
    if (window._sift){
        window._sift.push(['_trackPageview']);
    }
}

export { setSiftForUser, pushEventsToSift }