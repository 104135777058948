import _ from "lodash";
import React, { useMemo } from "react";

import { CartSelectors, itemIsSoldOut } from "@snackpass/accounting";

import {
    checkProductHasValidProductHours,
    productHasCompatibleFulfillmentMethod,
} from "@/utils/Helpers";
import { useAppSelector } from "@/redux/utils";

import { selectProducts } from "#menu";

export const useUpsell = () => {
    const cateringMode = useAppSelector(CartSelectors.getCartIsCatering);
    const scheduledDate = useAppSelector(CartSelectors.getCartScheduleDate);
    const cartItems = useAppSelector(CartSelectors.getCartItems);
    const fulfillment = useAppSelector(CartSelectors.getCartFulfillment);
    const products = useAppSelector(selectProducts);

    const cartProducts = useMemo(
        () => cartItems.map((item) => item.product._id),
        [cartItems],
    );

    // upsell modal shows up on cart screen we need to make sure the upsell products are all eligible
    const upsellProducts = useMemo(
        () =>
            products.filter(
                (product) =>
                    product.upsell &&
                    !itemIsSoldOut(product) &&
                    !cartProducts.includes(product._id) &&
                    (cateringMode ? product.isCatering : !product.isCatering) &&
                    productHasCompatibleFulfillmentMethod(
                        product,
                        fulfillment,
                    ) &&
                    checkProductHasValidProductHours(product, scheduledDate),
            ),
        [cartProducts, cateringMode, fulfillment, products, scheduledDate],
    );
    return useMemo(
        () => ({
            upsellProducts: upsellProducts.slice(0, 5),
            hasUpsellProduct: upsellProducts.length > 0,
        }),
        [upsellProducts],
    );
};
