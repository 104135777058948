import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ModalsName,
    getUser,
    hideModal,
    showModal,
    hasLocationSet,
} from "@/redux";
import { Button } from "@/components/ui/button";
import { useGetGeolocation } from "@/hooks/useGetLocation";
import { AtlasStoreFinder } from "./AtlasStoreFinder";

const DynamicLinkRegister = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const hasLocation = useSelector(hasLocationSet);
    const { requestLocation } = useGetGeolocation();

    const checkShouldShowSearchContent = useCallback(() => {
        if (!user) {
            dispatch(showModal(ModalsName.authenticationModal));
        } else {
            dispatch(hideModal(ModalsName.authenticationModal));
            if (!hasLocation) {
                requestLocation();
            }
        }
    }, [dispatch, hasLocation, requestLocation, user]);

    useEffect(() => {
        checkShouldShowSearchContent();
    }, [checkShouldShowSearchContent]);

    if (!user) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen w-full gap-4">
                <h2 className="text-lg font-semibold">Login Required</h2>
                <p>To access this page, please log in or sign up.</p>
                <Button
                    onPress={() => {
                        dispatch(showModal(ModalsName.authenticationModal));
                    }}
                    variant="secondary"
                    label="  Log in or sign up"
                />
            </div>
        );
    }

    if (!hasLocation) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen w-full gap-4">
                <h2 className="text-lg font-semibold">Location Required</h2>
                <p>
                    To access this page, please enable location services on your
                    device.
                </p>
                <p>You can change it in your browser settings.</p>
            </div>
        );
    }

    return (
        <div>
            <br />
            <div>
                <h4 className="pb-2 px-5">REGISTER QR CODE TO STORE</h4>
                <hr />
            </div>
            <AtlasStoreFinder />
        </div>
    );
};

export default DynamicLinkRegister;
