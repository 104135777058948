import {
    Fulfillment,
    IDealItem,
    IDiscount,
    IPartyPromotion,
    IProduct,
    IPromotion,
    IReward,
    IStore,
    PromoTypes
} from "@snackpass/snackpass-types";
import fp from "lodash/fp";
import moment from "moment";
import { CategoryMap, MaxDiscountType, PromotionValidation } from "../../types";
import {
    getIsGiftCardProduct,
    isNumber,
    round,
    Time,
    toDollar
} from "../../utils";
import {
    isAfterEndTime,
    isAfterStartTime,
    momentFormat1,
    replaceFullDayOfWeek
} from "../../utils/Time";
import {
    DELIVERY_ONLY_ERROR,
    ONE_PER_CART_ERROR,
    PICKUP_ONLY_ERROR
} from "../promotionValidation";

export function calculateMaxDiscount(
    product: Pick<IProduct, "price">,
    promotion: IPromotion
): MaxDiscountType {
    const percentOff = promotion?.discount?.percentOff ?? 0;
    const dollarsOff = promotion?.discount?.dollarsOff ?? 0;
    const newPrice = promotion?.discount?.newPrice ?? 0;

    if (promotion?.discount?.percentOff !== undefined) {
        return { discount: percentOff };
    }
    if (promotion?.discount?.newPrice !== undefined) {
        return {
            discount:
                Math.min((product.price - newPrice) / product.price, 1) * 100
        };
    }
    if (dollarsOff !== undefined) {
        return { discount: Math.min(dollarsOff / product.price, 1) * 100 };
    }
    return 0;
}

export function validateAddPromotion(
    promotion: IPromotion | null | undefined,
    fulfillment: Fulfillment,
    promotions: string[],
    dealIds: string[],
    subtotal: number,
    rewardPromoIdsInCart: string[] = [],
    scheduledDate?: Date | null
): PromotionValidation {
    if (!promotion) {
        return {
            validated: true,
            reason: "",
            allowAddToCart: true
        };
    }
    if (promotion.inWaitlistMode) {
        return {
            validated: false,
            reason: "Cannot use a promo that is in waitlist mode.",
            allowAddToCart: false
        };
    }
    const conditions = promotion.conditions || {};
    // IMPORTANT!!!!!!!, allowAddToCart=false validations must be set ABOVE
    // allowAddToCart=true validations
    if (conditions.onePerCart) {
        if (promotion.type === "DEAL" && dealIds.includes(promotion._id)) {
            return {
                validated: false,
                reason: ONE_PER_CART_ERROR,
                allowAddToCart: false
            };
        } else if (
            promotions.includes(promotion._id) ||
            rewardPromoIdsInCart.includes(promotion._id)
        ) {
            return {
                validated: false,
                reason: ONE_PER_CART_ERROR,
                allowAddToCart: false
            };
        }
    }
    if (promotion.activeTimePeriod) {
        if (!isAfterStartTime(promotion)) {
            return {
                validated: false,
                reason: `This promotion will be live at\n${moment(
                    promotion.activeTimePeriod.startTime
                ).format(momentFormat1)} 📅`,
                allowAddToCart: false
            };
        }
        if (isAfterStartTime(promotion)) {
            if (isAfterEndTime(promotion)) {
                return {
                    validated: false,
                    reason: `This promotion ended at \n${moment(
                        promotion.activeTimePeriod.endTime
                    ).format(momentFormat1)} 📅`,
                    allowAddToCart: false
                };
            }
        }
    }
    if (promotion.hours && !Time.isOpen(promotion, scheduledDate)) {
        // @ts-expect-error happyHourTimeDescription exists as a virtual on PromotionSchema
        const happyHourDescription = (promotion.happyHourTimeDescription ??
            "") as string;
        return {
            validated: false,
            reason: `${promotion.name} is only available:\n${replaceFullDayOfWeek(
                happyHourDescription.toLowerCase()
            )}`,
            allowAddToCart: false
        };
    }
    if (conditions.pickupOnly) {
        if (fulfillment !== "PICKUP") {
            return {
                validated: false,
                reason: PICKUP_ONLY_ERROR,
                allowAddToCart: true
            };
        }
    }

    if (conditions.deliveryOnly && fulfillment !== "DELIVERY") {
        return {
            validated: false,
            reason: DELIVERY_ONLY_ERROR,
            allowAddToCart: true
        };
    }
    if (isNumber(conditions.cartMax) && subtotal > (conditions.cartMax || 0)) {
        return {
            validated: false,
            reason: `Cart must have subtotal less than ${toDollar(
                conditions.cartMax || 0
            )}`,
            allowAddToCart: true
        };
    }
    if (isNumber(conditions.cartMin) && subtotal < (conditions.cartMin || 0)) {
        return {
            validated: false,
            reason: `Cart must have subtotal greater than ${toDollar(
                conditions.cartMin || 0
            )}`,
            allowAddToCart: true
        };
    }
    return {
        validated: true,
        reason: "",
        allowAddToCart: true
    };
}

export function promotionAppliesToProduct(
    promotion: IPromotion,
    product: Pick<IProduct, "_id" | "category" | "specialProductType">,
    categoryMap: CategoryMap
): boolean {
    const isProductGiftCard = getIsGiftCardProduct(product);
    const isPromotionForGiftCards = promotion.type === PromoTypes.GiftCard;
    if (isProductGiftCard !== isPromotionForGiftCards) {
        return false;
    }
    return (
        promotion.storewide ||
        // product is contained in promotion's productIds
        promotion.productIds.includes(product._id) ||
        // product is contained in promotion's categories
        promotion.categories.includes(categoryMap[product.category])
    );
}

export function getHasDoublePointsPromotion(
    product: Pick<IProduct, "_id" | "category" | "specialProductType">,
    activeStore: IStore | null,
    categoryMap: CategoryMap,
    fulfillment: Fulfillment,
    promotions: string[],
    dealIds: string[],
    subtotal: number
): boolean {
    if (!activeStore) {
        return false;
    }

    return (
        activeStore.promotions.filter(
            (p: IPromotion) =>
                !p.inWaitlistMode &&
                p.type === "DISCOUNT" &&
                p.discount &&
                p.discount.isDoublePoints &&
                validateAddPromotion(
                    p,
                    fulfillment,
                    promotions,
                    dealIds,
                    subtotal
                ).validated &&
                promotionAppliesToProduct(p, product, categoryMap)
        ).length > 0
    );
}

export function getBasePriceAfterDiscount(
    product: Pick<IProduct, "price">,
    reward?: IReward | null,
    activePromotion?: IPromotion | null,
    dealItem?: IDealItem | null,
    currentPartyPercentOff?: number
): number {
    let basePriceAfterDiscount = product.price;

    // dealItem overrides reward, which overrides activePromotion
    const discountItem =
        (dealItem?.discount ? dealItem : null) || reward || activePromotion;
    if (discountItem) {
        basePriceAfterDiscount = calcGetBasePriceAfterDiscount(
            product,
            discountItem,
            activePromotion?.type === "PARTY" ? currentPartyPercentOff : 0
        );
    }

    return basePriceAfterDiscount;
}

export function getHasPromotionOrReward(
    activePromotion?: IPromotion | null,
    reward?: IReward | null
): boolean {
    return !!(activePromotion || reward);
}

export function getIsDiscounted(
    hasPromotionOrReward: boolean,
    dealItem?: IDealItem
): boolean {
    // some dealItems don't contain discounts
    if (!dealItem?.discount) {
        return false;
    }
    return !!(hasPromotionOrReward || (dealItem && dealItem.discount));
}

function applyPercentOff(
    originalPrice: number,
    percentOff: number,
    maximumDiscount?: number
) {
    let discountAmount = (originalPrice * percentOff) / 100;
    if (maximumDiscount) {
        discountAmount = Math.min(discountAmount, maximumDiscount);
    }
    const ret = originalPrice - discountAmount;
    return round(ret);
}

function calcGetBasePriceAfterDiscount(
    product: Pick<IProduct, "price">,
    activePromotion?: IPromotion | IReward | IDealItem,
    currentPartyPercentOff?: number
) {
    if (!activePromotion?.discount && !currentPartyPercentOff) {
        return product.price;
    }
    return applyDiscount(
        product.price,
        activePromotion?.discount || undefined,
        currentPartyPercentOff,
        activePromotion?.discount?.maximumDiscount
    );
}

export function getUnitPriceAfterDiscount(
    product: Pick<IProduct, "priceByWeight">,
    reward?: IReward | null,
    activePromotion?: IPromotion | null,
    dealItem?: IDealItem | null,
    currentPartyPercentOff?: number
): number {
    if (!product.priceByWeight) {
        throw new Error(
            "Only call getUnitPriceAfterDiscount with valid product.priceByWeight."
        );
    }

    // dealItem overrides reward, which overrides activePromotion
    const discountItem = dealItem?.discount
        ? dealItem
        : reward || activePromotion;

    currentPartyPercentOff =
        activePromotion?.type === "PARTY" ? currentPartyPercentOff : 0;

    // Discount on unit price only makes sense for percent-off discounts. We still
    // apply flat discounts on pay-by-weight items; however, we do not want to
    // display that discount relative to unit price.
    if (discountItem?.discount?.percentOff || currentPartyPercentOff) {
        return applyDiscount(
            product.priceByWeight.perUnit,
            activePromotion?.discount,
            currentPartyPercentOff,
            activePromotion?.discount?.maximumDiscount
        );
    }

    return product.priceByWeight?.perUnit;
}

function applyDiscount(
    originalPrice: number,
    discount?: IDiscount,
    currentPartyPercentOffOverride = 0,
    maximumDiscount?: number
) {
    if (currentPartyPercentOffOverride || !discount) {
        return applyPercentOff(
            originalPrice,
            currentPartyPercentOffOverride,
            maximumDiscount
        );
    }
    if (discount.percentOff !== undefined) {
        return applyPercentOff(
            originalPrice,
            discount.percentOff,
            maximumDiscount
        );
    }
    if (discount.newPrice !== undefined) {
        return discount.newPrice;
    }
    if (discount.dollarsOff) {
        return Math.max(round(originalPrice - discount.dollarsOff), 0);
    }
    if (discount.isDoublePoints) {
        return originalPrice;
    }
    return originalPrice;
}

export function getBestNonPartyPromotionForProduct(
    product: Pick<
        IProduct,
        "_id" | "category" | "specialProductType" | "price"
    >,
    activeStore: IStore | null,
    categoryMap: CategoryMap,
    fulfillment: Fulfillment,
    promotions: string[],
    dealIds: string[],
    subtotal: number
): IPromotion | null {
    if (!activeStore) {
        return null;
    }

    return fp.flow(
        fp.filter(
            (p: IPromotion) =>
                !p.inWaitlistMode &&
                p.type === "DISCOUNT" &&
                !!p.discount &&
                validateAddPromotion(
                    p,
                    fulfillment,
                    promotions,
                    dealIds,
                    subtotal
                ).validated &&
                promotionAppliesToProduct(p, product, categoryMap)
        ),
        fp.map((p: IPromotion) => ({
            discount: calculateMaxDiscount(product, p),
            promotion: p
        })),
        fp.maxBy("discount.discount"),
        fp.get("promotion")
    )(activeStore.promotions) as IPromotion | null;
}

// in the past there were complaints about not being able to use
// non-party discounts when in party mode. so now, this fxn calculates
// the best non party promo (using helper fxn), then only if user is in
// party mode will it compare the value of that promo to the value of the
// current party discount and pick the best. If not in party mode then it will always
// use the best non-party promo available.
export const getBestPromotionForProduct = (
    product: Pick<
        IProduct,
        "_id" | "category" | "specialProductType" | "price"
    >,
    activeStore: IStore | null,
    categoryMap: CategoryMap,
    fulfillment: Fulfillment,
    promotions: string[],
    dealIds: string[],
    subtotal: number,
    currentPartyPromoPercentOff: number,
    activePartyPromotion: IPartyPromotion | null
): IPromotion | null => {
    if (!activeStore) {
        return null;
    }

    // Note on below behavior:
    // If a promo is in waitlist mode, it gets ignored by this fxn
    // since it is not currently usable.

    const bestNonPartyPromotion = getBestNonPartyPromotionForProduct(
        product,
        activeStore,
        categoryMap,
        fulfillment,
        promotions,
        dealIds,
        subtotal
    );

    // only active party promo is valid
    if (
        !bestNonPartyPromotion &&
        currentPartyPromoPercentOff &&
        !activePartyPromotion?.inWaitlistMode
    ) {
        return activePartyPromotion;
    }

    // no promo is valid
    if (
        !bestNonPartyPromotion &&
        (!currentPartyPromoPercentOff || activePartyPromotion?.inWaitlistMode)
    ) {
        return null;
    }

    // both promos are valid
    const nonPartyPromoDiscount = bestNonPartyPromotion
        ? calculateMaxDiscount(product, bestNonPartyPromotion)
        : null;

    const percentOffNonPartyPromo = nonPartyPromoDiscount
        ? nonPartyPromoDiscount.discount
        : 0;

    return percentOffNonPartyPromo > currentPartyPromoPercentOff
        ? bestNonPartyPromotion
        : activePartyPromotion;
};
