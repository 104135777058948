import { IProduct, SpecialProductType } from "@snackpass/snackpass-types";

export function getIsGiftCardProduct(
    product: Pick<IProduct, "specialProductType">
): boolean {
    return (
        product.specialProductType != null &&
        [
            SpecialProductType.PhysicalGiftCard,
            SpecialProductType.DigitalGiftCard
        ].includes(product.specialProductType)
    );
}
