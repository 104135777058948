import moment from "moment";
import { DateTime } from "luxon";

export const computeWeekDates = (
    fromDate: Date = new Date(),
    totalDays = 14
): moment.Moment[] => {
    const dates: moment.Moment[] = [];

    const startDate = DateTime.fromJSDate(fromDate);

    for (let i = 0; i < totalDays; i++) {
        dates.push(moment(startDate.plus({ days: i }).toJSDate()));
    }

    return dates;
};
