import React, { useCallback, useEffect } from "react";
import { Events, scrollSpy } from "react-scroll";
import { Categories } from "./Categories";
import { useDispatch, useSelector } from "react-redux";
import {
    ModalsName,
    getActiveStore,
    getFilteredProducts,
    getItemSearchParameter,
    selectPastOrders,
    showModal,
    getPromotions,
} from "@/redux";
import ProductSection from "./ProductSection";
import { ASHLEY_STORE_ID } from "@/utils/Constants";
import { IProduct } from "@snackpass/snackpass-types";
import { ActiveCartItemActions, CartAddon } from "@snackpass/accounting";
import _ from "lodash";
import { CATEGORY_NAME } from "@/utils/Types";
import { useLastOrder } from "@/hooks/useLastOrder";
import { useQuickAdd } from "@/hooks/useQuickAdd";
import { selectPopularProducts, selectPopulatedCategories } from "#menu";
import { Divider } from "@/SharedComponents/Divider";
import PromoCard from "@/components/PromoCard";
import { Element } from "react-scroll";

export const Menu = () => {
    const pastOrders = useSelector(selectPastOrders);
    const activeStore = useSelector(getActiveStore);
    const promotions = useSelector(getPromotions);

    useEffect(() => {
        Events.scrollEvent.register("begin");
        Events.scrollEvent.register("end");
        scrollSpy.update();
        return () => {
            Events.scrollEvent.remove("begin");
            Events.scrollEvent.remove("end");
        };
    }, []);

    if (!activeStore) {
        return null;
    }

    return (
        <>
            <div className="sticky lg:top-[75px] py-2 top-0 left-0 right-0 flex h-fit bg-white hidden-scroll overflow-scroll z-[3]">
                <Categories
                    hasPastOrder={!_.isEmpty(pastOrders)}
                    hasPromos={!_.isEmpty(promotions)}
                    hidePopular={activeStore?.disablePopularSectionOnOO}
                />
            </div>
            <div className="flex flex-row mb-[160px] lg:mb-[290px]">
                <div className="w-[100%]">
                    <Products />
                </div>
            </div>
        </>
    );
};

function Products() {
    const dispatch = useDispatch();
    const populatedCategories = useSelector(selectPopulatedCategories);
    const itemSearchParameter = useSelector(getItemSearchParameter);
    const filteredProducts = useSelector(getFilteredProducts);

    const handlePressProduct = useCallback(
        (product: IProduct, selectedAddons?: CartAddon[] | null) => {
            dispatch(ActiveCartItemActions.setActiveCartItem(product));

            if (!_.isEmpty(selectedAddons)) {
                selectedAddons?.forEach((selectedAddon) => {
                    const addOn = getAddon(
                        product,
                        selectedAddon.addonGroup._id,
                        selectedAddon.addon._id,
                    );
                    for (let i = 0; i < selectedAddon.quantity; i++) {
                        addOn &&
                            dispatch(
                                ActiveCartItemActions.selectIncrementAddon(
                                    addOn,
                                ),
                            );
                    }
                });
            }

            dispatch(showModal(ModalsName.productModal));
        },
        [dispatch],
    );

    if (
        populatedCategories.length === 0 ||
        (itemSearchParameter && filteredProducts.length === 0)
    ) {
        return (
            <div className="w-full h-full flex justify-center items-center m-auto">
                <span className="text-primary text-xl font-medium m-auto text-center mt-6">
                    No items on menu
                </span>
            </div>
        );
    }

    return (
        <div>
            {!itemSearchParameter ? (
                <div>
                    <StorePromotions />
                    <PastOrderProducts
                        handlePressProduct={handlePressProduct}
                    />
                    <PopularProducts handlePressProduct={handlePressProduct} />
                    {populatedCategories.map((populatedCategory) => (
                        <ProductSection
                            key={populatedCategory.name}
                            title={populatedCategory.name}
                            category={populatedCategory.name}
                            products={populatedCategory.products}
                            handlePressProduct={handlePressProduct}
                        />
                    ))}
                </div>
            ) : (
                <ProductSection
                    key="Search results"
                    title="Search results"
                    category="searchresults"
                    products={filteredProducts}
                    handlePressProduct={handlePressProduct}
                />
            )}
        </div>
    );
}

interface ProductsProps {
    handlePressProduct: (product: IProduct) => void;
}

function PopularProducts({ handlePressProduct }: ProductsProps) {
    const mostPopularProducts = useSelector(selectPopularProducts);
    const activeStore = useSelector(getActiveStore);

    const shouldShowPopularProducts =
        mostPopularProducts.length > 0 &&
        activeStore?._id !== ASHLEY_STORE_ID &&
        !activeStore?.disablePopularSectionOnOO;

    if (!shouldShowPopularProducts) {
        return null;
    }

    return (
        <ProductSection
            key={CATEGORY_NAME.Popular}
            title={CATEGORY_NAME.Popular}
            category={CATEGORY_NAME.Popular}
            products={mostPopularProducts}
            handlePressProduct={handlePressProduct}
        />
    );
}

function PastOrderProducts({ handlePressProduct }: ProductsProps) {
    const { hasPastOrder, lastOrderProductsAndAddons } = useLastOrder();
    const onQuickAdd = useQuickAdd();

    if (!hasPastOrder) {
        return null;
    }

    return (
        <ProductSection
            key={CATEGORY_NAME.PastOrder}
            title={CATEGORY_NAME.PastOrder}
            category={CATEGORY_NAME.PastOrder}
            products={lastOrderProductsAndAddons}
            isPastOrder={true}
            handlePressProduct={handlePressProduct}
            onQuickAdd={onQuickAdd}
        />
    );
}

const StorePromotions = () => {
    const promotions = useSelector(getPromotions);
    if (!promotions.length) {
        return null;
    }

    return (
        <Element name={CATEGORY_NAME.Promos}>
            <div className="mx-4 2xl:mx-0 overflow-hidden">
                <div className="cursor-pointer py-6 text-2xl font-semibold leading-6 text-primary">
                    <div className="flex flex-row items-center gap-4">
                        <div>Promos</div>
                    </div>
                </div>

                <div className="grid gap-y-2 lg:gap-y-6 md:gap-x-0 lg:grid-cols-2 lg:gap-x-5 xl:grid-cols-3 2xl:gap-x-6">
                    {promotions.map((p, i) => {
                        return <PromoCard promotion={p} key={i} />;
                    })}
                </div>
            </div>
            <div className="md:hidden">
                <Divider width={8} />
            </div>
        </Element>
    );
};

const getAddon = (
    product: IProduct,
    selectedAddonGroupId: string,
    selectedAddonId: string,
) => {
    const addonGroup = product.addonGroups.find(
        (addonGroup) => addonGroup._id === selectedAddonGroupId,
    );

    return addonGroup?.addons.find((addon) => addon._id === selectedAddonId);
};

export default Menu;
