import { Geolocation } from "@snackpass/snackpass-types";
import _ from "lodash";
import { createSelector } from "@reduxjs/toolkit";

export const UPDATE_MY_LOCATION = "UPDATE_MY_LOCATION";
export const INCREMENT_GEOLOCATION_UPDATE = "INCREMENT_GEOLOCATION_UPDATE";

export type UPDATE_MY_LOCATION = typeof UPDATE_MY_LOCATION;
export type INCREMENT_GEOLOCATION_UPDATE = typeof INCREMENT_GEOLOCATION_UPDATE;

export function updateMyLocation(geolocation: Geolocation) {
    return { type: UPDATE_MY_LOCATION, geolocation } as const;
}

export type UpdateMyLocation = ReturnType<typeof updateMyLocation>;

export const getMyLocation = (state): MyLocation => state.myLocation;

export type MyLocation = {
    type: "Point";
    coordinates: [number, number];
};

const INITIAL_STATE: MyLocation = {
    type: "Point",
    coordinates: [0, 0] as [number, number],
};

function myLocation(
    state: MyLocation = INITIAL_STATE,
    action: UpdateMyLocation
) {
    switch (action.type) {
        case UPDATE_MY_LOCATION:
            return {
                ...state,
                ...action.geolocation,
            };

        default:
            return state;
    }
}

export default myLocation;

export const getGeolocation = createSelector([getMyLocation], (myLocation) => {
    return _.pick(myLocation, ["type", "coordinates"]);
});

export const getLatLng = createSelector([getMyLocation], (myLocation) => ({
    lat: myLocation.coordinates[1],
    lng: myLocation.coordinates[0],
}));

export const hasLocationSet = createSelector(
    [getGeolocation],
    (geolocation) =>
        geolocation.coordinates[0] !== 0 || geolocation.coordinates[1] !== 0
);
