import { CSSProperties } from "react";

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)"
        },
        modal: {
            width: 500,
            background: "#f7f7f7",
            padding: 0,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 20,
            overflow: "hidden"
        }
    },
    cardRows: {
        backgroundColor: "#f1f1f1",
        padding: 0,
        marginTop: 0,
        height: "100%",
        flexDirection: "column",
        flex: 1,
        alignItems: "stretch"
    },
    title: {
        margin: 0,
        fontSize: 20
    },

    card: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: 10,
        width: '100%',
        padding: '2%',
        height:'100%'
    } as CSSProperties,
};

export default styles;
