import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CartSelectors,
    CartActions,
    CartItemFlat,
} from "@snackpass/accounting";

const checkCartHasInvalidCartItems = (
    isCatering: boolean,
    cartItems: CartItemFlat[]
) => {
    // check if cart has non-catering product in catering mode
    if (isCatering) {
        return cartItems.some((item) => !item.product.isCatering);
    } else {
        // check if cart has catering product in non-catering mode
        return cartItems.some((item) => item.product.isCatering);
    }
};

// invalid cartItem: if the cart items are catering product in non-catering mode, vice-versa

export function useCheckInvalidCartItem() {
    const dispatch = useDispatch();
    const isCatering = useSelector(CartSelectors.getCartIsCatering);
    const cartItems = useSelector(CartSelectors.getCartItems);

    useEffect(() => {
        if (cartItems.length === 0) {
            return;
        }
        const hasInvalidCartItem = checkCartHasInvalidCartItems(
            isCatering,
            cartItems
        );
        if (hasInvalidCartItem) {
            dispatch(CartActions.clearCart());
        }
    }, [isCatering, cartItems, dispatch]);
}
