import React from "react";
import { IPurchase } from "@snackpass/snackpass-types";
import { useOrderStatus } from "./useOrderStatus";
import { Button } from "@/components/ui/button";

interface OrderStatusProps {
    purchase: IPurchase;
}

export function OrderStatus({ purchase }: OrderStatusProps) {
    const {
        orderStatusText,
        deliveryStatusText,
        cancelledText,
        estimatedTimeText,
        refundText,
        cateringText,
        scheduledText,
        deliveryTrackingURL,
        isDelivery,
    } = useOrderStatus(purchase);

    return (
        <div className="items-center flex-col w-full rounded-3xl border border-dashed border-border-subtle my-4 py-4">
            <p className="text-center font-bold text-3xl mb-2">
                {orderStatusText}
            </p>
            <p className="text-center font-bold text-3xl mb-2">
                {deliveryStatusText}
            </p>
            <p className="text-center font-bold text-3xl mb-2">
                {cancelledText}
            </p>
            <p className="text-center mb-0">{scheduledText}</p>
            <p className="text-center mb-0">{cateringText}</p>
            <p className="text-center mb-0">{estimatedTimeText}</p>
            <p className="text-center mb-0">{refundText}</p>
            {isDelivery && (
                <div className="pt-2 w-full  justify-center flex-row flex">
                    <Button
                        label="Track Delivery"
                        onPress={() => {
                            window.open(deliveryTrackingURL, "_blank");
                        }}
                        variant={"outline"}
                    />
                </div>
            )}
        </div>
    );
}
