import { gql } from "graphql-request";

export const GiftCard = gql`
    fragment GiftCard on GiftCard {
        id
        remainingCreditCents
        storeId
        chainId
    }
`;

export const GiftCardId = gql`
    fragment GiftCardId on GiftCard {
        id
    }
`;

export const GiftCardRedeem = gql`
    fragment GiftCardRedeem on GiftCard {
        id
        maxCreditCents
        storeId
        fromName
        note
        status
    }
`;
