import React from "react";
import { PaymentRequestButton } from "@/components/Payment";
import { Button } from "@/components/ui/button";
import usePurchase from "@/hooks/usePurchase";
import { PaymentRequestTokenEvent } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import {
    getUser,
    getUserActiveCardIndex,
    getUserCards,
    getUserIsLoggedIn,
} from "src/redux";
import Cards from "../PaymentSection/Cards";
import PayNowButton from "./PayNowButton";
import { SegmentEventNames, Segment } from "src/utils/Segment";
import { CheckoutSelectors, GiftCardSelectors, getActiveStore } from "@snackpass/accounting";
import { isEmpty } from "lodash";
import PayByGiftCardNameEnforcer from "./PayByGiftCardNameEnforcer";

const useGiftCardCoversTotal = () => {
    const total = useSelector(CheckoutSelectors.getTotal);
    const giftCardCredit = useSelector(
        GiftCardSelectors.getGiftCardCreditAvailable,
    );
    return !!giftCardCredit && total === 0;
};

const CheckoutButton = () => {
    const store = useSelector(getActiveStore);
    const user = useSelector(getUser);
    const isLoggedIn = useSelector(getUserIsLoggedIn);
    const [, updateState] = React.useState<any>();
    const cards = useSelector(getUserCards);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const activeCardIndex = useSelector(getUserActiveCardIndex);
    const {
        createPurchase,
        fetchingQuote,
        isValid,
        message,
        component,
        type,
        isLoadingGiftCards,
        isLoading,
    } = usePurchase();
    const doesGiftCardsCoverTotal = useGiftCardCoversTotal();

    const _onPress = async (e?: PaymentRequestTokenEvent) => {
        if (!user) return;

        Segment.track(SegmentEventNames.CHECKOUT_ATTEMPT, {
            userId: user._id,
            store_id: store?._id,
            store_name: store?.name,
            store_kind: store?.kind,
        });
        if (!isValid) {
            return forceUpdate();
        }

        if (fetchingQuote) {
            alert(
                "Please wait for us to fetch a delivery quote before checking out",
            );
            return;
        }
        Segment.track(SegmentEventNames.CHECKOUT_CREATE_PURCHASE, {
            userId: user._id,
            store_id: store?._id,
            store_name: store?.name,
            store_kind: store?.kind,
        });
        await createPurchase(user, e);
    };

    // if user not logged in, we can not check if cart is fully valid or not
    // some checks can only run if user logged in e.g. delivery quote and delivery address within reach or not
    if (!isLoggedIn || !user) {
        return null;
    }

    // render a warning if cart is invalid
    if (!isValid) {
        return (
            <div className="mx-6 my-2 flex items-center justify-center bg-white">
                <Button
                    label={message}
                    onPress={() => {}}
                    disabled
                    className="w-[100%]"
                />
            </div>
        );
    }

    const shouldPayNowButtonBeVisible =
        cards.length || (doesGiftCardsCoverTotal && !isEmpty(user?.name));
    const needsNameInputForGiftCard =
        doesGiftCardsCoverTotal && isEmpty(user?.name);

    return (
        <div>
            <div className="flex flex-col">
                {/* if no cards saved show apple pay button on top, and user has cards <Cards /> will let user select apple pay and render at the bottom */}
                {!cards.length && !activeCardIndex ? (
                    <div className="px-4 md:px-6">
                        <PaymentRequestButton
                            onPress={_onPress}
                            isLoading={isLoadingGiftCards || isLoading}
                        />
                    </div>
                ) : null}
                <div className="text-xl font-semibold text-primary leading-6 pt-6 px-4 md:px-6">
                    Payment Method
                </div>
                <Cards />
            </div>
            {shouldPayNowButtonBeVisible ? (
                <div className="mx-4 mt-4 mb-2 flex items-center justify-center bg-white">
                    <PayNowButton
                        onPress={_onPress}
                        isLoading={isLoadingGiftCards || isLoading}
                        isFetchingQuote={fetchingQuote}
                        isValid={isValid}
                        errorType={type}
                        errorComponent={component}
                        errorMessage={message}
                    />
                </div>
            ) : needsNameInputForGiftCard ? (
                <PayByGiftCardNameEnforcer />
            ) : null}
        </div>
    );
};

export default CheckoutButton;
