import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGiftCardPromotion, setActivePromotion } from "src/redux";
import { FulfillmentTypeEnum, IPromotion } from "@snackpass/snackpass-types";
import {
    getBasePriceAfterDiscount,
    validatePromotion,
} from "@snackpass/accounting";
import { getActiveProduct } from "@snackpass/accounting/build/src/redux/activeCartItem/selectors";

/*
 * This hook checks if store has gift card promos
 * then validate and apply the best promo based on gift card price
 */

export function useApplyBestGCPromo() {
    const activeProduct = useSelector(getActiveProduct);
    const gcPromotion = useSelector(getGiftCardPromotion);
    const dispatch = useDispatch();
    const validateAndApplyBestPromo = useCallback(() => {
        if (!activeProduct) {
            return;
        }

        const productPrice = activeProduct.price;
        let bestPromo: IPromotion | null = null;
        let bestPrice = productPrice;

        for (const gcp of gcPromotion) {
            const { validated } = validatePromotion(
                gcp,
                productPrice,
                FulfillmentTypeEnum.Pickup,
                [],
                [],
                productPrice,
            );
            if (validated) {
                const priceAfterPromo = getBasePriceAfterDiscount(
                    activeProduct,
                    undefined,
                    gcp,
                );

                if (priceAfterPromo < bestPrice) {
                    bestPrice = priceAfterPromo;
                    bestPromo = gcp;
                }
            }
        }
        dispatch(setActivePromotion(bestPromo));
    }, [activeProduct, dispatch, gcPromotion]);
    return validateAndApplyBestPromo;
}
