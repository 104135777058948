import React, { useEffect } from "react";
import {
    CartSelectors,
    CartActions,
    CheckoutSelectors,
} from "@snackpass/accounting";
import { useSelector, useDispatch } from "react-redux";
import { getActiveStore, hideModal, ModalsName } from "@/redux";
import fp from "lodash/fp";
import CartItemComponent from "./CartItem";
import DealGroup from "./DealGroup";
import { Divider } from "@/SharedComponents/Divider";

export type ViewOrderContentProps = {
    hideOrderNotes?: boolean;
    showDivider?: boolean;
};

const ViewOrderContent: React.FC<ViewOrderContentProps> = ({
    hideOrderNotes,
    showDivider,
}) => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const items = useSelector(CartSelectors.getCartItems);
    const notes = useSelector(CheckoutSelectors.getNotes);
    const noNotes = fp.getOr(false, "noNotes", activeStore);
    const _setCartNotes = fp.compose(dispatch, CartActions.setCartNotes);
    const handleHideViewOrderModal = () => {
        dispatch(hideModal(ModalsName.viewOrderModal));
    };
    const dealGroups = useSelector(CartSelectors.getCartDealGroups);
    const onHide = () => {
        handleHideViewOrderModal();
    };

    useEffect(() => {
        if (items.length < 1) {
            onHide();
        }
    }, [items.length]);

    return (
        <div className="flex flex-col overflow-y-auto h-full relative w-[100%] hidden-scroll">
            <div className="mt-4">
                {items
                    .filter((item) => !item.dealItem)
                    .map((cartItem, index) => {
                        return (
                            <CartItemComponent
                                key={index}
                                item={cartItem}
                                cartItemIndex={cartItem.cartIndex}
                                showDivider={showDivider}
                            />
                        );
                    })}
                <div>
                    {dealGroups.map((dealGroup, index) => (
                        <div key={index}>
                            <DealGroup
                                dealGroup={dealGroup}
                                key={index}
                                dealGroupIndex={index}
                            />
                            <Divider width={1} className="ml-4" />
                        </div>
                    ))}
                </div>
            </div>
            {hideOrderNotes || noNotes ? null : (
                <div className="mx-6 mt-4 mb-8 flex flex-col flex-1">
                    <div className="text-lg font-semibold text-primary mb-3">
                        Order notes
                    </div>
                    <div>
                        <textarea
                            rows={3}
                            placeholder="Add special instructions"
                            className="resize-y p-2.5 text-sm w-[100%] h-[100%] bg-white shadow-[0_0_0_.5px_rgba(239,239,239,1)] rounded-lg"
                            value={notes || ""}
                            onChange={(e) => _setCartNotes(e.target.value)}
                        />
                    </div>
                    <div />
                </div>
            )}
        </div>
    );
};

export default ViewOrderContent;
