import { Divider } from "@/SharedComponents";
import InfoTooltip from "@/SharedComponents/InfoTooltip";
import { GiftCard } from "@/components/GiftCard";
import { GiftCardDesign } from "@/screens/GiftCardScreen/lib";
import { Colors, Helpers } from "@/utils";
import {
    centsToDollars,
    getFirstName,
    optimizedImageURL,
    toDollar,
} from "@/utils/Helpers";
import { GiftCardStatus, IGiftCard, IStore } from "@snackpass/snackpass-types";
import React from "react";
import { GiftCardViewParticipatingLocations } from "./GiftCardViewParticipatingLocations/GiftCardViewParticipatingLocations";
import GiftCardDesignURL from "./GiftCardDesignURL";
import Helmet from "react-helmet";

type Props = {
    data?: IGiftCard;
    error?: Error;
    store: IStore | null;
    token: string;
};

/*
 * Currently this page should only be shown to DGC customers. Although technically everything
 * will load for a PGC.
 */
export default function GiftCardView({ data, error, store, token }: Props) {
    if (error) {
        return (
            <span className="p-6 text-start text-sm text-alert">
                Error: {error.message}
            </span>
        );
    }
    return (
        <div className="flex flex-col text-black">
            <div className="p-4 text-sm font-semibold">
                {store?.name} Gift Card
            </div>
            <Divider width={1} />
            <div className="flex flex-col items-center justify-center p-6">
                <div className="pb-6 text-2xl font-bold">
                    Hi {getFirstName(data!.toName ?? "")},{" "}
                    {getFirstName(data!.fromName ?? "")} sent you a gift card!
                </div>
                {data?.designURL ? (
                    <div className="flex w-full flex-col items-center">
                        <Helmet>
                            <meta
                                property="og:image"
                                content={`${optimizedImageURL(data.designURL, {
                                    size: { h: 200, w: 329.166 },
                                })}`}
                            />
                        </Helmet>
                        <GiftCardDesignURL size="l" imageURL={data.designURL} />
                    </div>
                ) : (
                    <GiftCard
                        loading={!store?._id}
                        storeName={store?.name ?? ""}
                        type={GiftCardDesign.Gift}
                        color={store?.brandColor || Colors.lightBlue}
                    />
                )}
                <div className="py-6">
                    <div className="flex flex-row items-center gap-1 text-3xl font-semibold">
                        {toDollar(
                            centsToDollars(data?.remainingCreditCents ?? 0),
                        )}
                        <InfoTooltip
                            hoverText={`Original Value: ${toDollar(
                                centsToDollars(data?.maxCreditCents ?? 0),
                            )}`}
                        />
                    </div>
                    {data?.status === GiftCardStatus.Refunded ? (
                        <div className="text-xl text-destructive">Refunded</div>
                    ) : null}
                </div>
                {data?.note ? (
                    <div className="w-full rounded-xl bg-neutral-100 p-3 text-left">
                        {data.note}
                    </div>
                ) : null}
            </div>
            <Divider width={1} />
            {/* Could be useful to put back in place when DGCs are used via code + pin rather than only phone number */}
            {/* <div className="flex flex-col p-6">
                <div className="pb-6 text-left text-lg font-semibold">
                    Gift Card Details
                </div>
                <div className="flex w-full flex-row rounded-xl border p-3 text-left">
                    <div className="text-gray-400">Number</div>
                    <div className="align-center flex flex-1 flex-col text-center tracking-[0.25em]">
                        {data!.code}
                    </div>
                </div>
            </div> */}
            <Divider width={1} />
            <div className="flex flex-col p-6 text-left">
                <div className="pb-6 text-lg font-semibold">Instructions</div>
                <div className="text-sm">
                    {data?.toPhone
                        ? `Checkout with phone number ${Helpers.formatPhoneNumber(
                              data?.toPhone,
                          )} to use gift card. Return to this page at any time to check the balance of the card.`
                        : "Unknown phone number"}
                </div>
            </div>
            <Divider width={1} />
            <div className="flex flex-col p-6">
                <div className="pb-6 text-left text-lg font-semibold">
                    Participating Locations
                </div>
                <GiftCardViewParticipatingLocations
                    store={store}
                    giftCard={data}
                    token={token}
                />
            </div>
        </div>
    );
}
