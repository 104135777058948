import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "src/config";
import { firebaseAuth } from "src/firebase";

const httpLink = createHttpLink({
    uri: config.gqlUrl
});

const authLink = setContext(async (_, { headers }) => {
    const token = await firebaseAuth.currentUser?.getIdToken();

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ""
        }
    };
});

export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});
