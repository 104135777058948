import { useSelector, useDispatch } from "react-redux";

import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import { ModalsName, getUserIsLoggedIn, hideModal, showModal } from "@/redux";
import ModalContent from "./Content";
import { useMediaQuery } from "react-responsive";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const DealAndDiscountPreviewModal = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const dispatch = useDispatch();
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const dealAndDiscountPreviewModal = useSelector(
        // @ts-ignore
        (state: ReduxState) => state.modals.modals.dealAndDiscountPreviewModal,
    );
    const onHide = () => {
        dispatch(hideModal(ModalsName.dealAndDiscountPreviewModal));
    };
    const handleLogIn = () => {
        onHide();
        setTimeout(() => {
            dispatch(showModal(ModalsName.authenticationModal));
        }, 300);
    };

    if (isDesktop) {
        return (
            <Dialog open={dealAndDiscountPreviewModal} onOpenChange={onHide}>
                <DialogContent
                    onEscapeKeyDown={onHide}
                    className="overflow-y-scroll hidden-scroll"
                >
                    <div className="flex flex-col h-full relative w-full">
                        <div className="flex sticky top-0 right-0 z-[101] bg-white border-b">
                            <DialogClose className="flex gap-0" />
                            <div className="w-auto h-auto bg-white sticky top-0 ml-6 md:m-auto">
                                <div className="h-14 flex items-center text-primary text-base font-semibold leading-normal">
                                    Log in or sign up to use promotions
                                </div>
                            </div>
                        </div>
                        <div className="m-6">
                            <ModalContent />
                        </div>
                        <DialogFooter className="bg-white border-t p-6 md:px-6">
                            <div className="w-full">
                                <div className="flex flex-1 items-center justify-center"></div>
                                <div className="flex flex-col justify-between gap-4">
                                    <Button
                                        className="w-full"
                                        label="Log in or sign up"
                                        onPress={() => {
                                            handleLogIn();
                                        }}
                                    />
                                    <Button
                                        className="w-full"
                                        label="Done"
                                        onPress={onHide}
                                        variant="outline"
                                    />
                                </div>
                            </div>
                        </DialogFooter>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
    return (
        <Sheet open={dealAndDiscountPreviewModal} onOpenChange={onHide}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Log in or sign up to use promotions</SheetTitle>
                </SheetHeader>
                <div className="overflow-auto px-6 pt-6 pb-32">
                    <ModalContent />
                </div>
                <SheetFooter>
                    <SheetClose asChild>
                        <div className="flex flex-col justify-between gap-2 p-4 border-t">
                            <Button
                                className="w-full"
                                label="Log in or sign up"
                                onPress={() => {
                                    handleLogIn();
                                }}
                            />
                            <Button
                                className="w-full"
                                label="Done"
                                onPress={onHide}
                                variant="outline"
                            />
                        </div>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default DealAndDiscountPreviewModal;
