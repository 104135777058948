import { ResultAsync, err, ok, okAsync } from "neverthrow";
import * as R from "remeda";

import {
    AddonGroupRefs,
    IProduct,
    IProductCategory
} from "@snackpass/snackpass-types";

import { LegacyMenuClient, NormalizedLegacyMenu } from "./legacyMenuClient";
import { populateProduct } from "./populateProducts";
import {
    LegacyAddonGroupsStock,
    LegacyProductStock,
    projectLegacyAddonGroupsStock,
    projectLegacyProductsStock
} from "./projections";

export type LegacyMenu = {
    categories: IProductCategory[];
    cateringCategories: IProductCategory[];
    products: IProduct[];
    success: boolean;
};

export type LegacyStock = {
    addonGroups: LegacyAddonGroupsStock[];
    products: LegacyProductStock[];
};

export type MenuServiceAPI = {
    /**
     * Fetches the menu from the legacy menu client.
     */
    fetchMenu: () => ResultAsync<LegacyMenu | undefined, Error>;

    /**
     * Get the rendered menu anytime after a successful call to `fetchMenu`.
     */
    getMenu: () => LegacyMenu | undefined;

    /**
     * Get the projected stock state (i.e. necessary state for stock management),
     * after a successful call to `fetchMenu`. `undefined` if no menu has been fetched.
     */
    getStock: () => LegacyStock | undefined;

    /**
     * Marks a product sold out by ID.
     */
    sellOutProduct: (
        id: string,
        until?: Date
    ) => ResultAsync<LegacyStock | undefined, Error>;

    /**
     * Marks a product as restocked by ID.
     */
    restockProduct: (id: string) => ResultAsync<LegacyStock | undefined, Error>;

    /**
     * Marks all addons with a given name as sold out.
     * XXX: Our current implementation assumes that all addons with the same name are the same.
     * We duplicate addons, instead of referencing them 😵.
     */
    sellOutAddonsByName: (
        name: string,
        until?: Date
    ) => ResultAsync<LegacyStock | undefined, Error>;

    /**
     * Restocks all addons with a given name.
     * XXX: Our current implementation assumes that all addons with the same name are the same.
     * We duplicate addons, instead of referencing them 😵.
     */
    restockAddonsByName: (
        name: string
    ) => ResultAsync<LegacyStock | undefined, Error>;

    /**
     * DEVELOPMENT ONLY: Fetch the legacy menu directly. In production, use `fetchMenu`.
     */
    __fetchLegacyMenu: () => ResultAsync<NormalizedLegacyMenu, Error>;
};

export const MenuService =
    (legacyMenuClient: LegacyMenuClient) =>
    (storeId: string): MenuServiceAPI => {
        let _legacyMenu: LegacyMenu | undefined;
        let _legacyAddonGroups: NormalizedLegacyMenu["addonGroups"] | undefined;

        /** Utility function to hydrate our legacy menu cache on miss. */
        const hydrateLegacyMenu = () => {
            // Early return if cache hit.
            if (_legacyMenu && _legacyAddonGroups)
                return okAsync({
                    legacyMenu: _legacyMenu,
                    legacyAddonGroups: _legacyAddonGroups
                });

            return fetchLegacyMenu().andThen(() => {
                // Should never happen
                if (!_legacyMenu || !_legacyAddonGroups)
                    return err(
                        new Error(
                            "[hydrateLegacyMenu]: Expected _legacyMenu and _legacyAddonGroups to be defined."
                        )
                    );
                return ok({
                    legacyMenu: _legacyMenu,
                    legacyAddonGroups: _legacyAddonGroups
                });
            });
        };

        /** @see MenuServiceAPI#__fetchLegacyMenu */
        const fetchLegacyMenu = (): ResultAsync<NormalizedLegacyMenu, Error> =>
            legacyMenuClient.fetchMenu(storeId).map((menu) => {
                _legacyMenu = {
                    categories: menu.categories,
                    cateringCategories: menu.cateringCategories,
                    products: menu.products.map(
                        populateProduct(menu.addonGroups)
                    ),
                    success: menu.success
                };
                _legacyAddonGroups = menu.addonGroups;
                return menu;
            });

        /** @see MenuServiceAPI#fetchMenu */
        const fetchMenu = (): ResultAsync<LegacyMenu | undefined, Error> =>
            fetchLegacyMenu().map(() => _legacyMenu);

        /** @see MenuServiceAPI#getMenu */
        const getMenu = (): LegacyMenu | undefined => _legacyMenu;

        /** @see MenuServiceAPI#getStock */
        const getStock = () => {
            if (!_legacyMenu || !_legacyAddonGroups) return undefined;

            return {
                addonGroups: projectLegacyAddonGroupsStock(_legacyAddonGroups),
                products: projectLegacyProductsStock(_legacyMenu.products)
            };
        };

        /** @see MenuServiceAPI#sellOutProduct */
        const sellOutProduct = (id: string, until?: Date) => {
            return hydrateLegacyMenu().andThen(({ legacyMenu }) =>
                legacyMenuClient
                    .sellOutProduct(id, until)
                    .map(updateLegacyCacheOnProductStockChange(legacyMenu))
                    .map(() => getStock())
            );
        };

        /** @see MenuServiceAPI#restockProduct */
        const restockProduct = (id: string) =>
            hydrateLegacyMenu().andThen(({ legacyMenu }) =>
                legacyMenuClient
                    .restockProduct(id)
                    .map(updateLegacyCacheOnProductStockChange(legacyMenu))
                    .map(() => getStock())
            );

        /** @see MenuServiceAPI#sellOutAddonsByName */
        const sellOutAddonsByName = (name: string, until?: Date) =>
            hydrateLegacyMenu().andThen(({ legacyMenu, legacyAddonGroups }) =>
                legacyMenuClient
                    .sellOutAddons(name, storeId, until)
                    .map(
                        updateLegacyCacheOnAddonStockChange(
                            name,
                            legacyMenu,
                            legacyAddonGroups
                        )
                    )
                    .map(() => getStock())
            );

        /** @see MenuServiceAPI#restockAddonsByName */
        const restockAddonsByName = (name: string) =>
            hydrateLegacyMenu().andThen(({ legacyMenu, legacyAddonGroups }) =>
                legacyMenuClient
                    .restockAddons(name, storeId)
                    .map(
                        updateLegacyCacheOnAddonStockChange(
                            name,
                            legacyMenu,
                            legacyAddonGroups
                        )
                    )
                    .map(() => getStock())
            );

        return {
            __fetchLegacyMenu: fetchLegacyMenu,

            fetchMenu,
            getMenu,
            getStock,

            sellOutProduct,
            restockProduct,

            sellOutAddonsByName,
            restockAddonsByName
        };
    };

/**
 * Utility function for updating our caches on addon stock change.
 * NB: This function mutates the legacy menu and addon groups.
 */
const updateLegacyCacheOnAddonStockChange =
    (name: string, legacyMenu: LegacyMenu, legacyAddonGroups: AddonGroupRefs) =>
    (updatedProducts: IProduct[]) => {
        const updatedAddon = updatedProducts
            .at(0)
            ?.addonGroups.flatMap((ag) => ag.addons)
            .find((addon) => addon.name === name);

        // Should never happen;
        if (!updatedAddon) return;

        // Grab the sold out fields from an updated addon to use for updating cache.
        const soldOutFields = R.pick(updatedAddon, ["soldOut", "soldOutDates"]);

        // Update relevant products
        updatedProducts.forEach((updatedProduct) => {
            updateLegacyCacheOnProductStockChange(legacyMenu)(updatedProduct);
        });

        // Updating relevant addons
        Object.values(legacyAddonGroups).forEach((addonGroup) => {
            addonGroup.ag.addons = addonGroup.ag.addons.map((addon) => ({
                ...addon,
                ...(addon.name === name ? soldOutFields : {})
            }));
        });
    };

/**
 * Utility function for updating our caches on product stock change.
 * NB: This function mutates the legacy menu.
 */
const updateLegacyCacheOnProductStockChange =
    (legacyMenu: LegacyMenu) => (updatedProduct: IProduct) => {
        const existingIndex = legacyMenu.products.findIndex(
            (product) => product._id === updatedProduct._id
        );

        if (existingIndex === -1) return;

        // NB: We spread the previous value to ensure we don't lose virtual fields (e.g. `categoryId` / `categoryName`).
        legacyMenu.products = [
            ...legacyMenu.products.slice(0, existingIndex),
            {
                ...legacyMenu.products[existingIndex],
                ...updatedProduct
            },
            ...legacyMenu.products.slice(existingIndex + 1)
        ];
    };
