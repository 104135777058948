import Helmet from "react-helmet";
import Icon40x40 from "src/assets/logos/Icon-App-40x40@3x.png";
import Icon60x60 from "src/assets/logos/Icon-App-60x60@3x.png";
import { parseStoreName } from "@/utils";
import { getActiveStore } from "@snackpass/accounting";
import { useSelector } from "react-redux";

const Seo = () => {
    const store = useSelector(getActiveStore);
    if (!store) {
        return null;
    }
    const url = `https://${window.location.hostname}/${store.slug}`;
    const parsedName = parseStoreName(store.name);
    const storeName = parsedName.name;
    const title = `${storeName} | Order Online`;
    const description = `Order online and track your order live. Powered by Snackpass`;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta name="robots" content="index,follow" />
            <meta name="url" content={url} />
            <link
                rel="shortcut icon"
                href={`${store.logoUrl}?v=${new Date().getTime()}`}
                sizes="40x40"
                type="image/png"
            />

            <link
                rel="icon"
                type="image/png"
                sizes="120x120"
                href={store.logoUrl || Icon40x40}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="180x180"
                href={store.logoUrl || Icon60x60}
            />

            {/* Apple */}
            <meta name="apple-mobile-web-app-title" content={storeName} />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={store.logoUrl || Icon60x60}
            />
            {/* Instagram / Facebook / LinkedIn tags */}
            <meta property="og:title" content={`${storeName}`} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={store?.logoUrl || ""} />
            {/* TWITTER TAGS */}
            <meta name="twitter:title" content={`${storeName} Order Online`} />
            <meta
                name="twitter:description"
                content={`${storeName} — order online and track your order live.`}
            />
            <meta name="twitter:image" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    );
};

export default Seo;
