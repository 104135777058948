import React, { useState, useEffect } from "react";
import {
    CartWhen,
    FulfillmentTypeEnum,
    WhenTypeEnum,
} from "@snackpass/snackpass-types";
import { CartSelectors, CartActions } from "@snackpass/accounting";
import moment from "moment-timezone";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import { useDispatch } from "react-redux";
import * as actions from "../../redux";
import { hideModal, ModalsName } from "@/redux";
import ModalActivePane from "./components/ModalActivePane";
import ModalFooter from "./components/ModalFooter";
import { ScheduleContext } from "./ScheduleModalContext";
import { useFetchActiveStore } from "#activeStore";
import ToggleSlider from "@/components/ui/toggleSlider";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "@/redux/utils";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";

const ScheduleModal = () => {
    const { fetchStore } = useFetchActiveStore();
    const cart = useAppSelector(CartSelectors.getCart);
    const activeStore = useAppSelector(actions.getActiveStore);
    const isCatering = useAppSelector(CartSelectors.getCartIsCatering);
    const scheduledDate = cart.scheduledDate;
    const fulfillment = useAppSelector(CartSelectors.getCartFulfillment);
    const cartWhen = useAppSelector(CartSelectors.getCartWhen);
    const scheduleModal = useAppSelector(actions.getIsScheduleModalOpen);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isSwitchingToCatering = useAppSelector(
        actions.getIsSwitchingToCatering,
    );
    const dispatch = useDispatch();
    const [modalWhen, _setModalWhen] = useState<CartWhen>();
    const [modalSelectedWeekDay, setModalSelectedWeekDay] =
        useState<moment.Moment | null>(moment(scheduledDate));
    const [modalSelectedTime, setModalSelectedTime] =
        useState<moment.Moment | null>(moment(scheduledDate));
    const handleHideScheduleModal = () => {
        dispatch(hideModal(ModalsName.scheduleModal));
    };
    const setCartWhen = (when: CartWhen) => {
        dispatch(CartActions.setCartWhen(when));
    };
    const shouldNotAllowCloseModal =
        fulfillment === FulfillmentTypeEnum.Pickup &&
        !activeStore?.hasPickupNow &&
        !scheduledDate; // if store doesn't support pickup Now, user has to select a schedule time

    useEffect(() => {
        // set initial value
        _setModalWhen(cartWhen);
        setModalSelectedWeekDay(scheduledDate ? moment(scheduledDate) : null);
        setModalSelectedTime(scheduledDate ? moment(scheduledDate) : null);
    }, [fulfillment, scheduledDate, cartWhen]);

    useEffect(() => {
        setModalSelectedWeekDay(scheduledDate ? moment(scheduledDate) : null);
        setModalSelectedTime(scheduledDate ? moment(scheduledDate) : null);
    }, [fulfillment, scheduledDate]);

    const setModalWhen = (when: string) => {
        if (when === WhenTypeEnum.Now) {
            _setModalWhen(WhenTypeEnum.Now);
            setModalSelectedWeekDay(null);
            setModalSelectedTime(null);
        } else {
            _setModalWhen(WhenTypeEnum.Later);
        }
    };

    const handleConfirmOptions = async () => {
        handleHideScheduleModal();
        if (modalWhen === WhenTypeEnum.Now) {
            setCartWhen(WhenTypeEnum.Now);
            onSetCartScheduleDate(null);
        } else if (modalWhen === WhenTypeEnum.Later) {
            setCartWhen(WhenTypeEnum.Later);
            onSetCartScheduleDate(modalSelectedTime);
            if (isCatering && isSwitchingToCatering) {
                dispatch(actions.setIsSwitchingToCatering(false));
            }
        }
    };

    const onHide = async () => {
        // if user didn't confirm(when switching from non-catering to catering), exit catering mode
        // if user already in catering and changing the time only, if shouldn't exist catering even they didn't press confirm
        if (isCatering && isSwitchingToCatering) {
            dispatch(CartActions.setCartCatering(false));
            dispatch(CartActions.setCartWhen(WhenTypeEnum.Now));
        }

        if (!shouldNotAllowCloseModal) {
            _setModalWhen(cartWhen); // reset to initial value
            handleHideScheduleModal();
        }
    };

    const onSetCartScheduleDate = (date: moment.Moment | null) => {
        dispatch(
            CartActions.setCartCateringScheduleDate(
                date ? date.toDate() : null,
            ),
        );

        if (!activeStore?._id) return;

        // TODO: We refetch the store (and menu) when the user selects a schedule date. WHY?
        if (date) {
            // Fire and forget :(
            fetchStore(`$${activeStore?._id}`);
        }
    };
    const showToggle = !isCatering && activeStore?.hasPickupNow;
    if (isDesktop) {
        return (
            <ScheduleContext.Provider
                value={{
                    modalWhen,
                    modalSelectedWeekDay,
                    modalSelectedTime,
                    setModalWhen,
                    setModalSelectedWeekDay,
                    setModalSelectedTime,
                }}
            >
                <Dialog open={scheduleModal} onOpenChange={onHide}>
                    <DialogContent
                        onEscapeKeyDown={onHide}
                        className="overflow-y-scroll hidden-scroll"
                    >
                        <div className="relative flex h-full w-full flex-col">
                            <div className="sticky right-0 top-0 z-[101] flex border-b bg-white">
                                {/* if store doesn't support pickupNow, user has to
                            select a schedule time */}
                                {!shouldNotAllowCloseModal && (
                                    <DialogClose className="flex gap-0" />
                                )}
                                <div className="sticky top-0 ml-6 h-auto w-auto bg-white md:m-auto">
                                    <div className="flex h-14 items-center text-base font-semibold leading-normal text-primary">
                                        {isCatering ? "Catering" : "Time"}
                                    </div>
                                </div>
                            </div>
                            {showToggle ? (
                                <div className="mx-6 mt-4 flex flex-row space-x-1 rounded-lg bg-gray-200 p-[1px]">
                                    <ToggleSlider
                                        small
                                        name="modal-schedule-toggle"
                                        options={[
                                            {
                                                value: WhenTypeEnum.Now,
                                                label: "ASAP",
                                            },
                                            {
                                                value: WhenTypeEnum.Later,
                                                label: "Schedule",
                                            },
                                        ]}
                                        value={modalWhen as CartWhen}
                                        onValueChange={(val) =>
                                            setModalWhen(val as WhenTypeEnum)
                                        }
                                    />
                                </div>
                            ) : null}

                            <ModalActivePane />
                            <DialogFooter className="border-t bg-white pt-0">
                                <ModalFooter
                                    handleConfirmOptions={handleConfirmOptions}
                                />
                            </DialogFooter>
                        </div>
                    </DialogContent>
                </Dialog>
            </ScheduleContext.Provider>
        );
    }
    return (
        <ScheduleContext.Provider
            value={{
                modalWhen,
                modalSelectedWeekDay,
                modalSelectedTime,
                setModalWhen,
                setModalSelectedWeekDay,
                setModalSelectedTime,
            }}
        >
            <Sheet open={scheduleModal} onOpenChange={onHide}>
                <SheetContent className="pb-20">
                    <SheetHeader>
                        <SheetTitle>
                            {isCatering ? "Catering" : "Time"}
                        </SheetTitle>
                    </SheetHeader>
                    <div className="relative flex h-full w-full flex-col max-h-[500px] overflow-auto">
                        {showToggle ? (
                            <div className="mx-6 mt-4 flex flex-row space-x-1 rounded-lg bg-gray-200 p-[1px]">
                                <ToggleSlider
                                    small
                                    name="modal-schedule-toggle"
                                    options={[
                                        {
                                            value: WhenTypeEnum.Now,
                                            label: "ASAP",
                                        },
                                        {
                                            value: WhenTypeEnum.Later,
                                            label: "Schedule",
                                        },
                                    ]}
                                    value={modalWhen as CartWhen}
                                    onValueChange={(val) =>
                                        setModalWhen(val as WhenTypeEnum)
                                    }
                                />
                            </div>
                        ) : null}

                        <ModalActivePane />
                    </div>
                    <SheetFooter className="px-6 border-t pb-4">
                        <SheetClose asChild>
                            <ModalFooter
                                handleConfirmOptions={handleConfirmOptions}
                            />
                        </SheetClose>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        </ScheduleContext.Provider>
    );
};

export default ScheduleModal;
