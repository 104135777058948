import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";
import { useSetPunchcards } from "@snackpass/loyalty-react";
import { projectPunchcards } from "@snackpass/loyalty";

import { getUserIsLoggedIn, setPunchcards, getUser } from "@/redux";
import Api from "src/utils/Api/REST";

export function useLoadPunchCards() {
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const user = useSelector(getUser);
    const userId = user?._id;

    const activeStore = useSelector(getActiveStore);
    const storeId = activeStore?._id;

    const { setUserStorePunchcards } = useSetPunchcards();

    const dispatch = useDispatch();

    const fetchPunchcards = useCallback(async () => {
        if (!storeId || !isUserLoggedIn || !userId) return;

        try {
            const res = await Api.me.getPunchcards({
                storeId,
            });
            const { punchcards, totalPointsBalance } = res.data;

            const projectedPunchcards = projectPunchcards(punchcards);

            setUserStorePunchcards(
                userId,
                projectedPunchcards,
                totalPointsBalance,
            );

            dispatch(setPunchcards(punchcards));
        } catch (error) {
            setUserStorePunchcards(userId, [], 0);
            dispatch(setPunchcards([]));
        }
    }, [storeId, dispatch, isUserLoggedIn, setUserStorePunchcards, userId]);

    useEffect(() => {
        fetchPunchcards();
    }, [fetchPunchcards]);
}
