import React from "react";
import { ReactComponent as CheckIcon } from "src/assets/icons/check.svg";
import { cn } from "@/utils";
import { getRewardPointsString } from "@/utils/Loyalty";
import { useSelector } from "react-redux";
import { Button } from "../ui/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../ui/tooltip";
import { getUserIsLoggedIn } from "@/redux";

type PromotionCardProps = {
    name: string;
    points?: number | string;
    classNames?: string;
    disable?: boolean;
    handleClick: () => void;
    isSelected?: boolean;
    isReward?: boolean;
};

const PromotionCard: React.FC<PromotionCardProps> = ({
    name,
    points,
    classNames,
    disable,
    handleClick,
    isSelected,
    isReward,
}) => {
    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    return (
        <div
            className={cn(
                "m-1 flex flex-row items-center rounded-2xl border px-4 py-4 outline",
                {
                    "bg-brandColor/20 outline-2 outline-brandColor": isSelected,
                    "bg-white outline-transparent": !isSelected,
                    "cursor-pointer": !disable,
                },
                classNames,
            )}
        >
            <div className="flex flex-1 flex-col">
                <div
                    className={cn(
                        "line-clamp-2 select-none text-ellipsis text-base font-semibold text-primary pr-4",
                        {
                            "text-primary": !disable,
                            "text-secondary": disable,
                        },
                    )}
                >
                    {name}
                </div>

                <div
                    className={cn("select-none text-sm font-normal", {
                        "text-secondary": !disable,
                    })}
                >
                    {isReward ? "For You" : getRewardPointsString(points)}
                </div>
            </div>
            {isUserLoggedIn ? (
                <div className="basis-1/8 outline-none">
                    <Button
                        variant={isSelected ? "brand" : "default"}
                        className="rounded-full"
                        onPress={handleClick}
                        disabled={disable}
                        label={
                            isSelected ? (
                                <CheckIcon className="h-4 w-4 fill-white" />
                            ) : (
                                "Use"
                            )
                        }
                    />
                </div>
            ) : (
                <div className="basis-1/8 outline-none">
                    <TooltipProvider delayDuration={200}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    variant="default"
                                    className="rounded-full opacity-50 cursor-default"
                                    onPress={() => {}}
                                    label="Use"
                                />
                            </TooltipTrigger>
                            <TooltipContent>
                                Login to use rewards
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            )}
        </div>
    );
};

export default PromotionCard;
