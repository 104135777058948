import { compose, head, map, prop, propOr, sumBy } from "lodash/fp";
import { createSelector } from "reselect";
import {
    flattenCartItem,
    groupFlattenedCartItems
} from "../../accountingFormulas/flattenCartItem";
import { ICart, ReduxState } from "../../types";

export const getMultiCart = (state: Pick<ReduxState, "multiCart">) =>
    state.multiCart;

// total number of items in multicart across all stores
export const getMultiCartItemCount = createSelector(
    [getMultiCart],
    sumBy("items.length")
);

// returns list of unflattened item lists for each store
export const getMultiCartItemsUnflattened = createSelector(
    [getMultiCart],
    map((cart) => cart.items)
);

// returns list of flattened item lists for each store
export const getMultiCartItems = createSelector(
    [getMultiCartItemsUnflattened],
    map((cartItems) => cartItems.map(flattenCartItem))
);

// returns list of grouped item lists for each store
export const getMultiCartItemsGrouped = createSelector(
    [getMultiCartItems],
    map(groupFlattenedCartItems)
);

// returns list of deal group lists for each store
export const getMultiCartDealGroups = createSelector(
    [getMultiCart],
    map(propOr([], "dealGroups"))
);

export const getMultiCartIsEmpty = createSelector(
    [getMultiCart],
    (multiCart) => multiCart.length === 1
);

export const getMultiCartFulfillment = createSelector(
    [getMultiCart],
    compose(prop("fulfillment"), head)
);

// returns a list of booleans indicating whether to use
// credit at each store
export const getMultiCartUseCredit = createSelector(
    [getMultiCart],
    map((cart: ICart) => cart.useCredit)
);

export const getMultiCartWhen = createSelector(
    [getMultiCart],
    compose(prop("when"), head)
);

export const getMultiCartScheduledDate = createSelector(
    [getMultiCart],
    compose(prop("scheduledDate"), head)
);

export const getMultiCartTableNumber = createSelector(
    [getMultiCart],
    compose(prop("tableNumber"), head)
);

export const getAllNumbersOfBags = createSelector(
    [getMultiCart],
    map(propOr(0, "numberOfBags"))
);
