import { DateTime } from "luxon";

import type { LocalHours, TimeOfDayRange, TimeOfDayWithZone } from "src/types";
import { dateHours, weekdayHours } from "./containsTime";

const sortHours = (a: TimeOfDayRange, b: TimeOfDayRange) =>
    a.start.hour - b.start.hour || a.start.minute - b.start.minute;

const getTomorrowWeekdayHours = (hours: LocalHours, dateTime: DateTime) => {
    const tomorrow = dateTime.plus({ days: 1 }).weekday;
    return hours.weekdays[tomorrow] ?? [];
}

// Merge blocks with the same start and end time in the same day
const mergeSameDayBlocks = (blocks: TimeOfDayRange[]) => {
    return blocks.reduce<TimeOfDayRange[]>(
        (acc, { start, end }) => {
            const lastBlock = acc[acc.length - 1];
            if (
                lastBlock &&
                lastBlock.end.hour === start.hour &&
                lastBlock.end.minute === start.minute
            ) {
                lastBlock.end = end;
            } else {
                acc.push({ start, end });
            }
            return acc;
        },
        []
    );
};

// Merge blocks that span across two days
const mergeWithTomorrow = (blocks: TimeOfDayRange[], tomorrow: TimeOfDayRange[], dateTime: DateTime) => {
    return blocks.reduce<TimeOfDayRange | undefined>(
        (acc, { start, end }) => {
            const relevantBlock = acc ??
                (dateTime.set({ ...start, second: 0 }) <= dateTime &&
                dateTime < dateTime.set({ ...end, second: 0 })
                    ? { start, end }
                    : undefined);

            // Handle case where the date range is split across two days.
            // One minute gap between days is used to determine if the date range is split.
            // If the date range is split, the end time is set to the end of the first day
            // and the start time of the next block is set to the start of the second day.
            if (
                relevantBlock
                && relevantBlock.end.hour === 23 
                && relevantBlock.end.minute === 59
                // Check if tomorrow's weekday hours start at midnight
                && tomorrow.length > 0
                && tomorrow[0].start.hour === 0
                && tomorrow[0].start.minute === 0
            ) {
                // Set tomorrows end time to the end of the first day
                relevantBlock.end = tomorrow[0].end;
            }
            return relevantBlock;
        },
        undefined
    );
};

/**
 * Returns the TimeOfDayRange within LocalHours containing the unix time, if exists.
 * By default, timeMs is `now()` in LocalHours's timezone.
 */
export const containedBy =
    (hours: LocalHours) =>
    (timeMs = DateTime.now().toMillis()): TimeOfDayRange | undefined => {
        const dateTime = DateTime.fromMillis(timeMs).setZone(hours.zone);
        const dates = dateHours(hours, dateTime);
        const datesSorted = dates?.sort(sortHours);

        const weekdays = weekdayHours(hours, dateTime);
        const weekdaysSorted = weekdays.sort(sortHours);

        // Tomorrow's day of the week
        const tomorrowWeekdayHours = getTomorrowWeekdayHours(hours, dateTime);
        const tomorrowWeekdayHoursSorted = tomorrowWeekdayHours.sort(sortHours);

        const datesOrWeekdays = datesSorted ?? weekdaysSorted;

        // Combine blocks with the same start and end time
        const datesOrWeekdaysMerged = mergeSameDayBlocks(datesOrWeekdays);

        return mergeWithTomorrow(datesOrWeekdaysMerged, tomorrowWeekdayHoursSorted, dateTime);
    };

/**
 * Returns if the store is open and when if closes given the provided date-time in unix time (timeMs).
 * By default, timeMs is now in Store's timezone.
 */
export const storeClosesAt =
    (hours: LocalHours) =>
    (timeMs = DateTime.now().toMillis()): TimeOfDayWithZone | undefined => {
        const closesAt = containedBy(hours)(timeMs);
        return closesAt ? { ...closesAt.end, zone: hours.zone } : undefined;
    };
