import { IPurchase } from "@snackpass/snackpass-types";
import React from "react";
import { cn } from "@/utils";
import useURLParams from "@/hooks/useURLParams";
import { history } from "src/utils/history";
import { getRewardPointsString } from "@/utils/Loyalty";

type Props = {
    order: IPurchase & { receiptToken: string };
};

type StoreNameParts = {
    name: string;
    address: string;
};

function parseStoreName(storeName: string | undefined): StoreNameParts {
    if (!storeName) {
        return { name: "", address: "" };
    }
    const matched = storeName.match(/(.*?)(?:\((.*?)\))?$/);

    if (!matched) {
        return { name: storeName, address: "" };
    }

    const [, name, address] = matched;

    return {
        name: name.trim(),
        address: address ? address.trim() : "",
    };
}

const OrderItem: React.FC<Props> = ({ order }) => {
    const params = useURLParams();
    const fromCustomApp = params.get("fromCustomApp");
    const storeNameParts = parseStoreName(order?.store?.name);

    const _onClickMore = () => {
        const smartReceiptURL = `/purchases/${order._id}/${order.receiptToken}/status`;
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: "clickedViewPurchase",
                    smartReceiptURL,
                }),
            );
        }
        if (fromCustomApp) {
            return smartReceiptURL;
        } else {
            history.push(smartReceiptURL);
        }
    };
    const pointsText = getRewardPointsString(order.pointsEarned);

    return (
        <div
            className={cn(
                "flex justify-between items-center rounded cursor-pointer",
                fromCustomApp ? "py-1" : "py-2",
            )}
            onClick={_onClickMore}
        >
            <div className="flex flex-row items-center basis-2/3">
                <div className="flex flex-col">
                    <span className="font-bold text-ellipsis line-clamp-2">
                        {storeNameParts.address || storeNameParts.name}
                    </span>
                    <span className="text-sm text-gray-500">
                        {order.startTime
                            ? new Date(order.createdAt).toLocaleDateString()
                            : "N/A"}{" "}
                    </span>
                </div>
            </div>
            <div
                className="flex flex-row items-center justify-end cursor-pointer gap-2 basis-1/3"
                onClick={_onClickMore}
            >
                <div className="flex justify-between flex-col items-end">
                    <div className="font-semibold">
                        ${order.amountPaidByCustomer}
                    </div>
                    <div className="font-normal text-end leading-tight">
                        {pointsText}
                    </div>
                </div>
                <div className="rounded p-1 flex justify-between items-center">
                    <i className="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
    );
};

export default OrderItem;
