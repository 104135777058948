import {
    CartAdjustment,
    CartAdjustmentType,
    FeePolicyPayer,
    FeePolicyRecipient,
    IOrderItem,
    IPurchase,
    IPurchaseFee,
    IStore,
    PaymentProvider,
    ReservedFeeEnum
} from "@snackpass/snackpass-types";
import Dinero from "dinero.js";
import { compose, filter, prop, propOr, reduce } from "lodash/fp";

import { purchaseIs3PDelivery } from "../../utils/Helpers";
import { D, DD } from "../../utils/Money";

import { filterNulls } from "../../utils/arrays/filterNulls";
import { descriptionForFee, labelForFee } from "../fees/utils";
import {
    calcCartItemSubtotal,
    calcCustomDiscountsTotal,
    calcCustomSurchargeTotal
} from "../formulas";
import { SalesTax } from "../salesTax";

enum LineItemLabels {
    Refunded = "Refunded",
    EstimatedTotal = "Estimated Total",
    Total = "Total",
    AdjustedTotal = "Adjusted Total", // Total after refunds
    GiftCardCredit = "Gift Card Credit",
    StoreCredit = "Store Credit",
    GlobalCredit = "Snackpass Credit",
    Upcharge = "Upcharge",
    ServiceFees3P = "3P Service fees",
    ChannelDiscount = "Channel Discount",
    Tip = "Tip",
    ThirdPartyDeliveryTip = "Delivery Tip",
    DeliveryFee = "Delivery Fee",
    BagFee = "Bag Fee",
    CardFee = "Card Fee",
    Tax = "Tax",
    Discount = "Discount",
    Subtotal = "Subtotal"
}

type IPurchaseCommonFields =
    | "items"
    | "deliveryInfo"
    | "fulfillment"
    | "tip"
    | "upChargeAmount"
    | "convenienceFee"
    | "fees"
    | "cartAdjustments"
    | "deliveryFee"
    | "taxableSnackpassContribution"
    | "customFees"
    | "salesTaxRate"
    | "upChargeAmount"
    | "storeCreditUsed";

export type ReceiptLineItem = {
    label: string;
    description?: string; // Detailed description, intended for use in tooltips
    value: number;
    isDiscount?: boolean;
    isRefund?: boolean;
    textProps?: {
        bold?: boolean;
        color?: string;
    };
};

/**
 * This library contains functions which recompute purchase accounting values
 * based on whether the purchase contains any promotions or rewards which are
 * explicitly marked as hidden on the POS.
 */

// Iff an item is using a reward or promotion which is explicitly marked as
// hidden, do not show the item's discounted price.
export const shouldShowDiscount = (item: IOrderItem): boolean => {
    const usedHiddenPromotion =
        propOr(true, "promotion.tabletShowsPromotionDiscount", item) === false;
    const usedHiddenReward =
        propOr(true, "reward.tabletShowsRewardDiscount", item) === false;

    return !(usedHiddenPromotion || usedHiddenReward);
};

const _formatItem = (item: IOrderItem): IOrderItem =>
    shouldShowDiscount(item)
        ? item
        : {
              ...item,
              rewardPromotion: null,
              promotion: null,
              reward: null,
              usingPromotion: false,
              usingReward: false,
              totalPriceAfterDiscount: item.totalPrice
          };

const _calculateItemTotal = (
    purchase: Pick<IPurchase, "items">
): Dinero.Dinero =>
    reduce(
        (sum, item) => sum.add(DD(_formatItem(item).totalPrice)),
        D(0),
        purchase.items
    );

const _calculateSubtotal = (
    purchase: Pick<IPurchase, "items" | "cartAdjustments">,
    applyDiscounts = true
): Dinero.Dinero => {
    const itemTotal = _calculateItemTotal(purchase);
    if (!purchase.cartAdjustments || purchase.cartAdjustments.length === 0)
        return itemTotal;

    const cartAdjustments: CartAdjustment[] = purchase.cartAdjustments;

    const surchargeTotal = calcCustomSurchargeTotal(cartAdjustments);
    let subtotal = itemTotal.add(DD(surchargeTotal));
    if (applyDiscounts) {
        const discountsTotal = calcCustomDiscountsTotal(
            purchase.items,
            cartAdjustments
        );
        subtotal = subtotal.subtract(DD(discountsTotal));
    }

    return subtotal;
};

const _calculateSalesTaxAmount = (
    purchase: Pick<
        IPurchase,
        | "items"
        | "fees"
        | "fulfillment"
        | "deliveryFee"
        | "deliveryInfo"
        | "taxableSnackpassContribution"
        | "customFees"
        | "convenienceFee"
        | "salesTaxRate"
        | "upChargeAmount"
        | "storeCreditUsed"
        | "cartAdjustments"
    >
): Dinero.Dinero =>
    D(
        SalesTax.calculateForPurchase(purchase, {
            items: purchase.items.map(_formatItem),
            fees: _filterPositiveFees(_filterCustomerFees(purchase.fees || [])),
            deliveryFeeAmountDollars: purchaseIs3PDelivery(purchase)
                ? 0
                : undefined
        }).taxAmount.amount
    );

// TODO: When we support tipping and stores *and* drivers, rework this function
const _calculateTip = (
    purchase: Pick<IPurchase, "deliveryInfo" | "fulfillment" | "tip">
): Dinero.Dinero => DD(purchaseIs3PDelivery(purchase) ? 0 : purchase.tip || 0);

// Since we only support tipping one of stores or drivers, we know a 3p delivery tip is going to the driver
const _calculate3PDeliveryTip = (
    purchase: Pick<IPurchase, "deliveryInfo" | "fulfillment" | "tip">
): Dinero.Dinero =>
    DD((purchaseIs3PDelivery(purchase) ? purchase.tip : 0) ?? 0);

// TODO: When 1P Delivery Fee is rolled into fees array, remove this function
const _calculateDeliveryFee = (
    purchase: Pick<IPurchase, "deliveryInfo" | "fulfillment" | "deliveryFee">
): Dinero.Dinero =>
    DD(purchaseIs3PDelivery(purchase) ? 0 : purchase.deliveryFee || 0);

// Not finally used atm but kept in case its needed in the future
const _calculateTaxesAndFees = (
    purchase: Pick<IPurchase, IPurchaseCommonFields | "taxExemptSalesAmount">,
    store?: Pick<IStore, "customFeeLabels"> | null
) => {
    const { convenienceFee = 0 } = purchase;
    const deliveryFee = _calculateDeliveryFee(purchase).toUnit();
    const salesTaxAmount = _calculateSalesTaxAmount(purchase).toUnit();
    const formattedFees = _formatFees(purchase, store);
    const taxesAndFees =
        salesTaxAmount +
        convenienceFee +
        deliveryFee +
        formattedFees.reduce((prevFee, currFee) => prevFee + currFee.amount, 0);
    return DD(taxesAndFees);
};

const _filterCustomerFees: (fees?: IPurchaseFee[]) => IPurchaseFee[] = filter(
    (fee: IPurchaseFee) => fee.fee.payer === FeePolicyPayer.Customer
);

const _filterPositiveFees: (fees?: IPurchaseFee[]) => IPurchaseFee[] = filter(
    (fee: IPurchaseFee) => fee.total > 0
);

// We should display bag fee if enabled even if its 0, but not negative in the case of refund.
const _filterZeroValuesFees: (fees?: IPurchaseFee[]) => IPurchaseFee[] = filter(
    (fee: IPurchaseFee) =>
        (fee.fee.name === ReservedFeeEnum.BagStoreFee && fee.total >= 0) ||
        fee.total > 0
);

type FormattedFee = { label: string; amount: number; description?: string };
const _formatFees = (
    purchase: Pick<IPurchase, "fees" | "numberOfBags">,
    store?: Pick<IStore, "customFeeLabels"> | null
): FormattedFee[] =>
    _filterZeroValuesFees(_filterCustomerFees(purchase.fees)).map((f) => ({
        label: labelForFee(f.fee.name, store),
        amount: f.total,
        description: descriptionForFee(f, purchase.numberOfBags ?? 0, store)
    }));

const _calculateFeeTotal: (purchase: Pick<IPurchase, "fees">) => Dinero.Dinero =
    compose(
        reduce(
            (sum: Dinero.Dinero, fee: IPurchaseFee) => DD(fee.total).add(sum),
            D(0)
        ),
        _filterPositiveFees,
        _filterCustomerFees,
        prop("fees")
    );

// TODO @Accounting team: since amountDiscounted is set to 0 on refund
// we need to recompute here to be able to display it
// It would be better to review how we do refunds to avoid setting values to 0
// thus losing info about the purchase
const _calculateAmountDiscounted = (
    purchase: Pick<IPurchase, "items" | "refund" | "amountDiscounted">
) => {
    if (!purchase.refund) return purchase.amountDiscounted || 0;
    const amountDiscounted = purchase.items.reduce((totalDiscount, curItem) => {
        const totalDiscountOnItem =
            curItem.totalPrice - curItem.totalPriceAfterDiscount;
        return totalDiscount + totalDiscountOnItem;
    }, 0);
    return amountDiscounted;
};

// Artifact: Not used for the moment but might be needed in the future - pending check on product team
// TODO @Accounting team: since giftCardsCreditUsed is set to 0 on refund
// we need to recompute here to be able to display it
// It would be better to review how we do refunds to avoid setting values to 0
// thus losing info about the purchase
const _calculateGiftCardCreditUsed = (
    purchase: Pick<
        IPurchase,
        "giftCardsUsed" | "refund" | "giftCardsCreditUsed"
    >
) => {
    if (!purchase.refund || !purchase.giftCardsUsed)
        return purchase.giftCardsCreditUsed || 0;
    const giftCardsCreditUsed = purchase.giftCardsUsed.reduce(
        (totalCreditUsed, curGiftCard) =>
            totalCreditUsed + curGiftCard.amountInCents,
        0
    );
    return giftCardsCreditUsed;
};

const _calculateTotal = (
    purchase: Pick<IPurchase, IPurchaseCommonFields>
): Dinero.Dinero =>
    _calculateSubtotal(purchase)
        .add(_calculateSalesTaxAmount(purchase))
        .add(_calculateTip(purchase))
        // TODO: When 1P Delivery Fee is rolled into fees array, remove this function
        .add(_calculateDeliveryFee(purchase))
        .add(DD(purchase.upChargeAmount || 0))
        .add(DD(purchase.convenienceFee || 0))
        .add(_calculateFeeTotal(purchase));

const _calculateTotalMinusGCCredit = (
    purchase: Pick<IPurchase, IPurchaseCommonFields | "giftCardsCreditUsed">
): Dinero.Dinero =>
    _calculateTotal(purchase).subtract(DD(purchase.giftCardsCreditUsed || 0));

const _calculateTotalMinusDiscounts = (
    purchase: Pick<IPurchase, IPurchaseCommonFields>
): Dinero.Dinero =>
    _calculateTotal(purchase).subtract(
        DD(_calculateAmountDiscounted(purchase))
    );

const _calculateTotalMinusSubtractions = (
    purchase: Pick<
        IPurchase,
        | IPurchaseCommonFields
        | "giftCardsCreditUsed"
        | "amountDiscounted"
        | "storeCreditUsed"
        | "globalCreditUsed"
    >
): Dinero.Dinero =>
    _calculateTotal(purchase)
        .subtract(DD(_calculateAmountDiscounted(purchase)))
        .subtract(DD(purchase.giftCardsCreditUsed || 0))
        .subtract(DD(purchase.storeCreditUsed || 0))
        .subtract(DD(purchase.globalCreditUsed || 0));

const _calculateTotalAfterRefund = (
    purchase: Pick<IPurchase, IPurchaseCommonFields | "refundedAmount">
): Dinero.Dinero =>
    _calculateTotal(purchase).subtract(DD(purchase.refundedAmount || 0));

export const getLineItems = (
    purchase: Pick<
        IPurchase,
        | "items"
        | "deliveryFee"
        | "deliveryInfo"
        | "fulfillment"
        | "tip"
        | "amountDiscounted"
        | "upChargeAmount"
        | "convenienceFee"
        | "globalCreditUsed"
        | "storeCreditUsed"
        | "giftCardsCreditUsed"
        | "fees"
        | "cartAdjustments"
        | "taxableSnackpassContribution"
        | "customFees"
        | "salesTaxRate"
        | "refundedAmount"
    >,
    store?: Pick<IStore, "customFeeLabels"> | null
): {
    subtotal: number;
    subtotalPlusDiscount: number;
    salesTaxAmount: number;
    tip: number;
    thirdPartyDeliveryTip: number;
    deliveryFee: number;
    upChargeAmount: number;
    amountDiscounted: number;
    convenienceFee: number;
    fees: FormattedFee[];
    taxesAndFees: number;
    globalCreditUsed: number;
    storeCreditUsed: number;
    giftCardsCreditUsed: number;
    total: number;
    totalMinusDiscounts: number;
    totalMinusGCCredit: number;
    totalMinusSubtractions: number;
    totalAfterRefund: number;
    refundedAmount: number;
    cartAdjustments?: CartAdjustment[];
} => ({
    subtotal: _calculateSubtotal(purchase).toUnit(),
    subtotalPlusDiscount: _calculateSubtotal(purchase, false).toUnit(),
    salesTaxAmount: _calculateSalesTaxAmount(purchase).toUnit(),
    tip: _calculateTip(purchase).toUnit(),
    thirdPartyDeliveryTip: _calculate3PDeliveryTip(purchase).toUnit(),
    deliveryFee: _calculateDeliveryFee(purchase).toUnit(),
    taxesAndFees: _calculateTaxesAndFees(purchase, store).toUnit(),
    upChargeAmount: purchase.upChargeAmount || 0,
    amountDiscounted: _calculateAmountDiscounted(purchase),
    convenienceFee: purchase.convenienceFee || 0,
    fees: _formatFees(purchase, store),
    globalCreditUsed: purchase.globalCreditUsed || 0,
    storeCreditUsed: purchase.storeCreditUsed || 0,
    giftCardsCreditUsed: purchase.giftCardsCreditUsed || 0,
    total: _calculateTotal(purchase).toUnit(),
    totalMinusDiscounts: _calculateTotalMinusDiscounts(purchase).toUnit(),
    totalMinusGCCredit: _calculateTotalMinusGCCredit(purchase).toUnit(),
    totalMinusSubtractions: _calculateTotalMinusSubtractions(purchase).toUnit(),
    totalAfterRefund: _calculateTotalAfterRefund(purchase).toUnit(),
    refundedAmount: purchase.refundedAmount || 0,
    cartAdjustments: purchase.cartAdjustments
});

export const buildCommonSummaryLineItems = (
    purchase: Pick<
        IPurchase,
        | "items"
        | "deliveryFee"
        | "deliveryInfo"
        | "fulfillment"
        | "tip"
        | "amountDiscounted"
        | "upChargeAmount"
        | "convenienceFee"
        | "globalCreditUsed"
        | "storeCreditUsed"
        | "giftCardsCreditUsed"
        | "fees"
        | "cartAdjustments"
        | "taxableSnackpassContribution"
        | "customFees"
        | "salesTaxRate"
        | "refundedAmount"
        | "refund"
        | "numberOfBags"
        | "thirdPartyOrderInfo"
        | "paymentProviderId"
    >,
    store?: Pick<IStore, "customFeeLabels"> | null
) => {
    const {
        subtotalPlusDiscount: subtotal,
        amountDiscounted,
        giftCardsCreditUsed,
        storeCreditUsed,
        globalCreditUsed,
        tip,
        thirdPartyDeliveryTip,
        refundedAmount,
        upChargeAmount,
        convenienceFee,
        deliveryFee,
        fees,
        salesTaxAmount,
        totalMinusSubtractions,
        cartAdjustments,
        totalAfterRefund
    } = getLineItems(purchase, store);

    let receiptLineItems: ReceiptLineItem[] = [];
    if (cartAdjustments) {
        const surcharges = cartAdjustments.filter(
            (adj) => adj.type === CartAdjustmentType.SurchargeFlat
        );
        receiptLineItems = receiptLineItems.concat(
            surcharges.map((surcharge) => {
                return {
                    label: "Charge",
                    value: surcharge.flat || 0
                };
            })
        );
    }

    receiptLineItems.push({
        label: LineItemLabels.Subtotal,
        value: subtotal
    });

    if (cartAdjustments) {
        // Logic here taken from calcCustomDiscountsTotal, the discount amount is based on the
        // item subtotal (which is after item discounts) + surcharges.
        // All cart adjustments SHOULD have a stored flat amount, even if its a percentage discount, but we can correctly compute a percentage fallback just in case.
        const itemAndSurchargeSubtotal =
            calcCartItemSubtotal(purchase.items) +
            calcCustomSurchargeTotal(cartAdjustments);
        const percentDiscounts = cartAdjustments.filter(
            (adj) => adj.type === CartAdjustmentType.DiscountPercent
        );
        for (const percentDiscount of percentDiscounts) {
            const computedDiscount =
                percentDiscount.flat ??
                itemAndSurchargeSubtotal *
                    (1 - (percentDiscount.percent ?? 0) / 100);
            receiptLineItems.push({
                label: `Percent Discount (${percentDiscount.percent ?? 0}%)`,
                value: computedDiscount,
                isDiscount: true,
                textProps: {
                    color: "#00CC22"
                }
            });
        }
        const flatDiscounts = cartAdjustments.filter(
            (adj) => adj.type === CartAdjustmentType.DiscountFlat
        );
        for (const flatDiscount of flatDiscounts) {
            receiptLineItems.push({
                label: "Flat Discount",
                value: flatDiscount.flat || 0,
                isDiscount: true,
                textProps: {
                    color: "#00CC22"
                }
            });
        }
    }

    if (amountDiscounted) {
        purchase.items.forEach((item) => {
            const amountSaved = item.totalPrice - item.totalPriceAfterDiscount;
            if (amountSaved > 0) {
                const possibleSavingsNames = filterNulls([
                    item?.promotion?.name,
                    item?.reward?.name
                ]);
                receiptLineItems.push({
                    // Having both a reward and promotion is rare but possible (e.g. _id: 64386255de146f5364864ea8).
                    // However, currently there are no items that were discounting by both a reward and promotion
                    // at the same time. Rather than trying to reverse engineer how much savings was applied
                    // by the promotion vs the reward, we'll show both together.
                    label: possibleSavingsNames.join(", "),
                    value: amountSaved,
                    isDiscount: true,
                    textProps: {
                        color: "#00CC22"
                    }
                });
            }
        });
    } else if (purchase.refund) {
        const discounts = _calculateAmountDiscounted(purchase);
        if (discounts !== 0) {
            receiptLineItems.push({
                label: LineItemLabels.Discount,
                value: discounts,
                isDiscount: true,
                textProps: {
                    color: "#00CC22"
                }
            });
        }
    }

    receiptLineItems.push({ label: LineItemLabels.Tax, value: salesTaxAmount });

    if (convenienceFee) {
        receiptLineItems.push({
            label: labelForFee(ReservedFeeEnum.ConvenienceStoreFee, store),
            value: convenienceFee,
            description: descriptionForFee(
                {
                    fee: {
                        name: ReservedFeeEnum.ConvenienceStoreFee,
                        payer: FeePolicyPayer.Customer,
                        recipient: FeePolicyRecipient.Store,
                        flat: convenienceFee,
                        percent: 0,
                        rules: {}
                    },
                    total: convenienceFee
                },
                purchase.numberOfBags ?? 0,
                store
            )
        });
    }

    if (fees.length) {
        receiptLineItems = receiptLineItems.concat(
            fees.map((fee) => {
                const description = fee.description;
                // We want to always show bag fee even if number of bags are 0 if bags are enabled
                if (fee.label === LineItemLabels.BagFee) {
                    return {
                        label:
                            fee.label + ` (${purchase.numberOfBags || "0"}x)`,
                        value: fee.amount,
                        description
                    };
                }
                return { label: fee.label, value: fee.amount, description };
            })
        );
    }

    if (deliveryFee) {
        receiptLineItems.push({
            label: LineItemLabels.DeliveryFee,
            value: deliveryFee
        });
    }

    // Third party purchases
    if (purchase.thirdPartyOrderInfo) {
        // This is just to set what was done in OH, although it does not seems to be
        // fully accurate (amountDiscounted === Channel Discount).
        // However, according to product it seems it is not being used atm
        // so it should be okay to keep it like that for now
        if (amountDiscounted) {
            receiptLineItems.push({
                value: amountDiscounted,
                isDiscount: true,
                label: LineItemLabels.ChannelDiscount,
                textProps: {
                    color: "#00CC22"
                }
            });
        }
        if (purchase.thirdPartyOrderInfo.deliverect?.serviceCharge) {
            receiptLineItems.push({
                label: LineItemLabels.ServiceFees3P,
                value: purchase.thirdPartyOrderInfo.deliverect.serviceCharge
            });
        }
    }

    receiptLineItems.push({ label: LineItemLabels.Tip, value: tip });

    if (upChargeAmount) {
        receiptLineItems.push({
            label: LineItemLabels.Upcharge,
            value: upChargeAmount
        });
    }

    const creditSources = [
        { label: LineItemLabels.GiftCardCredit, value: giftCardsCreditUsed },
        { label: LineItemLabels.StoreCredit, value: storeCreditUsed },
        { label: LineItemLabels.GlobalCredit, value: globalCreditUsed }
    ];
    creditSources.forEach((creditSource) => {
        if (creditSource.value !== 0) {
            receiptLineItems.push({
                label: creditSource.label,
                value: creditSource.value,
                isDiscount: true,
                textProps: {
                    color: "#00CC22"
                }
            });
        }
    });

    receiptLineItems.push({
        label:
            purchase.paymentProviderId === PaymentProvider.unpaid
                ? LineItemLabels.EstimatedTotal
                : LineItemLabels.Total,
        value: totalMinusSubtractions,
        textProps: { bold: true, color: "black" }
    });

    if (purchase.refund) {
        receiptLineItems.push({
            label: LineItemLabels.Refunded,
            isDiscount: true,
            isRefund: true,
            value: refundedAmount,
            textProps: { bold: true, color: "#FF3929" }
        });

        if (refundedAmount !== 0) {
            receiptLineItems.push({
                label: LineItemLabels.AdjustedTotal,
                value: totalAfterRefund,
                textProps: { bold: true, color: "black" }
            });
        }
    }

    if (thirdPartyDeliveryTip) {
        receiptLineItems.push({
            label: LineItemLabels.ThirdPartyDeliveryTip,
            value: thirdPartyDeliveryTip
        });
    }

    return receiptLineItems;
};
