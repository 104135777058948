import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import config from "@/config";

type Props = {
    children?: React.ReactNode;
};

const FIFTEEN_MINUTES = 15 * 60 * 1000;

const fetchVersion = async () => {
    // prevents version.txt from getting cached.
    const minutesSinceEpoch = Math.round(Date.now() / 1000 / 60);
    return axios.get(`/version.txt?${minutesSinceEpoch}`, {
        baseURL: window.location.origin,
    });
};

const CacheBusting = ({ children }: Props) => {
    const [currentVersion, setCurrentVersion] = useState<string>("");

    const { data: version } = useQuery({
        queryKey: ["cache-busting"],
        queryFn: fetchVersion,
        refetchInterval: FIFTEEN_MINUTES,
        enabled: config.IS_LOCAL === false, // disable useQuery during local development
    });

    useEffect(() => {
        if (version && version.data) {
            if (currentVersion === "") {
                setCurrentVersion(version.data);
            } else if (currentVersion !== version.data) {
                setCurrentVersion(version.data);
                // Do not reload on any cart screen, because they could be mid checkout.
                if (window.location.pathname.includes("/cart") === false) {
                    window.location.reload();
                }
            }
        }
    }, [version, currentVersion]);

    return <>{children}</>;
};

export default CacheBusting;
