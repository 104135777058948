import React from "react";
import PhoneVerification from "@/components/PhoneVerification";
import { useSelector } from "react-redux";
import { getActiveStore } from "@/redux";
import { optimizedImageURL } from "@/utils/Helpers";

type LoginProps = {
    onSuccess: any;
    setModalTitle: React.Dispatch<React.SetStateAction<string>>;
};

const LoginByPhone = ({ onSuccess, setModalTitle }: LoginProps) => {
    const activeStore = useSelector(getActiveStore);
    const logo = activeStore?.logoUrl || activeStore?.thumbnailUrl || "";
    const source = optimizedImageURL(logo, { size: { h: 80, w: 80 } });
    return (
        <div className="p-6">
            {activeStore?.name || logo ? (
                <div className="flex flex-col items-center justify-center pb-6">
                    <div className="flex">
                        <div
                            className="bg-center bg-no-repeat bg-cover rounded-full w-[48px] h-[48px] lg:w-[80px] lg:h-[80px]"
                            style={{
                                backgroundImage: `url(${source})`,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            <PhoneVerification
                handleHide={onSuccess}
                authModal
                setModalTitle={setModalTitle}
            />
        </div>
    );
};

export default LoginByPhone;
