import React, { useCallback, useEffect, useMemo } from "react";
import DigitalGiftCardPrice from "./DigitalGiftCardPrice";
import DigitalGiftCardFormHeader from "./DigitalGiftCardFormHeader";
import { Divider } from "@/SharedComponents";
import {
    IProduct,
    IStore,
    MAX_GIFTCARD_AMOUNT_CENTS,
    MIN_GIFTCARD_AMOUNT_CENTS,
} from "@snackpass/snackpass-types";
import { Input, standardInputClassNames } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import * as z from "zod";
import { getFirstName, validatePhoneNumber } from "@/utils/Helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "@/components/ui/form";
import NumberFormat from "react-number-format";
import { DigitalGiftCardDesigns } from "./DigitalGiftCardDesigns";
import { ActiveCartItemActions } from "@snackpass/accounting";
import { useDispatch, useSelector } from "react-redux";
import { getDigitalGiftCardCartInfo } from "@snackpass/accounting/build/src/redux/activeCartItem/selectors";
import { randomAlphaNumericString } from "./randomAlphaNumericString";
import { filterNulls } from "@/utils/arrays/filterNulls";
import { DialogFooter } from "@/components/ui/dialog";
import AddToCartButton from "../AddToCartButton";
import { getActivePromotion, getIsEditCartItem } from "@/redux";
import { useApplyBestGCPromo } from "@/hooks/useApplyBestGCPromo";
import { useMediaQuery } from "react-responsive";
import { SheetFooter } from "@/components/ui/sheet";

const MAX_MESSAGE_LENGTH = 300;

const formSchema = z.object({
    designURL: z.string().optional(),
    price: z
        .number()
        .min(
            MIN_GIFTCARD_AMOUNT_CENTS / 100,
            "Gift card value must be at least $5",
        )
        .max(
            MAX_GIFTCARD_AMOUNT_CENTS / 100,
            "Gift card value cannot exceed $500",
        ),
    toName: z.string().min(1, "Please enter a valid name."),
    toPhone: z
        .string()
        .refine(
            (s) => validatePhoneNumber(s),
            "Please enter a valid phone number.",
        ),
    note: z.string().max(MAX_MESSAGE_LENGTH).optional(),
});

type Props = {
    activeProduct: IProduct;
    activeStore: IStore;
    handleAddItemToCart: () => void;
};

function DigitalGiftCard({
    activeProduct,
    activeStore,
    handleAddItemToCart,
}: Props) {
    const dispatch = useDispatch();
    const validateAndApplyBestPromo = useApplyBestGCPromo();
    const setActiveCartItemNotes = useCallback(
        (notes: string) => {
            dispatch(ActiveCartItemActions.setActiveCartItemNotes(notes));
        },
        [dispatch],
    );
    const activeDigitalGiftCardCartInfo = useSelector(
        getDigitalGiftCardCartInfo,
    );
    const activePromotion = useSelector(getActivePromotion);
    const code =
        activeDigitalGiftCardCartInfo?.code ??
        randomAlphaNumericString(16, "A", "#");
    const getNewNotes = useCallback((toName, toPhone) => {
        if (toName && toPhone) {
            return `To redeem gift card, recipient ${getFirstName(toName)} must enter their phone number ${toPhone} when checking out.`;
        }
        return "";
    }, []);
    const designURLs = filterNulls([
        activeStore?.thumbnailUrl,
        "https://res.cloudinary.com/ddhetmqtt/image/upload/v1706039770/Loyalty_Card_7_xifooa.png",
        "https://res.cloudinary.com/ddhetmqtt/image/upload/v1706039776/Loyalty_Card_9_kbdjox.png",
        "https://res.cloudinary.com/ddhetmqtt/image/upload/v1705454913/Loyalty_Card_2_hi3raj.png",
        "https://res.cloudinary.com/ddhetmqtt/image/upload/v1705454865/Loyalty_Card_1_lrtpcs.png",
        "https://res.cloudinary.com/ddhetmqtt/image/upload/v1705454763/Loyalty_Card_lwfdtm.png",
    ]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        mode: "onTouched", // mode == before a user submits the form. onTouched == after first blur + on every subsequent change
        defaultValues: {
            price: activeProduct.price,
            designURL: designURLs[0],
            toName: "",
            toPhone: "",
            ...activeDigitalGiftCardCartInfo,
        },
    });
    const formWatch = useWatch({ control: form.control });
    useEffect(() => {
        dispatch(
            ActiveCartItemActions.setActiveCartItem(
                activeProduct,
                activePromotion ?? undefined,
                undefined,
                undefined,
                undefined,
                {
                    toName: formWatch.toName ?? "",
                    toPhone: formWatch.toPhone ?? "",
                    note: formWatch.note,
                    designURL: formWatch.designURL,
                    code,
                },
            ),
        );
        // any active notes are reset on setting the cart item. So make sure we set them again.
        setActiveCartItemNotes(
            getNewNotes(formWatch.toName, formWatch.toPhone),
        );
    }, [
        activeProduct,
        activePromotion,
        code,
        dispatch,
        formWatch,
        getNewNotes,
        setActiveCartItemNotes,
    ]);

    useEffect(() => {
        if (formWatch.price) {
            validateAndApplyBestPromo();
        }
    }, [
        activeProduct,
        code,
        dispatch,
        formWatch,
        getNewNotes,
        setActiveCartItemNotes,
        validateAndApplyBestPromo,
    ]);
    const updatePrice = useCallback(
        (newPrice: number) => {
            // Update the true price so that "Add to Order" will reflect the new price.
            dispatch(
                ActiveCartItemActions.setActiveCartItem(
                    {
                        ...activeProduct,
                        price: newPrice,
                    },
                    activePromotion ?? undefined,
                    undefined,
                    undefined,
                    undefined,
                    {
                        toName: formWatch.toName ?? "",
                        toPhone: formWatch.toPhone ?? "",
                        note: formWatch.note,
                        designURL: formWatch.designURL,
                        code,
                    },
                ),
            );
            // any active notes are reset on setting the cart item. So make sure we set them again.
            setActiveCartItemNotes(
                getNewNotes(formWatch.toName, formWatch.toPhone),
            );
        },
        [
            dispatch,
            activeProduct,
            activePromotion,
            formWatch,
            code,
            setActiveCartItemNotes,
            getNewNotes,
        ],
    );
    const editCartItem = useSelector(getIsEditCartItem);
    const isDesktop = useMediaQuery({ minWidth: 768 });

    return (
        <>
            <FormProvider {...form}>
                <form
                    // This form should never be submitted, since the submit button is outside the form.
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            (document.activeElement as HTMLElement)?.blur();
                            e.preventDefault();
                        }
                    }}
                >
                    <div className="px-6">
                        <DigitalGiftCardFormHeader label={"Choose a design"} />
                        <FormField
                            control={form.control}
                            name="designURL"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <DigitalGiftCardDesigns
                                            value={field.value}
                                            onChange={field.onChange}
                                            designURLs={designURLs}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Divider className="pt-6" width={2} />
                    <div className="px-6">
                        <FormField
                            control={form.control}
                            name="price"
                            render={() => (
                                <FormItem>
                                    <FormControl>
                                        <DigitalGiftCardPrice
                                            activeProduct={activeProduct}
                                            updatePrice={updatePrice}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Divider className="pt-6" width={2} />
                    <div className="px-6">
                        <DigitalGiftCardFormHeader label={"Who is it for?"} />
                        <div className="flex flex-col gap-3">
                            <FormField
                                control={form.control}
                                name="toName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                type="text"
                                                placeholder="Recipient Name"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="toPhone"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <NumberFormat
                                                type="tel"
                                                format="(###) ###-####"
                                                mask=" "
                                                placeholder="Recipient Phone"
                                                className={
                                                    standardInputClassNames
                                                }
                                                getInputRef={field.ref}
                                                {...{
                                                    name: field.name,
                                                    value: field.value,
                                                    onChange: field.onChange,
                                                    onBlur: field.onBlur,
                                                }}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <Divider className="pt-6" width={2} />
                    <div className="px-6 pb-6">
                        <DigitalGiftCardFormHeader
                            label={"Add a personalized note?"}
                        />
                        <div className="flex flex-col gap-2">
                            <FormField
                                control={form.control}
                                name="note"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Textarea
                                                placeholder="Your message"
                                                maxLength={MAX_MESSAGE_LENGTH}
                                                {...field}
                                            />
                                        </FormControl>
                                        <div>
                                            {field.value?.length ?? 0} /{" "}
                                            {MAX_MESSAGE_LENGTH}
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                </form>
            </FormProvider>
            {isDesktop ? (
                <DialogFooter className="w-[100%] border-t bg-white p-3 md:px-6 mt-auto">
                    <div className="flex w-full flex-row">
                        <div className="flex w-full flex-col gap-3">
                            <div className="self-center">
                                Gift card will be texted to recipient
                                immediately after checkout.
                            </div>
                            <AddToCartButton
                                activeProduct={activeProduct}
                                quantity={1}
                                weight={0}
                                selectedAddons={[]}
                                handleAddItemToCart={form.handleSubmit(
                                    handleAddItemToCart,
                                )} // Outside of the form for styling, so this will manually submit the form.
                                editCartItem={editCartItem}
                            />
                        </div>
                    </div>
                </DialogFooter>
            ) : (
                <SheetFooter className="px-6 border-t pt-3 pb-2">
                    <div className="flex w-full flex-row">
                        <div className="flex w-full flex-col gap-3">
                            <div className="self-center">
                                Gift card will be texted to recipient
                                immediately after checkout.
                            </div>
                            <AddToCartButton
                                activeProduct={activeProduct}
                                quantity={1}
                                weight={0}
                                selectedAddons={[]}
                                handleAddItemToCart={form.handleSubmit(
                                    handleAddItemToCart,
                                )} // Outside of the form for styling, so this will manually submit the form.
                                editCartItem={editCartItem}
                            />
                        </div>
                    </div>
                </SheetFooter>
            )}
        </>
    );
}

export default DigitalGiftCard;
