import { SystemColors } from "@snackpass/design-system";
import {
    useElements,
    useStripe,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import fp from "lodash/fp";
import React, { useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStore, setUser } from "src/redux";
import uuid from "uuid";
import { isNetworkError } from "src/utils/Api/REST";
import { Button, Text, View } from "../../SharedComponents";
import { Api, Colors } from "../../utils";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ModalHeader } from "src/modals/GIftCardPhoneAuthenticationModal/Header";
import API from "src/utils/Api/graphql";
import { centsToDollars, validateZIPCode } from "src/utils/Helpers";
import { GiftCardActions } from "@snackpass/accounting";
import { Col, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { COL_FULL_SIZE } from "src/screens/GiftCardScreen/lib";
import { sendError } from "src/utils/Errors";
import { toast } from "sonner";

export interface Props {
    onSuccess: () => void;
    closeFunc: () => void;
    cardInputName?: string | null;
    buttonText?: string;
    isSubmitting?: boolean;
    settingGiftcard: boolean;
}

const MODAL_TITLE = "New Card";
const CREDIT_CARD = "Credit/Debit Card";
const GIFT_CARD = "Gift Card";
const SECURE_PAYMENT = "SECURE PAYMENT";
const SAVE = "Save";
const COL_STRIPE_SIZE = 4;
const ZIP_TOKEN_ERROR = "Your ZIP code is incomplete";

type StripeCardElementsType = {
    cardNumber: boolean;
    cardCvc: boolean;
    cardExpiry: boolean;
    cardZIP: string;
};

export const GiftCreditCardInput = ({
    onSuccess,
    settingGiftcard,
    closeFunc,
}: Props) => {
    const dispatch = useDispatch();
    const _setUser = fp.compose(dispatch, setUser);
    const [stripeError, setStripeError] = useState<string | null>(null);
    const [giftCardError, setGiftCardError] = useState<string | null>(null);
    const [idempotencyKey, setIdempotencyKey] = useState<string>(uuid());
    const [isLoading, setLoading] = useState(false);
    const [giftCardNumber, setGiftCardNumber] = useState<string>("");
    const [stripeCardElements, setStripeCardElements] =
        useState<StripeCardElementsType>({
            cardNumber: true,
            cardCvc: true,
            cardExpiry: true,
            cardZIP: "",
        });
    const elements = useElements();
    const stripe = useStripe();
    const activeStore = useSelector(getActiveStore);

    const refreshIdempotencyKey = () => setIdempotencyKey(uuid());

    const submitStripe = async () => {
        if (!stripe || !elements) {
            return;
        }
        try {
            setLoading(true);
            if (!validateZIPCode(stripeCardElements.cardZIP)) {
                setStripeError(ZIP_TOKEN_ERROR);
            } else {
                const cardNumberElement =
                    elements.getElement(CardNumberElement);
                const result = await stripe.createToken(cardNumberElement!, {
                    address_zip: stripeCardElements.cardZIP,
                });

                if (!result || result?.error) {
                    setStripeError(
                        result?.error?.message ||
                            "Sorry, could not add card 😢. Check that you typed in the number correctly and try again!"
                    );
                    return false;
                }

                if (result?.token) {
                    const response = await Api.me.cards.create(
                        result.token.id,
                        result.token.card?.last4!,
                        idempotencyKey
                    );
                    _setUser(response.data.user);
                    setStripeError(null);
                    return true;
                }
            }
        } catch (err) {
            sendError(err);

            if (isNetworkError(err)) {
                setStripeError(
                    "Couldn't add card. Please check your network connection."
                );
                onShowError(
                    "Couldn't add card. Please check your network connection."
                );
            } else {
                setStripeError("Couldn't add card. Please try again.");
                onShowError("Couldn't add card. Please try again.");
                refreshIdempotencyKey();
            }

            return false;
        } finally {
            setLoading(false);
        }
    };

    const onShowError = (error) => {
        toast(error);
    };

    const submitGiftCard = async () => {
        try {
            setLoading(true);
            const redeemGiftCard = await API.giftCards.redeem(
                giftCardNumber.replace(/\s+/g, "")
            );
            dispatch({
                type: GiftCardActions.ADD_REDEEM_GIFTCARD,
                giftCard: redeemGiftCard,
            });
            return redeemGiftCard;
        } catch (err) {
            setLoading(false);
            if (isNetworkError(err)) {
                onShowError(
                    "Couldn't add card. Please check your network connection."
                );
            } else {
                onShowError(err.message);
                refreshIdempotencyKey();
            }

            return false;
        } finally {
            setLoading(false);
        }
    };

    const validateGiftCardData = () => {
        if (!validateGitfCardNumber(giftCardNumber))
            setGiftCardError(
                "Please complete all gift card number characters. Only use letters and numbers"
            );
        else setGiftCardError("");
        return validateGitfCardNumber(giftCardNumber);
    };

    const validateGitfCardNumber = (p: string) => {
        const regex = /^[A-Z0-9]{15}/;
        return regex.test(p);
    };

    const checkStripeCardElements = () => {
        return (
            stripeCardElements.cardCvc &&
            stripeCardElements.cardNumber &&
            stripeCardElements.cardExpiry &&
            !stripeCardElements.cardZIP
        );
    };

    const handleSubmit = async (e) => {
        // prevent parent form submit from being called
        e.preventDefault();
        // If the user wants to redeem a giftcard and add a credit card
        if (
            giftCardNumber.length &&
            validateGiftCardData() &&
            !checkStripeCardElements()
        ) {
            const redeemGiftCard = await submitGiftCard();
            if (redeemGiftCard) {
                const subStripe = await submitStripe();
                if (subStripe) {
                    toast.success("Gift Card Redeemed and Credit Card added", {
                        description: `$${centsToDollars(
                            redeemGiftCard.remainingCreditCents
                        )} added at ${
                            activeStore?.name
                        } and credit card added to your account`,
                    });
                    onSuccess();
                }
            }
        }

        // If the user only wants to redeem a gift card
        else if (
            giftCardNumber.length &&
            validateGiftCardData() &&
            checkStripeCardElements()
        ) {
            const redeemGiftCard = await submitGiftCard();
            if (redeemGiftCard) {
                toast.success("Gift Card Redeemed", {
                    description: `$${centsToDollars(
                        redeemGiftCard.remainingCreditCents
                    )} added at ${activeStore?.name}`,
                });
                onSuccess();
            }
        }

        // If the user only wants to add a credit card
        else if (!giftCardNumber.length && !checkStripeCardElements()) {
            const subStripe = await submitStripe();
            if (subStripe) {
                toast.success("Credit card added", {
                    description: "Credit card added to your account",
                });
                onSuccess();
            }
        }
    };

    const handleChangeCreditCardNumber = (e) => {
        setStripeCardElements({ ...stripeCardElements, cardNumber: e.empty });
    };

    const handleChangeCreditCardExp = (e) => {
        setStripeCardElements({ ...stripeCardElements, cardExpiry: e.empty });
    };

    const handleChangeCreditCardCVC = (e) => {
        setStripeCardElements({ ...stripeCardElements, cardCvc: e.empty });
    };

    const handleChangeCreditCardZIP = (e) => {
        setStripeCardElements({
            ...stripeCardElements,
            cardZIP: e.target.value,
        });
    };

    const onChangeGiftCardInput = (e) => {
        setGiftCardNumber(
            e.target.value.toLocaleUpperCase().replace(/[^\w\s]/gi, "")
        );
    };

    return (
        <View style={localStyles.container}>
            <ModalHeader
                title={MODAL_TITLE}
                closeFunc={closeFunc}
                icon="arrow"
            />
            <View style={localStyles.innerContainer}>
                <Text style={localStyles.title}>{CREDIT_CARD}</Text>
                <Text style={localStyles.subTitle}>{SECURE_PAYMENT}</Text>
                <Row className="justify-content-lg-center row-centered">
                    <Col
                        lg={COL_FULL_SIZE}
                        xs={COL_FULL_SIZE}
                        md={COL_FULL_SIZE}
                        className="column-centered"
                    >
                        <CardNumberElement
                            onChange={handleChangeCreditCardNumber}
                            options={{
                                ...cardElementOptions,
                                placeholder: "Card Number",
                                classes: { base: "stripe-base-class-big" },
                            }}
                        />
                    </Col>
                    <Col
                        lg={COL_STRIPE_SIZE}
                        xs={COL_STRIPE_SIZE}
                        md={COL_STRIPE_SIZE}
                        className="column-centered-credit-card-field"
                    >
                        <CardExpiryElement
                            onChange={handleChangeCreditCardExp}
                            options={{
                                ...cardElementOptions,
                                classes: {
                                    base: "stripe-base-class-external-big",
                                },
                            }}
                        />
                    </Col>
                    <Col
                        lg={COL_STRIPE_SIZE}
                        xs={COL_STRIPE_SIZE}
                        md={COL_STRIPE_SIZE}
                        className="column-centered-credit-card-field"
                    >
                        <CardCvcElement
                            onChange={handleChangeCreditCardCVC}
                            options={{
                                ...cardElementOptions,
                                classes: {
                                    base: "stripe-base-class-external-big",
                                },
                            }}
                        />
                    </Col>
                    <Col
                        lg={COL_STRIPE_SIZE}
                        xs={COL_STRIPE_SIZE}
                        md={COL_STRIPE_SIZE}
                        className="column-centered-credit-card-field"
                    >
                        <NumberFormat
                            name="ZipCode"
                            type="tel"
                            className="stripe-base-class-external-big"
                            format="#####"
                            mask=" "
                            style={localStyles.zipCode}
                            placeholder="ZIP Code"
                            onChange={handleChangeCreditCardZIP}
                            value={stripeCardElements.cardZIP}
                        />
                    </Col>
                </Row>

                {stripeError && (
                    <div style={{ padding: "15px 0" }}>
                        <Text bold color={SystemColors.melon60}>
                            {stripeError}
                        </Text>
                    </div>
                )}

                {settingGiftcard && (
                    <>
                        <Text style={localStyles.title}>{GIFT_CARD}</Text>
                        <Row className="justify-content-lg-center row-centered">
                            <Col
                                lg={COL_FULL_SIZE}
                                xs={COL_FULL_SIZE}
                                md={COL_FULL_SIZE}
                                className="column-centered"
                            >
                                <input
                                    type="text"
                                    className="qrInput"
                                    style={localStyles.inputStyles}
                                    value={giftCardNumber}
                                    placeholder="Gift Card Code"
                                    maxLength={16}
                                    required
                                    onChange={onChangeGiftCardInput}
                                />
                            </Col>
                        </Row>
                        {giftCardError && (
                            <div style={{ padding: "15px 0" }}>
                                <Text bold color={SystemColors.melon60}>
                                    {giftCardError}
                                </Text>
                            </div>
                        )}
                    </>
                )}

                <Button
                    onPress={handleSubmit}
                    loading={isLoading}
                    label={SAVE}
                    style={{
                        width: "100%",
                        marginTop: 20,
                    }}
                    backgroundColor={Colors.lightBlue}
                    disabled={!stripe}
                />
            </View>
        </View>
    );
};

const cardElementOptions = {
    hidePostalCode: false,
    style: {
        base: {
            fontSize: "16px",
            color: "#424770",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: SystemColors.melon60,
        },
    },
};

const localStyles = {
    container: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        borderRadius: 10,
        width: "100%",
        padding: "2%",
        height: "100%",
    } as CSSProperties,
    innerContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        borderRadius: 10,
        width: "100%",
        padding: "2%",
        margin: "2%",
        height: "100%",
    } as CSSProperties,

    title: {
        width: "100%",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 18,
        textAlign: "start",
        color: Colors.darkerGrey,
        padding: "2%",
    } as CSSProperties,

    subTitle: {
        width: "100%",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "100%",
        textAlign: "start",
        color: "#606C76",
        padding: "2%",
    } as CSSProperties,
    inputStyles: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        color: "#606C76",
        padding: "2%",
        borderRadius: 8,
        border: "1px solid #DDE5EE",
        width: "100%",
        marginBottom: "2%",
        backgroundColor: "white",
        textAlign: "center",
    } as CSSProperties,
    zipCode: {
        fontSize: 16,
    } as CSSProperties,
};
