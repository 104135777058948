import React from "react";
import { IProduct } from "@snackpass/snackpass-types";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";
import { CartAddon, getIsGiftCardProduct } from "@snackpass/accounting";
import {
    useActiveRewardPromo,
    useActiveReward,
} from "@snackpass/loyalty-react";

import { Helpers } from "../../../utils";
import { InProductBadges } from "./InProductBadges";
import { optimizedImageURL } from "@/utils/Helpers";
import { useSelector } from "react-redux";
import { getDealSelection } from "@/redux/deal";
import { getActivePromotion } from "@/redux";
import { useGetProductPrice } from "@/hooks/useGetProductPrice";

type Props = {
    showDescription: boolean;
    product: IProduct;
    handlePressProduct: (
        product: IProduct,
        selectedAddons: CartAddon[] | null | undefined,
    ) => void;
    selectedAddons: CartAddon[] | null | undefined;
    isPromoOrReward?: boolean;
};

export default function ProductCard({
    showDescription,
    product,
    handlePressProduct,
    selectedAddons,
    isPromoOrReward,
}: Props) {
    const activeRewardPromo = useActiveRewardPromo();
    const activeReward = useActiveReward();
    const activePromotion = useSelector(getActivePromotion);
    const activeDealItem = useSelector(getDealSelection).activeDealItem;
    const { basePriceAfterDiscount } = useGetProductPrice(
        product,
        activeReward,
        activeRewardPromo || activePromotion,
        activeDealItem,
    );
    const price = product.priceByWeight
        ? product.priceByWeight.perUnit
        : product.price;

    const unitText = product.priceByWeight
        ? " /" + product.priceByWeight.unit
        : "";

    const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

    const dimension = isSmallScreen ? 96 : 158;

    const isGiftCard = getIsGiftCardProduct(product);

    return (
        <div
            onClick={() => {
                handlePressProduct(product, selectedAddons);
            }}
            className="min-h-[50px] relative flex h-fit min-w-[355px] cursor-pointer flex-row justify-between overflow-hidden border-b bg-white lg:mb-0 lg:h-[158px] lg:rounded-xl lg:border"
        >
            <div className="mr-1.5 flex flex-row whitespace-pre-line">
                <div className="flex flex-col">
                    <p className="mb-1 line-clamp-2 text-ellipsis text-base font-semibold text-primary lg:mx-4 lg:mt-4 lg:font-semibold">
                        {product.name}
                    </p>

                    {showDescription && !_.isEmpty(product.description) && (
                        <div className="line-clamp-2 overflow-hidden text-ellipsis break-words text-sm font-normal text-primary lg:mx-4">
                            {product.description}
                        </div>
                    )}

                    {isGiftCard ? null : (
                        <div className="mt-auto flex items-center py-4 lg:ml-4">
                            <div className="flex flex-row items-start gap-3">
                                {isPromoOrReward ? (
                                    <div className="flex flex-row gap-1">
                                        <div className="my-auto inline-block text-sm font-normal leading-5 text-accent">
                                            {Helpers.toDollar(
                                                basePriceAfterDiscount,
                                            ) + unitText}
                                        </div>
                                        <div className="my-auto inline-block text-sm font-normal leading-5 text-secondary line-through">
                                            {Helpers.toDollar(price) + unitText}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="my-auto inline-block text-sm font-normal leading-5">
                                        {Helpers.toDollar(price) + unitText}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {product.image && (
                <div className="my-4 flex flex-row items-start justify-center lg:my-0">
                    <div
                        className={
                            "flex flex-col items-start overflow-hidden rounded-xl text-right lg:rounded-l-none lg:rounded-r-xl"
                        }
                        style={{
                            backgroundImage: `url("${optimizedImageURL(
                                product.image,
                                { size: { h: 220 } },
                            )}")`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: dimension,
                            height: dimension,
                        }}
                    ></div>
                </div>
            )}
            <InProductBadges product={product} />
        </div>
    );
}
