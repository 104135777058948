import Dinero from "dinero.js";
import { ChainFeatures, IGiftCard } from "@snackpass/snackpass-types";
import { createSelector } from "reselect";
import { getActiveStore } from "../helperSelectors/globals";
import { ReduxState } from "../../types";
import { getMultiCartStores } from "../helperSelectors";
import { fallbackToEmptyArray } from "../../utils/arrays/fallbackToEmptyArray";

export const getGiftCards = (
    state: Pick<ReduxState, "giftCards">
): IGiftCard[] => state.giftCards;

/* Normal Gift card Selectors */
export const getActiveGiftCards = createSelector(
    [getActiveStore, getGiftCards],
    (activeStore, giftCards) => {
        if (!activeStore) {
            return null;
        }
        const { _id: storeId, chainId, chainConfig } = activeStore;
        // chainId is null if the GC's store does not participate in chain-wide GCs.
        // Stores using chain-wide GCs will have ChainWideGiftCard feature in their chainConfig.
        // Therefore applicable GCs are those with chainId matching active store's chainId
        // (GCs with chainId indicate the GCs associated store is using chain-wide GCs)
        // AND active store has chain-wide GCs enabled in chainConfig
        return fallbackToEmptyArray(
            giftCards?.filter(
                (g: IGiftCard) =>
                    g.storeId === storeId ||
                    (g.chainId === chainId &&
                        chainConfig?.some(
                            (config) =>
                                config?.feature ===
                                ChainFeatures.ChainWideGiftCard
                        ))
            )
        );
    }
);

// Return all the gift card credit available for the active store
export const getGiftCardCreditAvailable = createSelector(
    [getActiveGiftCards],
    (giftCards) => {
        let credit = Dinero({ amount: 0 });
        giftCards?.map((card: IGiftCard) => {
            credit = credit.add(Dinero({ amount: card.remainingCreditCents }));
        });
        return credit.toUnit();
    }
);

/* MultiCart Gift cards Selectors */
// same as getActiveGiftCards, but returns a list of active cards
// each for a different store
export const getAllActiveGiftCards = createSelector(
    [getMultiCartStores, getGiftCards],
    (multiCartStores, giftCards): (IGiftCard[] | null)[] =>
        multiCartStores.map(
            (store) =>
                giftCards?.filter((g: IGiftCard) => g.storeId === store?._id) ||
                []
        )
);

// list of gift card credit available for each store
export const getAllGiftCardCreditAvailable = createSelector(
    [getAllActiveGiftCards],
    (allActiveGiftCardsByStore) =>
        allActiveGiftCardsByStore.map((giftCardsByStore) => {
            let credit = Dinero({ amount: 0 });
            giftCardsByStore?.forEach((giftCard) => {
                credit = credit.add(
                    Dinero({ amount: giftCard.remainingCreditCents })
                );
            });
            return credit.toUnit();
        })
);
