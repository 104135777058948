import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CartActions,
    DealSelectionActions,
    SelectedDealItems,
} from "@snackpass/accounting";
import { IPromotion } from "@snackpass/snackpass-types";
import { getDealSelection } from "@/redux/deal";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import {
    ModalsName,
    hideModal,
    setActiveDealItemsMap,
    setActivePromotion,
    setDealModalActiveTabIndex,
} from "@/redux";
import { cn } from "@/utils";
import { Segment, SegmentEventNames } from "src/utils/Segment";

type Props = {
    promotion: IPromotion;
};

const _allItemsAreSelected = (
    selectedDealItems: SelectedDealItems,
    promotion: IPromotion,
) => {
    return (
        Object.keys(selectedDealItems).length === promotion?.dealItems?.length
    );
};
export function AddDealToCartButton({ promotion }: Props) {
    const selectedDealItems = useSelector(getDealSelection)?.selectedDealItems;
    const allItemsAreSelected = _allItemsAreSelected(
        selectedDealItems,
        promotion,
    );
    const dispatch = useDispatch();

    const addDealGroup = (
        selectedDealItems: SelectedDealItems,
        promotion: IPromotion,
    ) => {
        dispatch(CartActions.addDealGroup(selectedDealItems, promotion));
    };

    const handlePressAddDealToCart = () => {
        if (!allItemsAreSelected) {
            toast.error("Please select all items.");
            return;
        }
        addDealGroup(selectedDealItems, promotion);
        Segment.track(SegmentEventNames.ADDED_DEAL_TO_CART, {
            promoId: promotion?._id,
        });
        dispatch(hideModal(ModalsName.dealModal));
        dispatch(DealSelectionActions.clearDealItems());
        dispatch(setActivePromotion(null));
        dispatch(setActiveDealItemsMap(null));
        dispatch(setDealModalActiveTabIndex(0));
    };

    return (
        <div className="flex w-full flex-row">
            <div className="bg-white w-full">
                <Button
                    onPress={handlePressAddDealToCart}
                    label={"Add to Cart"}
                    variant="brand"
                    className={cn("w-full", {
                        "opacity-50 cursor-default": !allItemsAreSelected,
                    })}
                />
            </div>
        </div>
    );
}
