import axios from "@/utils/Api/axios";
import { IStore } from "@snackpass/snackpass-types";
import { AxiosError } from "axios";
import { ResultAsync } from "neverthrow";

type MaybeError = Partial<Error>;

export async function fetchStoreByID(storeID: string, signal?: AbortSignal) {
    return await ResultAsync.fromPromise(
        axios.get<{ store: IStore }>(`/stores/online/${storeID}`, {
            signal,
        }),
        (error) =>
            error instanceof AxiosError
                ? error
                : new Error(
                      `Unexpected Error: ${(error as MaybeError)?.message}`,
                  ),
    ).map((response) => response.data.store);
}
