import React, { useRef, useEffect, CSSProperties } from "react";
import { View } from "src/SharedComponents";
import {
    GiftCardStateType,
    GiftCardDesign,
    outerContainer,
    StepMoment,
    GiftCardSchema,
    MIN_GIFT_CARD_AMOUNT,
    MAX_GIFT_CARD_AMOUNT,
    GIFT_CARD_VALUE_ERROR_TEXT,
} from "../lib";
import { getUser, getUserIsLoggedIn } from "src/redux";
import { useSelector } from "react-redux";
import { GiftCard } from "src/components/GiftCard";
import { GiftCardCreditCardModal } from "src/components/GiftCardCreditCardModal";
import { GiftCardMoneySelect } from "./GiftCardMoneySelect";
import { GiftCardRecipient } from "./GiftCardRecipient";
import { GiftCardSender } from "./GiftCardSender";
import { GiftCardAddPayment } from "./GiftCardAddPayment";
import { GiftCardDataDisplay } from "./GiftCardDataDisplay";
import { Confirmation } from "./GiftCardConfirmation";
import { IStore } from "@snackpass/snackpass-types";
import { Colors } from "src/utils";
import { Formik, Form } from "formik";
import _ from "lodash";

type GiftCardStepperProps = {
    giftCardState: GiftCardStateType;
    setGiftCardState: React.Dispatch<React.SetStateAction<GiftCardStateType>>;
    store: IStore;
    step: StepMoment;
    setStep: React.Dispatch<React.SetStateAction<StepMoment>>;
    setStart: () => void;
};

const BUTTON_OTHER = "buttonOther";

export const GiftCardStepper = ({
    giftCardState,
    setGiftCardState,
    store,
    step,
    setStep,
    setStart,
}: GiftCardStepperProps) => {
    const formikForm = useRef<any>(null);
    const GIFT_CARD_TYPE =
        giftCardState.amount > 0 ? GiftCardDesign.Amount : GiftCardDesign.Gift;

    const isUserLoggedIn = useSelector(getUserIsLoggedIn);
    const user = useSelector(getUser);

    useEffect(() => {
        if (isUserLoggedIn) {
            formikForm.current?.setFieldValue("fromName", user?.name);
            formikForm.current?.setFieldValue("fromEmail", user?.email);
        }
    }, [isUserLoggedIn, user, step]);

    const handleHideGiftCardCredit = () => setStep(StepMoment.checkout);

    const handleSubmit = (values) => {
        const newGiftCardState = {
            ...giftCardState,
            toName: values.toName,
            toPhone: values.toPhone,
            note: values.note,
            fromName: values.fromName,
            fromEmail: values.fromEmail,
        };

        setStep(StepMoment.checkout);
        setGiftCardState(newGiftCardState);
    };

    const validateAmount = (formValues) => {
        const errors: any = {};

        if (!formValues.amount) {
            if (!giftCardState.buttonSelected.length) {
                errors.amount = "Please select a value for the gift card";
            } else if (giftCardState.buttonSelected === BUTTON_OTHER) {
                errors.amount = GIFT_CARD_VALUE_ERROR_TEXT;
            }
        } else if (
            giftCardState.buttonSelected === BUTTON_OTHER &&
            (formValues.amount < MIN_GIFT_CARD_AMOUNT ||
                formValues.amount > MAX_GIFT_CARD_AMOUNT)
        ) {
            errors.amount = GIFT_CARD_VALUE_ERROR_TEXT;
        }

        return errors;
    };

    switch (step) {
        case StepMoment.fillingForm:
            return (
                <Formik
                    initialValues={{
                        amount: giftCardState.amount,
                        toName: giftCardState.toName,
                        toPhone: giftCardState.toPhone,
                        note: giftCardState.note,
                        fromName: giftCardState.fromName,
                        fromEmail: giftCardState.fromEmail,
                    }}
                    validationSchema={GiftCardSchema}
                    innerRef={(ref) => (formikForm.current = ref)}
                    validate={validateAmount}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setTouched,
                        setFieldValue,
                    }) => (
                        <View style={styles.extContainer}>
                            <Form>
                                <View style={outerContainer}>
                                    <GiftCard
                                        loading={!store._id}
                                        storeName={store.name}
                                        type={GIFT_CARD_TYPE}
                                        color={store.color || Colors.lightBlue}
                                        qty={giftCardState.amount}
                                    />
                                </View>
                                <GiftCardMoneySelect
                                    giftCardState={giftCardState}
                                    setGiftCardState={setGiftCardState}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    setTouched={setTouched}
                                />
                                <GiftCardRecipient
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isKioskPurchase={
                                        giftCardState.isKioskPurchase
                                    }
                                    setFieldValue={setFieldValue}
                                />
                                <GiftCardSender
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isKioskPurchase={
                                        giftCardState.isKioskPurchase
                                    }
                                />
                                {step === StepMoment.fillingForm && (
                                    <button
                                        type="submit"
                                        style={{
                                            ...styles.nextButton,
                                            backgroundColor:
                                                !_.isEmpty(errors) ||
                                                giftCardState.amount === 0
                                                    ? Colors.gray
                                                    : Colors.lightBlue,
                                        }}
                                    >
                                        {`Checkout · $${giftCardState.amount}`}
                                    </button>
                                )}
                            </Form>
                        </View>
                    )}
                </Formik>
            );
        case StepMoment.paymentMethod:
            return (
                <GiftCardCreditCardModal
                    handleHide={handleHideGiftCardCredit}
                    showGiftcard={false}
                />
            );
        case StepMoment.checkout:
            return (
                <>
                    <View style={outerContainer}>
                        <GiftCard
                            size="small"
                            storeName={store.name}
                            type={GIFT_CARD_TYPE}
                            color={store.color || Colors.lightBlue}
                            qty={giftCardState.amount}
                        />
                    </View>
                    <GiftCardDataDisplay giftCardState={giftCardState} />
                    <GiftCardAddPayment
                        giftCardState={giftCardState}
                        step={step}
                        setStep={setStep}
                        setGiftCardState={setGiftCardState}
                    />
                </>
            );
        case StepMoment.confirmPurchase:
            return (
                <Confirmation
                    giftCardState={giftCardState}
                    setStart={setStart}
                    setStep={setStep}
                />
            );
        default:
            return null;
    }
};

const styles = {
    extContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
        width: "100%",
        margin: "auto",
    } as CSSProperties,

    nextButton: {
        width: "95%",
        marginBottom: "5%",
        marginTop: "2%",

        fontSize: 16,
        padding: 10,
        border: "none",
        boxShadow: "none",
        borderRadius: 8,
        fontWeight: 500,
        color: Colors.white,
    } as CSSProperties,
};
